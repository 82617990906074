import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createTaskFetchAllThunk,
	createTaskFetchNextThunk,
	createTaskFetchCollectionEntityThunk,
	createTaskSelectCollection,
	createTaskSelectCollectionEntity,
	createTaskSelectCreated,
	createTaskCollectionSlice,
	TaskCollectionStore,
	TaskCollectionStoreAccessors,
	ThunkApiConfig,
	createTaskCountCollection,
	createTaskRefetchAllThunk,
	createTaskFetchAllSortedThunk,
	createTaskFetchAllFilteredThunk,
	createTaskCollectionAssignThunk,
	createTaskRefetchCollectionEntityThunk,
	createTaskCollectionStoreSize,
	createTaskCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskCollectionApiClientFactory, AnyTaskCollectionApiClientInterface } from '@abb-emobility/usertask/api-integration';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

// Basic definition
export const taskCollectionStoreName = 'task-collection';
export type TaskCollectionCrudStore = { [taskCollectionStoreName]: TaskCollectionStore<AnyTaskCollectionItemModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyTaskCollectionApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createTaskFetchAllThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const refetchAllThunk = createTaskRefetchAllThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createTaskFetchAllSortedThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createTaskFetchAllFilteredThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const fetchNextThunk = createTaskFetchNextThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const fetchEntityThunk = createTaskFetchCollectionEntityThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const refetchEntityThunk = createTaskRefetchCollectionEntityThunk<AnyTaskCollectionItemModel, TaskCollectionCrudStore, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);
const assignThunk = createTaskCollectionAssignThunk<AnyTaskCollectionItemModel, AnyTaskCollectionApiClientInterface>(taskCollectionStoreName, createApiClient);

// Slice definition
export const taskCollectionSlice = createTaskCollectionSlice<AnyTaskCollectionItemModel, ThunkApiConfig>(
	taskCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	assignThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore, setTaskStatus } = taskCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createTaskSelectCollection<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(taskCollectionStoreName);
const countCollection = createTaskCountCollection<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(taskCollectionStoreName);
const selectEntity = createTaskSelectCollectionEntity<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(taskCollectionStoreName);
const selectCreated = createTaskSelectCreated<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(taskCollectionStoreName);
const storeSize = createTaskCollectionStoreSize<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(taskCollectionStoreName);
const storeEntryIds = createTaskCollectionStoreEntryIds<AnyTaskCollectionItemModel, TaskCollectionCrudStore>(taskCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const taskCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	assign: assignThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	setTaskStatus,
	resetStore,
	storeSize,
	storeEntryIds
} as TaskCollectionStoreAccessors<AnyTaskCollectionItemModel, TaskCollectionCrudStore>;
