import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'ABB wallbox',
				labelCancel: 'Abbrechen',
				titlePaymentData: 'Bezahlung',
				labelAmount: 'Summe zusätzlicher Installationsleistungen',
				labelDate: 'Datum',
				labelMerchant: 'Anbieter',
				labelIban: 'Deine IBAN'
			}
		},
		application: {
			pageTitle: 'ABB Wallbox-Installationsservice',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Bestellung {{marketplaceId}}',
			task: 'Bestellung {{marketplaceId}} – {{taskType}}',
			documents: 'Dokumente',
			payment: 'Zahlung',
			notFound: 'Not found',
			static: {
				terms: 'AGB',
				privacy: 'Datenschutz',
				imprint: 'Impressum'
			}
		},
		footer: {
			termsLabel: 'AGB',
			privacyLabel: 'Datenschutz',
			imprintLabel: 'Impressum'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Code eingeben',
			otpInputMessage: 'Wir haben einen 6-stelligen Code an Ihre Handynummer gesendet.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Nochmals senden',
			otpInputLabel: 'Bitte geben Sie Ihren Code ein',
			otpInputPlaceholder: 'Authentifizierungscode',
			otpSubmitButtonLabel: 'Fortsetzen'
		},
		error: {
			otpAuthInitFailed: 'Anmeldung fehlgeschlagen',
			otpAuthReceiveTokenFailed: 'Ungültiger Code',
			taskTypeNotFound: 'Der Aufgabentyp {{taskType}} ist unbekannt.',
			taskNotFound: 'Die Aufgabe konnte nicht gefunden werden.',
			orderNotFound: 'Die Bestellung konnte nicht gefunden werden.',
			documentNotFound: 'Das Dokument konnte nicht gefunden werden.',
			validation: {
				heading: 'Ungültige Daten',
				message: 'Die gesendeten Daten sind ungültig.'
			},
			generic: {
				heading: 'Ein Fehler ist aufgetreten',
				message: 'Der Fehler konnte nicht behoben werden.',
				resolveAction: {
					label: 'Neu laden'
				}
			},
			notFound: {
				heading: 'Not found',
				message: 'Die angefragten Daten konnte nicht gefunden werden.'
			},
			taskCompleteMessage: 'Während der Erledigung der Aufgabe ist ein Fehler aufgetreten.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'Ich benötige Hilfe',
			dialogueDismissLabel: 'Abbrechen',
			dialogueCaption: 'Kundenservice',
			phone: {
				heading: 'Sie erreichen uns rund um die Uhr telefonisch'
			},
			mail: {
				heading: 'Oder Sie schreiben uns eine E-Mail',
				subject: 'Meine Wallbox Bestellung #{{marketplaceId}}',
				subjectStatic: 'Meine Wallbox Bestellung'
			},
			infoPage: {
				heading: 'Oder Sie besuchen unser Informationsangebot',
				label: 'Antworten auf die häufigsten Fragen'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Unbekannt',
			allDoneStepLabel: 'Fertig',
			upcomingLabelPrefix: 'Nächster Schritt: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'von'
		},
		payment: {
			success: {
				heading: 'Ihre Bezahlung war erfolgreich!',
				message: 'Der Installation Ihrer Wallbox steht nichts mehr im Wege. Unser Installationspartner meldet sich zeitnah bei Ihnen, um einen Installationstermin zu vereinbaren.\n\nDanke für Ihr Vertrauen!'
			},
			failed: {
				heading: 'Ihre Bezahlung ist fehlgeschlagen!',
				retryLabel: 'Erneute Zahlung'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Installations-Check',
				customerProvidePreCheckPreferences: 'Kontaktwünsche angeben',
				customerHandleUnfeasibleInstallation: 'Installation technisch nicht möglich',
				customerHandleGridOperatorRejection: 'Rückmeldung Netzbetreiber',
				customerAcceptQuotation: 'Angebot Mehraufwand',
				customerPerformPayment: 'Bezahlung',
				customerPayment: 'Ihre Zahlung',
				customerApproveInstallation: 'Bestätigung Ihrer Installation',
				installationPartnerEvaluateBasicAnswers: 'Installations-Check',
				installationPartnerExecuteRemotePreCheck: 'Finaler Check',
				installationPartnerRegisterAtGridOperator: 'Registrierung Netzbetreiber',
				installationPartnerRevokeGridOperatorRegistration: 'Rücknahme Netzbetreiber-Registrierung',
				installationPartnerProvideGridOperatorDecision: 'Rückmeldung Netzbetreiber',
				installationPartnerProvideQuotation: 'Erstellung Angebot',
				installationPartnerArrangeAppointment: 'Terminvereinbarung',
				installationPartnerCompleteInstallation: 'Installationsdurchführung'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Installations-Check',
				customerProvidePreCheckPreferences: 'Kontaktwünsche',
				customerHandleUnfeasibleInstallation: 'Installation nicht möglich',
				customerHandleGridOperatorRejection: 'Rückmeldung Netzbetreiber',
				customerAcceptQuotation: 'Angebot Mehraufwand',
				customerPerformPayment: 'Bezahlung',
				customerPayment: 'Zahlung',
				customerApproveInstallation: 'Bestätigung Ihrer Installation',
				installationPartnerEvaluateBasicAnswers: 'Installations-Check',
				installationPartnerExecuteRemotePreCheck: 'Finaler Check',
				installationPartnerRegisterAtGridOperator: 'Registrierung Netzbetreiber',
				installationPartnerRevokeGridOperatorRegistration: 'Rücknahme Netzbetreiber-Registrierung',
				installationPartnerProvideGridOperatorDecision: 'Rückmeldung Netzbetreiber',
				installationPartnerProvideQuotation: 'Erstellung Angebot',
				installationPartnerArrangeAppointment: 'Terminvereinbarung',
				installationPartnerCompleteInstallation: 'Installationsdurchführung'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'Die Vollmachtserteilung dient dazu, dass wir durch Ihr Einverständnis die Anmeldung zum Netzanschluss vornehmen dürfen.',
						powerOfAttorney: {
							heading: 'Vollmachtserteilung zur Anmeldung von Verbrauchern beim Netzbetreiber',
							description: 'Nennen Sie uns Ihren Netzbetreiber zur erfolgreichen Anmeldung Ihrer Wallbox.',
							gridOperator: {
								question: 'Netzbetreiber oder Energieversorger'
							},
							powerOfAttorney: {
								question: 'Hiermit erteile ich ABB und seinem Installationspartner die Vollmacht, die Wallbox beim Netzbetreiber zu registrieren. Wird die Installation aus jedwedem Grund nicht durchgeführt, erlischt die Vollmacht automatisch.'
							}
						},
						marketingConsent: {
							heading: 'Zustimmung zur Kommunikation mit dem Installationspartner',
							installationPartnerMarketingConsent: {
								question: 'Hiermit erteile ich ABB und seinem Installationspartner die Erlaubnis, mich zu Marketingzwecken zu kontaktieren.'
							}
						},
						surveyConsent: {
							heading: 'Teilen Sie Ihre Meinung mit uns',
							description: 'Bitte helfen Sie uns, unsere Produkte und unser Angbot zu verbessern, indem Sie nach Abschluss der Bestellung an einer kurzen Umfrage teilnehmen.',
							surveyConsent: {
								question: 'Hiermit erteile ich ABB die Erlaubnis, mich zu einer Umfrage einzuladen.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Danke für Ihre Zeit!',
					message: 'Sie erhalten umgehend eine Bestätigung per E-Mail. Wir prüfen Ihre Daten und setzen uns anschließend mit Ihnen in Verbindung, um das weitere Vorgehen mit Ihnen abzustimmen.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Zurück',
				nextStepButtonLabel: 'Nächster Schritt',
				submitButtonLabel: 'Informationen senden',
				channelSelection: {
					heading: 'Persönliches Gespräch mit unserem Service-Team',
					message: 'Damit die Installation Ihrer neuen Wallbox reibungslos verläuft, möchten wir Sie bitten, Ihre Präferenzen für ein persönliches Gespräch mit unserem Service-Team zu teilen. Basierend auf Ihren bereits übermittelten Informationen wird dann das weitere Vorgehen besprochen.',
					subheading: 'Wie dürfen wir Sie kontaktieren?',
					explanation: 'Wir können uns auf verschiedenen Wegen mit Ihnen in Verbindung setzen.'
				},
				preferredTimesOfDay: {
					subHeading: 'Lassen Sie uns einen gemeinsamen Termin für ein Gespräch finden. Dieser Anruf findet über {{channelName}} statt.',
					timeOfDay: {
						morning: 'Morgens',
						noon: 'Mittags',
						afternoon: 'Nachmittags',
						evening: 'Abends'
					}
				},
				completionSuccess: {
					heading: 'Schön, dass Sie Zeit für uns haben!',
					message: 'Wir werden Sie möglichst zeitnah zu den von Ihnen gewählten Verfügbarkeiten kontaktieren.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Es tut uns sehr leid, aber',
				message: 'eine Installation durch unseren Installationspartner ist derzeit nicht möglich. Der Grund dafür ist: \n\n{{unfeasibleReason}}\n\nDaher müssen wir Sie bitten, Ihre Bestellung zu stornieren.',
				submitButtonLabel: 'Informationen senden',
				unfeasibleReason: {
					noPotentialRailAvailable: 'Eine Potenzialschiene, die zur Installation des Überspannschutzes notwendig ist, ist nicht vorhanden.',
					overvoltageProtectionUnfeasible: 'Die notwndige Installation eines Überspannschutzes ist nicht möglich.',
					spaceForCableOutletMissing: 'Für einen notwedigen zusätzlichen Leitungsabgang ist kein ausreichender Platz vorhanden.',
					houseConnectionUndersized: 'Der Hausanschluss ist so unterdimensioniert, dass eine Installation der Wallbox nicht möglich ist.',
					noThreePhaseAvailable: 'Der bestehende elektrische Anlage stellt die notwendige Drehstrom-Versorgung nicht zur Verfügung.',
					outdatedMeterCabinet: 'Der Zählerschrank genügt den Anforderungen an die Inbetriebnahme der Wallbox nicht.',
					notTabCompliant: 'Die elektrische Anlage entspricht den geltenden technischen Anschlussbedingungen nicht.',
					other: 'Einer Installation stehen technische Gründe im Weg, die sich nicht im Zuge einer Installation beseitigen lassen.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Ihre Installation wurde erfolgreich storniert.',
					message: 'Ihr Stornierung ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung per E-Mail.'
				},
				completionCancelOrderSuccess: {
					heading: 'Ihre Bestellung wurde erfolgreich storniert.',
					message: 'Ihr Stornierung ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung per E-Mail.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Es tut uns leid! Ihr Netzbetreiber hat die Inbetriebnahme der Wallbox verweigert',
				message: 'Leider reicht die Stromversorgung am Installationsort für den Betrieb Ihrer Wallbox nicht aus. Sie haben die Möglichkeit die Leistung Ihrer Wallbox zu verringern oder die Installation zu stornieren.  ',
				submitButtonLabel: 'Informationen senden',
				solutionDecreaseWallboxPower: {
					heading: 'Verringern Sie die Leistung der Wallbox',
					description: 'Die Leistung Ihrer Wallbox wird auf eine Ihrer Stromversorgung angepassten Leistung reduziert. Die Reduzierung der Leistungsaufnahme hat Einfluss auf die Ladeeffizienz.'
				},
				solutionCancelInstallation: {
					heading: 'Installation stornieren',
					description: 'Ihre Installation durch unseren Installationspartner wird storniert. Die Wallbox wird Ihnen direkt zugesendet. Die Installationskosten werden Ihnen in den nächsten Tagen erstattet.'
				},
				solutionCancelOrder: {
					heading: 'Gesamte Bestellung stornieren',
					description: 'Ihre gesamte Bestellung wird storniert und alle Kosten werden Ihnen in den nächsten Tagen erstattet.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Leistungsverringerung Ihrer Wallbox',
					message: 'Hiermit bestätigt ABB die Installation der Wallbox auf Basis einer den Anforderungen des Netzbetreibers entsprechenden Leistungsverringerung. \n\nWir informieren Sie zeitnah über die nächsten Schritte.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Ihre Installation wurde erfolgreich storniert.',
					message: 'Ihr Stornierung ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung per E-Mail.'
				},
				completionCancelOrderSuccess: {
					heading: 'Ihre Bestellung wurde erfolgreich storniert.',
					message: 'Ihr Stornierung ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung per E-Mail.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Informationen senden',
				message: 'Bitte bestätigen Sie uns die erfolgreiche Durchführung der Wallbox-Installation.',
				approval: {
					caption: 'Bestätigung',
					label: 'Hiermit bestätige ich die erfolgreiche Installation der Wallbox sowie die Inbetriebnahme und Funktionalität.'
				},
				completionSuccess: {
					heading: 'Vielen Dank!',
					message: 'Die Installation Ihrer Wallbox ist abgeschlossen. Sie erhalten in Kürze alle wichtigen Unterlagen.\n\nDanke für Ihr Vertrauen!'
				}
			},
			quotationAcceptance: {
				caption: 'Ihr individuelles Angebot',
				message: 'Die folgenden Installationspakete sind Zusatzleistungen, die gesondert berechnet werden. Im Zuge der Angebotsannahme werden Sie zu unserem Zahlungsdienstleister Deutsche Bank weitergeleitet, der die Zahlung in unserem Auftrag abwickelt. \n\nMit der Angebotsannahme werden über dieses Angebot hinaus keine weiteren Kosten fällig und die Installation kann durchgeführt werden. \n\nUnser Installationspartner wird mit Ihnen in Kontakt treten, um einen Installationstermin zu vereinbaren.',
				submitButtonLabel: 'Angebot zahlungspflichtig annehmen',
				cancelButtonLabel: 'Stornieren',
				cancellationCaption: 'Schade, dass wir Ihnen kein gutes Angebot machen können',
				cancellationMessage: 'Bitte geben Sie an, wie Sie Ihre Bestellung stornieren möchten.',
				submitCancellationButtonLabel: 'Informationen senden',
				recoverCancellationButtonLabel: 'Zurück',
				conditions: {
					caption: 'Angebotsbedingungen',
					message: 'Dieses Angebot ist gültig solange die folgenden Bedingungen basierend auf Ihren Angaben zutreffen.'
				},
				acceptance: {
					terms: 'die allgemeinen Geschäftsbedingungen',
					label: 'Hiermit akzeptiere ich {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'Sie werden weitergeleitet',
					message: 'Sie haben unser Angebot angenommen und werden zu unserem Zahlungsdienstleister weitergeleitet.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Ihre Installation wurde erfolgreich storniert.',
					message: 'Ihr Stornierung ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung per E-Mail.'
				},
				completionCancelOrderSuccess: {
					heading: 'Ihre Bestellung wurde erfolgreich storniert.',
					message: 'Ihr Stornierung ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung per E-Mail.'
				}
			},
			payment: {
				heading: 'Ihre Zahlung',
				message: 'Sie werden zu unserem Zahlungsdienstleister weitergeleitet.'
			}
		},
		documents: {
			heading: 'Dokumente zu Ihrer Bestellung',
			introduction: 'Dokumentenübersicht'
		},
		order: {
			heading: 'Ihre Bestellung',
			cancelledHint: 'Diese Bestellung wurde erfolgreich storniert.',
			doneHint: 'Diese Bestellung wurde erfolgreich abgeschlossen.',
			introduction: 'Hier finden Sie alle aktuellen Informationen zu Ihrer Wallbox-Bestellung bei {{marketplaceName}} vom {{orderDate}} mit der Bestellnummer {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Jetzt erledigen'
			},
			information: {
				heading: {
					orderItems: 'Bestellung',
					quotation: 'Angebot Zusatzleistungen',
					appointment: 'Installationstermin',
					shipping: 'Versandstatus'
				},
				orderItems: {
					position: 'Position',
					description: 'Beschreibung',
					wallboxSerialNumbner: 'Wallbox Seriennr.',
					totalGrossPrice: 'Gesamt-Bruttopreis',
					cancellationHint: 'Storniert am {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'Die Installation wurde am {{cancellationDate}} storniert'
				},
				appointment: {
					heading: 'Installation'
				},
				shipping: {
					heading: 'Versand durch {{carrier}}',
					trackingNumberLabel: 'Sendungsnummer',
					shippingAddressLabel: 'Versandadresse',
					trackingLinkLabel: 'Sendungsverfolgung',
					itemsLabel: 'Lieferpositionen',
					productCodeLabel: 'Produkt-Code'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Installation stornieren',
				description: 'Ihre Installation durch unseren Installationspartner wird storniert. Die Wallbox wird Ihnen direkt zugesendet. Die Installationskosten werden Ihnen in den nächsten Tagen erstattet.'
			},
			cancelOrder: {
				heading: 'Installation und Wallbox stornieren',
				description: 'Ihre gesamte Bestellung wird storniert und die gesamten Kosten werden Ihnen in den nächsten Tagen erstattet.'
			}
		}
	}
};

export default literals;
