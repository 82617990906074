import { InstallationPartnerOrderApiClientFactory, InstallationPartnerOrderApiClientInterface } from '@abb-emobility/oms/api-integration';
import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const installationPartnerOrderCollectionStoreName = 'installation-partner-order-collection-crud';
export type InstallationPartnerOrderCollectionStore = { [installationPartnerOrderCollectionStoreName]: CrudCollectionStore<InstallationPartnerOrderModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return InstallationPartnerOrderApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<InstallationPartnerOrderModel, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<InstallationPartnerOrderModel, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<InstallationPartnerOrderModel, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderCollectionStoreName, createApiClient);

// Slice definition
export const installationPartnerOrderCollectionSlice = createCrudCollectionSlice<InstallationPartnerOrderModel, ThunkApiConfig>(
	installationPartnerOrderCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = installationPartnerOrderCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>(installationPartnerOrderCollectionStoreName);
const countCollection = createCrudCountCollection<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>(installationPartnerOrderCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>(installationPartnerOrderCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>(installationPartnerOrderCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>(installationPartnerOrderCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>(installationPartnerOrderCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const installationPartnerOrderCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<InstallationPartnerOrderModel, InstallationPartnerOrderCollectionStore>;
