import { TaskCompletionCommentModel } from '@abb-emobility/shared/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';

export const taskCompletionCommentModelMock: Dto<TaskCompletionCommentModel> = {
	id: '1',
	author: {
		name: 'Max Mustermann',
		mailAddress: 'mm@domain.tld'
	},
	createdAt: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	comment: 'Donec id elit non mi porta gravida at eget metus. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla.'
};
