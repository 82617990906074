import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	PaginatedCollectionResponse,
	compareFilterCriteria,
	compareSortCriteria,
	FilterCriteria,
	SortCriteria,
	TaskAssignResponse,
	TaskCollectionApiClient, HypermediaEntityResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey, TaskCollectionModel } from '@abb-emobility/shared/domain-model-foundation';
import { createErrorObject } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { TaskCollectionStore } from './TaskCollectionStore';
import { FetchStatus, fetchStatusPendingGroup } from '../FetchStatus';
import { ThunkApiConfig } from '../ThunkApiConfig';

export const createTaskCollectionAssignThunk = <TaskModel extends TaskCollectionModel, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<Nullable<TaskAssignResponse>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, model: TaskModel, candidateGroupIds: Array<ModelPrimaryKey> }, ThunkApiConfig>(
		storeName + '/assign',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).assign(params.id, params.candidateGroupIds);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};

export const createTaskFetchAllThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, sortCriteria?: SortCriteria<TaskModel>, filterCriteria?: FilterCriteria<TaskModel>, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchAll',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(params.sortCriteria, params.filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Proceed if force fetch
				if (params.forceFetch) {
					return true;
				}
				// Proceed if sort criteria has changed
				if (!compareSortCriteria<TaskModel>(params.sortCriteria, store.sortCriteria ?? undefined)) {
					return true;
				}
				// Proceed if filter criteria has changed
				if (!compareFilterCriteria<TaskModel>(params.filterCriteria, store.filterCriteria ?? undefined)) {
					return true;
				}
				// Cancel if store is populated
				return store.fetchStatus === FetchStatus.IDLE;
			}
		}
	);
};

export const createTaskRefetchAllThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> }, ThunkApiConfig>(
		storeName + '/refetchAll',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const sortCriteria = store.sortCriteria ?? undefined;
				const filterCriteria = store.filterCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(sortCriteria, filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (_params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				return !fetchStatusPendingGroup.includes(store.fetchStatus);
			}
		}
	);
};

export const createTaskFetchAllSortedThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, sortCriteria?: SortCriteria<TaskModel>, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchAllSorted',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const filterCriteria = store.filterCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(params.sortCriteria, filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Proceed if force fetch
				if (params.forceFetch) {
					return true;
				}
				// Proceed if sort criteria has changed
				if (!compareSortCriteria<TaskModel>(params.sortCriteria, store.sortCriteria ?? undefined)) {
					return true;
				}
				// Cancel if store is populated
				return store.fetchStatus === FetchStatus.IDLE;
			}
		}
	);
};

export const createTaskFetchAllFilteredThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, filterCriteria?: FilterCriteria<TaskModel>, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchAllFiltered',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const sortCriteria = store.sortCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(sortCriteria, params.filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Proceed if force fetch
				if (params.forceFetch) {
					return true;
				}
				// Proceed if filter criteria has changed
				if (!compareFilterCriteria<TaskModel>(params.filterCriteria, store.filterCriteria ?? undefined)) {
					return true;
				}
				// Cancel if store is populated
				return store.fetchStatus === FetchStatus.IDLE;
			}
		}
	);
};

export const createTaskFetchNextThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> }, ThunkApiConfig>(
		storeName + '/fetchNext',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const currentPage = store.currentPage ?? 1;
				const sortCriteria = store.sortCriteria ?? undefined;
				const filterCriteria = store.filterCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchPage(currentPage + 1, sortCriteria, filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (_params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				const currentPage = store.currentPage ?? 1;
				const lastPage = store.maxPages ?? 1;
				return !fetchStatusPendingGroup.includes(store.fetchStatus) && currentPage < lastPage;
			}
		}
	);
};

export const createTaskFetchCollectionEntityThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchCollectionEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus) || fetchStatusPendingGroup.includes(store.fetchEntityStatus?.[params.id])) {
					return false;
				}
				// Ignore existing model if forceFetch
				if (params.forceFetch) {
					return true;
				}
				// Cancel if id is already existing
				const existingModel = store.models.find((entry) => {
					return entry.id === params.id;
				});
				return existingModel === undefined;
			}
		}
	);
};

export const createTaskRefetchCollectionEntityThunk = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>, ApiClient extends TaskCollectionApiClient<TaskModel>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey }, ThunkApiConfig>(
		storeName + '/refetchCollectionEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus) || fetchStatusPendingGroup.includes(store.fetchEntityStatus?.[params.id])) {
					return false;
				}
				// Cancel if id not already existing
				const existingModel = store.models.find((entry) => {
					return entry.id === params.id;
				});
				return existingModel !== undefined;
			}
		}
	);
};
