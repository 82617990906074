import {
	CustomerModel, CustomerModelConverter,
	InstallationDecisionType, InstallationProjectReason,
	InstallationTradeEffortModel,
	InstallationTradeModel, InstallationTradeModelConverter, InstallationUnfeasibleReason
} from '@abb-emobility/shared/domain-model';
import {
	ModelConverter,
	ModelConverterInterface
} from '@abb-emobility/shared/domain-model-foundation';
import {
	PersistedAnswersDto,
	QuestionnaireEnvironmentModel,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

type BasicAnswersPayloadFoundationModel = {
	readonly questionnaire: AnyQuestionnaireModel,
	readonly questionnaireEnvironment: QuestionnaireEnvironmentModel,
	readonly answers: PersistedAnswersDto,
	// TODO: Add the customer member to the API and define it as mandatory; see https://jira.fiege.com/browse/ABB-653
	readonly customer?: CustomerModel,
	readonly tradeOptions: Array<InstallationTradeModel>
} & TaskPayloadFoundationModel;

export type BasicAnswersPayloadDecisionModel = {
	installationType?: InstallationDecisionType,
	installationTradeEfforts?: Array<InstallationTradeEffortModel>
} & BasicAnswersPayloadFoundationModel;

export type BasicAnswersPayloadUnfeasibleDecisionModel = {
	installationType?: InstallationDecisionType.UNFEASIBLE,
	decisionReason?: InstallationUnfeasibleReason
} & BasicAnswersPayloadFoundationModel;

export type BasicAnswersPayloadProjectDecisionModel = {
	installationType?: InstallationDecisionType.PROJECT,
	decisionReason?: InstallationProjectReason
} & BasicAnswersPayloadFoundationModel;

export type BasicAnswersPayloadFurtherInformationRequiredModel = {
	installationType?: InstallationDecisionType.FURTHER_INFORMATION_REQUIRED
} & BasicAnswersPayloadFoundationModel;

export type AnyBasicAnswersPayloadModel =
	| BasicAnswersPayloadDecisionModel
	| BasicAnswersPayloadFurtherInformationRequiredModel
	| BasicAnswersPayloadUnfeasibleDecisionModel
	| BasicAnswersPayloadProjectDecisionModel;

export class BasicAnswersPayloadModelConverter extends ModelConverter<AnyBasicAnswersPayloadModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof AnyBasicAnswersPayloadModel, ModelConverterInterface<any>> {
		return new Map<
			keyof AnyBasicAnswersPayloadModel,
			ModelConverterInterface<CustomerModel> |
			ModelConverterInterface<InstallationTradeModel>
		>([
			['customer', new CustomerModelConverter()],
			['tradeOptions', new InstallationTradeModelConverter()]
		]);
	}
}

export type AnyBasicAnswersEscalationPayloadModel = AnyBasicAnswersPayloadModel & CustomerServiceTaskPayloadFoundationModel;

/*
BasicAnswersEscalationPayloadModelConverter combines the functionality of the
- CustomerServiceTaskPayloadFoundationModelConverter
- BasicAnswersPayloadModelConverter
 */
export class BasicAnswersEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<AnyBasicAnswersEscalationPayloadModel> {
	private payloadModelConverter = new BasicAnswersPayloadModelConverter();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof AnyBasicAnswersEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}

