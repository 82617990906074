/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	ModelConverterInterface,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type TaskCompletionCommentAuthorModel = {
	readonly name: string,
	readonly mailAddress: string
};

export class TaskCompletionCommentAuthorModelConverter extends ModelConverter<TaskCompletionCommentAuthorModel> {
}

export type TaskCompletionCommentModel = {
	readonly id: ModelPrimaryKey,
	readonly author: TaskCompletionCommentAuthorModel,
	readonly createdAt: Timestamp,
	readonly comment: string
};

export class TaskCompletionCommentModelConverter extends ModelConverter<TaskCompletionCommentModel> {

	override getTimestampFields(): Array<TimestampFieldNames<TaskCompletionCommentModel> | ArrayOfTimestampFieldNames<TaskCompletionCommentModel>> {
		return ['createdAt'];
	}

	override getFieldConverterMapByModel(): Map<keyof TaskCompletionCommentModel, ModelConverterInterface<any>> {
		return new Map<keyof TaskCompletionCommentModel, ModelConverterInterface<any>>([
			['author', new TaskCompletionCommentAuthorModelConverter()]
		]);
	}

}
