import { useEffect, useState } from 'react';

import { getPageTitle, setPageTitle } from '@abb-emobility/shared/browser';

export const usePageTitle = (title: string): void => {

	useEffect(() => {
		setPageTitle(title);
	}, [title]);

};

export const usePageTitleModifier = (): (title: string) => void => {

	const [pageTitleState, setPageTitleState] = useState<string>(getPageTitle());

	useEffect(() => {
		setPageTitle(pageTitleState);
	}, [pageTitleState]);

	return (title: string): void => {
		if (title !== pageTitleState) {
			setPageTitleState(title);
		}
	};
};
