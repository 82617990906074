import { ButtonHTMLAttributes } from 'react';

import './ButtonSecondary.scss';

export type ButtonSecondaryProps = {
	label: string
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonSecondary(props: ButtonSecondaryProps) {
	const { type = 'button', label, ...rest } = props;

	return (
		<button
			type={type}
			className="button-secondary"
			{...rest}
		>{label}</button>
	);
}
