import { FooterItems } from '@abb-emobility/oms/customer-ui-primitive';
import { useCustomerOrderEntityData } from '@abb-emobility/oms/data-provider';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { useLegaDocumentUrls } from '../../../hooks/LegalDocumentUrls.hook';

export function OrderFooterItems() {

	const l10n = useL10n();
	const customerOrderData = useCustomerOrderEntityData();
	const customerOrder = customerOrderData.query().getOrThrow(new AppError('Order not found'));
	const legalDocumentUrls = useLegaDocumentUrls(customerOrder.marketplace.id);

	return (
		<FooterItems>
			<a href={legalDocumentUrls.generalTermsUrl} target="_blank" rel="noopener noreferrer">
				{l10n.translate('omsCustomerApp.footer.termsLabel')}
			</a>
			<a href={legalDocumentUrls.privacyNoticeUrl} target="_blank" rel="noopener noreferrer">
				{l10n.translate('omsCustomerApp.footer.privacyLabel')}
			</a>
			<a href={legalDocumentUrls.imprintUrl} target="_blank" rel="noopener noreferrer">
				{l10n.translate('omsCustomerApp.footer.imprintLabel')}
			</a>
		</FooterItems>
	);
}
