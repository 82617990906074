import { ErrorFeedback, ErrorFeedbackAction, SpinnerCable } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderEntityDataProvider } from '@abb-emobility/oms/data-provider';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AuthenticationFailedError, AuthenticationRequiredError, ErrorHandler } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { Order } from './Order';

export type OrderAssemblerProps = {
	orderId: ModelPrimaryKey
};

export function OrderAssembler(props: OrderAssemblerProps) {

	const { orderId } = props;

	const auth = useAuth();
	const l10n = useL10n();

	const handleError = (error: Error) => {
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth.reauthenticate();
		}

		const reloadAction: ErrorFeedbackAction = {
			label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
			onInvoke: (): void => {
				window.location.reload();
			}
		};

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				actions={[reloadAction]}
			/>
		);
	};

	return (
		<ErrorHandler errorComponent={handleError}>
			<CustomerOrderEntityDataProvider
				primaryKey={orderId}
				pendingComponent={SpinnerCable}
			>
				<Order />
			</CustomerOrderEntityDataProvider>
		</ErrorHandler>
	);
}
