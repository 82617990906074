import {
	CustomerOrderInformationAppointmentModel,
	CustomerOrderInformationOrderItemsModel,
	CustomerOrderInformationQuotationModel,
	CustomerOrderInformationShippingModel,
	CustomerOrderInformationType
} from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerOrderInformationAppointmentInformationModelMock
} from './order-information/CustomerOrderInformationAppointmentInformationModel.mock';
import {
	customerOrderInformationOrderItemsInformationModelMock
} from './order-information/CustomerOrderInformationOrderItemsInformationModel.mock';
import {
	customerOrderInformationQuotationInformationModelMock
} from './order-information/CustomerOrderInformationQuotationInformationModel.mock';
import {
	customerOrderInformationShippingInformationModelMock
} from './order-information/CustomerOrderInformationShippingInformationModel.mock';

export const customerOrderInformationOrderItemsModelMock: Dto<CustomerOrderInformationOrderItemsModel> = {
	id: '1',
	orderId: '1',
	type: CustomerOrderInformationType.ORDER_ITEMS,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerOrderInformationOrderItemsInformationModelMock
};

export const customerOrderInformationOfferModelMock: Dto<CustomerOrderInformationQuotationModel> = {
	id: '2',
	orderId: '1',
	type: CustomerOrderInformationType.QUOTATION,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerOrderInformationQuotationInformationModelMock
};

export const customerOrderInformationAppointmentModelMock: Dto<CustomerOrderInformationAppointmentModel> = {
	id: '3',
	orderId: '1',
	type: CustomerOrderInformationType.APPOINTMENT,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerOrderInformationAppointmentInformationModelMock
};

export const customerOrderInformationShippingModelMock: Dto<CustomerOrderInformationShippingModel> = {
	id: '4',
	orderId: '1',
	type: CustomerOrderInformationType.SHIPPING,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerOrderInformationShippingInformationModelMock
};
