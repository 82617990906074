import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createTaskAssignThunk,
	createTaskCompleteThunk,
	createTaskFetchThunk,
	createTaskSelectEntity,
	createTaskEntitySlice,
	TaskEntityStore,
	TaskEntityStoreAccessor,
	ThunkApiConfig,
	createTaskSelectHypermediaLinks, createTaskEntityStoreSize, createTaskEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskApiClientFactory, AnyTaskApiClientInterface } from '@abb-emobility/usertask/api-integration';
import { AnyTaskModel } from '@abb-emobility/usertask/domain-model';

// Basic definition
export const anyTaskEntityStoreName = 'task';
export type AnyTaskEntityStore = { [anyTaskEntityStoreName]: TaskEntityStore<AnyTaskModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyTaskApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createTaskFetchThunk<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskEntityStore, AnyTaskApiClientInterface>(anyTaskEntityStoreName, createApiClient);
const completeThunk = createTaskCompleteThunk<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskApiClientInterface>(anyTaskEntityStoreName, createApiClient);
const assignThunk = createTaskAssignThunk<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskApiClientInterface>(anyTaskEntityStoreName, createApiClient);

// Slice definition
export const anyTaskEntitySlice = createTaskEntitySlice<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, ThunkApiConfig>(anyTaskEntityStoreName, fetchThunk, completeThunk, assignThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore, setTaskStatus } = anyTaskEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createTaskSelectEntity<AnyTaskModel, AnyTaskEntityStore>(anyTaskEntityStoreName);
const selectHypermediaLinks = createTaskSelectHypermediaLinks<AnyTaskModel, AnyTaskEntityStore>(anyTaskEntityStoreName);
const storeSize = createTaskEntityStoreSize<AnyTaskModel, AnyTaskEntityStore>(anyTaskEntityStoreName);
const storeEntryIds = createTaskEntityStoreEntryIds<AnyTaskModel, AnyTaskEntityStore>(anyTaskEntityStoreName);

// Export the store accessors to be used by the data provider value
export const anyTaskEntityStoreAccessors = {
	fetch: fetchThunk,
	complete: completeThunk,
	assign: assignThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	setTaskStatus,
	resetStore,
	storeSize,
	storeEntryIds
} as TaskEntityStoreAccessor<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskEntityStore>;
