import { PreCheckMediumModel, PreCheckMediumType } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const preCheckMediumModelMock01: Dto<PreCheckMediumModel> = {
	id: '1',
	name: 'WhatsApp video call',
	type: PreCheckMediumType.VIDEO_CALL
};

export const preCheckMediumModelMock02: Dto<PreCheckMediumModel> = {
	id: '2',
	name: 'Facetime call',
	type: PreCheckMediumType.VIDEO_CALL
};

export const preCheckMediumModelMock03: Dto<PreCheckMediumModel> = {
	id: '3',
	name: 'Phone call',
	type: PreCheckMediumType.PHONE_CALL
};
