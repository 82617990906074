import React, { ReactElement, ReactNode } from 'react';

import {
	ButtonGhost,
	ErrorFeedback,
	ErrorFeedbackAction,
	Notification,
	NotificationLevel,
	SpinnerCable
} from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderInformationCollectionDataProvider, useCustomerOrderEntityData } from '@abb-emobility/oms/data-provider';
import { CustomerOrderState } from '@abb-emobility/oms/domain-model';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { CrudActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { AuthenticationFailedError, AuthenticationRequiredError, ErrorHandler, NotFoundError } from '@abb-emobility/shared/error';
import { useDialogue } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { Nullable } from '@abb-emobility/shared/util';

import { useBuildPageTitle } from '../../hooks/PageTitle.hook';
import { CurrentTaskButton } from '../current-task-button/CurrentTaskButton';
import { CustomerServiceDialogue } from '../dialogue/customer-service-dialogue/CustomerServiceDialogue';
import { OrderInformation } from '../order-information/OrderInformation';
import { TaskOrderProgress } from '../task-order-progress/TaskOrderProgress';

import './Order.scss';

export function Order() {

	const auth = useAuth();
	const l10n = useL10n();
	const dialogue = useDialogue();
	const orderEntityData = useCustomerOrderEntityData();
	const orderModel = orderEntityData.query().getOrThrow(new NotFoundError(l10n.translate('omsCustomerApp.error.taskNotFound')));
	const orderActionState = orderEntityData.queryActionStatus();

	usePageTitle(useBuildPageTitle('omsCustomerApp.pageTitle.order', new Map([['marketplaceId', orderModel.marketplaceOrderId]])));

	const handleError = (error: Error): Nullable<ReactElement> => {
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth.reauthenticate();
		}

		const reloadAction: ErrorFeedbackAction = {
			label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
			onInvoke: (): void => {
				window.location.reload();
			}
		};

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				actions={[reloadAction]}
				key="OmsGenericError"
			/>
		);
	};

	const handleHelpRequest = (): void => {
		dialogue.present(() => {
			return (<CustomerServiceDialogue orderId={orderModel.id} />);
		});
	};

	const renderFooter = (): ReactNode => {
		if (orderActionState === CrudActionStatus.IDLE) {
			return (
				<footer className="order__footer">
					<ButtonGhost
						label={l10n.translate('omsCustomerApp.customerServiceDialogue.dialogueTriggerLabel')}
						onClick={handleHelpRequest}
					/>
				</footer>
			);
		}
		return null;
	};

	const renderOrderProgress = (): ReactNode => {
		if (orderModel.orderState === CustomerOrderState.CANCELLED) {
			return (
				<Notification level={NotificationLevel.INFO} message={l10n.translate('omsCustomerApp.order.cancelledHint')} />
			);
		}
		if (orderModel.orderState === CustomerOrderState.DONE) {
			return (
				<Notification level={NotificationLevel.SUCCESS} message={l10n.translate('omsCustomerApp.order.doneHint')} />
			);
		}
		return (
			<header className="order__header">
				<TaskOrderProgress />
				<CurrentTaskButton />
			</header>
		);
	};

	return (
		<article className="order">
			{renderOrderProgress()}
			<main className="order__main">
				<ErrorHandler errorComponent={handleError}>
					<section className="order__main__intro">
						<h1 className="order__main__intro__heading">{l10n.translate('omsCustomerApp.order.heading')}</h1>
						<p className="order__main__intro__introduction">{
							l10n.translate('omsCustomerApp.order.introduction', new Map([
								['marketplaceName', orderModel.marketplace.name],
								['marketplaceOrderId', orderModel.marketplaceOrderId],
								['orderDate', l10n.formatTimestampDateTime(orderModel.orderDate)]
							]))
						}</p>
					</section>
					<CustomerOrderInformationCollectionDataProvider
						orderId={orderModel.id}
						pendingComponent={SpinnerCable}
					>
						<OrderInformation />
					</CustomerOrderInformationCollectionDataProvider>
				</ErrorHandler>
			</main>
			{renderFooter()}
		</article>
	);

}
