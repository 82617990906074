/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { TimestampFieldNames, ArrayOfTimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { OrderImportEntryModelConverter } from './common/OrderImportEntryModel';
import { OrderImportPaymentModelConverter } from './common/OrderImportPaymentModel';
import { OrderImportRecipientModelConverter } from './common/OrderImportRecipientModel';
import { OrderImportCustomerSalutation } from './common/OrderImportSalutation';
import { OrderImportFoundationModel } from './foundation/OrderImportFoundationModel';

export type OrderImportB2cCustomerModel = {
	customerId: string,
	firstName: string,
	lastName: string,
	emailAddress: string,
	languageCode: IsoLanguageCode,
	salutation: OrderImportCustomerSalutation
};

class OrderImportB2cCustomerModelConverter extends ModelConverter<OrderImportB2cCustomerModel> {
}

export type OrderImportB2cModel = OrderImportFoundationModel & {
	customer: OrderImportB2cCustomerModel
};

export class OrderImportB2cModelConverter extends ModelConverter<OrderImportB2cModel> {
	override getTimestampFields(): Array<TimestampFieldNames<OrderImportB2cModel> | ArrayOfTimestampFieldNames<OrderImportB2cModel>> {
		return ['orderCreationDateGMT'];
	}

	override getFieldConverterMapByModel(): Map<keyof OrderImportB2cModel, ModelConverterInterface<any>> {
		return new Map<keyof OrderImportB2cModel, ModelConverterInterface<any>>([
			['customer', new OrderImportB2cCustomerModelConverter()],
			['orderEntries', new OrderImportEntryModelConverter()],
			['payment', new OrderImportPaymentModelConverter()],
			['shipTo', new OrderImportRecipientModelConverter()],
			['billTo', new OrderImportRecipientModelConverter()]
		]);
	}
}
