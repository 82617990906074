import { ReactNode } from 'react';

import { Header } from '@abb-emobility/oms/customer-ui-primitive';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { OrderFooter } from '../order-footer/OrderFooter';
import { useTheme } from '../theme/Theme.context';

import './OrderViewLayout.scss';

export type OrderViewLayoutProps = {
	orderId: ModelPrimaryKey,
	children?: ReactNode
};

export function OrderViewLayout(props: OrderViewLayoutProps) {

	const { orderId, children } = props;

	const theme = useTheme();

	return (
		<article className="order-view-layout">
			<header className="order-view-layout__header">
				<div className="order-view-layout__header__dimension">
					<Header caption={theme.caption()} />
				</div>
			</header>
			<main className="order-view-layout__main">
				<div className="order-view-layout__main__dimension">
					{children}
				</div>
			</main>
			<OrderFooter orderId={orderId} />
		</article>
	);
}
