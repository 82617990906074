import React, { ReactNode, useState } from 'react';

import { DeltaQuotationItems } from '@abb-emobility/oms/customer-ui-domain';
import {
	ButtonBar,
	ButtonBarSection,
	ButtonBarSectionAlign,
	ButtonGhost,
	ButtonPrimary,
	ButtonTertiary,
	Form,
	FormSection,
	FormSegement,
	InputCheckbox,
	Separator,
	SpinnerCable,
	SuccessFeeback
} from '@abb-emobility/oms/customer-ui-primitive';
import { FilterCriteria, SingleValueFilterComparator } from '@abb-emobility/shared/api-integration-foundation';
import { scrollWindowToTop } from '@abb-emobility/shared/browser';
import { getCurrencyByCode } from '@abb-emobility/shared/currency';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskEntitySearchDataProvider, useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	AnyTaskModel,
	QuotationAcceptanceCancellation,
	QuotationAcceptancePayloadModel,
	QuotationAcceptanceTaskModel,
	TaskType
} from '@abb-emobility/usertask/domain-model';

import { CancellationDialogue, CancellationDialogueSelection } from '../../../cancellation-dialogue/CancellationDialogue';
import { TaskSearchResolver } from '../TaskSearchResolver';

import './TaskQuotationAcceptance.scss';

export type TaskQuotationAcceptanceProps = {
	task: QuotationAcceptanceTaskModel
};

function SearchForPaymentPending() {

	const l10n = useL10n();

	return (
		<main className="task-quotation-acceptance__feedback__main">
			<SpinnerCable />
			<h1 className="task-quotation-acceptance__feedback__main__heading">
				{l10n.translate('omsCustomerApp.task.quotationAcceptance.completionSuccess.heading')}
			</h1>
			<p className="task-quotation-acceptance__feedback__main__description">
				{l10n.translate('omsCustomerApp.task.quotationAcceptance.completionSuccess.message')}
			</p>
		</main>
	);
}

export function TaskQuotationAcceptance(props: TaskQuotationAcceptanceProps) {

	const { task } = props;

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const actionStatus = anyTaskEntityData.queryActionStatus();

	const [taskPayload, setTaskPayload] = useState<QuotationAcceptancePayloadModel>(task.payload);

	const currency = getCurrencyByCode(task.payload.quotation.currencyCode);

	if (currency === null) {
		throw new AppError('Currency for code ' + task.payload.quotation.currencyCode + ' unresolvable');
	}

	const handleCancellation = (): void => {
		setTaskPayload({ ...taskPayload, accepted: false });
		scrollWindowToTop('auto');
	};

	const handleRecoverFromCancellation = (): void => {
		setTaskPayload({ ...taskPayload, accepted: undefined });
		scrollWindowToTop('auto');
	};

	const handleCancellationSelect = (selection: Nullable<CancellationDialogueSelection>) => {
		const userSelection = selection ? selection as QuotationAcceptanceCancellation : undefined;
		setTaskPayload({ ...taskPayload, cancellation: userSelection });
	};

	const handleAcceptance = (acceptance: boolean): void => {
		setTaskPayload({ ...taskPayload, accepted: acceptance || undefined });
	};

	const handleSubmit = (): void => {
		anyTaskEntityData.complete(task, taskPayload);
	};

	const renderPayload = (): ReactNode => {
		switch (actionStatus) {
			case TaskActionStatus.COMPLETE_PENDING:
				return (<SpinnerCable />);
			case TaskActionStatus.COMPLETE_SUCCESS:
				if (taskPayload.accepted) {
					const filterCriteria: FilterCriteria<AnyTaskModel> = [
						{
							criteria: [
								{
									comparator: SingleValueFilterComparator.EQUAL,
									property: 'orderId',
									value: task.orderId
								},
								{
									comparator: SingleValueFilterComparator.EQUAL,
									property: 'type',
									value: TaskType.CUSTOMER_PERFORM_PAYMENT
								}
							],
							id: 'task'
						}
					];

					return (
						<div className="task-quotation-acceptance__feedback">
							<AnyTaskEntitySearchDataProvider
								filterCriteria={filterCriteria}
								pendingComponent={SearchForPaymentPending}
							>
								<TaskSearchResolver taskProps={{ initialPayment: true }} />
							</AnyTaskEntitySearchDataProvider>
						</div>
					);
				} else {
					let cancellationSuccessHeading = '';
					let cancellationSuccessMessage = '';
					switch (taskPayload.cancellation) {
						case QuotationAcceptanceCancellation.CANCEL_INSTALLATION:
							cancellationSuccessHeading = 'omsCustomerApp.task.quotationAcceptance.completionCancelInstallationSuccess.heading';
							cancellationSuccessMessage = 'omsCustomerApp.task.quotationAcceptance.completionCancelInstallationSuccess.message';
							break;
						case QuotationAcceptanceCancellation.CANCEL_ORDER:
							cancellationSuccessHeading = 'omsCustomerApp.task.quotationAcceptance.completionCancelOrderSuccess.heading';
							cancellationSuccessMessage = 'omsCustomerApp.task.quotationAcceptance.completionCancelOrderSuccess.message';
							break;
					}
					return (
						<SuccessFeeback
							heading={l10n.translate(cancellationSuccessHeading)}
							message={l10n.translate(cancellationSuccessMessage)}
						/>
					);
				}
			default: {
				let formContent: ReactNode;
				if (taskPayload.accepted === false) {
					formContent = (
						<>
							<CancellationDialogue
								heading={l10n.translate('omsCustomerApp.task.quotationAcceptance.cancellationCaption')}
								description={l10n.translate('omsCustomerApp.task.quotationAcceptance.cancellationMessage')}
								selected={taskPayload.cancellation}
								onSelect={handleCancellationSelect}
								installationCancellable={taskPayload.installationCancelable ?? false}
							/>
							<Separator />
							<ButtonBar>
								<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_LEFT}>
									<ButtonTertiary
										label={l10n.translate('omsCustomerApp.task.quotationAcceptance.recoverCancellationButtonLabel')}
										onClick={handleRecoverFromCancellation}
									/>
								</ButtonBarSection>
								<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
									<ButtonPrimary
										type="submit"
										label={l10n.translate('omsCustomerApp.task.quotationAcceptance.submitCancellationButtonLabel')}
										disabled={taskPayload.cancellation === undefined}
									/>
								</ButtonBarSection>
							</ButtonBar>
						</>
					);
				} else {
					const terms: string = l10n.translate('omsCustomerApp.task.quotationAcceptance.acceptance.terms');
					const acceptance: string = l10n.translate('omsCustomerApp.task.quotationAcceptance.acceptance.label');
					const acceptanceParts = acceptance.split('{{termsLink}}');
					const checkboxLabel: React.JSX.Element = (
						<span>
							{acceptanceParts.shift()}
							<a href="/static/terms">{terms}</a>
							{acceptanceParts.shift()}
						</span>
					);
					formContent = (
						<>
							<FormSegement
								heading={l10n.translate('omsCustomerApp.task.quotationAcceptance.caption')}
								description={l10n.translate('omsCustomerApp.task.quotationAcceptance.message')}
							>
								<FormSection>
									<DeltaQuotationItems quotation={task.payload.quotation} currency={currency} />
								</FormSection>
							</FormSegement>
							<Separator />
							<FormSegement
								heading={l10n.translate('omsCustomerApp.task.quotationAcceptance.conditions.caption')}
								description={l10n.translate('omsCustomerApp.task.quotationAcceptance.conditions.message')}
							>
								<FormSection>
									<p className="task-quotation-acceptance__conditions">{task.payload.quotation.quotationConditions}</p>
								</FormSection>
							</FormSegement>
							<Separator />
							<FormSegement>
								<FormSection>
									<InputCheckbox
										label={checkboxLabel}
										value="true"
										onChange={handleAcceptance}
									/>
								</FormSection>
							</FormSegement>
							<Separator />
							<ButtonBar>
								<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_CENTER}>
									<ButtonPrimary
										type="submit"
										label={l10n.translate('omsCustomerApp.task.quotationAcceptance.submitButtonLabel')}
										disabled={taskPayload.accepted !== true}
									/>
									<ButtonGhost
										label={l10n.translate('omsCustomerApp.task.quotationAcceptance.cancelButtonLabel')}
										onClick={handleCancellation}
									/>
								</ButtonBarSection>
							</ButtonBar>
						</>
					);
				}
				return (
					<Form onSubmit={handleSubmit}>
						{formContent}
					</Form>
				);
			}
		}
	};

	return (
		<>
			{renderPayload()}
		</>
	);

}
