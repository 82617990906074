/* eslint-disable @typescript-eslint/no-explicit-any */
import { QuotationModel, QuotationModelConverter } from '@abb-emobility/shared/domain-model';
import {
	ModelConverter,
	ModelConverterInterface
} from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type PaymentPayloadModel = {
	// TODO: Define as mandatory after the backend has integrated the member; see https://jira.fiege.com/browse/ABB-1078
	readonly quotation?: QuotationModel,
} & TaskPayloadFoundationModel;

export class PaymentPayloadModelConverter extends ModelConverter<PaymentPayloadModel> {

	override getFieldConverterMapByModel(): Map<keyof PaymentPayloadModel, ModelConverterInterface<QuotationModel>> {
		return new Map([
			['quotation', new QuotationModelConverter()]
		]);
	}
}

export type PaymentEscalationPayloadModel =
	PaymentPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

export class PaymentEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<PaymentEscalationPayloadModel> {
	private payloadModelConverter = new PaymentPayloadModelConverter();

	override getFieldConverterMapByModel(): Map<keyof PaymentEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
