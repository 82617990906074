import { DownloadFileDto, DownloadFileMetaDataDto, DownloadFile, DownloadFileMetaData } from './DownloadFile';
import { isUrlDto, isUrl } from './Url.guards';

export const isDownloadFile = (file?: unknown): file is DownloadFile => {
	if ((file ?? null) === null) {
		return false;
	}
	return (file as DownloadFile).discriminator === 'DownloadFile'
		&& isUrl((file as DownloadFile).url)
		&& isDownloadFileMetaData((file as DownloadFile).meta);
};

export const isDownloadFileDto = (fileDto?: unknown): fileDto is DownloadFileDto => {
	if ((fileDto ?? null) === null) {
		return false;
	}
	return isUrlDto((fileDto as DownloadFileDto).url)
		&& isDownloadFileMetaDataDto((fileDto as DownloadFileDto).meta);
};

export const isDownloadFileMetaData = (fileMetaData?: unknown): fileMetaData is DownloadFileMetaData => {
	if ((fileMetaData ?? null) === null) {
		return false;
	}
	return (fileMetaData as DownloadFileMetaData).fileName !== undefined
		&& (fileMetaData as DownloadFileMetaData).mimeType !== undefined;
};

export const isDownloadFileMetaDataDto = (fileMetaDataDto?: unknown): fileMetaDataDto is DownloadFileMetaDataDto => {
	if ((fileMetaDataDto ?? null) === null) {
		return false;
	}
	return (fileMetaDataDto as DownloadFileMetaDataDto).fileName !== undefined
		&& (fileMetaDataDto as DownloadFileMetaDataDto).mimeType !== undefined;
};
