import { ReactNode } from 'react';

import { ButtonGhost, ErrorFeedback, ErrorFeedbackAction, ErrorFeedbackActionWeight, SuccessFeeback } from '@abb-emobility/oms/customer-ui-primitive';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useDialogue } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';

import { useBuildPageTitle } from '../../hooks/PageTitle.hook';
import { usePaymentRedirect } from '../../hooks/PaymentRedirectHook';
import { PaymentResultRouteParam } from '../../router/Routes';
import { CustomerServiceDialogue } from '../dialogue/customer-service-dialogue/CustomerServiceDialogue';

import './PaymentResult.scss';

export type PaymentResultProps = {
	orderId: ModelPrimaryKey,
	paymentResult: PaymentResultRouteParam
};

export function PaymentResult(props: PaymentResultProps) {

	const { orderId, paymentResult } = props;

	const l10n = useL10n();
	const paymentRedirect = usePaymentRedirect(orderId);
	const dialogue = useDialogue();

	usePageTitle(useBuildPageTitle('omsCustomerApp.pageTitle.payment'));

	const handleRetry = () => {
		paymentRedirect((error) => {
			throw error;
		});
	};

	const renderPaymentResult = (): ReactNode => {
		switch (paymentResult) {
			case PaymentResultRouteParam.PAYMENT_SUCCESS:
				return (
					<SuccessFeeback
						heading={l10n.translate('omsCustomerApp.payment.success.heading')}
						message={l10n.translate('omsCustomerApp.payment.success.message')}
					/>
				);
			case PaymentResultRouteParam.PAYMENT_FAILED: {
				const retryAction: ErrorFeedbackAction = {
					label: l10n.translate('omsCustomerApp.payment.failed.retryLabel'),
					onInvoke: handleRetry,
					weight: ErrorFeedbackActionWeight.PRIMARY
				};
				return (
					<ErrorFeedback
						heading={l10n.translate('omsCustomerApp.payment.failed.heading')}
						actions={[retryAction]}
					/>
				);
			}
		}
	};

	const handleHelpRequest = (): void => {
		dialogue.present(() => {
			return (<CustomerServiceDialogue orderId={orderId} />);
		});
	};

	const renderFooter = (): ReactNode => {
		if (paymentResult === PaymentResultRouteParam.PAYMENT_SUCCESS) {
			return null;
		}
		return (
			<footer className="payment-result__footer">
				<ButtonGhost
					label={l10n.translate('omsCustomerApp.customerServiceDialogue.dialogueTriggerLabel')}
					onClick={handleHelpRequest}
				/>
			</footer>
		);
	};

	return (
		<article className="payment-result">
			<main className="payment-result__main">
				{renderPaymentResult()}
			</main>
			{renderFooter()}
		</article>
	);
}
