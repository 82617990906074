/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CustomerModel, CustomerModelConverter,
	InstallationPeriod,
	InstallationTradeModel,
	InstallationTradeModelConverter,
	InstallerModel,
	numericApiSafeguard,
	TradeAppointment,
	TradeAppointmentConverter
} from '@abb-emobility/shared/domain-model';
import {
	ArrayOfUploadFileFieldNames,
	CustomConversion,
	ModelConverter,
	ModelConverterInterface,
	UploadFileFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type RequestedTradeEffort = {
	trade: InstallationTradeModel,
	availableInstallers: Array<InstallerModel>,
	expectedEffort: number,
	installationPeriod: InstallationPeriod
};

class RequestedTradeEffortConverter extends ModelConverter<RequestedTradeEffort> {

	override getFieldConverterMapByModel(): Map<keyof RequestedTradeEffort, ModelConverterInterface<any>> {
		return new Map<keyof RequestedTradeEffort, ModelConverterInterface<any>>([
			['trade', new InstallationTradeModelConverter()]
		]);
	}

	override getCustomConversionFields(): Map<keyof RequestedTradeEffort, CustomConversion> {
		return new Map([
			['expectedEffort', numericApiSafeguard]
		]);
	}
}

export enum ArrangeAppointmentInstallationType {
	INITIAL = 'INITIAL',
	REPLACEMENT = 'REPLACEMENT'
}

export enum ArrangeAppointmentAppointmentType {
	INITIAL = 'INITIAL',
	RESCHEDULED = 'RESCHEDULED'
}

export type ArrangeAppointmentPayloadModel = {
	readonly customer: CustomerModel,
	readonly requestedTrades: Array<RequestedTradeEffort>,
	// ANNOTATION: The earliest installation date is calculated following those rules:
	//  - Earliest at the next working day (handled by the frontend)
	//  - Earliest two working days after the planned delivery (hand over) of the wallbox
	//  - Earliest 10 calendar days before the planned delivery of the car if provided by OEM
	readonly earliestInstallationDate: Timestamp,
	readonly installationType?: ArrangeAppointmentInstallationType,
	readonly appointmentType?: ArrangeAppointmentAppointmentType,
	tradeAppointments?: Array<TradeAppointment>
} & TaskPayloadFoundationModel;

export class ArrangeAppointmentPayloadModelConverter extends ModelConverter<ArrangeAppointmentPayloadModel> {

	override getTimestampFields(): Array<TimestampFieldNames<ArrangeAppointmentPayloadModel> | ArrayOfTimestampFieldNames<ArrangeAppointmentPayloadModel>> {
		return ['earliestInstallationDate'];
	}

	override getFieldConverterMapByModel(): Map<keyof ArrangeAppointmentPayloadModel, ModelConverterInterface<any>> {
		return new Map<
			keyof ArrangeAppointmentPayloadModel,
			ModelConverterInterface<CustomerModel> |
			ModelConverterInterface<RequestedTradeEffort> |
			ModelConverterInterface<TradeAppointment>
		>([
			['customer', new CustomerModelConverter()],
			['requestedTrades', new RequestedTradeEffortConverter()],
			['tradeAppointments', new TradeAppointmentConverter()]
		]);
	}
}

export type ArrangeAppointmentEscalationPayloadModel =
	ArrangeAppointmentPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

export class ArrangeAppointmentEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<ArrangeAppointmentEscalationPayloadModel> {
	private payloadModelConverter = new ArrangeAppointmentPayloadModelConverter();

	override getUploadFileFields(): Array<UploadFileFieldNames<ArrangeAppointmentEscalationPayloadModel> | ArrayOfUploadFileFieldNames<ArrangeAppointmentEscalationPayloadModel>> {
		return this.payloadModelConverter.getUploadFileFields();
	}

	override getFieldConverterMapByModel(): Map<keyof ArrangeAppointmentEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
