import { InstallationPartnerOrderModel, InstallationPartnerOrderModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class InstallationPartnerOrderApiClient extends AbstractCrudApiClient<InstallationPartnerOrderModel> {
	protected collectionControllerUri = 'installation-partner/orders/';
	protected paginationControllerUri = 'installation-partner/orders/';
	protected entityControllerUri = 'installation-partner/order/{id}/';
	protected entitySearchControllerUri = 'installation-partner/order/';
	protected modelConverter = new InstallationPartnerOrderModelConverter();
}
