import { createContext } from 'react';

import { AppError } from '@abb-emobility/shared/error';
import { Optional } from '@abb-emobility/shared/util';

import { AccessToken, JsonWebToken, RefreshToken } from '../foundation/JsonWebToken';

export type AuthContextValue = {
	getScope(): string | undefined,
	authenticate(accessToken: AccessToken, accessTokenValidTo: Date, refreshToken?: RefreshToken, refreshTokenValidTo?: Date): void,
	update(accessToken: AccessToken, accessTokenValidTo: Date, refreshToken?: RefreshToken, refreshTokenValidTo?: Date): void,
	reauthenticate(): void,
	unauthenticate(): void,
	getToken(): Optional<JsonWebToken>,
	isAuthenticated(): boolean,
	needsUpdate(): boolean,
	hasRole(role: string): boolean
};

export const authContext = createContext<AuthContextValue>({
	getScope: (): string | undefined => {
		throw new AppError('No auth context provided');
	},
	authenticate: (_accessToken: AccessToken, _accessTokenValidTo: Date, _refreshToken?: RefreshToken, _refreshTokenValidTo?: Date): void => {
		throw new AppError('No auth context provided');
	},
	update: (_accessToken: AccessToken, _accessTokenValidTo: Date, _refreshToken?: RefreshToken, _refreshTokenValidTo?: Date): void => {
		throw new AppError('No auth context provided');
	},
	reauthenticate: (): void => {
		throw new AppError('No auth context provided');
	},
	unauthenticate: (): void => {
		throw new AppError('No auth context provided');
	},
	getToken: (): Optional<JsonWebToken> => {
		throw new AppError('No auth context provided');
	},
	isAuthenticated: (): boolean => {
		throw new AppError('No auth context provided');
	},
	needsUpdate: (): boolean => {
		throw new AppError('No auth context provided');
	},
	hasRole: (_role: string): boolean => {
		throw new AppError('No auth context provided');
	}
});
