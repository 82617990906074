import { ReactNode } from 'react';

import { useCustomerOrderEntityData } from '@abb-emobility/oms/data-provider';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { removeWhitespace } from '@abb-emobility/shared/util';

import './CustomerServiceDialogueContact.scss';

export function CustomerServiceDialogueContact() {

	const l10n = useL10n();

	const customerOrderData = useCustomerOrderEntityData();
	const customerOrder = customerOrderData.query().getOrThrow(new AppError('Order not found'));

	const phoneLink = 'tel:' + removeWhitespace(customerOrder.marketplace.customerServiceHotline);
	let mailLink = 'mailto:' + customerOrder.marketplace.customerServiceMailAddress;
	mailLink += '?subject=';
	mailLink += l10n.translate('omsCustomerApp.customerServiceDialogue.mail.subject', new Map([['marketplaceId', customerOrder.marketplaceOrderId]]));

	const renderInformationUrl = (): ReactNode => {
		if (!customerOrder.marketplace.customerServiceInformationUrl) {
			return null;
		}
		return (
			<section className="customer-service-dialogue-contact__section">
				<h2>{l10n.translate('omsCustomerApp.customerServiceDialogue.infoPage.heading')}</h2>
				<p><a href={customerOrder.marketplace.customerServiceInformationUrl} target="_blank" rel="noopener noreferrer">
					{l10n.translate('omsCustomerApp.customerServiceDialogue.infoPage.label')}
				</a></p>
			</section>
		);
	};

	return (
		<div className="customer-service-dialogue-contact">
			<section className="customer-service-dialogue-contact__section">
				<h2>{l10n.translate('omsCustomerApp.customerServiceDialogue.phone.heading')}</h2>
				<p><a href={phoneLink}>{customerOrder.marketplace.customerServiceHotline}</a></p>
			</section>
			<section className="customer-service-dialogue-contact__section">
				<h2>{l10n.translate('omsCustomerApp.customerServiceDialogue.mail.heading')}</h2>
				<p><a href={mailLink}>{customerOrder.marketplace.customerServiceMailAddress}</a></p>
			</section>
			{renderInformationUrl()}
		</div>
	);

}
