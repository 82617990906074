import { ReactNode } from 'react';

import { Icon, IconIdentifier, Separator } from '@abb-emobility/oms/customer-ui-primitive';
import { calculateMajorUnitFromMinorUnit, Currency, getCurrencySymbolByCurrency } from '@abb-emobility/shared/currency';
import { CustomerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import './DeltaQuotationItem.scss';

export type DeltaQuotationItemProps = {
	deltaInstallation: CustomerDeltaInstallationPackageModel,
	currency: Currency
};

export function DeltaQuotationItem(props: DeltaQuotationItemProps) {

	const { deltaInstallation, currency } = props;

	const l10n = useL10n();

	const renderDescription = (): ReactNode => {
		if (deltaInstallation.description === undefined) {
			return;
		}
		return (
			<p className="delta-quotation-item__header__description">
				{deltaInstallation.description}
			</p>
		);
	};

	const renderItems = (): ReactNode => {
		return (deltaInstallation.includedGoodsPublic ?? []).map((item, index) => {
			return (
				<section className="delta-quotation-item__main__item" key={index}>
					<Icon name={IconIdentifier.CHECK} size={'small'} />
					<div className="delta-quotation-item__main__item__label">
						{item}
					</div>
				</section>
			);
		});
	};

	return (
		<article className="delta-quotation-item">
			<header className="delta-quotation-item__header">
				<h1 className="delta-quotation-item__header__heading">
					{deltaInstallation.name}
				</h1>
				{renderDescription()}
			</header>

			<Separator />

			<main className="delta-quotation-item__main">
				{renderItems()}
			</main>

			<Separator />

			<footer className="delta-quotation-item__footer">
				<div className="delta-quotation-item__footer__price">
					{l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, deltaInstallation.salesGrossPrice), currency.fraction ?? 0)}
					&nbsp;
					{getCurrencySymbolByCurrency(currency)}
				</div>
			</footer>
		</article>
	);

}
