export enum IconIdentifier {
	CARET_LEFT = 'caret-left',
	CARET_UP = 'caret-up',
	CARET_RIGHT = 'caret-right',
	CARET_DOWN = 'caret-down',
	CHECK = 'check',
	CALENDAR = 'calendar',
	CALENDAR_CHECKED = 'calendar-checked',
	CLOCK = 'clock',
	CIRCLE = 'circle',
	CROSS = 'cross',
	DOWNLOAD = 'download',
	INFO = 'info',
	PHONE_CALL = 'phone-call',
	VIDEO_CAMERA = 'video-camera',
	SPINNER = 'spinner'
}
