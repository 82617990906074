import {
	ArrayOfDateRangeFieldNames,
	DateRange,
	DateRangeFieldNames,
	ModelConverter,
	ModelConverterInterface
} from '@abb-emobility/shared/domain-model-foundation';

export type CustomerTradeAppointmentModel = {
	trade: string,
	periods: Array<DateRange>
};

export class CustomerTradeAppointmentModelConverter extends ModelConverter<CustomerTradeAppointmentModel> {

	override getDateRangeFields(): Array<DateRangeFieldNames<CustomerTradeAppointmentModel> | ArrayOfDateRangeFieldNames<CustomerTradeAppointmentModel>> {
		return ['periods'];
	}

}

export type CustomerOrderInformationAppointmentInformationModel = {
	readonly appointments: Array<CustomerTradeAppointmentModel>
};

export class CustomerOrderInformationAppointmentInformationModelConverter extends ModelConverter<CustomerOrderInformationAppointmentInformationModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof CustomerOrderInformationAppointmentInformationModel, ModelConverterInterface<any>> {
		return new Map([
			['appointments', new CustomerTradeAppointmentModelConverter()]
		]);
	}

}
