import { CustomConversion, ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { DeltaInstallationPackageFoundationModel } from './foundation/DeltaInstallationPackageFoundationModel';
import { numericApiSafeguard } from '../converter/NumericSafeGuard';


export type CustomerDeltaInstallationPackageModel = {
	readonly salesGrossPrice: number
} & DeltaInstallationPackageFoundationModel;

export class CustomerDeltaInstallationPackageModelConverter extends ModelConverter<CustomerDeltaInstallationPackageModel> {

	override getCustomConversionFields(): Map<keyof CustomerDeltaInstallationPackageModel, CustomConversion> {
		return new Map([
			['salesGrossPrice', numericApiSafeguard]
		]);
	}

}
