import { Fragment, ReactNode } from 'react';

import { FormSection, Notification, Separator } from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { QuestionnaireSection } from '@abb-emobility/shared/questionnaire';

import { useQuestionnaire } from '../Questionnaire.context';
import { QuestionFactory } from '../question/QuestionFactory';

export function Sections() {
	const l10n = useL10n();
	const questionnaire = useQuestionnaire();
	const segment = questionnaire.getCurrentSegment();
	const answers = questionnaire.getAnswers();

	const renderHint = (section: QuestionnaireSection): ReactNode => {
		if (section.hint === undefined) {
			return;
		}
		const localizedMessage = l10n.translate('questionnaire.' + section.hint, undefined, section.hint);
		return (
			<Notification message={localizedMessage} key={section.key + '.hint'} />
		);
	};

	const renderQuestions = (
		segmentKey: string,
		section: QuestionnaireSection
	): ReactNode => {
		return section.questions.map((question): ReactNode => {
			return (
				<QuestionFactory
					keyPrefix={segmentKey + '.' + section.key}
					question={question}
					answers={answers}
					key={segmentKey + '.' + section.key + '.' + question.key}
				/>
			);
		});
	};

	const sections = segment.sections.map((section): ReactNode => {
		const localizedHeading = l10n.translate('questionnaire.' + section.heading, undefined, section.heading);
		const localizedDescription = section.description
			? l10n.translate('questionnaire.' + section.description, undefined, section.description)
			: undefined;
		return (
			<Fragment key={segment.key + '.' + section.key}>
				<FormSection
					heading={localizedHeading}
					description={localizedDescription}
				>
					{renderHint(section)}
					{renderQuestions(segment.key, section)}
				</FormSection>
				<Separator />
			</Fragment>
		);
	});

	return <Fragment key={segment.key + '.sections'}>{sections}</Fragment>;
}
