/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddressModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { TaskFoundationModel } from './foundation/TaskFoundationModel';
import { TaskType } from './foundation/TaskType';

export type AnyTaskCollectionItemModel = {
	type: TaskType
} & TaskFoundationModel;

export class AnyTaskCollectionItemModelConverter extends ModelConverter<AnyTaskCollectionItemModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyTaskCollectionItemModel> | ArrayOfTimestampFieldNames<AnyTaskCollectionItemModel>> {
		return ['updatedOn', 'dueDate'];
	}

	override getFieldConverterMapByModel(): Map<keyof AnyTaskCollectionItemModel, ModelConverterInterface<any>> {
		return new Map<keyof AnyTaskCollectionItemModel, ModelConverterInterface<any>>([
			['installationAddress', new AddressModelConverter()]
		]);
	}

}
