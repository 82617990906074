import { JsonRestRequestFactory } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { OtpAuthApiClientInterface } from './OtpAuthApiClientInterface';

export type LoginOtpExchangeResponseModel = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	access_token: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	expires_in: number
};

export class OtpAuthApiClient implements OtpAuthApiClientInterface {

	private readonly restApiUrl: string;

	constructor(apiBaseUrl: string, orderId: ModelPrimaryKey) {
		this.restApiUrl = apiBaseUrl + 'login/' + orderId;
	}

	async requestOtp(): Promise<void> {
		await JsonRestRequestFactory.create().get(this.restApiUrl);
	}

	async resolveOtp(otp: Nullable<string>): Promise<LoginOtpExchangeResponseModel> {
		const response = await JsonRestRequestFactory.create().post(this.restApiUrl, { oneTimePassword: otp });
		return response.body as LoginOtpExchangeResponseModel;
	}

}
