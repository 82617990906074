import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerOrderDocumentModel = {
	readonly id: ModelPrimaryKey,
	readonly document: DownloadFile
};

export class CustomerOrderDocumentModelConverter extends ModelConverter<CustomerOrderDocumentModel> {

	override getDownloadFileFields(): Array<DownloadFileFieldNames<CustomerOrderDocumentModel> | ArrayOfDownloadFileFieldNames<CustomerOrderDocumentModel>> {
		return ['document'];
	}
}
