import { camelCase, trimFromLeft, trimFromRight } from './String';

export const l10nLiteralFromEnumValue = (enumValue: string, prefix?: string, suffix?: string): string => {
	prefix = prefix ? trimFromRight(prefix, '.') + '.' : '';
	suffix = suffix ? '.' + trimFromLeft(suffix, '.') : '';
	return prefix + camelCase(enumValue) + suffix;
};

export const l10nLiteralFromModelMember = (memberKey: string, prefix?: string, suffix?: string): string => {
	prefix = prefix ? trimFromRight(prefix, '.') + '.' : '';
	suffix = suffix ? '.' + trimFromLeft(suffix, '.') : '';
	return prefix + memberKey + suffix;
};
