import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	TaskEntityApiClient,
	TaskAssignResponse,
	TaskCompleteResponse,
	HypermediaEntityResponse,
	FilterCriteria
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, ModelPrimaryKey, TaskEntityModel, TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { createErrorObject } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { TaskEntitySearchStore } from './TaskEntitySearchStore';
import { FetchStatus } from '../FetchStatus';
import { ThunkApiConfig } from '../ThunkApiConfig';

export const createTaskSearchEntityThunk = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, Store extends Record<string, TaskEntitySearchStore<TaskModel>>, ApiClient extends TaskEntityApiClient<TaskModel, Payload>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, filter: FilterCriteria<TaskModel> }, ThunkApiConfig>(
		storeName + '/searchEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).searchEntity(params.filter);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (_params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				return store.searchStatus !== FetchStatus.PENDING;
			}
		}
	);
};

export const createTaskSearchCompleteThunk = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, ApiClient extends TaskEntityApiClient<TaskModel, Payload>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<Nullable<TaskCompleteResponse<TaskModel>>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, model: TaskModel, payload: Mutable<Payload> }, ThunkApiConfig>(
		storeName + '/complete',
		async (params, thunkApi) => {
			try {
				const taskCompleteApiResponse = await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).complete(params.model.id, params.model, params.payload);
				const taskCompleteResponse = { ...taskCompleteApiResponse, payload: params.payload };
				return taskCompleteResponse as TaskCompleteResponse<TaskModel>;
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};

export const createTaskSearchAssignThunk = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, ApiClient extends TaskEntityApiClient<TaskModel, Payload>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<Nullable<TaskAssignResponse>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, model: TaskModel, candidateGroupIds: Array<ModelPrimaryKey> }, ThunkApiConfig>(
		storeName + '/assign',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).assign(params.model.id, params.candidateGroupIds);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};
