import { ReactElement, useState } from 'react';

import {
	InputCheckbox,
	InputCheckboxGroup,
	InputFile,
	InputSelect,
	InputSelectOption,
	InputText,
	InputTextArea,
	Notification,
	NotificationLevel
} from '@abb-emobility/oms/customer-ui-primitive';
import { createAccessibleUrlFromUrlString } from '@abb-emobility/shared/domain-model-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { formatFileSize } from '@abb-emobility/shared/formatter';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Answers, AnswerTypeId, AnyQuestion } from '@abb-emobility/shared/questionnaire';
import { Nullable } from '@abb-emobility/shared/util';

export type QuestionFactoryProps = {
	keyPrefix: string,
	question: AnyQuestion,
	answers: Answers
};

const MAX_UPLOAD_FILE_SIZE = 5 * 1024 * 1024;

export function QuestionFactory(props: QuestionFactoryProps) {
	const { keyPrefix, question, answers } = props;

	const l10n = useL10n();

	const [notification, setNotification] = useState<Nullable<string>>(null);

	const questionKey = keyPrefix + '.' + question.key;
	const answerValue = answers[questionKey] ?? undefined;

	const handleFileSizeExceeded = (exceeded: boolean): void => {
		if (exceeded) {
			const maxFileSize = formatFileSize(MAX_UPLOAD_FILE_SIZE);
			setNotification(l10n.translate('omsCustomerUiQuestionnaire.questionnaire.selectedFileTooLarge', new Map([['maxFileSize', maxFileSize]])));
		} else {
			setNotification(null);
		}
	};

	const renderNotification = (): Nullable<ReactElement> => {
		if (notification !== null) {
			return (
				<Notification message={notification} level={NotificationLevel.ERROR} preventClickHandling={true} />
			);
		}
		if (question.pictureHint) {
			return (
				<Notification
					message={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.pictureSelectHint.examplePicture')}
					level={NotificationLevel.INFO}
					preventClickHandling={true}
					picture={{
						url: createAccessibleUrlFromUrlString(question.pictureHint.url),
						title: l10n.translate('questionnaire.' + question.pictureHint.title)
					}}
				/>
			);
		}
		if (question.hint) {
			return (
				<Notification
					message={l10n.translate('questionnaire.' + question.hint)}
					level={NotificationLevel.INFO}
					preventClickHandling={true}
				/>
			);
		}
		return null;
	};

	const localizedQuestion = l10n.translate('questionnaire.' + question.question, undefined, question.question);

	switch (question.answerType.type) {
		case AnswerTypeId.TEXT: {
			const answerTypeOptions = question.answerType.options;
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: undefined;
			if (answerTypeOptions.multiline) {
				return (
					<InputTextArea
						label={localizedQuestion}
						notificationElement={renderNotification()}
						name={questionKey}
						defaultValue={answerValue as string}
						placeholder={localizedPlaceholder}
						minLength={answerTypeOptions.minLength}
						maxLength={answerTypeOptions.maxLength}
						required={answerTypeOptions.required}
					/>
				);
			}
			return (
				<InputText
					type="text"
					label={localizedQuestion}
					notificationElement={renderNotification()}
					name={questionKey}
					defaultValue={answerValue as string}
					placeholder={localizedPlaceholder}
					minLength={answerTypeOptions.minLength}
					maxLength={answerTypeOptions.maxLength}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.MAIL_ADDRESS: {
			const answerTypeOptions = question.answerType.options;
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: undefined;
			return (
				<InputText
					type="email"
					label={localizedQuestion}
					notificationElement={renderNotification()}
					name={questionKey}
					defaultValue={answerValue as string}
					placeholder={localizedPlaceholder}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.PHONE: {
			const answerTypeOptions = question.answerType.options;
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: undefined;
			return (
				<InputText
					type="tel"
					label={localizedQuestion}
					notificationElement={renderNotification()}
					name={questionKey}
					defaultValue={answerValue as string}
					placeholder={localizedPlaceholder}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.NUMBER: {
			const answerTypeOptions = question.answerType.options;
			const precision = answerTypeOptions.precision ?? 0;
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: undefined;
			return (
				<InputText
					type="number"
					label={localizedQuestion}
					notificationElement={renderNotification()}
					name={questionKey}
					defaultValue={answerValue as string}
					placeholder={localizedPlaceholder}
					min={answerTypeOptions.minValue}
					max={answerTypeOptions.maxValue}
					step={1 / Math.pow(10, precision)}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.PICTURE: {
			const answerTypeOptions = question.answerType.options;
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: l10n.translate('omsCustomerUiQuestionnaire.questionnaire.pictureSelectPlaceholder');

			let notification = renderNotification();
			if (notification === null) {
				notification = (
					<Notification
						message={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.pictureSelectHint.default')}
						level={NotificationLevel.INFO}
						preventClickHandling={true}
					/>
				);
			}

			return (
				<InputFile
					label={localizedQuestion}
					notificationElement={notification}
					triggerLabel={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.pictureSelectTrigger')}
					name={questionKey}
					capture={undefined}
					accept="image/*"
					placeholder={localizedPlaceholder}
					maxFileSize={MAX_UPLOAD_FILE_SIZE}
					onMaxFileSizeExceeded={handleFileSizeExceeded}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.DOCUMENT: {
			const answerTypeOptions = question.answerType.options;
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: l10n.translate('omsCustomerUiQuestionnaire.questionnaire.documentSelectPlaceholder');

			let notification = renderNotification();
			if (notification === null) {
				notification = (
					<Notification
						message={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.pictureSelectHint.default')}
						level={NotificationLevel.INFO}
						preventClickHandling={true}
					/>
				);
			}

			return (
				<InputFile
					label={localizedQuestion}
					notificationElement={notification}
					triggerLabel={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.documentSelectTrigger')}
					name={questionKey}
					placeholder={localizedPlaceholder}
					maxFileSize={MAX_UPLOAD_FILE_SIZE}
					onMaxFileSizeExceeded={handleFileSizeExceeded}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.SINGLE_SELECT: {
			const answerTypeOptions = question.answerType.options;
			const selectOptions = answerTypeOptions.options.map(
				(option): InputSelectOption => {
					const localizedLabel = l10n.translate('questionnaire.' + option.label, undefined, option.label);
					return {
						label: localizedLabel,
						value: option.value
					};
				}
			);
			const localizedPlaceholder = answerTypeOptions.placeholder
				? l10n.translate('questionnaire.' + answerTypeOptions.placeholder, undefined, answerTypeOptions.placeholder)
				: l10n.translate('omsCustomerUiQuestionnaire.questionnaire.singleSelectPlaceholder');
			return (
				<InputSelect
					label={localizedQuestion}
					notificationElement={renderNotification()}
					placeholder={localizedPlaceholder}
					name={questionKey}
					value={(answerValue ?? '') as string}
					options={selectOptions}
					required={answerTypeOptions.required}
				/>
			);
		}
		case AnswerTypeId.MULTI_SELECT: {
			const answerTypeOptions = question.answerType.options;
			return (
				<InputCheckboxGroup
					label={localizedQuestion}
					notificationElement={renderNotification()}
				>
					{answerTypeOptions.options.map((option) => {
						const multiSelectAnswerValue = answers[questionKey + '.' + option.value] ?? undefined;
						const localizedLabel = l10n.translate('questionnaire.' + option.label, undefined, option.label);
						return (
							<InputCheckbox
								label={localizedLabel}
								name={questionKey + '.' + option.value}
								value={option.value}
								defaultChecked={multiSelectAnswerValue === option.value}
								key={questionKey + '.' + option.value}
							/>
						);
					})}
				</InputCheckboxGroup>
			);
		}
		case AnswerTypeId.CONSENT: {
			const consentAnswerValue = answers[questionKey] ?? undefined;
			return (
				<InputCheckbox
					label={localizedQuestion}
					notificationElement={renderNotification()}
					name={questionKey}
					value="granted"
					defaultChecked={consentAnswerValue === 'granted'}
					key={questionKey}
				/>
			);
		}
		default:
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			throw new NotFoundError(l10n.translate('omsCustomerUiQuestionnaire.questionnaire.answerTypeNotFound', new Map([['type', question.answerType.type]])));
	}
}
