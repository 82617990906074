import { Nullable, trimFromLeft } from '@abb-emobility/shared/util';

export const useUrlFragment = (): Nullable<string> => {
	const locationHash = window.location.hash;
	const fragment = trimFromLeft(locationHash, '#').trim();
	if (fragment.length === 0) {
		return null;
	}
	return fragment;
};

export const useUrlFragmentUpdate = (): (fragment: string) => void => {
	return (fragment) => {
		window.location.hash = '#' + trimFromLeft(fragment, '#');
	};
};
