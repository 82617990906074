import { ReactNode, useState } from 'react';

import { FormSection, FormSegement, InputCheckbox, InputCheckboxGroup } from '@abb-emobility/oms/customer-ui-primitive';
import { ModelPrimaryKey, TimeOfDay } from '@abb-emobility/shared/domain-model-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';
import { PreCheckPreferencesTaskModel } from '@abb-emobility/usertask/domain-model';

export type TaskPreCheckPreferencesFormStep2Props = {
	task: PreCheckPreferencesTaskModel,
	mediumId: ModelPrimaryKey,
	selectedTimesOfDay: Array<TimeOfDay>,
	onSelectPreferredTimesOfDay: (timesOfDay: Array<TimeOfDay>) => void
};

export function TaskPreCheckPreferencesFormStep2(props: TaskPreCheckPreferencesFormStep2Props) {

	const { task, mediumId, selectedTimesOfDay, onSelectPreferredTimesOfDay } = props;

	const l10n = useL10n();
	const [preferredTimesOfDay, setPreferredTimesOfDay] = useState<Array<TimeOfDay>>(selectedTimesOfDay);

	const channel = task.payload.availableChannels.find((channel) => {
		return channel.medium.id === mediumId;
	});

	if (channel === undefined) {
		throw new NotFoundError('The channel for the selected medium with id ' + mediumId + 'was not found');
	}

	const addTimeOfDay = (timeOfDay: Nullable<TimeOfDay>): void => {
		if (timeOfDay === null) {
			return;
		}
		const timesOfday = [...preferredTimesOfDay].filter((time) => {
			return time !== timeOfDay;
		});
		timesOfday.push(timeOfDay);
		setPreferredTimesOfDay(timesOfday);
		onSelectPreferredTimesOfDay(timesOfday);
	};

	const removeTimeOfDay = (timeOfDay: Nullable<TimeOfDay>): void => {
		if (timeOfDay === null) {
			return;
		}
		const timesOfday = [...preferredTimesOfDay].filter((time) => {
			return time !== timeOfDay;
		});
		setPreferredTimesOfDay(timesOfday);
		onSelectPreferredTimesOfDay(timesOfday);
	};

	const renderPreCheckTimesOfDaySelection = () => {
		return channel.availableTimesOfDay.map((timeOfDay): ReactNode => {
			return (
				<InputCheckbox
					label={l10n.translate(l10nLiteralFromEnumValue(timeOfDay, 'omsCustomerApp.task.preCheckPreferences.preferredTimesOfDay.timeOfDay'))}
					value={String(timeOfDay)}
					defaultChecked={preferredTimesOfDay.includes(timeOfDay)}
					onChange={(checked, value) => {
						if (checked) {
							addTimeOfDay(value as TimeOfDay);
							return;
						}
						removeTimeOfDay(value as TimeOfDay);
					}}
					key={String(timeOfDay)}
				/>
			);
		});
	};

	return (
		<FormSegement>
			<FormSection
				heading={channel.medium.name}
				description={l10n.translate('omsCustomerApp.task.preCheckPreferences.preferredTimesOfDay.subHeading', new Map([['channelName', channel.medium.name]]))}
			>
				<InputCheckboxGroup>
					{renderPreCheckTimesOfDaySelection()}
				</InputCheckboxGroup>
			</FormSection>
		</FormSegement>
	);

}
