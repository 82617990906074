import { L10nContextValue, useL10n } from '@abb-emobility/shared/localization-provider';

import { ThemeContextValue, useTheme } from '../components/theme/Theme.context';

const composePageTitle = (theme: ThemeContextValue, l10n: L10nContextValue, pageTitleLiteral?: string, replacements?: Map<string, string>) => {
	let title = '';
	if (pageTitleLiteral !== undefined) {
		title = l10n.translate(pageTitleLiteral, replacements);
		title += ' – ';
	}
	title += theme.pageTitle();
	return title;
};

export const useBuildPageTitle = (pageTitleLiteral?: string, replacements?: Map<string, string>): string => {
	const l10n = useL10n();
	const theme = useTheme();
	return composePageTitle(theme, l10n, pageTitleLiteral, replacements);
};

export const usePageTitleBuilder = (): (pageTitleLiteral?: string, replacements?: Map<string, string>) => string => {
	const l10n = useL10n();
	const theme = useTheme();
	return (pageTitleLiteral?: string, replacements?: Map<string, string>): string => {
		return composePageTitle(theme, l10n, pageTitleLiteral, replacements);
	};
};
