import { useContext } from 'react';

import { AnyCustomerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { CrudCollectionDataProviderValue } from '@abb-emobility/shared/data-provider-foundation';

import { customerOrderInformationCollectionData } from './CustomerOrderInformationCollectionData';

export const useCustomerOrderInformationCollectionData = (): CrudCollectionDataProviderValue<AnyCustomerOrderInformationCollectionItemModel> => {
	return useContext(customerOrderInformationCollectionData);
};
