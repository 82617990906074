import { ReactNode } from 'react';

import { SpinnerCable, SuccessFeeback } from '@abb-emobility/oms/customer-ui-primitive';
import { Questionnaire, QuestionnaireContextProvider } from '@abb-emobility/oms/customer-ui-questionnaire';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Answers, getAnswersByKey, QuestionnaireSegment } from '@abb-emobility/shared/questionnaire';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { BasicQuestionsTaskModel } from '@abb-emobility/usertask/domain-model';

import { useQuestionnaireConsentSegment } from './TaskBasicQuestions.hooks';

export type TaskBasicQuestionsProps = {
	task: BasicQuestionsTaskModel
};

export function TaskBasicQuestions(props: TaskBasicQuestionsProps) {
	const { task } = props;

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const actionStatus = anyTaskEntityData.queryActionStatus();

	const additionalQuestionnaireSegment: QuestionnaireSegment = useQuestionnaireConsentSegment();
	const effectiveQuestionnaire = { ...task.payload.questionnaire };
	effectiveQuestionnaire.segments = [...effectiveQuestionnaire.segments, additionalQuestionnaireSegment];

	const handleSubmit = (answers: Answers) => {
		const marketingConsentAnswer = getAnswersByKey(answers, 'consent.marketing-consent.installation-partner-marketing-consent');
		const surveyConsentAnswer = getAnswersByKey(answers, 'consent.survey-consent.survey-consent');

		anyTaskEntityData.complete(task, {
			answers,
			marketingConsentGiven: marketingConsentAnswer === 'granted',
			surveyConsentGiven: surveyConsentAnswer === 'granted',
			questionnaire: task.payload.questionnaire
		});
	};

	const renderPayload = (): ReactNode => {
		switch (actionStatus) {
			case TaskActionStatus.COMPLETE_PENDING:
				return <SpinnerCable />;
			case TaskActionStatus.COMPLETE_SUCCESS:
				return (
					<SuccessFeeback
						heading={l10n.translate(
							'omsCustomerApp.task.basicQuestions.completionSuccess.heading'
						)}
						message={l10n.translate(
							'omsCustomerApp.task.basicQuestions.completionSuccess.message'
						)}
					/>
				);
			default:
				return (
					<QuestionnaireContextProvider
						questionnaire={effectiveQuestionnaire}
						environment={task.payload.questionnaireEnvironment}
					>
						<Questionnaire onSubmit={handleSubmit} />
					</QuestionnaireContextProvider>
				);
		}
	};

	return <>{renderPayload()}</>;
}
