import { QuestionnaireModel } from './QuestionnaireModel';
import { QuestionnaireBusinessRuleCombinator, QuestionnaireBusinessRuleComparator } from './business-rule/BusinessRule';
import { AnswerTypeId } from './question/Question';

const questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Bitte beantworten Sie die folgenden Fragen, um sicherzustellen, dass Ihr Eigenheim alle notwendigen Voraussetzungen für eine erfolgreiche Installation Ihrer Wallbox erfüllt.',
		sections: {
			property: {
				heading: 'Das ist mein Zuhause',
				description: 'Für die reibungslose Installation Ihrer Wallbox benötigen wir folgende Informationen zu Ihrer Immobilie.',
				questions: {
					typeOfHouse: {
						question: 'Art des Hauses',
						answerOptions: {
							singleFamilyHouse: 'Einfamilienhaus',
							apartmentHouse: 'Mehfamilienhaus',
							unknown: 'Anderes Gebäude'
						}
					},
					constructionYear: {
						question: 'Baujahr des Hauses oder letzte Sanierung der elektrischen Anlage',
						answerOptions: {
							before2000: 'Vor 2000',
							after2000: 'Nach 2000',
							unknown: 'Weiss ich nicht'
						}
					},
					ownership: {
						question: 'Eigentumsangaben',
						answerOptions: {
							selfOwnedHouse: 'Ich bin der Eigentümer',
							ownersAssociation: 'Ich bin Teil der Eigentümergemeinschaft',
							rentalHouse: 'Ich bin Mieter',
							unknwon: 'Andere Eigentümerschaft'
						}
					}
				}
			},
			installation: {
				heading: 'Hier wünsche ich mir meine neue Wallbox',
				description: 'Teilen Sie uns mit, wo Ihre neue Wallbox platziert werden soll.',
				hint: 'Bitte achten Sie darauf, dass sich der Ladeanschluss auf der linken Seite Ihres Fahrzeuges befindet.',
				questions: {
					location: {
						question: 'Installationsort',
						answerOptions: {
							garageDry: 'Montage in einer Garage',
							exteriorWallDamp: 'Montage an eine Außenwand',
							unknown: 'An einem anderen Ort'
						}
					},
					pictureLocation: {
						question: 'Bild des Installationsorts der Wallbox'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Bitte beantworten Sie die folgenden Fragen, um die Installation Ihrer Wallbox zu ermöglichen.',
		sections: {
			consent: {
				heading: 'Ich versichere, dass eine Installation vor Ort durchgeführt werden kann.',
				questions: {
					consentGiven: {
						question: 'Ich habe die notwendigen Unterlagen und die Zustimmung des Eigentümers oder der Eigentümergemeinschaft zur Installation einer Wallbox.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Bitte geben Sie die erforderlichen Informationen zur Montage an einer Außenwand an.',
		sections: {
			installation: {
				heading: 'Montage an einer Außenwand',
				questions: {
					mounting: {
						question: 'Wie ist die Wand beschaffen?',
						answerOptions: {
							onInsulatedWall: 'Gedämmte Wand',
							onUninsulatedWall: 'Ungedämmte Wand',
							unknown: 'Weiss ich nicht'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Bitte geben Sie die erforderlichen Informationen zur Montage an einer gedämmten Außenwand an.',
		sections: {
			insulation: {
				heading: 'Angaben zur Dämmung',
				questions: {
					thickness: {
						question: 'Dicke der Dämmung in cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Bitte geben Sie die erforderlichen Informationen zur Montage auf einem Ständer an.',
		sections: {
			earthworks: {
				heading: 'Angaben zur Ständermontage',
				questions: {
					distance: {
						question: 'Entfernung zur elektrischen Installation in Meter'
					},
					floorCovering: {
						question: 'Boden-Beschaffenheit',
						answerOptions: {
							asphalt: 'Asphalt',
							concreteTiles: 'Beton',
							pavingStones: 'Pflastersteine',
							earthLawn: 'Erde / Rasen',
							unknown: 'Anderer Bodenbelag'
						}
					},
					pictureMounting: {
						question: 'Bild des Montageorts'
					},
					pictureFloor: {
						question: 'Bild des Bereichs zwischen Gebäude und Montageort'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Angaben zur Verkabelung Ihrer neuen Wallbox',
		sections: {
			wallBreakthrough: {
				heading: 'Erforderliche Mauerwerksarbeiten',
				questions: {
					innerWallBreakthroughs: {
						question: 'Anzahl der Wanddurchbrüche im Hausinneren'
					},
					outerWallBreakthroughs: {
						question: 'Anzahl der Wanddurchbrüche durch Außenmauern'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Angaben zur elektrischen Installation',
		sections: {
			currentInstallation: {
				heading: 'Angaben zur bestehenden elektrischen Anlage',
				questions: {
					overallFuse: {
						question: 'Hausabsicherung in Ampere'
					},
					overvoltageProtection: {
						question: 'Ein Überspannschutz ist vorhanden'
					},
					lightningConductor: {
						question: 'Ein Blitzableiter ist vorhanden'
					},
					equipotentialBonding: {
						question: 'Ein Potentialausgleich ist vorhanden'
					},
					pictureDistributionBox: {
						question: 'Bild des Zählerschranks oder des Verteilerkastens'
					},
					pictureBackUpFuse: {
						question: 'Bild der Sicherungen vor dem Stromzähler sowie deren Bezeichnung'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsEn: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Please answer the following questions to ensure that your home meets all the necessary requirements for a successful installation of your wallbox.',
		sections: {
			property: {
				heading: 'This is my home',
				description: 'For the smooth installation of your wallbox, we need the following information about your property.',
				questions: {
					typeOfHouse: {
						question: 'Type of house',
						answerOptions: {
							singleFamilyHouse: 'Single-family house',
							apartmentHouse: 'Apartment building',
							unknown: 'Other building'
						}
					},
					constructionYear: {
						question: 'Year of construction of the house or last renovation of the electrical system',
						answerOptions: {
							before2000: 'Before 2000',
							after2000: 'After 2000',
							unknown: 'I don\'t know'
						}
					},
					ownership: {
						question: 'Ownership details',
						answerOptions: {
							selfOwnedHouse: 'I am the owner',
							ownersAssociation: 'I am part of the community of owners',
							rentalHouse: 'I am a tenant',
							unknwon: 'Other ownership'
						}
					}
				}
			},
			installation: {
				heading: 'This is where I would like my new wallbox',
				description: 'Let us know where you want your new wallbox to be placed.',
				hint: 'Please make sure that the connection is on the left side of your vehicle.',
				questions: {
					location: {
						question: 'Installation site',
						answerOptions: {
							garageDry: 'Mounting in a garage',
							exteriorWallDamp: 'Mounting on an outside wall',
							unknown: 'In another place'
						}
					},
					pictureLocation: {
						question: 'Picture of the installation location of the wallbox'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Please answer the following questions to enable the installation of your wallbox.',
		sections: {
			consent: {
				heading: 'I assure that an installation can be carried out on site.',
				questions: {
					consentGiven: {
						question: 'I have the necessary documents and the consent of the owner or the owners\' association to install a wallbox.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Please provide the required information for mounting on an exterior wall.',
		sections: {
			installation: {
				heading: 'Mounting on an exterior wall',
				questions: {
					mounting: {
						question: 'What is the wall like?',
						answerOptions: {
							onInsulatedWall: 'Insulated wall',
							onUninsulatedWall: 'Uninsulated wall',
							unknown: 'I don\'t know'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Please provide the required information for mounting on an insulated exterior wall.',
		sections: {
			insulation: {
				heading: 'Insulation details',
				questions: {
					thickness: {
						question: 'Thickness of insulation in cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Please provide the necessary information for mounting on a stand.',
		sections: {
			earthworks: {
				heading: 'Information on stand mounting',
				questions: {
					distance: {
						question: 'Distance to electrical installation in metres'
					},
					floorCovering: {
						question: 'Soil condition',
						answerOptions: {
							asphalt: 'Asphalt',
							concreteTiles: 'Concrete',
							pavingStones: 'Paving stones',
							earthLawn: 'Earth / Lawn',
							unknown: 'Other soil condition'
						}
					},
					pictureMounting: {
						question: 'Picture of the installation site'
					},
					pictureFloor: {
						question: 'Image of the area between the building and the installation site'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Information on the wiring of your new wallbox',
		sections: {
			wallBreakthrough: {
				heading: 'Masonry work required',
				questions: {
					innerWallBreakthroughs: {
						question: 'Number of wall openings inside the house'
					},
					outerWallBreakthroughs: {
						question: 'Number of wall openings through exterior walls'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Information on the electrical installation',
		sections: {
			currentInstallation: {
				heading: 'Details of the existing electrical installation',
				questions: {
					overallFuse: {
						question: 'House fuse in amperes'
					},
					overvoltageProtection: {
						question: 'Overvoltage protection is available'
					},
					lightningConductor: {
						question: 'A lightning conductor is available'
					},
					equipotentialBonding: {
						question: 'Equipotential bonding is available'
					},
					pictureDistributionBox: {
						question: 'Picture of the meter cabinet or the distribution box'
					},
					pictureBackUpFuse: {
						question: 'Picture of the fuses in front of the electricity meter and their designation'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsEs: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Responda a las siguientes preguntas para asegurarse de que su vivienda cumple todos los requisitos necesarios para una correcta instalación de su wallbox.',
		sections: {
			property: {
				heading: 'Esta es mi casa',
				description: 'Para la correcta instalación de su wallbox, necesitamos la siguiente información sobre su propiedad.',
				questions: {
					typeOfHouse: {
						question: 'Tipo de vivienda',
						answerOptions: {
							singleFamilyHouse: 'Vivienda unifamiliar',
							apartmentHouse: 'Edificio de apartamentos',
							unknown: 'Otro edificio'
						}
					},
					constructionYear: {
						question: 'Año de construcción de la vivienda o de la última renovación de la instalación eléctrica',
						answerOptions: {
							before2000: 'Antes de 2000',
							after2000: 'Después de 2000',
							unknown: 'No lo sé'
						}
					},
					ownership: {
						question: 'Datos de propiedad',
						answerOptions: {
							selfOwnedHouse: 'Soy el propietario',
							ownersAssociation: 'Formo parte de la comunidad de propietarios',
							rentalHouse: 'Soy inquilino',
							unknwon: 'Otra propiedad'
						}
					}
				}
			},
			installation: {
				heading: 'Aquí es donde me gustaría mi nuevo wallbox',
				description: 'Díganos dónde quiere colocar su nuevo wallbox.',
				hint: 'Asegúrese de que la conexión se encuentra en el lado izquierdo de su vehículo.',
				questions: {
					location: {
						question: 'Lugar de instalación',
						answerOptions: {
							garageDry: 'Montaje en un garaje',
							exteriorWallDamp: 'Montaje en una pared exterior',
							unknown: 'En otro lugar'
						}
					},
					pictureLocation: {
						question: 'Imagen del lugar de instalación del wallbox'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Responda a las siguientes preguntas para permitir la instalación de su wallbox.',
		sections: {
			consent: {
				heading: 'Garantizo que se puede realizar una instalación in situ.',
				questions: {
					consentGiven: {
						question: 'Dispongo de los documentos necesarios y del consentimiento del propietario o de la comunidad de propietarios para instalar un wallbox.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Proporcione la información necesaria para el montaje en una pared exterior.',
		sections: {
			installation: {
				heading: 'Montaje en una pared exterior',
				questions: {
					mounting: {
						question: '¿Cómo es la pared?',
						answerOptions: {
							onInsulatedWall: 'Pared aislada',
							onUninsulatedWall: 'Pared sin aislar',
							unknown: 'No lo sé'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Proporcione la información necesaria para el montaje en una pared exterior aislada.',
		sections: {
			insulation: {
				heading: 'Detalles del aislamiento',
				questions: {
					thickness: {
						question: 'Espesor del aislamiento en cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Proporcione la información necesaria para el montaje en un soporte.',
		sections: {
			earthworks: {
				heading: 'Información sobre el montaje del soporte',
				questions: {
					distance: {
						question: 'Distancia a la instalación eléctrica en metros'
					},
					floorCovering: {
						question: 'Estado del suelo',
						answerOptions: {
							asphalt: 'Asfalto',
							concreteTiles: 'Hormigón',
							pavingStones: 'Adoquines',
							earthLawn: 'Tierra / césped',
							unknown: 'Otras condiciones del suelo'
						}
					},
					pictureMounting: {
						question: 'Imagen del lugar de instalación'
					},
					pictureFloor: {
						question: 'Imagen de la zona entre el edificio y el lugar de instalación'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Información sobre el cableado de su nuevo wallbox',
		sections: {
			wallBreakthrough: {
				heading: 'Trabajos de albañilería necesarios',
				questions: {
					innerWallBreakthroughs: {
						question: 'Número de aberturas en las paredes del interior de la vivienda'
					},
					outerWallBreakthroughs: {
						question: 'Número de aberturas en paredes exteriores'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Información sobre la instalación eléctrica',
		sections: {
			currentInstallation: {
				heading: 'Detalles de la instalación eléctrica existente',
				questions: {
					overallFuse: {
						question: 'Fusible doméstico en amperios'
					},
					overvoltageProtection: {
						question: 'La protección contra sobretensiones está disponible'
					},
					lightningConductor: {
						question: 'Hay un pararrayos disponible'
					},
					equipotentialBonding: {
						question: 'Hay una conexión equipotencial disponible'
					},
					pictureDistributionBox: {
						question: 'Imagen del armario de contadores o de la caja de distribución'
					},
					pictureBackUpFuse: {
						question: 'Imagen de los fusibles frente al contador de electricidad y su designación'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsFr: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Veuillez répondre aux questions suivantes afin de vous assurer que votre habitation répond à toutes les exigences nécessaires pour une installation réussie de votre Wallbox.',
		sections: {
			property: {
				heading: 'C’est ma maison',
				description: 'For the smooth installation of your wallbox, we need the following information about your property.',
				questions: {
					typeOfHouse: {
						question: 'Type de maison',
						answerOptions: {
							singleFamilyHouse: 'Maison individuelle',
							apartmentHouse: 'Immeubles d’appartements',
							unknown: 'Autre bâtiment'
						}
					},
					constructionYear: {
						question: 'Année de construction de la maison ou de la dernière rénovation du système électrique',
						answerOptions: {
							before2000: 'Avant 2000',
							after2000: 'Après 2000',
							unknown: 'Je ne sais pas'
						}
					},
					ownership: {
						question: 'Détails de la propriété',
						answerOptions: {
							selfOwnedHouse: 'Je suis le propriétaire',
							ownersAssociation: 'Je fais partie de la communauté des propriétaires',
							rentalHouse: 'Je suis locataire',
							unknwon: 'Autre propriété'
						}
					}
				}
			},
			installation: {
				heading: 'Voici l’endroit où j’aimerais installer ma nouvelle Wallbox',
				description: 'Indiquez-nous l’endroit où vous souhaitez placer votre nouvelle Wallbox.',
				hint: 'Veillez à ce que la connexion se fasse du côté gauche de votre véhicule.',
				questions: {
					location: {
						question: 'Lieu d’installation',
						answerOptions: {
							garageDry: 'Montage dans un garage',
							exteriorWallDamp: 'Montage sur un mur extérieur',
							unknown: 'Dans un autre lieu'
						}
					},
					pictureLocation: {
						question: 'Photo de l’emplacement d’installation de la Wallbox'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Veuillez répondre aux questions suivantes pour permettre l’installation de votre Wallbox.',
		sections: {
			consent: {
				heading: 'J’assure qu’une installation peut être réalisée sur place.',
				questions: {
					consentGiven: {
						question: 'Je dispose des documents nécessaires et de l’accord du propriétaire ou de l’association de propriétaires pour installer une Wallbox.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Veuillez fournir les informations requises pour le montage sur un mur extérieur.',
		sections: {
			installation: {
				heading: 'Montage sur un mur extérieur',
				questions: {
					mounting: {
						question: 'À quoi ressemble le mur ?',
						answerOptions: {
							onInsulatedWall: 'Mur isolé',
							onUninsulatedWall: 'Mur non isolé',
							unknown: 'Je ne sais pas'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Veuillez fournir les informations requises pour le montage sur un mur extérieur isolé.',
		sections: {
			insulation: {
				heading: 'Détails de l’isolation',
				questions: {
					thickness: {
						question: 'Épaisseur de l’isolation en cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Veuillez fournir les informations nécessaires pour le montage sur un support.',
		sections: {
			earthworks: {
				heading: 'Informations sur le montage du support',
				questions: {
					distance: {
						question: 'Distance de l’installation électrique en mètres'
					},
					floorCovering: {
						question: 'Condition du sol',
						answerOptions: {
							asphalt: 'Asphalte',
							concreteTiles: 'Béton',
							pavingStones: 'Pavés',
							earthLawn: 'Terre / pelouse',
							unknown: 'Autres conditions du sol'
						}
					},
					pictureMounting: {
						question: 'Photo du site d’installation'
					},
					pictureFloor: {
						question: 'Image de la zone située entre le bâtiment et le site d’installation'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Informations sur le câblage de votre nouvelle Wallbox',
		sections: {
			wallBreakthrough: {
				heading: 'Travaux de maçonnerie nécessaires',
				questions: {
					innerWallBreakthroughs: {
						question: 'Nombre d’ouvertures murales à l’intérieur de la maison'
					},
					outerWallBreakthroughs: {
						question: 'Nombre d’ouvertures murales dans les murs extérieurs'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Informations sur l’installation électrique',
		sections: {
			currentInstallation: {
				heading: 'Détails de l’installation électrique existante',
				questions: {
					overallFuse: {
						question: 'Fusible domestique en ampères'
					},
					overvoltageProtection: {
						question: 'Une protection contre les surtensions est disponible'
					},
					lightningConductor: {
						question: 'Un paratonnerre est disponible'
					},
					equipotentialBonding: {
						question: 'Une liaison équipotentielle est disponible'
					},
					pictureDistributionBox: {
						question: 'Photo de l’armoire à compteurs ou du coffret de distribution'
					},
					pictureBackUpFuse: {
						question: 'Photo des fusibles devant le compteur d’électricité et leur désignation'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsIt: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Rispondi alle seguenti domande per assicurarti che la tua abitazione soddisfi tutti i requisiti necessari per una corretta installazione della centralina a parete.',
		sections: {
			property: {
				heading: 'Questa è la mia casa',
				description: 'Per un\'installazione regolare della tua centralina a parete, abbiamo bisogno delle seguenti informazioni sulla vostra proprietà.',
				questions: {
					typeOfHouse: {
						question: 'Tipo di abitazione',
						answerOptions: {
							singleFamilyHouse: 'Residenza unifamiliare',
							apartmentHouse: 'Edificio residenziale',
							unknown: 'Altro edificio'
						}
					},
					constructionYear: {
						question: 'Anno di costruzione dell\'abitazione o dell\'ultima ristrutturazione dell\'impianto elettrico',
						answerOptions: {
							before2000: 'Prima del 2000',
							after2000: 'Dopo il 2000',
							unknown: 'Non so'
						}
					},
					ownership: {
						question: 'Dettagli sulla proprietà',
						answerOptions: {
							selfOwnedHouse: 'Sono il proprietario',
							ownersAssociation: 'Faccio parte della comunità di proprietari',
							rentalHouse: 'Sono un inquilino',
							unknwon: 'Altri tipi di proprietà'
						}
					}
				}
			},
			installation: {
				heading: 'Questo è il punto in cui vorrei installare la mia nuova centralina a parete.',
				description: 'Facci sapere il punto in cui desideri installare la tua nuova centralina a parete.',
				hint: 'Assicurati che il collegamento si trovi sul lato sinistro del veicolo.',
				questions: {
					location: {
						question: 'Luogo di installazione',
						answerOptions: {
							garageDry: 'Montaggio in garage',
							exteriorWallDamp: 'Montaggio su parete esterna',
							unknown: 'In un altro luogo'
						}
					},
					pictureLocation: {
						question: 'Immagine della sede di installazione della centralina a parete'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Rispondi alle seguenti domande per consentire l\'installazione della centralina a parete.',
		sections: {
			consent: {
				heading: 'Garantisco che l\'installazione può essere effettuata in loco.',
				questions: {
					consentGiven: {
						question: 'Sono in possesso dei documenti necessari e del consenso del proprietario o dell\'associazione dei proprietari per l\'installazione della centralina a parete.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Fornire le informazioni richieste per il montaggio su parete esterna.',
		sections: {
			installation: {
				heading: 'Montaggio su parete esterna',
				questions: {
					mounting: {
						question: 'Com\'è la parete?',
						answerOptions: {
							onInsulatedWall: 'Parete isolata',
							onUninsulatedWall: 'Parete non isolata',
							unknown: 'Non so'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Fornire le informazioni richieste per il montaggio su parete esterna isolata.',
		sections: {
			insulation: {
				heading: 'Dettagli dell\'isolamento',
				questions: {
					thickness: {
						question: 'Spessore dell\'isolamento in cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Fornire le informazioni necessarie per il montaggio su supporto.',
		sections: {
			earthworks: {
				heading: 'Informazioni sul montaggio del supporto',
				questions: {
					distance: {
						question: 'Distanza dall\'impianto elettrico in metri'
					},
					floorCovering: {
						question: 'Condizioni del suolo',
						answerOptions: {
							asphalt: 'Asfalto',
							concreteTiles: 'Calcestruzzo',
							pavingStones: 'Lastre di pietra',
							earthLawn: 'Terra / Prato',
							unknown: 'Altre condizioni del suolo'
						}
					},
					pictureMounting: {
						question: 'Immagine della sede di installazione'
					},
					pictureFloor: {
						question: 'Immagine della zona tra l\'edificio e la sede di installazione'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Informazioni sul cablaggio della nuova centralina a parete',
		sections: {
			wallBreakthrough: {
				heading: 'Sono necessari lavori di muratura',
				questions: {
					innerWallBreakthroughs: {
						question: 'Numero di aperture delle pareti all\'interno dell\'abitazione'
					},
					outerWallBreakthroughs: {
						question: 'Numero di aperture nelle pareti esterne'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Informazioni sull\'impianto elettrico',
		sections: {
			currentInstallation: {
				heading: 'Dettagli dell\'impianto elettrico esistente',
				questions: {
					overallFuse: {
						question: 'Fusibile interno in ampere'
					},
					overvoltageProtection: {
						question: 'È disponibile la protezione da sovratensione'
					},
					lightningConductor: {
						question: 'È disponibile un parafulmine'
					},
					equipotentialBonding: {
						question: 'È disponibile il collegamento equipotenziale'
					},
					pictureDistributionBox: {
						question: 'Immagine dell\'armadio del contatore o della scatola di distribuzione'
					},
					pictureBackUpFuse: {
						question: 'Immagine dei fusibili davanti al contatore elettrico e relativa denominazione'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsNl: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Beantwoord de volgende vragen om er zeker van te zijn dat uw huis voldoet aan alle vereisten voor een succesvolle installatie van uw wallbox.',
		sections: {
			property: {
				heading: 'Dit is mijn thuis',
				description: 'Voor een vlotte installatie van uw wallbox hebben we de volgende informatie over uw woning nodig.',
				questions: {
					typeOfHouse: {
						question: 'Type huis',
						answerOptions: {
							singleFamilyHouse: 'Eengezinswoning',
							apartmentHouse: 'Appartementsgebouw',
							unknown: 'Ander gebouw'
						}
					},
					constructionYear: {
						question: 'Bouwjaar van het huis of laatste renovatie van de elektrische installatie',
						answerOptions: {
							before2000: 'Voor 2000',
							after2000: 'Na 2000',
							unknown: 'Ik weet het niet'
						}
					},
					ownership: {
						question: 'Eigendomsgegevens',
						answerOptions: {
							selfOwnedHouse: 'Ik ben de eigenaar',
							ownersAssociation: 'Ik ben lid van de vereniging van eigenaren',
							rentalHouse: 'Ik ben huurder',
							unknwon: 'Ander eigendom'
						}
					}
				}
			},
			installation: {
				heading: 'Dit is waar ik mijn nieuwe wallbox wil hebben',
				description: 'Laat ons weten waar u uw nieuwe wallbox wilt plaatsen.',
				hint: 'Zorg ervoor dat de aansluiting zich aan de linkerkant van uw voertuig bevindt.',
				questions: {
					location: {
						question: 'Installatielocatie',
						answerOptions: {
							garageDry: 'Montage in een garage',
							exteriorWallDamp: 'Montage aan een buitenmuur',
							unknown: 'Op een andere plaats'
						}
					},
					pictureLocation: {
						question: 'Afbeelding van de installatielocatie van de wallbox'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Beantwoord de volgende vragen om de installatie van uw wallbox mogelijk te maken.',
		sections: {
			consent: {
				heading: 'Ik verzeker u dat een installatie ter plaatse kan worden uitgevoerd.',
				questions: {
					consentGiven: {
						question: 'Ik heb de benodigde documenten en de toestemming van de eigenaar of de vereniging van eigenaren om een wallbox te installeren.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Graag ontvangen wij de vereiste informatie voor montage op een buitenmuur.',
		sections: {
			installation: {
				heading: 'Montage op een buitenmuur',
				questions: {
					mounting: {
						question: 'Hoe ziet de muur eruit?',
						answerOptions: {
							onInsulatedWall: 'Geïsoleerde muur',
							onUninsulatedWall: 'Ongeïsoleerde muur',
							unknown: 'Ik weet het niet'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Graag ontvangen wij de vereiste informatie voor montage op een geïsoleerde buitenmuur.',
		sections: {
			insulation: {
				heading: 'Isolatiegegevens',
				questions: {
					thickness: {
						question: 'Dikte van isolatie in cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Graag ontvangen wij de nodige informatie voor montage op een sokkel.',
		sections: {
			earthworks: {
				heading: 'Informatie over montage op sokkel',
				questions: {
					distance: {
						question: 'Afstand tot elektrische installatie in meters'
					},
					floorCovering: {
						question: 'Bodemgesteldheid',
						answerOptions: {
							asphalt: 'Asfalt',
							concreteTiles: 'Beton',
							pavingStones: 'Bestrating',
							earthLawn: 'Aarde / gazon',
							unknown: 'Andere bodemgesteldheid'
						}
					},
					pictureMounting: {
						question: 'Foto van de installatielocatie'
					},
					pictureFloor: {
						question: 'Beeld van het gebied tussen het gebouw en de installatielocatie'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Informatie over de bedrading van uw nieuwe wallbox',
		sections: {
			wallBreakthrough: {
				heading: 'Metselwerk vereist',
				questions: {
					innerWallBreakthroughs: {
						question: 'Aantal muuropeningen in het huis'
					},
					outerWallBreakthroughs: {
						question: 'Aantal muuropeningen door buitenmuren'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Informatie over de elektrische installatie',
		sections: {
			currentInstallation: {
				heading: 'Details van de bestaande elektrische installatie',
				questions: {
					overallFuse: {
						question: 'Zekeringen in ampère'
					},
					overvoltageProtection: {
						question: 'Overspanningsbeveiliging is beschikbaar'
					},
					lightningConductor: {
						question: 'Er is een bliksemafleider beschikbaar'
					},
					equipotentialBonding: {
						question: 'Potentiaalvereffening is beschikbaar'
					},
					pictureDistributionBox: {
						question: 'Foto van de meterkast of de verdeelkast'
					},
					pictureBackUpFuse: {
						question: 'Foto van de zekeringen voor de elektriciteitsmeter en hun aanduiding'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsPt: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Por favor, responda às seguintes perguntas para garantir que a sua casa cumpre todos os requisitos necessários para uma instalação bem-sucedida da sua Wallbox.',
		sections: {
			property: {
				heading: 'Esta é a minha casa',
				description: 'Para uma instalação sem problemas da sua Wallbox, precisamos das seguintes informações sobre a sua propriedade.',
				questions: {
					typeOfHouse: {
						question: 'Tipo de casa',
						answerOptions: {
							singleFamilyHouse: 'Habitação unifamiliar',
							apartmentHouse: 'Edifício de apartamentos',
							unknown: 'Outro edifício'
						}
					},
					constructionYear: {
						question: 'Ano de construção da casa ou da última renovação do sistema elétrico',
						answerOptions: {
							before2000: 'Antes de 2000',
							after2000: 'Depois de 2000',
							unknown: 'Não sei'
						}
					},
					ownership: {
						question: 'Detalhes da propriedade',
						answerOptions: {
							selfOwnedHouse: 'Eu sou o proprietário',
							ownersAssociation: 'Faço parte da comunidade de proprietários',
							rentalHouse: 'Sou inquilino',
							unknwon: 'Outra propriedade'
						}
					}
				}
			},
			installation: {
				heading: 'É aqui que gostaria de ter a minha nova Wallbox',
				description: 'Diga-nos onde quer que a sua nova Wallbox seja colocada.',
				hint: 'Certifique-se de que a ligação se encontra do lado esquerdo do seu veículo.',
				questions: {
					location: {
						question: 'Local de instalação',
						answerOptions: {
							garageDry: 'Montagem numa garagem',
							exteriorWallDamp: 'Montagem numa parede exterior',
							unknown: 'Noutro local'
						}
					},
					pictureLocation: {
						question: 'Imagem do local de instalação da Wallbox'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Responda às seguintes perguntas para permitir a instalação da sua Wallbox.',
		sections: {
			consent: {
				heading: 'Garanto que a instalação pode ser efetuada no local.',
				questions: {
					consentGiven: {
						question: 'Tenho os documentos necessários e a autorização do proprietário ou da associação de proprietários para instalar uma wallbox.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Forneça as informações necessárias para a montagem numa parede exterior.',
		sections: {
			installation: {
				heading: 'Montagem numa parede exterior',
				questions: {
					mounting: {
						question: 'Como é que é a parede?',
						answerOptions: {
							onInsulatedWall: 'Parede isolada',
							onUninsulatedWall: 'Parede sem isolamento',
							unknown: 'Não sei'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Forneça as informações necessárias para a montagem numa parede exterior isolada.',
		sections: {
			insulation: {
				heading: 'Pormenores do isolamento',
				questions: {
					thickness: {
						question: 'Espessura do isolamento em cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Fornecer as informações necessárias para a montagem num suporte.',
		sections: {
			earthworks: {
				heading: 'Informações sobre a montagem do suporte',
				questions: {
					distance: {
						question: 'Distância da instalação elétrica em metros'
					},
					floorCovering: {
						question: 'Estado do solo',
						answerOptions: {
							asphalt: 'Asfalto',
							concreteTiles: 'Betão',
							pavingStones: 'Pedras da calçada',
							earthLawn: 'Terra / Relvado',
							unknown: 'Outro estado do solo'
						}
					},
					pictureMounting: {
						question: 'Imagem do local de instalação'
					},
					pictureFloor: {
						question: 'Imagem da zona entre o edifício e o local de instalação'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Informações sobre a cablagem da sua nova Wallbox',
		sections: {
			wallBreakthrough: {
				heading: 'Trabalhos de alvenaria necessários',
				questions: {
					innerWallBreakthroughs: {
						question: 'Número de aberturas de parede no interior da casa'
					},
					outerWallBreakthroughs: {
						question: 'Número de aberturas nas paredes exteriores'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Informações sobre a instalação elétrica',
		sections: {
			currentInstallation: {
				heading: 'Detalhes da instalação elétrica existente',
				questions: {
					overallFuse: {
						question: 'Fusível da casa em amperes'
					},
					overvoltageProtection: {
						question: 'Proteção contra sobretensão disponível'
					},
					lightningConductor: {
						question: 'Está disponível um para-raios'
					},
					equipotentialBonding: {
						question: 'A ligação equipotencial está disponível'
					},
					pictureDistributionBox: {
						question: 'Fotografia do armário do contador ou da caixa de distribuição'
					},
					pictureBackUpFuse: {
						question: 'Imagem dos fusíveis em frente ao contador de eletricidade e sua designação'
					}
				}
			}
		}
	}
};

const questionnaireDeLiteralsSv: typeof questionnaireDeLiteralsDe = {
	installationLocation: {
		description: 'Vänligen svara på följande frågor för att säkerställa att ditt hem uppfyller alla nödvändiga krav för en lyckad installation av din laddbox.',
		sections: {
			property: {
				heading: 'Detta är mitt hem',
				description: 'För att installationen av din laddbox ska gå smidigt till behöver vi följande information om din fastighet.',
				questions: {
					typeOfHouse: {
						question: 'Typ av hus',
						answerOptions: {
							singleFamilyHouse: 'Enfamiljshus',
							apartmentHouse: 'Flerfamiljshus',
							unknown: 'Annan byggnad'
						}
					},
					constructionYear: {
						question: 'Byggnadsår för huset eller senaste renovering av elsystemet',
						answerOptions: {
							before2000: 'Före år 2000',
							after2000: 'Efter år 2000',
							unknown: 'Jag vet inte'
						}
					},
					ownership: {
						question: 'Uppgifter om ägande',
						answerOptions: {
							selfOwnedHouse: 'Jag är ägare',
							ownersAssociation: 'Jag är en del av ägarkollektivet',
							rentalHouse: 'Jag är hyresgäst',
							unknwon: 'Annat ägande'
						}
					}
				}
			},
			installation: {
				heading: 'Det är här jag vill ha min nya laddbox',
				description: 'Meddela oss var du vill att din nya laddbox ska placeras.',
				hint: 'Se till att anslutningen sitter på vänster sida av fordonet.',
				questions: {
					location: {
						question: 'Installationsplats',
						answerOptions: {
							garageDry: 'Montering i garage',
							exteriorWallDamp: 'Montering på yttervägg',
							unknown: 'På en annan plats'
						}
					},
					pictureLocation: {
						question: 'Bild på installationsplatsen för laddboxen'
					}
				}
			}
		}
	},
	ownershipDetails: {
		description: 'Vänligen besvara följande frågor för att möjliggöra installationen av din laddbox.',
		sections: {
			consent: {
				heading: 'Jag försäkrar att en installation kan utföras på plats.',
				questions: {
					consentGiven: {
						question: 'Jag har de nödvändiga dokumenten och samtycke från ägaren eller ägarföreningen för att installera en laddbox.'
					}
				}
			}
		}
	},
	installationMountingDetailsOnWall: {
		description: 'Ange den information som krävs för montering på en yttervägg.',
		sections: {
			installation: {
				heading: 'Montering på yttervägg',
				questions: {
					mounting: {
						question: 'Hur ser väggen ut?',
						answerOptions: {
							onInsulatedWall: 'Isolerad vägg',
							onUninsulatedWall: 'Oisolerad vägg',
							unknown: 'Jag vet inte'
						}
					}
				}
			}
		}
	},
	installationMountingDetailsOnInsulatedWall: {
		description: 'Ange den information som krävs för montering på en isolerad yttervägg.',
		sections: {
			insulation: {
				heading: 'Detaljer om isolering',
				questions: {
					thickness: {
						question: 'Isoleringens tjocklek i cm'
					}
				}
			}
		}
	},
	installationMountingDetailsStand: {
		description: 'Ange nödvändig information för montering på stativ.',
		sections: {
			earthworks: {
				heading: 'Information om montering på stativ',
				questions: {
					distance: {
						question: 'Avstånd till elinstallation i meter'
					},
					floorCovering: {
						question: 'Markförhållanden',
						answerOptions: {
							asphalt: 'Asfalt',
							concreteTiles: 'Betong',
							pavingStones: 'Stenplattor',
							earthLawn: 'Jord/gräsmatta',
							unknown: 'Andra markförhållanden'
						}
					},
					pictureMounting: {
						question: 'Bild av installationsplatsen'
					},
					pictureFloor: {
						question: 'Bild av området mellan byggnaden och installationsplatsen'
					}
				}
			}
		}
	},
	masonryWorks: {
		description: 'Information om kabeldragningen för din nya laddbox',
		sections: {
			wallBreakthrough: {
				heading: 'Murningsarbete krävs',
				questions: {
					innerWallBreakthroughs: {
						question: 'Antal väggöppningar inne i huset'
					},
					outerWallBreakthroughs: {
						question: 'Antal väggöppningar genom ytterväggar'
					}
				}
			}
		}
	},
	electricalInstallation: {
		description: 'Information om den elektriska installationen',
		sections: {
			currentInstallation: {
				heading: 'Detaljer om den befintliga elinstallationen',
				questions: {
					overallFuse: {
						question: 'Husets säkring i ampere'
					},
					overvoltageProtection: {
						question: 'Överspänningsskydd är tillgängligt'
					},
					lightningConductor: {
						question: 'En åskledare är tillgänglig'
					},
					equipotentialBonding: {
						question: 'Potentialutjämning är tillgänglig'
					},
					pictureDistributionBox: {
						question: 'Bild på mätarskåpet eller fördelningslådan'
					},
					pictureBackUpFuse: {
						question: 'Bild på säkringarna framför elmätaren och deras beteckning'
					}
				}
			}
		}
	}
};

export const basicQuestionsQuestionnaireDeModelMock = (): QuestionnaireModel => {
	return {
		locales: {
			'de': questionnaireDeLiteralsDe,
			'en': questionnaireDeLiteralsEn,
			'es': questionnaireDeLiteralsEs,
			'fr': questionnaireDeLiteralsFr,
			'it': questionnaireDeLiteralsIt,
			'nl': questionnaireDeLiteralsNl,
			'pt': questionnaireDeLiteralsPt,
			'sv': questionnaireDeLiteralsSv
		},
		defaultLanguage: 'en',
		segments: [
			{
				key: 'installation-location',
				description: 'installationLocation.description',
				sections: [
					{
						key: 'property',
						heading: 'installationLocation.sections.property.heading',
						description: 'installationLocation.sections.property.description',
						questions: [
							{
								key: 'type-of-house',
								question: 'installationLocation.sections.property.questions.typeOfHouse.question',
								answerType: {
									type: AnswerTypeId.SINGLE_SELECT,
									options: {
										required: true,
										options: [
											{
												label: 'installationLocation.sections.property.questions.typeOfHouse.answerOptions.singleFamilyHouse',
												value: 'single-family-house'
											},
											{
												label: 'installationLocation.sections.property.questions.typeOfHouse.answerOptions.apartmentHouse',
												value: 'apartment-house'
											},
											{
												label: 'installationLocation.sections.property.questions.typeOfHouse.answerOptions.unknown',
												value: 'unknown'
											}
										]
									}
								}
							},
							{
								key: 'construction-year',
								question: 'installationLocation.sections.property.questions.constructionYear.question',
								answerType: {
									type: AnswerTypeId.SINGLE_SELECT,
									options: {
										required: true,
										options: [
											{
												label: 'installationLocation.sections.property.questions.constructionYear.answerOptions.before2000',
												value: 'before-2000'
											},
											{
												label: 'installationLocation.sections.property.questions.constructionYear.answerOptions.after2000',
												value: 'after-2000'
											},
											{
												label: 'installationLocation.sections.property.questions.constructionYear.answerOptions.unknown',
												value: 'unknown'
											}
										]
									}
								}
							},
							{
								key: 'ownership',
								question: 'installationLocation.sections.property.questions.ownership.question',
								answerType: {
									type: AnswerTypeId.SINGLE_SELECT,
									options: {
										required: true,
										options: [
											{
												label: 'installationLocation.sections.property.questions.ownership.answerOptions.selfOwnedHouse',
												value: 'self-owned-house'
											},
											{
												label: 'installationLocation.sections.property.questions.ownership.answerOptions.ownersAssociation',
												value: 'owners-association'
											},
											{
												label: 'installationLocation.sections.property.questions.ownership.answerOptions.rentalHouse',
												value: 'rental-house'
											},
											{
												label: 'installationLocation.sections.property.questions.ownership.answerOptions.unknwon',
												value: 'unknwon'
											}
										]
									}
								}
							}
						]
					},
					{
						key: 'installation',
						heading: 'installationLocation.sections.installation.heading',
						description: 'installationLocation.sections.installation.description',
						hint: 'installationLocation.sections.installation.hint',
						questions: [
							{
								key: 'location',
								question: 'installationLocation.sections.installation.questions.location.question',
								answerType: {
									type: AnswerTypeId.SINGLE_SELECT,
									options: {
										required: true,
										options: [
											{
												label: 'installationLocation.sections.installation.questions.location.answerOptions.garageDry',
												value: 'garage-dry'
											},
											{
												label: 'installationLocation.sections.installation.questions.location.answerOptions.exteriorWallDamp',
												value: 'exterior-wall-damp'
											},
											{
												label: 'installationLocation.sections.installation.questions.location.answerOptions.unknown',
												value: 'unknown'
											}
										]
									}
								}
							},
							{
								key: 'picture-location',
								question: 'installationLocation.sections.installation.questions.pictureLocation.question',
								answerType: {
									type: AnswerTypeId.PICTURE,
									options: {
										required: true
									}
								}
							}
						]
					}
				]
			},
			{
				key: 'ownership-details',
				description: 'ownershipDetails.description',
				businessRules: {
					combinator: QuestionnaireBusinessRuleCombinator.AND,
					rules: [
						{
							discriminator: 'question',
							key: 'installation-location.property.ownership',
							negation: true,
							comparator: QuestionnaireBusinessRuleComparator.EQUALS,
							value: 'self-owned-house'
						}
					]
				},
				sections: [
					{
						key: 'consent',
						heading: 'ownershipDetails.sections.consent.heading',
						questions: [
							{
								key: 'consent-given',
								question: 'ownershipDetails.sections.consent.questions.consentGiven.question',
								answerType: {
									type: AnswerTypeId.CONSENT,
									options: {
										required: false
									}
								}
							}
						]
					}
				]
			},
			{
				key: 'installation-mounting-details-on-wall',
				description: 'installationMountingDetailsOnWall.description',
				businessRules: {
					combinator: QuestionnaireBusinessRuleCombinator.AND,
					rules: [
						{
							discriminator: 'environment',
							key: 'wallboxType',
							negation: false,
							comparator: QuestionnaireBusinessRuleComparator.EQUALS,
							value: 'WALL_MOUNT'
						}
					]
				},
				sections: [
					{
						key: 'installation',
						heading: 'installationMountingDetailsOnWall.sections.installation.heading',
						questions: [
							{
								key: 'mounting',
								question: 'installationMountingDetailsOnWall.sections.installation.questions.mounting.question',
								answerType: {
									type: AnswerTypeId.SINGLE_SELECT,
									options: {
										required: true,
										options: [
											{
												label: 'installationMountingDetailsOnWall.sections.installation.questions.mounting.answerOptions.onInsulatedWall',
												value: 'on-insulated-wall'
											},
											{
												label: 'installationMountingDetailsOnWall.sections.installation.questions.mounting.answerOptions.onUninsulatedWall',
												value: 'on-uninsulated-wall'
											},
											{
												label: 'installationMountingDetailsOnWall.sections.installation.questions.mounting.answerOptions.unknown',
												value: 'unknown'
											}
										]
									}
								}
							}
						]
					}
				]
			},
			{
				key: 'installation-mounting-details-on-insulated-wall',
				description: 'installationMountingDetailsOnInsulatedWall.description',
				businessRules: {
					combinator: QuestionnaireBusinessRuleCombinator.AND,
					rules: [
						{
							discriminator: 'question',
							key: 'installation-mounting-details-on-wall.installation.mounting',
							negation: false,
							comparator: QuestionnaireBusinessRuleComparator.EQUALS,
							value: 'on-insulated-wall'
						}
					]
				},
				sections: [
					{
						key: 'insulation',
						heading: 'installationMountingDetailsOnInsulatedWall.sections.insulation.heading',
						questions: [
							{
								key: 'thickness',
								question: 'installationMountingDetailsOnInsulatedWall.sections.insulation.questions.thickness.question',
								answerType: {
									type: AnswerTypeId.NUMBER,
									options: {
										required: false,
										minValue: 0,
										maxValue: 80,
										precision: 0
									}
								}
							}
						]
					}
				]
			},
			{
				key: 'installation-mounting-details-stand',
				description: 'installationMountingDetailsStand.description',
				businessRules: {
					combinator: QuestionnaireBusinessRuleCombinator.AND,
					rules: [
						{
							discriminator: 'environment',
							key: 'wallboxType',
							negation: false,
							comparator: QuestionnaireBusinessRuleComparator.EQUALS,
							value: 'STAND_MOUNT'
						}
					]
				},
				sections: [
					{
						key: 'earthworks',
						heading: 'installationMountingDetailsStand.sections.earthworks.heading',
						questions: [
							{
								key: 'distance',
								question: 'installationMountingDetailsStand.sections.earthworks.questions.distance.question',
								answerType: {
									type: AnswerTypeId.NUMBER,
									options: {
										required: false,
										minValue: 0,
										maxValue: 100,
										precision: 0
									}
								}
							},
							{
								key: 'floor-covering',
								question: 'installationMountingDetailsStand.sections.earthworks.questions.floorCovering.question',
								answerType: {
									type: AnswerTypeId.MULTI_SELECT,
									options: {
										required: false,
										options: [
											{
												label: 'installationMountingDetailsStand.sections.earthworks.questions.floorCovering.answerOptions.asphalt',
												value: 'asphalt'
											},
											{
												label: 'installationMountingDetailsStand.sections.earthworks.questions.floorCovering.answerOptions.concreteTiles',
												value: 'concrete-tiles'
											},
											{
												label: 'installationMountingDetailsStand.sections.earthworks.questions.floorCovering.answerOptions.pavingStones',
												value: 'paving-stones'
											},
											{
												label: 'installationMountingDetailsStand.sections.earthworks.questions.floorCovering.answerOptions.earthLawn',
												value: 'earth-lawn'
											},
											{
												label: 'installationMountingDetailsStand.sections.earthworks.questions.floorCovering.answerOptions.unknown',
												value: 'unknown'
											}
										]
									}
								}
							},
							{
								key: 'picture-mounting',
								question: 'installationMountingDetailsStand.sections.earthworks.questions.pictureMounting.question',
								answerType: {
									type: AnswerTypeId.PICTURE,
									options: {
										required: true
									}
								}
							},
							{
								key: 'picture-floor',
								question: 'installationMountingDetailsStand.sections.earthworks.questions.pictureFloor.question',
								answerType: {
									type: AnswerTypeId.PICTURE,
									options: {
										required: true
									}
								}
							}
						]
					}
				]
			},
			{
				key: 'masonry-works',
				description: 'masonryWorks.description',
				sections: [
					{
						key: 'wall-breakthrough',
						heading: 'masonryWorks.sections.wallBreakthrough.heading',
						questions: [
							{
								key: 'inner-wall-breakthroughs',
								question: 'masonryWorks.sections.wallBreakthrough.questions.innerWallBreakthroughs.question',
								answerType: {
									type: AnswerTypeId.NUMBER,
									options: {
										required: false,
										minValue: 0,
										maxValue: 10,
										precision: 0
									}
								}
							},
							{
								key: 'outer-wall-breakthroughs',
								question: 'masonryWorks.sections.wallBreakthrough.questions.outerWallBreakthroughs.question',
								answerType: {
									type: AnswerTypeId.NUMBER,
									options: {
										required: false,
										minValue: 0,
										maxValue: 10,
										precision: 0
									}
								}
							}
						]
					}
				]
			},
			{
				key: 'electrical-installation',
				description: 'electricalInstallation.description',
				businessRules: {
					combinator: QuestionnaireBusinessRuleCombinator.AND,
					rules: [
						{
							discriminator: 'question',
							key: 'installation-location.property.construction-year',
							negation: false,
							comparator: QuestionnaireBusinessRuleComparator.EQUALS,
							value: 'before-2000'
						}
					]
				},
				sections: [
					{
						key: 'current-installation',
						heading: 'electricalInstallation.sections.currentInstallation.heading',
						questions: [
							{
								key: 'overall-fuse',
								question: 'electricalInstallation.sections.currentInstallation.questions.overallFuse.question',
								answerType: {
									type: AnswerTypeId.NUMBER,
									options: {
										required: true,
										minValue: 0,
										maxValue: 200,
										precision: 0
									}
								}
							},
							{
								key: 'overvoltage-protection',
								question: 'electricalInstallation.sections.currentInstallation.questions.overvoltageProtection.question',
								answerType: {
									type: AnswerTypeId.CONSENT,
									options: {
										required: false
									}
								}
							},
							{
								key: 'lightning-conductor',
								question: 'electricalInstallation.sections.currentInstallation.questions.lightningConductor.question',
								answerType: {
									type: AnswerTypeId.CONSENT,
									options: {
										required: false
									}
								}
							},
							{
								key: 'equipotential-bonding',
								question: 'electricalInstallation.sections.currentInstallation.questions.equipotentialBonding.question',
								answerType: {
									type: AnswerTypeId.CONSENT,
									options: {
										required: false
									}
								}
							},
							{
								key: 'picture-distribution-box',
								question: 'electricalInstallation.sections.currentInstallation.questions.pictureDistributionBox.question',
								answerType: {
									type: AnswerTypeId.PICTURE,
									options: {
										required: true
									}
								}
							},
							{
								key: 'picture-back-up-fuse',
								question: 'electricalInstallation.sections.currentInstallation.questions.pictureBackUpFuse.question',
								answerType: {
									type: AnswerTypeId.PICTURE,
									options: {
										required: false
									}
								}
							}
						]
					}
				]
			}
		]
	};
};
