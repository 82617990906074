import { ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { ButtonBarSectionAlign } from './ButtonBarSection.enums';

import './ButtonBarSection.scss';

export type ButtonBarSectionProps = {
	align?: ButtonBarSectionAlign,
	children?: ReactNode
};

export function ButtonBarSection(props: ButtonBarSectionProps) {
	const { align = ButtonBarSectionAlign.ALIGN_LEFT, children } = props;

	return (
		<div className={`button-bar-section ${buildCssClassNameFromEnumValue(align, 'button-bar-section')}`}>
			{children}
		</div>
	);
}
