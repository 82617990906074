import { customerModelMock } from '@abb-emobility/shared/api-integration';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';
import { UncoveredInstallationAddressPayloadModel } from '@abb-emobility/usertask/domain-model';

export const uncoveredInstallationAddressPayloadModelMock: Dto<UncoveredInstallationAddressPayloadModel> = {
	customer: customerModelMock,
	uncoveredAddress: {
		zip: '24103',
		city: 'Kiel',
		street: 'Kirchhofallee 999',
		countryCode: IsoCountryCode.DE
	}
};
