import { DecisionType, GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationPartnerOrderInformationGridOperatorDecisionInformationModel = {
	readonly decision: DecisionType,
	readonly gridOperator: GridOperatorModel,
	readonly files?: Array<DownloadFile>
};

export class InstallationPartnerOrderInformationGridOperatorDecisionInformationModelConverter extends ModelConverter<InstallationPartnerOrderInformationGridOperatorDecisionInformationModel> {
	override getDownloadFileFields(): Array<DownloadFileFieldNames<InstallationPartnerOrderInformationGridOperatorDecisionInformationModel> | ArrayOfDownloadFileFieldNames<InstallationPartnerOrderInformationGridOperatorDecisionInformationModel>> {
		return ['files'];
	}

	override getFieldConverterMapByModel(): Map<keyof InstallationPartnerOrderInformationGridOperatorDecisionInformationModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}

}
