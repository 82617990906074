import { ReactNode } from 'react';

import './DownloadLayout.scss';

export type DownloadLayoutProps = {
	children: ReactNode
};

export function DownloadLayout(props: DownloadLayoutProps) {
	const { children } = props;

	return (
		<section className="download-layout">
			{children}
		</section>
	);
}
