import { useContext } from 'react';

import { AppError } from '@abb-emobility/shared/error';

import { l10nContext } from './L10nContext';
import { L10nContextValue } from './L10nContextValue';

export const useL10n = (): L10nContextValue => {
	const context = useContext(l10nContext);
	if (context === null) {
		throw new AppError('No L10n context available');
	}
	return context;
};
