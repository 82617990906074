import { ChangeEvent, InputHTMLAttributes, ReactNode, useState } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap, Nullable } from '@abb-emobility/shared/util';

import './InputFile.scss';

export type InputFileProps = {
	label: string,
	notificationElement?: ReactNode,
	notification?: never,
	notificationLevel?: never,
	triggerLabel?: string,
	onChange?: (value: File) => void,
	disabled?: boolean,
	maxFileSize?: number,
	onMaxFileSizeExceeded?: (exceeded: boolean) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'multiple' | 'disabled' | 'max'>;

export function InputFile(props: InputFileProps) {

	const {
		label,
		notificationElement,
		triggerLabel = 'Select',
		required = false,
		onChange,
		disabled = false,
		placeholder = '',
		maxFileSize = Number.MAX_SAFE_INTEGER,
		onMaxFileSizeExceeded = null,
		...rest
	} = props;

	const l10n = useL10n();
	const [focus, setFocus] = useState<boolean>(false);
	const [displayFilename, setDisplayFilename] = useState<Nullable<string>>(null);

	// event handling
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedFiles = event.target.files;
		const selectedFile = selectedFiles?.[0] ?? null;
		if (selectedFile === null) {
			setDisplayFilename(null);
			return;
		}
		if (selectedFile.size > maxFileSize) {
			setDisplayFilename(null);
			event.target.value = '';
			if (onMaxFileSizeExceeded !== null) {
				onMaxFileSizeExceeded(true);
			}
			return;
		}
		if (onMaxFileSizeExceeded !== null) {
			onMaxFileSizeExceeded(false);
		}
		setDisplayFilename(selectedFile.name);
		if (onChange !== undefined) {
			onChange(selectedFile);
		}
	};

	const handleInputFocus = () => {
		setFocus(true);
	};

	const handleInputBlur = () => {
		setFocus(false);
	};

	const fieldClassMap = {
		'input-file__field': true,
		'input-file__field--disabled': disabled,
		'input-file__field--focus': focus
	};

	const displayValueClassMap = {
		'input-file__field__display-value': true,
		'input-file__field__display-value--filename': displayFilename !== null
	};

	const renderLabel = (): ReactNode => {
		const labelSuffix = required ? null : (
			<span className="input-file__label__suffix">
				{l10n.translate('omsCustomerUiPrimitive.input.optionalValueSuffix')}
			</span>
		);
		return (
			<span className="input-file__label">
				{label}
				{labelSuffix}
			</span>
		);
	};

	return (
		<label className="input-file">
			{renderLabel()}
			{notificationElement}
			<span className={buildCssClassStringFromClassMap(fieldClassMap)}>
				<span className={buildCssClassStringFromClassMap(displayValueClassMap)}>{displayFilename ?? placeholder}</span>
				<span className="input-file__field__trigger">{triggerLabel}</span>
				<input
					type="file"
					multiple={false}
					onChange={handleChange}
					disabled={disabled}
					className="input-file__field__input"
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					required={required}
					{...rest}
				/>
			</span>
		</label>
	);

}
