import { FooApiClientFactory, FooApiClientInterface } from '@abb-emobility/oms/api-integration';
import { FooModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const fooCollectionStoreName = 'foo-collection-crud';
export type FooCollectionStore = { [fooCollectionStoreName]: CrudCollectionStore<FooModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return FooApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<FooModel, FooCollectionStore, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<FooModel, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<FooModel, FooApiClientInterface>(fooCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<FooModel, FooApiClientInterface>(fooCollectionStoreName, createApiClient);

// Slice definition
export const fooCollectionSlice = createCrudCollectionSlice<FooModel, ThunkApiConfig>(
	fooCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = fooCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<FooModel, FooCollectionStore>(fooCollectionStoreName);
const countCollection = createCrudCountCollection<FooModel, FooCollectionStore>(fooCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<FooModel, FooCollectionStore>(fooCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<FooModel, FooCollectionStore>(fooCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<FooModel, FooCollectionStore>(fooCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<FooModel, FooCollectionStore>(fooCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const fooCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<FooModel, FooCollectionStore>;
