import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDateRangeFieldNames, DateRange, DateRangeFieldNames, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { InstallationPeriod } from './InstallationPeriod';

export type InstallerAppointment = {
	installerId: ModelPrimaryKey,
	period: DateRange,
	installationPeriod: InstallationPeriod
};

export class InstallerAppointmentConverter extends ModelConverter<InstallerAppointment> {

	override getDateRangeFields(): Array<DateRangeFieldNames<InstallerAppointment> | ArrayOfDateRangeFieldNames<InstallerAppointment>> {
		return ['period'];
	}

}

export type TradeAppointment = {
	tradeId: ModelPrimaryKey,
	installerAppointments: Array<InstallerAppointment>
};

export class TradeAppointmentConverter extends ModelConverter<TradeAppointment> {

	override getFieldConverterMapByModel(): Map<keyof TradeAppointment, ModelConverterInterface<InstallerAppointment>> {
		return new Map([
			['installerAppointments', new InstallerAppointmentConverter()]
		]);
	}

}
