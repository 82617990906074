// Adapted from https://stackoverflow.com/a/14919494

export enum FileSizeConversion {
	BINARY_IEC = 'BINARY_IEC',
	METRIC_SI = 'METRIC_SI'
}

export const formatFileSize = (bytes?: number, conversion = FileSizeConversion.BINARY_IEC, precision = 1) => {
	if (bytes === undefined) {
		return '';
	}

	const threshold = conversion === FileSizeConversion.METRIC_SI ? 1000 : 1024;

	if (Math.abs(bytes) < threshold) {
		return bytes + ' B';
	}

	const units = conversion === FileSizeConversion.METRIC_SI
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10 ** precision;

	do {
		bytes /= threshold;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);

	return bytes.toFixed(precision) + ' ' + units[u];
};
