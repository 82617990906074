import React from 'react';

import { ExpandableSection } from '@abb-emobility/oms/customer-ui-primitive';
import { useCustomerOrderInformationCollectionData } from '@abb-emobility/oms/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { OrderInformationAssembler } from './order-information-assembler/OrderInformationAssembler';

import './OrderInformation.scss';

export function OrderInformation() {

	const l10n = useL10n();

	const orderInformationCollectionData = useCustomerOrderInformationCollectionData();
	const orderInformationCollection = orderInformationCollectionData.query();

	const renderSections = () => {
		return orderInformationCollection.map((orderInformation) => {
			return (
				<ExpandableSection
					key={orderInformation.id}
					heading={l10n.translate(l10nLiteralFromEnumValue(orderInformation.type, 'omsCustomerApp.order.information.heading'))}
				>
					<OrderInformationAssembler orderInformationId={orderInformation.id} />
				</ExpandableSection>
			);
		});
	};

	return (
		<article className="order-information">
			{renderSections()}
		</article>
	);
}
