/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CustomerModel,
	CustomerModelConverter,
	InstallationDecisionType,
	InstallationProjectReason,
	InstallationTradeEffortModel,
	InstallationTradeEffortModelConverter,
	InstallationTradeModel,
	InstallationTradeModelConverter,
	InstallationUnfeasibleReason,
	PreCheckMediumModel,
	TaskCompletionCommentModel
} from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { Dto, TimeOfDay } from '@abb-emobility/shared/domain-model-foundation';
import {
	PersistedAnswersDto,
	QuestionnaireEnvironmentModel,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

type PreCheckRemotePayloadFoundationModel = {
	readonly tradeOptions: Array<InstallationTradeModel>,
	readonly customer: CustomerModel,
	readonly preferredTimesOfDay: Array<TimeOfDay>,
	readonly preferredMedium: PreCheckMediumModel,
	readonly questionnaire: AnyQuestionnaireModel,
	readonly questionnaireEnvironment: QuestionnaireEnvironmentModel,
	readonly answers: PersistedAnswersDto,
	// TODO: Add the member to the API and define it as mandatory; see https://jira.fiege.com/browse/ABB-1056
	readonly comments?: Array<TaskCompletionCommentModel>,
} & TaskPayloadFoundationModel;

type PreCheckRemotePayloadDecisionModel = {
	installationType?: InstallationDecisionType,
	installationTradeEfforts?: Array<InstallationTradeEffortModel>
} & PreCheckRemotePayloadFoundationModel;

export type PreCheckRemotePayloadUnfeasibleDecisionModel = {
	installationType?: InstallationDecisionType.UNFEASIBLE,
	decisionReason?: InstallationUnfeasibleReason
} & PreCheckRemotePayloadFoundationModel;

export type PreCheckRemotePayloadProjectDecisionModel = {
	installationType?: InstallationDecisionType.PROJECT,
	decisionReason?: InstallationProjectReason
} & PreCheckRemotePayloadFoundationModel;

export type PreCheckRemotePayloadFurtherInformationRequiredModel = {
	installationType?: InstallationDecisionType.FURTHER_INFORMATION_REQUIRED
} & PreCheckRemotePayloadFoundationModel;

export type AnyPreCheckRemotePayloadModel =
	| PreCheckRemotePayloadDecisionModel
	| PreCheckRemotePayloadFurtherInformationRequiredModel
	| PreCheckRemotePayloadUnfeasibleDecisionModel
	| PreCheckRemotePayloadProjectDecisionModel;

export class PreCheckRemotePayloadModelConverter extends ModelConverter<AnyPreCheckRemotePayloadModel> {

	override getFieldConverterMapByModel(
		model: Dto<AnyPreCheckRemotePayloadModel> | AnyPreCheckRemotePayloadModel
	): Map<keyof AnyPreCheckRemotePayloadModel, ModelConverterInterface<any>> {
		const map = new Map<
			string,
			ModelConverterInterface<CustomerModel> |
			ModelConverterInterface<InstallationTradeModel> |
			ModelConverterInterface<InstallationTradeEffortModel>
		>([
			['customer', new CustomerModelConverter()],
			['tradeOptions', new InstallationTradeModelConverter()]
		]);

		const isPreCheckRemotePayloadDecisionModel = (model: unknown): model is PreCheckRemotePayloadDecisionModel => {
			return 'installationTradeEfforts' in (model as AnyPreCheckRemotePayloadModel);
		};
		if (isPreCheckRemotePayloadDecisionModel(model)) {
			map.set('installationTradeEfforts', new InstallationTradeEffortModelConverter());
		}

		return map as Map<keyof AnyPreCheckRemotePayloadModel, ModelConverterInterface<any>>;
	}

}

export type AnyPreCheckRemoteEscalationPayloadModel = AnyPreCheckRemotePayloadModel & CustomerServiceTaskPayloadFoundationModel

/**
 * PreCheckRemoteEscalationPayloadModelConverter combines the functionality of:
 * - CustomerServiceTaskPayloadFoundationModelConverter
 * - PreCheckRemotePayloadModelConverter
 */
export class PreCheckRemoteEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<AnyPreCheckRemoteEscalationPayloadModel> {
	private payloadModelConverter = new PreCheckRemotePayloadModelConverter();

	override getFieldConverterMapByModel(model: Dto<AnyPreCheckRemoteEscalationPayloadModel> | AnyPreCheckRemoteEscalationPayloadModel): Map<keyof AnyPreCheckRemoteEscalationPayloadModel, ModelConverterInterface<any>> {

		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel(model))
		]);
	}
}
