import {
	customerModelMock,
	installationTradeModelMock01,
	installationTradeModelMock02, installationTradeModelMock03,
	installerModelMock01,
	installerModelMock02
} from '@abb-emobility/shared/api-integration';
import { InstallationPeriod } from '@abb-emobility/shared/domain-model';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';
import { getCalendarDay } from '@abb-emobility/shared/util';
import {
	ArrangeAppointmentAppointmentType,
	ArrangeAppointmentInstallationType,
	ArrangeAppointmentPayloadModel
} from '@abb-emobility/usertask/domain-model';

const earliestInstallationDate = getCalendarDay(3);
const earliestInstallationTimestamp = createTimestampDtoFromDate(earliestInstallationDate);
export const arrangeAppointmentPayloadModelMock: Dto<ArrangeAppointmentPayloadModel> = {
	appointmentType: ArrangeAppointmentAppointmentType.RESCHEDULED,
	installationType: ArrangeAppointmentInstallationType.REPLACEMENT,
	requestedTrades: [
		{
			trade: installationTradeModelMock01,
			expectedEffort: 8,
			installationPeriod: InstallationPeriod.BEGINNING,
			availableInstallers: [
				installerModelMock01,
				installerModelMock02
			]
		},
		{
			trade: installationTradeModelMock01,
			expectedEffort: 8,
			installationPeriod: InstallationPeriod.END,
			availableInstallers: [
				installerModelMock01,
				installerModelMock02
			]
		},
		{
			trade: installationTradeModelMock02,
			expectedEffort: 4,
			installationPeriod: InstallationPeriod.PERMANENT,
			availableInstallers: [
				installerModelMock01,
				installerModelMock02
			]
		},
		{
			trade: installationTradeModelMock03,
			expectedEffort: 2,
			installationPeriod: InstallationPeriod.FOLLOW_UP,
			availableInstallers: [
				installerModelMock01,
				installerModelMock02
			]
		}
	],
	customer: customerModelMock,
	earliestInstallationDate: earliestInstallationTimestamp
};
