import { ReactNode, useEffect, useMemo, useRef } from 'react';

import {
	ButtonBar,
	ButtonBarSection,
	ButtonBarSectionAlign,
	ButtonPrimary,
	ButtonTertiary,
	Form,
	readFormItemsFromForm
} from '@abb-emobility/oms/customer-ui-primitive';
import { clearFocus, scrollWindowToTop } from '@abb-emobility/shared/browser';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Answers,
	isConsentQuestion,
	isQuestionnaireModel,
	questionRelatesToBusinessRule
} from '@abb-emobility/shared/questionnaire';
import { Nullable } from '@abb-emobility/shared/util';

import { useQuestionnaire } from './Questionnaire.context';
import { Segment } from './segment/Segment';

export type QuestionnaireProps = {
	onSubmit: (answers: Answers) => void
};

export function Questionnaire(props: QuestionnaireProps) {
	const { onSubmit } = props;

	const l10n = useL10n();
	const questionnaire = useQuestionnaire();
	const formRef = useRef<Nullable<HTMLFormElement>>(null);

	useEffect(() => {
		scrollWindowToTop();
		clearFocus();
	}, [questionnaire.getCurrentSegment()]);

	useMemo(() => {
		const questionnaireModel = questionnaire.getQuestionnaire();
		if (isQuestionnaireModel(questionnaireModel)) {
			const applicationLocales = l10n.getLocales();
			const questionnaireLocales = questionnaireModel.locales;
			for (const [applicationLanguage, applicationLocale] of applicationLocales) {
				if (applicationLanguage in questionnaireLocales) {
					applicationLocale.addLiterals(questionnaireLocales[applicationLanguage], 'questionnaire');
					continue;
				}
				if (!(questionnaireModel.defaultLanguage in questionnaireLocales)) {
					throw new AppError(`Invalid default language ${questionnaireModel.defaultLanguage} in questionnaire.`);
				}
				applicationLocale.addLiterals(questionnaireLocales[questionnaireModel.defaultLanguage], 'questionnaire');
			}
		}
	}, []);

	// Handle changes of form fields that are relevant for questionnaire business rules
	// to keep validation of next and previous segments consistent
	const handleChange = (key: string): void => {
		const questionnaireModel = questionnaire.getQuestionnaire();
		if (questionRelatesToBusinessRule(questionnaireModel, key) || isConsentQuestion(questionnaireModel, key)) {
			storeAnswers();
		}
	};

	const handlePrevious = () => {
		storeAnswers();
		questionnaire.previous();
	};

	const handleNext = () => {
		storeAnswers();
		if (!questionnaire.hasNext()) {
			handleSubmit();
			return;
		}
		questionnaire.next();
	};

	const storeAnswers = (): void => {
		const formItems = formRef.current !== null ? readFormItemsFromForm(formRef.current) : [];
		questionnaire.updateAnswers(questionnaire.getCurrentSegment(), formItems);
	};

	const handleSubmit = () => {
		onSubmit(questionnaire.getAnswers());
	};

	const renderSubmitButton = (): ReactNode => {
		if (!questionnaire.hasNext()) {
			return (
				<ButtonPrimary
					label={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.submitButtonLabel')}
					type="submit"
					disabled={!questionnaire.hasConsent()}
				/>
			);
		}
		return (
			<ButtonPrimary
				label={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.nextSegmentButtonLabel')}
				type="submit"
				disabled={!questionnaire.hasConsent()}
			/>
		);
	};

	return (
		<Form onSubmit={handleNext} onChange={handleChange} ref={formRef}>
			<Segment />
			<ButtonBar>
				<ButtonBarSection>
					<ButtonTertiary
						label={l10n.translate('omsCustomerUiQuestionnaire.questionnaire.previousSegmentButtonLabel')}
						onClick={handlePrevious}
						disabled={!questionnaire.hasPrevious()}
						tabIndex={-1}
					/>
				</ButtonBarSection>
				<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
					{renderSubmitButton()}
				</ButtonBarSection>
			</ButtonBar>
		</Form>
	);
}
