import { useAuth } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { DeutscheBankCheckoutApiClientFactory, FormDataLabelKey } from '@abb-emobility/shared/psp-integration';
import { combineUrl, l10nLiteralFromEnumValue, Optional } from '@abb-emobility/shared/util';

import { createRouteUrl, PaymentResultRouteParam, RoutePath } from '../router/Routes';

let paymentRedirectPending = false;

export function usePaymentRedirect(orderId: ModelPrimaryKey): (errorCallback?: (error: AppError) => void) => void {

	const auth = useAuth();
	const l10n = useL10n();

	return (errorCallback?: (error: AppError) => void) => {
		const handlePaymentRedirect = async (): Promise<void> => {
			if (paymentRedirectPending) {
				return;
			}
			paymentRedirectPending = true;

			const paymentApiBaseUrl = new Optional(process.env['NX_PAYMENT_API_BASE_URL'])
				.getOrThrow(new AppError('Payment API base URL not defined'));

			const paymentSuccessRedirectUrl = combineUrl(window.location.href, createRouteUrl(
				RoutePath.PAYMENT_RESULT,
				['orderId', orderId],
				['paymentResult', PaymentResultRouteParam.PAYMENT_SUCCESS]
			));
			const paymentErrorRedirectUrl = combineUrl(window.location.href, createRouteUrl(
				RoutePath.PAYMENT_RESULT,
				['orderId', orderId],
				['paymentResult', PaymentResultRouteParam.PAYMENT_FAILED]
			));

			const pspApiClient = DeutscheBankCheckoutApiClientFactory.create(paymentApiBaseUrl, auth.getToken().get());
			const redirectUrl = await pspApiClient.getCheckoutUrl(
				orderId,
				paymentSuccessRedirectUrl,
				paymentErrorRedirectUrl,
				paymentErrorRedirectUrl,
				{
					locale: l10n.language ?? 'en',
					label: [
						{
							key: FormDataLabelKey.TITLE_CHECKOUT,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.TITLE_CHECKOUT, 'omsCustomerApp.abbPspIntegration.formData'))
						},
						{
							key: FormDataLabelKey.LABEL_CANCEL,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.LABEL_CANCEL, 'omsCustomerApp.abbPspIntegration.formData'))
						},
						{
							key: FormDataLabelKey.TITLE_PAYMENT_DATA,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.TITLE_PAYMENT_DATA, 'omsCustomerApp.abbPspIntegration.formData'))
						},
						{
							key: FormDataLabelKey.LABEL_AMOUNT,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.LABEL_AMOUNT, 'omsCustomerApp.abbPspIntegration.formData'))
						},
						{
							key: FormDataLabelKey.LABEL_DATE,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.LABEL_DATE, 'omsCustomerApp.abbPspIntegration.formData'))
						},
						{
							key: FormDataLabelKey.LABEL_MERCHANT,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.LABEL_MERCHANT, 'omsCustomerApp.abbPspIntegration.formData'))
						},
						{
							key: FormDataLabelKey.LABEL_IBAN,
							value: l10n.translate(l10nLiteralFromEnumValue(FormDataLabelKey.LABEL_IBAN, 'omsCustomerApp.abbPspIntegration.formData'))
						}
					]
				}
			);

			window.location.href = redirectUrl;
		};
		void handlePaymentRedirect().catch((e) => {
			if (errorCallback !== undefined) {
				errorCallback(e);
			}
		});
	};

}
