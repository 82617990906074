import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	InstallationPartnerDeltaInstallationPackageModel,
	InstallationPartnerDeltaInstallationPackageModelConverter
} from './InstallationPartnerDeltaInstallationPackageModel';
import { numericApiSafeguard } from '../converter/NumericSafeGuard';

export type OrderedInstallationPartnerDeltaInstallationPackageModel = {
	readonly amount: number,
	readonly deltaPackage: InstallationPartnerDeltaInstallationPackageModel,
	readonly salesNetPrice?: number,
	readonly salesGrossPrice?: number,
	readonly vatRate?: number
};

export class OrderedInstallationPartnerDeltaInstallationPackageModelConverter extends ModelConverter<OrderedInstallationPartnerDeltaInstallationPackageModel> {

	override getFieldConverterMapByModel(): Map<keyof OrderedInstallationPartnerDeltaInstallationPackageModel, ModelConverterInterface<InstallationPartnerDeltaInstallationPackageModel>> {
		return new Map([
			['deltaPackage', new InstallationPartnerDeltaInstallationPackageModelConverter()]
		]);
	}

	override getCustomConversionFields(): Map<keyof OrderedInstallationPartnerDeltaInstallationPackageModel, CustomConversion> {
		return new Map([
			['amount', numericApiSafeguard],
			['salesNetPrice', numericApiSafeguard],
			['salesGrossPrice', numericApiSafeguard],
			['vatRate', numericApiSafeguard]
		]);
	}

}
