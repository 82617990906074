import { CustomerOrderModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerOrderApiClient } from './CustomerOrderApiClient';
import { CustomerOrderApiClientMock } from './CustomerOrderApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerOrderApiClientInterface extends CrudApiClient<CustomerOrderModel> {
}

export class CustomerOrderApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerOrderApiClientInterface {
		if (process.env.NX_OMS_CUSTOMER_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new CustomerOrderApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerOrderApiClient().init(baseUrl, jsonWebToken);
	}

}
