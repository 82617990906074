import { useState } from 'react';

import { TaskLayout } from '@abb-emobility/oms/customer-ui-primitive';
import { TaskCompleteError } from '@abb-emobility/shared/business-error';
import { taskActionStatusFailedGroup } from '@abb-emobility/shared/data-provider-foundation';
import {
	AppError,
	NotFoundError,
	ValidationError,
	restoreError,
	AuthenticationFailedError,
	AuthenticationRequiredError
} from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { TaskType } from '@abb-emobility/usertask/domain-model';

import { TaskBasicQuestions } from './task-basic-questions/TaskBasicQuestions';
import { TaskInstallationApproval } from './task-installation-approval/TaskInstallationApproval';
import { TaskPayment } from './task-payment/TaskPayment';
import { TaskPreCheckInstallationUnfeasible } from './task-pre-check-installation-unfeasible/TaskPreCheckInstallationUnfeasible';
import { TaskPreCheckPreferences } from './task-pre-check-preferences/TaskPreCheckPreferences';
import { TaskPreCheckRejectedByGridOperator } from './task-pre-check-rejected-by-grid-operator/TaskPreCheckRejectedByGridOperator';
import { TaskQuotationAcceptance } from './task-quotation-acceptance/TaskQuotationAcceptance';
import { MaybeError } from '../../maybe-error/MaybeError';

export function TaskFactory() {

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const task = anyTaskEntityData.query().getOrThrow(new NotFoundError(l10n.translate('omsCustomerApp.error.taskNotFound')));
	const actionErrorObject = anyTaskEntityData.queryActionError();
	const [actionError, setActionError] = useState<Nullable<AppError>>(null);

	anyTaskEntityData.useActionStatusEffect(taskActionStatusFailedGroup, () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		if (error instanceof ValidationError) {
			setActionError(error);
			return;
		}
		throw new TaskCompleteError(l10n.translate('omsCustomerApp.error.taskCompleteMessage'), undefined, undefined, error);
	}, true);

	const handleDismissActionError = (): void => {
		setActionError(null);
	};

	const renderTask = () => {
		switch (task.type) {
			case TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS:
				return (<TaskBasicQuestions task={task} />);
			case TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES:
				return (<TaskPreCheckPreferences task={task} />);
			case TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION:
				return (<TaskPreCheckInstallationUnfeasible task={task} />);
			case TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION:
				return (<TaskPreCheckRejectedByGridOperator task={task} />);
			case TaskType.CUSTOMER_ACCEPT_QUOTATION:
				return (<TaskQuotationAcceptance task={task} />);
			case TaskType.CUSTOMER_PERFORM_PAYMENT:
				return (<TaskPayment task={task} />);
			case TaskType.CUSTOMER_APPROVE_INSTALLATION:
				return (<TaskInstallationApproval task={task} />);
			default:
				throw new NotFoundError(l10n.translate('omsCustomerApp.error.taskTypeNotFound', new Map([['taskType', task.type]])));
		}
	};

	return (
		<TaskLayout>
			<MaybeError error={actionError ?? undefined} onDismiss={handleDismissActionError} key="ValidationError" />
			{renderTask()}
		</TaskLayout>
	);

}
