import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerOrderInformationFoundationModel } from './foundation/CustomerOrderInformationFoundationModel';
import { CustomerOrderInformationType } from './foundation/CustomerOrderInformationType';

export type AnyCustomerOrderInformationCollectionItemModel = {
	type: CustomerOrderInformationType
} & CustomerOrderInformationFoundationModel;

export class AnyCustomerOrderInformationCollectionItemModelConverter extends ModelConverter<AnyCustomerOrderInformationCollectionItemModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerOrderInformationCollectionItemModel> | ArrayOfTimestampFieldNames<AnyCustomerOrderInformationCollectionItemModel>> {
		return ['createdAt'];
	}

}
