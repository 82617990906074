import { FooModel, FooModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient, FilterCriteria, RestResponse, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export class FooApiClient extends AbstractCrudApiClient<FooModel> {
	protected collectionControllerUri = 'foos/';
	protected paginationControllerUri = 'foos/';
	protected entityControllerUri = 'foo/{id}/';
	protected entitySearchControllerUri = 'foo/';
	protected modelConverter = new FooModelConverter();

	protected override preFetchCollection(
		endpoint: string,
		_sort?: SortCriteria<FooModel>,
		_filter?: FilterCriteria<FooModel>
	): void {
		console.debug(endpoint);
	}

	protected override async postFetchCollection(
		collection: Array<Dto<FooModel>>,
		response: RestResponse<unknown>
	): Promise<Array<Dto<FooModel>>> {
		console.debug(response.headers);
		return super.postFetchCollection(collection, response);
	}

	protected override preFetchPage(
		endpoint: string,
		_page: number,
		_sort?: SortCriteria<FooModel>,
		_filter?: FilterCriteria<FooModel>
	): void {
		console.debug(endpoint);
	}

	protected override async postFetchPage(
		collection: Array<Dto<FooModel>>,
		response: RestResponse<unknown>
	): Promise<Array<Dto<FooModel>>> {
		console.debug(response.headers);
		return super.postFetchPage(collection, response);
	}

	protected override preSearchEntity(endpoint: string, _filter: FilterCriteria<FooModel>) {
		console.debug(endpoint);
		super.preSearchEntity(endpoint, _filter);
	}

	protected override async postSearchEntity(apiModel: Dto<FooModel>, _response: RestResponse<unknown>): Promise<Dto<FooModel>> {
		return super.postSearchEntity(apiModel, _response);
	}
}
