import { ErrorNotification } from '@abb-emobility/oms/customer-ui-primitive';
import { DetailedError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';

export type MaybeErrorProps = {
	error?: Error,
	onDismiss?: () => void
};

export function MaybeError(props: MaybeErrorProps) {

	const { error, onDismiss } = props;

	const l10n = useL10n();

	if (error === undefined) {
		return null;
	}

	const handleDismiss = (): void => {
		if (onDismiss !== undefined) {
			onDismiss();
		}
	};

	const unwrapDetails = (): Array<string> => {
		if (error instanceof DetailedError) {
			return error.details.map((detail) => {
				return detail.message;
			});
		}
		return [];
	};

	return (
		<ErrorNotification
			heading={l10n.translate('omsCustomerApp.error.validation.heading')}
			message={l10n.translate('omsCustomerApp.error.validation.message')}
			details={unwrapDetails()}
			onDismiss={handleDismiss}
		/>
	);
}
