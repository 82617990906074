import { CustomConversion, ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { InstallationPeriod } from './InstallationPeriod';
import { numericApiSafeguard } from '../converter/NumericSafeGuard';

export type InstallationTradeEffortModel = {
	tradeId: ModelPrimaryKey,
	period: InstallationPeriod,
	effortInHours: number,
	approvable: boolean
};

export class InstallationTradeEffortModelConverter extends ModelConverter<InstallationTradeEffortModel> {
	override getCustomConversionFields(): Map<keyof InstallationTradeEffortModel, CustomConversion> {
		return new Map([
			['effortInHours', numericApiSafeguard]
		]);
	}

}
