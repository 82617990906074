import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { combineUrl, Optional } from '@abb-emobility/shared/util';

enum LegalDocumentType {
	GTC = 'gtc',
	PRIVACY = 'privacy',
	IMPRINT = 'imprint'
}

export const useLegaDocumentUrls = (marketplaceId: ModelPrimaryKey) => {

	const baseUrl = new Optional(process.env['NX_CUSTOMER_APP_API_BASE_URL'])
		.getOrThrow(new AppError('Customer API base URL missing'));

	const errorUrl = combineUrl(window.location.href, '404');

	const generalTermsUrl = combineUrl(
		baseUrl,
		'/document-urls/' + LegalDocumentType.GTC + '/' + marketplaceId,
		new Map([
			['errorUrl', errorUrl]
		])
	);

	const privacyNoticeUrl = combineUrl(
		baseUrl,
		'/document-urls/' + LegalDocumentType.PRIVACY + '/' + marketplaceId,
		new Map([
			['errorUrl', errorUrl]
		])
	);

	const imprintUrl = combineUrl(
		baseUrl,
		'/document-urls/' + LegalDocumentType.IMPRINT + '/' + marketplaceId,
		new Map([
			['errorUrl', errorUrl]
		])
	);

	return { generalTermsUrl, privacyNoticeUrl, imprintUrl };
};
