import { OrderImportB2cModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { OrderImportB2cApiClient } from './OrderImportB2cApiClient';
import { OrderImportB2cApiClientMock } from './OrderImportB2cApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderImportB2cApiClientInterface extends CrudApiClient<OrderImportB2cModel> {
}

export class OrderImportB2cApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): OrderImportB2cApiClientInterface {
		if (process.env.NX_OMS_ORDER_IMPORT_API_MOCKED === 'true') {
			const apiClientMock = new OrderImportB2cApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new OrderImportB2cApiClient().init(baseUrl, jsonWebToken);
	}

}
