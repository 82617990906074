import { CSSTransition } from 'react-transition-group';

import { useExpandable } from '@abb-emobility/shared/interaction';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './SectionHeader.scss';

export type SectionHeaderProps = {
	heading: string
};

export function SectionHeader(props: SectionHeaderProps) {
	const { heading } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const handleClick = () => {
		expandable.toggle();
	};

	const renderIcon = () => {
		return (
			<CSSTransition in={expanded} timeout={200} classNames="section-header__icon">
				<span className="section-header__icon">
					<Icon name={IconIdentifier.CARET_RIGHT} />
				</span>
			</CSSTransition>
		);
	};

	return (
		<header className="section-header" tabIndex={0} onClick={() => handleClick()}>
			<h1 className="section-header__heading">{heading}</h1>
			{renderIcon()}
		</header>
	);

}
