import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Vänligen välj',
			pictureSelectPlaceholder: 'Välj bild eller ta en bild',
			pictureSelectTrigger: 'Välj bild',
			pictureSelectHint: {
				default: 'Se till att det inte finns några personer i bilden.',
				examplePicture: 'Använd exempelbilden som vägledning och se till att det inte finns några personer i bilden.'
			},
			documentSelectPlaceholder: 'Välj ett dokument',
			documentSelectTrigger: 'Välj dokument',
			selectedFileTooLarge: 'Den valda filen är för stor för att kunna överföras. Vänligen välj en fil som är mindre än {{maxFileSize}}.',
			answerTypeNotFound: 'Svarstypen {{typ}} är okänd.',
			previousSegmentButtonLabel: 'Bakåt',
			nextSegmentButtonLabel: 'Nästa steg',
			submitButtonLabel: 'Skicka information',
			validationErrors: {
				heading: 'Det finns några problem med dina svar',
				message: 'Vänligen kontrollera dina svar för att fylla i frågeformuläret'
			}
		}
	}
};

export default literals;
