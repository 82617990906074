import { useEffect, useRef, useState } from 'react';

import { NotificationProps, NotificationLevel } from '@abb-emobility/oms/customer-ui-primitive';
import { AccessToken } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ApiError, NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { OtpAuthApiClientFactory } from '@abb-emobility/shared/otp-auth-integration';
import { Nullable, Optional } from '@abb-emobility/shared/util';

export enum AuthActivity {
	OTP_REQUESTABLE,
	OTP_REQUEST_PENDING,
	OTP_EXPECTABLE,
	OTP_SUBMITTABLE,
	OTP_SUBMISSION_PENDING,
	OTP_SUBMITTED,
	OTP_FAILED,
}

export const useOtpAuth = (
	orderId: ModelPrimaryKey,
	onAuthenticate: Nullable<(accessToken: AccessToken, accessTokenValidTo: Date) => void>
) => {

	const l10n = useL10n();
	const [authActivity, setAuthActivity] = useState<AuthActivity>(AuthActivity.OTP_REQUESTABLE);
	const [notification, setNotification] = useState<Nullable<NotificationProps>>(null);
	const [error, setError] = useState<Nullable<Error>>(null);
	const [oneTimePassword, setOneTimePassword] = useState<Nullable<string>>(null);

	const otpRequested = useRef<boolean>(false);

	const otpAuthApiBaseUrl = new Optional(process.env['NX_ORDER_API_BASE_URL'])
		.getOrThrow(new ApiError('API base URL unavailable'));

	const requestOtp = (): void => {
		otpRequested.current = false;
		setAuthActivity(AuthActivity.OTP_REQUESTABLE);
	};

	const exchangeOtp = (token: string): void => {
		setOneTimePassword(token);
		setAuthActivity(AuthActivity.OTP_SUBMITTABLE);
	};

	const performRequestOtp = async (): Promise<void> => {
		try {
			setAuthActivity(AuthActivity.OTP_REQUEST_PENDING);
			await OtpAuthApiClientFactory.create(otpAuthApiBaseUrl, orderId).requestOtp();
			setNotification(null);
			setAuthActivity(AuthActivity.OTP_EXPECTABLE);
		} catch (e) {
			if (e instanceof Error) {
				setError(e);
			} else {
				setNotification({
					level: NotificationLevel.ERROR,
					message: l10n.translate('omsCustomerApp.error.otpAuthInitFailed')
				});
			}
			setAuthActivity(AuthActivity.OTP_FAILED);
			console.warn(e);
		}
	};

	const performRequestJwt = async (): Promise<void> => {
		try {
			setAuthActivity(AuthActivity.OTP_SUBMISSION_PENDING);
			const responseBody = await OtpAuthApiClientFactory.create(otpAuthApiBaseUrl, orderId).resolveOtp(oneTimePassword);
			const accessToken = responseBody.access_token;

			setNotification(null);
			setAuthActivity(AuthActivity.OTP_SUBMITTED);

			if (onAuthenticate !== null) {
				const accessTokenValidTo = new Date();
				accessTokenValidTo.setSeconds(accessTokenValidTo.getSeconds() + responseBody.expires_in);
				onAuthenticate(accessToken, accessTokenValidTo);
			}
		} catch (e) {
			if (e instanceof NotFoundError) {
				setError(null);
				setNotification({
					level: NotificationLevel.ERROR,
					message: l10n.translate('omsCustomerApp.error.otpAuthReceiveTokenFailed')
				});
				setAuthActivity(AuthActivity.OTP_EXPECTABLE);
			} else {
				setError(e as Error);
				setNotification(null);
				setAuthActivity(AuthActivity.OTP_FAILED);
			}
		}
	};

	useEffect((): void => {
		switch (authActivity) {
			case AuthActivity.OTP_REQUESTABLE:
				// Request the submission of an OTP
				if (otpRequested.current) {
					return;
				}
				otpRequested.current = true;
				void performRequestOtp();
				break;
			case AuthActivity.OTP_SUBMITTABLE:
				// Submit the OTP
				void performRequestJwt();
				break;
			case AuthActivity.OTP_FAILED:
				otpRequested.current = false;
				break;
		}
	}, [authActivity]);

	return {
		authActivity,
		notification,
		error,
		requestOtp,
		exchangeOtp
	};
};
