import { AddressModel } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

export const addressModelMock: Dto<AddressModel> = {
	zip: '12345',
	city: 'Musterstadt',
	street: 'Musterstraße 1',
	countryCode: IsoCountryCode.DE
};
