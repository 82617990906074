import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
	FilterCriteria,
	HypermediaEntityResponse,
	HypermediaLink,
	TaskAssignResponse,
	TaskCompleteResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, ModelPrimaryKey, TaskEntityModel, TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { TaskEntitySearchStore } from './TaskEntitySearchStore';
import { FetchStatus } from '../FetchStatus';
import { HypermediaLinkFilter } from '../HypermediaLinkFilter';
import { HypermediaLinkSort } from '../HypermediaLinkSort';
import { TaskActionStatus } from '../TaskActionStatus';
import { AsyncThunkConfig } from '../ThunkApiConfig';

// Initial store
export const createInitialTaskSearchEntityStore = <TaskModel extends TaskEntityModel>() => {
	return {
		model: null,
		hypermediaLinks: [],
		searchStatus: FetchStatus.IDLE,
		lastSearchError: null,
		actionStatus: TaskActionStatus.IDLE,
		lastActionError: null
	} as TaskEntitySearchStore<TaskModel>;
};

// Action error identifier
export enum TaskEntitySearchErrorAction {
	SEARCH = 'SEARCH',
	COMPLETE = 'COMPLETE',
	ASSIGN = 'ASSIGN'
}

// Slice creator
export const createTaskEntitySearchSlice = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, ThunkApiConfig extends AsyncThunkConfig>(
	storeName: string,
	search: AsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, filter: FilterCriteria<TaskModel> }, ThunkApiConfig>,
	complete: AsyncThunk<Nullable<TaskCompleteResponse<TaskModel>>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, model: TaskModel, payload: Mutable<Payload> }, ThunkApiConfig>,
	assign: AsyncThunk<Nullable<TaskAssignResponse>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, model: TaskModel, candidateGroupIds: Array<ModelPrimaryKey> }, ThunkApiConfig>
) => {
	return createSlice({
		name: storeName,
		initialState: createInitialTaskSearchEntityStore<TaskModel>(),
		// Regular synchronous reducers
		reducers: {
			resetStore(store) {
				Object.assign(store, createInitialTaskSearchEntityStore<TaskModel>());
			},
			resolveActionStatus(store) {
				store.actionStatus = TaskActionStatus.IDLE;
			},
			resolveSearchStatus(store) {
				store.searchStatus = FetchStatus.IDLE;
			}
		},
		// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
		// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
		// handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
		// https://github.com/immerjs/immer.
		extraReducers: {
			[String(search.pending)]: (state) => {
				state.searchStatus = FetchStatus.PENDING;
			},
			[String(search.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<TaskModel>>>) => {
				if (action.payload.item !== null) {
					state.model = action.payload.item;
					state.hypermediaLinks = action.payload.hypermediaLinks;
					state.searchStatus = FetchStatus.SUCCESS;
				}
			},
			[String(search.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskEntitySearchErrorAction.SEARCH;
				state.lastSearchError = lastActionError;
				state.searchStatus = FetchStatus.FAILED;
			},
			[String(complete.pending)]: (state) => {
				state.actionStatus = TaskActionStatus.COMPLETE_PENDING;
			},
			[String(complete.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<TaskCompleteResponse<TaskModel>>>>) => {
				if (action.payload !== null) {
					const anyTaskModel = state.model;
					if (anyTaskModel !== null) {
						const result = {
							...anyTaskModel,
							status: action.payload.status,
							payload: action.payload.payload
						};
						state.model = result;
					}
					state.actionStatus = TaskActionStatus.COMPLETE_SUCCESS;
				}
			},
			[String(complete.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskEntitySearchErrorAction.COMPLETE;
				state.lastActionError = lastActionError;
				state.actionStatus = TaskActionStatus.COMPLETE_FAILED;
			},
			[String(assign.pending)]: (state) => {
				state.actionStatus = TaskActionStatus.ASSIGN_PENDING;
			},
			[String(assign.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<TaskAssignResponse>>>) => {
				if (action.payload !== null) {
					const anyTaskModel = state.model;
					if (anyTaskModel !== null) {
						const result = {
							...anyTaskModel,
							candidateGroupIds: action.payload.candidateGroupIds
						};
						state.model = result;
					}
					state.actionStatus = TaskActionStatus.ASSIGN_SUCCESS;
				}
			},
			[String(assign.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskEntitySearchErrorAction.ASSIGN;
				state.lastActionError = lastActionError;
				state.actionStatus = TaskActionStatus.ASSIGN_FAILED;
			}
		}
	});
};

// Selector creators
export const createTaskSelectFoundEntity = <Model extends TaskEntityModel, Store extends Record<string, TaskEntitySearchStore<Model>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyOptional<Model> => {
		return (rootStore): ReadonlyOptional<Model> => {
			return new ReadonlyOptional(rootStore[storeName].model ?? null);
		};
	};
};

export const createTaskSelectFoundHypermediaLinks = <Model extends TaskEntityModel, Store extends Record<string, TaskEntitySearchStore<Model>>>(storeName: string) => {
	return (sort?: HypermediaLinkSort, filter?: HypermediaLinkFilter): (rootStore: Store) => ReadonlyArray<HypermediaLink> => {
		return (rootStore): ReadonlyArray<HypermediaLink> => {
			let hypermediaLinks = [...rootStore[storeName].hypermediaLinks];
			if ((filter ?? null) !== null) {
				hypermediaLinks = hypermediaLinks.filter(filter as HypermediaLinkFilter);
			}
			if ((sort ?? null) !== null) {
				hypermediaLinks = [...hypermediaLinks].sort(sort);
			}
			return hypermediaLinks;
		};
	};
};

export const createTaskSearchStoreSize = <Model extends TaskEntityModel, Store extends Record<string, TaskEntitySearchStore<Model>>>(storeName: string) => {
	return (): (rootStore: Store) => Readonly<number> => {
		return (rootStore): Readonly<number> => {
			return rootStore[storeName].model === null ? 0 : 1;
		};
	};
};

export const createTaskSearchStoreEntryIds = <Model extends TaskEntityModel, Store extends Record<string, TaskEntitySearchStore<Model>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyArray<ModelPrimaryKey> => {
		return (rootStore): ReadonlyArray<ModelPrimaryKey> => {
			const model = rootStore[storeName].model;
			if (model === null) {
				return [];
			}
			return [model.id];
		};
	};
};
