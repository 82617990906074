import { FormSegement } from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { useQuestionnaire } from '../Questionnaire.context';
import { Sections } from '../sections/Sections';

export function Segment() {
	const l10n = useL10n();
	const questionnaire = useQuestionnaire();
	const segment = questionnaire.getCurrentSegment();

	const localizedDescription = l10n.translate('questionnaire.' + segment.description, undefined, segment.description);

	return (
		<FormSegement description={localizedDescription} key={segment.key}>
			<Sections />
		</FormSegement>
	);
}
