import { InstallationPartnerFiscalReportModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { InstallationPartnerFiscalReportApiClient } from './InstallationPartnerFiscalReportApiClient';
import { InstallationPartnerFiscalReportApiClientMock } from './InstallationPartnerFiscalReportApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InstallationPartnerFiscalReportApiClientInterface extends CrudApiClient<InstallationPartnerFiscalReportModel> {
}

export class InstallationPartnerFiscalReportApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): InstallationPartnerFiscalReportApiClientInterface {
		if (process.env.NX_OMS_IP_FISCAL_REPORTING_API_MOCKED === 'true') {
			const apiClientMock = new InstallationPartnerFiscalReportApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new InstallationPartnerFiscalReportApiClient().init(baseUrl, jsonWebToken);
	}

}
