import { CurrencyCode } from '@abb-emobility/shared/currency';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import {
	ArrayOfDownloadFileFieldNames,
	ArrayOfTimestampFieldNames,
	DownloadFile, DownloadFileFieldNames,
	ModelPrimaryKey,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

export enum InstallationPartnerFiscalReportState {
	CURRENT = 'CURRENT',
	CLOSED = 'CLOSED'
}

export type InstallationPartnerFiscalReportModel = {
	readonly id: ModelPrimaryKey,
	readonly month: Timestamp,
	readonly state: InstallationPartnerFiscalReportState,
	readonly installationCount: number,
	readonly netRevenue: number,
	readonly netRevenueCurrency: CurrencyCode,
	readonly download?: DownloadFile
};

export class InstallationPartnerFiscalReportModelConverter extends ModelConverter<InstallationPartnerFiscalReportModel> {

	override getTimestampFields(): Array<TimestampFieldNames<InstallationPartnerFiscalReportModel> | ArrayOfTimestampFieldNames<InstallationPartnerFiscalReportModel>> {
		return ['month'];
	}

	override getDownloadFileFields(): Array<DownloadFileFieldNames<InstallationPartnerFiscalReportModel> | ArrayOfDownloadFileFieldNames<InstallationPartnerFiscalReportModel>> {
		return ['download'];
	}

}
