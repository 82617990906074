import './ButtonBar.scss';
import { ReactNode } from 'react';

export type ButtonBarProps = {
	children?: ReactNode
};

export function ButtonBar(props: ButtonBarProps) {
	const { children } = props;

	return (
		<div className="button-bar">
			{children}
		</div>
	);
}
