import { ButtonHTMLAttributes } from 'react';

import './ButtonGhost.scss';

export type ButtonGhostProps = {
	label: string
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonGhost(props: ButtonGhostProps) {
	const { type = 'button', label, ...rest } = props;

	return (
		<button
			type={type}
			className="button-ghost"
			{...rest}
		>{label}</button>
	);
}
