/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { TimestampFieldNames, ArrayOfTimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { OrderImportEntryModelConverter } from './common/OrderImportEntryModel';
import { OrderImportPaymentModelConverter } from './common/OrderImportPaymentModel';
import { OrderImportRecipientModel, OrderImportRecipientModelConverter } from './common/OrderImportRecipientModel';
import { OrderImportCustomerSalutation } from './common/OrderImportSalutation';
import { OrderImportFoundationModel } from './foundation/OrderImportFoundationModel';

export type OrderImportB2bCustomerUnitModel = {
	id: string,
	name: string,
	description: string,
	vatRegistrationNumber: string,
	salesOrganisationId: string,
	taxGroupCode: string
};

class OrderImportB2bCustomerUnitModelConverter extends ModelConverter<OrderImportB2bCustomerUnitModel> {
}

export type OrderImportB2bCustomerModel = {
	customerId: string,
	firstName: string,
	lastName: string,
	emailAddress: string,
	languageCode: IsoLanguageCode,
	salutation: OrderImportCustomerSalutation,
	unit: OrderImportB2bCustomerUnitModel
};

class OrderImportB2bCustomerModelConverter extends ModelConverter<OrderImportB2bCustomerModel> {
	override getFieldConverterMapByModel(): Map<keyof OrderImportB2bCustomerModel, ModelConverterInterface<any>> {
		return new Map<keyof OrderImportB2bCustomerModel, ModelConverterInterface<any>>([
			['unit', new OrderImportB2bCustomerUnitModelConverter()]
		]);
	}
}

export type OrderImportB2bModel = OrderImportFoundationModel & {
	customer: OrderImportB2bCustomerModel,
	soldTo: OrderImportRecipientModel,
	contact: OrderImportRecipientModel
};

export class OrderImportB2bModelConverter extends ModelConverter<OrderImportB2bModel> {
	override getTimestampFields(): Array<TimestampFieldNames<OrderImportB2bModel> | ArrayOfTimestampFieldNames<OrderImportB2bModel>> {
		return ['orderCreationDateGMT'];
	}

	override getFieldConverterMapByModel(): Map<keyof OrderImportB2bModel, ModelConverterInterface<any>> {
		return new Map<keyof OrderImportB2bModel, ModelConverterInterface<any>>([
			['customer', new OrderImportB2bCustomerModelConverter()],
			['orderEntries', new OrderImportEntryModelConverter()],
			['payment', new OrderImportPaymentModelConverter()],
			['shipTo', new OrderImportRecipientModelConverter()],
			['billTo', new OrderImportRecipientModelConverter()],
			['soldTo', new OrderImportRecipientModelConverter()],
			['contact', new OrderImportRecipientModelConverter()]
		]);
	}
}
