import { createContext } from 'react';

import { Logger } from '../Logger';

export type LoggerContext = Logger & {
	provided: boolean
};

export const loggerContext = createContext<LoggerContext>({
	provided: false,
	logEvent(): Promise<void> {
		throw new Error('No Logger provided');
	},
	logMessage(): Promise<void> {
		throw new Error('No Logger provided');
	},
	logError(): Promise<void> {
		throw new Error('No Logger provided');
	}
});
