import { InstallationUnfeasibleReason } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export enum PreCheckInstallationUnfeasibleSolution {
	CANCEL_INSTALLATION = 'CANCEL_INSTALLATION',
	CANCEL_ORDER = 'CANCEL_ORDER'
}

export type PreCheckInstallationUnfeasiblePayloadModel = {
	readonly unfeasibleReason?: InstallationUnfeasibleReason,
	// TODO: Define as mandatory after the backend has integrated the member; see https://jira.fiege.com/browse/ABB-801
	readonly installationCancelable?: boolean,
	solution?: PreCheckInstallationUnfeasibleSolution
} & TaskPayloadFoundationModel;

export class PreCheckInstallationUnfeasiblePayloadModelConverter extends ModelConverter<PreCheckInstallationUnfeasiblePayloadModel> {}

export type PreCheckInstallationUnfeasibleEscalationPayloadModel =
	PreCheckInstallationUnfeasiblePayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

/**
 * PreCheckInstallationUnfeasibleEscalationPayloadModelConverter combines the functionality of the
 * - CustomerServiceTaskPayloadFoundationModelConverter
 * - PreCheckInstallationUnfeasiblePayloadModelConverter
 * */
export class PreCheckInstallationUnfeasibleEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<PreCheckInstallationUnfeasibleEscalationPayloadModel> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof PreCheckInstallationUnfeasibleEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel())
		]);
	}
}
