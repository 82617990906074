import { Nullable } from '@abb-emobility/shared/util';

import { QuestionnaireSection, QuestionnaireSegment } from '../types/QuestionnaireModel';
import { Answer, Answers } from '../types/answer/Answers';
import { AnyQuestion } from '../types/question/Question';

export const getAnswersByKey = (
	answers: Answers,
	answerKey: string
): Nullable<Answer> => {
	return answers?.[answerKey] ?? null;
};

export const answersContainKey = (
	answers: Answers,
	answerKey: string
): boolean => {
	return answers?.[answerKey] !== undefined;
};

export const answersContainOptionWithKey = (
	answers: Answers,
	answerKey: string
): boolean => {
	for (const key in answers) {
		if (key.startsWith(answerKey + '.')) {
			return true;
		}
	}
	return false;
};

export const buildAnswerKey = (
	segment: QuestionnaireSegment,
	section: QuestionnaireSection,
	question: AnyQuestion
): string => {
	return [segment.key, section.key, question.key].join('.');
};
