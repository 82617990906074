import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState
} from 'react';

import { FormItems } from '@abb-emobility/oms/customer-ui-primitive';
import { createUploadFileFromFile } from '@abb-emobility/shared/domain-model-foundation';
import {
	Answers,
	QuestionnaireEnvironmentModel,
	isSegmentInEffect,
	QuestionnaireSegment,
	segmentHasConsent,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';
import { Nullable } from '@abb-emobility/shared/util';

export type QuestionnaireContextValue = {
	getQuestionnaire: () => AnyQuestionnaireModel,
	getCurrentSegment: () => QuestionnaireSegment,
	previous: () => void,
	next: () => void,
	hasPrevious: () => boolean,
	hasNext: () => boolean,
	hasConsent: () => boolean,
	getAnswers: () => Answers,
	setAnswers: (answers: Answers) => void,
	updateAnswers: (
		questionnaireSegment: QuestionnaireSegment,
		formItems: FormItems
	) => void
};

export const questionnaireContext = createContext<QuestionnaireContextValue>({
	getQuestionnaire: (): AnyQuestionnaireModel => {
		throw new Error('No questionnaire provided');
	},
	getCurrentSegment: (): QuestionnaireSegment => {
		throw new Error('No questionnaire provided');
	},
	previous: (): void => {
		throw new Error('No questionnaire provided');
	},
	next: (): void => {
		throw new Error('No questionnaire provided');
	},
	hasPrevious: (): boolean => {
		throw new Error('No questionnaire provided');
	},
	hasNext: (): boolean => {
		throw new Error('No questionnaire provided');
	},
	hasConsent: (): boolean => {
		throw new Error('No questionnaire provided');
	},
	getAnswers: (): Answers => {
		throw new Error('No questionnaire provided');
	},
	setAnswers: (): void => {
		throw new Error('No questionnaire provided');
	},
	updateAnswers: (): void => {
		throw new Error('No questionnaire provided');
	}
});

export const useQuestionnaire = () => {
	return useContext(questionnaireContext);
};

export type QuestionnaireContextProviderProps = {
	questionnaire: AnyQuestionnaireModel,
	environment: QuestionnaireEnvironmentModel,
	children: ReactNode
};

export const QuestionnaireContextProvider = (props: QuestionnaireContextProviderProps) => {
	const { questionnaire, environment, children } = props;

	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [answers, setAnswers] = useState<Answers>({});
	const [updatableAnswers, setUpdatableAnswers] = useState<Map<string, FormItems>>(new Map());

	useEffect((): void => {
		const handleUpdatableAnswers = async (): Promise<void> => {
			const updatedAnswers: Answers = {};
			for (const segmentKey of updatableAnswers.keys()) {
				for (const answerKey in answers) {
					if (!answerKey.startsWith(segmentKey + '.')) {
						updatedAnswers[answerKey] = answers[answerKey];
					}
				}
				for (const formItem of updatableAnswers.get(segmentKey) ?? []) {
					const formItemValue = formItem.value;
					if (formItemValue instanceof File) {
						updatedAnswers[formItem.name] =
							await createUploadFileFromFile(formItemValue);
					} else {
						updatedAnswers[formItem.name] = formItemValue;
					}
				}
			}
			setAnswers(updatedAnswers);
		};
		void handleUpdatableAnswers();
	}, [updatableAnswers]);

	const _segmentInEffect = (index: number): boolean => {
		const segment = questionnaire.segments?.[index];
		if (segment === undefined) {
			return false;
		}
		return isSegmentInEffect(segment, answers, environment);
	};

	const _getPreviousIndex = (): Nullable<number> => {
		let previousSegmentIndex = currentIndex;
		do {
			previousSegmentIndex--;
			if (previousSegmentIndex < 0) {
				break;
			}
		} while (!_segmentInEffect(previousSegmentIndex));

		return previousSegmentIndex >= 0 ? previousSegmentIndex : null;
	};

	const _getNextIndex = (): Nullable<number> => {
		let nextSegmentIndex = currentIndex;
		do {
			nextSegmentIndex++;
			if (nextSegmentIndex > questionnaire.segments.length) {
				break;
			}
		} while (!_segmentInEffect(nextSegmentIndex));

		return nextSegmentIndex < questionnaire.segments.length
			? nextSegmentIndex
			: null;
	};

	const getQuestionnaire = (): AnyQuestionnaireModel => {
		return questionnaire;
	};

	const getCurrentSegment = (): QuestionnaireSegment => {
		return questionnaire.segments[currentIndex];
	};

	const hasPrevious = (): boolean => {
		return _getPreviousIndex() !== null;
	};

	const hasNext = (): boolean => {
		return _getNextIndex() !== null;
	};

	const previous = (): void => {
		const previousSegmentIndex = _getPreviousIndex();
		if (previousSegmentIndex === null) {
			return;
		}
		setCurrentIndex(previousSegmentIndex);
	};

	const next = (): void => {
		const nextSegmentIndex = _getNextIndex();
		if (nextSegmentIndex === null) {
			return;
		}
		setCurrentIndex(nextSegmentIndex);
	};

	const hasConsent = (): boolean => {
		const segment = questionnaire.segments[currentIndex];
		return segmentHasConsent(segment, answers);
	};

	const providerValue = {
		getQuestionnaire,
		getCurrentSegment,
		previous,
		next,
		hasPrevious,
		hasNext,
		hasConsent,
		getAnswers: (): Answers => {
			return answers;
		},
		setAnswers: (answers: Answers): void => {
			setAnswers(answers);
		},
		updateAnswers: (
			questionnaireSegment: QuestionnaireSegment,
			formItems: FormItems
		): void => {
			setUpdatableAnswers(
				new Map([[questionnaireSegment.key, formItems]])
			);
		}
	};

	return (
		<questionnaireContext.Provider value={providerValue}>
			{children}
		</questionnaireContext.Provider>
	);
};
