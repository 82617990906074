import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationPartnerModel = {
	readonly id: ModelPrimaryKey,
	readonly name: string,
	readonly mailAddress?: string,
	readonly phone?: string
};

export class InstallationPartnerModelConverter extends ModelConverter<InstallationPartnerModel> {
}
