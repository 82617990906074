/* eslint-disable @typescript-eslint/no-empty-function */
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	ModelConverter,
	TaskCollectionModel,
	TaskEntityModel,
	TaskPayloadModel,
	Mutable,
	Dto,
	ModelPrimaryKey
} from '@abb-emobility/shared/domain-model-foundation';
import { ApiError, NotFoundError, TooEarlyError } from '@abb-emobility/shared/error';
import { delay, Nullable } from '@abb-emobility/shared/util';

import { TaskCollectionApiClient } from './TaskCollectionApiClient';
import { TaskEntityApiClient } from './TaskEntityApiClient';
import { TaskAssignResponse } from './response/TaskAssignResponse';
import { TaskCompleteApiResponse } from './response/TaskCompleteResponse';
import { ApiUrlBuilder } from '../api-url-builder/ApiUrlBuilder';
import { FilterCriteria } from '../collection-criteria/FilterCriteria';
import { SortCriteria } from '../collection-criteria/SortCriteria';
import {
	CollectionPaginationResponse,
	extractPaginationResponse,
	PaginatedCollectionResponse
} from '../collection-pagination/PaginatedCollectionResponse';
import { extractHypermediaResponse, HypermediaEntityResponse } from '../hypermedia/HypermdiaEntityResponse';
import { JsonRestRequestFactory } from '../network/JsonRestRequestFactory';
import { RestResponse } from '../network/JsonRestRequestInterface';

export abstract class AbstractTaskCollectionApiClient<TaskModel extends TaskCollectionModel>
	implements TaskCollectionApiClient<TaskModel> {

	protected abstract collectionControllerUri: string;
	protected abstract paginationControllerUri: string;
	protected abstract entityControllerUri: string;
	protected abstract entitySearchControllerUri: string;

	protected paginationSize = 50;

	protected abstract modelConverter: ModelConverter<TaskModel>;

	private apiUrlBuilder: Nullable<ApiUrlBuilder<TaskModel>> = null;
	protected jsonWebToken: Nullable<JsonWebToken> = null;

	public init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		this.apiUrlBuilder = new ApiUrlBuilder<TaskModel>(
			baseUrl,
			this.collectionControllerUri,
			this.paginationControllerUri,
			this.entityControllerUri,
			this.entitySearchControllerUri,
			this.paginationSize
		);
		this.jsonWebToken = jsonWebToken;
		return this;
	}

	public getUrlBuilder(): ApiUrlBuilder<TaskModel> {
		if (this.apiUrlBuilder === null) {
			throw new ApiError('CRUD API not initialized');
		}
		return this.apiUrlBuilder;
	}

	protected preFetchCollection(_endpoint: string, _sort?: SortCriteria<TaskModel>, _filter?: FilterCriteria<TaskModel>): void {
	}

	protected async postFetchCollection(
		collection: Array<Dto<TaskModel>>,
		_response: RestResponse<unknown>
	): Promise<Array<Dto<TaskModel>>> {
		return collection;
	}

	protected preHeadCollection(_endpoint: string, _filter?: FilterCriteria<TaskModel>): void {
	}

	protected preFetchPage(_endpoint: string, _page: number, _sort?: SortCriteria<TaskModel>, _filter?: FilterCriteria<TaskModel>): void {
	}

	protected async postFetchPage(
		collection: Array<Dto<TaskModel>>,
		_response: RestResponse<unknown>
	): Promise<Array<Dto<TaskModel>>> {
		return collection;
	}

	protected preFetch(_endpoint: string, _id: ModelPrimaryKey): void {
	}

	protected async postFetch(apiModel: Dto<TaskModel>, _response: RestResponse<unknown>): Promise<Dto<TaskModel>> {
		return apiModel;
	}

	protected preSearchEntity(_endpoint: string, _filter: FilterCriteria<TaskModel>): void {
	}

	protected async postSearchEntity(apiModel: Dto<TaskModel>, _response: RestResponse<unknown>): Promise<Dto<TaskModel>> {
		return apiModel;
	}

	protected preAssign(_endpoint: string, _id: ModelPrimaryKey, _assignment: Array<ModelPrimaryKey>): void {
	}

	protected async postAssign(assignResponse: TaskAssignResponse, _response: RestResponse<unknown>): Promise<TaskAssignResponse> {
		return assignResponse;
	}

	async fetchCollection(
		sort?: SortCriteria<TaskModel>,
		filter?: FilterCriteria<TaskModel>
	): Promise<PaginatedCollectionResponse<TaskModel>> {
		const endpoint = this.getUrlBuilder().buildCollectionEndpoint(sort, filter);
		this.preFetchCollection(endpoint, sort, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let modelCollection = parsedResponse.body as Array<Dto<TaskModel>>;
		modelCollection = await this.postFetchCollection(modelCollection, parsedResponse);
		const items = modelCollection.map((apiModel): TaskModel => {
			return this.modelConverter.toModel(apiModel);
		});
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}

	async headCollection(filter?: FilterCriteria<TaskModel>): Promise<CollectionPaginationResponse> {
		const endpoint = this.getUrlBuilder().buildCollectionEndpoint(undefined, filter);
		this.preHeadCollection(endpoint, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).head(endpoint);
		return extractPaginationResponse(parsedResponse.headers);
	}

	async fetchPage(
		page = 1,
		sort?: SortCriteria<TaskModel>,
		filter?: FilterCriteria<TaskModel>
	): Promise<PaginatedCollectionResponse<TaskModel>> {
		const endpoint = this.getUrlBuilder().buildPaginationEndpoint(page, sort, filter);
		this.preFetchPage(endpoint, page, sort, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let modelCollection = parsedResponse.body as Array<Dto<TaskModel>>;
		modelCollection = await this.postFetchCollection(modelCollection, parsedResponse);
		const items = modelCollection.map((apiModel): TaskModel => {
			return this.modelConverter.toModel(apiModel);
		});
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}

	async fetchEntity(id: ModelPrimaryKey): Promise<HypermediaEntityResponse<TaskModel>> {
		const endpoint = this.getUrlBuilder().buildEntityEndpoint(id);
		this.preFetch(endpoint, id);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let apiModel = parsedResponse.body as Dto<TaskModel>;
		apiModel = await this.postFetch(apiModel, parsedResponse);
		return { item: this.modelConverter.toModel(apiModel), hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	async searchEntity(filter: FilterCriteria<TaskModel>, retry = 0): Promise<HypermediaEntityResponse<TaskModel>> {
		const endpoint = this.getUrlBuilder().buildSearchEntityEndpoint(filter);
		this.preSearchEntity(endpoint, filter);
		try {
			const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
			let apiModel = parsedResponse.body as Dto<TaskModel>;
			apiModel = await this.postSearchEntity(apiModel, parsedResponse);
			return { item: this.modelConverter.toModel(apiModel), hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
		} catch (error) {
			if (error instanceof NotFoundError || error instanceof TooEarlyError) {
				if (retry >= 3) {
					throw error;
				}
				await delay(5000);
				return this.searchEntity(filter, retry + 1);
			}
			throw error;
		}
	}

	async assign(id: ModelPrimaryKey, candidateGroupIds: Array<ModelPrimaryKey>): Promise<Nullable<TaskAssignResponse>> {
		const endpoint = this.getUrlBuilder().buildEntityMethodEndpoint(id, 'assign');
		this.preAssign(endpoint, id, candidateGroupIds);
		const apiPayload = {
			candidateGroupIds
		};
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).put(endpoint, apiPayload);
		let assignResponse = parsedResponse.body as TaskAssignResponse;
		assignResponse = await this.postAssign(assignResponse, parsedResponse);
		return assignResponse;
	}

}

export abstract class AbstractTaskEntityApiClient<TaskModel extends TaskEntityModel, TaskModelPayload extends TaskPayloadModel<TaskModel>>
	extends AbstractTaskCollectionApiClient<TaskModel>
	implements TaskEntityApiClient<TaskModel, TaskModelPayload> {

	protected preComplete(_endpoint: string, _id: ModelPrimaryKey, _payload: Mutable<TaskModelPayload>): void {
	}

	protected async postComplete(completeResponse: TaskCompleteApiResponse, _response: RestResponse<unknown>): Promise<TaskCompleteApiResponse> {
		return completeResponse;
	}

	async complete(id: ModelPrimaryKey, model: TaskModel, payload: Mutable<TaskModelPayload>): Promise<Nullable<TaskCompleteApiResponse>> {
		const endpoint = this.getUrlBuilder().buildEntityMethodEndpoint(id, 'complete');
		this.preComplete(endpoint, model.id, payload);
		const payloadModelConverter = this.modelConverter.getFieldConverterByModel('payload', model);
		let apiPayload = undefined;
		if (payloadModelConverter !== null) {
			apiPayload = payloadModelConverter.toDto(payload);
		}
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).put(endpoint, apiPayload);
		let completeResponse = parsedResponse.body as TaskCompleteApiResponse;
		completeResponse = await this.postComplete(completeResponse, parsedResponse);
		return completeResponse;
	}

}
