export enum PaymentResultRouteParam {
	PAYMENT_SUCCESS = 'payment-success',
	PAYMENT_FAILED = 'payment-failed'
}

export enum RoutePath {
	ORDER = '/order/:orderId',
	TASK = '/:orderId/task/:taskId',
	PAYMENT_RESULT = '/:orderId/payment-result/:paymentResult',
	DOCUMENTS = '/:orderId/documents'
}

export type RouteParameter = [string, string];

export const createRouteUrl = (routePath: RoutePath, ...parameters: Array<RouteParameter>): string => {
	let url = String(routePath);
	for (const parameter of parameters) {
		url = url.replace(':' + parameter[0], parameter[1]);
	}
	return url;
};
