import { JsonRestRequestFactory } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { FormData } from './DeutscheBankCheckoutApiClient.types';
import { DeutscheBankCheckoutApiClientInterface } from './DeutscheBankCheckoutApiClientInterface';

type FormCustomerContinuation = {
	successUrl: string,
	errorUrl: string,
	notificationFailedUrl: string
};

type RequestBody = {
	formCustomerContinuation: FormCustomerContinuation,
	formData: FormData
};

type ResponseBody = {
	redirectUrl: string
};

export class DeutscheBankCheckoutApiClient implements DeutscheBankCheckoutApiClientInterface {

	protected readonly apiBaseUrl: string;
	private readonly jsonWebToken: Nullable<JsonWebToken>;

	constructor(apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) {
		this.apiBaseUrl = apiBaseUrl;
		this.jsonWebToken = jsonWebToken;
	}

	async getCheckoutUrl(
		orderId: ModelPrimaryKey,
		successUrl: string,
		errorUrl: string,
		notificationFailedUrl: string,
		formData: FormData
	): Promise<string> {
		const endpointUrl = this.apiBaseUrl + 'directpos/' + orderId + '/checkout';
		const requestBody: RequestBody = {
			formCustomerContinuation: {
				successUrl,
				errorUrl,
				notificationFailedUrl
			},
			formData
		};

		const result = await JsonRestRequestFactory.create(this.jsonWebToken).put(endpointUrl, requestBody);
		return (result.body as ResponseBody).redirectUrl;
	}

}
