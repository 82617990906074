import { ReactElement, ReactNode } from 'react';

import { ButtonGhost, ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderEntityDataProvider } from '@abb-emobility/oms/data-provider';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import {
	ErrorHandler,
	SilentErrorHandler,
	NotFoundError,
	AuthenticationFailedError,
	AuthenticationRequiredError
} from '@abb-emobility/shared/error';
import { useDialogue } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { TaskType } from '@abb-emobility/usertask/domain-model';

import { TaskFactory } from './task-factory/TaskFactory';
import { useBuildPageTitle } from '../../hooks/PageTitle.hook';
import { CustomerServiceDialogue } from '../dialogue/customer-service-dialogue/CustomerServiceDialogue';
import { TaskOrderProgress } from '../task-order-progress/TaskOrderProgress';

import './Task.scss';

export function Task() {

	const auth = useAuth();
	const l10n = useL10n();
	const dialogue = useDialogue();

	const anyTaskEntityData = useAnyTaskEntityData();
	const taskModel = anyTaskEntityData.query().getOrThrow(new NotFoundError(l10n.translate('omsCustomerApp.error.taskNotFound')));
	const taskActionState = anyTaskEntityData.queryActionStatus();

	usePageTitle(useBuildPageTitle('omsCustomerApp.pageTitle.task', new Map([
		['marketplaceId', taskModel.marketplaceOrderId],
		['taskType', l10n.translate(l10nLiteralFromEnumValue(taskModel.type, 'omsCustomerApp.task.shortName'))]
	])));

	const taskTypesWithoutOrderProgress = [TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION, TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION];

	const handleError = (error: Error): Nullable<ReactElement> => {
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth.reauthenticate();
		}

		const reloadAction: ErrorFeedbackAction = {
			label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
			onInvoke: (): void => {
				window.location.reload();
			}
		};

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				actions={[reloadAction]}
				key="OmsGenericError"
			/>
		);
	};

	const handleHelpRequest = (): void => {
		dialogue.present(() => {
			return (<CustomerServiceDialogue orderId={taskModel.orderId} />);
		});
	};

	const renderFooter = (): ReactNode => {
		if (taskActionState === TaskActionStatus.IDLE) {
			return (
				<footer className="task__footer">
					<ButtonGhost
						label={l10n.translate('omsCustomerApp.customerServiceDialogue.dialogueTriggerLabel')}
						onClick={handleHelpRequest}
					/>
				</footer>
			);
		}
		return null;
	};

	const renderOrderProgress = (): ReactNode => {
		if (taskTypesWithoutOrderProgress.includes(taskModel.type)) {
			return null;
		}
		return (
			<header className="task__header">
				<SilentErrorHandler handleError={() => true}>
					<CustomerOrderEntityDataProvider
						primaryKey={taskModel.orderId}
						suspense={false}
					>
						<TaskOrderProgress />
					</CustomerOrderEntityDataProvider>
				</SilentErrorHandler>
			</header>
		);
	};

	return (
		<article className="task">
			{renderOrderProgress()}
			<main className="task__main">
				<ErrorHandler errorComponent={handleError}>
					<TaskFactory />
				</ErrorHandler>
			</main>
			{renderFooter()}
		</article>
	);

}
