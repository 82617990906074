import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import {
	Answers,
	QuestionnaireEnvironmentModel,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type BasicQuestionsPayloadModel = {
	readonly questionnaire: AnyQuestionnaireModel,
	readonly questionnaireEnvironment: QuestionnaireEnvironmentModel,
	answers?: Answers,
	marketingConsentGiven?: boolean,
	surveyConsentGiven?: boolean
} & TaskPayloadFoundationModel;

export class BasicQuestionsPayloadModelConverter extends ModelConverter<BasicQuestionsPayloadModel> {
}

export type BasicQuestionsEscalationPayloadModel = BasicQuestionsPayloadModel & CustomerServiceTaskPayloadFoundationModel;

export class BasicQuestionsEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<BasicQuestionsEscalationPayloadModel> {
}
