export enum FormDataLabelKey {
	TITLE_CHECKOUT = 'TITLE_CHECKOUT',
	LABEL_CANCEL = 'LABEL_CANCEL',
	TITLE_PAYMENT_DATA = 'TITLE_PAYMENT_DATA',
	LABEL_AMOUNT = 'LABEL_AMOUNT',
	LABEL_DATE = 'LABEL_DATE',
	LABEL_MERCHANT = 'LABEL_MERCHANT',
	LABEL_IBAN = 'LABEL_IBAN'
}

type FormDataLabel = {
	key: FormDataLabelKey,
	value: string
};

export type FormData = {
	locale: string,
	label: Array<FormDataLabel>
};
