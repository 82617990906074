import { CurrencyCode } from '@abb-emobility/shared/currency';
import { InstallationPartnerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const installationPartnerDeltaInstallationPackageModelMock01: Dto<InstallationPartnerDeltaInstallationPackageModel> = {
	id: '1',
	name: 'Delta-Installtionspaket 1',
	description: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren no sea takimata sanctus est Lorem ipsum dolor sit amet.',
	category: 'Curabitur blandit tempus porttitor.',
	purchaseNetPrice: parseInt((8350 / 1.19).toFixed(0)),
	salesNetPrice: 12750,
	salesGrossPrice: parseInt((12750 * 1.19).toFixed(0)),
	vatRate: 19,
	currencyCode: CurrencyCode.EUR,
	includedGoodsInternal: [
		'Kabellänge bis 30 Meter',
		'Materialkosten bis 200 €',
		'Personenstd. bis 20 Stunden',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €'
	],
	includedGoodsPublic: [
		'Benötigte Kabellänge',
		'Materialkosten',
		'Personenstunden',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service'
	]
};

export const installationPartnerDeltaInstallationPackageModelMock02: Dto<InstallationPartnerDeltaInstallationPackageModel> = {
	id: '2',
	name: 'Delta-Installtionspaket 2',
	description: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren no sea takimata sanctus est Lorem ipsum dolor sit amet.',
	category: 'Curabitur blandit tempus porttitor.',
	purchaseNetPrice: parseInt((1123 / 1.19).toFixed(0)),
	salesNetPrice: 4099,
	salesGrossPrice: parseInt((4099 * 1.19).toFixed(0)),
	vatRate: 19,
	currencyCode: CurrencyCode.EUR,
	includedGoodsInternal: [
		'Kabellänge bis 30 Meter',
		'Materialkosten bis 200 €',
		'Personenstd. bis 20 Stunden',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €'
	],
	includedGoodsPublic: [
		'Benötigte Kabellänge',
		'Materialkosten',
		'Personenstunden',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service'
	]
};

export const installationPartnerDeltaInstallationPackageModelMock03: Dto<InstallationPartnerDeltaInstallationPackageModel> = {
	id: '3',
	name: 'Delta-Installtionspaket 3',
	description: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren no sea takimata sanctus est Lorem ipsum dolor sit amet.',
	category: 'Donec id elit non mi porta gravida at eget metus.',
	purchaseNetPrice: parseInt((4502 / 1.19).toFixed(0)),
	salesNetPrice: 5980,
	salesGrossPrice: parseInt((5980 * 1.19).toFixed(0)),
	vatRate: 19,
	currencyCode: CurrencyCode.EUR,
	includedGoodsInternal: [
		'Kabellänge bis 30 Meter',
		'Materialkosten bis 200 €',
		'Personenstd. bis 20 Stunden',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €'
	],
	includedGoodsPublic: [
		'Benötigte Kabellänge',
		'Materialkosten',
		'Personenstunden',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service'
	]
};

export const installationPartnerDeltaInstallationPackageModelMock04: Dto<InstallationPartnerDeltaInstallationPackageModel> = {
	id: '4',
	name: 'Delta-Installtionspaket 4',
	description: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren no sea takimata sanctus est Lorem ipsum dolor sit amet.',
	category: 'Donec ullamcorper nulla non metus auctor fringilla.',
	purchaseNetPrice: parseInt((32323 / 1.19).toFixed(0)),
	salesNetPrice: 45323,
	salesGrossPrice: parseInt((45323 * 1.19).toFixed(0)),
	vatRate: 19,
	currencyCode: CurrencyCode.EUR,
	includedGoodsInternal: [
		'Kabellänge bis 30 Meter',
		'Materialkosten bis 200 €',
		'Personenstd. bis 20 Stunden',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €'
	],
	includedGoodsPublic: [
		'Benötigte Kabellänge',
		'Materialkosten',
		'Personenstunden',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service'
	]
};

export const installationPartnerDeltaInstallationPackageModelMock05: Dto<InstallationPartnerDeltaInstallationPackageModel> = {
	id: '5',
	name: 'Delta-Installtionspaket 5',
	description: 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren no sea takimata sanctus est Lorem ipsum dolor sit amet.',
	category: 'Donec ullamcorper nulla non metus auctor fringilla.',
	purchaseNetPrice: parseInt((77766 / 1.19).toFixed(0)),
	salesNetPrice: 97766,
	salesGrossPrice: parseInt((97750 * 1.19).toFixed(0)),
	vatRate: 19,
	currencyCode: CurrencyCode.EUR,
	includedGoodsInternal: [
		'Kabellänge bis 30 Meter',
		'Materialkosten bis 200 €',
		'Personenstd. bis 20 Stunden',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €',
		'Reisekosten bis 30 km',
		'Zusätzlicher Service bis 79 €',
		'Zusätzlicher Service bis 79 €'
	],
	includedGoodsPublic: [
		'Benötigte Kabellänge',
		'Materialkosten',
		'Personenstunden',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service',
		'Reisekosten',
		'Zusätzlicher Service',
		'Zusätzlicher Service'
	]
};
