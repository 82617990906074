/* eslint-disable @typescript-eslint/no-explicit-any */
import { MarketplaceModel, MarketplaceModelConverter } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	ModelConverterInterface,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { Dto, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerOrderProgressModel, CustomerOrderProgressModelConverter } from './CustomerOrderProgressModel';

export enum CustomerOrderState {
	DONE = 'DONE',
	RUNNING = 'RUNNING',
	CANCELLED = 'CANCELLED'
}

export type CustomerOrderModel = {
	readonly id: ModelPrimaryKey,
	readonly marketplaceOrderId: string,
	readonly orderDate: Timestamp,
	readonly orderState: CustomerOrderState,
	readonly progress: CustomerOrderProgressModel,
	readonly marketplace: MarketplaceModel
};

export class CustomerOrderModelConverter extends ModelConverter<CustomerOrderModel> {

	override getTimestampFields(): Array<TimestampFieldNames<CustomerOrderModel> | ArrayOfTimestampFieldNames<CustomerOrderModel>> {
		return ['orderDate'];
	}

	override getFieldConverterMapByModel(
		_model: Dto<CustomerOrderModel> | CustomerOrderModel
	): Map<keyof CustomerOrderModel, ModelConverterInterface<any>> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return new Map<keyof CustomerOrderModel, ModelConverterInterface<any>>([
			['progress', new CustomerOrderProgressModelConverter()],
			['marketplace', new MarketplaceModelConverter()]
		]);
	}

}
