import React, { useEffect } from 'react';

import { SpinnerCable } from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { PaymentTaskModel } from '@abb-emobility/usertask/domain-model';

import { usePaymentRedirect } from '../../../../hooks/PaymentRedirectHook';

import './TaskPayment.scss';

export type TaskPaymentProps = {
	task: PaymentTaskModel,
	initialPayment?: boolean
};

export function TaskPayment(props: TaskPaymentProps) {

	const { task, initialPayment = false } = props;

	const l10n = useL10n();

	const paymentRedirect = usePaymentRedirect(task.orderId);

	useEffect(() => {
		paymentRedirect((error: Error) => {
			throw error;
		});
	}, []);

	let headingLiteral = 'omsCustomerApp.task.payment.heading';
	let messageLiteral = 'omsCustomerApp.task.payment.message';
	if (initialPayment) {
		headingLiteral = 'omsCustomerApp.task.quotationAcceptance.completionSuccess.heading';
		messageLiteral = 'omsCustomerApp.task.quotationAcceptance.completionSuccess.message';
	}

	return (
		<div className="task-payment__feedback">
			<main className="task-payment__feedback__main">
				<SpinnerCable />
				<h1 className="task-payment__feedback__main__heading">
					{l10n.translate(headingLiteral)}
				</h1>
				<p className="task-payment__feedback__main__description">
					{l10n.translate(messageLiteral)}
				</p>
			</main>
		</div>
	);

}
