import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiDomain: {
		deltaQuotationItem: {
			additionalExpenses: 'Totale extra kosten',
			vatInformation: 'Inclusief BTW ({{vatRate}}%)'
		}
	}
};

export default literals;
