import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { JsonRestRequest } from './JsonRestRequest';
import { JsonRestRequestInterface } from './JsonRestRequestInterface';

export type JsonRestRequestBuilderCallback = (jsonWebToken: Nullable<JsonWebToken>) => JsonRestRequestInterface;

export class JsonRestRequestFactory {

	private static builder: Nullable<JsonRestRequestBuilderCallback> = null;

	public static setBuilder(builder: JsonRestRequestBuilderCallback) {
		this.builder = builder;
	}

	public static create(jsonWebToken: Nullable<JsonWebToken> = null): JsonRestRequestInterface {
		if (this.builder === null) {
			return this.createDefault(jsonWebToken);
		}
		return this.builder(jsonWebToken);
	}

	private static createDefault(jsonWebToken: Nullable<JsonWebToken> = null): JsonRestRequestInterface {
		return new JsonRestRequest(jsonWebToken);
	}

}
