import { AnyAction, combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';

import {
	customerOrderDocumentCollectionSlice,
	customerOrderDocumentCollectionStoreName,
	customerOrderEntitySlice,
	customerOrderEntityStoreName,
	customerOrderInformationCollectionSlice,
	customerOrderInformationCollectionStoreName,
	customerOrderInformationEntitySlice,
	customerOrderInformationEntityStoreName
} from '@abb-emobility/oms/data-provider';
import { sentryPerformanceMiddleware, sentrySpanManagementMiddleware } from '@abb-emobility/shared/sentry-integration';
import {
	anyTaskEntitySearchSlice,
	anyTaskEntitySearchStoreName,
	anyTaskEntitySlice,
	anyTaskEntityStoreName
} from '@abb-emobility/usertask/data-provider';


const combinedReducer = combineReducers({
	[customerOrderDocumentCollectionStoreName]: customerOrderDocumentCollectionSlice.reducer,
	[customerOrderEntityStoreName]: customerOrderEntitySlice.reducer,
	[anyTaskEntityStoreName]: anyTaskEntitySlice.reducer,
	[anyTaskEntitySearchStoreName]: anyTaskEntitySearchSlice.reducer,
	[customerOrderInformationCollectionStoreName]: customerOrderInformationCollectionSlice.reducer,
	[customerOrderInformationEntityStoreName]: customerOrderInformationEntitySlice.reducer
});

export type RootState = ReturnType<typeof combinedReducer> | undefined;

export const UNAUTHENTICATE_SIGNAL = '@@unauth';

const rootReducer = (state: RootState, action: AnyAction) => {
	if (action.type === UNAUTHENTICATE_SIGNAL) {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let middleware: MiddlewareArray<any> = getDefaultMiddleware();
		if (process.env['NX_SENTRY_ENABLED'] === 'true') {
			middleware = middleware.concat(sentrySpanManagementMiddleware).concat(sentryPerformanceMiddleware);
		}
		return middleware;
	}
});
