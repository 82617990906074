import { ErrorFeedback } from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';

import { ViewLayout } from '../../components/view-layout/ViewLayout';
import { useBuildPageTitle } from '../../hooks/PageTitle.hook';

export function NotFoundView() {

	const l10n = useL10n();

	usePageTitle(useBuildPageTitle('omsCustomerApp.pageTitle.notFound'));

	return (
		<ViewLayout>
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.notFound.heading')}
				message={l10n.translate('omsCustomerApp.error.notFound.message')}
			/>
		</ViewLayout>
	);
}
