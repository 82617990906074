import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiDomain: {
		deltaQuotationItem: {
			additionalExpenses: 'Coût total supplémentaire',
			vatInformation: 'TVA incluse ({{vatRate}}%)'
		}
	}
};

export default literals;
