import { Nullable } from '@abb-emobility/shared/util';

import { currencyData } from './Currency.data';
import { Currency } from './Currency.types';
import { CurrencyCode } from './CurrencyCode';

export const getCurrencyByCode = (code: CurrencyCode): Nullable<Currency> => {
	return currencyData.find((currency): boolean => {
		return currency.code === code;
	}) ?? null;
};

export const getCurrencyByNumericCode = (numeric: number): Nullable<Currency> => {
	return currencyData.find((currency): boolean => {
		return currency.numeric === numeric;
	}) ?? null;
};

export const listCurrencies = (): Array<Currency> => {
	return currencyData;
};

export const getCurrencySymbolByCode = (code: CurrencyCode): string => {
	return getCurrencySymbolByCurrency(getCurrencyByCode(code));
};

export const getCurrencySymbolByNumericCode = (numeric: number): string => {
	return getCurrencySymbolByCurrency(getCurrencyByNumericCode(numeric));
};

export const getCurrencySymbolByCurrency = (currency: Nullable<Currency>): string => {
	if (currency === null) {
		return '';
	}
	return currency.symbol ?? currency.code;
};

export const calculateMajorUnitFromMinorUnit = (code: CurrencyCode, minorUnit: number): number => {
	const currency = getCurrencyByCode(code);
	if (currency === null || currency.fraction === 0) {
		return minorUnit;
	}
	return minorUnit / Math.pow(10, currency.fraction);
};

export const calculateMinorUnitFromMajorUnit = (code: CurrencyCode, majorUnit: number): number => {
	const currency = getCurrencyByCode(code);
	if (currency === null || currency.fraction === 0) {
		return majorUnit;
	}
	return majorUnit * Math.pow(10, currency.fraction);
};
