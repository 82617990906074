import { createContext, ReactNode, useContext } from 'react';

import { TaskCollectionDataProviderValue } from '@abb-emobility/shared/data-provider-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

type TaskCollectionDataProviderProviderContextValue = {
	getTaskCollectionDataProvider: () => TaskCollectionDataProviderValue<AnyTaskCollectionItemModel>
};

export type TaskCollectionDataProviderProviderProps = {
	children: ReactNode,
	dataProvider: TaskCollectionDataProviderValue<AnyTaskCollectionItemModel>
};

const taskCollectionDataProviderProviderContext = createContext<TaskCollectionDataProviderProviderContextValue>({
	getTaskCollectionDataProvider: (): TaskCollectionDataProviderValue<AnyTaskCollectionItemModel> => {
		throw new AppError('Task collection data provider factory missing');
	}
});

export const TaskCollectionDataProviderProvider = (props: TaskCollectionDataProviderProviderProps) => {

	const { children, dataProvider } = props;

	const contextValue = {
		getTaskCollectionDataProvider: (): TaskCollectionDataProviderValue<AnyTaskCollectionItemModel> => {
			return dataProvider;
		}
	};

	return (
		<taskCollectionDataProviderProviderContext.Provider value={contextValue}>
			{children}
		</taskCollectionDataProviderProviderContext.Provider>
	);
};

export const useTaskCollectionDataProviderProvider = () => {
	return useContext(taskCollectionDataProviderProviderContext);
};
