import { InstallationState } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationPartnerOrderInformationApprovalInformationModel = {
	readonly installationState: InstallationState,
	readonly customerSignature?: DownloadFile,
	readonly installationDocuments: Array<DownloadFile>
};

export class InstallationPartnerOrderInformationApprovalInformationModelConverter extends ModelConverter<InstallationPartnerOrderInformationApprovalInformationModel> {

	override getDownloadFileFields(): Array<DownloadFileFieldNames<InstallationPartnerOrderInformationApprovalInformationModel> | ArrayOfDownloadFileFieldNames<InstallationPartnerOrderInformationApprovalInformationModel>> {
		return ['customerSignature', 'installationDocuments'];
	}

}
