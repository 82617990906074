import {
	AnyInstallationPartnerOrderInformationCollectionApiClientFactory,
	AnyInstallationPartnerOrderInformationCollectionApiClientInterface
} from '@abb-emobility/oms/api-integration';
import { AnyInstallationPartnerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const installationPartnerOrderInformationCollectionStoreName = 'installation-partner-order-information-collection';
export type InstallationPartnerOrderInformationCollectionCrudStore = { [installationPartnerOrderInformationCollectionStoreName]: CrudCollectionStore<AnyInstallationPartnerOrderInformationCollectionItemModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyInstallationPartnerOrderInformationCollectionApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyInstallationPartnerOrderInformationCollectionItemModel, AnyInstallationPartnerOrderInformationCollectionApiClientInterface>(installationPartnerOrderInformationCollectionStoreName, createApiClient);

// Slice definition
export const installationPartnerOrderInformationCollectionSlice = createCrudCollectionSlice<AnyInstallationPartnerOrderInformationCollectionItemModel, ThunkApiConfig>(
	installationPartnerOrderInformationCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = installationPartnerOrderInformationCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>(installationPartnerOrderInformationCollectionStoreName);
const countCollection = createCrudCountCollection<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>(installationPartnerOrderInformationCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>(installationPartnerOrderInformationCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>(installationPartnerOrderInformationCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>(installationPartnerOrderInformationCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>(installationPartnerOrderInformationCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const installationPartnerOrderInformationCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<AnyInstallationPartnerOrderInformationCollectionItemModel, InstallationPartnerOrderInformationCollectionCrudStore>;
