import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationTradeModel = {
	id: ModelPrimaryKey,
	name: string
};

export class InstallationTradeModelConverter extends ModelConverter<InstallationTradeModel> {
}
