import { useEffect } from 'react';

import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useWebsocket } from '@abb-emobility/shared/websocket';

type DataProviderValue = {
	storeSize: () => Readonly<number>,
	storeEntryIds: () => ReadonlyArray<ModelPrimaryKey>
};

type ScopedMessage = {
	resourceId: string
};

type DataProviderWebsocketMessageHandler<Message> = (message: Message) => void;

export const useDataProviderWebsocketHandler = <Topic extends string, Message extends ScopedMessage>(
	name: string,
	dataProviderValue: DataProviderValue,
	topic: Topic,
	messageHandler: DataProviderWebsocketMessageHandler<Message>
) => {

	const websocket = useWebsocket<Topic, Message>();
	const storeEntryIds = dataProviderValue.storeEntryIds();

	useEffect(() => {
		if (websocket === null) {
			return;
		}

		if (storeEntryIds.length > 0) {
			websocket.subscribe(topic, (message: Message) => {
				if (!storeEntryIds.includes(message.resourceId)) {
					return;
				}
				messageHandler(message);
			}, name);
		} else {
			websocket.unsubscribe(topic, name);
		}

	}, [storeEntryIds, websocket]);

};
