export const getToday = (): Date => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return today;
};

export const getCalendarDay = (offset: number): Date => {
	const day = getToday();
	day.setDate(day.getDate() + offset);
	return day;
};

export const getBusinessDay = (offset: number): Date => {
	const day = getToday();
	day.setDate(day.getDate() + offset);
	if (day.getDay() === 0) {
		day.setDate(day.getDate() + 1);
	}
	return day;
};

export const getNextBusinessDay = (date: Date): Date => {
	if (date.getDay() === 0) {
		date.setDate(date.getDate() + 1);
	}
	return date;
};

export const getMorningOfDay = (date: Date): Date => {
	date.setHours(0, 0, 0, 0);
	return date;
};

export const isToday = (date: Date): boolean => {
	return getMorningOfDay(date).getTime() === getToday().getTime();
};

export const isSameDay = (left: Date, right: Date): boolean => {
	return (getMorningOfDay(left).getTime() === getMorningOfDay(right).getTime());
};

export const isSameMonth = (left: Date, right: Date): boolean => {
	return ((left.getMonth() === right.getMonth()) && (left.getFullYear() === right.getFullYear()));
};
