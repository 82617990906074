import { Answer } from '../answer/Answers';

export enum QuestionnaireBusinessRuleCombinator {
	AND = 'AND',
	OR = 'OR'
}

export enum QuestionnaireBusinessRuleComparator {
	ISSET = 'ISSET',
	EQUALS = 'EQUALS',
	LESS_THAN = 'LESS_THAN',
	LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL'
}

export type QuestionnaireBusinessRuleFoundation = {
	key: string,
	comparator: QuestionnaireBusinessRuleComparator,
	negation: boolean,
	value: Answer
};

export type QuestionBusinessRule = {
	discriminator: 'question'
} & QuestionnaireBusinessRuleFoundation;

export type EnvironmentBusinessRule = {
	discriminator: 'environment'
} & QuestionnaireBusinessRuleFoundation;

export type QuestionnaireBusinessRule =
	| QuestionBusinessRule
	| EnvironmentBusinessRule;
