import { L10nProvider } from '@abb-emobility/shared/localization-provider';

import { GenericError } from './GenericError';
import { locales } from '../../../main';
import { ViewLayout } from '../../components/view-layout/ViewLayout';

export type ErrorViewProps = {
	error: Error
};

export function ErrorView(props: ErrorViewProps) {

	const { error } = props;

	const heading = 'omsCustomerApp.error.generic.heading';
	const message = error.message;

	return (
		<ViewLayout>
			<L10nProvider
				locales={locales}
				fallbackLocaleIdentifier={'en'}
			>
				<GenericError heading={heading} message={message} />
			</L10nProvider>
		</ViewLayout>
	);
}
