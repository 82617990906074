import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	ThunkApiConfig,
	TaskEntitySearchStore,
	createTaskSearchEntityThunk,
	createTaskSearchCompleteThunk,
	createTaskSearchAssignThunk,
	createTaskEntitySearchSlice,
	createTaskSelectFoundEntity,
	createTaskSelectFoundHypermediaLinks,
	TaskEntitySearchStoreAccessor, createTaskSearchStoreSize, createTaskSearchStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskApiClientFactory, AnyTaskApiClientInterface } from '@abb-emobility/usertask/api-integration';
import { AnyTaskModel } from '@abb-emobility/usertask/domain-model';

// Basic definition
export const anyTaskEntitySearchStoreName = 'task-search';
export type AnyTaskEntitySearchStore = { [anyTaskEntitySearchStoreName]: TaskEntitySearchStore<AnyTaskModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyTaskApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const searchThunk = createTaskSearchEntityThunk<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskEntitySearchStore, AnyTaskApiClientInterface>(anyTaskEntitySearchStoreName, createApiClient);
const completeThunk = createTaskSearchCompleteThunk<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskApiClientInterface>(anyTaskEntitySearchStoreName, createApiClient);
const assignThunk = createTaskSearchAssignThunk<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskApiClientInterface>(anyTaskEntitySearchStoreName, createApiClient);

// Slice definition
export const anyTaskEntitySearchSlice = createTaskEntitySearchSlice<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, ThunkApiConfig>(anyTaskEntitySearchStoreName, searchThunk, completeThunk, assignThunk);
const { resolveSearchStatus, resolveActionStatus, resetStore } = anyTaskEntitySearchSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createTaskSelectFoundEntity<AnyTaskModel, AnyTaskEntitySearchStore>(anyTaskEntitySearchStoreName);
const selectHypermediaLinks = createTaskSelectFoundHypermediaLinks<AnyTaskModel, AnyTaskEntitySearchStore>(anyTaskEntitySearchStoreName);
const storeSize = createTaskSearchStoreSize<AnyTaskModel, AnyTaskEntitySearchStore>(anyTaskEntitySearchStoreName);
const storeEntryIds = createTaskSearchStoreEntryIds<AnyTaskModel, AnyTaskEntitySearchStore>(anyTaskEntitySearchStoreName);

// Export the store accessors to be used by the data provider value
export const anyTaskEntityStoreAccessors = {
	search: searchThunk,
	complete: completeThunk,
	assign: assignThunk,
	resolveSearchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as TaskEntitySearchStoreAccessor<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskEntitySearchStore>;
