import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import {
	installationTradeModelMock01,
	installationTradeModelMock02,
	installationTradeModelMock03,
	installerModelMock01
} from '@abb-emobility/shared/api-integration';
import { InstallationPeriod, InstallationType } from '@abb-emobility/shared/domain-model';
import {
	DateRangeDto,
	createTimestampDtoFromDate,
	Dto
} from '@abb-emobility/shared/domain-model-foundation';
import { IdentityHandler } from '@abb-emobility/shared/identity-provider';

import { installationPartnerOrderModelMock } from './InstallationPartnerOrderModel.mock';

const installerIdentity = IdentityHandler.get().getIdentity().get();

const createDateRange = (forToday: boolean): DateRangeDto => {
	const todayStart = new Date();
	todayStart.setHours(10, 0, 0, 0);
	const todayEnd = new Date();
	todayEnd.setHours(16, 30, 0, 0);
	if (!forToday) {
		todayStart.setDate(todayStart.getDate() + 1 + Math.round(Math.random() * 5));
		todayEnd.setDate(todayEnd.getDate() + 1 + Math.round(Math.random() * 5));
	}
	return {
		start: createTimestampDtoFromDate(todayStart),
		end: createTimestampDtoFromDate(todayEnd)
	};
};

let period = createDateRange(true);
export const installerAppointmentModelMock01: Dto<InstallerAppointmentModel> = {
	id: '1',
	orderId: installationPartnerOrderModelMock().id,
	installer: {
		...installerModelMock01,
		id: installerIdentity?.userId ?? '1',
		userName: installerIdentity?.userName ?? '1'
	},
	trade: installationTradeModelMock01,
	completionTaskId: 'ip7',
	period: period,
	periodStartsAt: period.start,
	customerFirstName: 'Max',
	customerLastName: 'Mustermann',
	customerCompanyName: 'Musterfirma',
	installationAddress: 'Musterstraße 234, 24145 Kiel',
	installationType: InstallationType.DELTA,
	installationPeriod: InstallationPeriod.PREPARATION
};

period = createDateRange(false);
export const installerAppointmentModelMock02: Dto<InstallerAppointmentModel> = {
	id: '2',
	orderId: installationPartnerOrderModelMock().id,
	installer: {
		...installerModelMock01,
		id: installerIdentity?.userId ?? '1',
		userName: installerIdentity?.userName ?? '1'
	},
	trade: installationTradeModelMock02,
	completionTaskId: 'ip7',
	period: period,
	periodStartsAt: period.start,
	customerFirstName: 'Max',
	customerLastName: 'Mustermann',
	customerCompanyName: 'Musterfirma',
	installationAddress: 'Musterstraße 234, 24145 Kiel',
	installationType: InstallationType.DELTA,
	installationPeriod: InstallationPeriod.PERMANENT
};

period = createDateRange(false);
export const installerAppointmentModelMock03: Dto<InstallerAppointmentModel> = {
	id: '3',
	orderId: installationPartnerOrderModelMock().id,
	installer: {
		...installerModelMock01,
		id: installerIdentity?.userId ?? '1',
		userName: installerIdentity?.userName ?? '1'
	},
	trade: installationTradeModelMock03,
	completionTaskId: 'ip7',
	period: period,
	periodStartsAt: period.start,
	customerFirstName: 'Max',
	customerLastName: 'Mustermann',
	customerCompanyName: 'Musterfirma',
	installationAddress: 'Musterstraße 234, 24145 Kiel',
	installationType: InstallationType.DELTA,
	installationPeriod: InstallationPeriod.BEGINNING
};
