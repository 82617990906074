import { ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';

export type GenericErrorProps = {
	heading: string,
	message: string
};

export function GenericError(props: GenericErrorProps) {

	const {heading, message} = props;

	const l10n = useL10n();

	const reloadAction: ErrorFeedbackAction = {
		label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
		onInvoke: (): void => {
			window.location.reload();
		}
	};

	return (
		<ErrorFeedback
			heading={l10n.translate(heading)}
			message={l10n.translate(message)}
			actions={[reloadAction]}
		/>
	);
}
