import { ReactNode } from 'react';

import {
	Notification,
	SpinnerCable,
	ErrorFeedback,
	ErrorFeedbackAction
} from '@abb-emobility/oms/customer-ui-primitive';
import { AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { AuthActivity, useOtpAuth } from './OtpAuthEmitter.hooks';
import { OtpAuthForm } from './otp-auth-form/OtpAuthForm';

import './OtpAuthEmitter.scss';

export type OtpAuthEmitterProps = {
	orderId: string
};

export function OtpAuthEmitter(props: AuthProviderInterceptingProps & OtpAuthEmitterProps) {

	const { onAuthenticate = null, orderId } = props;

	const l10n = useL10n();
	const {
		requestOtp,
		exchangeOtp,
		authActivity,
		notification,
		error
	} = useOtpAuth(orderId, onAuthenticate);

	const renderError = (): ReactNode => {
		if (error === null) {
			return null;
		}

		const reloadAction: ErrorFeedbackAction = {
			label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
			onInvoke: (): void => {
				window.location.reload();
			}
		};

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				actions={[reloadAction]}
			/>
		);
	};

	const renderNotification = (): ReactNode => {
		if (notification === null) {
			return null;
		}
		return (
			<Notification {...notification} />
		);
	};

	const renderActivity = (): ReactNode => {
		switch (authActivity) {
			case AuthActivity.OTP_EXPECTABLE:
				return (
					<OtpAuthForm onSubmit={exchangeOtp} onResend={requestOtp} />
				);
			case AuthActivity.OTP_FAILED:
				return;
			default:
				return (
					<SpinnerCable />
				);
		}
	};

	return (
		<article className="otp-auth-emitter">
			<header className="otp-auth-emitter__header">
				<div className="otp-auth-emitter__header__logo">
					<img
						src="/assets/otp-auth-emitter/asset-logo-big.svg"
						alt="ABB"
					/>
				</div>
				<h1 className="otp-auth-emitter__header__heading">
					{l10n.translate('omsCustomerApp.otpAuthEmitter.otpInputHeading')}
				</h1>
				<p className="otp-auth-emitter__header__note">
					{l10n.translate('omsCustomerApp.otpAuthEmitter.otpInputMessage')}
				</p>
			</header>
			<main className="otp-auth-emitter__main">
				<div className="otp-auth-emitter__main__dimension">
					{renderNotification()}
					{renderError()}
					{renderActivity()}
				</div>
			</main>
		</article>
	);
}
