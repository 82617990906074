import { ReactNode } from 'react';

import './InputCheckboxGroup.scss';

export type InputCheckboxGroupProps = {
	label?: string,
	notificationElement?: ReactNode,
	children: ReactNode
};

export function InputCheckboxGroup(props: InputCheckboxGroupProps) {
	const { label, notificationElement, children } = props;

	const renderLabel = (): ReactNode => {
		if (label === undefined) {
			return null;
		}
		return (
			<div className="input-checkbox-group__label">{label}</div>
		);
	};

	return (
		<div className="input-checkbox-group">
			{renderLabel()}
			{notificationElement}
			<div className="input-checkbox-group__items">
				{children}
			</div>
		</div>
	);
}
