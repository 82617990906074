import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'ABB-laddbox',
				labelCancel: 'Avbryt',
				titlePaymentData: 'Betalning',
				labelAmount: 'Belopp för ytterligare installationsarbete och material',
				labelDate: 'Datum',
				labelMerchant: 'Handlare',
				labelIban: 'Ditt IBAN-nummer'
			}
		},
		application: {
			pageTitle: 'Installationstjänst för ABB-laddbox',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Beställ {{marketplaceId}}',
			task: 'Beställ {{marketplaceId}} – {{taskType}}',
			documents: 'Dokument',
			payment: 'Betalning',
			notFound: 'Hittades inte',
			static: {
				terms: 'Allmänna villkor',
				privacy: 'Integritet',
				imprint: 'Tryck'
			}
		},
		footer: {
			termsLabel: 'Allmänna villkor',
			privacyLabel: 'Integritet',
			imprintLabel: 'Tryck'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Ange kod',
			otpInputMessage: 'Vi har skickat en 6-siffrig kod till din mobiltelefon.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Skicka igen',
			otpInputLabel: 'Ange din kod',
			otpInputPlaceholder: 'Autentiseringskod',
			otpSubmitButtonLabel: 'Fortsätt'
		},
		error: {
			otpAuthInitFailed: 'Autentiseringen misslyckades',
			otpAuthReceiveTokenFailed: 'Ogiltig kod',
			taskTypeNotFound: 'Den begärda uppgiftstypen {{taskType}} är okänd.',
			taskNotFound: 'Den begärda uppgiften hittades inte',
			orderNotFound: 'Den begärda ordern hittades inte',
			documentNotFound: 'Det begärda dokumentet hittades inte',
			validation: {
				heading: 'Ogiltiga data',
				message: 'De data du skickade är inte giltiga.'
			},
			generic: {
				heading: 'Ett fel har uppstått',
				message: 'Ett fel inträffade som inte kunde återställas.',
				resolveAction: {
					label: 'Ladda om'
				}
			},
			notFound: {
				heading: 'Hittades inte',
				message: 'Resursen du begärde hittades inte'
			},
			taskCompleteMessage: 'Det uppstod ett fel under slutförandet av uppgiften.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'Jag behöver hjälp',
			dialogueDismissLabel: 'Avbryt',
			dialogueCaption: 'Kundtjänst',
			phone: {
				heading: 'Du kan när som helst kontakta oss via telefon'
			},
			mail: {
				heading: 'Eller skicka ett mejl till oss',
				subject: 'Min laddboxorder #{{marketplaceId}}',
				subjectStatic: 'Min laddboxorder'
			},
			infoPage: {
				heading: 'Eller så kan du besöka vårt informationsutbud',
				label: 'Svar på de vanligaste frågorna'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Okänd',
			allDoneStepLabel: 'Klar',
			upcomingLabelPrefix: 'Nästa: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'av'
		},
		payment: {
			success: {
				heading: 'Din betalning har genomförts.',
				message: 'Nu står ingenting i vägen för installationen av din laddbox. Vår installationspartner kommer snart att kontakta dig för att boka en tid för installationen.\n\nTack för ditt förtroende!'
			},
			failed: {
				heading: 'Din betalning har misslyckats!',
				retryLabel: 'Försök igen'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Installationskontroll',
				customerProvidePreCheckPreferences: 'Ange önskad kontaktmetod',
				customerHandleUnfeasibleInstallation: 'Installationen kan inte genomföras',
				customerHandleGridOperatorRejection: 'Avvisad av nätoperatören',
				customerAcceptQuotation: 'Erbjudande om ytterligare arbete',
				customerPerformPayment: 'Betalning',
				customerPayment: 'Din betalning',
				customerApproveInstallation: 'Bekräftelse av installationen',
				installationPartnerEvaluateBasicAnswers: 'Installationskontroll',
				installationPartnerExecuteRemotePreCheck: 'Slutkontroll',
				installationPartnerRegisterAtGridOperator: 'Registrering av nätoperatör',
				installationPartnerRevokeGridOperatorRegistration: 'Nekande av registrering av nätoperatör',
				installationPartnerProvideGridOperatorDecision: 'Beslut från nätoperatör',
				installationPartnerProvideQuotation: 'Offert',
				installationPartnerArrangeAppointment: 'Boka tid för möte',
				installationPartnerCompleteInstallation: 'Utförande av installation'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Installationskontroll',
				customerProvidePreCheckPreferences: 'Önskad kontaktmetod',
				customerHandleUnfeasibleInstallation: 'Installationen kan inte genomföras',
				customerHandleGridOperatorRejection: 'Avvisad av nätoperatören',
				customerAcceptQuotation: 'Erbjudande om ytterligare arbete',
				customerPerformPayment: 'Betalning',
				customerPayment: 'Betalning',
				customerApproveInstallation: 'Bekräftelse av installationen',
				installationPartnerEvaluateBasicAnswers: 'Installationskontroll',
				installationPartnerExecuteRemotePreCheck: 'Slutkontroll',
				installationPartnerRegisterAtGridOperator: 'Registrering av nätoperatör',
				installationPartnerRevokeGridOperatorRegistration: 'Nekande av registrering av nätoperatör',
				installationPartnerProvideGridOperatorDecision: 'Beslut från nätoperatör',
				installationPartnerProvideQuotation: 'Offert',
				installationPartnerArrangeAppointment: 'Boka tid för möte',
				installationPartnerCompleteInstallation: 'Utförande av installation'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'Syftet med fullmakten är att vi ska kunna registrera nätanslutningen med ditt samtycke.',
						powerOfAttorney: {
							heading: 'Beviljande av fullmakt för registrering av konsumenter hos nätoperatören',
							description: 'Berätta för oss vilken nätoperatör du har för att registrering din laddbox.',
							gridOperator: {
								question: 'Nätoperatör eller elleverantör'
							},
							powerOfAttorney: {
								question: 'Jag ger härmed ABB och dess installationspartner tillstånd att registrera laddboxen hos nätoperatören. Om installationen inte genomförs upphör fullmakten automatiskt att gälla.'
							}
						},
						marketingConsent: {
							heading: 'Samtycke till kommunikation med installationspartnern',
							installationPartnerMarketingConsent: {
								question: 'Jag ger härmed ABB och dess installationspartner tillstånd att kontakta mig i marknadsföringssyfte.'
							}
						},
						surveyConsent: {
							heading: 'Dela din åsikt med oss',
							description: 'Hjälp oss att förbättra våra produkter och tjänster genom att delta i en kort undersökning efter att du har slutfört din beställning.',
							surveyConsent: {
								question: 'Jag ger härmed ABB tillåtelse att bjuda in mig att delta i en undersökning.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Tack för din tid!',
					message: 'Du kommer snart att få en bekräftelse via e-post. Vi kontrollerar dina uppgifter och kommer sedan att kontakta dig för att komma överens med dig om nästa steg.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Bakåt',
				nextStepButtonLabel: 'Nästa steg',
				submitButtonLabel: 'Skicka information',
				channelSelection: {
					heading: 'Personligt samtal med vårt serviceteam',
					message: 'För att installationen av din nya laddbox ska gå smidigt ber vi dig att meddela oss dina önskemål om ett personligt samtal med vårt serviceteam.\nNästa steg kommer sedan att överenskommas baserat på den information du redan har lämnat.',
					subheading: 'Hur kan vi kontakta dig?',
					explanation: 'Vi kan kontakta dig på olika sätt.'
				},
				preferredTimesOfDay: {
					subHeading: 'Låt oss hitta en tid för en videochatt. Det kommer att ske via {{channelName}}.',
					timeOfDay: {
						morning: 'Morgon',
						noon: 'Lunchtid',
						afternoon: 'Eftermiddag',
						evening: 'Kväll'
					}
				},
				completionSuccess: {
					heading: 'Det är bra att du har tid för oss!',
					message: 'Vi kommer att kontakta dig så snart som möjligt för att bekräfta de lediga tiderna du har valt.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Vi beklagar verkligen, men',
				message: 'installation av vår installationspartner är för närvarande inte möjlig. Orsaken är:\n\n{{unfeasibleReasong}}\n\nVi måste därför be dig att annullera din beställning.',
				submitButtonLabel: 'Skicka information',
				unfeasibleReason: {
					noPotentialRailAvailable: 'En potentialskena, som är nödvändig för att installera överspänningsskyddet, är inte tillgänglig.',
					overvoltageProtectionUnfeasible: 'Den nödvändiga installationen av ett överspänningsskydd är inte möjlig.',
					spaceForCableOutletMissing: 'Det finns inte tillräckligt med utrymme för ett nödvändigt extra kabeluttag.',
					houseConnectionUndersized: 'Husanslutningen är så underdimensionerad att det inte är möjligt att installera laddboxen.',
					noThreePhaseAvailable: 'Det befintliga elsystemet ger inte den nödvändiga trefasförsörjningen.',
					outdatedMeterCabinet: 'Mätarskåpet uppfyller inte kraven för idrifttagning av laddboxen.',
					notTabCompliant: 'Den elektriska installationen överensstämmer inte med gällande tekniska anslutningsvillkor.',
					other: 'Det finns tekniska hinder för installationen som inte kan undanröjas under installationens gång.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Din installation har avbrutits annullerats.',
					message: 'Vi har mottagit din avbokning. Du kommer inom kort att få en bekräftelse via e-post.'
				},
				completionCancelOrderSuccess: {
					heading: 'Ordern har annullerats.',
					message: 'Vi har mottagit din avbokning. Du kommer inom kort att få en bekräftelse via e-post.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Vi beklagar, men din nätoperatör har nekat idriftsättning av din laddbox',
				message: 'Tyvärr är strömförsörjningen på installationsplatsen inte tillräcklig för att driva din laddbox. Du har möjlighet att minska effekten i din laddbox eller avbryta installationen.',
				submitButtonLabel: 'Skicka information',
				solutionDecreaseWallboxPower: {
					heading: 'Minska laddboxens effekt',
					description: 'Laddboxens effekt reduceras till den effekt som är lämplig för din strömförsörjning. Den minskade strömförbrukningen kommer att påverka laddningseffektiviteten.'
				},
				solutionCancelInstallation: {
					heading: 'Avboka installation',
					description: 'Installationen av vår installationspartner avbokas. Laddboxen kommer att skickas direkt till dig. Du kommer att få ersättning för installationskostnaderna inom de närmaste dagarna.'
				},
				solutionCancelOrder: {
					heading: 'Avbryt installation och laddbox',
					description: 'Hela din beställning kommer att annulleras och du kommer att få ersättning för alla kostnader inom de närmaste dagarna.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Effektreduktion för laddbox',
					message: 'ABB bekräftar härmed installationen av din laddbox baserat på effektreduktion som uppfyller nätoperatörens krav.\n\nVi kommer snart att meddela dig om nästa steg.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Din installation har avbrutits annullerats.',
					message: 'Vi har mottagit din avbokning. Du kommer inom kort att få en bekräftelse via e-post.'
				},
				completionCancelOrderSuccess: {
					heading: 'Ordern har annullerats.',
					message: 'Vi har mottagit din avbokning. Du kommer inom kort att få en bekräftelse via e-post.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Skicka information',
				message: 'Vänligen bekräfta att installationen av din laddbox har slutförts.',
				approval: {
					caption: 'Bekräftelse',
					label: 'Härmed bekräftar jag att installationen av laddboxen har genomfört, samt dess idrifttagning och funktionalitet.'
				},
				completionSuccess: {
					heading: 'Tack!',
					message: 'Installationen av din laddbox har slutförts. Du kommer snart att få all viktig dokumentation.\n\nTack för ditt förtroende!'
				}
			},
			quotationAcceptance: {
				caption: 'Ditt individuella erbjudande',
				message: 'Följande installationspaket är tilläggstjänster som faktureras separat. När du accepterar erbjudandet kommer du att vidarebefordras till vår leverantör av betalningstjänster, Deutsche Bank, så att beställningen kan behandlas för vår räkning.\n\nInga ytterligare kostnader tillkommer när du accepterar erbjudandet och installationen kan därefter genomföras.\n\nVår installationspartner kommer att kontakta dig för att boka en tid för installationen.',
				submitButtonLabel: 'Acceptera erbjudande med förbehåll för betalning',
				cancelButtonLabel: 'Avbryt',
				cancellationCaption: 'Vad tråkigt att vi inte kan ge dig ett bra erbjudande',
				cancellationMessage: 'Ange hur du vill avbryta din beställning.',
				submitCancellationButtonLabel: 'Skicka information',
				recoverCancellationButtonLabel: 'Bakåt',
				conditions: {
					caption: 'Villkor för erbjudandet',
					message: 'Erbjudandet gäller så länge som följande villkor är tillämpliga baserat på dina uppgifter.'
				},
				acceptance: {
					terms: 'de allmänna villkoren',
					label: 'Jag accepterar härmed {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'Din order har genomförts.',
					message: 'Du har accepterat vårt erbjudande och kommer att vidarebefordras till vår betaltjänstleverantör.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Din installation har avbrutits annullerats.',
					message: 'Vi har mottagit din avbokning. Du kommer inom kort att få en bekräftelse via e-post.'
				},
				completionCancelOrderSuccess: {
					heading: 'Ordern har annullerats.',
					message: 'Vi har mottagit din avbokning. Du kommer inom kort att få en bekräftelse via e-post.'
				}
			},
			payment: {
				heading: 'Din betalning',
				message: 'Du kommer att vidarebefordras till vår betaltjänstleverantör.'
			}
		},
		documents: {
			heading: 'Din orderdokumentation',
			introduction: 'Översikt över dokument'
		},
		order: {
			heading: 'Din order',
			cancelledHint: 'Denna order har annullerats.',
			doneHint: 'Denna order har slutförts.',
			introduction: 'Här hittar du all aktuell information om din laddboxbeställning på {{marketplaceName}} från {{orderDate}} med ordernummer {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Avsluta nu'
			},
			information: {
				heading: {
					orderItems: 'Order',
					quotation: 'Erbjudande för ytterligare tjänster',
					appointment: 'Tid för installation',
					shipping: 'Försändelsens status'
				},
				orderItems: {
					position: 'Position',
					description: 'Beskrivning',
					wallboxSerialNumbner: 'Laddboxserie.',
					totalGrossPrice: 'Totalt bruttopris',
					cancellationHint: 'Annullerad den {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'Installationen avbröts den {{cancellationDate}}'
				},
				appointment: {
					heading: 'Installation'
				},
				shipping: {
					heading: 'Levereras av {{carrier}}',
					trackingNumberLabel: 'Referensnummer',
					shippingAddressLabel: 'Leveransadress',
					trackingLinkLabel: 'Orderspårning',
					itemsLabel: 'Försändelsens artiklar',
					productCodeLabel: 'Produktkod'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Avboka installation',
				description: 'Installationen av vår installationspartner avbokas. Laddboxen kommer att skickas direkt till dig. Du kommer att få ersättning för installationskostnaderna inom de närmaste dagarna.'
			},
			cancelOrder: {
				heading: 'Avbryt installation och laddbox',
				description: 'Hela din beställning kommer att annulleras och du kommer att få ersättning för alla kostnader inom de närmaste dagarna.'
			}
		}
	}
};

export default literals;
