import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputText.scss';

export type InputTextProps = {
	type?: 'color' | 'date' | 'datetime-local' | 'email' | 'hidden' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
	label: string,
	notificationElement?: ReactNode,
	icon?: IconIdentifier,
	onChange?: (value: string) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'>;

export function InputText(props: InputTextProps) {

	const { type = 'text', label, notificationElement, icon = null, required = false, onChange = null, ...rest } = props;

	const l10n = useL10n();

	const inputClassMap = {
		'input-text__field__input': true,
		'input-text__field__input--icon': icon !== null
	};

	// event handling
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange !== null) {
			onChange(event.target.value);
		}
	};

	// render functions
	const renderIcon = () => {
		if (icon === null) {
			return;
		}
		return (
			<span className="input-text__field__icon">
				<Icon name={icon} />
			</span>
		);
	};

	const renderLabel = (): ReactNode => {
		const labelSuffix = required ? null : (
			<span className="input-text__label__suffix">
				{l10n.translate('omsCustomerUiPrimitive.input.optionalValueSuffix')}
			</span>
		);
		return (
			<span className="input-text__label">
				{label}
				{labelSuffix}
			</span>
		);
	};

	return (
		<label className="input-text">
			{renderLabel()}
			{notificationElement}
			<span className="input-text__field">
				<input
					type={type}
					onChange={handleChange}
					className={buildCssClassStringFromClassMap(inputClassMap)}
					required={required}
					{...rest}
				/>
				{renderIcon()}
			</span>
		</label>
	);
}
