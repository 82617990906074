import { AppError } from '@abb-emobility/shared/error';
import { Optional, Probably } from '@abb-emobility/shared/util';

import { createTimestampDtoFromTimestamp, createTimestampFromTimestampDto, createTimestampFromDate } from './Timestamp.util';
import { UploadFileDto, UploadFileMetaDataDto, UploadFile, UploadFileMetaData } from './UploadFile';
import { isUploadFileDto, isUploadFileMetaDataDto, isUploadFile, isUploadFileMetaData } from './UploadFile.guards';

export const createUploadFileFromUploadFileDto = (fileDto: Probably<UploadFileDto>): Optional<UploadFile> => {
	let file = null;
	if (isUploadFileDto(fileDto)) {
		file = {
			discriminator: 'UploadFile',
			dataUri: fileDto.dataUri,
			meta: createUploadFileMetaDataFromUploadFileMetaDataDto(fileDto.meta).get()
		} as UploadFile;
	}
	return new Optional<UploadFile>(file);
};

export const createUploadFileFromFile = async (file: File): Promise<UploadFile> => {
	let dataUri: string;
	try {
		dataUri = await convertFileToDataUri(file);
	} catch (e) {
		throw new AppError('Converting the file to a data URI failed with message: ' + (e as AppError).message);
	}

	return {
		discriminator: 'UploadFile',
		dataUri: dataUri,
		meta: createUploadFileMetaDataFromFile(file)
	} as UploadFile;
};

export const createUploadFileDtoFromUploadFile = (file: Probably<UploadFile>): Optional<UploadFileDto> => {
	let fileDto = null;
	if (isUploadFile(file)) {
		fileDto = {
			dataUri: file.dataUri,
			meta: createUploadFileMetaDataDtoFromUploadFileMetaData(file.meta).get()
		} as UploadFileDto;
	}
	return new Optional<UploadFileDto>(fileDto);
};

const createUploadFileMetaDataFromUploadFileMetaDataDto = (fileMetaDto: Probably<UploadFileMetaDataDto>): Optional<UploadFileMetaData> => {
	let fileMetaData = null;
	if (isUploadFileMetaDataDto(fileMetaDto)) {
		fileMetaData = {
			...fileMetaDto,
			lastModifiedDate: createTimestampFromTimestampDto(fileMetaDto.lastModifiedDate).get()
		} as UploadFileMetaData;
	}
	return new Optional<UploadFileMetaData>(fileMetaData);
};

const createUploadFileMetaDataDtoFromUploadFileMetaData = (fileMeta: Probably<UploadFileMetaData>): Optional<UploadFileMetaDataDto> => {
	let fileMetaDataDto = null;
	if (isUploadFileMetaData(fileMeta)) {
		fileMetaDataDto = {
			...fileMeta,
			lastModifiedDate: createTimestampDtoFromTimestamp(fileMeta.lastModifiedDate).get()
		} as UploadFileMetaDataDto;
	}
	return new Optional<UploadFileMetaDataDto>(fileMetaDataDto);
};

const createUploadFileMetaDataFromFile = (file: File): UploadFileMetaData => {
	return {
		fileName: file.name,
		size: file.size,
		lastModifiedDate: createTimestampFromDate(new Date(file.lastModified))
	} as UploadFileMetaData;
};

const convertFileToDataUri = async (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.onerror = (error) => {
			reject(error);
		};
	});
};
