import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { HypermediaEntityResponse } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, Model, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { CrudCreationStore } from './CrudCreationStore';
import { CrudActionStatus } from '../CrudActionStatus';
import { AsyncThunkConfig } from '../ThunkApiConfig';

// Initial store
export const createInitialCrudCreationStore = <T extends Model>(): CrudCreationStore<T> => {
	return {
		model: null,
		actionStatus: CrudActionStatus.IDLE,
		lastActionError: null
	};
};

// Slice creator
export const createCrudCreationSlice = <T extends Model, ThunkApiConfig extends AsyncThunkConfig>(
	storeName: string,
	createEntity: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		mutableModel: Mutable<T>
	}, ThunkApiConfig>
) => {
	return createSlice({
		name: storeName,
		initialState: createInitialCrudCreationStore<T>(),
		// Regular synchronous reducers
		reducers: {
			resetStore(store) {
				Object.assign(store, createInitialCrudCreationStore<T>());
			},
			resolveActionStatus(store) {
				store.actionStatus = CrudActionStatus.IDLE;
			}
		},
		// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
		// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
		// handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
		// https://github.com/immerjs/immer.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		extraReducers: {
			[String(createEntity.pending)]: (state) => {
				state.actionStatus = CrudActionStatus.CREATE_PENDING;
			},
			[String(createEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					state.model = action.payload.item;
					state.actionStatus = CrudActionStatus.CREATE_SUCCESS;
				}
			},
			[String(createEntity.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				state.lastActionError = lastActionError;
				state.actionStatus = CrudActionStatus.CREATE_FAILED;
			}
		}
	});
};

// Selector creators
export const createCrudSelectCreated = <T extends Model, Store extends Record<string, CrudCreationStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyOptional<T> => {
		return (rootStore): ReadonlyOptional<T> => {
			return new ReadonlyOptional(rootStore[storeName].model ?? null);
		};
	};
};

export const createCrudCreationStoreSize = <T extends Model, Store extends Record<string, CrudCreationStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => Readonly<number> => {
		return (rootStore): Readonly<number> => {
			return rootStore[storeName].model === null ? 0 : 1;
		};
	};
};

export const createCrudCreationStoreEntryIds = <T extends Model, Store extends Record<string, CrudCreationStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyArray<ModelPrimaryKey> => {
		return (rootStore): ReadonlyArray<ModelPrimaryKey> => {
			const model = rootStore[storeName].model;
			if (model === null) {
				return [];
			}
			return [model.id];
		};
	};
};
