import React from 'react';

import { useCustomerOrderInformationEntityData } from '@abb-emobility/oms/data-provider';
import { CustomerOrderInformationType } from '@abb-emobility/oms/domain-model';
import { AppError } from '@abb-emobility/shared/error';

import { AppointmentInformation } from './appointment-information/AppointmentInformation';
import { OrderItemInformation } from './order-item-information/OrderItemInformation';
import { QuotationInformation } from './quotation-information/QuotationInformation';
import { ShippingInformation } from './shipping-information/ShippingInformation';

export function OrderInformationFactory() {

	const orderInformationData = useCustomerOrderInformationEntityData();
	const orderInformationModel = orderInformationData.query().getOrThrow(new AppError());

	switch (orderInformationModel.type) {
		case CustomerOrderInformationType.ORDER_ITEMS:
			return (<OrderItemInformation orderInformation={orderInformationModel} />);
		case CustomerOrderInformationType.QUOTATION:
			return (<QuotationInformation orderInformation={orderInformationModel} />);
		case CustomerOrderInformationType.APPOINTMENT:
			return (<AppointmentInformation orderInformation={orderInformationModel} />);
		case CustomerOrderInformationType.SHIPPING:
			return (<ShippingInformation orderInformation={orderInformationModel} />);
	}

}
