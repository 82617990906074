import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
	PaginatedCollectionResponse,
	FilterCriteria,
	SortCriteria,
	HypermediaEntityResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, Mutation, Model, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { CrudCollectionStore } from './CrudCollectionStore';
import { CrudActionStatus } from '../CrudActionStatus';
import { FetchStatus } from '../FetchStatus';
import { ModelFilter } from '../ModelFilter';
import { ModelLimit } from '../ModelLimit';
import { ModelSort } from '../ModelSort';
import { AsyncThunkConfig } from '../ThunkApiConfig';

// Initial store
export const createInitialCrudCollectionStore = <T extends Model>() => {
	return {
		models: [] as Array<T>,
		fetchStatus: FetchStatus.IDLE,
		fetchEntityStatus: {},
		lastFetchError: null,
		currentPage: null,
		maxPages: null,
		sortCriteria: null,
		filterCriteria: null,
		actionStatus: CrudActionStatus.IDLE,
		lastActionError: null,
		createdEntity: null
	} as CrudCollectionStore<T>;
};

// Action error identifier
export enum CrudCollectionErrorAction {
	FETCH = 'FETCH',
	CREATE = 'CREATE',
	MUTATE = 'MUTATE',
	DELETE = 'DELETE'
}

// Slice creator
export const createCrudCollectionSlice = <T extends Model, ThunkApiConfig extends AsyncThunkConfig>(
	storeName: string,
	fetchAll: AsyncThunk<PaginatedCollectionResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		sortCriteria?: SortCriteria<T>,
		filterCriteria?: FilterCriteria<T>,
		forceFetch?: boolean
	}, ThunkApiConfig>,
	refetchAll: AsyncThunk<PaginatedCollectionResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>
	}, ThunkApiConfig>,
	fetchAllSorted: AsyncThunk<PaginatedCollectionResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		sortCriteria?: SortCriteria<T>,
		forceFetch?: boolean
	}, ThunkApiConfig>,
	fetchAllFiltered: AsyncThunk<PaginatedCollectionResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		filterCriteria?: FilterCriteria<T>,
		forceFetch?: boolean
	}, ThunkApiConfig>,
	fetchNext: AsyncThunk<PaginatedCollectionResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>
	}, ThunkApiConfig>,
	fetchEntity: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey,
		forceFetch?: boolean
	}, ThunkApiConfig>,
	refetchEntity: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey
	}, ThunkApiConfig>,
	mutateEntity: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey,
		modelMutation: Mutation<T>
	}, ThunkApiConfig>,
	createEntity: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		mutableModel: Mutable<T>
	}, ThunkApiConfig>,
	deleteEntity: AsyncThunk<Nullable<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey
	}, ThunkApiConfig>
) => {
	return createSlice({
		name: storeName,
		initialState: createInitialCrudCollectionStore<T>(),
		// Regular synchronous reducers
		reducers: {
			resetStore(store) {
				Object.assign(store, createInitialCrudCollectionStore<T>());
			},
			resolveActionStatus(store) {
				store.actionStatus = CrudActionStatus.IDLE;
			},
			resolveFetchStatus(store) {
				store.fetchStatus = FetchStatus.IDLE;
			}
		},
		// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
		// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
		// handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
		// https://github.com/immerjs/immer.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		extraReducers: {
			[String(fetchAll.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(fetchAll.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<T>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.sortCriteria = action.meta.arg.sortCriteria ?? null;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.filterCriteria = action.meta.arg.filterCriteria ?? null;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchAll.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(refetchAll.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(refetchAll.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<T>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(refetchAll.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchAllSorted.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(fetchAllSorted.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<T>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.sortCriteria = action.meta.arg.sortCriteria ?? null;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchAllSorted.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchAllFiltered.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(fetchAllFiltered.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<T>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.filterCriteria = action.meta.arg.filterCriteria ?? null;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchAllFiltered.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchNext.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PAGING_PENDING;
			},
			[String(fetchNext.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<T>>>) => {
				state.models = [...state.models, ...action.payload.items];
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchNext.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchEntity.pending)]: (state, action) => {
				state.fetchEntityStatus[action.meta.arg.id] = FetchStatus.PENDING;
			},
			[String(fetchEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload.item?.id;
					});
					if (index >= 0) {
						state.models[index] = action.payload.item;
					} else {
						state.models.push(action.payload.item);
					}
					state.fetchEntityStatus[action.payload.item?.id] = FetchStatus.SUCCESS;
				}
			},
			[String(fetchEntity.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchEntityStatus[action.meta.arg.id] = FetchStatus.FAILED;
			},
			[String(refetchEntity.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(refetchEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload.item?.id;
					});
					if (index >= 0) {
						state.models[index] = action.payload.item;
					} else {
						state.models.push(action.payload.item);
					}
				}
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(refetchEntity.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(mutateEntity.pending)]: (state) => {
				state.actionStatus = CrudActionStatus.MUTATE_PENDING;
			},
			[String(mutateEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload.item?.id;
					});
					if (index >= 0) {
						state.models[index] = action.payload.item;
					}
				}
				state.actionStatus = CrudActionStatus.MUTATE_SUCCESS;
			},
			[String(mutateEntity.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = CrudCollectionErrorAction.MUTATE;
				state.lastActionError = lastActionError;
				state.actionStatus = CrudActionStatus.MUTATE_FAILED;
			},
			[String(createEntity.pending)]: (state) => {
				state.createdEntity = null;
				state.actionStatus = CrudActionStatus.CREATE_PENDING;
			},
			[String(createEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					state.models.push(action.payload.item);
					state.createdEntity = action.payload.item;
					state.actionStatus = CrudActionStatus.CREATE_SUCCESS;
				}
			},
			[String(createEntity.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = CrudCollectionErrorAction.CREATE;
				state.lastActionError = lastActionError;
				state.actionStatus = CrudActionStatus.CREATE_FAILED;
			},
			[String(deleteEntity.pending)]: (state) => {
				state.actionStatus = CrudActionStatus.DELETE_PENDING;
			},
			[String(deleteEntity.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<T>>>) => {
				if (action.payload !== null) {
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload?.id;
					});
					if (index >= 0) {
						state.models.splice(index, 1);
					}
				}
				state.actionStatus = CrudActionStatus.DELETE_SUCCESS;
			},
			[String(deleteEntity.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = CrudCollectionErrorAction.DELETE;
				state.lastActionError = lastActionError;
				state.actionStatus = CrudActionStatus.DELETE_FAILED;
			}
		}
	});
};

// Selector creators
export const createCrudSelectCollection = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>>(storeName: string) => {
	return (
		filter?: ModelFilter<T>,
		sort?: ModelSort<T>,
		limit?: ModelLimit
	): (rootStore: Store) => ReadonlyArray<T> => {
		return (rootStore): ReadonlyArray<T> => {
			let modelCollection = rootStore[storeName].models;
			if (filter) {
				modelCollection = modelCollection.filter(filter as ModelFilter<T>);
			}
			if (sort) {
				modelCollection = [...modelCollection].sort(sort);
			}
			if (limit) {
				const offset = limit.offset ?? 0;
				modelCollection = modelCollection.slice(offset, offset + limit.limit);
			}
			return modelCollection;
		};
	};
};

export const createCrudCountCollection = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>>(storeName: string) => {
	return (filter?: ModelFilter<T>): (rootStore: Store) => number => {
		return (rootStore): number => {
			let modelCollection = rootStore[storeName].models;
			if (filter) {
				modelCollection = modelCollection.filter(filter as ModelFilter<T>);
			}
			return modelCollection.length;
		};
	};
};

export const createCrudSelectCollectionEntity = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>>(storeName: string) => {
	return (id: ModelPrimaryKey): (rootStore: Store) => ReadonlyOptional<T> => {
		return (rootStore): ReadonlyOptional<T> => {
			const modelEntity = rootStore[storeName].models.find((model): boolean => {
				return model.id === id;
			});
			return new ReadonlyOptional(modelEntity);
		};
	};
};

export const createCrudSelectCreatedEntity = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyOptional<T> => {
		return (rootStore): ReadonlyOptional<T> => {
			return new ReadonlyOptional(rootStore[storeName]?.createdEntity);
		};
	};
};

export const createCrudCollectionStoreSize = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => Readonly<number> => {
		return (rootStore): Readonly<number> => {
			return rootStore[storeName].models.length;
		};
	};
};

export const createCrudCollectionStoreEntryIds = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyArray<ModelPrimaryKey> => {
		return (rootStore): ReadonlyArray<ModelPrimaryKey> => {
			return rootStore[storeName].models.map<ModelPrimaryKey>((model) => {
				return model.id;
			});
		};
	};
};
