import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ApiError } from '@abb-emobility/shared/error';
import { trimFromRight } from '@abb-emobility/shared/util';

import { FilterCriteria } from '../collection-criteria/FilterCriteria';
import { isMultiValueFilterCriteriaRule, isSingleValueFilterCriteriaRule } from '../collection-criteria/FilterCriteria.guards';
import { SortCriteria, SortDirection } from '../collection-criteria/SortCriteria';
import { PaginationRequestHeader } from '../collection-pagination/PaginationRequestHeader';

export class ApiUrlBuilder<Model> {

	private sortableQuery = 'sort';
	private filterableQuery = 'filter';

	private readonly baseUrl: string;
	private readonly collectionControllerUri: string;
	private readonly paginationControllerUri: string;
	private readonly entityControllerUri: string;
	private readonly entitySearchControllerUri: string;
	private readonly paginationSize: number;

	constructor(
		baseUrl: string,
		collectionControllerUri: string,
		paginationControllerUri: string,
		entityControllerUri: string,
		entitySearchControllerUri: string,
		paginationSize: number
	) {
		this.baseUrl = baseUrl;
		this.collectionControllerUri = collectionControllerUri;
		this.paginationControllerUri = paginationControllerUri;
		this.entityControllerUri = entityControllerUri;
		this.entitySearchControllerUri = entitySearchControllerUri;
		this.paginationSize = paginationSize;
	}

	public buildCollectionEndpoint(sort?: SortCriteria<Model>, filter?: FilterCriteria<Model>, pageSize: number | null = this.paginationSize, relative = false): string {
		const baseUrl = relative ? '/' : this.baseUrl;
		const endpointUrl = new URL(baseUrl + this.collectionControllerUri);
		if (pageSize !== null) {
			endpointUrl.searchParams.set(PaginationRequestHeader.PAGE_SIZE, pageSize.toString());
		}
		if (sort !== undefined && sort.length > 0) {
			this.appendSortParams(endpointUrl, sort);
		}
		if (filter !== undefined && filter.length > 0) {
			this.appendFilterParams(endpointUrl, filter);
		}
		return endpointUrl.toString();
	}

	public buildPaginationEndpoint(page: number, sort?: SortCriteria<Model>, filter?: FilterCriteria<Model>, relative = false): string {
		const baseUrl = relative ? '/' : this.baseUrl;
		const endpointUrl = new URL(baseUrl + this.paginationControllerUri);
		endpointUrl.searchParams.set(PaginationRequestHeader.PAGE, page.toString());
		endpointUrl.searchParams.set(PaginationRequestHeader.PAGE_SIZE, this.paginationSize.toString());
		if (sort !== undefined && sort.length > 0) {
			this.appendSortParams(endpointUrl, sort);
		}
		if (filter !== undefined && filter.length > 0) {
			this.appendFilterParams(endpointUrl, filter);
		}
		return endpointUrl.toString();
	}

	public buildEntityEndpoint(id: ModelPrimaryKey, relative = false): string {
		const baseUrl = relative ? '/' : this.baseUrl;
		return baseUrl + this.entityControllerUri.replace('{id}', id);
	}

	public buildSearchEntityEndpoint(filter: FilterCriteria<Model>, relative = false): string {
		const baseUrl = relative ? '/' : this.baseUrl;
		const endpointUrl = new URL(baseUrl + this.entitySearchControllerUri);
		if (filter.length > 0) {
			this.appendFilterParams(endpointUrl, filter);
		}
		return endpointUrl.toString();
	}

	public buildCreateEntityEndpoint(relative = false): string {
		const baseUrl = relative ? '/' : this.baseUrl;
		return baseUrl + this.entityControllerUri.replace('/{id}', '');
	}

	public buildEntityMethodEndpoint(id: ModelPrimaryKey, method: string, relative = false): string {
		return trimFromRight(this.buildEntityEndpoint(id, relative), '/') + '/' + method + '/';
	}

	private appendSortParams = (url: URL, sort: SortCriteria<Model>) => {
		const sortParams = sort.map<string>((sortCriteria) => {
			return sortCriteria.property.toString() + ':' + (sortCriteria?.direction ?? SortDirection.ASCENDING);
		});
		url.searchParams.set(this.sortableQuery, sortParams.join(';'));
	};

	private appendFilterParams = (url: URL, filter: FilterCriteria<Model>) => {
		const filterParams: Array<string> = [];
		for (const filterCriteria of filter) {
			for (const filterCriterion of filterCriteria.criteria) {
				const comparator = filterCriterion.comparator ?? '=';
				let value: string;
				if (isSingleValueFilterCriteriaRule(filterCriterion)) {
					value = filterCriterion.value.toString();
				} else if (isMultiValueFilterCriteriaRule(filterCriterion)) {
					value = filterCriterion.value.join('|');
				} else {
					throw new ApiError('Invalid filter criteria');
				}
				filterParams.push((filterCriterion.property as keyof Model).toString() + comparator.toString() + value);
			}
		}
		url.searchParams.set(this.filterableQuery, filterParams.join(';'));
	};
}
