import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { OrderInstallerAppointmentApiClient } from './OrderInstallerAppointmentApiClient';
import { OrderInstallerAppointmentApiClientMock } from './OrderInstallerAppointmentApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderInstallerAppointmentApiClientInterface extends CrudApiClient<InstallerAppointmentModel> {
}

export class OrderInstallerAppointmentApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): OrderInstallerAppointmentApiClientInterface {
		if (process.env.NX_OMS_IP_APPOINTMENT_API_MOCKED === 'true') {
			const apiClientMock = new OrderInstallerAppointmentApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new OrderInstallerAppointmentApiClient().init(baseUrl, jsonWebToken);
	}

}
