import {
	AccessDeniedError,
	ApiError,
	AppError,
	AuthenticationFailedError,
	AuthenticationRequiredError,
	NetworkError,
	NotFoundError,
	StoreError,
	TimeoutError,
	TooEarlyError,
	ValidationError,
	WebSocketError
} from './Error';
import { AnyErrorObject } from './ErrorObject';

export const restoreError = (errorObject: AnyErrorObject): AppError => {
	let error;
	switch (errorObject.discriminator) {
		case 'AppError':
			error = new AppError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'StoreError':
			error = new StoreError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'NotFoundError':
			error = new NotFoundError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'TooEarlyError':
			error = new TooEarlyError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'NetworkError':
			error = new NetworkError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'TimeoutError':
			error = new TimeoutError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'ApiError':
			error = new ApiError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'ValidationError':
			error = new ValidationError(errorObject.message, errorObject.status, errorObject.timestamp, errorObject.details);
			break;
		case 'AuthenticationRequiredError':
			error = new AuthenticationRequiredError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'AuthenticationFailedError':
			error = new AuthenticationFailedError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'AccessDeniedError':
			error = new AccessDeniedError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
		case 'WebSocketError':
			error = new WebSocketError(errorObject.message, errorObject.status, errorObject.timestamp);
			break;
	}
	error.stack = errorObject.stack;
	return error;
};
