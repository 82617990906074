import {
	CustomerOrderInformationShipmentInformationModel,
	CustomerOrderInformationShippingInformationModel
} from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerOrderInformationShippingLineItemModelMock01,
	customerOrderInformationShippingLineItemModelMock02
} from './CustomerOrderInformationShippingLineItemModel.mock';

const customerOrderInformationShipmentInformationModelMock: Dto<CustomerOrderInformationShipmentInformationModel> = {
	carrierName: 'DHL',
	recipientName: 'Maximilian Mustermann',
	shippingAddress: 'Ringstraße 89, 24114 Kiel',
	trackingNumber: '3257340672497235',
	trackingUrl: 'https://www.dhl.de/de/privatkunden/pakete-empfangen/verfolgen.html?piececode=3257340672497235',
	shippingLineItems: [customerOrderInformationShippingLineItemModelMock01, customerOrderInformationShippingLineItemModelMock02]
};

export const customerOrderInformationShippingInformationModelMock: Dto<CustomerOrderInformationShippingInformationModel> = {
	id: '1',
	shipments: [
		customerOrderInformationShipmentInformationModelMock,
		customerOrderInformationShipmentInformationModelMock
	]
};
