import { CustomerOrderDocumentModel, CustomerOrderDocumentModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerOrderDocumentApiClient extends AbstractCrudApiClient<CustomerOrderDocumentModel> {
	protected collectionControllerUri = 'documents/';
	protected paginationControllerUri = 'documents/';
	protected entityControllerUri = 'document/{id}/';
	protected entitySearchControllerUri = 'document/';
	protected modelConverter = new CustomerOrderDocumentModelConverter();
}
