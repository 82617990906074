/* eslint-disable @typescript-eslint/no-empty-function */
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { ModelConverter, ModelValue, Mutable, Mutation, Dto, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ApiError } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { CrudApiClient } from './CrudApiClient';
import { ApiUrlBuilder } from '../api-url-builder/ApiUrlBuilder';
import { FilterCriteria } from '../collection-criteria/FilterCriteria';
import { SortCriteria } from '../collection-criteria/SortCriteria';
import {
	CollectionPaginationResponse,
	extractPaginationResponse,
	PaginatedCollectionResponse
} from '../collection-pagination/PaginatedCollectionResponse';
import { extractHypermediaResponse, HypermediaEntityResponse } from '../hypermedia/HypermdiaEntityResponse';
import { JsonRestRequestFactory } from '../network/JsonRestRequestFactory';
import { RestResponse } from '../network/JsonRestRequestInterface';

export abstract class AbstractCrudApiClient<Model extends Record<string, ModelValue>, DtoModel extends Dto<Model> = Dto<Model>> implements CrudApiClient<Model> {

	protected abstract collectionControllerUri: string;
	protected abstract paginationControllerUri: string;
	protected abstract entityControllerUri: string;
	protected abstract entitySearchControllerUri: string;

	protected paginationSize = 50;

	protected abstract modelConverter: ModelConverter<Model>;

	private apiUrlBuilder: Nullable<ApiUrlBuilder<Model>> = null;
	private jsonWebToken: Nullable<JsonWebToken> = null;

	public init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
		this.apiUrlBuilder = new ApiUrlBuilder<Model>(
			baseUrl,
			this.collectionControllerUri,
			this.paginationControllerUri,
			this.entityControllerUri,
			this.entitySearchControllerUri,
			this.paginationSize
		);
		this.jsonWebToken = jsonWebToken;
		return this;
	}

	public getUrlBuilder(): ApiUrlBuilder<Model> {
		if (this.apiUrlBuilder === null) {
			throw new ApiError('CRUD API not initialized');
		}
		return this.apiUrlBuilder;
	}

	protected preFetchCollection(_endpoint: string, _sort?: SortCriteria<Model>, _filter?: FilterCriteria<Model>): void {
	}

	protected async postFetchCollection(
		collection: Array<DtoModel>,
		_response: RestResponse<unknown>
	): Promise<Array<DtoModel>> {
		return collection;
	}

	protected preHeadCollection(_endpoint: string, _filter?: FilterCriteria<Model>): void {
	}

	protected preFetchPage(_endpoint: string, _page: number, _sort?: SortCriteria<Model>, _filter?: FilterCriteria<Model>): void {
	}

	protected async postFetchPage(collection: Array<DtoModel>, _response: RestResponse<unknown>): Promise<Array<DtoModel>> {
		return collection;
	}

	protected preFetch(_endpoint: string, _id: ModelPrimaryKey): void {
	}

	protected async postFetch(apiModel: DtoModel, _response: RestResponse<unknown>): Promise<DtoModel> {
		return apiModel;
	}

	protected preSearchEntity(_endpoint: string, _filter: FilterCriteria<Model>): void {
	}

	protected async postSearchEntity(apiModel: DtoModel, _response: RestResponse<unknown>): Promise<DtoModel> {
		return apiModel;
	}

	protected preMutate(_endpoint: string, _id: ModelPrimaryKey, _mutation: Mutation<Model>): void {
	}

	protected async postMutate(apiModel: DtoModel, _response: RestResponse<unknown>): Promise<DtoModel> {
		return apiModel;
	}

	protected preCreate(_endpoint: string, _mutable: Mutable<Model>): void {
	}

	protected async postCreate(apiModel: DtoModel, _response: RestResponse<unknown>): Promise<DtoModel> {
		return apiModel;
	}

	protected preDelete(_endpoint: string, _id: ModelPrimaryKey): void {
	}

	protected async postDelete(apiModel: DtoModel, _response: RestResponse<unknown>): Promise<DtoModel> {
		return apiModel;
	}

	public async fetchCollection(sort?: SortCriteria<Model>, filter?: FilterCriteria<Model>): Promise<PaginatedCollectionResponse<Model>> {
		const endpoint = this.getUrlBuilder().buildCollectionEndpoint(sort, filter);
		this.preFetchCollection(endpoint, sort, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let modelCollection = parsedResponse.body as Array<DtoModel>;
		modelCollection = await this.postFetchCollection(modelCollection, parsedResponse);
		const items = modelCollection.map((apiModel): Model => {
			return this.modelConverter.toModel(apiModel);
		});
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}

	public async headCollection(filter?: FilterCriteria<Model>): Promise<CollectionPaginationResponse> {
		const endpoint = this.getUrlBuilder().buildCollectionEndpoint(undefined, filter);
		this.preHeadCollection(endpoint, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).head(endpoint);
		return extractPaginationResponse(parsedResponse.headers);
	}

	public async fetchPage(
		page = 1,
		sort?: SortCriteria<Model>,
		filter?: FilterCriteria<Model>
	): Promise<PaginatedCollectionResponse<Model>> {
		const endpoint = this.getUrlBuilder().buildPaginationEndpoint(page, sort, filter);
		this.preFetchPage(endpoint, page, sort, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let modelCollection = parsedResponse.body as Array<DtoModel>;
		modelCollection = await this.postFetchCollection(modelCollection, parsedResponse);
		const items = modelCollection.map((apiModel): Model => {
			return this.modelConverter.toModel(apiModel);
		});
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}

	public async fetchEntity(id: ModelPrimaryKey): Promise<HypermediaEntityResponse<Model>> {
		const endpoint = this.getUrlBuilder().buildEntityEndpoint(id);
		this.preFetch(endpoint, id);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let apiModel = parsedResponse.body as DtoModel;
		apiModel = await this.postFetch(apiModel, parsedResponse);
		return { item: this.modelConverter.toModel(apiModel), hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	async searchEntity(filter: FilterCriteria<Model>): Promise<HypermediaEntityResponse<Model>> {
		const endpoint = this.getUrlBuilder().buildSearchEntityEndpoint(filter);
		this.preSearchEntity(endpoint, filter);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).get(endpoint);
		let apiModel = parsedResponse.body as DtoModel;
		apiModel = await this.postSearchEntity(apiModel, parsedResponse);
		return { item: this.modelConverter.toModel(apiModel), hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async mutate(id: ModelPrimaryKey, mutation: Mutation<Model>): Promise<HypermediaEntityResponse<Model>> {
		const endpoint = this.getUrlBuilder().buildEntityEndpoint(id);
		this.preMutate(endpoint, id, mutation);
		const apiMutation = this.modelConverter.toDto(mutation);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).patch(endpoint, apiMutation);
		let apiModel = parsedResponse.body as DtoModel;
		apiModel = await this.postMutate(apiModel, parsedResponse);
		return { item: this.modelConverter.toModel(apiModel), hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async create(model: Mutable<Model>): Promise<HypermediaEntityResponse<Model>> {
		const endpoint = this.getUrlBuilder().buildCreateEntityEndpoint();
		this.preCreate(endpoint, model);
		const apiMutableModel = this.modelConverter.toDto(model);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).post(endpoint, apiMutableModel);
		let apiModel = parsedResponse.body as DtoModel;
		apiModel = await this.postCreate(apiModel, parsedResponse);
		return { item: this.modelConverter.toModel(apiModel), hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async delete(id: ModelPrimaryKey): Promise<Nullable<Model>> {
		const endpoint = this.getUrlBuilder().buildEntityEndpoint(id);
		this.preDelete(endpoint, id);
		const parsedResponse = await JsonRestRequestFactory.create(this.jsonWebToken).delete(endpoint);
		let apiModel = parsedResponse.body as DtoModel;
		apiModel = await this.postDelete(apiModel, parsedResponse);
		return this.modelConverter.toModel(apiModel);
	}

}
