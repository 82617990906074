import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Selecione',
			pictureSelectPlaceholder: 'Selecionar imagem ou tirar uma fotografia',
			pictureSelectTrigger: 'Selecionar imagem',
			pictureSelectHint: {
				default: 'Certifique-se de que não há pessoas na fotografia.',
				examplePicture: 'Utilize a imagem de exemplo como guia e certifique-se de que não há pessoas na imagem.'
			},
			documentSelectPlaceholder: 'Selecione um cliente',
			documentSelectTrigger: 'Selecione um documento',
			selectedFileTooLarge: 'O ficheiro selecionado é demasiado grande para ser transferido. Selecione um ficheiro com menos de {{maxFileSize}}.',
			answerTypeNotFound: 'O tipo de resposta {{type}} é desconhecido.',
			previousSegmentButtonLabel: 'Voltar',
			nextSegmentButtonLabel: 'Próximo passo',
			submitButtonLabel: 'Enviar informações',
			validationErrors: {
				heading: 'Há alguns problemas com as suas respostas',
				message: 'Por favor, assinale as suas respostas para completar o questionário'
			}
		}
	}
};

export default literals;
