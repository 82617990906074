import { ButtonHTMLAttributes, ReactNode } from 'react';

import './ButtonIcon.scss';

export type ButtonIconProps = {
	children: ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonIcon(props: ButtonIconProps) {
	const { type = 'button', children, ...rest } = props;

	return (
		<button
			type={type}
			className="button-icon"
			{...rest}
		>{children}</button>
	);
}
