import { GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { UploadFile } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export enum PreCheckDecisionGridOperatorDecision {
	ACCEPTED = 'ACCEPTED',
	DECLINED = 'DECLINED'
}

export type PreCheckDecisionGridOperatorPayloadModel = {
	readonly gridOperator: GridOperatorModel,
	readonly wallboxPower: number,
	decision?: PreCheckDecisionGridOperatorDecision,
	documents?: Array<UploadFile>
} & TaskPayloadFoundationModel;

export class PreCheckDecisionGridOperatorPayloadModelConverter extends ModelConverter<PreCheckDecisionGridOperatorPayloadModel> {

	override getFieldConverterMapByModel(): Map<keyof PreCheckDecisionGridOperatorPayloadModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}
}

export type PreCheckDecisionGridOperatorEscalationPayloadModel =
	PreCheckDecisionGridOperatorPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

export class PreCheckDecisionGridOperatorEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<PreCheckDecisionGridOperatorEscalationPayloadModel> {
	private payloadModelConverter = new PreCheckDecisionGridOperatorPayloadModelConverter();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof PreCheckDecisionGridOperatorEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
				...Array.from(super.getFieldConverterMapByModel()),
				...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
			]
		);
	}

}
