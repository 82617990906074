import { isArray } from '@abb-emobility/shared/util';

export enum SingleValueFilterComparator {
	EQUAL = '==',
	NOT_EQUAL = '!==',
	LIKE = '=',
	NOT_LIKE = '!=',
	GREATER_THAN = '>',
	GREATER_THAN_EQUAL = '>=',
	LESS_THAN = '<',
	LESS_THAN_EQUAL = '<='
}

export enum MultiValueFilterComparator {
	ONE_OF = '='
}

export type FilterCriteriaRuleValue = string | number;

export type SingleValueFilterCriteriaRule<Model> = {
	property: keyof Model,
	comparator?: SingleValueFilterComparator,
	value: FilterCriteriaRuleValue
};

export type SingleValueFreeFilterCriteriaRule = {
	flavor: 'free',
	property: string,
	comparator?: SingleValueFilterComparator,
	value: FilterCriteriaRuleValue
};

export type MultiValueFilterCriteriaRule<Model> = {
	property: keyof Model,
	comparator?: MultiValueFilterComparator,
	value: Array<FilterCriteriaRuleValue>
};

export type MultiValueFreeFilterCriteriaRule = {
	flavor: 'free',
	property: string,
	comparator?: MultiValueFilterComparator,
	value: Array<FilterCriteriaRuleValue>
};

export type ValueFilterCriteriaRule<Model> =
	SingleValueFilterCriteriaRule<Model>
	| MultiValueFilterCriteriaRule<Model>
	| SingleValueFreeFilterCriteriaRule
	| MultiValueFreeFilterCriteriaRule;

export type FilterCriteriaEntry<Model> = {
	id: string,
	criteria: Array<ValueFilterCriteriaRule<Model>>
};

export type FilterCriteria<Model> = Array<FilterCriteriaEntry<Model>>;

export const compareFilterCriteria = <Model>(left?: FilterCriteria<Model>, right?: FilterCriteria<Model>): boolean => {
	if (left === undefined && right === undefined) {
		return true;
	}
	if (left === undefined || right === undefined) {
		return false;
	}
	if (left.length !== right.length) {
		return false;
	}
	for (let i = 0, l = left.length; i < l; i++) {
		if (left[i].id !== right[i].id) {
			return false;
		}
		if (left[i].criteria.length !== right[i].criteria.length) {
			return false;
		}

		for (let j = 0, m = left[i].criteria.length; j < m; j++) {
			if (left[i].criteria[j].property !== right[i].criteria[j].property) {
				return false;
			}
			if (left[i].criteria[j].comparator !== right[i].criteria[j].comparator) {
				return false;
			}
			// Check value
			const leftValue = left[i].criteria[j].value;
			const rightValue = right[i].criteria[j].value;
			if (isArray(leftValue) && isArray(rightValue)) {
				// Both values are multi value criteria values, so array of scalar
				if (!leftValue.every((value, index) => value === rightValue[index])) {
					return false;
				}
			} else if (leftValue !== rightValue) {
				return false;
			}
		}
	}
	return true;
};
