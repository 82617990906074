import React, { ReactNode } from 'react';

import { TradeAppointment } from '@abb-emobility/oms/customer-ui-domain';
import { CustomerOrderInformationAppointmentModel } from '@abb-emobility/oms/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import './AppointmentInformation.scss';

export type AppointmentInformationProps = {
	orderInformation: CustomerOrderInformationAppointmentModel
};

export function AppointmentInformation(props: AppointmentInformationProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderTradeAppointments = (): ReactNode => {
		return orderInformation.payload.appointments.map((tradeAppointment) => {
			return (
				<TradeAppointment key={tradeAppointment.trade} tradeAppointment={tradeAppointment} />
			);
		});
	};

	return (
		<article className="appointment-information">
			<h1 className="appointment-information__heading">
				{l10n.translate('omsCustomerApp.order.information.appointment.heading')}
			</h1>
			<div className="appointment-information__trades">
				{renderTradeAppointments()}
			</div>
		</article>
	);
}
