import { ReactNode } from 'react';

import './FormElements.scss';

export type FormElementsProps = {
	children?: ReactNode
};

export function FormElements(props: FormElementsProps) {
	const { children } = props;
	return (
		<div className="form-elements">
			{children}
		</div>
	);
}
