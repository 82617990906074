import { PreCheckMediumModel, PreCheckMediumModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey, TimeOfDay } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type PreCheckChannelModel = {
	readonly medium: PreCheckMediumModel,
	readonly availableTimesOfDay: ReadonlyArray<TimeOfDay>
} & TaskPayloadFoundationModel;

export class PreCheckChannelModelConverter extends ModelConverter<PreCheckChannelModel> {
	override getFieldConverterMapByModel(): Map<keyof PreCheckChannelModel, ModelConverterInterface<PreCheckMediumModel>> {
		return new Map([
			['medium', new PreCheckMediumModelConverter()]
		]);
	}
}

export type PreCheckPreferencesPayloadModel = {
	readonly availableChannels: ReadonlyArray<PreCheckChannelModel>,
	preferredMediumId?: ModelPrimaryKey,
	preferredTimesOfDay?: Array<TimeOfDay>
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PreCheckPreferencesPayloadModelConverter<T extends PreCheckPreferencesPayloadModel> extends ModelConverter<PreCheckPreferencesPayloadModel> {
	override getFieldConverterMapByModel(): Map<keyof PreCheckPreferencesPayloadModel, ModelConverterInterface<PreCheckChannelModel>> {
		return new Map([
			['availableChannels', new PreCheckChannelModelConverter()]
		]);
	}
}

export type PreCheckPreferencesEscalationPayloadModel = PreCheckPreferencesPayloadModel & CustomerServiceTaskPayloadFoundationModel;

export class PreCheckPreferencesEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<PreCheckPreferencesEscalationPayloadModel> {
	private payloadModelConverter = new PreCheckPreferencesPayloadModelConverter();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof PreCheckPreferencesEscalationPayloadModel, ModelConverterInterface<any>> {

		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
