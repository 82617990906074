import { useL10n } from '@abb-emobility/shared/localization-provider';
import { AnswerTypeId, QuestionnaireSegment } from '@abb-emobility/shared/questionnaire';

export const useQuestionnaireConsentSegment = (): QuestionnaireSegment => {

	const l10n = useL10n();

	return {
		key: 'consent',
		description: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.description'),
		sections: [
			{
				key: 'power-of-attorney',
				heading: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.powerOfAttorney.heading'),
				description: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.powerOfAttorney.description'),
				questions: [
					{
						key: 'grid-operator',
						question: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.powerOfAttorney.gridOperator.question'),
						answerType: {
							type: AnswerTypeId.TEXT,
							options: {
								required: true
							}
						}
					},
					{
						key: 'power-of-attorney',
						question: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.powerOfAttorney.powerOfAttorney.question'),
						answerType: {
							type: AnswerTypeId.CONSENT,
							options: {
								required: true
							}
						}
					}
				]
			},
			{
				key: 'marketing-consent',
				heading: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.marketingConsent.heading'),
				questions: [
					{
						key: 'installation-partner-marketing-consent',
						question: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.marketingConsent.installationPartnerMarketingConsent.question'),
						answerType: {
							type: AnswerTypeId.CONSENT,
							options: {
								required: false
							}
						}
					}
				]
			},
			{
				key: 'survey-consent',
				heading: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.surveyConsent.heading'),
				description: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.surveyConsent.description'),
				questions: [
					{
						key: 'survey-consent',
						question: l10n.translate('omsCustomerApp.task.basicQuestions.questionnaire.consent.surveyConsent.surveyConsent.question'),
						answerType: {
							type: AnswerTypeId.CONSENT,
							options: {
								required: false
							}
						}
					}
				]
			}
		]
	};

};
