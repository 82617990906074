import { DetailedError, SimpleError } from './Error';

const errorTypes = [
	'AppError',
	'NotFoundError',
	'TooEarlyError',
	'StoreError',
	'TimeoutError',
	'NetworkError',
	'ApiError',
	'ValidationError',
	'AuthenticationRequiredError',
	'AuthenticationFailedError',
	'AccessDeniedError',
	'WebSocketError'
] as const;

export type ErrorDiscriminator = typeof errorTypes[number];

export type SimpleErrorObject = {
	name: string,
	message: string,
	status?: number,
	timestamp?: string,
	stack?: string
};

export type ErrorDetailObject = {
	message: string,
	reference?: string
};

export type DetailedErrorObject = SimpleErrorObject & {
	details?: Array<ErrorDetailObject>
};

export type ErrorObject = SimpleErrorObject & {
	discriminator: 'AppError'
};

export type StoreErrorObject = SimpleErrorObject & {
	discriminator: 'StoreError'
};

export type NotFoundErrorObject = SimpleErrorObject & {
	discriminator: 'NotFoundError'
};

export type TooEarlyErrorObject = SimpleErrorObject & {
	discriminator: 'TooEarlyError'
};

export type TimeoutErrorObject = SimpleErrorObject & {
	discriminator: 'TimeoutError'
};

export type NetworkErrorObject = SimpleErrorObject & {
	discriminator: 'NetworkError'
};

export type ApiErrorObject = SimpleErrorObject & {
	discriminator: 'ApiError'
};

export type ValidationErrorObject = DetailedErrorObject & {
	discriminator: 'ValidationError'
};

export type AuthenticationRequiredErrorObject = SimpleErrorObject & {
	discriminator: 'AuthenticationRequiredError'
};

export type AuthenticationFailedErrorObject = SimpleErrorObject & {
	discriminator: 'AuthenticationFailedError'
};

export type AccessDeniedErrorObject = SimpleErrorObject & {
	discriminator: 'AccessDeniedError'
};

export type WebSocketErrorObject = SimpleErrorObject & {
	discriminator: 'WebSocketError'
};

export type AnyErrorObject =
	| ErrorObject
	| StoreErrorObject
	| NotFoundErrorObject
	| TooEarlyErrorObject
	| TimeoutErrorObject
	| NetworkErrorObject
	| ApiErrorObject
	| ValidationErrorObject
	| AuthenticationRequiredErrorObject
	| AuthenticationFailedErrorObject
	| AccessDeniedErrorObject
	| WebSocketErrorObject;

export const createErrorObject = (error: Error): AnyErrorObject => {
	const discriminator = errorTypes.includes(error.name as ErrorDiscriminator) ? error.name as ErrorDiscriminator : 'AppError';
	const status = error instanceof SimpleError ? error.status : undefined;
	const timestamp = error instanceof SimpleError ? error.timestamp : undefined;
	const details = error instanceof DetailedError ? error.details : undefined;
	return {
		discriminator,
		name: error.name,
		message: error.message,
		stack: error.stack,
		status,
		timestamp,
		details
	};
};
