import { readLocalStorage, removeLocalStorage, writeLocalStorage } from '@abb-emobility/shared/local-storage';
import { Nullable } from '@abb-emobility/shared/util';

import { CandidateGroup } from '../foundation/CandidateGroup';

export const CANDIDATE_GROUP_STORAGE_KEY = 'lib_candidate_group';

export class CandidateGroupHandler {

	private static instance: Nullable<CandidateGroupHandler> = null;
	public scope?: string;

	private constructor(scope?: string) {
		this.scope = scope;
	}

	static get(scope?: string): CandidateGroupHandler {
		if (this.instance === null) {
			this.instance = new this(scope);
		}
		this.instance.scope = scope;
		return this.instance;
	}

	setCandidateGroups(candidateGroups: Array<CandidateGroup>): void {
		writeLocalStorage<Array<CandidateGroup>>(CANDIDATE_GROUP_STORAGE_KEY, candidateGroups, { namespace: this.scope });
	}

	unsetCandidateGroups(): void {
		removeLocalStorage(CANDIDATE_GROUP_STORAGE_KEY, { namespace: this.scope });
	}

	getCandidateGroups(): Array<CandidateGroup> {
		return readLocalStorage<Array<CandidateGroup>>(CANDIDATE_GROUP_STORAGE_KEY, { namespace: this.scope }).getOrDefault([]);
	}

}
