import { createContext } from 'react';

import { AppError } from '@abb-emobility/shared/error';
import { Optional } from '@abb-emobility/shared/util';

export type EnvironmentValue = string | number | boolean | Array<unknown>;
export type Environment = { [_: string]: EnvironmentValue };

export type EnvContextValue = {
	get<T = EnvironmentValue>(key: string): Optional<T>
};

function getter<T = EnvironmentValue>(_key: string): Optional<T> {
	throw new AppError('Environment provider not found');
}

export const envContext = createContext<EnvContextValue>({
	get: getter
});
