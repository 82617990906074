import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AuthProvider, AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { NotFoundError } from '@abb-emobility/shared/error';
import { DialogueProvider } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { UNAUTHENTICATE_SIGNAL } from '../../../store/store';
import { OrderViewLayout } from '../../components/order-view-layout/OrderViewLayout';
import { OtpAuthEmitter } from '../../components/otp-auth-emitter/OtpAuthEmitter';
import { PaymentResult } from '../../components/payment/PaymentResult';
import { createRouteUrl, PaymentResultRouteParam, RoutePath } from '../../router/Routes';

export function PaymentResultView() {

	const l10n = useL10n();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		orderId = null,
		paymentResult = PaymentResultRouteParam.PAYMENT_FAILED
	} = useParams<{ orderId: string, paymentResult: PaymentResultRouteParam }>();

	if (orderId === null) {
		throw new NotFoundError(l10n.translate('omsCustomerApp.error.orderNotFound'));
	}

	const handleReauthenticate = () => {
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
	};

	const handleUnauthenticate = () => {
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
		navigate(createRouteUrl(RoutePath.ORDER, ['orderId', orderId]));
	};

	const renderOtpAuthEmitter = (): ReactElement<AuthProviderInterceptingProps> => {
		return (<OtpAuthEmitter orderId={orderId} />);
	};

	return (
		<AuthProvider
			authScope={orderId}
			interceptingComponent={renderOtpAuthEmitter}
			onReauthenticate={handleReauthenticate}
			onUnauthenticate={handleUnauthenticate}
		>
			<DialogueProvider>
				<OrderViewLayout orderId={orderId}>
					<PaymentResult orderId={orderId} paymentResult={paymentResult} />
				</OrderViewLayout>
			</DialogueProvider>
		</AuthProvider>
	);
}
