import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { dismissableContext, DismissableContextValue } from './Dialogue.context';
import { ButtonGhost } from '../button-ghost/ButtonGhost';

import './Dialogue.scss';

export type DialogueProps = {
	caption: string,
	children?: ReactNode,
	dismissLabel?: string,
	onDismiss?: () => void
};

export function Dialogue(props: DialogueProps) {

	const { caption, children, dismissLabel, onDismiss } = props;
	const [visible, setVisible] = useState<boolean>(true);
	const backdrpoRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const scrollY = window.scrollY;
		document.body.style.height = '100vh';
		document.body.style.width = '100vw';
		document.body.style.overflowY = 'hidden';
		document.body.style.paddingRight = '15px';
		document.body.style.position = 'fixed';
		document.body.style.top = `-${scrollY}px`;
		return () => {
			const scrollY = document.body.style.top;
			document.body.style.height = 'auto';
			document.body.style.width = 'auto';
			document.body.style.overflowY = 'auto';
			document.body.style.paddingRight = '0';
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		};
	});

	const startDismiss = (): void => {
		setVisible(false);
	};

	const handleUnmount = (): void => {
		if (onDismiss !== undefined) {
			onDismiss();
		}
	};

	const renderFooter = (): ReactNode => {
		if (dismissLabel === undefined) {
			return null;
		}
		return (
			<footer className="dialogue__content__footer">
				<ButtonGhost label={dismissLabel} onClick={startDismiss} />
			</footer>
		);
	};

	const dismissableProvider: DismissableContextValue = {
		dismiss: startDismiss
	};

	return (
		<aside className="dialogue">
			<CSSTransition
				in={visible}
				appear={true}
				timeout={300}
				classNames="dialogue__backdrop"
				nodeRef={backdrpoRef}
				unmountOnExit={true}
			>
				<div className="dialogue__backdrop" ref={backdrpoRef} onClick={startDismiss} />
			</CSSTransition>

			<CSSTransition
				in={visible}
				appear={true}
				timeout={300}
				classNames="dialogue__content"
				nodeRef={contentRef}
				unmountOnExit={true}
				onExited={handleUnmount}
			>
				<article className="dialogue__content" ref={contentRef}>
					<dismissableContext.Provider value={dismissableProvider}>
						<header className="dialogue__content__header">
							<h1>{caption}</h1>
						</header>
						<main className="dialogue__content__main">
							{children}
						</main>
						{renderFooter()}
					</dismissableContext.Provider>
				</article>
			</CSSTransition>
		</aside>
	);

}
