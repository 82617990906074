import { Optional, Probably } from '@abb-emobility/shared/util';

import { DownloadFileDto, DownloadFileMetaDataDto, DownloadFile, DownloadFileMetaData } from './DownloadFile';
import { isDownloadFileDto, isDownloadFileMetaDataDto, isDownloadFile, isDownloadFileMetaData } from './DownloadFile.guards';
import { isTimestamp, isTimestampDto } from './Timestamp.guards';
import { createTimestampDtoFromTimestamp, createTimestampFromTimestampDto } from './Timestamp.util';
import { createAccessibleUrlFromUrlString, createUrlDtoFromUrl, createUrlFromUrlDto } from './Url.util';

export const createDownloadFileFromDownloadFileDto = (fileDto: Probably<DownloadFileDto>): Optional<DownloadFile> => {
	let file = null;
	if (isDownloadFileDto(fileDto)) {
		file = {
			discriminator: 'DownloadFile',
			url: createUrlFromUrlDto(fileDto.url).get(),
			meta: createDownloadFileMetaDataFromDownloadFileMetaDataDto(fileDto.meta).get()
		} as DownloadFile;
	}
	return new Optional<DownloadFile>(file);
};

export const createDownloadFileDtoFromDownloadFile = (file: Probably<DownloadFile>): Optional<DownloadFileDto> => {
	let fileDto = null;
	if (isDownloadFile(file)) {
		fileDto = {
			url: createUrlDtoFromUrl(file.url).get(),
			meta: createDownloadFileMetaDataDtoFromDownloadFileMetaData(file.meta).get()
		} as DownloadFileDto;
	}
	return new Optional<DownloadFileDto>(fileDto);
};

const createDownloadFileMetaDataFromDownloadFileMetaDataDto = (fileMetaDto: Probably<DownloadFileMetaDataDto>): Optional<DownloadFileMetaData> => {
	let fileMetaData = null;
	if (isDownloadFileMetaDataDto(fileMetaDto)) {
		fileMetaData = {
			...fileMetaDto
		} as DownloadFileMetaData;
		if (isTimestampDto(fileMetaDto.lastModifiedDate)) {
			fileMetaData.lastModifiedDate = createTimestampFromTimestampDto(fileMetaDto.lastModifiedDate).getOrUndefined();
		}
	}
	return new Optional<DownloadFileMetaData>(fileMetaData);
};

const createDownloadFileMetaDataDtoFromDownloadFileMetaData = (fileMeta: Probably<DownloadFileMetaData>): Optional<DownloadFileMetaDataDto> => {
	let fileMetaDataDto = null;
	if (isDownloadFileMetaData(fileMeta)) {
		fileMetaDataDto = {
			...fileMeta
		} as DownloadFileMetaDataDto;
		if (isTimestamp(fileMeta.lastModifiedDate)) {
			fileMetaDataDto.lastModifiedDate = createTimestampDtoFromTimestamp(fileMeta.lastModifiedDate).getOrUndefined();
		}
	}
	return new Optional<DownloadFileMetaDataDto>(fileMetaDataDto);
};

export const createDownloadFileFromUrlString = (urlString: string, title?: string): DownloadFile => {
	return {
		discriminator: 'DownloadFile',
		url: createAccessibleUrlFromUrlString(urlString),
		meta: {
			title,
			fileName: title ?? '',
			mimeType: '*/*'
		}
	} as DownloadFile;
};
