import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'Wallbox de ABB',
				labelCancel: 'Cancelar',
				titlePaymentData: 'Pago',
				labelAmount: 'Importe por esfuerzo y material de instalación adicionales',
				labelDate: 'Fecha',
				labelMerchant: 'Comerciante',
				labelIban: 'Su IBAN'
			}
		},
		application: {
			pageTitle: 'Servicio de instalación del wallbox de ABB',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Pedido {{marketplaceId}}',
			task: 'Pedido {{marketplaceId}} - {{taskType}}',
			documents: 'Documentos',
			payment: 'Pago',
			notFound: 'No encontrado',
			static: {
				terms: 'Plazos y condiciones generales',
				privacy: 'Privacidad',
				imprint: 'Imprimir'
			}
		},
		footer: {
			termsLabel: 'Plazos y condiciones generales',
			privacyLabel: 'Privacidad',
			imprintLabel: 'Imprimir'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Introducir código',
			otpInputMessage: 'Hemos enviado un código de 6 dígitos a su teléfono móvil.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Enviar de nuevo',
			otpInputLabel: 'Introducir su código',
			otpInputPlaceholder: 'Código de autenticación',
			otpSubmitButtonLabel: 'Continuar'
		},
		error: {
			otpAuthInitFailed: 'Error de autenticación',
			otpAuthReceiveTokenFailed: 'Código no válido',
			taskTypeNotFound: 'El tipo de tarea solicitado {{taskType}} es desconocido.',
			taskNotFound: 'No se ha encontrado la tarea solicitada',
			orderNotFound: 'No se ha encontrado el pedido solicitado',
			documentNotFound: 'No se ha encontrado el documento solicitado',
			validation: {
				heading: 'Datos no válidos',
				message: 'Los datos enviados no son válidos.'
			},
			generic: {
				heading: 'Se ha producido un error',
				message: 'Se ha producido un error que no se ha podido recuperar.',
				resolveAction: {
					label: 'Volver a cargar'
				}
			},
			notFound: {
				heading: 'No encontrado',
				message: 'No se ha encontrado el recurso solicitado'
			},
			taskCompleteMessage: 'Se produjo un error al completar la tarea.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'Necesito ayuda',
			dialogueDismissLabel: 'Cancelar',
			dialogueCaption: 'Servicio al cliente',
			phone: {
				heading: 'Puede ponerse en contacto con nosotros por teléfono en cualquier momento'
			},
			mail: {
				heading: 'O enviarnos un correo electrónico',
				subject: 'Mi pedido de wallbox #{{marketplaceId}}',
				subjectStatic: 'Mi pedido de wallbox'
			},
			infoPage: {
				heading: 'O puede visitar nuestra oferta de información',
				label: 'Respuestas a las preguntas más frecuentes'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Desconocido',
			allDoneStepLabel: 'Hecho',
			upcomingLabelPrefix: 'Siguiente: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'de'
		},
		payment: {
			success: {
				heading: 'Su pago se ha realizado correctamente.',
				message: 'Ya no hay nada que impida la instalación de su Wallbox. Nuestro socio instalador se pondrá en contacto con usted en breve para organizar una cita de instalación.\n\nGracias por su confianza.'
			},
			failed: {
				heading: 'Su pago ha fallado.',
				retryLabel: 'Inténtelo de nuevo'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Comprobación de la instalación',
				customerProvidePreCheckPreferences: 'Proporcionar método de contacto preferido',
				customerHandleUnfeasibleInstallation: 'Instalación inviable',
				customerHandleGridOperatorRejection: 'Rechazado por el operador de red',
				customerAcceptQuotation: 'Oferta de trabajos adicionales',
				customerPerformPayment: 'Pago',
				customerPayment: 'Su pago',
				customerApproveInstallation: 'Confirmación de la instalación',
				installationPartnerEvaluateBasicAnswers: 'Comprobación de la instalación',
				installationPartnerExecuteRemotePreCheck: 'Comprobación final',
				installationPartnerRegisterAtGridOperator: 'Operador de red de registro',
				installationPartnerRevokeGridOperatorRegistration: 'Revocación del registro de operador de red',
				installationPartnerProvideGridOperatorDecision: 'Decisión de operador de red',
				installationPartnerProvideQuotation: 'Presupuesto',
				installationPartnerArrangeAppointment: 'Concertar cita',
				installationPartnerCompleteInstallation: 'Ejecución de la instalación'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Comprobación de la instalación',
				customerProvidePreCheckPreferences: 'Método de contacto preferido',
				customerHandleUnfeasibleInstallation: 'Instalación inviable',
				customerHandleGridOperatorRejection: 'Rechazado por el operador de red',
				customerAcceptQuotation: 'Oferta de trabajos adicionales',
				customerPerformPayment: 'Pago',
				customerPayment: 'Pago',
				customerApproveInstallation: 'Confirmación de la instalación',
				installationPartnerEvaluateBasicAnswers: 'Comprobación de la instalación',
				installationPartnerExecuteRemotePreCheck: 'Comprobación final',
				installationPartnerRegisterAtGridOperator: 'Operador de red de registro',
				installationPartnerRevokeGridOperatorRegistration: 'Revocación del registro de operador de red',
				installationPartnerProvideGridOperatorDecision: 'Decisión de operador de red',
				installationPartnerProvideQuotation: 'Presupuesto',
				installationPartnerArrangeAppointment: 'Concertar cita',
				installationPartnerCompleteInstallation: 'Ejecución de la instalación'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'El objetivo de otorgar poderes es permitirnos registrar la conexión a la red con su consentimiento.',
						powerOfAttorney: {
							heading: 'Otorgamiento de poderes para el registro de consumidores con el operador de red',
							description: 'Indíquenos su operador de red para registrar correctamente su wallbox.',
							gridOperator: {
								question: 'Operador de red o proveedor de energía'
							},
							powerOfAttorney: {
								question: 'Por la presente, autorizo a ABB y a su socio instalador a registrar el wallbox con el operador de red. Si no se lleva a cabo la instalación, los poderes caducan automáticamente.'
							}
						},
						marketingConsent: {
							heading: 'Consentimiento para la comunicación con el socio instalador',
							installationPartnerMarketingConsent: {
								question: 'Por la presente, autorizo a ABB y a su socio instalador a ponerse en contacto conmigo con fines de marketing.'
							}
						},
						surveyConsent: {
							heading: 'Compartir su opinión con nosotros',
							description: 'Ayúdenos a mejorar nuestros productos y servicios participando en una breve encuesta tras completar su pedido.',
							surveyConsent: {
								question: 'Por la presente, autorizo a ABB a invitarme a participar en una encuesta.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Gracias por su tiempo.',
					message: 'En breve recibirá una confirmación por correo electrónico. Estamos comprobando sus detalles y nos pondremos en contacto con usted para acordar los siguientes pasos.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Volver',
				nextStepButtonLabel: 'Próximo paso',
				submitButtonLabel: 'Enviar información',
				channelSelection: {
					heading: 'Conversación personal con nuestro Equipo de servicio',
					message: 'Para que la instalación de su nuevo Wallbox se realice sin problemas, háganos saber sus preferencias para una conversación personal con nuestro Equipo de servicio.\nA continuación, se acordarán los siguientes pasos en función de la información que nos haya facilitado.',
					subheading: '¿Cómo podemos ponernos en contacto con usted?',
					explanation: 'Hay varias formas de ponernos en contacto con usted.'
				},
				preferredTimesOfDay: {
					subHeading: 'Encontremos un momento para un videochat. Tendrá lugar a través de {{channelName}}.',
					timeOfDay: {
						morning: 'Por la mañana',
						noon: 'Mediodía',
						afternoon: 'Por la tarde',
						evening: 'Por la noche'
					}
				},
				completionSuccess: {
					heading: 'Nos alegra que tenga tiempo para nosotros.',
					message: 'Nos pondremos en contacto con usted teniendo en cuenta la disponibilidad que haya seleccionado.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Lo sentimos mucho, pero',
				message: 'actualmente no es posible la instalación por parte de nuestro socio instalador. El motivo es:\n\n{{feasibleReasong}}\n\nPor lo tanto, tenemos que pedirle que cancele su pedido.',
				submitButtonLabel: 'Enviar información',
				unfeasibleReason: {
					noPotentialRailAvailable: 'No se dispone de un carril de potencial, el cual necesario para instalar la protección contra sobretensiones.',
					overvoltageProtectionUnfeasible: 'No es posible la instalación necesaria de un protector contra sobretensiones.',
					spaceForCableOutletMissing: 'No hay espacio suficiente para una toma de cable adicional necesaria.',
					houseConnectionUndersized: 'La conexión doméstica es tan pequeña que no es posible instalar el wallbox.',
					noThreePhaseAvailable: 'El sistema eléctrico existente no proporciona la alimentación eléctrica trifásica necesaria.',
					outdatedMeterCabinet: 'El armario de contadores no cumple los requisitos para la puesta en servicio del wallbox.',
					notTabCompliant: 'La instalación eléctrica no cumple las condiciones técnicas de conexión aplicables.',
					other: 'En el transcurso de una instalación se interponen motivos técnicos que no pueden evitarse.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Su instalación se ha cancelado satisfactoriamente.',
					message: 'Hemos recibido su cancelación. En breve recibirá una confirmación por correo electrónico.'
				},
				completionCancelOrderSuccess: {
					heading: 'Su pedido se ha cancelado con éxito.',
					message: 'Hemos recibido su cancelación. En breve recibirá una confirmación por correo electrónico.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Lo sentimos mucho, pero su operador de red ha rechazado la puesta en servicio de su Wallbox',
				message: 'Lamentablemente, el suministro eléctrico en el lugar de instalación no es suficiente para el funcionamiento de su Wallbox. Tienes la opción de reducir la potencia de tu Wallbox o de cancelar la instalación.',
				submitButtonLabel: 'Enviar información',
				solutionDecreaseWallboxPower: {
					heading: 'Reducir la potencia del Wallbox',
					description: 'La potencia de su Wallbox se reducirá a una potencia que se corresponda con su fuente de alimentación. La reducción del consumo de potencia repercutirá en la eficiencia de la carga.'
				},
				solutionCancelInstallation: {
					heading: 'Cancelar instalación',
					description: 'Se cancela la instalación de nuestro socio instalador. El Wallbox se le enviará directamente. En los próximos días se le reembolsarán los gastos de instalación.'
				},
				solutionCancelOrder: {
					heading: 'Cancelar instalación y Wallbox',
					description: 'Se cancelará todo su pedido y se le reembolsarán todos los gastos en los próximos días.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Reducción de potencia del Wallbox',
					message: 'Por la presente, ABB confirma la instalación de su Wallbox basada en la reducción de potencia conforme a los requisitos del operador de red.\n\nEn breve le notificaremos los próximos pasos.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Su instalación se ha cancelado satisfactoriamente.',
					message: 'Hemos recibido su cancelación. En breve recibirá una confirmación por correo electrónico.'
				},
				completionCancelOrderSuccess: {
					heading: 'Su pedido se ha cancelado con éxito.',
					message: 'Hemos recibido su cancelación. En breve recibirá una confirmación por correo electrónico.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Enviar información',
				message: 'Confirme que la instalación de su Wallbox se ha realizado correctamente.',
				approval: {
					caption: 'Confirmación',
					label: 'Por la presente, confirmo la correcta instalación del Wallbox, así como su puesta en servicio y funcionalidad.'
				},
				completionSuccess: {
					heading: 'Gracias.',
					message: 'La instalación del Wallbox ha finalizado. En breve recibirá toda la documentación importante.\n\nGracias por su confianza.'
				}
			},
			quotationAcceptance: {
				caption: 'Su oferta individual',
				message: 'Los siguientes paquetes de instalación son servicios adicionales que se facturan por separado. Cuando acepte la oferta, se le remitirá a nuestro proveedor de servicios de pago, Deutsche Bank, para que pueda tramitar el pedido en nuestro nombre.\n\nSi acepta esta oferta, no se le cobrará nada más y podrá proceder a la instalación.\n\nNuestro socio instalador se pondrá en contacto con usted para organizar una cita de instalación.',
				submitButtonLabel: 'Aceptar oferta sujeta a pago',
				cancelButtonLabel: 'Cancelar',
				cancellationCaption: 'Lástima que no podamos hacerle una buena oferta',
				cancellationMessage: 'Indique cómo desea cancelar su pedido.',
				submitCancellationButtonLabel: 'Enviar información',
				recoverCancellationButtonLabel: 'Volver',
				conditions: {
					caption: 'Condiciones de la oferta',
					message: 'Esta oferta es válida mientras se cumplan las siguientes condiciones basadas en su información.'
				},
				acceptance: {
					terms: 'los Plazos y condiciones generales',
					label: 'Por la presente, acepto {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'Su pedido se ha realizado correctamente.',
					message: 'Ha aceptado nuestra oferta y será remitido a nuestro proveedor de servicios de pago.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Su instalación se ha cancelado satisfactoriamente.',
					message: 'Hemos recibido su cancelación. En breve recibirá una confirmación por correo electrónico.'
				},
				completionCancelOrderSuccess: {
					heading: 'Su pedido se ha cancelado con éxito.',
					message: 'Hemos recibido su cancelación. En breve recibirá una confirmación por correo electrónico.'
				}
			},
			payment: {
				heading: 'Su pago',
				message: 'Se le remitirá a nuestro proveedor de servicios de pago.'
			}
		},
		documents: {
			heading: 'La documentación de su pedido',
			introduction: 'Resumen de documentos'
		},
		order: {
			heading: 'Su pedido',
			cancelledHint: 'Este pedido se ha cancelado correctamente.',
			doneHint: 'Este pedido se ha completado con éxito.',
			introduction: 'Aquí encontrará toda la información actual relacionada con su pedido de Wallbox en {{marketplaceName}} desde {{orderDate}} con el número de pedido {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Terminar ahora'
			},
			information: {
				heading: {
					orderItems: 'Pedido',
					quotation: 'Oferta de servicios adicionales',
					appointment: 'Cita para la instalación',
					shipping: 'Estado del envío'
				},
				orderItems: {
					position: 'Posición',
					description: 'Descripción',
					wallboxSerialNumbner: 'Serie de Wallbox.',
					totalGrossPrice: 'Precio bruto total',
					cancellationHint: 'Cancelado el {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'La instalación se canceló el {{cancellationDate}}'
				},
				appointment: {
					heading: 'Instalación'
				},
				shipping: {
					heading: 'Enviado por {{carrier}}',
					trackingNumberLabel: 'Número de referencia',
					shippingAddressLabel: 'Dirección de envío',
					trackingLinkLabel: 'Seguimiento del envío',
					itemsLabel: 'Artículos de envío',
					productCodeLabel: 'Código de producto'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Cancelar instalación',
				description: 'Se cancela la instalación de nuestro socio instalador. El Wallbox se le enviará directamente. En los próximos días se le reembolsarán los gastos de instalación.'
			},
			cancelOrder: {
				heading: 'Cancelar instalación y Wallbox',
				description: 'Se cancelará todo su pedido y se le reembolsarán todos los gastos en los próximos días.'
			}
		}
	}
};

export default literals;
