import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'ABB wallbox',
				labelCancel: 'Annuleren',
				titlePaymentData: 'Betaling',
				labelAmount: 'Bedrag aan extra installatie-inspanning en materiaal',
				labelDate: 'Datum',
				labelMerchant: 'Leverancier',
				labelIban: 'Uw IBAN'
			}
		},
		application: {
			pageTitle: 'ABB wallbox installatieservice',
			caption: 'ABB e-Mobility BV'
		},
		pageTitle: {
			order: 'Bestelling {{marketplaceId}}',
			task: 'Bestelling {{marketplaceId}} - {{taskType}}',
			documents: 'Documenten',
			payment: 'Betaling',
			notFound: 'Niet gevonden',
			static: {
				terms: 'Algemene voorwaarden',
				privacy: 'Privacy',
				imprint: 'Colofon'
			}
		},
		footer: {
			termsLabel: 'Algemene voorwaarden',
			privacyLabel: 'Privacy',
			imprintLabel: 'Colofon'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Code invoeren',
			otpInputMessage: 'We hebben een code van 6 cijfers naar uw mobiele telefoon gestuurd.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Opnieuw verzenden',
			otpInputLabel: 'Vul uw code in',
			otpInputPlaceholder: 'Verificatiecode',
			otpSubmitButtonLabel: 'Verdergaan'
		},
		error: {
			otpAuthInitFailed: 'Verificatie mislukt',
			otpAuthReceiveTokenFailed: 'Ongeldige code',
			taskTypeNotFound: 'Het aangevraagde taaktype {{taskType}} is onbekend.',
			taskNotFound: 'De gevraagde taak werd niet gevonden',
			orderNotFound: 'De gevraagde bestelling werd niet gevonden',
			documentNotFound: 'Het opgevraagde document werd niet gevonden',
			validation: {
				heading: 'Ongeldige gegevens',
				message: 'De verzonden gegevens zijn ongeldig.'
			},
			generic: {
				heading: 'Er is een fout opgetreden',
				message: 'Er is een fout opgetreden die niet hersteld kon worden.',
				resolveAction: {
					label: 'Opnieuw laden'
				}
			},
			notFound: {
				heading: 'Niet gevonden',
				message: 'De bron die u heeft opgevraagd is niet gevonden'
			},
			taskCompleteMessage: 'Tijdens het uitvoeren van de taak is een fout opgetreden.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'Ik heb hulp nodig',
			dialogueDismissLabel: 'Annuleren',
			dialogueCaption: 'Klantenservice',
			phone: {
				heading: 'U kunt op elk gewenst moment telefonisch contact met ons opnemen'
			},
			mail: {
				heading: 'Of stuur ons een e-mail',
				subject: 'Mijn wallbox-bestelling #{marketplaceId}}',
				subjectStatic: 'Mijn wallbox-bestelling'
			},
			infoPage: {
				heading: 'Of u kunt ons informatieaanbod bezoeken',
				label: 'Antwoorden op de meest gestelde vragen'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Onbekend',
			allDoneStepLabel: 'Gereed',
			upcomingLabelPrefix: 'Volgende: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'van'
		},
		payment: {
			success: {
				heading: 'Uw betaling is gelukt.',
				message: 'Niets staat de installatie van uw wallbox nu nog in de weg. Onze installatiepartner neemt snel contact met u op om een installatieafspraak te maken.\n\nBedankt voor uw vertrouwen!'
			},
			failed: {
				heading: 'Uw betaling is mislukt!',
				retryLabel: 'Probeer opnieuw'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Installatiecontrole',
				customerProvidePreCheckPreferences: 'Gewenste contactmethode opgeven',
				customerHandleUnfeasibleInstallation: 'Installatie onhaalbaar',
				customerHandleGridOperatorRejection: 'Afgewezen door netbeheerder',
				customerAcceptQuotation: 'Offerte voor aanvullende werkzaamheden',
				customerPerformPayment: 'Betaling',
				customerPayment: 'Uw betaling',
				customerApproveInstallation: 'Bevestiging van installatie',
				installationPartnerEvaluateBasicAnswers: 'Installatiecontrole',
				installationPartnerExecuteRemotePreCheck: 'Laatste controle',
				installationPartnerRegisterAtGridOperator: 'Registratie netbeheerder',
				installationPartnerRevokeGridOperatorRegistration: 'Intrekking registratie netbeheerder',
				installationPartnerProvideGridOperatorDecision: 'Besluit netbeheerder',
				installationPartnerProvideQuotation: 'Offerte',
				installationPartnerArrangeAppointment: 'Afspraak maken',
				installationPartnerCompleteInstallation: 'Installatie-uitvoering'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Installatiecontrole',
				customerProvidePreCheckPreferences: 'Gewenste contactmethode',
				customerHandleUnfeasibleInstallation: 'Installatie onhaalbaar',
				customerHandleGridOperatorRejection: 'Afgewezen door netbeheerder',
				customerAcceptQuotation: 'Offerte voor aanvullende werkzaamheden',
				customerPerformPayment: 'Betaling',
				customerPayment: 'Betaling',
				customerApproveInstallation: 'Bevestiging van installatie',
				installationPartnerEvaluateBasicAnswers: 'Installatiecontrole',
				installationPartnerExecuteRemotePreCheck: 'Laatste controle',
				installationPartnerRegisterAtGridOperator: 'Registratie netbeheerder',
				installationPartnerRevokeGridOperatorRegistration: 'Intrekking registratie netbeheerder',
				installationPartnerProvideGridOperatorDecision: 'Besluit netbeheerder',
				installationPartnerProvideQuotation: 'Offerte',
				installationPartnerArrangeAppointment: 'Afspraak maken',
				installationPartnerCompleteInstallation: 'Installatie-uitvoering'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'Het verlenen van een volmacht heeft tot doel ons in staat te stellen om ons met uw toestemming te registreren voor de aansluiting op het elektriciteitsnet.',
						powerOfAttorney: {
							heading: 'Verlening van volmacht voor de registratie van consumenten bij de netbeheerder',
							description: 'Laat ons weten wie uw netbeheerder is voor een succesvolle registratie van uw wallbox.',
							gridOperator: {
								question: 'Netbeheerder of energieleverancier'
							},
							powerOfAttorney: {
								question: 'Hierbij geef ik ABB en zijn installatiepartner toestemming om de wallbox aan te melden bij de netbeheerder. Als de installatie niet wordt uitgevoerd, vervalt de volmacht automatisch.'
							}
						},
						marketingConsent: {
							heading: 'Toestemming voor communicatie met de installatiepartner',
							installationPartnerMarketingConsent: {
								question: 'Hierbij geef ik ABB en zijn installatiepartner toestemming om contact met mij op te nemen voor marketingdoeleinden.'
							}
						},
						surveyConsent: {
							heading: 'Deel uw mening met ons',
							description: 'Help ons onze producten en diensten te verbeteren door deel te nemen aan een korte enquête na het afronden van uw bestelling.',
							surveyConsent: {
								question: 'Hierbij geef ik ABB toestemming om mij uit te nodigen om deel te nemen aan een enquête.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Bedankt voor uw tijd!',
					message: 'U ontvangt binnenkort een bevestiging per e-mail. We controleren uw gegevens en nemen daarna contact op om de volgende stappen met u af te stemmen.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Terug',
				nextStepButtonLabel: 'Volgende stap',
				submitButtonLabel: 'Stuur informatie',
				channelSelection: {
					heading: 'Persoonlijk gesprek met ons serviceteam',
					message: 'Om de installatie van uw nieuwe wallbox soepel te laten verlopen, willen we graag van u weten wat uw voorkeur heeft voor een persoonlijk gesprek met ons serviceteam.\nDe volgende stappen worden dan afgesproken op basis van de informatie die u ons heeft verstrekt.',
					subheading: 'Hoe kunnen we contact met u opnemen?',
					explanation: 'We kunnen op verschillende manieren contact met u opnemen.'
				},
				preferredTimesOfDay: {
					subHeading: 'Laten we een tijd afspreken voor een videochat. Het zal plaatsvinden via {{channelName}}.',
					timeOfDay: {
						morning: 'Ochtend',
						noon: 'Lunchtijd',
						afternoon: 'Middag',
						evening: 'Avond'
					}
				},
				completionSuccess: {
					heading: 'Geweldig dat u tijd voor ons heeft!',
					message: 'We nemen contact met u op zodra de door u geselecteerde beschikbaarheid bekend is.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Het spijt ons zeer, maar',
				message: 'installatie door onze installatiepartner is momenteel niet mogelijk. De reden is:\n\n{{unfeasibleReasong}}\n\nWe moeten u daarom vragen uw bestelling te annuleren.',
				submitButtonLabel: 'Stuur informatie',
				unfeasibleReason: {
					noPotentialRailAvailable: 'Een potentiaalrail, die nodig is om de overspanningsbeveiliging te installeren, is niet beschikbaar.',
					overvoltageProtectionUnfeasible: 'De noodzakelijke installatie van een overspanningsbeveiliging is niet mogelijk.',
					spaceForCableOutletMissing: 'Er is niet genoeg ruimte voor een noodzakelijke extra kabeluitgang.',
					houseConnectionUndersized: 'De huisaansluiting heeft onvoldoende capaciteit en daardoor is het niet mogelijk om de wallbox te installeren.',
					noThreePhaseAvailable: 'De bestaande elektrische installatie voorziet niet in de benodigde driefasige voeding.',
					outdatedMeterCabinet: 'De meterkast voldoet niet aan de vereisten voor inbedrijfstelling van de wallbox.',
					notTabCompliant: 'De elektrische installatie voldoet niet aan de geldende technische aansluitvoorwaarden.',
					other: 'Installatie is niet mogelijk als gevolg van technische obstakels die tijdens de installatie niet kunnen worden weggenomen.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Uw installatie is met succes geannuleerd.',
					message: 'We hebben uw annulering ontvangen. U ontvangt binnenkort een bevestiging per e-mail.'
				},
				completionCancelOrderSuccess: {
					heading: 'Uw bestelling is met succes geannuleerd.',
					message: 'We hebben uw annulering ontvangen. U ontvangt binnenkort een bevestiging per e-mail.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Het spijt ons, maar uw netbeheerder heeft de inbedrijfstelling van uw wallbox geweigerd',
				message: 'Helaas is de stroomvoorziening op de installatielocatie niet voldoende voor de werking van uw Wallbox. U heeft de optie om het vermogen van uw wallbox te verlagen of installatie te annuleren.',
				submitButtonLabel: 'Stuur informatie',
				solutionDecreaseWallboxPower: {
					heading: 'Verminder het vermogen van de wallbox',
					description: 'Het vermogen van uw wallbox wordt gereduceerd tot het vermogen dat past bij uw stroomvoorziening. De vermogensreductie zal gevolgen hebben voor de efficiëntie van het opladen.'
				},
				solutionCancelInstallation: {
					heading: 'Installatie annuleren',
					description: 'De installatie door onze installatiepartner wordt geannuleerd. De wallbox wordt rechtstreeks naar u opgestuurd. U krijgt de installatiekosten in de komende dagen vergoed.'
				},
				solutionCancelOrder: {
					heading: 'Installatie en wallbox annuleren',
					description: 'Uw bestelling wordt in zijn geheel geannuleerd en alle kosten worden in de komende dagen aan u terugbetaald.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Wallbox vermogensreductie',
					message: 'ABB bevestigt hierbij de installatie van uw wallbox op basis van vermogensreductie die voldoet aan de vereisten van de netbeheerder.\n\nWe zullen u binnenkort op de hoogte brengen van de volgende stappen.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Uw installatie is met succes geannuleerd.',
					message: 'We hebben uw annulering ontvangen. U ontvangt binnenkort een bevestiging per e-mail.'
				},
				completionCancelOrderSuccess: {
					heading: 'Uw bestelling is met succes geannuleerd.',
					message: 'We hebben uw annulering ontvangen. U ontvangt binnenkort een bevestiging per e-mail.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Stuur informatie',
				message: 'Bevestig dat de installatie van uw wallbox is voltooid.',
				approval: {
					caption: 'Bevestiging',
					label: 'Hierbij bevestig ik de succesvolle installatie van de wallbox en de ingebruikname en werking ervan.'
				},
				completionSuccess: {
					heading: 'Bedankt!',
					message: 'De installatie van uw wallbox is voltooid. U ontvangt binnenkort alle belangrijke documentatie.\n\nBedankt voor uw vertrouwen!'
				}
			},
			quotationAcceptance: {
				caption: 'Uw persoonlijke aanbieding',
				message: 'De volgende installatiepakketten omvatten aanvullende diensten die afzonderlijk worden gefactureerd. Wanneer u het aanbod accepteert, word u doorgeleid naar onze betalingsdienstaanbieder, Deutsche Bank, zodat de bestelling namens ons kan worden verwerkt.\n\nAls u dit aanbod accepteert, worden er geen verdere kosten in rekening gebracht en kan de installatie worden uitgevoerd.\n\nOnze installatiepartner neemt contact met u op voor het maken van een installatieafspraak.',
				submitButtonLabel: 'Acceptatie offerte onder voorbehoud van betaling',
				cancelButtonLabel: 'Annuleren',
				cancellationCaption: 'Jammer dat we u geen goed aanbod kunnen doen',
				cancellationMessage: 'Geef aan hoe u uw bestelling wilt annuleren.',
				submitCancellationButtonLabel: 'Stuur informatie',
				recoverCancellationButtonLabel: 'Terug',
				conditions: {
					caption: 'Offertevoorwaarden',
					message: 'Deze aanbieding is geldig zolang de volgende voorwaarden gelden die zijn gebaseerd op uw informatie.'
				},
				acceptance: {
					terms: 'de algemene voorwaarden',
					label: 'Hierbij accepteer ik {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'Uw bestelling is geslaagd.',
					message: 'U heeft ons aanbod aanvaard en wordt doorgeleid naar onze betalingsdienstaanbieder.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Uw installatie is met succes geannuleerd.',
					message: 'We hebben uw annulering ontvangen. U ontvangt binnenkort een bevestiging per e-mail.'
				},
				completionCancelOrderSuccess: {
					heading: 'Uw bestelling is met succes geannuleerd.',
					message: 'We hebben uw annulering ontvangen. U ontvangt binnenkort een bevestiging per e-mail.'
				}
			},
			payment: {
				heading: 'Uw betaling',
				message: 'U wordt doorgeleid naar onze betalingsdienstaanbieder.'
			}
		},
		documents: {
			heading: 'De documentatie van je bestelling',
			introduction: 'Overzicht documenten'
		},
		order: {
			heading: 'Uw bestelling',
			cancelledHint: 'Deze bestelling werd succesvol geannuleerd.',
			doneHint: 'Deze bestelling werd succesvol afgerond.',
			introduction: 'Hieronder vindt u alle huidige informatie met betrekking tot uw wallbox bestelling bij {{marketplaceName}} vanaf {{orderDate}} met ordernummer {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Nu afmaken'
			},
			information: {
				heading: {
					orderItems: 'Bestellen',
					quotation: 'Aanbod voor aanvullende diensten',
					appointment: 'Installatieafspraak',
					shipping: 'Verzendstatus'
				},
				orderItems: {
					position: 'Positie',
					description: 'Beschrijving',
					wallboxSerialNumbner: 'Wallbox-serie.',
					totalGrossPrice: 'Totale brutoprijs',
					cancellationHint: 'Geannuleerd op {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'De installatie werd geannuleerd op {{cancellationDate}}'
				},
				appointment: {
					heading: 'Installatie'
				},
				shipping: {
					heading: 'Verzonden door {{vervoerder}}',
					trackingNumberLabel: 'Referentienummer',
					shippingAddressLabel: 'Afleveradres',
					trackingLinkLabel: 'Verzending volgen',
					itemsLabel: 'Te verzenden artikelen',
					productCodeLabel: 'Productcode'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Installatie annuleren',
				description: 'De installatie door onze installatiepartner wordt geannuleerd. De wallbox wordt rechtstreeks naar u opgestuurd. U krijgt de installatiekosten in de komende dagen vergoed.'
			},
			cancelOrder: {
				heading: 'Installatie en wallbox annuleren',
				description: 'Uw bestelling wordt in zijn geheel geannuleerd en alle kosten worden in de komende dagen aan u terugbetaald.'
			}
		}
	}
};

export default literals;
