import { CustomerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	installationPartnerDeltaInstallationPackageModelMock01,
	installationPartnerDeltaInstallationPackageModelMock02,
	installationPartnerDeltaInstallationPackageModelMock03,
	installationPartnerDeltaInstallationPackageModelMock04,
	installationPartnerDeltaInstallationPackageModelMock05
} from './InstallationPartnerDeltaInstallationPackageModel.mock';

export const customerDeltaInstallationPackageModelMock01: Dto<CustomerDeltaInstallationPackageModel> = {
	...installationPartnerDeltaInstallationPackageModelMock01,
	salesGrossPrice: parseInt((installationPartnerDeltaInstallationPackageModelMock01.purchaseNetPrice * 1.1 * 1.19).toFixed(0))
};

export const customerDeltaInstallationPackageModelMock02: Dto<CustomerDeltaInstallationPackageModel> = {
	...installationPartnerDeltaInstallationPackageModelMock02,
	salesGrossPrice: parseInt((installationPartnerDeltaInstallationPackageModelMock02.purchaseNetPrice * 1.1 * 1.19).toFixed(0))
};

export const customerDeltaInstallationPackageModelMock03: Dto<CustomerDeltaInstallationPackageModel> = {
	...installationPartnerDeltaInstallationPackageModelMock03,
	salesGrossPrice: parseInt((installationPartnerDeltaInstallationPackageModelMock03.purchaseNetPrice * 1.1 * 1.19).toFixed(0))
};

export const customerDeltaInstallationPackageModelMock04: Dto<CustomerDeltaInstallationPackageModel> = {
	...installationPartnerDeltaInstallationPackageModelMock04,
	salesGrossPrice: parseInt((installationPartnerDeltaInstallationPackageModelMock04.purchaseNetPrice * 1.1 * 1.19).toFixed(0))
};

export const customerDeltaInstallationPackageModelMock05: Dto<CustomerDeltaInstallationPackageModel> = {
	...installationPartnerDeltaInstallationPackageModelMock05,
	salesGrossPrice: parseInt((installationPartnerDeltaInstallationPackageModelMock05.purchaseNetPrice * 1.1 * 1.19).toFixed(0))
};
