import { GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import { TaskPayloadFoundationModel } from '../foundation/TaskPayloadFoundationModel';

export type PreCheckRevokeGridOperatorRegistrationPayloadModel = {
	readonly gridOperator: GridOperatorModel
} & TaskPayloadFoundationModel;

export class PreCheckRevokeGridOperatorRegistrationPayloadModelConverter extends ModelConverter<PreCheckRevokeGridOperatorRegistrationPayloadModel> {

	override getFieldConverterMapByModel(): Map<keyof PreCheckRevokeGridOperatorRegistrationPayloadModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}

}
