import { CustomerOrderApiClientFactory, CustomerOrderApiClientInterface } from '@abb-emobility/oms/api-integration';
import { CustomerOrderModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerOrderEntityStoreName = 'customer-order-entity-crud';
export type CustomerOrderEntityStore = { [customerOrderEntityStoreName]: CrudEntityStore<CustomerOrderModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerOrderApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<CustomerOrderModel, CustomerOrderEntityStore, CustomerOrderApiClientInterface>(customerOrderEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerOrderModel, CustomerOrderApiClientInterface>(customerOrderEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerOrderModel, CustomerOrderApiClientInterface>(customerOrderEntityStoreName, createApiClient);

// Slice definition
export const customerOrderEntitySlice = createCrudEntitySlice<CustomerOrderModel, ThunkApiConfig>(customerOrderEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerOrderEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<CustomerOrderModel, CustomerOrderEntityStore>(customerOrderEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<CustomerOrderModel, CustomerOrderEntityStore>(customerOrderEntityStoreName);
const storeSize = createCrudEntityStoreSize<CustomerOrderModel, CustomerOrderEntityStore>(customerOrderEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<CustomerOrderModel, CustomerOrderEntityStore>(customerOrderEntityStoreName);

// Export the store accessors to be used by the data provider value
export const customerOrderEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<CustomerOrderModel, CustomerOrderEntityStore>;
