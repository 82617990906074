import './SpinnerCable.scss';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

export function SpinnerCable() {
	return (
		<div className="spinner-cable">
			<div className="spinner-cable__animation">
				<Icon name={IconIdentifier.SPINNER} />
			</div>
		</div>
	);
}
