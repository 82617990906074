import React, { FunctionComponent, ReactElement } from 'react';

import { Nullable, Optional } from '@abb-emobility/shared/util';

import { FetchStatus, fetchStatusPendingGroup } from '../../store/FetchStatus';
import { DataProviderPendingProps } from '../DataProviderProps';

type DataProviderValue = {
	queryFetchStatus: () => FetchStatus
};

type SearchDataProviderValue = {
	querySearchStatus: () => FetchStatus
};

export const useDataProviderFetchSuspense = (
	dataProviderValue: DataProviderValue,
	suspense: boolean,
	pendingComponent: Nullable<FunctionComponent<DataProviderPendingProps>>
): Optional<ReactElement> => {
	const PendingComponent = pendingComponent;
	const fetchStatus = dataProviderValue.queryFetchStatus();
	if (suspense && (fetchStatus === FetchStatus.IDLE || fetchStatusPendingGroup.includes(fetchStatus))) {
		if (PendingComponent !== null) {
			return new Optional(<PendingComponent />);
		}
		return new Optional(<code>Loading</code>);
	}
	return new Optional<ReactElement>(null);
};

export const useSearchDataProviderFetchSuspense = (
	dataProviderValue: SearchDataProviderValue,
	suspense: boolean,
	pendingComponent: Nullable<FunctionComponent<DataProviderPendingProps>>
): Optional<ReactElement> => {
	const PendingComponent = pendingComponent;
	const searchStatus = dataProviderValue.querySearchStatus();
	if (suspense && (searchStatus === FetchStatus.IDLE || fetchStatusPendingGroup.includes(searchStatus))) {
		if (PendingComponent !== null) {
			return new Optional(<PendingComponent />);
		}
		return new Optional(<code>Loading</code>);
	}
	return new Optional<ReactElement>(null);
};
