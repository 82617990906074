import { CustomerOrderInformationShippingLineItemModel } from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerOrderInformationShippingLineItemModelMock01: Dto<CustomerOrderInformationShippingLineItemModel> = {
	id: '1',
	productCode: '77352',
	productName: 'Terra AC Wallbox, IP54, A Slightly longer name'
};

export const customerOrderInformationShippingLineItemModelMock02: Dto<CustomerOrderInformationShippingLineItemModel> = {
	id: '1',
	productCode: '77352',
	productName: 'Terra AC Wallbox, IP54'
};
