import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AuthProvider, AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { NotFoundError } from '@abb-emobility/shared/error';
import { DialogueProvider } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { UNAUTHENTICATE_SIGNAL } from '../../../store/store';
import { OrderAssembler } from '../../components/order/OrderAssembler';
import { OrderViewLayout } from '../../components/order-view-layout/OrderViewLayout';
import { OtpAuthEmitter } from '../../components/otp-auth-emitter/OtpAuthEmitter';
import { usePrimaryKeyValidator } from '../../hooks/PrimaryKeyValidator.hook';
import { createRouteUrl, RoutePath } from '../../router/Routes';

export function OrderView() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { orderId = null } = useParams();
	const l10n = useL10n();
	const primaryKeyValidator = usePrimaryKeyValidator();

	if (orderId === null || !primaryKeyValidator(orderId)) {
		throw new NotFoundError(l10n.translate('omsCustomerApp.error.orderNotFound'));
	}

	const handleReauthenticate = () => {
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
	};

	const handleUnauthenticate = () => {
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
		navigate(createRouteUrl(RoutePath.ORDER, ['orderId', orderId]));
	};

	const renderOtpAuthEmitter = (): ReactElement<AuthProviderInterceptingProps> => {
		return (<OtpAuthEmitter orderId={orderId} />);
	};

	return (
		<AuthProvider
			authScope={orderId}
			interceptingComponent={renderOtpAuthEmitter}
			onReauthenticate={handleReauthenticate}
			onUnauthenticate={handleUnauthenticate}
		>
			<DialogueProvider>
				<OrderViewLayout orderId={orderId}>
					<OrderAssembler orderId={orderId} />
				</OrderViewLayout>
			</DialogueProvider>
		</AuthProvider>
	);

}
