import {
	AnyInstallationPartnerOrderInformationCollectionItemModel,
	AnyInstallationPartnerOrderInformationCollectionItemModelConverter
} from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class AnyInstallationPartnerOrderInformationCollectionApiClient extends AbstractCrudApiClient<AnyInstallationPartnerOrderInformationCollectionItemModel> {
	protected collectionControllerUri = 'installation-partner/order-informations/';
	protected paginationControllerUri = 'installation-partner/order-informations/';
	protected entityControllerUri = 'installation-partner/order-information/{id}/';
	protected entitySearchControllerUri = 'installation-partner/order-information/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyInstallationPartnerOrderInformationCollectionItemModelConverter();
}
