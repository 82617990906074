import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

export type OrderImportRecipientModel = {
	firstName: string,
	lastName: string,
	address1: string,
	city: string,
	region: string,
	country: IsoCountryCode,
	postalCode: string,
	phoneNumber: string,
	workPhoneNumber: string,
	emailAddress: string
};

export class OrderImportRecipientModelConverter extends ModelConverter<OrderImportRecipientModel> {
}
