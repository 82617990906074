import { InstallationPartnerOrderInformationQuotationInformationModel } from '@abb-emobility/oms/domain-model';
import {
	installationPartnerDeltaInstallationPackageModelMock01,
	installationPartnerDeltaInstallationPackageModelMock02,
	installationPartnerDeltaInstallationPackageModelMock03
} from '@abb-emobility/shared/api-integration';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

const deltaPackageMocks = [
	{
		amount: 1,
		deltaPackage: installationPartnerDeltaInstallationPackageModelMock01
	},
	{
		amount: 2,
		deltaPackage: installationPartnerDeltaInstallationPackageModelMock02
	},
	{
		amount: 3,
		deltaPackage: installationPartnerDeltaInstallationPackageModelMock03
	}
];

export const installationPartnerOrderInformationQuotationInformationModelMock: Dto<InstallationPartnerOrderInformationQuotationInformationModel> = {
	deltaPackages: deltaPackageMocks,
	totalPurchaseNetPrice: deltaPackageMocks.reduce<number>((accumulator, currentValue) => accumulator + currentValue.amount * currentValue.deltaPackage.purchaseNetPrice, 0),
	currencyCode: CurrencyCode.EUR,
	conditions: 'Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Maecenas sed diam eget risus varius blandit sit amet non magna. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Aenean lacinia bibendum nulla sed consectetur. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec sed odio dui. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.'
};
