import { ReactNode } from 'react';

import { calculateMajorUnitFromMinorUnit, Currency, getCurrencySymbolByCurrency } from '@abb-emobility/shared/currency';
import { QuotationModel } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { DeltaQuotationItem } from '../delta-quotation-item/DeltaQuotationItem';

import './DeltaQuotationItems.scss';

export type DeltaQuotationItemsProps = {
	quotation: QuotationModel,
	currency: Currency
};

export function DeltaQuotationItems(props: DeltaQuotationItemsProps) {

	const { quotation, currency } = props;

	const l10n = useL10n();

	const calculateContainedVat = (): number => {
		return quotation.grossPrice - quotation.netPrice;
	};

	const renderQuotation = (): ReactNode => {
		const items = quotation.items.map((item): ReactNode => {
			return (
				<DeltaQuotationItem
					deltaInstallation={item}
					currency={currency}
					key={item.id}
				/>
			);
		});
		return (
			<section className="delta-quotation-items">
				<main className="delta-quotation-items__items">
					{items}
				</main>
				<footer className="delta-quotation-items__footer">
					<section className="delta-quotation-items__footer__section">
						<label className="delta-quotation-items__footer__section__label">
							{l10n.translate('omsCustomerUiDomain.deltaQuotationItem.additionalExpenses')}
						</label>
						<var className="delta-quotation-items__footer__section__gross-price">
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, quotation.grossPrice), currency.fraction)}
							&nbsp;
							{getCurrencySymbolByCurrency(currency)}
						</var>
					</section>

					<section className="delta-quotation-items__footer__section">
						<label className="delta-quotation-items__footer__section__label">
							{l10n.translate(
								'omsCustomerUiDomain.deltaQuotationItem.vatInformation',
								new Map([['vatRate', l10n.formatNumber(quotation.vatRate, 1)]])
							)}
						</label>
						<var className="delta-quotation-items__footer__section__contained-vat">
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, calculateContainedVat()), currency.fraction)}
							&nbsp;
							{getCurrencySymbolByCurrency(currency)}
						</var>
					</section>

				</footer>
			</section>
		);
	};

	return (
		<>
			{renderQuotation()}
		</>
	);
}
