import { CustomerModel, CustomerModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import {
	ArrayOfUploadFileFieldNames,
	UploadFile,
	UploadFileFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type InstallationCompletionPayloadModel = {
	readonly customer: CustomerModel,
	readonly wallboxSerialNumber: string,
	readonly throttleWallboxPowerConsumption: boolean,
	documents?: Array<UploadFile>,
	customerIsPresent?: boolean,
	signature?: UploadFile
} & TaskPayloadFoundationModel;

export class InstallationCompletionPayloadModelConverter extends ModelConverter<InstallationCompletionPayloadModel> {

	override getUploadFileFields(): Array<UploadFileFieldNames<InstallationCompletionPayloadModel> | ArrayOfUploadFileFieldNames<InstallationCompletionPayloadModel>> {
		return ['documents', 'signature'];
	}

	override getFieldConverterMapByModel() {
		return new Map<keyof InstallationCompletionPayloadModel, ModelConverterInterface<CustomerModel>>([
			['customer', new CustomerModelConverter()]
		]);
	}
}

export type InstallationCompletionEscalationPayloadModel =
	InstallationCompletionPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

export class InstallationCompletionEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<InstallationCompletionEscalationPayloadModel> {
	private payloadModelConverter = new InstallationCompletionPayloadModelConverter();

	override getUploadFileFields(): Array<UploadFileFieldNames<InstallationCompletionEscalationPayloadModel> | ArrayOfUploadFileFieldNames<InstallationCompletionEscalationPayloadModel>> {
		return this.payloadModelConverter.getUploadFileFields();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof InstallationCompletionEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
