export const combineUrl = (baseUrl: string, path: string, queryParams?: Map<string, string>): string => {
	const url = new URL(baseUrl);
	url.pathname = path;
	if (queryParams && queryParams.size > 0) {
		queryParams.forEach((value, key) => {
			url.searchParams.set(key, value);
		});
	}
	return url.toString();
};
