import React, { ReactNode } from 'react';

import { ButtonTertiary, Separator } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderInformationShipmentInformationModel, CustomerOrderInformationShippingModel } from '@abb-emobility/oms/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import './ShippingInformation.scss';

export type ShippingInformationProps = {
	orderInformation: CustomerOrderInformationShippingModel
};

export function ShippingInformation(props: ShippingInformationProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const productCodeLabel = l10n.translate('omsCustomerApp.order.information.shipping.productCodeLabel');

	const renderTrackingButton = (shipment: CustomerOrderInformationShipmentInformationModel): ReactNode => {
		const handleTrackingLink = (): void => {
			window.open(shipment.trackingUrl);
		};

		if (shipment.trackingUrl === undefined) {
			return null;
		}
		return (
			<div className="shipment-information__tracking-link">
				<ButtonTertiary
					label={l10n.translate('omsCustomerApp.order.information.shipping.trackingLinkLabel')}
					onClick={handleTrackingLink}
				/>
			</div>
		);
	};

	const renderShipment = (shipment: CustomerOrderInformationShipmentInformationModel, index: number): ReactNode => {

		const renderShippingItems = () => {
			return (
				shipment.shippingLineItems.map((item, index) => {
					return (
						<span className="shipment-information__block__content" key={index}>
							{`#${index + 1}`}
							<br />
							{`${productCodeLabel}: ${item.productCode}`}
							<br />
							{item.productName}
						</span>
					);
				})
			);
		};

		return (
			<main className="shipment-information" key={index}>
				<h1 className="shipment-information__heading">
					{l10n.translate('omsCustomerApp.order.information.shipping.heading', new Map([['carrier', shipment.carrierName]]))}
				</h1>
				<div className="shipment-information__block">
					<span className="shipment-information__block__label">
						{l10n.translate('omsCustomerApp.order.information.shipping.trackingNumberLabel')}
					</span>
					<span className="shipment-information__block__content">{shipment.trackingNumber}</span>
				</div>
				<div className="shipment-information__block">
					<span className="shipment-information__block__label">
						{l10n.translate('omsCustomerApp.order.information.shipping.shippingAddressLabel')}
					</span>
					<span className="shipment-information__block__content">
						{shipment.recipientName}
						<br />
						{shipment.shippingAddress}
					</span>
				</div>
				<div className="shipment-information__block">
					<span className="shipment-information__block__label">
						{l10n.translate('omsCustomerApp.order.information.shipping.itemsLabel')}
					</span>

					{renderShippingItems()}
				</div>
				{renderTrackingButton(shipment)}
			</main>
		);
	};

	const renderShipments = (): ReactNode => {
		return (orderInformation.payload.shipments ?? []).map((shipment, index) => {
			if (index > 0) {
				return [<Separator key={`separator-${index}`} />, renderShipment(shipment, index)];
			}
			return renderShipment(shipment, index);
		});
	};

	return (
		<article className="shipping-information">
			{renderShipments()}
		</article>
	);
}
