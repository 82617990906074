import { withProfiler } from '@sentry/react';
import { ReactElement } from 'react';

import { ErrorHandler } from '@abb-emobility/shared/error';

import { Theme } from './components/theme/Theme';
import { Router } from './router/Router';
import { ErrorView } from './views/error-view/ErrorView';
import { themes } from '../themes/themes';

const handleError = (error: Error): ReactElement => {
	return (<ErrorView error={error} />);
};

export function App() {

	return (
		<Theme themes={themes}>
			<ErrorHandler errorComponent={handleError}>
				<Router />
			</ErrorHandler>
		</Theme>
	);
}

export const AppWithSentryProfiler = withProfiler(App);
