import { ModelConverter, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerOrderInformationShippingLineItemModel = {
	id: ModelPrimaryKey,
	productCode: string,
	productName: string
};

export class CustomerOrderInformationShippingLineItemModelConverter extends ModelConverter<CustomerOrderInformationShippingLineItemModel> {
}
