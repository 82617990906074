import { Nullable } from '@abb-emobility/shared/util';

import { EventData } from './EventData';
import { LogLevel } from './LogLevel';
import { Logger } from './Logger';

export class ConsoleLogger implements Logger {

	private lastError: Nullable<Error> = null;
	private lastMessage: unknown = null;

	private readonly doubleLogProtection: boolean;
	private readonly filterError: Nullable<(error: Error, level: LogLevel) => boolean>;
	private readonly filterMessage: Nullable<(message: unknown, level: LogLevel) => boolean>;

	public static instrument() {
		return new ConsoleLogger();
	}

	constructor(
		doubleLogProtection?: boolean,
		filterError?: (error: Error, level: LogLevel) => boolean,
		filterMessage?: (message: unknown, level: LogLevel) => boolean
	) {
		this.doubleLogProtection = doubleLogProtection ?? false;
		this.filterError = filterError ?? null;
		this.filterMessage = filterMessage ?? null;
	}

	public async logEvent(message: string, data?: EventData, category?: string, level: LogLevel = LogLevel.INFO): Promise<void> {
		const logMessageParts: Array<Nullable<string>> = [];
		if (data) {
			logMessageParts.push(data.type);
		}
		if (category) {
			logMessageParts.push(category + ':');
		}
		logMessageParts.push(message);
		this.log(level, ...logMessageParts);
		if (data?.payload !== undefined) {
			console.table(data.payload);
		}
	}

	public async logError(error: Error, level: LogLevel = LogLevel.LOG): Promise<void> {
		if (this.doubleLogProtection && this.lastError === error) {
			return;
		}
		if (this.filterError === null || this.filterError(error, level)) {
			this.lastError = error;
			this.log(level, error);
		}
	}

	public async logMessage(message: unknown, level: LogLevel = LogLevel.LOG): Promise<void> {
		if (this.doubleLogProtection && this.lastMessage === message) {
			return;
		}
		if (this.filterMessage === null || this.filterMessage(message, level)) {
			this.lastMessage = message;
			this.log(level, message);
		}
	}

	private log(level: LogLevel, ...data: Array<unknown>) {
		switch (level) {
			case LogLevel.DEBUG:
				console.debug('[ConsoleLogger]', ...data);
				break;
			case LogLevel.INFO:
				console.info('[ConsoleLogger]', ...data);
				break;
			case LogLevel.LOG:
				console.log('[ConsoleLogger]', ...data);
				break;
			case LogLevel.WARN:
				console.warn('[ConsoleLogger]', ...data);
				break;
			case LogLevel.ERROR:
			case LogLevel.FATAL:
				console.error('[ConsoleLogger]', ...data);
				break;
		}
	}

}
