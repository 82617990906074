import { LiteralStruct } from './Locale';

export const flattenLiteralStruct = (literals: LiteralStruct, keyChain: Array<string> = []): Record<string, string> => {
	let flattened: Record<string, string> = {};
	Object.keys(literals).forEach((key: string) => {
		if (typeof literals[key] === 'object' && literals[key] !== null) {
			flattened = { ...flattened, ...flattenLiteralStruct(literals[key] as LiteralStruct, [...keyChain, key]) };
		} else {
			flattened[[...keyChain, key].join('.')] = String(literals[key]);
		}
	});
	return flattened;
};
