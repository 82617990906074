import { useNavigate } from 'react-router-dom';

import { ErrorFeedback, ErrorFeedbackAction, SpinnerCable } from '@abb-emobility/oms/customer-ui-primitive';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AuthenticationFailedError, AuthenticationRequiredError, ErrorHandler, NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { AnyTaskEntityDataProvider } from '@abb-emobility/usertask/data-provider';

import { Task } from './Task';
import { createRouteUrl, RoutePath } from '../../router/Routes';

export type TaskAssemblerProps = {
	taskId: ModelPrimaryKey,
	orderId: ModelPrimaryKey
};

export function TaskAssembler(props: TaskAssemblerProps) {

	const { taskId, orderId } = props;

	const auth = useAuth();
	const l10n = useL10n();
	const navigate = useNavigate();

	const handleError = (error: Error) => {
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth.reauthenticate();
		}

		if (error instanceof NotFoundError) {
			navigate(createRouteUrl(
				RoutePath.ORDER,
				['orderId', orderId]
			));
			return (<SpinnerCable />);
		}

		const reloadAction: ErrorFeedbackAction = {
			label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
			onInvoke: (): void => {
				window.location.reload();
			}
		};

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				actions={[reloadAction]}
			/>
		);
	};

	return (
		<ErrorHandler errorComponent={handleError}>
			<AnyTaskEntityDataProvider
				primaryKey={taskId}
				pendingComponent={SpinnerCable}
			>
				<Task />
			</AnyTaskEntityDataProvider>
		</ErrorHandler>
	);
}
