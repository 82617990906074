import { AnchorHTMLAttributes, ReactNode } from 'react';

import { Timestamp } from '@abb-emobility/shared/domain-model-foundation';
import { formatFileSize } from '@abb-emobility/shared/formatter';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap, Maybe } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Download.scss';

export type DownloadProps = {
	title: string,
	fileSize?: number,
	fileType?: string,
	fileDate?: Timestamp,
	disabled?: boolean
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export function Download(props: DownloadProps) {

	const { title, fileSize, fileType, fileDate, disabled = false, ...rest } = props;

	const l10n = useL10n();

	const downloadClassmap = {
		'download': true,
		'download--disabled': disabled
	};

	const buildFiletype = (filetype?: string): Maybe<string> => {
		return filetype?.split('/').pop()?.toUpperCase() ?? undefined;
	};

	const renderFileInfo = (): ReactNode => {
		let fileInfoParts = [formatFileSize(fileSize ?? undefined), buildFiletype(fileType), l10n.formatTimestampDate(fileDate)];
		fileInfoParts = fileInfoParts.filter((fileInfoPart) => {
			return fileInfoPart !== undefined && fileInfoPart !== '';
		});
		if (fileInfoParts.length === 0) {
			return;
		}
		return (
			<div className="download__info__file">{fileInfoParts.join(' | ')}</div>
		);
	};

	return (
		<a {...rest} className={buildCssClassStringFromClassMap(downloadClassmap)}>
			<div className="download__info">
				<div className="download__info__name">{title}</div>
				{renderFileInfo()}
			</div>
			<div className="download__icon">
				<Icon name={IconIdentifier.DOWNLOAD} />
			</div>
		</a>
	);
}
