import { ReactNode, useState } from 'react';

import {
	ButtonBar, ButtonBarSection, ButtonBarSectionAlign, ButtonPrimary,
	Form,
	FormSection,
	FormSegement,
	InputRadio,
	Separator,
	SpinnerCable,
	SuccessFeeback
} from '@abb-emobility/oms/customer-ui-primitive';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	PreCheckRejectedByGridOperatorPayloadModel,
	PreCheckRejectedByGridOperatorSolution,
	PreCheckRejectedByGridOperatorTaskModel
} from '@abb-emobility/usertask/domain-model';

export type TaskPreCheckRejectedByGridOperatorProps = {
	task: PreCheckRejectedByGridOperatorTaskModel
};

export function TaskPreCheckRejectedByGridOperator(props: TaskPreCheckRejectedByGridOperatorProps) {

	const { task } = props;

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const actionStatus = anyTaskEntityData.queryActionStatus();
	const [taskPayload, setTaskPayload] = useState<PreCheckRejectedByGridOperatorPayloadModel>(task.payload);

	const handleDecreasePowerSelect = (checked: boolean): void => {
		const payload = { ...taskPayload };
		payload.solution = checked ? PreCheckRejectedByGridOperatorSolution.DECREASE_WALLBOX_POWER : undefined;
		setTaskPayload(payload);
	};

	const handleCancelInstallationSelect = (checked: boolean): void => {
		const payload = { ...taskPayload };
		payload.solution = checked ? PreCheckRejectedByGridOperatorSolution.CANCEL_INSTALLATION : undefined;
		setTaskPayload(payload);
	};

	const handleCancelOrderSelect = (checked: boolean): void => {
		const payload = { ...taskPayload };
		payload.solution = checked ? PreCheckRejectedByGridOperatorSolution.CANCEL_ORDER : undefined;
		setTaskPayload(payload);
	};

	const handleSubmit = (): void => {
		anyTaskEntityData.complete(task, taskPayload);
	};

	const renderCancelInstallationSelection = (): ReactNode => {
		if (!task.payload.installationCancelable) {
			return null;
		}
		return (
			<>
				<FormSegement>
					<FormSection>
						<InputRadio
							heading={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.solutionCancelInstallation.heading')}
							label={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.solutionCancelInstallation.description')}
							checked={taskPayload.solution === PreCheckRejectedByGridOperatorSolution.CANCEL_INSTALLATION}
							onChange={handleCancelInstallationSelect}
						/>
					</FormSection>
				</FormSegement>
				<Separator />
			</>
		);
	};

	const renderPayload = (): ReactNode => {
		switch (actionStatus) {
			case TaskActionStatus.COMPLETE_PENDING:
				return (<SpinnerCable />);
			case TaskActionStatus.COMPLETE_SUCCESS: {
				let successHeadingLiteral = '';
				let successMessageLiteral = '';
				switch (taskPayload.solution) {
					case PreCheckRejectedByGridOperatorSolution.DECREASE_WALLBOX_POWER:
						successHeadingLiteral = 'omsCustomerApp.task.preCheckRejectedByGridOperator.completionDecreaseWallboxPowerSuccess.heading';
						successMessageLiteral = 'omsCustomerApp.task.preCheckRejectedByGridOperator.completionDecreaseWallboxPowerSuccess.message';
						break;
					case PreCheckRejectedByGridOperatorSolution.CANCEL_INSTALLATION:
						successHeadingLiteral = 'omsCustomerApp.task.preCheckRejectedByGridOperator.completionCancelInstallationSuccess.heading';
						successMessageLiteral = 'omsCustomerApp.task.preCheckRejectedByGridOperator.completionCancelInstallationSuccess.message';
						break;
					case PreCheckRejectedByGridOperatorSolution.CANCEL_ORDER:
						successHeadingLiteral = 'omsCustomerApp.task.preCheckRejectedByGridOperator.completionCancelOrderSuccess.heading';
						successMessageLiteral = 'omsCustomerApp.task.preCheckRejectedByGridOperator.completionCancelOrderSuccess.message';
						break;
				}
				return (
					<SuccessFeeback
						heading={l10n.translate(successHeadingLiteral)}
						message={l10n.translate(successMessageLiteral)}
					/>
				);
			}
			default:
				return (
					<Form onSubmit={handleSubmit}>
						<FormSegement
							heading={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.caption')}
							description={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.message')}
						>
							<FormSection>
								<InputRadio
									heading={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.solutionDecreaseWallboxPower.heading')}
									label={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.solutionDecreaseWallboxPower.description')}
									checked={taskPayload.solution === PreCheckRejectedByGridOperatorSolution.DECREASE_WALLBOX_POWER}
									onChange={handleDecreasePowerSelect}
								/>
							</FormSection>
						</FormSegement>
						<Separator />
						{renderCancelInstallationSelection()}
						<FormSegement>
							<FormSection>
								<InputRadio
									heading={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.solutionCancelOrder.heading')}
									label={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.solutionCancelOrder.description')}
									checked={taskPayload.solution === PreCheckRejectedByGridOperatorSolution.CANCEL_ORDER}
									onChange={handleCancelOrderSelect}
								/>
							</FormSection>
						</FormSegement>
						<Separator />
						<ButtonBar>
							<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
								<ButtonPrimary
									type="submit"
									label={l10n.translate('omsCustomerApp.task.preCheckRejectedByGridOperator.submitButtonLabel')}
									disabled={taskPayload.solution === undefined}
								/>
							</ButtonBarSection>
						</ButtonBar>
					</Form>
				);
		}
	};

	return (
		<>
			{renderPayload()}
		</>
	);

}
