import {
	CustomerModel,
	CustomerModelConverter,
	InstallationPartnerModel,
	InstallationPartnerModelConverter
} from '@abb-emobility/shared/domain-model';
import {
	Dto,
	ModelConverter,
	ModelConverterInterface
} from '@abb-emobility/shared/domain-model-foundation';

export type TaskPayloadFoundationModel = {
	comment?: string
};

export type CustomerServiceTaskPayloadFoundationModel = {
	customer: CustomerModel,
	installationPartner: InstallationPartnerModel
};

/*
CustomerServiceTaskPayloadFoundationModelConverter can be extended to convert models that are created using an intersection (&) with
the CustomerServiceTaskPayloadFoundationModel
 */
export abstract class CustomerServiceTaskPayloadFoundationModelConverter<T extends CustomerServiceTaskPayloadFoundationModel> extends ModelConverter<T> {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(model?: T | Dto<T>): Map<keyof T, ModelConverterInterface<any>> {

		return new Map<keyof T, ModelConverterInterface<CustomerModel> | ModelConverterInterface<InstallationPartnerModel>>([
			['customer', new CustomerModelConverter()],
			['installationPartner', new InstallationPartnerModelConverter()]
		]);
	}
}
