import { InstallerAppointmentModelConverter } from '@abb-emobility/oms/domain-model';
import { InstallerAppointmentModel } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class InstallerAppointmentApiClient extends AbstractCrudApiClient<InstallerAppointmentModel> {
	protected collectionControllerUri = 'installer-appointments/';
	protected paginationControllerUri = 'installer-appointments/';
	protected entityControllerUri = 'installer-appointment/{id}/';
	protected entitySearchControllerUri = 'installer-appointment/';
	protected modelConverter = new InstallerAppointmentModelConverter();
}
