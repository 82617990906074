import { ReactNode } from 'react';

import './FormSegment.scss';

export type FormSegmentProps = {
	children?: ReactNode,
	heading?: string,
	description?: string
};

export function FormSegement(props: FormSegmentProps) {
	const { children, heading, description } = props;

	const renderHeaderHeading = () => {
		if (heading === undefined) {
			return;
		}
		return (
			<h1 className="form-segment__header__heading">
				{heading}
			</h1>
		);
	};

	const renderHeaderDescription = () => {
		if (description === undefined) {
			return;
		}
		return (
			<p className="form-segment__header__description">
				{description}
			</p>
		);
	};

	const renderHeader = () => {
		if (heading === undefined && description === undefined) {
			return;
		}
		return (
			<header className="form-segment__header">
				{renderHeaderHeading()}
				{renderHeaderDescription()}
			</header>
		);
	};

	const renderMain = (): ReactNode => {
		if (children === undefined) {
			return null;
		}
		return (
			<main className="form-segment__main">
				{children}
			</main>
		);
	};

	return (
		<section className="form-segment">
			{renderHeader()}
			{renderMain()}
		</section>
	);
}
