import { readLocalStorage, removeLocalStorage, writeLocalStorage } from '@abb-emobility/shared/local-storage';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { AccessToken, JsonWebToken, RefreshToken } from '../foundation/JsonWebToken';

type StoredJsonWebToken = Omit<JsonWebToken, 'accessTokenValidTo' | 'refreshTokenValidTo'> & {
	accessTokenValidTo: number,
	refreshTokenValidTo?: number
};

export const JSON_WEB_TOKEN_STORAGE_KEY = 'lib_auth_jwt';

export class AuthHandler {

	private static instance: Nullable<AuthHandler> = null;
	private readonly scope?: string;

	private constructor(scope?: string) {
		this.scope = scope;
	}

	static get(scope?: string): AuthHandler {
		if (this.instance === null) {
			this.instance = new this(scope);
		}
		return this.instance;
	}

	getScope(): string | undefined {
		return this.scope;
	}

	authenticate(
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date,
		roles?: Array<string>
	): void {
		const token = {
			accessToken,
			accessTokenValidTo,
			refreshToken,
			refreshTokenValidTo,
			roles: roles ?? []
		} as JsonWebToken;

		this.writeToken(token);
	}

	update(accessToken: AccessToken, accessTokenValidTo: Date, refreshToken?: RefreshToken, refreshTokenValidTo?: Date): void {
		const existingToken = this.getToken().get();
		if (existingToken === null) {
			return;
		}

		let token = {
			...existingToken,
			accessToken,
			accessTokenValidTo
		} as JsonWebToken;

		if (refreshToken !== undefined && refreshTokenValidTo !== undefined) {
			token = {
				...token,
				refreshToken,
				refreshTokenValidTo
			} as JsonWebToken;
		}

		this.writeToken(token);
	}

	unauthenticate(): void {
		removeLocalStorage(JSON_WEB_TOKEN_STORAGE_KEY, { namespace: this.getScope() });
	}

	getToken(): Optional<JsonWebToken> {
		return readLocalStorage<JsonWebToken, StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY, {
			namespace: this.getScope(),
			conversion: (value: StoredJsonWebToken) => {
				return {
					...value,
					accessTokenValidTo: new Date(value.accessTokenValidTo),
					refreshTokenValidTo: value.refreshTokenValidTo ? new Date(value.refreshTokenValidTo) : undefined
				};
			}
		});
	}

	isAuthenticated(): boolean {
		const jsonWebToken = this.getToken().get();
		if (jsonWebToken === null) {
			return false;
		}
		const accessTokenValidToTimestamp = jsonWebToken.accessTokenValidTo?.getTime() ?? 0;
		const refreshTokenValidToTimestamp = jsonWebToken.refreshTokenValidTo?.getTime() ?? 0;
		return (
			jsonWebToken?.accessToken !== undefined
			&& accessTokenValidToTimestamp > Date.now()
		) || (
			jsonWebToken?.refreshToken !== undefined
			&& refreshTokenValidToTimestamp > Date.now()
		);
	}

	needsUpdate(): boolean {
		const jsonWebToken = this.getToken().get();
		if (jsonWebToken === null) {
			return false;
		}
		const accessTokenValidToTimestamp = jsonWebToken.accessTokenValidTo?.getTime() ?? 0;
		const refreshTokenValidToTimestamp = jsonWebToken.refreshTokenValidTo?.getTime() ?? 0;
		return jsonWebToken?.refreshToken !== undefined
			&& refreshTokenValidToTimestamp > Date.now()
			&& accessTokenValidToTimestamp < Date.now() + 10000;
	}

	hasRole(role: string): boolean {
		return this.getToken().get()?.roles.includes(role) ?? false;
	}

	private writeToken(token: JsonWebToken): void {
		writeLocalStorage<JsonWebToken, StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY, token, {
			namespace: this.getScope(),
			conversion: (value) => {
				return {
					...value,
					accessTokenValidTo: value.accessTokenValidTo.getTime(),
					refreshTokenValidTo: value.refreshTokenValidTo?.getTime() ?? undefined
				};
			}
		});
	}

}
