import { isString } from '@abb-emobility/shared/util';

import { buildAnswerKey, getAnswersByKey } from './AnswerKeyUtil';
import { AnyQuestionnaireModel, QuestionnaireSegment } from '../types/QuestionnaireModel';
import { Answers } from '../types/answer/Answers';
import { AnswerTypeId } from '../types/question/Question';

export const segmentHasConsent = (
	segment: QuestionnaireSegment,
	answers: Answers
): boolean => {
	for (const section of segment.sections) {
		for (const question of section.questions.filter((question) => {
			return (
				question.answerType.type === AnswerTypeId.CONSENT &&
				question.answerType.options.required
			);
		})) {
			const requiredAnswerKey = buildAnswerKey(
				segment,
				section,
				question
			);
			const answer = getAnswersByKey(answers, requiredAnswerKey);
			if (
				answer === null ||
				(isString(answer) && answer.trim().length === 0)
			) {
				return false;
			}
		}
	}
	return true;
};

export const isConsentQuestion = (
	questionnaire: AnyQuestionnaireModel,
	questionKey: string
): boolean => {
	for (const segment of questionnaire.segments) {
		for (const section of segment.sections) {
			for (const question of section.questions.filter((question) => {
				return question.answerType.type === AnswerTypeId.CONSENT;
			})) {
				if (
					buildAnswerKey(segment, section, question) === questionKey
				) {
					return true;
				}
			}
		}
	}
	return false;
};
