import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	ModelPrimaryKey,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

type CancellationState = {
	readonly cancelled: true,
	readonly cancelledAt: Timestamp
} | {
	readonly cancelled: false,
	readonly cancelledAt?: never
};

export type CustomerOrderInformationOrderItemsInformationOrderLineItemModel = {
	readonly id: ModelPrimaryKey,
	readonly productName: string
} & CancellationState;

export class CustomerOrderInformationOrderItemsInformationOrderLineItemModelConverter extends ModelConverter<CustomerOrderInformationOrderItemsInformationOrderLineItemModel> {

	override getTimestampFields(): Array<TimestampFieldNames<CustomerOrderInformationOrderItemsInformationOrderLineItemModel> | ArrayOfTimestampFieldNames<CustomerOrderInformationOrderItemsInformationOrderLineItemModel>> {
		return ['cancelledAt'];
	}

}
