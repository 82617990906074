import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

export type DialogueContextValue = {
	present: (dialogueGenerator: () => ReactNode) => void,
	dismiss: () => void
};

export const dialogueContext = createContext<DialogueContextValue>({
	present: (): void => {
		throw new Error('No dialogue provided');
	},
	dismiss: (): void => {
		throw new Error('No dialogue provided');
	}
});

export const useDialogue = () => {
	return useContext(dialogueContext);
};

export type DialogueContextProviderProps = {
	children: ReactNode
};

export const DialogueProvider = (props: DialogueContextProviderProps): React.JSX.Element => {

	const { children } = props;

	const [presenting, setPresenting] = useState<boolean>(false);
	const presentGenerator = useRef<Nullable<() => ReactNode>>(null);

	const providerValue = {
		present: (dialogueGenerator: () => ReactNode) => {
			presentGenerator.current = dialogueGenerator;
			setPresenting(true);
		},
		dismiss: () => {
			setPresenting(false);
		}
	};

	const renderDialogue = (): ReactNode => {
		if (presenting && presentGenerator.current !== null) {
			return presentGenerator.current();
		}
		return null;
	};

	return (
		<dialogueContext.Provider value={providerValue}>
			{renderDialogue()}
			{children}
		</dialogueContext.Provider>
	);

};
