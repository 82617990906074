enum CharCase {
	NONE,
	NUMERIC,
	UPPERCASE,
	LOWERCASE
}

export const removeWhitespace = (string: string): string => {
	return string.replace(/\s/g, '');
};

export const removeNonNumericChars = (string: string): string => {
	return string.replace(/\D/g, '');
};

export const trim = (string: string, char: string): string => {
	return trimFromRight(trimFromLeft(string, char), char);
};

export const trimFromRight = (string: string, char: string): string => {
	while (string.endsWith(char)) {
		string = string.slice(0, -1 * char.length);
	}
	return string;
};

export const trimFromLeft = (string: string, char: string): string => {
	while (string.startsWith(char)) {
		string = string.slice(char.length);
	}
	return string;
};

export const camelCase = (string: string): string => {
	const stringFragments = string
		.toLowerCase()
		.split(/[\t\n\s,\-_]+/)
		.filter((fragment): boolean => {
			return fragment.length > 0;
		})
		.map((fragment): string => {
			return fragment[0].toUpperCase() + (fragment.substring(1) ?? '');
		});
	const combined = stringFragments.join('');
	return (combined[0]?.toLowerCase() ?? '') + (combined.substring(1) ?? '');
};

export const upperCase = (value: string) => {
	const stringFragments: Array<string> = [];
	let current = '';
	for (const char of value) {
		const caseChar = getCharCase(char);
		if (caseChar === CharCase.NONE) {
			stringFragments.push(current);
			current = '';
			continue;
		}
		if (caseChar === CharCase.NUMERIC) {
			stringFragments.push(current);
			stringFragments.push(char);
			current = '';
			continue;
		}
		if (caseChar === CharCase.UPPERCASE) {
			stringFragments.push(current);
			current = char;
			continue;
		}
		current += char;
	}
	stringFragments.push(current);
	return stringFragments
		.filter((fragment): boolean => {
			return fragment.length > 0;
		})
		.map((fragment): string => {
			return fragment.toUpperCase();
		})
		.join('_');
};

export const isNumeric = (value: string): boolean => {
	return !isNaN(Number(value));
};

export const isString = (value: unknown): value is string => {
	return typeof value === 'string' || value instanceof String;
};

export const isOptionalString = (value: unknown): value is string | undefined => {
	return value === undefined || isString(value);
};

export const truncateRight = (string: string, targetLength: number, placeholder = ' …'): string => {
	if (string.length <= targetLength) {
		return string;
	}
	return string.slice(0, targetLength - placeholder.length) + placeholder;
};

export const truncateLeft = (string: string, targetLength: number, placeholder = '… '): string => {
	if (string.length <= targetLength) {
		return string;
	}
	return placeholder + string.slice((targetLength - placeholder.length) * -1);
};

export const truncateCenter = (string: string, targetLength: number, placeholder = ' … '): string => {
	if (string.length <= targetLength) {
		return string;
	}
	const sliceIndex = (targetLength - placeholder.length) / 2;
	return string.slice(0, Math.ceil(sliceIndex)) + placeholder + string.slice(Math.ceil(sliceIndex * -1));
};

const getCharCase = (char: string): CharCase => {
	const charCode = char.charCodeAt(0);
	if (charCode >= 48 && charCode <= 57) {
		return CharCase.NUMERIC;
	}
	if (charCode >= 65 && charCode <= 90) {
		return CharCase.UPPERCASE;
	}
	if (charCode >= 97 && charCode <= 122) {
		return CharCase.LOWERCASE;
	}
	return CharCase.NONE;
};

