import { validate } from 'uuid';

import { booleanFromString, Optional } from '@abb-emobility/shared/util';

export const usePrimaryKeyValidator = (): (uuid: string) => boolean => {

	const validatePrimaryKeys = booleanFromString(
		new Optional(process.env['NX_VALIDATE_PRIMARY_KEYS']).getOrDefault('true')
	);
	if (!validatePrimaryKeys) {
		return (_uuid: string): boolean => {
			return true;
		};
	}
	return (uuid: string): boolean => {
		return validate(uuid);
	};

};
