import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

export type OrderImportEntryModel = {
	entryNumber: number,
	quantity: number,
	basePrice: number,
	taxAmount: number,
	totalPrice: number,
	product: {
		id: string,
		name: string
	}
};

export class OrderImportEntryModelConverter extends ModelConverter<OrderImportEntryModel> {
}
