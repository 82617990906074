import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, Model, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AnyErrorObject } from '@abb-emobility/shared/error';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { CrudActionStatus } from '../../store/CrudActionStatus';
import { CrudCreationStore } from '../../store/crud/CrudCreationStore';
import { CrudCreationStoreAccessor } from '../../store/crud/CrudCreationStoreAccessor';

export type CrudCreationDataProviderValue<T extends Model> = {
	query: () => ReadonlyOptional<T>,
	create: (mutatable: Mutable<T>) => void,
	idle: () => boolean,
	queryActionStatus: () => CrudActionStatus,
	queryActionError: () => Nullable<AnyErrorObject>,
	resolveActionStatus: () => void,
	useActionStatusEffect: (status: Array<CrudActionStatus>, effect: () => void, resolve: boolean) => void,
	resetStore: () => void,
	storeSize: () => number,
	storeEntryIds: () => ReadonlyArray<ModelPrimaryKey>
};

export const defaultCrudCreationDataValue: CrudCreationDataProviderValue<never> = {
	idle: (): boolean => {
		return true;
	},
	queryActionStatus: (): CrudActionStatus => {
		return CrudActionStatus.IDLE;
	},
	queryActionError: (): Nullable<AnyErrorObject> => {
		return null;
	},
	query: (): ReadonlyOptional<never> => {
		throw new Error('Data provider missing');
	},
	resolveActionStatus: (): void => {
		throw new Error('Data provider missing');
	},
	create: (_mutatable: Mutable<never>): void => {
		throw new Error('Data provider missing');
	},
	useActionStatusEffect: (_status: Array<CrudActionStatus>, _effect: () => void, _resolve = false): void => {
		throw new Error('Data provider missing');
	},
	resetStore: (): void => {
		throw new Error('Data provider missing');
	},
	storeSize: (): number => {
		throw new Error('Data provider missing');
	},
	storeEntryIds: (): ReadonlyArray<ModelPrimaryKey> => {
		throw new Error('Data provider missing');
	}
};

export const createCrudCreationDataProviderValue = <T extends Model, RootStore extends Record<string, CrudCreationStore<T>>>(
	storeKey: string,
	storeAccessors: CrudCreationStoreAccessor<T, RootStore>,
	apiBaseUrl: string,
	jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>
): CrudCreationDataProviderValue<T> => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const dispatch = useDispatch();

	const dataProviderValue = {
		idle: (): boolean => {
			return dataProviderValue.queryActionStatus() === CrudActionStatus.IDLE;
		},
		queryActionStatus: (): CrudActionStatus => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, CrudActionStatus>((state) => {
				return state[storeKey].actionStatus ?? CrudActionStatus.IDLE;
			});
		},
		queryActionError: (): Nullable<AnyErrorObject> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<AnyErrorObject>>((state) => {
				return state[storeKey].lastActionError ?? null;
			});
		},
		query: (): ReadonlyOptional<T> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, ReadonlyOptional<T>>(storeAccessors.select());
		},
		resolveActionStatus: (): void => {
			dispatch(storeAccessors.resolveActionStatus());
		},
		create: (mutableModel: Mutable<T>): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.create({ apiBaseUrl, jsonWebTokenLoader, mutableModel }));
		},
		useActionStatusEffect: (status: Array<CrudActionStatus>, effect: () => void, resolve = false): void => {
			const actionStatus = dataProviderValue.queryActionStatus();
			useEffect(() => {
				if (status.includes(actionStatus)) {
					try {
						effect();
					} finally {
						if (resolve) {
							dataProviderValue.resolveActionStatus();
						}
					}
				}
			}, [actionStatus]);
		},
		resetStore: (): void => {
			dispatch(storeAccessors.resetStore());
		},
		storeSize: (): number => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Readonly<number>>(storeAccessors.storeSize());
		},
		storeEntryIds: (): ReadonlyArray<ModelPrimaryKey> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, ReadonlyArray<ModelPrimaryKey>>(storeAccessors.storeEntryIds());
		}
	};

	return dataProviderValue;
};
