import { addressModelMock, customerModelMock } from '@abb-emobility/shared/api-integration';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';
import { AddressPayloadModel, AddressValidationContext, AddressValidationPayloadModel } from '@abb-emobility/usertask/domain-model';

export const addressValidationPayloadModelMock: Dto<AddressValidationPayloadModel> = {
	customer: customerModelMock,
	invalidAddresses: [
		{
			address: {
				...addressModelMock,
				region: 'Niedersachsen'
			},
			usage: [AddressValidationContext.DELIVERY]
		},
		{
			address: {
				zip: '24103',
				city: 'Kiel',
				street: 'Kirchhofallee 999',
				countryCode: IsoCountryCode.DE
			},
			usage: [AddressValidationContext.BILLING]
		}
	]
};

export const addressDraftPayloadModelMock: Dto<AddressPayloadModel> = {
	address: { ...addressModelMock },
	usage: [AddressValidationContext.DELIVERY]
};

export const addressValidationResponsePayloadModelMock: Dto<AddressValidationPayloadModel> = {
	...addressValidationPayloadModelMock,
	addressDraft: [addressDraftPayloadModelMock]
};

