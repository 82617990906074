import { createContext, useContext } from 'react';

export type ThemeContextValue = {
	name: () => string,
	pageTitle: () => string,
	caption: () => string
};

export const themeContext = createContext<ThemeContextValue>({
	name: (): string => {
		throw new Error('No theme provided');
	},
	pageTitle: (): string => {
		throw new Error('No theme provided');
	},
	caption: (): string => {
		throw new Error('No theme provided');
	}
});

export const useTheme = () => {
	return useContext(themeContext);
};
