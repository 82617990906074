export enum InstallationUnfeasibleReason {
	NO_POTENTIAL_RAIL_AVAILABLE = 'NO_POTENTIAL_RAIL_AVAILABLE',
	OVERVOLTAGE_PROTECTION_UNFEASIBLE = 'OVERVOLTAGE_PROTECTION_UNFEASIBLE',
	SPACE_FOR_CABLE_OUTLET_MISSING = 'SPACE_FOR_CABLE_OUTLET_MISSING',
	HOUSE_CONNECTION_UNDERSIZED = 'HOUSE_CONNECTION_UNDERSIZED',
	NO_THREE_PHASE_AVAILABLE = 'NO_THREE_PHASE_AVAILABLE',
	OUTDATED_METER_CABINET = 'OUTDATED_METER_CABINET',
	NOT_TAB_COMPLIANT = 'NOT_TAB_COMPLIANT',
	OTHER = 'OTHER'
}
