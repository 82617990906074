import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'Wallbox ABB',
				labelCancel: 'Annuler',
				titlePaymentData: 'Paiement',
				labelAmount: 'Montant de l\'effort d\'installation et du matériel supplémentaires',
				labelDate: 'Date',
				labelMerchant: 'Marchand',
				labelIban: 'Votre IBAN'
			}
		},
		application: {
			pageTitle: 'Service d’installation de Wallbox ABB',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Commande {{marketplaceId}}',
			task: 'Commande {{marketplaceId}} - {{taskType}}',
			documents: 'Documents',
			payment: 'Paiement',
			notFound: 'Introuvable',
			static: {
				terms: 'Conditions générales d’utilisation',
				privacy: 'Confidentialité',
				imprint: 'Impression'
			}
		},
		footer: {
			termsLabel: 'Conditions générales d’utilisation',
			privacyLabel: 'Confidentialité',
			imprintLabel: 'Impression'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Saisir le code',
			otpInputMessage: 'Nous avons envoyé un code à 6 chiffres sur votre téléphone portable.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Renvoyer',
			otpInputLabel: 'Veuillez saisir votre code',
			otpInputPlaceholder: 'Code d’authentification',
			otpSubmitButtonLabel: 'Continuer'
		},
		error: {
			otpAuthInitFailed: 'Échec de l’authentification',
			otpAuthReceiveTokenFailed: 'Code invalide',
			taskTypeNotFound: 'Le type de tâche demandé {{taskType}} est inconnu.',
			taskNotFound: 'Tâche demandée introuvable',
			orderNotFound: 'Commande demandée introuvable',
			documentNotFound: 'Document demandé introuvable',
			validation: {
				heading: 'Invalid data',
				message: 'The data you sent are not valid.'
			},
			generic: {
				heading: 'Une erreur est survenue',
				message: 'Une erreur s’est produite et n’a pas pu être récupérée.',
				resolveAction: {
					label: 'Recharger'
				}
			},
			notFound: {
				heading: 'Introuvable',
				message: 'La ressource que vous avez demandée est introuvable'
			},
			taskCompleteMessage: 'Une erreur s’est produite lors de l’exécution de la tâche.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'J’ai besoin d’aide',
			dialogueDismissLabel: 'Annuler',
			dialogueCaption: 'Service clientèle',
			phone: {
				heading: 'Vous pouvez nous contacter à tout moment par téléphone'
			},
			mail: {
				heading: 'Ou nous envoyer un e-mail',
				subject: 'Ma commande de Wallbox n°{{marketplaceId}}',
				subjectStatic: 'Ma commande de Wallbox'
			},
			infoPage: {
				heading: 'Ou visitez notre site d\'information',
				label: 'Réponses aux questions les plus fréquentes'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Inconnu',
			allDoneStepLabel: 'Terminé',
			upcomingLabelPrefix: 'Suivant : '
		},
		orderProgressIndicator: {
			outOfSeparator: 'de'
		},
		payment: {
			success: {
				heading: 'Votre paiement a été effectué avec succès.',
				message: 'Plus rien ne s’oppose à l’installation de votre Wallbox. Notre partenaire d’installation prendra prochainement contact avec vous pour organiser un rendez-vous d’installation.\n\nMerci de votre confiance !'
			},
			failed: {
				heading: 'Votre paiement a échoué !',
				retryLabel: 'Essayez à nouveau'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Contrôle de l’installation',
				customerProvidePreCheckPreferences: 'Indiquer la méthode de contact préférée',
				customerHandleUnfeasibleInstallation: 'Installation irréalisable',
				customerHandleGridOperatorRejection: 'Refusé par l’opérateur réseau',
				customerAcceptQuotation: 'Offre de travail supplémentaire',
				customerPerformPayment: 'Paiement',
				customerApproveInstallation: 'Confirmation de l’installation',
				installationPartnerEvaluateBasicAnswers: 'Contrôle de l’installation',
				installationPartnerExecuteRemotePreCheck: 'Contrôle final',
				installationPartnerRegisterAtGridOperator: 'Enregistrement de l’opérateur réseau',
				installationPartnerRevokeGridOperatorRegistration: 'Révocation de l’opérateur réseau',
				installationPartnerProvideGridOperatorDecision: 'Décision de l’opérateur réseau',
				installationPartnerProvideQuotation: 'Devis',
				installationPartnerArrangeAppointment: 'Fixer un rendez-vous',
				installationPartnerCompleteInstallation: 'Exécution de l’installation'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Contrôle de l’installation',
				customerProvidePreCheckPreferences: 'Méthode de contact préférée',
				customerHandleUnfeasibleInstallation: 'Installation irréalisable',
				customerHandleGridOperatorRejection: 'Refusé par l’opérateur réseau',
				customerAcceptQuotation: 'Offre de travail supplémentaire',
				customerPerformPayment: 'Paiement',
				customerApproveInstallation: 'Confirmation de l’installation',
				installationPartnerEvaluateBasicAnswers: 'Contrôle de l’installation',
				installationPartnerExecuteRemotePreCheck: 'Contrôle final',
				installationPartnerRegisterAtGridOperator: 'Enregistrement de l’opérateur réseau',
				installationPartnerRevokeGridOperatorRegistration: 'Révocation de l’opérateur réseau',
				installationPartnerProvideGridOperatorDecision: 'Décision de l’opérateur réseau',
				installationPartnerProvideQuotation: 'Devis',
				installationPartnerArrangeAppointment: 'Fixer un rendez-vous',
				installationPartnerCompleteInstallation: 'Exécution de l’installation'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'L’objectif de la procuration est de nous permettre de nous enregistrer pour le raccordement au réseau avec votre consentement.',
						powerOfAttorney: {
							heading: 'Octroi d’une procuration pour l’enregistrement des consommateurs auprès de l’opérateur réseau',
							description: 'Indiquez-nous votre opérateur de réseau pour que l’enregistrement de votre Wallbox soit couronné de succès.',
							gridOperator: {
								question: 'Opérateur réseau ou fournisseur d’énergie'
							},
							powerOfAttorney: {
								question: 'J’autorise ABB et son partenaire d’installation à enregistrer la Wallbox auprès de l’opérateur réseau. Si l’installation n’est pas effectuée, la procuration expire automatiquement.'
							}
						},
						marketingConsent: {
							heading: 'Consentement à la communication avec le partenaire d’installation',
							installationPartnerMarketingConsent: {
								question: 'Par la présente, j’autorise ABB et son partenaire d’installation à me contacter à des fins de marketing.'
							}
						},
						surveyConsent: {
							heading: 'Faites-nous part de votre opinion',
							description: 'Aidez-nous à améliorer nos produits et services en participant à une courte enquête après avoir passé votre commande.',
							surveyConsent: {
								question: 'Par la présente, j’autorise ABB à m’inviter à participer à une enquête.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Merci du temps que vous y consacrerez !',
					message: 'Vous recevrez bientôt un e-mail de confirmation. Nous vérifions vos coordonnées et nous vous contacterons pour convenir avec vous des étapes suivantes.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Retour',
				nextStepButtonLabel: 'Étape suivante',
				submitButtonLabel: 'Envoyer des informations',
				channelSelection: {
					heading: 'Entretien personnel avec notre équipe de service',
					message: 'Pour que l’installation de votre nouvelle Wallbox se déroule sans encombre, veuillez nous faire part de vos préférences pour un entretien personnel avec notre équipe de service.\nLes étapes suivantes seront alors convenues sur la base des informations que vous avez déjà fournies.',
					subheading: 'Comment pouvons-nous vous contacter ?',
					explanation: 'Nous pouvons vous contacter de différentes manières.'
				},
				preferredTimesOfDay: {
					subHeading: 'Trouvons un moment pour un chat vidéo. Il aura lieu via {{channelName}}.',
					timeOfDay: {
						morning: 'Matin',
						noon: 'Heure du déjeuner',
						afternoon: 'Après-midi',
						evening: 'Soir'
					}
				},
				completionSuccess: {
					heading: 'C’est formidable que vous ayez du temps à nous consacrer !',
					message: 'Nous vous contacterons au plus près des disponibilités que vous avez choisies.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Nous sommes désolés, mais',
				message: 'l’installation par notre partenaire d’installation n’est actuellement pas possible. La raison en est la suivante :\n\n{{unfeasibleReason}}\n\nNous devons donc vous demander d’annuler votre commande.',
				submitButtonLabel: 'Envoyer des informations',
				unfeasibleReason: {
					noPotentialRailAvailable: 'Un rail potentiel, nécessaire à l’installation de la protection contre les surtensions, n’est pas disponible.',
					overvoltageProtectionUnfeasible: 'L’installation nécessaire d’un parasurtenseur n’est pas possible.',
					spaceForCableOutletMissing: 'Il n’y a pas suffisamment d’espace pour une prise de câble supplémentaire nécessaire.',
					houseConnectionUndersized: 'Le raccordement de l’habitation est tellement sous-dimensionné qu’il n’est pas possible d’installer la Wallbox.',
					noThreePhaseAvailable: 'Le système électrique existant ne fournit pas l’alimentation triphasée nécessaire.',
					outdatedMeterCabinet: 'L’armoire à compteurs ne répond pas aux exigences de mise en service de la Wallbox.',
					notTabCompliant: 'L’installation électrique n’est pas conforme aux conditions techniques de raccordement applicables.',
					other: 'Des raisons techniques s’opposent à l’installation et ne peuvent être éliminées au cours de l’installation.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Votre installation a été annulée avec succès.',
					message: 'Nous avons reçu votre annulation. Vous recevrez sous peu un e-mail de confirmation.'
				},
				completionCancelOrderSuccess: {
					heading: 'Votre commande a été annulée avec succès.',
					message: 'Nous avons reçu votre annulation. Vous recevrez sous peu un e-mail de confirmation.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Nous sommes désolés, mais votre opérateur réseau a refusé la mise en service de votre Wallbox',
				message: 'Malheureusement, l’alimentation électrique sur le lieu d’installation n’est pas suffisante pour le fonctionnement de votre Wallbox. Vous avez la possibilité de réduire la puissance de votre Wallbox ou d’annuler l’installation.',
				submitButtonLabel: 'Envoyer des informations',
				solutionDecreaseWallboxPower: {
					heading: 'Réduire la puissance de la Wallbox',
					description: 'La puissance de votre Wallbox sera réduite à la puissance correspondant à votre alimentation. La réduction de la consommation d’énergie aura un impact sur l’efficacité de la recharge.'
				},
				solutionCancelInstallation: {
					heading: 'Annuler l’installation',
					description: 'L’installation par notre partenaire d’installation est en cours d’annulation. La Wallbox vous sera envoyée directement. Les frais d’installation vous seront remboursés dans les prochains jours.'
				},
				solutionCancelOrder: {
					heading: 'Annulation de l’installation et Wallbox',
					description: 'Votre commande sera totalement annulée et vous serez remboursé de tous les frais dans les prochains jours.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Réduction de puissance de la Wallbox',
					message: 'ABB confirme par la présente l’installation de votre Wallbox sur la base d’une réduction de puissance conforme aux exigences de l’opérateur réseau.\n\nNous vous informerons prochainement des étapes suivantes.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Votre installation a été annulée avec succès.',
					message: 'Nous avons reçu votre annulation. Vous recevrez sous peu un e-mail de confirmation.'
				},
				completionCancelOrderSuccess: {
					heading: 'Votre commande a été annulée avec succès.',
					message: 'Nous avons reçu votre annulation. Vous recevrez sous peu un e-mail de confirmation.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Envoyer des informations',
				message: 'Veuillez confirmer la réussite de l’installation de votre Wallbox.',
				approval: {
					caption: 'Confirmation',
					label: 'Je confirme par la présente l’installation couronnée de succès de la Wallbox, ainsi que sa mise en service et son bon fonctionnement.'
				},
				completionSuccess: {
					heading: 'Merci !',
					message: 'L’installation de votre Wallbox est terminée. Vous recevrez prochainement tous les documents importants.\n\nMerci de votre confiance !'
				}
			},
			quotationAcceptance: {
				caption: 'Votre offre individuelle',
				message: 'Les kits d’installation suivants sont des services supplémentaires qui sont facturés séparément. Lorsque vous acceptez l’offre, vous êtes redirigé vers notre prestataire de services de paiement, la Deutsche Bank, afin que la commande puisse être traitée en notre nom.\n\nL’acceptation de cette offre n’entraîne pas d’autres frais et l’installation peut alors être effectuée.\n\nNotre partenaire d’installation prendra contact avec vous pour organiser un rendez-vous d’installation.',
				submitButtonLabel: 'Acceptation de l’offre sous réserve de paiement',
				cancelButtonLabel: 'Annuler',
				cancellationCaption: 'Dommage que nous ne puissions pas vous faire une offre intéressante',
				cancellationMessage: 'Veuillez indiquer comment vous souhaitez annuler votre commande.',
				submitCancellationButtonLabel: 'Envoyer des informations',
				recoverCancellationButtonLabel: 'Retour',
				conditions: {
					caption: 'Conditions de l’offre',
					message: 'Cette offre est valable tant que les conditions suivantes s’appliquent sur la base de vos informations.'
				},
				acceptance: {
					terms: 'les conditions générales',
					label: 'J’accepte par la présente {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'Votre commande a été effectuée avec succès.',
					message: 'Vous avez accepté notre offre et serez redirigé vers notre prestataire de services de paiement.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Votre installation a été annulée avec succès.',
					message: 'Nous avons reçu votre annulation. Vous recevrez sous peu un e-mail de confirmation.'
				},
				completionCancelOrderSuccess: {
					heading: 'Votre commande a été annulée avec succès.',
					message: 'Nous avons reçu votre annulation. Vous recevrez sous peu un e-mail de confirmation.'
				}
			},
			payment: {
				heading: 'Votre paiement',
				message: 'Vous serez redirigé vers notre prestataire de services de paiement.'
			}
		},
		documents: {
			heading: 'Documentation relative à votre commande',
			introduction: 'Aperçu du document'
		},
		order: {
			cancelledHint: 'Cette commande a été annulée avec succès.',
			doneHint: 'Cette commande a été exécutée avec succès.',
			heading: 'Votre commande',
			introduction: 'Veuillez trouver toutes les informations relatives à votre commande de Wallbox chez {{marketplaceName}} depuis {{orderDate}} avec le numéro de commande {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Faire maintenant'
			},
			information: {
				heading: {
					orderItems: 'Commande',
					quotation: 'Offre de services supplémentaires',
					appointment: 'Rendez-vous d’installation',
					shipping: 'Statut de l’expédition'
				},
				orderItems: {
					position: 'Position',
					description: 'Description',
					wallboxSerialNumbner: 'Série de Wallbox.',
					totalGrossPrice: 'Prix brut total',
					cancellationHint: 'Annulé le {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'L\'installation a été interrompue le {{cancellationDate}}.'
				},
				appointment: {
					heading: 'Installation'
				},
				shipping: {
					heading: 'Expédié par {{carrier}}',
					trackingNumberLabel: 'Numéro de référence',
					shippingAddressLabel: 'Adresse d\'expédition',
					trackingLinkLabel: 'Suivi des expéditions',
					itemsLabel: 'Articles d\'expédition',
					productCodeLabel: 'Code produit'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Annuler l’installation',
				description: 'L’installation par notre partenaire d’installation est en cours d’annulation. La Wallbox vous sera envoyée directement. Les frais d’installation vous seront remboursés dans les prochains jours.'
			},
			cancelOrder: {
				heading: 'Annulation de l’installation et Wallbox',
				description: 'Votre commande sera totalement annulée et vous serez remboursé de tous les frais dans les prochains jours.'
			}
		}
	}
};

export default literals;
