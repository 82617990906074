import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiDomain: {
		deltaQuotationItem: {
			additionalExpenses: 'Summe zusätzliche Kosten',
			vatInformation: 'Inklusive {{vatRate}}% MwSt'
		}
	}
};

export default literals;
