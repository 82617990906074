import './SpinnerDots.scss';

export function SpinnerDots() {
	return (
		<div className="spinner-dots">
			<div className="spinner-dots__animation">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
