import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Seleziona',
			pictureSelectPlaceholder: 'Seleziona immagine o scatta una foto',
			pictureSelectTrigger: 'Seleziona foto',
			pictureSelectHint: {
				default: 'Assicurati che non ci siano persone nella foto.',
				examplePicture: 'Utilizzare l\'immagine di esempio come guida e assicurarsi che non vi siano persone nell\'immagine.'
			},
			documentSelectPlaceholder: 'Seleziona un documento',
			documentSelectTrigger: 'Seleziona documento',
			selectedFileTooLarge: 'Il file selezionato è troppo grande per essere trasferito. Seleziona un file con dimensioni inferiori a {{maxFileSize}}.',
			answerTypeNotFound: 'Il tipo di risposta {{tipo}} è sconosciuto.',
			previousSegmentButtonLabel: 'Indietro',
			nextSegmentButtonLabel: 'Passo successivo',
			submitButtonLabel: 'Invia informazioni',
			validationErrors: {
				heading: 'Si sono verificati alcuni problemi con le tue risposte',
				message: 'Verifica le risposte per completare il questionario'
			}
		}
	}
};

export default literals;
