import { useEffect } from 'react';

import { EffectCallbackReturn } from '@abb-emobility/shared/react';

import { FetchMode } from '../DataProviderProps';

export const useDataProviderFetchInit = (fetchMode: FetchMode, initCallback: () => void, preInitCallback?: () => void) => {
	useEffect((): EffectCallbackReturn => {
		if (preInitCallback) {
			preInitCallback();
		}
		if (fetchMode === FetchMode.IMMEDIATE) {
			initCallback();
		}
	}, [fetchMode]);
};
