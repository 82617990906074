import { OrderImportB2cModel, OrderImportB2cModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class OrderImportB2cApiClient extends AbstractCrudApiClient<OrderImportB2cModel> {
	protected collectionControllerUri = 'order/bundled/b2c';
	protected paginationControllerUri = 'order/bundled/b2c';
	protected entityControllerUri = 'order/bundled/b2c/{id}/';
	protected entitySearchControllerUri = 'order/bundled/b2c';
	protected modelConverter = new OrderImportB2cModelConverter();
}
