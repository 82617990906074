import { CustomerModel, CustomerModelConverter } from '@abb-emobility/shared/domain-model';
import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

// TODO: Use common address model
export type UncoveredInstallationAddressModel = {
	zip: string,
	city: string,
	street: string,
	countryCode: IsoCountryCode,
	region?: string
};

export class UncoveredInstallationAddressModelConverter extends ModelConverter<UncoveredInstallationAddressModel> {

	override getCustomConversionFields(): Map<keyof UncoveredInstallationAddressModel, CustomConversion> {
		return new Map([[
			'countryCode', {
				toDto: (modelValue) => {
					return modelValue;
				},
				toModel: (dtoValue) => {
					return dtoValue?.toUpperCase() as IsoCountryCode;
				}
			}
		]]);
	}
}

export type UncoveredInstallationAddressPayloadModel = {
	readonly customer: CustomerModel,
	readonly uncoveredAddress: UncoveredInstallationAddressModel,
};

export class UncoveredInstallationAddressPayloadModelConverter extends ModelConverter<UncoveredInstallationAddressPayloadModel> {
	override getFieldConverterMapByModel() {
		return new Map<
			keyof UncoveredInstallationAddressPayloadModel,
			ModelConverterInterface<CustomerModel> | ModelConverterInterface<UncoveredInstallationAddressModel>>([
			['customer', new CustomerModelConverter()],
			['uncoveredAddress', new UncoveredInstallationAddressModelConverter()]
		]);
	}
}
