import { MouseEvent, ReactNode } from 'react';

import { AccessibleUrl } from '@abb-emobility/shared/domain-model-foundation';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { NotificationLevel } from './Notification.enums';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Notification.scss';

export type NotificationProps = {
	level?: NotificationLevel,
	message?: string,
	preventClickHandling?: boolean,
	picture?: {
		url: AccessibleUrl,
		title?: string
	}
};

export function Notification(props: NotificationProps) {
	const { level = NotificationLevel.INFO, message, preventClickHandling = false, picture = null } = props;

	// conditional classNames
	const notificationClassMap = {
		'notification': true,
		[`notification--${level}`]: level
	};

	const handleClick = (event: MouseEvent): void => {
		if (preventClickHandling) {
			event.stopPropagation();
			event.preventDefault();
		}
	};

	// render functions
	const renderIcon = () => {
		switch (level) {
			case 'error':
				return (
					<Icon name={IconIdentifier.INFO} />
				);
			case 'info':
				return (
					<Icon name={IconIdentifier.INFO} />
				);
			case 'success':
				return (
					<Icon name={IconIdentifier.CHECK} />
				);
			default:
				return null;
		}
	};

	const renderPicture = (): ReactNode => {
		if (picture === null) {
			return null;
		}
		return (
			<figure className="notification__message__picture">
				<img src={picture.url.value} alt={picture.title} title={picture.title} className="notification__message__picture__image" />
				<figcaption className="notification__message__picture__caption">{picture.title}</figcaption>
			</figure>
		);
	};

	return (
		<article className={buildCssClassStringFromClassMap(notificationClassMap)} onClick={handleClick}>
			<div className="notification__icon">
				{renderIcon()}
			</div>
			<section className="notification__message">
				{message}
				{renderPicture()}
			</section>
		</article>
	);
}
