export enum TaskActionStatus {
	IDLE = 'IDLE',
	COMPLETE_PENDING = 'COMPLETE_PENDING',
	ASSIGN_PENDING = 'ASSIGN_PENDING',
	COMPLETE_SUCCESS = 'COMPLETE_SUCCESS',
	ASSIGN_SUCCESS = 'ASSIGN_SUCCESS',
	COMPLETE_FAILED = 'COMPLETE_FAILED',
	ASSIGN_FAILED = 'ASSIGN_FAILED'
}

export const taskActionStatusPendingGroup = [TaskActionStatus.COMPLETE_PENDING, TaskActionStatus.ASSIGN_PENDING];

export const taskActionStatusSuccessGroup = [TaskActionStatus.COMPLETE_SUCCESS, TaskActionStatus.ASSIGN_SUCCESS];

export const taskActionStatusFailedGroup = [TaskActionStatus.COMPLETE_FAILED, TaskActionStatus.ASSIGN_FAILED];
