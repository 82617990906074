import { CustomConversion, ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import {
	DateRange,
	ModelPrimaryKey,
	TimestampFieldNames,
	Timestamp,
	DateRangeFieldNames,
	ArrayOfTimestampFieldNames,
	ArrayOfDateRangeFieldNames,
	UrlFieldNames,
	UploadFileFieldNames,
	ArrayOfUrlFieldNames,
	ArrayOfUploadFileFieldNames,
	Url,
	UploadFile,
	DownloadFile,
	DownloadFileFieldNames,
	ArrayOfDownloadFileFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

export type FooModel = {
	readonly id: ModelPrimaryKey,
	name: string,
	someCode: string,
	dateOfBirth: Timestamp,
	dates?: Array<Timestamp>,
	readonly range: DateRange,
	url: Url,
	readonly urls: Array<Url>,
	readonly uploadFile: UploadFile,
	uploadFiles: Array<UploadFile>,
	downloadFile: DownloadFile,
	readonly downloadFiles: Array<DownloadFile>
};

export class FooModelConverter extends ModelConverter<FooModel> {

	override getCustomConversionFields(): Map<keyof FooModel, CustomConversion> {
		return new Map([
			['someCode', {
				toModel: (dtoValue) => {
					return dtoValue?.toUpperCase();
				},
				toDto: (modelValue) => {
					return modelValue?.toLowerCase();
				}
			}]
		]);
	}

	override getTimestampFields(): Array<TimestampFieldNames<FooModel> | ArrayOfTimestampFieldNames<FooModel>> {
		return ['dateOfBirth', 'dates'];
	}

	override getDateRangeFields(): Array<DateRangeFieldNames<FooModel> | ArrayOfDateRangeFieldNames<FooModel>> {
		return ['range'];
	}

	override getUrlFields(): Array<UrlFieldNames<FooModel> | ArrayOfUrlFieldNames<FooModel>> {
		return ['url', 'urls'];
	}

	override getUploadFileFields(): Array<UploadFileFieldNames<FooModel> | ArrayOfUploadFileFieldNames<FooModel>> {
		return ['uploadFile', 'uploadFiles'];
	}

	override getDownloadFileFields(): Array<DownloadFileFieldNames<FooModel> | ArrayOfDownloadFileFieldNames<FooModel>> {
		return ['downloadFile', 'downloadFiles'];
	}

}
