import { ReactNode } from 'react';

import { Icon, IconIdentifier } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerTradeAppointmentModel } from '@abb-emobility/oms/domain-model';
import { DaynameFormat, useL10n } from '@abb-emobility/shared/localization-provider';

import './TradeAppointment.scss';

export type TradeAppointmentProps = {
	tradeAppointment: CustomerTradeAppointmentModel
};

export function TradeAppointment(props: TradeAppointmentProps) {

	const { tradeAppointment } = props;

	const l10n = useL10n();

	const renderPeriods = (): ReactNode => {
		return tradeAppointment.periods.map((period, index) => {
			return (
				<div className="trade-appointment__periods__info__period" key={index}>
					<span className="trade-appointment__periods__info__period__day">
						{l10n.formatTimestampDayname(period.start, undefined, DaynameFormat.SHORT)}, {l10n.formatTimestampDate(period.start)}
					</span>
					<span className="trade-appointment__periods__info__period__time">
						{l10n.formatTimestampTime(period.start)} – {l10n.formatTimestampTime(period.end)}
					</span>
				</div>
			);
		});
	};

	return (
		<article className="trade-appointment">
			<h1 className="trade-appointment__trade">
				{tradeAppointment.trade}
			</h1>
			<section className="trade-appointment__periods">
				<div className="trade-appointment__periods__icon">
					<Icon name={IconIdentifier.CALENDAR} />
				</div>
				<div className="trade-appointment__periods__info">
					{renderPeriods()}
				</div>
			</section>
		</article>
	);
}
