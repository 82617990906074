import { isArray } from '@abb-emobility/shared/util';

import {
	MultiValueFilterComparator,
	MultiValueFilterCriteriaRule,
	SingleValueFilterComparator,
	SingleValueFilterCriteriaRule
} from './FilterCriteria';

const singleValueFilterCriteriaRuleComparators = [...Object.values(SingleValueFilterComparator), undefined];
const multiValueFilterCriteriaRuleComparators = [...Object.values(MultiValueFilterComparator), undefined];

export const isSingleValueFilterCriteriaRule = (filterCriteriaRule: unknown): filterCriteriaRule is SingleValueFilterCriteriaRule<unknown> => {
	return singleValueFilterCriteriaRuleComparators.includes((filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).comparator)
		&& (filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).property !== undefined
		&& (filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).value !== undefined
		&& !isArray((filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).value);
};

export const isMultiValueFilterCriteriaRule = (filterCriteriaRule: unknown): filterCriteriaRule is MultiValueFilterCriteriaRule<unknown> => {
	return multiValueFilterCriteriaRuleComparators.includes((filterCriteriaRule as MultiValueFilterCriteriaRule<unknown>).comparator)
		&& (filterCriteriaRule as MultiValueFilterCriteriaRule<unknown>).property !== undefined
		&& (filterCriteriaRule as MultiValueFilterCriteriaRule<unknown>).value !== undefined
		&& isArray((filterCriteriaRule as SingleValueFilterCriteriaRule<unknown>).value);
};
