import { ReactElement } from 'react';

import {
	ButtonGhost,
	ErrorFeedback,
	ErrorFeedbackAction,
	SpinnerCable
} from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderDocumentCollectionDataProvider } from '@abb-emobility/oms/data-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ErrorHandler } from '@abb-emobility/shared/error';
import { useDialogue } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { Nullable } from '@abb-emobility/shared/util';

import { useBuildPageTitle } from '../../hooks/PageTitle.hook';
import { CustomerServiceDialogue } from '../dialogue/customer-service-dialogue/CustomerServiceDialogue';
import { OrderDownloadDocumentList } from '../order-download-document-list/OrderDownloadDocumentList';

import './OrderDocumentCollection.scss';

export type OrderDocumentCollectionProps = {
	orderId: ModelPrimaryKey
};

export function OrderDocumentCollection(props: OrderDocumentCollectionProps) {

	const l10n = useL10n();
	const dialogue = useDialogue();

	usePageTitle(useBuildPageTitle('omsCustomerApp.pageTitle.documents'));

	const { orderId } = props;

	const handleError = (): Nullable<ReactElement> => {
		const reloadAction: ErrorFeedbackAction = {
			label: l10n.translate('omsCustomerApp.error.generic.resolveAction.label'),
			onInvoke: (): void => {
				window.location.reload();
			}
		};

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				actions={[reloadAction]}
				key="OmsGenericError"
			/>
		);
	};

	const handleHelpRequest = (): void => {
		dialogue.present(() => {
			return (<CustomerServiceDialogue orderId={orderId} />);
		});
	};

	return (
		<CustomerOrderDocumentCollectionDataProvider
			pendingComponent={SpinnerCable}
		>
			<article className="order-document-collection">
				<header className="order-document-collection__header">
					<h1 className="order-document-collection__header__heading">
						{l10n.translate('omsCustomerApp.documents.heading')}
					</h1>
					<p className="order-document-collection__header__introduction">
						{l10n.translate('omsCustomerApp.documents.introduction')}
					</p>
				</header>
				<main className="order-document-collection__main">
					<ErrorHandler errorComponent={handleError}>
						<OrderDownloadDocumentList />
					</ErrorHandler>
				</main>
				<footer className="order-document-collection__footer">
					<ButtonGhost
						label={l10n.translate('omsCustomerApp.customerServiceDialogue.dialogueTriggerLabel')}
						onClick={handleHelpRequest}
					/>
				</footer>
			</article>
		</CustomerOrderDocumentCollectionDataProvider>
	);
}
