import React from 'react';

import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	createTaskEntityDataProviderValue,
	EntityDataProviderProps,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense,
	useDataProviderWebsocketHandler
} from '@abb-emobility/shared/data-provider-foundation';
import { TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';
import { AnyTaskModel, TaskStatus } from '@abb-emobility/usertask/domain-model';
import { WebsocketEvent, WebsocketMessage, WebsocketTopic } from '@abb-emobility/usertask/websocket';

import { anyTaskEntityData } from './AnyTaskEntityData';
import { AnyTaskEntityStore, anyTaskEntityStoreAccessors, anyTaskEntityStoreName } from './AnyTaskEntitySlice';

export function AnyTaskEntityDataProvider(props: EntityDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		primaryKey,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const env = useEnv();
	const auth = useAuth();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_USER_TASK_MANAGEMENT_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createTaskEntityDataProviderValue<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskEntityStore>(
		anyTaskEntityStoreName,
		anyTaskEntityStoreAccessors,
		primaryKey,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('usertaskDataProvider.error.taskFetchMessage')));

	useDataProviderFetchInit(
		fetchMode,
		() => dataProviderValue.fetch(forceFetch),
		() => dataProviderValue.resolveActionStatus()
	);

	const handleMessage = (message: WebsocketMessage): void => {
		switch (message.event) {
			case WebsocketEvent.TASK_ASSIGNED:
				dataProviderValue.fetch(true);
				break;
			case WebsocketEvent.TASK_COMPLETED:
				dataProviderValue.setTaskStatus(TaskStatus.DONE);
				break;
		}
	};
	useDataProviderWebsocketHandler(anyTaskEntityStoreName, dataProviderValue, WebsocketTopic.INSTALLATION_PARTNER_TASK, handleMessage);

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<anyTaskEntityData.Provider value={dataProviderValue}>
			{props.children}
		</anyTaskEntityData.Provider>
	);

}
