import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { DeutscheBankCheckoutApiClient } from './DeutscheBankCheckoutApiClient';
import { DeutscheBankCheckoutApiClientInterface } from './DeutscheBankCheckoutApiClientInterface';
import { DeutscheBankCheckoutApiClientMock } from './DeutscheBankCheckoutApiClientMock';

export class DeutscheBankCheckoutApiClientFactory {

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): DeutscheBankCheckoutApiClientInterface {
		if (process.env['NX_SHARED_PSP_API_MOCKED'] === 'true') {
			return new DeutscheBankCheckoutApiClientMock(baseUrl, jsonWebToken);
		}
		return new DeutscheBankCheckoutApiClient(baseUrl, jsonWebToken);
	}

}
