import { InstallationPartnerOrderModel, InstallationPartnerOrderShipmentModel } from '@abb-emobility/oms/domain-model';
import { addressModelMock, customerModelMock } from '@abb-emobility/shared/api-integration';
import { InstallationType, OrderState, ShippingState } from '@abb-emobility/shared/domain-model';
import { createTimestampDtoFromDate, Dto, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { installationPartnerOrderLineItemModelMockCollection } from './InstallationPartnerOrderLineItemModel.mock';
import { installationPartnerOrderProgressMock } from './InstallationPartnerOrderProgressModel.mock';

const installationPartnerOrderShipmentModelMock01: Dto<InstallationPartnerOrderShipmentModel> = {
	id: '1',
	shippingState: ShippingState.PENDING,
	trackingNumber: 'AKwL3e7dQY',
	shippingTrackingUrl: {
		accessible: true,
		value: 'https://fiege.com'
	}
};

const installationPartnerOrderShipmentModelMock02: Dto<InstallationPartnerOrderShipmentModel> = {
	id: '2',
	estimatedDeliveryDate: createTimestampDtoFromDate(new Date('2022-01-02T14:30:12.345Z')),
	shippingState: ShippingState.DELIVERED,
	trackingNumber: 'AKwL3e7dQY',
	shippingTrackingUrl: {
		accessible: true,
		value: 'https://fiege.com'
	}
};

const installationPartnerOrderModelMockTemplate: Dto<InstallationPartnerOrderModel> = {
	id: '1',
	marketplaceOrderId: '1',
	state: OrderState.ON_SCHEDULE,
	progress: installationPartnerOrderProgressMock,
	customer: customerModelMock,
	installationType: InstallationType.CANCELLED,
	orderDate: createTimestampDtoFromDate(new Date('2022-01-01T16:30:12.345Z')),
	installationAddress: addressModelMock,
	orderLineItems: installationPartnerOrderLineItemModelMockCollection(),
	installationDate: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	estimatedCompletionDate: createTimestampDtoFromDate(new Date('2022-01-01T16:30:12.345Z')),
	shipments: [installationPartnerOrderShipmentModelMock01, installationPartnerOrderShipmentModelMock02],
	throttleWallboxPowerConsumption: true,
	wallboxSerialNumber: '352645856846'
};

export const installationPartnerOrderModelMock = (id: ModelPrimaryKey = '1'): Dto<InstallationPartnerOrderModel> => {
	return { ...installationPartnerOrderModelMockTemplate, id };
};
