import { createContext } from 'react';

import { JsonTransportValue } from '@abb-emobility/shared/api-integration-foundation';
import { AppError } from '@abb-emobility/shared/error';

import {
	RemoveEventListenerCallback,
	WebsocketMessageEventListener,
	WebsocketSimpleEventListener
} from '../websocket/WebsocketEvents';
import { WebsocketState } from '../websocket/WebsocketState';

export type WebsocketContextValue<Topic extends string, Message extends JsonTransportValue> = {
	provisioned: boolean,
	getState(): WebsocketState,
	isOpened(): boolean,
	subscribe(topic: Topic, listener: WebsocketMessageEventListener<Message>, listenerId?: string): string,
	unsubscribe(topic: Topic, subscriptionId: string): void,
	addOpenListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback,
	addCloseListener(listener: WebsocketSimpleEventListener): RemoveEventListenerCallback
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const websocketContext = createContext<WebsocketContextValue<string, any>>({
	provisioned: false,
	getState(): WebsocketState {
		throw new AppError('WebsocketProvider missing');
	},
	isOpened(): boolean {
		throw new AppError('WebsocketProvider missing');
	},
	subscribe(): string {
		throw new AppError('WebsocketProvider missing');
	},
	unsubscribe(): void {
		throw new AppError('WebsocketProvider missing');
	},
	addOpenListener(): RemoveEventListenerCallback {
		throw new AppError('WebsocketProvider missing');
	},
	addCloseListener(): RemoveEventListenerCallback {
		throw new AppError('WebsocketProvider missing');
	}
});
