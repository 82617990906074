import { createContext, MutableRefObject, ReactNode, useContext, useRef, useState, useTransition } from 'react';
import { v4 as uuid } from 'uuid';

import { requestIdleCallbackPolyfill } from '@abb-emobility/shared/browser';
import { AppError } from '@abb-emobility/shared/error';

type ExpandableEventListener = () => void;
type ExpandableToggleEventListener = (expanded: boolean) => void;
type RemoveEventListenerClosure = () => void;

type ExpandableContextValue = {
	isExpanded: () => boolean,
	expand: () => void,
	collapse: () => void,
	toggle: () => void,
	addExpandListener(listener: ExpandableEventListener): RemoveEventListenerClosure,
	addCollapseListener(listener: ExpandableEventListener): RemoveEventListenerClosure,
	addToggleListener(listener: ExpandableToggleEventListener): RemoveEventListenerClosure
};

const expandableContext = createContext<ExpandableContextValue>({
	isExpanded: (): boolean => {
		throw new AppError('No expandable context provided');
	},
	expand: (): void => {
		throw new AppError('No expandable context provided');
	},
	collapse: (): void => {
		throw new AppError('No expandable context provided');
	},
	toggle: (): void => {
		throw new AppError('No expandable context provided');
	},
	addExpandListener: (_listener: ExpandableEventListener): RemoveEventListenerClosure => {
		throw new AppError('No expandable context provided');
	},
	addCollapseListener: (_listener: ExpandableEventListener): RemoveEventListenerClosure => {
		throw new AppError('No expandable context provided');
	},
	addToggleListener: (_listener: ExpandableToggleEventListener): RemoveEventListenerClosure => {
		throw new AppError('No expandable context provided');
	}
});

export type ExpandableContextProviderProps = {
	children: ReactNode,
	expanded?: boolean
};

export const ExpandableProvider = (props: ExpandableContextProviderProps) => {

	const { children } = props;
	const [expanded, setExpandedState] = useState<boolean>(props.expanded ?? false);
	const [_transitionPending, startTransition] = useTransition();

	const expandListenerMap = useRef<Map<string, ExpandableEventListener>>(new Map());
	const collapseListenerMap = useRef<Map<string, ExpandableEventListener>>(new Map());
	const toggleListenerMap = useRef<Map<string, ExpandableToggleEventListener>>(new Map());

	const createRemoveEventClosure = (
		map: MutableRefObject<Map<string, ExpandableEventListener | ExpandableToggleEventListener>>,
		key: string
	): RemoveEventListenerClosure => {
		return (): void => {
			map.current.delete(key);
		};
	};

	const setExpanded = (expanded: boolean): void => {
		startTransition(() => {
			setExpandedState(expanded);
		});
	};

	const isExpanded = (): boolean => expanded;

	const expand = () => {
		setExpanded(true);
		requestIdleCallbackPolyfill(() => {
			for (const expandListener of expandListenerMap.current.values()) {
				expandListener();
			}
		}, { timeout: 100 });
	};

	const collapse = () => {
		setExpanded(false);
		requestIdleCallbackPolyfill(() => {
			for (const collapseListener of collapseListenerMap.current.values()) {
				collapseListener();
			}
		}, { timeout: 100 });
	};

	const toggle = (): void => {
		const newValue = !expanded;
		setExpanded(newValue);
		requestIdleCallbackPolyfill(() => {
			for (const toggleListener of toggleListenerMap.current.values()) {
				toggleListener(newValue);
			}
		}, { timeout: 100 });
	};

	const addExpandListener = (listener: ExpandableEventListener): RemoveEventListenerClosure => {
		const key = uuid();
		expandListenerMap.current.set(key, listener);
		return createRemoveEventClosure(expandListenerMap, key);
	};

	const addCollapseListener = (listener: ExpandableEventListener): RemoveEventListenerClosure => {
		const key = uuid();
		collapseListenerMap.current.set(key, listener);
		return createRemoveEventClosure(collapseListenerMap, key);
	};

	const addToggleListener = (listener: ExpandableToggleEventListener): RemoveEventListenerClosure => {
		const key = uuid();
		toggleListenerMap.current.set(key, listener);
		return createRemoveEventClosure(toggleListenerMap, key);
	};

	return (
		<expandableContext.Provider value={{
			isExpanded,
			expand,
			collapse,
			toggle,
			addExpandListener,
			addCollapseListener,
			addToggleListener
		}}>
			{children}
		</expandableContext.Provider>
	);
};

export const useExpandable = () => {
	return useContext(expandableContext);
};
