import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, Dto, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerOrderInformationFoundationModel } from './foundation/CustomerOrderInformationFoundationModel';
import { CustomerOrderInformationType } from './foundation/CustomerOrderInformationType';
import {
	CustomerOrderInformationAppointmentInformationModel,
	CustomerOrderInformationAppointmentInformationModelConverter
} from './order-information/CustomerOrderInformationAppointmentInformationModel';
import {
	CustomerOrderInformationApprovalInformationModelConverter,
	CustomerOrderInformationOrderItemsInformationModel
} from './order-information/CustomerOrderInformationOrderItemsInformationModel';
import {
	CustomerOrderInformationQuotationInformationModel,
	CustomerOrderInformationQuotationInformationModelConverter
} from './order-information/CustomerOrderInformationQuotationInformationModel';
import {
	CustomerOrderInformationShippingInformationModel,
	CustomerOrderInformationShippingInformationModelConverter
} from './order-information/CustomerOrderInformationShippingInformationModel';

export type AnyCustomerOrderInformationModel =
	CustomerOrderInformationOrderItemsModel |
	CustomerOrderInformationQuotationModel |
	CustomerOrderInformationAppointmentModel |
	CustomerOrderInformationShippingModel;

export type AnyCustomerOrderInformationPayloadModel<Model extends AnyCustomerOrderInformationModel> = Model['payload'];

export type CustomerOrderInformationOrderItemsModel = {
	type: CustomerOrderInformationType.ORDER_ITEMS,
	payload: CustomerOrderInformationOrderItemsInformationModel
} & CustomerOrderInformationFoundationModel;

export type CustomerOrderInformationQuotationModel = {
	type: CustomerOrderInformationType.QUOTATION,
	payload: CustomerOrderInformationQuotationInformationModel
} & CustomerOrderInformationFoundationModel;

export type CustomerOrderInformationAppointmentModel = {
	type: CustomerOrderInformationType.APPOINTMENT,
	payload: CustomerOrderInformationAppointmentInformationModel
} & CustomerOrderInformationFoundationModel;

export type CustomerOrderInformationShippingModel = {
	type: CustomerOrderInformationType.SHIPPING,
	payload: CustomerOrderInformationShippingInformationModel
} & CustomerOrderInformationFoundationModel;

export class AnyCustomerOrderInformationModelConverter extends ModelConverter<AnyCustomerOrderInformationModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerOrderInformationModel> | ArrayOfTimestampFieldNames<AnyCustomerOrderInformationModel>> {
		return ['createdAt'];
	}

	override getFieldConverterMapByModel(model: AnyCustomerOrderInformationModel | Dto<AnyCustomerOrderInformationModel>) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const converterMap = new Map<keyof AnyCustomerOrderInformationModel, any>();
		switch (model.type) {
			case CustomerOrderInformationType.ORDER_ITEMS:
				converterMap.set('payload', new CustomerOrderInformationApprovalInformationModelConverter());
				break;
			case CustomerOrderInformationType.QUOTATION:
				converterMap.set('payload', new CustomerOrderInformationQuotationInformationModelConverter());
				break;
			case CustomerOrderInformationType.APPOINTMENT:
				converterMap.set('payload', new CustomerOrderInformationAppointmentInformationModelConverter());
				break;
			case CustomerOrderInformationType.SHIPPING:
				converterMap.set('payload', new CustomerOrderInformationShippingInformationModelConverter());
				break;
		}
		return converterMap;
	}

}
