import React, { FunctionComponent } from 'react';

import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type DataProviderPendingProps = Record<string, never>;

export enum FetchMode {
	// Fetch starts with rendering
	IMMEDIATE = 'IMMEDIATE',
	// Fetch is started manually
	LAZY = 'LAZY'
}

type DataProviderFoundation = {
	fetchMode?: FetchMode,
	suspense?: boolean,
	pendingComponent?: FunctionComponent<DataProviderPendingProps>,
	children: React.ReactNode
};

export type CollectionDataProviderProps<Model> = {
	forceFetch?: boolean,
	sortCriteria?: SortCriteria<Model>,
	filterCriteria?: FilterCriteria<Model>
} & DataProviderFoundation;

export type EntityDataProviderProps = {
	forceFetch?: boolean,
	primaryKey: ModelPrimaryKey
} & DataProviderFoundation;

export type EntitySearchDataProviderProps<Model> = {
	filterCriteria: FilterCriteria<Model>
} & DataProviderFoundation;

export type CreationDataProviderProps = {
	mockApi?: boolean,
	children: React.ReactNode
};
