import { GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfUploadFileFieldNames, UploadFile, UploadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type PreCheckRegistrationGridOperatorPayloadModel = {
	readonly gridOperator: GridOperatorModel,
	readonly wallboxPower: number,
	documents?: Array<UploadFile>,
	registrationUnnecessary?: boolean
} & TaskPayloadFoundationModel;

export class PreCheckRegistrationGridOperatorPayloadModelConverter extends ModelConverter<PreCheckRegistrationGridOperatorPayloadModel> {

	override getUploadFileFields(): Array<UploadFileFieldNames<PreCheckRegistrationGridOperatorPayloadModel> | ArrayOfUploadFileFieldNames<PreCheckRegistrationGridOperatorPayloadModel>> {
		return ['documents'];
	}

	override getFieldConverterMapByModel(): Map<keyof PreCheckRegistrationGridOperatorPayloadModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}
}

export type PreCheckRegistrationGridOperatorEscalationPayloadModel =
	PreCheckRegistrationGridOperatorPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

export class PreCheckRegistrationGridOperatorEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<PreCheckRegistrationGridOperatorEscalationPayloadModel> {
	private preCheckModelConverter = new PreCheckRegistrationGridOperatorPayloadModelConverter();

	override getUploadFileFields(): Array<UploadFileFieldNames<PreCheckRegistrationGridOperatorEscalationPayloadModel> | ArrayOfUploadFileFieldNames<PreCheckRegistrationGridOperatorEscalationPayloadModel>> {
		return this.preCheckModelConverter.getUploadFileFields();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof PreCheckRegistrationGridOperatorEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.preCheckModelConverter.getFieldConverterMapByModel())
		]);
	}
}
