import { useEffect, useState } from 'react';

import { Download, DownloadProps } from '@abb-emobility/oms/customer-ui-primitive';
import { UrlResolver } from '@abb-emobility/shared/api-integration-foundation';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { startDownload } from '@abb-emobility/shared/browser';
import { Url } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { Nullable, Optional } from '@abb-emobility/shared/util';

export type DownloadCardProps = {
	url: Url
} & Omit<DownloadProps, 'href' | 'download' | 'target' | 'disabled'>;

export function DownloadCard(props: DownloadCardProps) {

	const { url, ...restProps } = props;

	const auth = useAuth();

	const documentStoreApiBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_API_BASE_URL'])
		.getOrThrow(new AppError('No document store API base URL provided'));
	const documentStoreMediaBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_MEDIA_BASE_URL'])
		.getOrThrow(new AppError('No document store media base URL provided'));

	const [downloadUrl, setDownloadUrl] = useState<Nullable<Url>>(null);

	useEffect(() => {
		const urlResolver = new UrlResolver();
		const resolveUrl = async (): Promise<void> => {
			if (downloadUrl === null) {
				return;
			}
			try {
				const resolvedUrl = await urlResolver.resolve(documentStoreApiBaseUrl, documentStoreMediaBaseUrl, downloadUrl, auth.getToken().get());
				startDownload(resolvedUrl);
			} catch (e) {
				console.error(e);
			} finally {
				setDownloadUrl(null);
			}
		};
		void resolveUrl();
		return () => {
			urlResolver.abort();
		};
	}, [downloadUrl, auth]);

	const handleClick = () => {
		setDownloadUrl(url);
	};

	return (
		<Download
			onClick={handleClick}
			disabled={downloadUrl !== null}
			{...restProps}
		/>
	);
}
