/* eslint-disable @typescript-eslint/naming-convention */
import { WordArray } from 'crypto-es/lib/core';
import { Base64 } from 'crypto-es/lib/enc-base64';
import { SHA256 } from 'crypto-es/lib/sha256';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import { AuthenticationFailedError, TimeoutError } from '@abb-emobility/shared/error';
import { readLocalStorage, removeLocalStorage, writeLocalStorage } from '@abb-emobility/shared/local-storage';
import { Timeout } from '@abb-emobility/shared/util';

import { KeycloakApiClientInterface, TokenResponseModel } from './KeycloakApiClientInterface';

export class KeycloakApiClient implements KeycloakApiClientInterface {

	private readonly VERIFIER_LOCAL_STORAGE_KEY = 'oauthVerfifier';

	login(baseUrl: string, realm: string, clientId: string, redirectUrl: string) {
		const challenge = this.base64Encode(SHA256(this.getVerifier()));
		const url = new URL(this.buildRealmUrl(baseUrl, realm) + '/protocol/openid-connect/auth');
		url.searchParams.set('scope', 'openid');
		url.searchParams.set('response_type', 'code');
		url.searchParams.set('client_id', clientId);
		url.searchParams.set('code_challenge', challenge);
		url.searchParams.set('code_challenge_method', 'S256');
		url.searchParams.set('redirect_uri', redirectUrl);
		window.location.replace(url.toString());
	}

	logout(baseUrl: string, realm: string) {
		window.location.replace(this.buildRealmUrl(baseUrl, realm) + '/protocol/openid-connect/logout');
	}

	async requestToken<AdditionalReponseFields = unknown>(
		baseUrl: string,
		realm: string,
		clientId: string,
		redirectUrl: string,
		oauthCode: string
	): Promise<TokenResponseModel & AdditionalReponseFields> {
		const abortController = new AbortController();
		const request = new Request(
			this.buildRealmUrl(baseUrl, realm) + '/protocol/openid-connect/token',
			{
				signal: abortController.signal,
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams({
					grant_type: 'authorization_code',
					client_id: clientId ?? '',
					code_verifier: this.getVerifier(),
					code: oauthCode ?? '',
					redirect_uri: redirectUrl ?? '',
					scope: 'openid'
				}).toString()
			}
		);

		removeLocalStorage(this.VERIFIER_LOCAL_STORAGE_KEY);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new TimeoutError('Request timeout'), (): void => {
			abortController.abort();
		});
		if (!response.ok) {
			throw new AuthenticationFailedError('Token request failed');
		}

		let responseBody: TokenResponseModel & AdditionalReponseFields;
		try {
			responseBody = await response.json();
		} catch (e) {
			throw new AuthenticationFailedError('Token response invalid');
		}

		let decodedToken;
		try {
			decodedToken = jwtDecode<JwtPayload & AdditionalReponseFields>(responseBody.access_token);
		} catch (e) {
			console.warn(e);
			decodedToken = {};
		}

		return {
			...responseBody,
			...decodedToken
		};
	}

	async refreshToken(
		baseUrl: string,
		realm: string,
		clientId: string,
		refreshToken: string
	): Promise<TokenResponseModel> {
		const abortController = new AbortController();
		const request = new Request(
			this.buildRealmUrl(baseUrl, realm) + '/protocol/openid-connect/token',
			{
				signal: abortController.signal,
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams({
					grant_type: 'refresh_token',
					client_id: clientId ?? '',
					refresh_token: refreshToken
				}).toString()
			}
		);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new TimeoutError('Request timeout'), (): void => {
			abortController.abort();
		});
		if (!response.ok) {
			throw new AuthenticationFailedError('Token refresh failed');
		}

		let responseBody: TokenResponseModel;
		try {
			responseBody = await response.json();
		} catch (e) {
			throw new AuthenticationFailedError('Token response invalid');
		}

		return responseBody;
	}

	private buildRealmUrl(baseUrl: string, realm: string): string {
		return baseUrl + 'auth/realms/' + realm;
	}

	private getVerifier(): string {
		return readLocalStorage<string>(this.VERIFIER_LOCAL_STORAGE_KEY).getOrCompute(() => {
			const verifier = this.base64Encode(WordArray.random(32));
			writeLocalStorage(this.VERIFIER_LOCAL_STORAGE_KEY, verifier);
			return verifier;
		});
	}

	private base64Encode(wordArray: WordArray): string {
		return wordArray.toString(Base64)
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=/g, '');
	}

}
