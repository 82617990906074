import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
	FilterCriteria,
	HypermediaEntityResponse,
	PaginatedCollectionResponse,
	SortCriteria,
	TaskAssignResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey, TaskCollectionModel } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { TaskCollectionStore } from './TaskCollectionStore';
import { FetchStatus } from '../FetchStatus';
import { ModelFilter } from '../ModelFilter';
import { ModelLimit } from '../ModelLimit';
import { ModelSort } from '../ModelSort';
import { TaskActionStatus } from '../TaskActionStatus';
import { AsyncThunkConfig } from '../ThunkApiConfig';

// Initial store
export const createInitialTaskCollectionStore = <TaskModel extends TaskCollectionModel>() => {
	return {
		models: [] as Array<TaskModel>,
		fetchStatus: FetchStatus.IDLE,
		fetchEntityStatus: {},
		lastFetchError: null,
		currentPage: null,
		maxPages: null,
		sortCriteria: null,
		filterCriteria: null,
		actionStatus: TaskActionStatus.IDLE,
		lastActionError: null,
		createdEntity: null
	} as TaskCollectionStore<TaskModel>;
};

// Action error identifier
export enum TaskCollectionErrorAction {
	FETCH = 'FETCH',
	ASSIGN = 'ASSIGN'
}

// Slice creator
export const createTaskCollectionSlice = <TaskModel extends TaskCollectionModel, ThunkApiConfig extends AsyncThunkConfig>(
	storeName: string,
	fetchAll: AsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, sortCriteria?: SortCriteria<TaskModel>, filterCriteria?: FilterCriteria<TaskModel>, forceFetch?: boolean }, ThunkApiConfig>,
	refetchAll: AsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> }, ThunkApiConfig>,
	fetchAllSorted: AsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, sortCriteria?: SortCriteria<TaskModel>, forceFetch?: boolean }, ThunkApiConfig>,
	fetchAllFiltered: AsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, filterCriteria?: FilterCriteria<TaskModel>, forceFetch?: boolean }, ThunkApiConfig>,
	fetchNext: AsyncThunk<PaginatedCollectionResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> }, ThunkApiConfig>,
	fetchEntity: AsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, forceFetch?: boolean }, ThunkApiConfig>,
	refetchEntity: AsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey }, ThunkApiConfig>,
	assign: AsyncThunk<Nullable<TaskAssignResponse>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, model: TaskModel, candidateGroupIds: Array<ModelPrimaryKey> }, ThunkApiConfig>
) => {
	return createSlice({
		name: storeName,
		initialState: createInitialTaskCollectionStore<TaskModel>(),
		// Regular synchronous reducers
		reducers: {
			resetStore(store) {
				Object.assign(store, createInitialTaskCollectionStore<TaskModel>());
			},
			resolveActionStatus(store) {
				store.actionStatus = TaskActionStatus.IDLE;
			},
			resolveFetchStatus(store) {
				store.fetchStatus = FetchStatus.IDLE;
			},
			setTaskStatus(store, action: PayloadAction<{ id: ModelPrimaryKey, status: string }>) {
				const index = store.models.findIndex((entry): boolean => {
					return entry.id === action.payload.id;
				});
				if (index === -1) {
					return;
				}
				store.models[index].status = action.payload.status;
			}
		},
		// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
		// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
		// handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
		// https://github.com/immerjs/immer.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		extraReducers: {
			[String(fetchAll.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(fetchAll.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<TaskModel>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.sortCriteria = action.meta.arg.sortCriteria ?? null;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.filterCriteria = action.meta.arg.filterCriteria ?? null;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchAll.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(refetchAll.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(refetchAll.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<TaskModel>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(refetchAll.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchAllSorted.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(fetchAllSorted.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<TaskModel>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.sortCriteria = action.meta.arg.sortCriteria ?? null;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchAllSorted.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchAllFiltered.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			},
			[String(fetchAllFiltered.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<TaskModel>>>) => {
				state.models = action.payload.items;
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				state.filterCriteria = action.meta.arg.filterCriteria ?? null;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchAllFiltered.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchNext.pending)]: (state) => {
				state.fetchStatus = FetchStatus.PAGING_PENDING;
			},
			[String(fetchNext.fulfilled)]: (state, action: PayloadAction<Draft<PaginatedCollectionResponse<TaskModel>>>) => {
				state.models = [...state.models, ...action.payload.items];
				state.currentPage = action.payload.currentPage;
				state.maxPages = action.payload.maxPages;
				state.fetchStatus = FetchStatus.SUCCESS;
			},
			[String(fetchNext.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchStatus = FetchStatus.FAILED;
			},
			[String(fetchEntity.pending)]: (state, action) => {
				state.fetchEntityStatus[action.meta.arg.id] = FetchStatus.PENDING;
			},
			[String(fetchEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<TaskModel>>>) => {
				if (action.payload.item !== null) {
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload.item?.id;
					});
					if (index >= 0) {
						state.models[index] = action.payload.item;
					} else {
						state.models.push(action.payload.item);
					}
					state.fetchEntityStatus[action.payload.item?.id] = FetchStatus.SUCCESS;
				}
			},
			[String(fetchEntity.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchEntityStatus[action.meta.arg.id] = FetchStatus.FAILED;
			},
			[String(refetchEntity.pending)]: (state, action) => {
				state.fetchEntityStatus[action.meta.arg.id] = FetchStatus.REFRESH_PENDING;
			},
			[String(refetchEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<TaskModel>>>) => {
				if (action.payload.item !== null) {
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload.item?.id;
					});
					if (index >= 0) {
						state.models[index] = action.payload.item;
					} else {
						state.models.push(action.payload.item);
					}
					state.fetchEntityStatus[action.payload.item?.id] = FetchStatus.SUCCESS;
				}
			},
			[String(refetchEntity.rejected)]: (state, action) => {
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = TaskCollectionErrorAction.FETCH;
				state.lastFetchError = lastFetchError;
				state.fetchEntityStatus[action.meta.arg.id] = FetchStatus.FAILED;
			},
			[String(assign.pending)]: (state) => {
				state.actionStatus = TaskActionStatus.ASSIGN_PENDING;
			},
			[String(assign.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<TaskAssignResponse>>>) => {
				if (action.payload !== null) {
					const index = state.models.findIndex((entry): boolean => {
						return entry.id === action.payload?.id;
					});
					if (index >= 0) {
						const anyTaskModel = state.models[index];
						if (anyTaskModel !== null) {
							const result = {
								...anyTaskModel,
								candidateGroupIds: action.payload.candidateGroupIds
							};
							state.models[index] = result;
						}
					}
					state.actionStatus = TaskActionStatus.ASSIGN_SUCCESS;
				}
			},
			[String(assign.rejected)]: (state, action) => {
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskCollectionErrorAction.ASSIGN;
				state.lastActionError = lastActionError;
				state.actionStatus = TaskActionStatus.ASSIGN_FAILED;
			}
		}
	});
};

// Selector creators
export const createTaskSelectCollection = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>>(storeName: string) => {
	return (
		filter?: ModelFilter<TaskModel>,
		sort?: ModelSort<TaskModel>,
		limit?: ModelLimit
	): (rootStore: Store) => ReadonlyArray<TaskModel> => {
		return (rootStore): ReadonlyArray<TaskModel> => {
			let modelCollection = rootStore[storeName].models;
			if (filter) {
				modelCollection = modelCollection.filter(filter);
			}
			if (sort) {
				modelCollection = [...modelCollection].sort(sort);
			}
			if (limit) {
				const offset = limit.offset ?? 0;
				modelCollection = modelCollection.slice(offset, offset + limit.limit);
			}
			return modelCollection;
		};
	};
};

export const createTaskCountCollection = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>>(storeName: string) => {
	return (filter?: ModelFilter<TaskModel>): (rootStore: Store) => number => {
		return (rootStore): number => {
			let modelCollection = rootStore[storeName].models;
			if (filter) {
				modelCollection = modelCollection.filter(filter as ModelFilter<TaskModel>);
			}
			return modelCollection.length;
		};
	};
};

export const createTaskSelectCollectionEntity = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>>(storeName: string) => {
	return (id: ModelPrimaryKey): (rootStore: Store) => ReadonlyOptional<TaskModel> => {
		return (rootStore): ReadonlyOptional<TaskModel> => {
			const modelEntity = rootStore[storeName].models.find((model): boolean => {
				return model.id === id;
			});
			return new ReadonlyOptional(modelEntity);
		};
	};
};

export const createTaskSelectCreated = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyOptional<TaskModel> => {
		return (rootStore): ReadonlyOptional<TaskModel> => {
			return new ReadonlyOptional(rootStore[storeName]?.createdEntity);
		};
	};
};

export const createTaskCollectionStoreSize = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>>(storeName: string) => {
	return (): (rootStore: Store) => Readonly<number> => {
		return (rootStore): Readonly<number> => {
			return rootStore[storeName].models.length;
		};
	};
};

export const createTaskCollectionStoreEntryIds = <TaskModel extends TaskCollectionModel, Store extends Record<string, TaskCollectionStore<TaskModel>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyArray<ModelPrimaryKey> => {
		return (rootStore): ReadonlyArray<ModelPrimaryKey> => {
			return rootStore[storeName].models.map<ModelPrimaryKey>((model) => {
				return model.id;
			});
		};
	};
};
