import { ReactNode } from 'react';

import { Header } from '@abb-emobility/oms/customer-ui-primitive';

import { useTheme } from '../theme/Theme.context';

import './ViewLayout.scss';

export type ViewLayoutProps = {
	children?: ReactNode
};

export function ViewLayout(props: ViewLayoutProps) {

	const { children } = props;

	const theme = useTheme();

	return (
		<article className="view-layout">
			<header className="view-layout__header">
				<div className="view-layout__header__dimension">
					<Header caption={theme.caption()} />
				</div>
			</header>
			<main className="view-layout__main">
				<div className="view-layout__main__dimension">
					{children}
				</div>
			</main>
		</article>
	);
}
