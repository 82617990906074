import { ReactNode, useState } from 'react';

import {
	ButtonBar,
	ButtonBarSection,
	ButtonBarSectionAlign,
	ButtonPrimary,
	Form,
	Separator,
	SpinnerCable,
	SuccessFeeback
} from '@abb-emobility/oms/customer-ui-primitive';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { InstallationUnfeasibleReason } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	PreCheckInstallationUnfeasiblePayloadModel,
	PreCheckInstallationUnfeasibleSolution,
	PreCheckInstallationUnfeasibleTaskModel
} from '@abb-emobility/usertask/domain-model';

import { CancellationDialogue, CancellationDialogueSelection } from '../../../cancellation-dialogue/CancellationDialogue';

export type TaskPreCheckInstallationUnfeasibleProps = {
	task: PreCheckInstallationUnfeasibleTaskModel
};

export function TaskPreCheckInstallationUnfeasible(props: TaskPreCheckInstallationUnfeasibleProps) {

	const { task } = props;

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const actionStatus = anyTaskEntityData.queryActionStatus();
	const [taskPayload, setTaskPayload] = useState<PreCheckInstallationUnfeasiblePayloadModel>(task.payload);

	const handleCancellationSelect = (selection: Nullable<CancellationDialogueSelection>) => {
		const userSelection = selection ? selection as PreCheckInstallationUnfeasibleSolution : undefined;
		setTaskPayload((payload): PreCheckInstallationUnfeasiblePayloadModel => {
			return { ...payload, solution: userSelection };
		});
	};

	const handleSubmit = (): void => {
		anyTaskEntityData.complete(task, taskPayload);
	};

	const renderPayload = (): ReactNode => {
		switch (actionStatus) {
			case TaskActionStatus.COMPLETE_PENDING:
				return (<SpinnerCable />);
			case TaskActionStatus.COMPLETE_SUCCESS: {
				let successHeadingLiteral = '';
				let successMessageLiteral = '';
				switch (taskPayload.solution) {
					case PreCheckInstallationUnfeasibleSolution.CANCEL_INSTALLATION:
						successHeadingLiteral = 'omsCustomerApp.task.preCheckInstallationUnfeasible.completionCancelInstallationSuccess.heading';
						successMessageLiteral = 'omsCustomerApp.task.preCheckInstallationUnfeasible.completionCancelInstallationSuccess.message';
						break;
					case PreCheckInstallationUnfeasibleSolution.CANCEL_ORDER:
						successHeadingLiteral = 'omsCustomerApp.task.preCheckInstallationUnfeasible.completionCancelOrderSuccess.heading';
						successMessageLiteral = 'omsCustomerApp.task.preCheckInstallationUnfeasible.completionCancelOrderSuccess.message';
						break;
				}
				return (
					<SuccessFeeback
						heading={l10n.translate(successHeadingLiteral)}
						message={l10n.translate(successMessageLiteral)}
					/>
				);
			}
			default: {
				const unfeasibleReason = taskPayload.unfeasibleReason ?? InstallationUnfeasibleReason.OTHER;
				const unfeasibleReasonDisplay = l10n.translate(
					l10nLiteralFromEnumValue(unfeasibleReason, 'omsCustomerApp.task.preCheckInstallationUnfeasible.unfeasibleReason')
				);
				return (
					<Form onSubmit={handleSubmit}>
						<CancellationDialogue
							heading={l10n.translate('omsCustomerApp.task.preCheckInstallationUnfeasible.caption')}
							description={l10n.translate('omsCustomerApp.task.preCheckInstallationUnfeasible.message', new Map([
								['unfeasibleReason', unfeasibleReasonDisplay]
							]))}
							selected={taskPayload.solution}
							onSelect={handleCancellationSelect}
							installationCancellable={taskPayload.installationCancelable ?? false}
						/>
						<Separator />
						<ButtonBar>
							<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
								<ButtonPrimary
									type="submit"
									label={l10n.translate('omsCustomerApp.task.preCheckInstallationUnfeasible.submitButtonLabel')}
									disabled={taskPayload.solution === undefined}
								/>
							</ButtonBarSection>
						</ButtonBar>
					</Form>
				);
			}
		}
	};

	return (
		<>
			{renderPayload()}
		</>
	);

}
