import './Header.scss';

import { ReactComponent as AbbLogo } from './assets/abb-logo.svg';

export type HeaderProps = {
	caption: string
};

export function Header(props: HeaderProps) {

	const { caption } = props;

	return (
		<div className="header">
			<span className="header__logo">
				<AbbLogo />
			</span>
			<span className="header__caption">
				{caption}
			</span>
		</div>
	);
}
