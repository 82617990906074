import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { IconIdentifier } from './Icon.enums';

import './Icon.scss';

export type IconProps = {
	name: IconIdentifier,
	size?: 'small'
};

export function Icon(props: IconProps) {
	const { name, size } = props;

	const iconClassMap = {
		'icon': true,
		[`icon--${size}`]: size
	};


	return (
		<div className={buildCssClassStringFromClassMap(iconClassMap)}>
			<svg>
				<use href={`/assets/lib-abb-ui-primitive/icons/icon.svg#${name}`} />
			</svg>
		</div>
	);
}
