import { SimpleError } from '@abb-emobility/shared/error';

export class FetchError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('FetchError', message, status, timestamp, previousError);
	}
}

export class MutateError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('MutateError', message, status, timestamp, previousError);
	}
}

export class CreateError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('CreateError', message, status, timestamp, previousError);
	}
}

export class DeleteError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('DeleteError', message, status, timestamp, previousError);
	}
}

export class TaskAssignError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('TaskAssignError', message, status, timestamp, previousError);
	}
}

export class TaskCompleteError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('TaskCompleteError', message, status, timestamp, previousError);
	}
}
