import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiDomain: {
		deltaQuotationItem: {
			additionalExpenses: 'Total extra kostnad',
			vatInformation: 'Inklusive moms ({{vatRate}} %)'
		}
	}
};

export default literals;
