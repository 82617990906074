import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
// TODO: Remove phone number, replace name, remove address
export type CustomerModel = {
	readonly id: ModelPrimaryKey,
	readonly name: string,
	readonly address: string,
	readonly phoneNumber: string,
	readonly mailAddress: string,
	readonly companyName?: string
};

export class CustomerModelConverter extends ModelConverter<CustomerModel> {
}
