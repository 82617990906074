import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
	HypermediaEntityResponse,
	HypermediaLink,
	TaskAssignResponse,
	TaskCompleteResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey, Mutable, TaskEntityModel, TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { TaskEntityStore } from './TaskEntityStore';
import { FetchStatus } from '../FetchStatus';
import { HypermediaLinkFilter } from '../HypermediaLinkFilter';
import { HypermediaLinkSort } from '../HypermediaLinkSort';
import { TaskActionStatus } from '../TaskActionStatus';
import { AsyncThunkConfig } from '../ThunkApiConfig';

// Initial store
export const createInitialTaskEntityStore = <TaskModel extends TaskEntityModel>() => {
	return {
		scopes: {}
	} as TaskEntityStore<TaskModel>;
};

// Scope loader
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createInitialTaskEntityStoreEntry = (store: any, id: ModelPrimaryKey): void => {
	if (store.scopes?.[id] === undefined) {
		store.scopes[id] = {
			model: null,
			hypermediaLinks: [],
			fetchStatus: FetchStatus.IDLE,
			lastFetchError: null,
			actionStatus: TaskActionStatus.IDLE,
			lastActionError: null
		};
	}
};

// Action error identifier
export enum TaskEntityErrorAction {
	FETCH = 'FETCH',
	COMPLETE = 'COMPLETE',
	ASSIGN = 'ASSIGN'
}

// Slice creator
export const createTaskEntitySlice = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, ThunkApiConfig extends AsyncThunkConfig>(
	storeName: string,
	fetch: AsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, forceFetch?: boolean }, ThunkApiConfig>,
	complete: AsyncThunk<Nullable<TaskCompleteResponse<TaskModel>>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, model: TaskModel, payload: Mutable<Payload> }, ThunkApiConfig>,
	assign: AsyncThunk<Nullable<TaskAssignResponse>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, model: TaskModel, candidateGroupIds: Array<ModelPrimaryKey> }, ThunkApiConfig>
) => {
	return createSlice({
		name: storeName,
		initialState: createInitialTaskEntityStore<TaskModel>(),
		// Regular synchronous reducers
		reducers: {
			resetStore(store) {
				Object.assign(store, createInitialTaskEntityStore<TaskModel>());
			},
			resolveActionStatus(store, action: PayloadAction<ModelPrimaryKey>) {
				createInitialTaskEntityStoreEntry(store, action.payload);
				store.scopes[action.payload].actionStatus = TaskActionStatus.IDLE;
			},
			resolveFetchStatus(store, action: PayloadAction<ModelPrimaryKey>) {
				createInitialTaskEntityStoreEntry(store, action.payload);
				store.scopes[action.payload].fetchStatus = FetchStatus.IDLE;
			},
			setTaskStatus(store, action: PayloadAction<{ id: ModelPrimaryKey, status: string }>) {
				if (!store.scopes?.[action.payload.id]?.model) {
					return;
				}
				const model = store.scopes[action.payload.id].model;
				store.scopes[action.payload.id].model = {
					...model,
					status: action.payload.status
				} as Draft<TaskModel>;
			}
		},
		// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
		// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
		// handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
		// https://github.com/immerjs/immer.
		extraReducers: {
			[String(fetch.pending)]: (state, action) => {
				createInitialTaskEntityStoreEntry(state, action.meta.arg.id);
				// state.scopes[action.meta.arg.id] is Proxy instance in this use case, Object.assign acts like an unproxy function
				const entityState = Object.assign({}, state.scopes?.[action.meta.arg.id]);
				const pendingFetchStatus = entityState.fetchStatus === FetchStatus.IDLE ? FetchStatus.PENDING : FetchStatus.REFRESH_PENDING;
				state.scopes[action.meta.arg.id].fetchStatus = pendingFetchStatus;
			},
			[String(fetch.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<TaskModel>>>) => {
				if (action.payload.item !== null) {
					createInitialTaskEntityStoreEntry(state, action.payload.item.id);
					state.scopes[action.payload.item.id].model = action.payload.item;
					state.scopes[action.payload.item.id].hypermediaLinks = action.payload.hypermediaLinks;
					state.scopes[action.payload.item.id].fetchStatus = FetchStatus.SUCCESS;
				}
			},
			[String(fetch.rejected)]: (state, action) => {
				createInitialTaskEntityStoreEntry(state, action.meta.arg.id);
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskEntityErrorAction.FETCH;
				state.scopes[action.meta.arg.id].lastFetchError = lastActionError;
				state.scopes[action.meta.arg.id].fetchStatus = FetchStatus.FAILED;
			},
			[String(complete.pending)]: (state, action) => {
				createInitialTaskEntityStoreEntry(state, action.meta.arg.id);
				state.scopes[action.meta.arg.id].actionStatus = TaskActionStatus.COMPLETE_PENDING;
			},
			[String(complete.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<TaskCompleteResponse<TaskModel>>>>) => {
				if (action.payload !== null) {
					createInitialTaskEntityStoreEntry(state, action.payload.id);
					const anyTaskModel = state.scopes[action.payload.id].model;
					if (anyTaskModel !== null) {
						const result = {
							...anyTaskModel,
							status: action.payload.status,
							payload: action.payload.payload
						};
						state.scopes[action.payload.id].model = result;
					}
					state.scopes[action.payload.id].actionStatus = TaskActionStatus.COMPLETE_SUCCESS;
				}
			},
			[String(complete.rejected)]: (state, action) => {
				createInitialTaskEntityStoreEntry(state, action.meta.arg.id);
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskEntityErrorAction.COMPLETE;
				state.scopes[action.meta.arg.id].lastActionError = lastActionError;
				state.scopes[action.meta.arg.id].actionStatus = TaskActionStatus.COMPLETE_FAILED;
			},
			[String(assign.pending)]: (state, action) => {
				createInitialTaskEntityStoreEntry(state, action.meta.arg.id);
				state.scopes[action.meta.arg.id].actionStatus = TaskActionStatus.ASSIGN_PENDING;
			},
			[String(assign.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<TaskAssignResponse>>>) => {
				if (action.payload !== null) {
					createInitialTaskEntityStoreEntry(state, action.payload.id);
					const anyTaskModel = state.scopes[action.payload.id].model;
					if (anyTaskModel !== null) {
						const result = {
							...anyTaskModel,
							candidateGroupIds: action.payload.candidateGroupIds
						};
						state.scopes[action.payload.id].model = result;
					}
					state.scopes[action.payload.id].actionStatus = TaskActionStatus.ASSIGN_SUCCESS;
				}
			},
			[String(assign.rejected)]: (state, action) => {
				createInitialTaskEntityStoreEntry(state, action.meta.arg.id);
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = TaskEntityErrorAction.ASSIGN;
				state.scopes[action.meta.arg.id].lastActionError = lastActionError;
				state.scopes[action.meta.arg.id].actionStatus = TaskActionStatus.ASSIGN_FAILED;
			}
		}
	});
};

// Selector creators
export const createTaskSelectEntity = <Model extends TaskEntityModel, Store extends Record<string, TaskEntityStore<Model>>>(storeName: string) => {
	return (id: ModelPrimaryKey): (rootStore: Store) => ReadonlyOptional<Model> => {
		return (rootStore): ReadonlyOptional<Model> => {
			return new ReadonlyOptional(rootStore[storeName].scopes?.[id]?.model ?? null);
		};
	};
};

export const createTaskSelectHypermediaLinks = <Model extends TaskEntityModel, Store extends Record<string, TaskEntityStore<Model>>>(storeName: string) => {
	return (id: ModelPrimaryKey, sort?: HypermediaLinkSort, filter?: HypermediaLinkFilter): (rootStore: Store) => ReadonlyArray<HypermediaLink> => {
		return (rootStore): ReadonlyArray<HypermediaLink> => {
			let hypermediaLinks = [...(rootStore[storeName].scopes?.[id]?.hypermediaLinks ?? [])];
			if ((filter ?? null) !== null) {
				hypermediaLinks = hypermediaLinks.filter(filter as HypermediaLinkFilter);
			}
			if ((sort ?? null) !== null) {
				hypermediaLinks = [...hypermediaLinks].sort(sort);
			}
			return hypermediaLinks;
		};
	};
};

export const createTaskEntityStoreSize = <Model extends TaskEntityModel, Store extends Record<string, TaskEntityStore<Model>>>(storeName: string) => {
	return (): (rootStore: Store) => Readonly<number> => {
		return (rootStore): Readonly<number> => {
			return Object.values(rootStore[storeName].scopes)
				.filter((scope) => {
					return (scope?.model ?? null) !== null;
				})
				.length;
		};
	};
};

export const createTaskEntityStoreEntryIds = <Model extends TaskEntityModel, Store extends Record<string, TaskEntityStore<Model>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyArray<ModelPrimaryKey> => {
		return (rootStore): ReadonlyArray<ModelPrimaryKey> => {
			return Object.values(rootStore[storeName].scopes)
				.map((scope) => {
					return scope.model;
				})
				.filter((model): model is Model => {
					return model !== null;
				})
				.map((model) => {
					return model.id;
				});
		};
	};
};
