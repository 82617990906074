import { useContext } from 'react';

import { JsonTransportValue } from '@abb-emobility/shared/api-integration-foundation';
import { Nullable } from '@abb-emobility/shared/util';

import { websocketContext, WebsocketContextValue } from './WebsocketContext';

export const useWebsocket = <Topic extends string, Message extends JsonTransportValue>(): Nullable<WebsocketContextValue<Topic, Message>> => {
	const websocketContextValue = useContext(websocketContext);
	if (!websocketContextValue.provisioned) {
		return null;
	}
	return websocketContextValue;
};
