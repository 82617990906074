import { TaskEntityApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskModel } from '@abb-emobility/usertask/domain-model';

import { AnyTaskApiClient } from './AnyTaskApiClient';
import { AnyTaskApiClientMock } from './AnyTaskApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnyTaskApiClientInterface extends TaskEntityApiClient<AnyTaskModel, TaskPayloadModel<AnyTaskModel>> {
}

export class AnyTaskApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): AnyTaskApiClientInterface {
		if (process.env.NX_TASK_API_MOCKED === 'true') {
			const apiClientMock = new AnyTaskApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock(baseUrl);
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new AnyTaskApiClient().init(baseUrl, jsonWebToken);
	}

}
