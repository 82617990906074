import { GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel = {
	readonly gridOperator: GridOperatorModel,
	readonly files?: Array<DownloadFile>
};

export class InstallationPartnerOrderInformationGridOperatorRegistrationInformationModelConverter extends ModelConverter<InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel> {
	override getDownloadFileFields(): Array<DownloadFileFieldNames<InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel> | ArrayOfDownloadFileFieldNames<InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel>> {
		return ['files'];
	}

	override getFieldConverterMapByModel(): Map<keyof InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}

}
