import { InstallationTradeModel } from "@abb-emobility/shared/domain-model";

export const installationTradeModelMock01: InstallationTradeModel = {
	id: '1',
	name: 'Earthworks'
};

export const installationTradeModelMock02: InstallationTradeModel = {
	id: '2',
	name: 'Electrical'
};

export const installationTradeModelMock03: InstallationTradeModel = {
	id: '3',
	name: 'Masonry'
};
