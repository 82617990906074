import {
	AnyInstallationPartnerOrderInformationApiClientFactory,
	AnyInstallationPartnerOrderInformationApiClientInterface
} from '@abb-emobility/oms/api-integration';
import { AnyInstallationPartnerOrderInformationModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const installationPartnerOrderInformationEntityStoreName = 'installation-partner-order-information-entity-crud';
export type InstallationPartnerOrderInformationEntityStore = { [installationPartnerOrderInformationEntityStoreName]: CrudEntityStore<AnyInstallationPartnerOrderInformationModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyInstallationPartnerOrderInformationApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<AnyInstallationPartnerOrderInformationModel, InstallationPartnerOrderInformationEntityStore, AnyInstallationPartnerOrderInformationApiClientInterface>(installationPartnerOrderInformationEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyInstallationPartnerOrderInformationModel, AnyInstallationPartnerOrderInformationApiClientInterface>(installationPartnerOrderInformationEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyInstallationPartnerOrderInformationModel, AnyInstallationPartnerOrderInformationApiClientInterface>(installationPartnerOrderInformationEntityStoreName, createApiClient);

// Slice definition
export const installationPartnerOrderInformationEntitySlice = createCrudEntitySlice<AnyInstallationPartnerOrderInformationModel, ThunkApiConfig>(installationPartnerOrderInformationEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = installationPartnerOrderInformationEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<AnyInstallationPartnerOrderInformationModel, InstallationPartnerOrderInformationEntityStore>(installationPartnerOrderInformationEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<AnyInstallationPartnerOrderInformationModel, InstallationPartnerOrderInformationEntityStore>(installationPartnerOrderInformationEntityStoreName);
const storeSize = createCrudEntityStoreSize<AnyInstallationPartnerOrderInformationModel, InstallationPartnerOrderInformationEntityStore>(installationPartnerOrderInformationEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<AnyInstallationPartnerOrderInformationModel, InstallationPartnerOrderInformationEntityStore>(installationPartnerOrderInformationEntityStoreName);

// Export the store accessors to be used by the data provider value
export const installationPartnerOrderInformationEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<AnyInstallationPartnerOrderInformationModel, InstallationPartnerOrderInformationEntityStore>;
