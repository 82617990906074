import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'Wallbox ABB',
				labelCancel: 'Cancelar',
				titlePaymentData: 'Pagamento',
				labelAmount: 'Quantidade de esforço e material de instalação adicional',
				labelDate: 'Data',
				labelMerchant: 'Comerciante',
				labelIban: 'O seu IBAN'
			}
		},
		application: {
			pageTitle: 'Serviço de instalação de wallbox ABB',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Encomenda {{marketplaceId}}',
			task: 'Encomenda {{marketplaceId}} - {{taskType}}',
			documents: 'Documentos',
			payment: 'Pagamento',
			notFound: 'Não encontrado',
			static: {
				terms: 'Termos e condições gerais',
				privacy: 'Privacidade',
				imprint: 'Impressão'
			}
		},
		footer: {
			termsLabel: 'Termos e condições gerais',
			privacyLabel: 'Privacidade',
			imprintLabel: 'Impressão'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Introduzir código',
			otpInputMessage: 'Enviámos um código de 6 dígitos para o seu telemóvel.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Enviar novamente',
			otpInputLabel: 'Introduza o seu código',
			otpInputPlaceholder: 'Código de autenticação',
			otpSubmitButtonLabel: 'Continuar'
		},
		error: {
			otpAuthInitFailed: 'A autenticação falhou',
			otpAuthReceiveTokenFailed: 'Código inválido',
			taskTypeNotFound: 'O tipo de tarefa solicitado {{taskType}} é desconhecido.',
			taskNotFound: 'A tarefa solicitada não foi encontrada',
			orderNotFound: 'A nota de encomenda solicitada não foi encontrada',
			documentNotFound: 'O documento solicitado não foi encontrado',
			validation: {
				heading: 'Dados inválidos',
				message: 'Os dados que enviou não são válidos.'
			},
			generic: {
				heading: 'Ocorreu um erro',
				message: 'Ocorreu um erro que não pode ser recuperado.',
				resolveAction: {
					label: 'Recarregar'
				}
			},
			notFound: {
				heading: 'Não encontrado',
				message: 'O recurso solicitado não foi encontrado'
			},
			taskCompleteMessage: 'Durante a conclusão da tarefa, ocorreu um erro.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'Preciso de ajuda',
			dialogueDismissLabel: 'Cancelar',
			dialogueCaption: 'Serviço de apoio ao cliente',
			phone: {
				heading: 'Pode contactar-nos por telefone a qualquer momento'
			},
			mail: {
				heading: 'Ou enviar-nos uma mensagem de correio eletrónico',
				subject: 'A minha encomenda da Wallbox #{{marketplaceId}}',
				subjectStatic: 'A minha encomenda de wallbox'
			},
			infoPage: {
				heading: 'Ou pode visitar a nossa oferta de informação',
				label: 'Respostas às perguntas mais frequentes'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Desconhecido',
			allDoneStepLabel: 'Pronto',
			upcomingLabelPrefix: 'Seguinte: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'de'
		},
		payment: {
			success: {
				heading: 'O seu pagamento foi efetuado com sucesso.',
				message: 'Agora não há nada a impedir a instalação da Wallbox. O nosso parceiro de instalação entrará em contacto consigo em breve para organizar uma marcação de instalação.\n\nObrigado pela sua confiança!'
			},
			failed: {
				heading: 'O seu pagamento falhou!',
				retryLabel: 'Tente novamente'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Verificação da instalação',
				customerProvidePreCheckPreferences: 'Indicar o método de contacto preferido',
				customerHandleUnfeasibleInstallation: 'Instalação inviável',
				customerHandleGridOperatorRejection: 'Rejeitada pelo operador de rede',
				customerAcceptQuotation: 'Oferta de trabalho adicional',
				customerPerformPayment: 'Pagamento',
				customerPayment: 'O seu pagamento',
				customerApproveInstallation: 'Confirmação da instalação',
				installationPartnerEvaluateBasicAnswers: 'Verificação da instalação',
				installationPartnerExecuteRemotePreCheck: 'Verificação final',
				installationPartnerRegisterAtGridOperator: 'Registo do operador de rede',
				installationPartnerRevokeGridOperatorRegistration: 'Revogação do registo do operador de rede',
				installationPartnerProvideGridOperatorDecision: 'Decisão do operador de rede',
				installationPartnerProvideQuotation: 'Orçamento',
				installationPartnerArrangeAppointment: 'Efetuar marcação',
				installationPartnerCompleteInstallation: 'Execução da instalação'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Verificação da instalação',
				customerProvidePreCheckPreferences: 'Método de contacto preferido',
				customerHandleUnfeasibleInstallation: 'Instalação inviável',
				customerHandleGridOperatorRejection: 'Rejeitada pelo operador de rede',
				customerAcceptQuotation: 'Oferta de trabalho adicional',
				customerPerformPayment: 'Pagamento',
				customerPayment: 'Pagamento',
				customerApproveInstallation: 'Confirmação da instalação',
				installationPartnerEvaluateBasicAnswers: 'Verificação da instalação',
				installationPartnerExecuteRemotePreCheck: 'Verificação final',
				installationPartnerRegisterAtGridOperator: 'Registo do operador de rede',
				installationPartnerRevokeGridOperatorRegistration: 'Revogação do registo do operador de rede',
				installationPartnerProvideGridOperatorDecision: 'Decisão do operador de rede',
				installationPartnerProvideQuotation: 'Orçamento',
				installationPartnerArrangeAppointment: 'Efetuar marcação',
				installationPartnerCompleteInstallation: 'Execução da instalação'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'O objetivo da procuração é permitir-nos registar a ligação à rede com o seu consentimento.',
						powerOfAttorney: {
							heading: 'Concessão de uma procuração para o registo dos consumidores junto do operador da rede',
							description: 'Indique-nos o seu operador de rede para que o registo da sua Wallbox seja bem-sucedido.',
							gridOperator: {
								question: 'Operador de rede ou fornecedor de energia'
							},
							powerOfAttorney: {
								question: 'Autorizo a ABB e o seu parceiro de instalação a registar a Wallbox junto do operador da rede. Se a instalação não for efetuada, a procuração caduca automaticamente.'
							}
						},
						marketingConsent: {
							heading: 'Consentimento para a comunicação com o parceiro de instalação',
							installationPartnerMarketingConsent: {
								question: 'Autorizo a ABB e o seu parceiro de instalação a contactarem-me para fins de marketing.'
							}
						},
						surveyConsent: {
							heading: 'Partilhe a sua opinião connosco',
							description: 'Ajude-nos a melhorar os nossos produtos e serviços, participando num breve inquérito após a conclusão da sua encomenda.',
							surveyConsent: {
								question: 'Autorizo a ABB a convidar-me a participar num inquérito.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Obrigado pelo seu tempo.',
					message: 'Receberá em breve uma confirmação por correio eletrónico. Estamos a verificar os seus dados e entraremos em contacto consigo para combinar os próximos passos.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Voltar',
				nextStepButtonLabel: 'Próximo passo',
				submitButtonLabel: 'Enviar informações',
				channelSelection: {
					heading: 'Conversa pessoal com a nossa equipa de assistência',
					message: 'Para que a instalação da sua nova Wallbox decorra sem problemas, informe-nos das suas preferências para uma conversa pessoal com a nossa equipa de assistência.\nOs passos seguintes serão então acordados com base nas informações que já forneceu.',
					subheading: 'Como podemos contactá-lo?',
					explanation: 'Existem várias formas de o contactar.'
				},
				preferredTimesOfDay: {
					subHeading: 'Vamos encontrar uma altura para uma conversa por vídeo. Será efetuada através de {{channelName}}.',
					timeOfDay: {
						morning: 'Manhã',
						noon: 'Hora do almoço',
						afternoon: 'Tarde',
						evening: 'Noite'
					}
				},
				completionSuccess: {
					heading: 'É ótimo que tenha tempo para nós!',
					message: 'Entraremos em contacto assim que as disponibilidades que selecionou estiverem próximas.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Lamentamos muito, mas',
				message: 'a instalação pelo nosso parceiro de instalação não é atualmente possível. A razão é:\n\n{{unfeasibleReasong}}\n\nPor conseguinte, temos de lhe pedir que cancele a sua encomenda.',
				submitButtonLabel: 'Enviar informações',
				unfeasibleReason: {
					noPotentialRailAvailable: 'Não está disponível uma calha de potencial, que é necessária para instalar a proteção contra sobretensões.',
					overvoltageProtectionUnfeasible: 'A instalação necessária de um protetor contra sobretensões não é possível.',
					spaceForCableOutletMissing: 'Não há espaço suficiente para uma tomada de cabo adicional necessária.',
					houseConnectionUndersized: 'A ligação doméstica é de tal forma subdimensionada que não é possível instalar a Wallbox.',
					noThreePhaseAvailable: 'O sistema elétrico existente não fornece a alimentação trifásica necessária.',
					outdatedMeterCabinet: 'O armário de contadores não cumpre os requisitos para a colocação em funcionamento da Wallbox.',
					notTabCompliant: 'A instalação elétrica não está em conformidade com as condições técnicas de ligação aplicáveis.',
					other: 'A instalação é dificultada por razões técnicas que não podem ser eliminadas no decurso da instalação.'
				},
				completionCancelInstallationSuccess: {
					heading: 'A sua instalação foi cancelada com sucesso.',
					message: 'Recebemos o seu cancelamento. Receberá em breve uma confirmação por correio eletrónico.'
				},
				completionCancelOrderSuccess: {
					heading: 'A nota de encomenda foi cancelada com sucesso.',
					message: 'Recebemos o seu cancelamento. Receberá em breve uma confirmação por correio eletrónico.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Lamentamos muito, mas o seu operador de rede recusou a colocação em funcionamento da Wallbox',
				message: 'Infelizmente, a alimentação de corrente no local de instalação não é suficiente para a operação da Wallbox. O utilizador tem a possibilidade de reduzir a potência da Wallbox ou de cancelar a instalação.',
				submitButtonLabel: 'Enviar informações',
				solutionDecreaseWallboxPower: {
					heading: 'Reduzir a potência da Wallbox',
					description: 'A potência da Wallbox será reduzida para a potência adequada à sua fonte de alimentação. A redução do consumo de energia terá um impacto na eficiência do carregamento.'
				},
				solutionCancelInstallation: {
					heading: 'Cancelar instalação',
					description: 'A instalação pelo nosso parceiro de instalação está a ser cancelada. A Wallbox ser-lhe-á enviada diretamente. As despesas de instalação ser-lhe-ão reembolsadas nos próximos dias.'
				},
				solutionCancelOrder: {
					heading: 'Cancelar instalação e Wallbox',
					description: 'A sua encomenda será anulada na totalidade e ser-lhe-ão reembolsados todos os custos nos próximos dias.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Redução da potência da Wallbox',
					message: 'A ABB confirma, por este meio, a instalação da Wallbox com base na redução da potência de acordo com os requisitos do operador de rede.\n\nInformá-lo-emos em breve sobre as próximas etapas.'
				},
				completionCancelInstallationSuccess: {
					heading: 'A sua instalação foi cancelada com sucesso.',
					message: 'Recebemos o seu cancelamento. Receberá em breve uma confirmação por correio eletrónico.'
				},
				completionCancelOrderSuccess: {
					heading: 'A nota de encomenda foi cancelada com sucesso.',
					message: 'Recebemos o seu cancelamento. Receberá em breve uma confirmação por correio eletrónico.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Enviar informações',
				message: 'Confirme a conclusão com êxito da instalação da Wallbox.',
				approval: {
					caption: 'Confirmar',
					label: 'Confirmo por este meio a instalação bem-sucedida da Wallbox, bem como a sua colocação em funcionamento e funcionalidade.'
				},
				completionSuccess: {
					heading: 'Obrigado!',
					message: 'A instalação da Wallbox está concluída. Receberá em breve toda a documentação importante.\n\nObrigado pela sua confiança!'
				}
			},
			quotationAcceptance: {
				caption: 'A sua oferta individual',
				message: 'Os seguintes pacotes de instalação são serviços adicionais que são faturados separadamente. Quando aceitar a oferta, será reencaminhado para o nosso prestador de serviços de pagamento, o Deutsche Bank, para que a encomenda possa ser processada em nosso nome.\n\nSe aceitar esta oferta, não haverá mais encargos e a instalação poderá ser efetuada.\n\nO nosso parceiro de instalação entrará em contacto consigo para organizar uma marcação de instalação.',
				submitButtonLabel: 'Aceitar a oferta sujeita a pagamento',
				cancelButtonLabel: 'Cancelar',
				cancellationCaption: 'É uma pena não podermos fazer-lhe uma boa oferta',
				cancellationMessage: 'Indique como pretende anular a sua encomenda.',
				submitCancellationButtonLabel: 'Enviar informações',
				recoverCancellationButtonLabel: 'Voltar',
				conditions: {
					caption: 'Condições da oferta',
					message: 'Esta oferta é válida enquanto as seguintes condições se aplicarem com base nas suas informações.'
				},
				acceptance: {
					terms: 'os Termos e Condições Gerais',
					label: 'Aceito {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'A sua encomenda foi efetuada com sucesso.',
					message: 'Aceitou a nossa oferta e será encaminhado para o nosso prestador de serviços de pagamento.'
				},
				completionCancelInstallationSuccess: {
					heading: 'A sua instalação foi cancelada com sucesso.',
					message: 'Recebemos o seu cancelamento. Receberá em breve uma confirmação por correio eletrónico.'
				},
				completionCancelOrderSuccess: {
					heading: 'A nota de encomenda foi cancelada com sucesso.',
					message: 'Recebemos o seu cancelamento. Receberá em breve uma confirmação por correio eletrónico.'
				}
			},
			payment: {
				heading: 'O seu pagamento',
				message: 'Será reencaminhado para o nosso prestador de serviços de pagamento.'
			}
		},
		documents: {
			heading: 'A documentação da sua encomenda',
			introduction: 'Síntese do documento'
		},
		order: {
			heading: 'A sua encomenda',
			cancelledHint: 'Esta encomenda foi anulada com sucesso.',
			doneHint: 'Esta encomenda foi concluída com êxito.',
			introduction: 'Encontram-se disponíveis todas as informações relativas à sua encomenda Wallbox na {{marketplaceName}} a partir de {{orderDate}} com o número de encomenda {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Terminar agora'
			},
			information: {
				heading: {
					orderItems: 'Encomenda',
					quotation: 'Oferta de serviços adicionais',
					appointment: 'Marcação da instalação',
					shipping: 'Estado do envio'
				},
				orderItems: {
					position: 'Posição',
					description: 'Descrição',
					wallboxSerialNumbner: 'Série Wallbox.',
					totalGrossPrice: 'Preço bruto total',
					cancellationHint: 'Cancelado em {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'A instalação foi cancelada em {{cancellationDate}}'
				},
				appointment: {
					heading: 'Instalação'
				},
				shipping: {
					heading: 'Enviado por {{carrier}}',
					trackingNumberLabel: 'Número de referência',
					shippingAddressLabel: 'Morada de envio',
					trackingLinkLabel: 'Acompanhamento do envio',
					itemsLabel: 'Itens do envio',
					productCodeLabel: 'Código do produto'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Cancelar instalação',
				description: 'A instalação pelo nosso parceiro de instalação está a ser cancelada. A Wallbox ser-lhe-á enviada diretamente. As despesas de instalação ser-lhe-ão reembolsadas nos próximos dias.'
			},
			cancelOrder: {
				heading: 'Cancelar instalação e Wallbox',
				description: 'A sua encomenda será anulada na totalidade e ser-lhe-ão reembolsados todos os custos nos próximos dias.'
			}
		}
	}
};

export default literals;
