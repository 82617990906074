import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	TaskEntityApiClient,
	TaskAssignResponse,
	TaskCompleteResponse,
	HypermediaEntityResponse
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, ModelPrimaryKey, TaskEntityModel, TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { createErrorObject } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { TaskEntityStore } from './TaskEntityStore';
import { FetchStatus } from '../FetchStatus';
import { ThunkApiConfig } from '../ThunkApiConfig';

export const createTaskFetchThunk = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, Store extends Record<string, TaskEntityStore<TaskModel>>, ApiClient extends TaskEntityApiClient<TaskModel, Payload>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<TaskModel>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetch',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (store.scopes?.[params.id].fetchStatus === FetchStatus.PENDING) {
					return false;
				}
				// Ignore existing model if forceFetch
				if (params.forceFetch) {
					return true;
				}
				// Cancel if id is already existing
				const existingModel = store.scopes?.[params.id].model ?? null;
				return existingModel === null;
			}
		}
	);
};

export const createTaskCompleteThunk = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, ApiClient extends TaskEntityApiClient<TaskModel, Payload>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<Nullable<TaskCompleteResponse<TaskModel>>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, model: TaskModel, payload: Mutable<Payload> }, ThunkApiConfig>(
		storeName + '/complete',
		async (params, thunkApi) => {
			try {
				const taskCompleteApiResponse = await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).complete(params.id, params.model, params.payload);
				const taskCompleteResponse = { ...taskCompleteApiResponse, payload: params.payload };
				return taskCompleteResponse as TaskCompleteResponse<TaskModel>;
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};

export const createTaskAssignThunk = <TaskModel extends TaskEntityModel, Payload extends TaskPayloadModel<TaskModel>, ApiClient extends TaskEntityApiClient<TaskModel, Payload>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<Nullable<TaskAssignResponse>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, model: TaskModel, candidateGroupIds: Array<ModelPrimaryKey> }, ThunkApiConfig>(
		storeName + '/assign',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).assign(params.id, params.candidateGroupIds);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};
