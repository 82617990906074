import React, { ReactNode } from 'react';

import { DeltaQuotationItems } from '@abb-emobility/oms/customer-ui-domain';
import { Notification, NotificationLevel } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderInformationQuotationModel } from '@abb-emobility/oms/domain-model';
import { getCurrencyByCode } from '@abb-emobility/shared/currency';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import './QuotationInformation.scss';

export type QuotationInformationProps = {
	orderInformation: CustomerOrderInformationQuotationModel
};

export function QuotationInformation(props: QuotationInformationProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const currency = getCurrencyByCode(orderInformation.payload.quotation.currencyCode);

	if (currency === null) {
		throw new AppError('Currency for code ' + orderInformation.payload.quotation.currencyCode + ' unresolvable');
	}

	const renderCancellationNotification = (): ReactNode => {
		if (!orderInformation.payload.cancelled) {
			return null;
		}
		return (
			<Notification
				message={l10n.translate('omsCustomerApp.order.information.quotation.cancellationHint', new Map([['cancellationDate', l10n.formatTimestampDateTime(orderInformation.payload.cancelledAt)]]))}
				level={NotificationLevel.INFO}
			/>
		);
	};

	return (
		<article className="quotation-information">
			{renderCancellationNotification()}
			<DeltaQuotationItems quotation={orderInformation.payload.quotation} currency={currency} />
		</article>
	);
}
