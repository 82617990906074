import { ReactNode, useState } from 'react';

import { ButtonIcon } from '../button-icon/ButtonIcon';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './ErrorNotification.scss';

export type ErrorNotificationAction = {
	label: string,
	onInvoke: () => void
};

export type ErrorNotificationProps = {
	heading: string,
	message?: string,
	details?: Array<string>,
	onDismiss?: () => void,
	actions?: Array<ErrorNotificationAction>
};

export function ErrorNotification(props: ErrorNotificationProps) {

	const { heading, message, details = [], onDismiss, actions = [] } = props;

	const [dismissed, setDismissed] = useState<boolean>(false);

	if (dismissed) {
		return null;
	}

	const handleDismiss = (): void => {
		if (onDismiss !== undefined) {
			onDismiss();
			return;
		}
		setDismissed(true);
	};

	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="error-notification__content__header__description">
				{message}
			</p>
		);
	};

	const renderDetails = (): ReactNode => {
		if (details.length === 0) {
			return;
		}
		const detailListItems = details.map((detail, index): ReactNode => {
			return (
				<li className="error-notification__content__main__details__item" key={index}>
					{detail}
				</li>
			);
		});
		return (
			<ul className="error-notification__content__main__details">
				{detailListItems}
			</ul>
		);
	};

	const renderActions = (): ReactNode => {
		if (actions.length === 0) {
			return;
		}
		const actionItems = actions.map((action, index): ReactNode => {
			return (
				<button
					onClick={(event) => {
						event.preventDefault();
						action.onInvoke();
					}}
					className="error-notification__content__footer__link"
					key={index}
				>
					{action.label}
				</button>
			);
		});
		return (
			<footer className="error-notification__content__footer">
				{actionItems}
			</footer>
		);
	};

	return (
		<article className="error-notification">
			<span className="error-notification__icon">
				<Icon name={IconIdentifier.INFO} />
			</span>

			<div className="error-notification__content">
				<header className="error-notification__content__header">
					<h1 className="error-notification__content__header__heading">
						{heading}
					</h1>
					{renderMessage()}
				</header>
				<main className="error-notification__content__main">
					{renderDetails()}
				</main>
				{renderActions()}
			</div>

			<span className="error-notification__close">
				<ButtonIcon onClick={handleDismiss}>
					<Icon name={IconIdentifier.CROSS} />
				</ButtonIcon>
			</span>
		</article>
	);
}
