import React, { ReactNode, Suspense } from 'react';

import { ButtonGhost } from '../button-ghost/ButtonGhost';
import { ButtonPrimary } from '../button-primary/ButtonPrimary';
import { ButtonSecondary } from '../button-secondary/ButtonSecondary';
import { ButtonTertiary } from '../button-tertiary/ButtonTertiary';

import './ErrorFeedback.scss';

const ErrorIllustration = React.lazy(() => import('../illustration/ErrorIllustration'));

export enum ErrorFeedbackActionWeight {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	TERTIARY = 'TERTIARY',
	GHOST = 'GHOST'
}

export type ErrorFeedbackAction = {
	label: string,
	weight?: ErrorFeedbackActionWeight,
	onInvoke: () => void
};

export type ErrorFeedbackProps = {
	heading: string,
	message?: string,
	stack?: string,
	actions?: Array<ErrorFeedbackAction>
};

export function ErrorFeedback(props: ErrorFeedbackProps) {

	const { heading, message, actions = [], stack } = props;

	const renderMessage = (): ReactNode => {
		if (message === undefined) {
			return;
		}
		return (
			<p className="error-feedback__main__description">{message}</p>
		);
	};

	const renderStack = (): ReactNode => {
		if (stack === undefined) {
			return;
		}
		return (
			<pre className="error-feedback__main__stack">{stack}</pre>
		);
	};

	const renderActtions = (): ReactNode => {
		if (actions.length === 0) {
			return;
		}
		const actionButtons = actions.map((action, index): ReactNode => {
			const weight = action.weight ?? ErrorFeedbackActionWeight.GHOST;
			switch (weight) {
				case ErrorFeedbackActionWeight.PRIMARY:
					return (<ButtonPrimary label={action.label} onClick={action.onInvoke} key={index} />);
				case ErrorFeedbackActionWeight.SECONDARY:
					return (<ButtonSecondary label={action.label} onClick={action.onInvoke} key={index} />);
				case ErrorFeedbackActionWeight.TERTIARY:
					return (<ButtonTertiary label={action.label} onClick={action.onInvoke} key={index} />);
				case ErrorFeedbackActionWeight.GHOST:
					return (<ButtonGhost label={action.label} onClick={action.onInvoke} key={index} />);
			}
			return null;
		});
		return (
			<footer className="error-feedback__actions">
				{actionButtons}
			</footer>
		);
	};

	return (
		<article className="error-feedback">
			<header className="error-feedback__header">
				<Suspense fallback={null}>
					<ErrorIllustration />
				</Suspense>
			</header>
			<main className="error-feedback__main">
				<h1 className="error-feedback__main__heading">
					{heading}
				</h1>
				{renderMessage()}
				{renderStack()}
			</main>
			{renderActtions()}
		</article>
	);
}
