import { OrderImportB2cApiClientFactory, OrderImportB2cApiClientInterface } from '@abb-emobility/oms/api-integration';
import { OrderImportB2cModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreationSlice,
	CrudCreationStoreAccessor,
	ThunkApiConfig,
	createCrudCreateThunk,
	createCrudSelectCreated,
	CrudCreationStore, createCrudCreationStoreSize, createCrudCreationStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const orderImportB2cCreationStoreName = 'order-import-b2c-creation-crud';
export type OrderImportB2cCreationStore = { [orderImportB2cCreationStoreName]: CrudCreationStore<OrderImportB2cModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return OrderImportB2cApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const createThunk = createCrudCreateThunk<OrderImportB2cModel, OrderImportB2cApiClientInterface>(orderImportB2cCreationStoreName, createApiClient);

// Slice definition
export const orderImportB2cCreationSlice = createCrudCreationSlice<OrderImportB2cModel, ThunkApiConfig>(orderImportB2cCreationStoreName, createThunk);
const { resolveActionStatus, resetStore } = orderImportB2cCreationSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectCreated<OrderImportB2cModel, OrderImportB2cCreationStore>(orderImportB2cCreationStoreName);
const storeSize = createCrudCreationStoreSize<OrderImportB2cModel, OrderImportB2cCreationStore>(orderImportB2cCreationStoreName);
const storeEntryIds = createCrudCreationStoreEntryIds<OrderImportB2cModel, OrderImportB2cCreationStore>(orderImportB2cCreationStoreName);

// Export the store accessors to be used by the data provider value
export const orderImportB2cEntityStoreAccessors = {
	create: createThunk,
	resolveActionStatus,
	select,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCreationStoreAccessor<OrderImportB2cModel, OrderImportB2cCreationStore>;
