import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Ett fel uppstod när kunddata lästes in.',
			customerEntityFetchMessage: 'Ett fel uppstod när kunduppgifterna lästes in.',
			taskFetchMessage: 'Ett fel uppstod när uppgiftsdetaljerna lästes in.',
			taskSearchMessage: 'Det uppstod ett fel vid sökning efter en uppgift.',
			taskCollectionFetchMessage: 'Det uppstod ett fel när uppgiftsdata lästes in.',
			orderFetchMessage: 'Det uppstod ett fel när orderuppgifter lästes in.',
			orderCollectionFetchMessage: 'Det uppstod ett fel när orderdata lästes in.',
			orderEventCollectionFetchMessage: 'Det uppstod ett fel när orderhistoriken lästes in.',
			orderEventEntityFetchMessage: 'Det uppstod ett fel när orderhändelsen lästes in.',
			orderShipmentCollectionFetchMessage: 'Det uppstod ett fel när leveransdata lästes in.',
			orderAccountingCollectionFetchMessage: 'Det uppstod ett fel när kontodata lästes in.',
			orderFilesCollectionFetchMessage: 'Det uppstod ett fel när filer lästes in.',
			orderInstallationCollectionFetchMessage: 'Det uppstod ett fel när installationsdata lästes in.',
			orderNotesCollectionFetchMessage: 'Det uppstod ett fel när anmärkningar lästes in.',
			orderInformationCollectionFetchMessage: 'Det uppstod ett fel när orderinformationen lästes in.',
			installerAppointmentCollectionFetchMessage: 'Det uppstod ett fel när mötena lästes in.',
			reportCollectionFetchMessage: 'Det uppstod ett fel när rapporterna lästes in.',
			searchCollectionFetchMessage: 'Det uppstod ett fel vid sökning efter data.',
			orderPaymentCollectionFetchMessage: 'Det uppstod ett fel när betalningsuppgifterna lästes in.',
			orderInformationEntityFetchMessage: 'Det uppstod ett fel när orderinformationen lästes in.'
		}
	}
};

export default literals;
