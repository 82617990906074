import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'ABB wallbox',
				labelCancel: 'Cancel',
				titlePaymentData: 'Payment',
				labelAmount: 'Amount additional installation effort and material',
				labelDate: 'Date',
				labelMerchant: 'Merchant',
				labelIban: 'Your IBAN'
			}
		},
		application: {
			pageTitle: 'ABB wallbox installation service',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Order {{marketplaceId}}',
			task: 'Order {{marketplaceId}} – {{taskType}}',
			documents: 'Documents',
			payment: 'Payment',
			notFound: 'Not found',
			static: {
				terms: 'General terms and conditions',
				privacy: 'Privacy',
				imprint: 'Imprint'
			}
		},
		footer: {
			termsLabel: 'General terms and conditions',
			privacyLabel: 'Privacy',
			imprintLabel: 'Imprint'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Enter code',
			otpInputMessage: 'We have sent a 6-digit code to your cell phone.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Send again',
			otpInputLabel: 'Please enter your code',
			otpInputPlaceholder: 'Authentication code',
			otpSubmitButtonLabel: 'Continue'
		},
		error: {
			otpAuthInitFailed: 'Authentication failed',
			otpAuthReceiveTokenFailed: 'Invalid code',
			taskTypeNotFound: 'The requested task type {{taskType}} is unknown.',
			taskNotFound: 'The requested task was not found',
			orderNotFound: 'The requested order was not found',
			documentNotFound: 'The requested document was not found',
			validation: {
				heading: 'Invalid data',
				message: 'The data you sent are not valid.'
			},
			generic: {
				heading: 'An error has occurred',
				message: 'An error occurred that could not be recovered.',
				resolveAction: {
					label: 'Reload'
				}
			},
			notFound: {
				heading: 'Not found',
				message: 'The resource you requested was not found'
			},
			taskCompleteMessage: 'While completing the task an error occurred.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'I need help',
			dialogueDismissLabel: 'Cancel',
			dialogueCaption: 'Customer service',
			phone: {
				heading: 'You can contact us by phone at any time'
			},
			mail: {
				heading: 'Or send us an email',
				subject: 'My wallbox order #{{marketplaceId}}',
				subjectStatic: 'My wallbox order'
			},
			infoPage: {
				heading: 'Or visit our information page',
				label: 'Answers to the most frequently asked questions'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Unknown',
			allDoneStepLabel: 'Done',
			upcomingLabelPrefix: 'Next: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'of'
		},
		payment: {
			success: {
				heading: 'Your payment has been successful.',
				message: 'Nothing is now in the way of your Wallbox installation. Our installation partner will get in touch with you soon to organize an installation appointment.\n\nThank you for your trust!'
			},
			failed: {
				heading: 'Your payment has failed!',
				retryLabel: 'Try again'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Installation check',
				customerProvidePreCheckPreferences: 'Provide preferred contact method',
				customerHandleUnfeasibleInstallation: 'Installation unfeasible',
				customerHandleGridOperatorRejection: 'Rejected by grid operator',
				customerAcceptQuotation: 'Offer for additional work',
				customerPerformPayment: 'Payment',
				customerPayment: 'Your payment',
				customerApproveInstallation: 'Installation confirmation',
				installationPartnerEvaluateBasicAnswers: 'Installation check',
				installationPartnerExecuteRemotePreCheck: 'Final check',
				installationPartnerRegisterAtGridOperator: 'Registration grid operator',
				installationPartnerRevokeGridOperatorRegistration: 'Revokation grid operator registration',
				installationPartnerProvideGridOperatorDecision: 'Decision grid operator',
				installationPartnerProvideQuotation: 'Quotation',
				installationPartnerArrangeAppointment: 'Arrange appointment',
				installationPartnerCompleteInstallation: 'Installation execution'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Installation check',
				customerProvidePreCheckPreferences: 'Preferred contact method',
				customerHandleUnfeasibleInstallation: 'Installation unfeasible',
				customerHandleGridOperatorRejection: 'Rejected by grid operator',
				customerAcceptQuotation: 'Offer for additional work',
				customerPerformPayment: 'Payment',
				customerPayment: 'Payment',
				customerApproveInstallation: 'Installation confirmation',
				installationPartnerEvaluateBasicAnswers: 'Installation check',
				installationPartnerExecuteRemotePreCheck: 'Final check',
				installationPartnerRegisterAtGridOperator: 'Registration grid operator',
				installationPartnerRevokeGridOperatorRegistration: 'Revokation grid operator registration',
				installationPartnerProvideGridOperatorDecision: 'Decision grid operator',
				installationPartnerProvideQuotation: 'Quotation',
				installationPartnerArrangeAppointment: 'Arrange appointment',
				installationPartnerCompleteInstallation: 'Installation execution'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'The purpose of granting power of attorney is to allow us to register for the grid connection with your consent.',
						powerOfAttorney: {
							heading: 'Granting of power of attorney for the registration of consumers with the grid operator',
							description: 'Tell us your network operator for the successful registration of your wallbox.',
							gridOperator: {
								question: 'Grid operator or energy supplier'
							},
							powerOfAttorney: {
								question: 'I hereby authorise ABB and its installation partner to register the wallbox with the grid operator. If the installation is not carried out, the power of attorney expires automatically.'
							}
						},
						marketingConsent: {
							heading: 'Consent to communication with the installation partner',
							installationPartnerMarketingConsent: {
								question: 'I hereby grant ABB and its installation partner permission to contact me for marketing purposes.'
							}
						},
						surveyConsent: {
							heading: 'Share your opinion with us',
							description: 'Please help us to improve our products and services by taking part in a short survey after completing your order.',
							surveyConsent: {
								question: 'I hereby give ABB permission to invite me to participate in a survey.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Thank you for your time!',
					message: 'You will soon receive an email confirmation. We are checking your details and will then be in touch to agree the next steps with you.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Back',
				nextStepButtonLabel: 'Next step',
				submitButtonLabel: 'Send information',
				channelSelection: {
					heading: 'Personal discussion with our Service Team',
					message: 'In order for the installation of your new Wallbox to run smoothly, please let us know your preferences for a personal discussion with our Service Team.\nThe next steps will then be agreed based on the information you have already provided.',
					subheading: 'How may we contact you?',
					explanation: 'There are various ways we can contact you.'
				},
				preferredTimesOfDay: {
					subHeading: 'Let’s find a time for a video chat. It will take place via {{channelName}}.',
					timeOfDay: {
						morning: 'Morning',
						noon: 'Lunchtime',
						afternoon: 'Afternoon',
						evening: 'Evening'
					}
				},
				completionSuccess: {
					heading: 'It’s great that you have time for us!',
					message: 'We will be in touch as close to the availabilities you have selected.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'We’re very sorry but',
				message: 'installation by our installation partner is currently not possible. The reason is:\n\n{{unfeasibleReason}}\n\nWe therefore have to ask you to cancel your order.',
				submitButtonLabel: 'Send information',
				unfeasibleReason: {
					noPotentialRailAvailable: 'A potential rail, which is necessary for installing the surge protection, is not available.',
					overvoltageProtectionUnfeasible: 'The necessary installation of a surge protector is not possible.',
					spaceForCableOutletMissing: 'There is not enough space for a necessary additional cable outlet.',
					houseConnectionUndersized: 'The house connection is so undersized that it is not possible to install the wallbox.',
					noThreePhaseAvailable: 'The existing electrical system does not provide the necessary three-phase supply.',
					outdatedMeterCabinet: 'The meter cabinet does not meet the requirements for commissioning the wallbox.',
					notTabCompliant: 'The electrical installation does not comply with the applicable technical connection conditions.',
					other: 'Technical reasons stand in the way of an installation that cannot be eliminated in the course of an installation.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Your installation has been successfully canceled.',
					message: 'We have received your cancellation. You will shortly receive an email confirmation.'
				},
				completionCancelOrderSuccess: {
					heading: 'Your order has been successfully canceled.',
					message: 'We have received your cancellation. You will shortly receive an email confirmation.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'We’re very sorry but your network operator has refused commissioning of your Wallbox',
				message: 'Unfortunately, the power supply at the installation location is not sufficient for the operation of your Wallbox. You have the option to reduce the power of your Wallbox or to cancel the installation.',
				submitButtonLabel: 'Send information',
				solutionDecreaseWallboxPower: {
					heading: 'Reduce Wallbox power',
					description: 'The power of your Wallbox will be reduced to the power appropriate to your power supply. The reduction in power consumption will have an impact on charging efficiency.'
				},
				solutionCancelInstallation: {
					heading: 'Cancel installation',
					description: 'The installation by our installation partner is being canceled. The Wallbox will be sent to you directly. You will be reimbursed for the installation costs in the next few days.'
				},
				solutionCancelOrder: {
					heading: 'Cancel installation and Wallbox',
					description: 'Your entire order will be canceled and you will be reimbursed for all costs in the next few days.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Wallbox power reduction',
					message: 'ABB hereby confirms installation of your Wallbox based on power reduction complying with the network operator’s requirements.\n\nWe will notify you of the next steps soon.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Your installation has been successfully canceled.',
					message: 'We have received your cancellation. You will shortly receive an email confirmation.'
				},
				completionCancelOrderSuccess: {
					heading: 'Your order has been successfully canceled.',
					message: 'We have received your cancellation. You will shortly receive an email confirmation.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Send information',
				message: 'Please confirm the successful completion of your Wallbox installation.',
				approval: {
					caption: 'Confirmation',
					label: 'I hereby confirm the successful installation of the Wallbox as well as its commissioning and functionality.'
				},
				completionSuccess: {
					heading: 'Thank you!',
					message: 'Your Wallbox installation has been completed. You will soon receive all important documentation.\n\nThank you for your trust!'
				}
			},
			quotationAcceptance: {
				caption: 'Your individual offer',
				message: 'The following installation packages are additional services that are invoiced separately. When you accept the offer, you will be forwarded to our payment service provider, Deutsche Bank, so the order can be processed on our behalf.\n\nNo further charges will occur when you accept this offer, and the installation can then be carried out.\n\nOur installation partner will get in touch with you to organize an installation appointment.',
				submitButtonLabel: 'Accept offer subject to payment',
				cancelButtonLabel: 'Cancel',
				cancellationCaption: 'Too bad we can\'t make you a good offer',
				cancellationMessage: 'Please indicate how you would like to cancel your order.',
				submitCancellationButtonLabel: 'Send information',
				recoverCancellationButtonLabel: 'Back',
				conditions: {
					caption: 'Offer conditions',
					message: 'This offer is valid for as long as the following conditions apply based on your information.'
				},
				acceptance: {
					terms: 'the General Terms and Conditions',
					label: 'I hereby accept {{termsLink}}.'
				},
				completionSuccess: {
					heading: 'Your order has been successful.',
					message: 'You have accepted our offer and will be forwarded to our payment service provider.'
				},
				completionCancelInstallationSuccess: {
					heading: 'Your installation has been successfully canceled.',
					message: 'We have received your cancellation. You will shortly receive an email confirmation.'
				},
				completionCancelOrderSuccess: {
					heading: 'Your order has been successfully canceled.',
					message: 'We have received your cancellation. You will shortly receive an email confirmation.'
				}
			},
			payment: {
				heading: 'Your payment',
				message: 'You will be forwarded to our payment service provider.'
			}
		},
		documents: {
			heading: 'Your order documentation',
			introduction: 'Document overview'
		},
		order: {
			heading: 'Your order',
			cancelledHint: 'This order was successfully cancelled.',
			doneHint: 'This order was successfully completed.',
			introduction: 'Please find all current information relating to your Wallbox order at {{marketplaceName}} from {{orderDate}} with order number {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Finish now'
			},
			information: {
				heading: {
					orderItems: 'Order',
					quotation: 'Offer for additional services',
					appointment: 'Installation appointment',
					shipping: 'Shipment status'
				},
				orderItems: {
					position: 'Position',
					description: 'Description',
					wallboxSerialNumbner: 'Wallbox series.',
					totalGrossPrice: 'Total gross price',
					cancellationHint: 'Cancelled on {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'The installation was cancelled on {{cancellationDate}}'
				},
				appointment: {
					heading: 'Installation'
				},
				shipping: {
					heading: 'Shipped by {{carrier}}',
					trackingNumberLabel: 'Reference number',
					shippingAddressLabel: 'Shipping address',
					trackingLinkLabel: 'Shipment tracking',
					itemsLabel: 'Shipment items',
					productCodeLabel: 'Product code'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Cancel installation',
				description: 'The installation by our installation partner is being canceled. The Wallbox will be sent to you directly. You will be reimbursed for the installation costs in the next few days.'
			},
			cancelOrder: {
				heading: 'Cancel installation and Wallbox',
				description: 'Your entire order will be canceled and you will be reimbursed for all costs in the next few days.'
			}
		}
	}
};

export default literals;
