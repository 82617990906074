import { GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export enum PreCheckRejectedByGridOperatorSolution {
	DECREASE_WALLBOX_POWER = 'DECREASE_WALLBOX_POWER',
	CANCEL_INSTALLATION = 'CANCEL_INSTALLATION',
	CANCEL_ORDER = 'CANCEL_ORDER'
}

export type PreCheckRejectedByGridOperatorPayloadModel = {
	readonly gridOperator: GridOperatorModel,
	// TODO: Define as mandatory after the backend has integrated the member; see https://jira.fiege.com/browse/ABB-801
	readonly installationCancelable?: boolean,
	solution?: PreCheckRejectedByGridOperatorSolution
} & TaskPayloadFoundationModel;

export class PreCheckRejectedByGridOperatorPayloadModelConverter extends ModelConverter<PreCheckRejectedByGridOperatorPayloadModel> {

	override getFieldConverterMapByModel(): Map<keyof PreCheckRejectedByGridOperatorPayloadModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}
}

export type PreCheckRejectedByGridOperatorEscalationPayloadModel =
	PreCheckRejectedByGridOperatorPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

/**
 * PreCheckRejectedByGridOperatorPayloadModelConverter combines the functionality of the
 * - CustomerServiceTaskPayloadFoundationModelConverter
 * - PreCheckRejectedByGridOperatorPayloadModelConverter
 */
export class PreCheckRejectedByGridOperatorEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<PreCheckRejectedByGridOperatorEscalationPayloadModel> {
	private payloadModelConverter = new PreCheckRejectedByGridOperatorPayloadModelConverter();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof PreCheckRejectedByGridOperatorEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
