import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type MarketplaceModel = {
	readonly id: ModelPrimaryKey,
	readonly name: string,
	readonly customerServiceHotline: string,
	readonly customerServiceMailAddress: string,
	readonly customerServiceInformationUrl?: string
};

export class MarketplaceModelConverter extends ModelConverter<MarketplaceModel> {
}
