import { AnyInstallationPartnerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { AnyInstallationPartnerOrderInformationCollectionApiClient } from './AnyInstallationPartnerOrderInformationCollectionApiClient';
import {
	AnyInstallationPartnerOrderInformationCollectionApiClientMock
} from './AnyInstallationPartnerOrderInformationCollectionApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnyInstallationPartnerOrderInformationCollectionApiClientInterface
	extends CrudApiClient<AnyInstallationPartnerOrderInformationCollectionItemModel> {
}

export class AnyInstallationPartnerOrderInformationCollectionApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): AnyInstallationPartnerOrderInformationCollectionApiClientInterface {
		if (process.env.NX_OMS_IP_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new AnyInstallationPartnerOrderInformationCollectionApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new AnyInstallationPartnerOrderInformationCollectionApiClient().init(baseUrl, jsonWebToken);
	}

}
