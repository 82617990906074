import { FooModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { FooApiClient } from './FooApiClient';
import { FooApiClientMock } from './FooApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FooApiClientInterface extends CrudApiClient<FooModel> {
}

export class FooApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): FooApiClientInterface {
		if (process.env.NX_OMS_FOO_API_MOCKED === 'true') {
			const apiClientMock = new FooApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new FooApiClient().init(baseUrl, jsonWebToken);
	}

}
