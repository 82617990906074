import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Während des Ladens der Kundeninformationen ist ein Fehler aufgetreten.',
			customerEntityFetchMessage: 'Während des Ladens der Kundeninformationen ist ein Fehler aufgetreten.',
			taskFetchMessage: 'Während des Ladens der Aufgabe ist ein Fehler aufgetreten.',
			taskSearchMessage: 'Während des Ladens der Aufgabe ist ein Fehler aufgetreten.',
			taskCollectionFetchMessage: 'Während des Ladens der Aufgaben ist ein Fehler aufgetreten.',
			orderFetchMessage: 'Während des Ladens der Bestellung ist ein Fehler aufgetreten.',
			orderCollectionFetchMessage: 'Während des Ladens der Bestellungen ist ein Fehler aufgetreten.',
			orderEventCollectionFetchMessage: 'Während des Ladens der Timeline ist ein Fehler aufgetreten.',
			orderEventEntityFetchMessage: 'Während des Ladens der Timeline Details ist ein Fehler aufgetreten.',
			orderShipmentCollectionFetchMessage: 'Während des Ladens der Versand Details ist ein Fehler aufgetreten.',
			orderAccountingCollectionFetchMessage: 'Während des Ladens der Buchhaltung Details ist ein Fehler aufgetreten.',
			orderFilesCollectionFetchMessage: 'Während des Ladens der Dateien ist ein Fehler aufgetreten.',
			orderInstallationCollectionFetchMessage: 'Während des Ladens der Dateien ist ein Fehler aufgetreten.',
			orderNotesCollectionFetchMessage: 'Während des Ladens der Notizen ist ein Fehler aufgetreten.',
			orderInformationCollectionFetchMessage: 'Während des Ladens der Bestellinformationen ist ein Fehler aufgetreten.',
			installerAppointmentCollectionFetchMessage: 'Während des Ladens der Termine ist ein Fehler aufgetreten.',
			reportCollectionFetchMessage: 'Während des Ladens der Berichte ist ein Fehler aufgetreten.',
			searchCollectionFetchMessage: 'Während des Ladens der Suchergebnisse ist ein Fehler aufgetreten.',
			orderPaymentCollectionFetchMessage: 'Während des Ladens der Zahlungsdetails ist ein Fehler aufgetreten.',
			orderInformationEntityFetchMessage: 'Während des Ladens der Bestellinformationen ist ein Fehler aufgetreten.'
		}
	}
};

export default literals;
