export enum CrudActionStatus {
	IDLE = 'IDLE',
	MUTATE_PENDING = 'MUTATE_PENDING',
	CREATE_PENDING = 'CREATE_PENDING',
	DELETE_PENDING = 'DELETE_PENDING',
	MUTATE_SUCCESS = 'MUTATE_SUCCESS',
	CREATE_SUCCESS = 'CREATE_SUCCESS',
	DELETE_SUCCESS = 'DELETE_SUCCESS',
	MUTATE_FAILED = 'MUTATE_FAILED',
	CREATE_FAILED = 'CREATE_FAILED',
	DELETE_FAILED = 'DELETE_FAILED'
}

export const crudActionStatusPendingGroup = [CrudActionStatus.CREATE_PENDING, CrudActionStatus.MUTATE_PENDING, CrudActionStatus.DELETE_PENDING];

export const crudActionStatusSuccessGroup = [CrudActionStatus.CREATE_SUCCESS, CrudActionStatus.MUTATE_SUCCESS, CrudActionStatus.DELETE_SUCCESS];

export const crudActionStatusFailedGroup = [CrudActionStatus.CREATE_FAILED, CrudActionStatus.MUTATE_FAILED, CrudActionStatus.DELETE_FAILED];
