import fetchMock from 'fetch-mock';

import { createMockBeginMatcher, createMockOptions, createMockResponse } from '@abb-emobility/shared/api-integration-foundation';
import { combineUrl } from '@abb-emobility/shared/util';

export const registerProtectedUrlResolverApiFetchMock = (baseUrl: string) => {
	fetchMock
		.put(
			createMockBeginMatcher(combineUrl(baseUrl, 'resolve-protected-image')),
			createMockResponse({
				accessible: true,
				value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
			}),
			createMockOptions()
		);
};
