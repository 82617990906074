import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Selecteer',
			pictureSelectPlaceholder: 'Selecteer afbeelding of maak een foto',
			pictureSelectTrigger: 'Foto selecteren',
			pictureSelectHint: {
				default: 'Zorg ervoor dat er geen mensen op de foto staan.',
				examplePicture: 'Gebruik de voorbeeldafbeelding als richtlijn en zorg ervoor dat er geen mensen op de afbeelding staan.'
			},
			documentSelectPlaceholder: 'Selecteer een document',
			documentSelectTrigger: 'Selecteer document',
			selectedFileTooLarge: 'Het geselecteerde bestand is te groot om te worden overgedragen. Selecteer een bestand kleiner dan {{maxFileSize}}.',
			answerTypeNotFound: 'Het antwoordtype {{type}} is onbekend.',
			previousSegmentButtonLabel: 'Terug',
			nextSegmentButtonLabel: 'Volgende stappen',
			submitButtonLabel: 'Stuur informatie',
			validationErrors: {
				heading: 'Er zijn enkele problemen met uw antwoorden',
				message: 'Controleer uw antwoorden om de vragenlijst in te vullen'
			}
		}
	}
};

export default literals;
