import { Footer } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderEntityDataProvider } from '@abb-emobility/oms/data-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { SilentErrorHandler } from '@abb-emobility/shared/error';

import { OrderFooterItems } from './order-footer-items/OrderFooterItems';

import './OrderFooter.scss';

export type OrderFooterProps = {
	orderId: ModelPrimaryKey
};

export function OrderFooter(props: OrderFooterProps) {

	const { orderId } = props;

	const handleError = (): boolean => {
		console.warn('[Order footer] Could not fetch order');
		return true;
	};

	return (
		<footer className="order-footer">
			<div className="order-footer__dimension">
				<Footer>
					<SilentErrorHandler handleError={handleError}>
						<CustomerOrderEntityDataProvider
							primaryKey={orderId}
							pendingComponent={() => (<span>…</span>)}
						>
							<OrderFooterItems />
						</CustomerOrderEntityDataProvider>
					</SilentErrorHandler>
				</Footer>
			</div>
		</footer>
	);
}
