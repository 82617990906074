import { Nullable } from '@abb-emobility/shared/util';

import { AccessibleUrl, AccessibleUrlDto, ProtectedUrlDto, UrlDto, ProtectedUrl, Url } from './Url';

export const isAccessableUrl = (accessableUrl?: unknown): accessableUrl is AccessibleUrl => {
	if ((accessableUrl ?? null) === null) {
		return false;
	}
	return (accessableUrl as AccessibleUrl).discriminator === 'Url'
		&& (accessableUrl as AccessibleUrl).accessible
		&& (accessableUrl as AccessibleUrl).value !== undefined;
};

export const isProtectedUrl = (protectedUrl?: unknown): protectedUrl is ProtectedUrl => {
	if ((protectedUrl ?? null) === null) {
		return false;
	}
	return (protectedUrl as ProtectedUrl).discriminator === 'Url'
		&& !(protectedUrl as ProtectedUrl).accessible
		&& (protectedUrl as ProtectedUrl).exchangeUrl !== undefined;
};

export const isAbsoluteUrl = (url: Url): boolean => {
	let urlValue: Nullable<string> = null;
	if (isAccessableUrl(url)) {
		urlValue = url.value;
	} else if (isProtectedUrl(url)) {
		urlValue = url.exchangeUrl;
	}
	if (urlValue === null) {
		return false;
	}
	return (
		urlValue.toLowerCase().startsWith('http://')
		|| urlValue.toLowerCase().startsWith('https://')
	);
};

export const isUrl = (url?: unknown): url is Url => {
	if ((url ?? null) === null) {
		return false;
	}
	return isAccessableUrl(url) || isProtectedUrl(url);
};

export const isAccessableUrlDto = (accessableUrlDto?: unknown): accessableUrlDto is AccessibleUrlDto => {
	if ((accessableUrlDto ?? null) === null) {
		return false;
	}
	// noinspection PointlessBooleanExpressionJS
	return (accessableUrlDto as AccessibleUrlDto).accessible === true
		&& (accessableUrlDto as AccessibleUrlDto).value !== undefined;
};

export const isProtectedUrlDto = (protectedUrlDto?: unknown): protectedUrlDto is ProtectedUrlDto => {
	if ((protectedUrlDto ?? null) === null) {
		return false;
	}
	// noinspection PointlessBooleanExpressionJS
	return (protectedUrlDto as ProtectedUrlDto).accessible === false
		&& (protectedUrlDto as ProtectedUrlDto).exchangeUrl !== undefined;
};

export const isUrlDto = (urlDto?: unknown): urlDto is UrlDto => {
	if ((urlDto ?? null) === null) {
		return false;
	}
	return isAccessableUrlDto(urlDto) || isProtectedUrlDto(urlDto);
};
