import { useContext } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { LoggerContext, loggerContext } from './LoggerContext';

export const useLogger = (): Nullable<LoggerContext> => {
	const logger = useContext(loggerContext);
	if (!logger.provided) {
		return null;
	}
	return logger;
};
