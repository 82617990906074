import { InstallationPartnerOrderInformationApprovalInformationModel } from '@abb-emobility/oms/domain-model';
import { InstallationState } from '@abb-emobility/shared/domain-model';
import { DownloadFileDto, Dto } from '@abb-emobility/shared/domain-model-foundation';

const downloadImageDto: DownloadFileDto = {
	url: {
		accessible: true,
		value: '/sites/default/files/styles/image_medium/public/2022-05/mitarbeiter-fiege.webp'
	},
	meta: {
		title: 'Mitarbeiter Fiege',
		fileName: 'mitarbeiter-fiege.webp',
		mimeType: 'image/webp',
		size: 70,
		lastModifiedDate: '2022-01-01T16:30:12.345Z'
	}
};

const downloadDocumentDto: DownloadFileDto = {
	url: {
		accessible: true,
		value: '/sites/default/files/2022-09/fiege_logo_0.zip'
	},
	meta: {
		title: 'Fiege Logo',
		fileName: 'fiege_logo_0.zip',
		mimeType: 'application/zip',
		size: 70,
		lastModifiedDate: '2022-01-01T16:30:12.345Z'
	}
};

export const installationPartnerOrderInformationApprovalInformationModelMock: Dto<InstallationPartnerOrderInformationApprovalInformationModel> = {
	installationState: InstallationState.DONE,
	customerSignature: downloadImageDto,
	installationDocuments: [downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadImageDto, downloadDocumentDto, downloadImageDto, downloadDocumentDto]
};
