import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Seleccionar',
			pictureSelectPlaceholder: 'Seleccionar imagen o hacer una fotografía',
			pictureSelectTrigger: 'Seleccionar imagen',
			pictureSelectHint: {
				default: 'Asegúrese de que no haya personas en la fotografía.',
				examplePicture: 'Utilice la imagen de ejemplo como guía y asegúrese de que no hay personas en la imagen.'
			},
			documentSelectPlaceholder: 'Seleccionar un documento',
			documentSelectTrigger: 'Seleccionar documento',
			selectedFileTooLarge: 'El archivo seleccionado es demasiado grande para ser transferido. Seleccione un archivo con un tamaño inferior a {{maxFileSize}}.',
			answerTypeNotFound: 'El tipo de respuesta {{type}} es desconocido.',
			previousSegmentButtonLabel: 'Volver',
			nextSegmentButtonLabel: 'Próximo paso',
			submitButtonLabel: 'Enviar información',
			validationErrors: {
				heading: 'Hay algunos problemas con sus respuestas',
				message: 'Marque sus respuestas para completar el cuestionario'
			}
		}
	}
};

export default literals;
