import { ReactNode, useEffect, useState } from 'react';

import {
	ButtonBar,
	ButtonBarSection,
	ButtonBarSectionAlign,
	ButtonGhost,
	ButtonPrimary,
	Form,
	InputText
} from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';

export type OtpAuthFormProps = {
	onSubmit: (token: string) => void,
	onResend: () => void
};

export function OtpAuthForm(props: OtpAuthFormProps) {

	const { onSubmit, onResend } = props;

	const l10n = useL10n();
	const [token, setToken] = useState<string>('');
	const expectedTokenLength = 6;

	const [pendingSeconds, setPendingSeconds] = useState<number>(0);
	const pendingSecondsUntilResend = 5;

	useEffect(() => {
		const pendingTimeout = setTimeout((): void => {
			setPendingSeconds(pendingSeconds + 1);
			if (pendingSeconds >= pendingSecondsUntilResend) {
				clearTimeout(pendingTimeout);
			}
		}, 1000);
		return (): void => {
			clearTimeout(pendingTimeout);
		};
	});

	const handleSubmit = () => {
		if (token.trim().length !== expectedTokenLength) {
			return;
		}
		onSubmit(token);
	};

	const handleResend = () => {
		onResend();
		setPendingSeconds(0);
	};

	const renderResendLink = (): ReactNode => {
		return (
			<ButtonGhost
				label={l10n.translate('omsCustomerApp.otpAuthForm.resendOtpButtonLabel')}
				onClick={handleResend}
				disabled={pendingSeconds < pendingSecondsUntilResend}
			/>
		);
	};

	return (
		<Form autoComplete="off" onSubmit={handleSubmit}>

			<InputText
				label={l10n.translate('omsCustomerApp.otpAuthForm.otpInputLabel')}
				placeholder={l10n.translate('omsCustomerApp.otpAuthForm.otpInputPlaceholder')}
				onChange={setToken}
				name="token"
				id="token"
				inputMode="numeric"
				pattern="[0-9]*"
				autoComplete="one-time-code"
				autoFocus={true}
				required={true}
			/>

			<ButtonBar>
				<ButtonBarSection>
					{renderResendLink()}
				</ButtonBarSection>
				<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
					<ButtonPrimary
						type="submit"
						label={l10n.translate('omsCustomerApp.otpAuthForm.otpSubmitButtonLabel')}
						disabled={token.length !== expectedTokenLength}
					/>
				</ButtonBarSection>
			</ButtonBar>

		</Form>
	);

}
