/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	AddressModel,
	AddressModelConverter,
	CustomerModel,
	CustomerModelConverter,
	InstallationType, OrderState,
	ShippingState
} from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import {
	ModelPrimaryKey,
	TimestampFieldNames,
	Timestamp,
	ArrayOfTimestampFieldNames,
	UrlFieldNames,
	ArrayOfUrlFieldNames,
	Url
} from '@abb-emobility/shared/domain-model-foundation';

import {
	InstallationPartnerOrderLineItemModel, InstallationPartnerOrderLineItemModelConverter
} from './InstallationPartnerOrderLineItemModel';
import { InstallationPartnerOrderProgressModel } from './InstallationPartnerOrderProgressModel';

export type InstallationPartnerOrderShipmentModel = {
	readonly id: ModelPrimaryKey,
	readonly shippingState: ShippingState
	readonly trackingNumber: string,
	readonly shippingTrackingUrl: Url,
	readonly estimatedDeliveryDate?: Timestamp,
};

class InstallationPartnerOrderShipmentModelConverter extends ModelConverter<InstallationPartnerOrderShipmentModel> {
	override getTimestampFields(): Array<TimestampFieldNames<InstallationPartnerOrderShipmentModel> | ArrayOfTimestampFieldNames<InstallationPartnerOrderShipmentModel>> {
		return ['estimatedDeliveryDate'];
	}

	override getUrlFields(): Array<UrlFieldNames<InstallationPartnerOrderShipmentModel> | ArrayOfUrlFieldNames<InstallationPartnerOrderShipmentModel>> {
		return ['shippingTrackingUrl'];
	}

}

export type InstallationPartnerOrderModel = {
	readonly id: ModelPrimaryKey,
	readonly marketplaceOrderId: string,

	readonly state: OrderState,
	readonly progress: InstallationPartnerOrderProgressModel,
	readonly customer: CustomerModel,
	readonly installationType: InstallationType,
	readonly orderDate: Timestamp,
	readonly installationAddress: AddressModel,
	readonly orderLineItems: Array<InstallationPartnerOrderLineItemModel>,
	// ANNOTATION: The estimated completion date is calculated following those rules:
	//  - For orders without installation it is the estimated delivery date – usually 2 working days after order creation
	//  - For orders including installation it is:
	//    a. 14 working days after the order creation for not already determined type of installation or delta installation
	//    b. 10 working days after the order creation for standard installations
	//    c. Earliest 10 calendar days before the planned delivery of the car if provided by OEM
	readonly estimatedCompletionDate: Timestamp,
	readonly installationDate?: Timestamp,
	readonly throttleWallboxPowerConsumption: boolean,
	readonly wallboxSerialNumber?: string,
	readonly shipments?: Array<InstallationPartnerOrderShipmentModel>
};

export class InstallationPartnerOrderModelConverter extends ModelConverter<InstallationPartnerOrderModel> {
	override getTimestampFields(): Array<TimestampFieldNames<InstallationPartnerOrderModel> | ArrayOfTimestampFieldNames<InstallationPartnerOrderModel>> {
		return ['installationDate', 'estimatedCompletionDate', 'orderDate'];
	}

	override getFieldConverterMapByModel(): Map<keyof InstallationPartnerOrderModel, ModelConverterInterface<any>> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return new Map<keyof InstallationPartnerOrderModel, ModelConverterInterface<any>>([
			['customer', new CustomerModelConverter()],
			['installationAddress', new AddressModelConverter()],
			['orderLineItems', new InstallationPartnerOrderLineItemModelConverter()],
			['shipments', new InstallationPartnerOrderShipmentModelConverter()]
		]);
	}

}
