import { TaskCollectionApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { AnyTaskCollectionApiClient } from './AnyTaskCollectionApiClient';
import { AnyTaskCollectionApiClientMock } from './AnyTaskCollectionApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnyTaskCollectionApiClientInterface extends TaskCollectionApiClient<AnyTaskCollectionItemModel> {
}

export class AnyTaskCollectionApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): AnyTaskCollectionApiClientInterface {
		if (process.env.NX_TASK_API_MOCKED === 'true') {
			const apiClientMock = new AnyTaskCollectionApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new AnyTaskCollectionApiClient().init(baseUrl, jsonWebToken);
	}

}
