import { ReactElement } from 'react';

import { Dialogue, Notification, NotificationLevel, SpinnerDots } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderEntityDataProvider } from '@abb-emobility/oms/data-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ErrorHandler } from '@abb-emobility/shared/error';
import { useDialogue } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { CustomerServiceDialogueContact } from '../customer-service-dialogue-contact/CustomerServiceDialogueContact';

export type CustomerServiceDialogueProps = {
	orderId: ModelPrimaryKey
};

export function CustomerServiceDialogue(props: CustomerServiceDialogueProps) {

	const { orderId } = props;

	const l10n = useL10n();
	const dialogue = useDialogue();

	const renderError = (error: Error): ReactElement => {
		console.warn('[Customer service dialogue] Could not fetch order');
		return (
			<Notification level={NotificationLevel.ERROR} message={error.message} />
		);
	};

	return (
		<Dialogue
			caption={l10n.translate('omsCustomerApp.customerServiceDialogue.dialogueCaption')}
			dismissLabel={l10n.translate('omsCustomerApp.customerServiceDialogue.dialogueDismissLabel')}
			onDismiss={dialogue.dismiss}
		>
			<ErrorHandler errorComponent={renderError}>
				<CustomerOrderEntityDataProvider
					primaryKey={orderId}
					pendingComponent={SpinnerDots}
				>
					<CustomerServiceDialogueContact />
				</CustomerOrderEntityDataProvider>
			</ErrorHandler>
		</Dialogue>
	);

}
