import { ButtonHTMLAttributes } from 'react';

import './ButtonPrimary.scss';

export type ButtonPrimaryProps = {
	label: string
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonPrimary(props: ButtonPrimaryProps) {
	const { type = 'button', label, ...rest } = props;

	return (
		<button
			type={type}
			className="button-primary"
			{...rest}
		>{label}</button>
	);
}
