import { ReactNode, useState } from 'react';

import { PreCheckChannelSelect } from '@abb-emobility/oms/customer-ui-domain';
import { FormSection, FormSegement } from '@abb-emobility/oms/customer-ui-primitive';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Nullable } from '@abb-emobility/shared/util';
import { PreCheckChannelModel, PreCheckPreferencesTaskModel } from '@abb-emobility/usertask/domain-model';

export type TaskPreCheckPreferencesFormStep1Props = {
	task: PreCheckPreferencesTaskModel,
	selectedMediumId?: ModelPrimaryKey,
	onSelectChannel: (channel: PreCheckChannelModel) => void
};

export function TaskPreCheckPreferencesFormStep1(props: TaskPreCheckPreferencesFormStep1Props) {

	const { task, selectedMediumId = null, onSelectChannel } = props;

	const initialChannel = task.payload.availableChannels.find((channel) => {
		return channel.medium.id === selectedMediumId;
	});

	const l10n = useL10n();
	const [preCheckChannel, setPreCheckChannel] = useState<Nullable<PreCheckChannelModel>>(initialChannel ?? null);

	const handleChannelSelect = (channel: PreCheckChannelModel) => {
		setPreCheckChannel(channel);
		onSelectChannel(channel);
	};

	const renderPreCheckChannelSelection = () => {
		return task.payload.availableChannels.map((channel): ReactNode => {
			return (
				<PreCheckChannelSelect
					channel={channel}
					onSelect={handleChannelSelect}
					selected={preCheckChannel?.medium?.id === channel.medium.id}
					key={channel.medium.id}
				/>
			);
		});
	};

	return (
		<FormSegement>
			<FormSection
				heading={l10n.translate('omsCustomerApp.task.preCheckPreferences.channelSelection.heading')}
				description={l10n.translate('omsCustomerApp.task.preCheckPreferences.channelSelection.message')}
			>
				<FormSection
					heading={l10n.translate('omsCustomerApp.task.preCheckPreferences.channelSelection.subheading')}
					description={l10n.translate('omsCustomerApp.task.preCheckPreferences.channelSelection.explanation')}
				>
					{renderPreCheckChannelSelection()}
				</FormSection>
			</FormSection>
		</FormSegement>
	);

}
