import {
	AnyCustomerOrderInformationCollectionApiClientFactory,
	AnyCustomerOrderInformationCollectionApiClientInterface
} from '@abb-emobility/oms/api-integration';
import { AnyCustomerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerOrderInformationCollectionStoreName = 'customer-order-information-collection';
export type CustomerOrderInformationCollectionCrudStore = { [customerOrderInformationCollectionStoreName]: CrudCollectionStore<AnyCustomerOrderInformationCollectionItemModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyCustomerOrderInformationCollectionApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyCustomerOrderInformationCollectionItemModel, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<AnyCustomerOrderInformationCollectionItemModel, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyCustomerOrderInformationCollectionItemModel, AnyCustomerOrderInformationCollectionApiClientInterface>(customerOrderInformationCollectionStoreName, createApiClient);

// Slice definition
export const customerOrderInformationCollectionSlice = createCrudCollectionSlice<AnyCustomerOrderInformationCollectionItemModel, ThunkApiConfig>(
	customerOrderInformationCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerOrderInformationCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(customerOrderInformationCollectionStoreName);
const countCollection = createCrudCountCollection<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(customerOrderInformationCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(customerOrderInformationCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(customerOrderInformationCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(customerOrderInformationCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(customerOrderInformationCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerOrderInformationCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>;
