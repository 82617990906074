import { Component, ReactElement, ReactNode } from 'react';

import { Logger, LogLevel, withLogger } from '@abb-emobility/shared/logger';
import { Nullable } from '@abb-emobility/shared/util';

export type ErrorHandlerProps = {
	errorComponent: (error: Error) => Nullable<ReactElement>,
	transparent?: boolean,
	logger?: Logger,
	children?: ReactNode
};

type ErrorHandlerState = {
	error?: Error
};

class ErrorHandlerFoundation extends Component<ErrorHandlerProps, ErrorHandlerState> {

	constructor(props: ErrorHandlerProps) {
		super(props);
		this.state = {
			error: undefined
		};
	}

	static getDerivedStateFromError(error: Error): ErrorHandlerState {
		return {
			error: error
		};
	}

	private logError(error: Error) {
		if (!this.props.logger) {
			return;
		}
		void this.props.logger.logError(error, LogLevel.ERROR);
	}

	private renderError(error: Error) {
		const errorComponent = this.props.errorComponent(error);
		if (errorComponent === null) {
			throw error;
		}
		const children = this.props.transparent ? this.props.children : null;
		return (
			<>
				{errorComponent}
				{children}
			</>
		);
	}

	override render() {
		const error = this.state.error;
		if (error !== undefined) {
			this.logError(error);
			return this.renderError(error);
		}
		return this.props.children;
	}

}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ErrorHandler = withLogger(ErrorHandlerFoundation);
