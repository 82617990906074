import { isNode } from 'browser-or-node';

import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	AccessDeniedError,
	ApiError,
	AppError,
	AuthenticationRequiredError,
	NetworkError,
	NotFoundError,
	TimeoutError,
	TooEarlyError,
	ValidationError
} from '@abb-emobility/shared/error';
import { L10n } from '@abb-emobility/shared/localization-provider';
import { Nullable, Timeout } from '@abb-emobility/shared/util';

import { HttpMethod } from './HttpMethod';
import { JsonRestRequestInterface, RestResponse } from './JsonRestRequestInterface';
import { JsonTransportValue } from '../json/JsonTransportValue';

type JsonExceptionDetailsTransportValue = {
	message: string
};

type JsonExceptionTransportValue = {
	status: number,
	error: string,
	timestamp: string,
	details: Array<JsonExceptionDetailsTransportValue>
};

export class JsonRestRequest implements JsonRestRequestInterface {

	private readonly jsonWebToken: Nullable<JsonWebToken> = null;
	private readonly abortController: AbortController = new AbortController();

	protected preRequest(request: Request): Request {
		return request;
	}

	protected postRequest(response: Response): Response {
		return response;
	}

	constructor(jsonWebToken: Nullable<JsonWebToken> = null) {
		this.jsonWebToken = jsonWebToken;
	}

	async options(uri: string): Promise<RestResponse<undefined>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.OPTIONS)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		this.handleResponse(response.status, {});
		return {
			body: undefined,
			headers: this.unwrapHeaders(response)
		};
	}

	async head(uri: string): Promise<RestResponse<undefined>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.HEAD)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		this.handleResponse(response.status, {});
		return {
			body: undefined,
			headers: this.unwrapHeaders(response)
		};
	}

	async get(uri: string): Promise<RestResponse<Array<JsonTransportValue> | JsonTransportValue | null>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.GET)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	// TODO: Decrease timeout when uploading images is not an issue anymore
	async put(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.PUT, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request, 120000);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	// TODO: Decrease timeout when uploading images is not an issue anymore
	async patch(uri: string, data: JsonTransportValue): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.PATCH, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request, 120000);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	async post(uri: string, data: JsonTransportValue): Promise<RestResponse<JsonTransportValue>> {
		let request = new Request(
			uri,
			this.buildRequestOptionsWithBody(HttpMethod.POST, data)
		);
		request = this.preRequest(request);
		let response = await this.perform(request, 60000);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	async delete(uri: string): Promise<RestResponse<Nullable<JsonTransportValue>>> {
		let request = new Request(
			uri,
			this.buildRequestOptions(HttpMethod.DELETE)
		);
		request = this.preRequest(request);
		let response = await this.perform(request);
		response = this.postRequest(response);
		this.preProcessResponse(response.status);
		const responseBody = await this.unwrapResponse(response);
		return {
			body: this.handleResponse(response.status, responseBody),
			headers: this.unwrapHeaders(response)
		};
	}

	abort(): void {
		this.abortController.abort();
	}

	private buildRequestOptions(method: HttpMethod): RequestInit {
		const requestHeaders: Record<string, string> = {
			'Accept-Language': L10n.effectiveLocale() ?? '',
			'X-Local-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};
		const jsonWebToken = this.jsonWebToken?.accessToken ?? null;
		if (jsonWebToken !== null) {
			requestHeaders['Authorization'] = 'Bearer ' + jsonWebToken;
		}
		return {
			signal: this.abortController.signal,
			method: String(method),
			cache: 'no-cache',
			headers: requestHeaders
		};
	}

	private buildRequestOptionsWithBody(method: HttpMethod, body: JsonTransportValue): RequestInit {
		return {
			...this.buildRequestOptions(method),
			body: JSON.stringify(body)
		};
	}

	private async perform(request: Request, timeout = 20000): Promise<Response> {
		const response = await Timeout.wrap<Response>(fetch(request), timeout, new TimeoutError('Request timeout'), (): void => {
			this.abortController.abort();
		});
		if (isNode) {
			// Do not clone the response object if in node environment due to a "feature" (which causes issues in our use cases) with
			// node-fetch; see: https://github.com/node-fetch/node-fetch/issues/396 for deeper insights
			return response;
		}
		return response.clone();
	}

	private async unwrapResponse(response: Response): Promise<Nullable<JsonTransportValue>> {
		if (response === null) {
			return null;
		}
		try {
			return await response.json();
		} catch (e) {
			throw new ApiError('Parse response failed with message: ' + (e as AppError).message);
		}
	}

	private unwrapHeaders(response: Response): Map<string, string> {
		const headers = new Map();
		if (response === null || response.headers === null) {
			return headers;
		}
		response.headers.forEach((value, key) => {
			headers.set(key.toLowerCase(), value);
		});
		return headers;
	}

	private preProcessResponse(responseStatus: number): void {
		if (responseStatus < 200) {
			throw new NetworkError('Request failed');
		}
		if (responseStatus === 401) {
			throw new AuthenticationRequiredError('Authentication required');
		}
	}

	private handleResponse(responseStatus: number, responseBody: Nullable<JsonTransportValue>): Nullable<JsonTransportValue> {
		if (responseStatus >= 200 && responseStatus < 300) {
			return responseBody;
		}

		const exceptionResponseBody = responseBody as JsonExceptionTransportValue;

		if (responseStatus === 400) {
			throw new ValidationError(
				'Invalid request',
				exceptionResponseBody?.status,
				exceptionResponseBody?.timestamp,
				exceptionResponseBody?.details
			);
		}
		if (responseStatus === 403) {
			throw new AccessDeniedError(
				'Forbidden',
				exceptionResponseBody?.status,
				exceptionResponseBody?.timestamp
			);
		}
		if (responseStatus === 404) {
			throw new NotFoundError(
				'Not found',
				exceptionResponseBody?.status,
				exceptionResponseBody?.timestamp
			);
		}
		if (responseStatus === 408) {
			throw new TimeoutError(
				'Response timeout',
				exceptionResponseBody?.status,
				exceptionResponseBody?.timestamp
			);
		}
		if (responseStatus === 425) {
			throw new TooEarlyError(
				'Too early',
				exceptionResponseBody?.status,
				exceptionResponseBody?.timestamp
			);
		}
		if (responseStatus >= 400) {
			throw new ApiError(
				'Unexpected response',
				exceptionResponseBody?.status ?? responseStatus,
				exceptionResponseBody?.timestamp
			);
		}
		throw new AppError('HTTP connector error');
	}

}
