import { Nullable, trim } from '@abb-emobility/shared/util';

export type HypermediaLink = {
	link: string,
	rel: string,
	title: Nullable<string>
};

export type HypermediaResponse = Array<HypermediaLink>;

export type HypermediaEntityResponse<Model> = {
	item: Nullable<Model>,
	hypermediaLinks: HypermediaResponse
};

export const extractHypermediaResponse = (responseHeaders: Map<string, string>): HypermediaResponse => {
	const linkHeaderEntry = responseHeaders.get('link');
	if (linkHeaderEntry === undefined) {
		return [];
	}
	const links = linkHeaderEntry.split(',')
		.map((fragment): Nullable<HypermediaLink> => {
			const values = fragment.trim().split(';').map((value) => {
				return value.trim();
			});
			let link: Nullable<string> = null;
			let rel: Nullable<string> = null;
			let title: Nullable<string> = null;
			for (const value of values) {
				if (value.startsWith('<') && value.endsWith('>')) {
					link = value.substring(1, value.length - 1);
				}
				if (value.toLowerCase().startsWith('rel=')) {
					rel = trim(value.substring(4), '"');
				}

				if (value.toLowerCase().startsWith('title=')) {
					title = trim(value.substring(6), '"');
				}
			}

			if (link === null || rel === null) {
				return null;
			}

			return { link, rel, title };
		})
		.filter((fragment): fragment is HypermediaLink => {
			return fragment !== null;
		});
	return links;
};
