import { CurrencyCode } from '@abb-emobility/shared/currency';
import { QuotationModel } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerDeltaInstallationPackageModelMock01,
	customerDeltaInstallationPackageModelMock02
} from './CustomerDeltaInstallationPackageModel.mock';

export const quotationModelMock: Dto<QuotationModel> = {
	items: [
		customerDeltaInstallationPackageModelMock01,
		customerDeltaInstallationPackageModelMock02
	],
	quotationConditions: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vestibulum id ligula porta felis euismod semper. Donec ullamcorper nulla non metus auctor fringilla.',
	grossPrice: 1325,
	netPrice: 1325 / 1.19,
	vatRate: 19,
	currencyCode: CurrencyCode.EUR
};
