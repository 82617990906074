import { CurrencyCode } from '@abb-emobility/shared/currency';
import {
	numericApiSafeguard,
	OrderedInstallationPartnerDeltaInstallationPackageModel,
	OrderedInstallationPartnerDeltaInstallationPackageModelConverter
} from '@abb-emobility/shared/domain-model';
import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationPartnerOrderInformationQuotationInformationModel = {
	readonly deltaPackages: Array<OrderedInstallationPartnerDeltaInstallationPackageModel>,
	readonly totalPurchaseNetPrice: number,
	readonly currencyCode: CurrencyCode,
	readonly conditions?: string
};

export class InstallationPartnerOrderInformationQuotationInformationModelConverter extends ModelConverter<InstallationPartnerOrderInformationQuotationInformationModel> {
	override getFieldConverterMapByModel(): Map<keyof InstallationPartnerOrderInformationQuotationInformationModel, ModelConverterInterface<OrderedInstallationPartnerDeltaInstallationPackageModel>> {
		return new Map([
			['deltaPackages', new OrderedInstallationPartnerDeltaInstallationPackageModelConverter()]
		]);
	}

	override getCustomConversionFields(): Map<keyof InstallationPartnerOrderInformationQuotationInformationModel, CustomConversion> {
		return new Map([
			['totalPurchaseNetPrice', numericApiSafeguard]
		]);
	}

}
