import { InstallationPartnerOrderApiClientFactory, InstallationPartnerOrderApiClientInterface } from '@abb-emobility/oms/api-integration';
import { InstallationPartnerOrderModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const installationPartnerOrderEntityStoreName = 'installation-partner-order-entity-crud';
export type InstallationPartnerOrderEntityStore = { [installationPartnerOrderEntityStoreName]: CrudEntityStore<InstallationPartnerOrderModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return InstallationPartnerOrderApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<InstallationPartnerOrderModel, InstallationPartnerOrderEntityStore, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<InstallationPartnerOrderModel, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<InstallationPartnerOrderModel, InstallationPartnerOrderApiClientInterface>(installationPartnerOrderEntityStoreName, createApiClient);

// Slice definition
export const installationPartnerOrderEntitySlice = createCrudEntitySlice<InstallationPartnerOrderModel, ThunkApiConfig>(installationPartnerOrderEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = installationPartnerOrderEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<InstallationPartnerOrderModel, InstallationPartnerOrderEntityStore>(installationPartnerOrderEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<InstallationPartnerOrderModel, InstallationPartnerOrderEntityStore>(installationPartnerOrderEntityStoreName);
const storeSize = createCrudEntityStoreSize<InstallationPartnerOrderModel, InstallationPartnerOrderEntityStore>(installationPartnerOrderEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<InstallationPartnerOrderModel, InstallationPartnerOrderEntityStore>(installationPartnerOrderEntityStoreName);

// Export the store accessors to be used by the data provider value
export const installationPartnerOrderEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<InstallationPartnerOrderModel, InstallationPartnerOrderEntityStore>;
