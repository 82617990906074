import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { OtpAuthApiClient } from './OtpAuthApiClient';
import { OtpAuthApiClientInterface } from './OtpAuthApiClientInterface';
import { OtpAuthApiClientMock } from './OtpAuthApiClientMock';

export class OtpAuthApiClientFactory {

	private static mockConfigured = false;

	public static create(apiBaseUrl: string, orderId: ModelPrimaryKey): OtpAuthApiClientInterface {
		if (process.env.NX_OMS_OTP_AUTH_API_MOCKED === 'true') {
			const apiClientMock = new OtpAuthApiClientMock(apiBaseUrl, orderId);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock(apiBaseUrl + 'login/');
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new OtpAuthApiClient(apiBaseUrl, orderId);
	}

}
