/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelConverter, ModelConverterInterface, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerOrderInformationShippingLineItemModel, CustomerOrderInformationShippingLineItemModelConverter
} from './CustomerOrderInformationShippingLineItemModel';

export type CustomerOrderInformationShipmentInformationModel = {
	readonly carrierName: string,
	readonly trackingNumber: string,
	readonly recipientName: string,
	readonly shippingAddress: string,
	readonly shippingLineItems: Array<CustomerOrderInformationShippingLineItemModel>
	readonly trackingUrl?: string
};

export class CustomerOrderInformationShipmentInformationModelConverter extends ModelConverter<CustomerOrderInformationShipmentInformationModel> {

	override getFieldConverterMapByModel(): Map<keyof CustomerOrderInformationShipmentInformationModel, ModelConverterInterface<any>> {
		return new Map<keyof CustomerOrderInformationShipmentInformationModel, any>([
			['shippingLineItems', new CustomerOrderInformationShippingLineItemModelConverter()]
		]);
	}
}

export type CustomerOrderInformationShippingInformationModel = {
	readonly id: ModelPrimaryKey,
	readonly shipments?: Array<CustomerOrderInformationShipmentInformationModel>
};

export class CustomerOrderInformationShippingInformationModelConverter extends ModelConverter<CustomerOrderInformationShippingInformationModel> {

	override getFieldConverterMapByModel(): Map<keyof CustomerOrderInformationShippingInformationModel, ModelConverterInterface<any>> {
		return new Map<keyof CustomerOrderInformationShippingInformationModel, any>([
			['shipments', new CustomerOrderInformationShipmentInformationModelConverter()]
		]);
	}

}
