import { InstallationPartnerOrderLineItemModel } from '@abb-emobility/oms/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

const installationPartnerOrderLineItemModelMock01: Dto<InstallationPartnerOrderLineItemModel> = {
	id: '1',
	productName: 'Extended warranty 2 years',
	productCode: '77351'
};

const installationPartnerOrderLineItemModelMock02: Dto<InstallationPartnerOrderLineItemModel> = {
	id: '2',
	productName: 'Terra AC Wallbox, IP54',
	productCode: '77352'
};

const installationPartnerOrderLineItemModelMock03: Dto<InstallationPartnerOrderLineItemModel> = {
	id: '3',
	productName: 'TAC pedestal single-wallbox',
	productCode: '77353'
};

export const installationPartnerOrderLineItemModelMockCollection = ():Array<Dto<InstallationPartnerOrderLineItemModel>> => {
	return [
		installationPartnerOrderLineItemModelMock01,
		installationPartnerOrderLineItemModelMock02,
		installationPartnerOrderLineItemModelMock03
	];
};
