import { ChangeEvent, ReactNode, TextareaHTMLAttributes } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputTextArea.scss';

export type InputTextAreaProps = {
	label: string,
	notificationElement?: ReactNode,
	icon?: IconIdentifier,
	onChange?: (value: string) => void
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>;

export function InputTextArea(props: InputTextAreaProps) {

	const { label, notificationElement, icon = null, required = false, onChange = null, ...rest } = props;

	const l10n = useL10n();

	const inputClassMap = {
		'input-text-area__field__input': true,
		'input-text-area__field__input--icon': icon !== null
	};

	// event handling
	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		if (onChange !== null) {
			onChange(event.target.value);
		}
	};

	const renderIcon = () => {
		if (icon === null) {
			return;
		}
		return (
			<span className="input-text-area__field__icon">
				<Icon name={icon} />
			</span>
		);
	};

	const renderLabel = (): ReactNode => {
		const labelSuffix = required ? null : (
			<span className="input-text-area__label__suffix">
				{l10n.translate('omsCustomerUiPrimitive.input.optionalValueSuffix')}
			</span>
		);
		return (
			<span className="input-text-area__label">
				{label}
				{labelSuffix}
			</span>
		);
	};

	return (
		<label className="input-text-area">
			{renderLabel()}
			{notificationElement}
			<span className="input-text-area__field">
				<textarea
					onChange={handleChange}
					className={buildCssClassStringFromClassMap(inputClassMap)}
					required={required}
					{...rest}
				/>
				{renderIcon()}
			</span>
		</label>
	);
}
