import { ApiError } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { DateRangeDto, ArrayOfDateRangeFieldNames, DateRange, DateRangeFieldNames } from '../member-types/DateRange';
import { createDateRangeDtoFromDateRange, createDateRangeFromDateRangeDto } from '../member-types/DateRange.util';
import { DownloadFileDto, ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '../member-types/DownloadFile';
import { createDownloadFileDtoFromDownloadFile, createDownloadFileFromDownloadFileDto } from '../member-types/DownloadFile.util';
import { TimestampDto, ArrayOfTimestampFieldNames, Timestamp, TimestampFieldNames } from '../member-types/Timestamp';
import { createTimestampDtoFromTimestamp, createTimestampFromTimestampDto } from '../member-types/Timestamp.util';
import { UploadFileDto, ArrayOfUploadFileFieldNames, UploadFile, UploadFileFieldNames } from '../member-types/UploadFile';
import { createUploadFileDtoFromUploadFile, createUploadFileFromUploadFileDto } from '../member-types/UploadFile.util';
import { UrlDto, ArrayOfUrlFieldNames, Url, UrlFieldNames } from '../member-types/Url';
import { createUrlDtoFromUrl, createUrlFromUrlDto } from '../member-types/Url.util';
import { Dto } from '../model-types/Dto';
import { ModelValue } from '../model-types/Model';
import { Mutable, Mutation } from '../model-types/ModelMutation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomConversion = { toModel: (dtoValue: any) => any, toDto: (modelValue: any) => any };

export interface ModelConverterInterface<Model> {

	toDto(model: Model | Mutable<Model> | Mutation<Model>): Dto<Model>;

	toModel(dto: Dto<Model>): Model;

	getCustomConversionFields(): Map<keyof Model, CustomConversion>;

	getTimestampFields(): Array<TimestampFieldNames<Model> | ArrayOfTimestampFieldNames<Model>>;

	getDateRangeFields(): Array<DateRangeFieldNames<Model> | ArrayOfDateRangeFieldNames<Model>>;

	getUrlFields(): Array<UrlFieldNames<Model> | ArrayOfUrlFieldNames<Model>>;

	getUploadFileFields(): Array<UploadFileFieldNames<Model> | ArrayOfUploadFileFieldNames<Model>>;

	getDownloadFileFields(): Array<DownloadFileFieldNames<Model> | ArrayOfDownloadFileFieldNames<Model>>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getFieldConverterMapByModel(model: Model | Dto<Model>): Map<keyof Model, ModelConverterInterface<any>>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getFieldConverterByModel(fieldName: keyof Model, model: Model | Dto<Model>): Nullable<ModelConverterInterface<any>>;

}

export abstract class ModelConverter<Model> implements ModelConverterInterface<Model> {

	getCustomConversionFields(): Map<keyof Model, CustomConversion> {
		return new Map();
	}

	public getTimestampFields(): Array<TimestampFieldNames<Model> | ArrayOfTimestampFieldNames<Model>> {
		return [];
	}

	public getDateRangeFields(): Array<DateRangeFieldNames<Model> | ArrayOfDateRangeFieldNames<Model>> {
		return [];
	}

	public getUrlFields(): Array<UrlFieldNames<Model> | ArrayOfUrlFieldNames<Model>> {
		return [];
	}

	public getUploadFileFields(): Array<UploadFileFieldNames<Model> | ArrayOfUploadFileFieldNames<Model>> {
		return [];
	}

	public getDownloadFileFields(): Array<DownloadFileFieldNames<Model> | ArrayOfDownloadFileFieldNames<Model>> {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getFieldConverterMapByModel(_model: Model | Dto<Model>): Map<keyof Model, ModelConverterInterface<any>> {
		return new Map();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getFieldConverterByModel(fieldName: keyof Model, model: Model | Dto<Model>): Nullable<ModelConverterInterface<any>> {
		return this.getFieldConverterMapByModel(model).get(fieldName) ?? null;
	}

	public toModel(dto: Dto<Model>): Model {
		const model = { ...dto } as Record<string, ModelValue>;

		this.getCustomConversionFields().forEach((converters, field) => {
			model[field as string] = converters.toModel(dto?.[field]);
		});

		for (const timestampField of this.getTimestampFields()) {
			const timestampValue = dto?.[timestampField] as TimestampDto | Array<TimestampDto> ?? null;
			if (Array.isArray(timestampValue)) {
				model[timestampField as string] = timestampValue.map((timestampDto) => {
					return createTimestampFromTimestampDto(timestampDto).getOrThrow(
						new ApiError('Expected array of unix timestamp for field ' + timestampField.toString() + '. Got ' + timestampValue)
					);
				});
				continue;
			}
			if (timestampValue !== null) {
				model[timestampField as string] = createTimestampFromTimestampDto(timestampValue).get();
			}
		}

		for (const dateRangeField of this.getDateRangeFields()) {
			const dateRangeValue = dto?.[dateRangeField] as DateRangeDto | Array<DateRangeDto> ?? null;
			if (Array.isArray(dateRangeValue)) {
				model[dateRangeField as string] = dateRangeValue.map((timestampDto) => {
					return createDateRangeFromDateRangeDto(timestampDto);
				});
				continue;
			}
			if (dateRangeValue !== null) {
				model[dateRangeField as string] = createDateRangeFromDateRangeDto(dateRangeValue);
			}
		}

		for (const urlField of this.getUrlFields()) {
			const urlValue = dto?.[urlField] as UrlDto | Array<UrlDto> ?? null;
			if (Array.isArray(urlValue)) {
				model[urlField as string] = urlValue.map((urlDto) => {
					return createUrlFromUrlDto(urlDto).get();
				});
				continue;
			}
			if (urlValue !== null) {
				model[urlField as string] = createUrlFromUrlDto(urlValue).get();
			}
		}

		for (const uploadFileField of this.getUploadFileFields()) {
			const fileValue = dto?.[uploadFileField] as UploadFileDto | Array<UploadFileDto> ?? null;
			if (Array.isArray(fileValue)) {
				model[uploadFileField as string] = fileValue.map((fileDto) => {
					return createUploadFileFromUploadFileDto(fileDto).get();
				});
				continue;
			}
			if (fileValue !== null) {
				model[uploadFileField as string] = createUploadFileFromUploadFileDto(fileValue).get();
			}
		}

		for (const downloadFileField of this.getDownloadFileFields()) {
			const fileValue = dto?.[downloadFileField] as DownloadFileDto | Array<DownloadFileDto> ?? null;
			if (Array.isArray(fileValue)) {
				model[downloadFileField as string] = fileValue.map((fileDto) => {
					return createDownloadFileFromDownloadFileDto(fileDto).get();
				});
				continue;
			}
			if (fileValue !== null) {
				model[downloadFileField as string] = createDownloadFileFromDownloadFileDto(fileValue).get();
			}
		}

		for (const convertableField of this.getFieldConverterMapKeys(dto)) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const modelValue = dto?.[convertableField] as Record<string, any> ?? null;
			if (Array.isArray(modelValue)) {
				model[convertableField as string] = modelValue.map((modelValueItem) => {
					const convertedModelValueItem = this.getFieldConverterByModel(convertableField, dto)?.toModel(modelValueItem) ?? null;
					if (convertedModelValueItem === null) {
						throw new ApiError('Expected array of convertables for field ' + convertableField.toString() + '. Got ' + modelValue);
					}
					return convertedModelValueItem;
				});
				continue;
			}
			if (modelValue !== null) {
				model[convertableField as string] = this.getFieldConverterByModel(convertableField, dto)?.toModel(modelValue) ?? null;
			}
		}

		return model as unknown as Model;
	}

	public toDto(model: Mutable<Model> | Mutation<Model> | Model): Dto<Model> {
		const dto = { ...model } as Record<string, ModelValue>;

		for (const modelField in dto) {

			if (this.isCustomConverterField(modelField as keyof Model)) {
				const customValue = this.getCustomConversionFields().get(modelField as keyof Model)?.toDto(dto?.[modelField]) ?? null;
				if (customValue !== null) {
					dto[modelField] = customValue;
				}
				continue;
			}

			if (this.isTimestampField(modelField as keyof Model)) {
				const timestampValue = dto?.[modelField] as Timestamp | Array<Timestamp> ?? null;
				if (Array.isArray(timestampValue)) {
					dto[modelField] = timestampValue.map((timestamp) => {
						return createTimestampDtoFromTimestamp(timestamp).getOrThrow(
							new ApiError('Expected array of timestamp for field ' + modelField + '. Got ' + timestampValue)
						);
					});
					continue;
				}
				if (timestampValue !== null) {
					dto[modelField] = createTimestampDtoFromTimestamp(timestampValue).get();
				}
				continue;
			}

			if (this.isDateRangeField(modelField as keyof Model)) {
				const dateRangeValue = dto?.[modelField] as DateRange | Array<DateRange> ?? null;
				if (Array.isArray(dateRangeValue)) {
					dto[modelField] = dateRangeValue.map((dateRange) => {
						return createDateRangeDtoFromDateRange(dateRange).getOrThrow(
							new ApiError('Expected array of date range for field ' + modelField + '. Got ' + dateRangeValue)
						);
					});
					continue;
				}
				if (dateRangeValue !== null) {
					dto[modelField] = createDateRangeDtoFromDateRange(dateRangeValue).get();
				}
				continue;
			}

			if (this.isUrlField(modelField as keyof Model)) {
				const urlValue = dto?.[modelField] as Url | Array<Url> ?? null;
				if (Array.isArray(urlValue)) {
					dto[modelField] = urlValue.map((url) => {
						return createUrlDtoFromUrl(url).getOrThrow(
							new ApiError('Expected array of URL for field ' + modelField + '. Got ' + urlValue)
						);
					});
					continue;
				}
				if (urlValue !== null) {
					dto[modelField] = createUrlDtoFromUrl(urlValue).get();
				}
				continue;
			}

			if (this.isUploadFileField(modelField as keyof Model)) {
				const uploadFileValue = dto?.[modelField] as UploadFile | Array<UploadFile> ?? null;
				if (Array.isArray(uploadFileValue)) {
					dto[modelField] = uploadFileValue.map((file) => {
						return createUploadFileDtoFromUploadFile(file).getOrThrow(
							new ApiError('Expected array of file for field ' + modelField + '. Got ' + uploadFileValue)
						);
					});
					continue;
				}
				if (uploadFileValue !== null) {
					dto[modelField] = createUploadFileDtoFromUploadFile(uploadFileValue).get();
				}
				continue;
			}

			if (this.isDownloadFileField(modelField as keyof Model)) {
				const downloadFileValue = dto?.[modelField] as DownloadFile | Array<DownloadFile> ?? null;
				if (Array.isArray(downloadFileValue)) {
					dto[modelField] = downloadFileValue.map((file) => {
						return createDownloadFileDtoFromDownloadFile(file).getOrThrow(
							new ApiError('Expected array of file for field ' + modelField + '. Got ' + downloadFileValue)
						);
					});
					continue;
				}
				if (downloadFileValue !== null) {
					dto[modelField] = createDownloadFileDtoFromDownloadFile(downloadFileValue).get();
				}
				continue;
			}

			if (this.isConvertableField(modelField as keyof Model, model as Model)) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const modelValue = dto?.[modelField] as Record<string, any> ?? null;
				if (Array.isArray(modelValue)) {
					dto[modelField] = modelValue.map((modelValueItem) => {
						const convertedModelItem = this.getFieldConverterByModel(modelField as keyof Model, model as Model)?.toDto(modelValueItem) ?? null;
						if (convertedModelItem === null) {
							throw new ApiError('Expected array of convertables for field ' + modelField + '. Got ' + modelValue);
						}
						return convertedModelItem;
					});
					continue;
				}
				if (modelValue !== null) {
					dto[modelField] = this.getFieldConverterByModel(modelField as keyof Model, model as Model)?.toDto(modelValue) ?? null;
				}
			}

		}

		return dto as Dto<Model>;
	}

	private isCustomConverterField(fieldName: keyof Model): boolean {
		return Array.from(this.getCustomConversionFields().keys()).includes(fieldName);
	}

	private isTimestampField(fieldName: keyof Model): boolean {
		return this.getTimestampFields().includes(fieldName as TimestampFieldNames<Model>);
	}

	private isDateRangeField(fieldName: keyof Model): boolean {
		return this.getDateRangeFields().includes(fieldName as DateRangeFieldNames<Model>);
	}

	private isUrlField(fieldName: keyof Model): boolean {
		return this.getUrlFields().includes(fieldName as UrlFieldNames<Model>);
	}

	private isUploadFileField(fieldName: keyof Model): boolean {
		return this.getUploadFileFields().includes(fieldName as UploadFileFieldNames<Model>);
	}

	private isDownloadFileField(fieldName: keyof Model): boolean {
		return this.getDownloadFileFields().includes(fieldName as DownloadFileFieldNames<Model>);
	}

	private isConvertableField(fieldName: keyof Model, model: Model | Dto<Model>): boolean {
		return this.getFieldConverterMapByModel(model).has(fieldName);
	}

	private getFieldConverterMapKeys(model: Model | Dto<Model>): IterableIterator<keyof Model> {
		return this.getFieldConverterMapByModel(model).keys();
	}

}
