import React from 'react';

import { AnyCustomerOrderInformationCollectionItemModel } from '@abb-emobility/oms/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerOrderInformationCollectionData } from './CustomerOrderInformationCollectionData';
import {
	CustomerOrderInformationCollectionCrudStore,
	customerOrderInformationCollectionStoreAccessors,
	customerOrderInformationCollectionStoreName
} from './CustomerOrderInformationCollectionSlice';

type CustomerOrderInformationCollectionDataProviderProps =
	Omit<CollectionDataProviderProps<AnyCustomerOrderInformationCollectionItemModel>, 'filterCriteria' | 'sortCriteria'>
	& { orderId: ModelPrimaryKey };

export function CustomerOrderInformationCollectionDataProvider(props: CustomerOrderInformationCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_APP_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<AnyCustomerOrderInformationCollectionItemModel, CustomerOrderInformationCollectionCrudStore>(
		customerOrderInformationCollectionStoreName,
		customerOrderInformationCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('omsDataProvider.error.orderInformationCollectionFetchMessage')));

	const sortCriteria: SortCriteria<AnyCustomerOrderInformationCollectionItemModel> = [
		{ property: 'createdAt', direction: SortDirection.ASCENDING }
	];
	const filterCriteria: FilterCriteria<AnyCustomerOrderInformationCollectionItemModel> = [{
		id: 'ORDER_ID',
		criteria: [{
			property: 'orderId',
			comparator: SingleValueFilterComparator.EQUAL,
			value: orderId
		}]
	}];
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(sortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerOrderInformationCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerOrderInformationCollectionData.Provider>
	);

}
