import { QuotationModel, QuotationModelConverter } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	ModelConverterInterface,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

type CancellationState = {
	readonly cancelled: true,
	readonly cancelledAt: Timestamp
} | {
	readonly cancelled: false,
	readonly cancelledAt?: never
};

export type CustomerOrderInformationQuotationInformationModel = {
	readonly quotation: QuotationModel
} & CancellationState;

export class CustomerOrderInformationQuotationInformationModelConverter extends ModelConverter<CustomerOrderInformationQuotationInformationModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof CustomerOrderInformationQuotationInformationModel, ModelConverterInterface<any>> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return new Map([
			['quotation', new QuotationModelConverter()]
		]);
	}

	override getTimestampFields(): Array<TimestampFieldNames<CustomerOrderInformationQuotationInformationModel> | ArrayOfTimestampFieldNames<CustomerOrderInformationQuotationInformationModel>> {
		return ['cancelledAt'];
	}

}
