/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CustomerModel, CustomerModelConverter,
	InstallationPartnerDeltaInstallationPackageModel, InstallationPartnerDeltaInstallationPackageModelConverter,
	numericApiSafeguard,
	TaskCompletionCommentModel
} from '@abb-emobility/shared/domain-model';
import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import {
	PersistedAnswersDto,
	QuestionnaireEnvironmentModel,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export type SelectedDeltaInstallationModel = {
	deltaInstallationPackageId: ModelPrimaryKey,
	amount: number
};

export class SelectedDeltaInstallationModelConverter extends ModelConverter<SelectedDeltaInstallationModel> {

	override getCustomConversionFields(): Map<keyof SelectedDeltaInstallationModel, CustomConversion> {
		return new Map([
			['amount', numericApiSafeguard]
		]);
	}

}

export type TradeEffort = {
	trade: string,
	effort: number
};

export class TradeEffortConverter extends ModelConverter<TradeEffort> {

	override getCustomConversionFields(): Map<keyof TradeEffort, CustomConversion> {
		return new Map([
			['effort', numericApiSafeguard]
		]);
	}

}

export type QuotationPayloadModel = {
	readonly deltaInstallationPackages: Array<InstallationPartnerDeltaInstallationPackageModel>,
	readonly requestedTradeEfforts: Array<TradeEffort>,
	readonly customer: CustomerModel,
	// TODO: Add the member to the API and define it as mandatory; see https://jira.fiege.com/browse/ABB-1055
	readonly questionnaire?: AnyQuestionnaireModel,
	readonly questionnaireEnvironment?: QuestionnaireEnvironmentModel,
	readonly answers?: PersistedAnswersDto
	// TODO: Add the member to the API and define it as mandatory; see https://jira.fiege.com/browse/ABB-1056
	readonly comments?: Array<TaskCompletionCommentModel>,
	selectedDeltaInstallationPackages?: Array<SelectedDeltaInstallationModel>,
	quotationConditions?: string
} & TaskPayloadFoundationModel;

export class QuotationPayloadModelConverter extends ModelConverter<QuotationPayloadModel> {

	override getFieldConverterMapByModel(): Map<keyof QuotationPayloadModel, ModelConverterInterface<any>> {
		return new Map<
			keyof QuotationPayloadModel,
			ModelConverterInterface<InstallationPartnerDeltaInstallationPackageModel> |
			ModelConverterInterface<TradeEffort> |
			ModelConverterInterface<CustomerModel> |
			ModelConverterInterface<SelectedDeltaInstallationModel>
		>([
			['deltaInstallationPackages', new InstallationPartnerDeltaInstallationPackageModelConverter()],
			['requestedTradeEfforts', new TradeEffortConverter()],
			['customer', new CustomerModelConverter()],
			['selectedDeltaInstallationPackages', new SelectedDeltaInstallationModelConverter()]
		]);
	}
}

export type QuotationEscalationPayloadModel =
	QuotationPayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

/**
 * QuotationPayloadModelConverter combines the functionality of the
 * - CustomerServiceTaskPayloadFoundationModelConverter
 * - PreCheckRejectedByGridOperatorPayloadModelConverter
 */
export class QuotationEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<QuotationEscalationPayloadModel> {
	private payloadModelConverter = new QuotationPayloadModelConverter();

	override getFieldConverterMapByModel(): Map<keyof QuotationEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
