import { ReactNode, useState } from 'react';

import {
	ButtonBar,
	ButtonBarSection,
	ButtonBarSectionAlign,
	ButtonPrimary,
	Form,
	FormSection,
	FormSegement,
	InputCheckbox,
	Separator,
	SpinnerCable,
	SuccessFeeback
} from '@abb-emobility/oms/customer-ui-primitive';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { InstallationApprovalPayloadModel, InstallationApprovalTaskModel } from '@abb-emobility/usertask/domain-model';

export type TaskinstallationApprovalProps = {
	task: InstallationApprovalTaskModel
};

export function TaskInstallationApproval(props: TaskinstallationApprovalProps) {

	const { task } = props;

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const actionStatus = anyTaskEntityData.queryActionStatus();

	const [taskPayload, setTaskPayload] = useState<InstallationApprovalPayloadModel>(task.payload);

	const handleApproval = (approved: boolean): void => {
		setTaskPayload({ ...taskPayload, installationApproved: approved });
	};

	const handleSubmit = (): void => {
		anyTaskEntityData.complete(task, taskPayload);
	};

	const renderPayload = (): ReactNode => {
		switch (actionStatus) {
			case TaskActionStatus.COMPLETE_PENDING:
				return (<SpinnerCable />);
			case TaskActionStatus.COMPLETE_SUCCESS:
				return (
					<SuccessFeeback
						heading={l10n.translate('omsCustomerApp.task.installationApproval.completionSuccess.heading')}
						message={l10n.translate('omsCustomerApp.task.installationApproval.completionSuccess.message')}
					/>
				);
			default:
				return (
					<Form>
						<FormSegement description={l10n.translate('omsCustomerApp.task.installationApproval.message')}>
							<FormSection heading={l10n.translate('omsCustomerApp.task.installationApproval.approval.caption')}>
								<InputCheckbox
									label={l10n.translate('omsCustomerApp.task.installationApproval.approval.label')}
									value="approved"
									defaultChecked={false}
									onChange={handleApproval}
								/>
							</FormSection>
							<Separator />
						</FormSegement>
						<ButtonBar>
							<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
								<ButtonPrimary
									type="submit"
									label={l10n.translate('omsCustomerApp.task.installationApproval.submitButtonLabel')}
									disabled={!taskPayload.installationApproved}
									onClick={handleSubmit}
								/>
							</ButtonBarSection>
						</ButtonBar>
					</Form>
				);
		}
	};

	return (
		<>
			{renderPayload()}
		</>
	);
}
