import { performBusinessRule } from './BusinessRuleUtil';
import { QuestionnaireSegment } from '../types/QuestionnaireModel';
import { Answers, PersistedAnswers } from '../types/answer/Answers';
import { QuestionnaireBusinessRuleCombinator } from '../types/business-rule/BusinessRule';
import { QuestionnaireEnvironmentModel } from '../types/environment/QuestionnaireEnvironmentModel';

export const isSegmentInEffect = (
	segment: QuestionnaireSegment,
	answers: Answers | PersistedAnswers,
	environment?: QuestionnaireEnvironmentModel
): boolean => {
	const rules = segment.businessRules?.rules ?? [];
	if (rules.length === 0) {
		return true;
	}

	const combinator =
		segment.businessRules?.combinator ??
		QuestionnaireBusinessRuleCombinator.AND;
	const results = rules.map((rule): boolean => {
		return performBusinessRule(rule, answers, environment);
	});

	let combinedResult: boolean;
	switch (combinator) {
		case QuestionnaireBusinessRuleCombinator.AND:
			combinedResult = true;
			combinedResult = results.reduce((result, combinedResult) => {
				return !result ? false : combinedResult;
			});
			break;
		case QuestionnaireBusinessRuleCombinator.OR:
			combinedResult = false;
			combinedResult = results.reduce((result, combinedResult) => {
				return result ? true : combinedResult;
			});
			break;
	}

	return combinedResult;
};
