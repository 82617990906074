import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiDomain: {
		deltaQuotationItem: {
			additionalExpenses: 'Coste adicional total',
			vatInformation: 'IVA incluido ({{vatRate}}%)'
		}
	}
};

export default literals;
