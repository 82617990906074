import { CustomConversion, ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { DeltaInstallationPackageFoundationModel } from './foundation/DeltaInstallationPackageFoundationModel';
import { numericApiSafeguard } from '../converter/NumericSafeGuard';

export type InstallationPartnerDeltaInstallationPackageModel = {
	readonly purchaseNetPrice: number,
	readonly salesNetPrice?: number,
	readonly salesGrossPrice?: number,
	readonly vatRate?: number
} & DeltaInstallationPackageFoundationModel;

export class InstallationPartnerDeltaInstallationPackageModelConverter extends ModelConverter<InstallationPartnerDeltaInstallationPackageModel> {

	override getCustomConversionFields(): Map<keyof InstallationPartnerDeltaInstallationPackageModel, CustomConversion> {
		return new Map([
			['purchaseNetPrice', numericApiSafeguard],
			['salesNetPrice', numericApiSafeguard],
			['salesGrossPrice', numericApiSafeguard],
			['vatRate', numericApiSafeguard]
		]);
	}

}
