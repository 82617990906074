import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export enum PreCheckMediumType {
	PHONE_CALL = 'PHONE_CALL',
	VIDEO_CALL = 'VIDEO_CALL'
}

export type PreCheckMediumModel = {
	id: ModelPrimaryKey,
	name: string,
	type: PreCheckMediumType
};

export class PreCheckMediumModelConverter extends ModelConverter<PreCheckMediumModel> {
}
