export enum TaskType {
	CUSTOMER_ANSWER_BASIC_QUESTIONS = 'CUSTOMER_ANSWER_BASIC_QUESTIONS',
	CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES = 'CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES',
	CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION = 'CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION',
	CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION = 'CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION',
	CUSTOMER_ACCEPT_QUOTATION = 'CUSTOMER_ACCEPT_QUOTATION',
	CUSTOMER_PERFORM_PAYMENT = 'CUSTOMER_PERFORM_PAYMENT',
	CUSTOMER_APPROVE_INSTALLATION = 'CUSTOMER_APPROVE_INSTALLATION',
	INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS = 'INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS',
	INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK = 'INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK',
	INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR = 'INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR',
	INSTALLATION_PARTNER_REVOKE_GRID_OPERATOR_REGISTRATION = 'INSTALLATION_PARTNER_REVOKE_GRID_OPERATOR_REGISTRATION',
	INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION = 'INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION',
	INSTALLATION_PARTNER_PROVIDE_QUOTATION = 'INSTALLATION_PARTNER_PROVIDE_QUOTATION',
	INSTALLATION_PARTNER_ARRANGE_APPOINTMENT = 'INSTALLATION_PARTNER_ARRANGE_APPOINTMENT',
	INSTALLATION_PARTNER_COMPLETE_INSTALLATION = 'INSTALLATION_PARTNER_COMPLETE_INSTALLATION',

	CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION = 'CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION',
	CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION = 'CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION',
	CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION = 'CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION',
	CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION = 'CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION',
	CUSTOMER_ACCEPT_QUOTATION_ESCALATION = 'CUSTOMER_ACCEPT_QUOTATION_ESCALATION',
	CUSTOMER_PERFORM_PAYMENT_ESCALATION = 'CUSTOMER_PERFORM_PAYMENT_ESCALATION',
	CUSTOMER_APPROVE_INSTALLATION_ESCALATION = 'CUSTOMER_APPROVE_INSTALLATION_ESCALATION',
	// TODO: Rename when backend changed
	CUSTOMER_ADDRESS_VALIDATION_ESCALATION = 'CUSTOMER_ADDRESS_VALIDATION_ESCALATION',
	CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS = 'CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS',
	INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION = 'INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION',
	INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION = 'INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION',
	INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION = 'INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION',
	INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION = 'INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION',
	INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION = 'INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION',
	INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION = 'INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION',
	INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION = 'INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION'
}
