export enum DaynameFormat {
	FUZZY = 'fuzzy',
	LONG = 'long',
	SHORT = 'short',
	NARROW = 'narrow'
}

export enum MonthnameFormat {
	LONG = 'long',
	SHORT = 'short',
	NARROW = 'narrow'
}

export enum YearFormat {
	FULL = 'numeric',
	SHORT = '2-digit'
}

export enum FuzzyDayname {
	TODAY = 'TODAY',
	TOMORROW = 'TOMORROW',
	YESTERDAY = 'YESTERDAY'
}
