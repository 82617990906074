import { FormEvent, FormHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from 'react';

import './Form.scss';

export type FormInputElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
export type FormInputValue = string | number | File;

export type FormItem = {
	name: string,
	value: FormInputValue
};

export type FormItems = Array<FormItem>;

export type FormProps = {
	children: ReactNode,
	onSubmit?: (data: FormItems) => void,
	onChange?: (key: string, value: FormInputValue, inputElement: FormInputElement) => void
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'onChange'>;

export const Form = forwardRef((props: FormProps, ref: ForwardedRef<HTMLFormElement>) => {
	const { children, onSubmit = null, onChange = null, ...rest } = props;

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		if (onSubmit !== null) {
			onSubmit(readFormItemsFromForm(event.currentTarget));
		}
		event.preventDefault();
	};

	const handleChange = (event: FormEvent<HTMLFormElement>) => {
		if (onChange !== null) {
			const inputElement = event.target as FormInputElement;
			const key = inputElement.name;
			const value = inputElement.value;
			onChange(key, value, inputElement);
		}
	};

	return (
		<form
			className="form"
			onSubmit={handleSubmit}
			onChange={handleChange}
			ref={ref}
			{...rest}
		>
			{children}
		</form>
	);
});

export const readFormItemsFromForm = (form: HTMLFormElement): FormItems => {
	const formItems: FormItems = [];
	const formData = new FormData(form);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line prefer-const
	for (let [name, value] of formData.entries()) {
		if (value instanceof File && value.size === 0) {
			value = null;
		}
		formItems.push({ name, value });
	}
	return formItems;
};
