/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	InstallationPeriod,
	InstallationTradeModel,
	InstallationTradeModelConverter,
	InstallationType,
	InstallerModel
} from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import {
	DateRange,
	ModelPrimaryKey,
	DateRangeFieldNames,
	ArrayOfDateRangeFieldNames, Timestamp, TimestampFieldNames, ArrayOfTimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

/**
 * This domain model is meant to be used to display an appointment to an installer. Appointment then means it is installation independant
 * and refers to a sepcific day.
 */
export type InstallerAppointmentModel = {
	readonly id: ModelPrimaryKey,
	readonly orderId: ModelPrimaryKey,

	readonly installer: InstallerModel,
	readonly trade: InstallationTradeModel,

	readonly completionTaskId?: ModelPrimaryKey,
	readonly period: DateRange,
	readonly periodStartsAt: Timestamp,
	readonly customerFirstName?: string,
	readonly customerLastName?: string,
	readonly customerCompanyName?: string,
	readonly installationAddress?: string,
	readonly installationType: InstallationType,
	readonly installationPeriod: InstallationPeriod
};

export class InstallerAppointmentModelConverter extends ModelConverter<InstallerAppointmentModel> {

	override getTimestampFields(): Array<TimestampFieldNames<InstallerAppointmentModel> | ArrayOfTimestampFieldNames<InstallerAppointmentModel>> {
		return ['periodStartsAt'];
	}

	override getDateRangeFields(): Array<DateRangeFieldNames<InstallerAppointmentModel> | ArrayOfDateRangeFieldNames<InstallerAppointmentModel>> {
		return ['period'];
	}

	override getFieldConverterMapByModel(): Map<keyof InstallerAppointmentModel, ModelConverterInterface<any>> {
		return new Map<keyof InstallerAppointmentModel, ModelConverterInterface<any>>([
			['trade', new InstallationTradeModelConverter()]
		]);
	}

}
