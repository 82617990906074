import { Optional, Probably } from '@abb-emobility/shared/util';

import { TimestampDto, Timestamp } from './Timestamp';
import { isTimestampDto, isTimestamp } from './Timestamp.guards';

export const createTimestampFromDate = (date: Date): Timestamp => {
	return {
		discriminator: 'Timestamp',
		value: date.toISOString()
	};
};

export const createDateFromTimestamp = (timestamp: Timestamp): Date => {
	return new Date(timestamp.value);
};

export const createDateFromMaybeTimestamp = (timestamp: Probably<Timestamp>): Optional<Date> => {
	const date = timestamp?.value ? new Date(timestamp.value) : null;
	return new Optional(date);
};

export const createTimestampFromTimestampDto = (timestampDto: Probably<TimestampDto>): Optional<Timestamp> => {
	let timestamp = null;
	if (isTimestampDto(timestampDto)) {
		timestamp = {
			discriminator: 'Timestamp',
			value: timestampDto
		} as Timestamp;
	}
	return new Optional<Timestamp>(timestamp);
};

export const createTimestampDtoFromTimestamp = (timestamp: Probably<Timestamp>): Optional<TimestampDto> => {
	let timestampDto = null;
	if (isTimestamp(timestamp)) {
		timestampDto = timestamp.value;
	}
	return new Optional<TimestampDto>(timestampDto);
};

export const createTimestampDtoFromDate = (date: Date): TimestampDto => {
	return date.toISOString();
};
