import { merge } from 'lodash-es';

import { Nullable } from '@abb-emobility/shared/util';

import { flattenLiteralStruct } from './Locale.util';

type LiteralPrimitives = string;
type LiteralStructValue =
	LiteralPrimitives
	| ReadonlyArray<LiteralPrimitives>
	| { [x: string]: LiteralStructValue }
	| ReadonlyArray<LiteralStructValue>;
export type LiteralStruct = Record<string, LiteralStructValue>;

export class Locale {

	public readonly identifier: string;
	private literals: Record<string, string>;

	constructor(identifier: string, literals: Array<LiteralStruct>) {
		this.identifier = identifier;
		const mergedLiterals = {};
		merge(mergedLiterals, ...literals);
		this.literals = flattenLiteralStruct(mergedLiterals);
	}

	public addLiterals(literals: LiteralStruct, prefix?: string) {
		const keyChain = prefix ? [prefix] : [];
		const flattenedLiterals = flattenLiteralStruct(literals, keyChain);
		this.literals = { ...this.literals, ...flattenedLiterals };
	}

	public translate(
		literal: string,
		replacements?: Map<string, string> | Record<string, string>,
		defaultValue: Nullable<string> = null
	): string | null {
		let result: Nullable<string>;
		if (literal in this.literals) {
			result = this.literals[literal];
		} else {
			result = defaultValue;
		}

		if (result === null) {
			return null;
		}

		if (replacements !== undefined) {
			const replacementMap = (replacements instanceof Map) ? replacements : new Map(Object.entries(replacements));
			replacementMap.forEach((value, key) => {
				result = (result as string).replace('{{' + key + '}}', value);
			});
		}
		return result;
	}

}
