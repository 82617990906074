import { useMemo } from 'react';

import {
	AnyErrorObject,
	AuthenticationFailedError,
	AuthenticationRequiredError,
	restoreError,
	SimpleError
} from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { FetchStatus } from '../../store/FetchStatus';

type DataProviderValue = {
	queryFetchError: () => Nullable<AnyErrorObject>,
	useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void) => void,
	queryFetchStatus: () => FetchStatus,
	resolveFetchStatus: () => void
};

type SearchDataProviderValue = {
	querySearchError: () => Nullable<AnyErrorObject>,
	useSearchStatusEffect: (status: Array<FetchStatus>, effect: () => void) => void,
	querySearchStatus: () => FetchStatus,
	resolveSearchStatus: () => void
};

export const useDataProviderFetchFailedHandler = (dataProviderValue: DataProviderValue, fetchFailedError: Error) => {
	// Resolve a fet status FAILED if remaining from the last render
	const fetchStatus = dataProviderValue.queryFetchStatus();
	useMemo(() => {
		if (fetchStatus === FetchStatus.FAILED) {
			dataProviderValue.resolveFetchStatus();
		}
	}, []);

	// Register callback to handle fetch error if coming up
	const errorObject = dataProviderValue.queryFetchError();
	dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
		if (errorObject === null) {
			return;
		}
		const error = restoreError(errorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		if (fetchFailedError instanceof SimpleError) {
			fetchFailedError.previousError = error;
		}
		throw fetchFailedError;
	});
};

export const useDataProviderSearchFailedHandler = (dataProviderValue: SearchDataProviderValue, fetchFailedError: Error) => {
	// Resolve a fet status FAILED if remaining from the last render
	const fetchStatus = dataProviderValue.querySearchStatus();
	useMemo(() => {
		if (fetchStatus === FetchStatus.FAILED) {
			dataProviderValue.resolveSearchStatus();
		}
	}, []);

	// Register callback to handle fetch error if coming up
	const errorObject = dataProviderValue.querySearchError();
	dataProviderValue.useSearchStatusEffect([FetchStatus.FAILED], () => {
		if (errorObject === null) {
			return;
		}
		const error = restoreError(errorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		if (fetchFailedError instanceof SimpleError) {
			fetchFailedError.previousError = error;
		}
		throw fetchFailedError;
	});
};
