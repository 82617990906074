import { CustomConversion, ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

export type AddressModel = {
	zip: string,
	city: string,
	street: string,
	countryCode: IsoCountryCode
};

export class AddressModelConverter extends ModelConverter<AddressModel> {

	override getCustomConversionFields(): Map<keyof AddressModel, CustomConversion> {
		return new Map([[
			'countryCode', {
				toDto: (modelValue) => {
					return modelValue;
				},
				toModel: (dtoValue) => {
					return dtoValue?.toUpperCase() as IsoCountryCode;
				}
			}
		]]);
	}

}
