import { CustomerOrderInformationAppointmentInformationModel } from '@abb-emobility/oms/domain-model';
import { DateRangeDto, Dto } from '@abb-emobility/shared/domain-model-foundation';

const dateRange: DateRangeDto = {
	start: '2022-01-01T12:30:12.345Z',
	end: '2022-01-01T16:30:12.345Z'
};

export const customerOrderInformationAppointmentInformationModelMock: Dto<CustomerOrderInformationAppointmentInformationModel> = {
	appointments: [
		{
			trade: 'Earthworks',
			periods: [
				dateRange,
				dateRange,
				dateRange
			]
		},
		{
			trade: 'Wall breakthroughs',
			periods: [
				dateRange
			]
		},
		{
			trade: 'Wallbox installation',
			periods: [
				dateRange,
				dateRange,
				dateRange
			]
		}
	]
};
