import { isString, Nullable } from '@abb-emobility/shared/util';

import { AnyQuestionnaireModel } from '../types/QuestionnaireModel';
import {
	Answer,
	Answers,
	PersistedAnswer,
	PersistedAnswers
} from '../types/answer/Answers';
import {
	QuestionnaireBusinessRule,
	QuestionnaireBusinessRuleComparator
} from '../types/business-rule/BusinessRule';
import { QuestionnaireEnvironmentModel } from '../types/environment/QuestionnaireEnvironmentModel';

export const performBusinessRule = (
	rule: QuestionnaireBusinessRule,
	answers: Answers | PersistedAnswers,
	environment?: QuestionnaireEnvironmentModel
): boolean => {
	const comparableValue = getComparableValue(rule, answers, environment);
	const expectedValue = rule.value;
	let result: boolean;
	switch (rule.comparator) {
		case QuestionnaireBusinessRuleComparator.EQUALS:
			if (isString(comparableValue) && isString(expectedValue)) {
				result =
					comparableValue.toLowerCase() ===
					expectedValue.toLowerCase();
			} else {
				result = comparableValue === expectedValue;
			}
			break;
		case QuestionnaireBusinessRuleComparator.ISSET:
			result =
				comparableValue !== null &&
				String(comparableValue).trim().length > 0;
			break;
		case QuestionnaireBusinessRuleComparator.GREATER_THAN: {
			const relatedNumericAnswerValue = isString(comparableValue)
				? parseFloat(comparableValue)
				: comparableValue;
			const ruleNumericValue = isString(expectedValue)
				? parseFloat(expectedValue)
				: expectedValue;
			result =
				relatedNumericAnswerValue !== null &&
				relatedNumericAnswerValue > ruleNumericValue;
			break;
		}
		case QuestionnaireBusinessRuleComparator.GREATER_THAN_EQUAL: {
			const relatedNumericAnswerValue = isString(comparableValue)
				? parseFloat(comparableValue)
				: comparableValue;
			const ruleNumericValue = isString(expectedValue)
				? parseFloat(expectedValue)
				: expectedValue;
			result =
				relatedNumericAnswerValue !== null &&
				relatedNumericAnswerValue >= ruleNumericValue;
			break;
		}
		case QuestionnaireBusinessRuleComparator.LESS_THAN: {
			const relatedNumericAnswerValue = isString(comparableValue)
				? parseFloat(comparableValue)
				: comparableValue;
			const ruleNumericValue = isString(expectedValue)
				? parseFloat(expectedValue)
				: expectedValue;
			result =
				relatedNumericAnswerValue !== null &&
				relatedNumericAnswerValue < ruleNumericValue;
			break;
		}
		case QuestionnaireBusinessRuleComparator.LESS_THAN_EQUAL: {
			const relatedNumericAnswerValue = isString(comparableValue)
				? parseFloat(comparableValue)
				: comparableValue;
			const ruleNumericValue = isString(expectedValue)
				? parseFloat(expectedValue)
				: expectedValue;
			result =
				relatedNumericAnswerValue !== null &&
				relatedNumericAnswerValue <= ruleNumericValue;
			break;
		}
	}
	if (rule.negation) {
		result = !result;
	}
	return result;
};

export const questionRelatesToBusinessRule = (
	questionnaire: AnyQuestionnaireModel,
	questionKey: string
): boolean => {
	for (const segment of questionnaire.segments) {
		const businessRules = segment?.businessRules?.rules ?? [];
		for (const businessRule of businessRules) {
			if (businessRule.key === questionKey) {
				return true;
			}
		}
	}
	return false;
};

const getComparableValue = (
	rule: QuestionnaireBusinessRule,
	answers: Answers | PersistedAnswers,
	environment?: QuestionnaireEnvironmentModel
): Nullable<Answer | Exclude<PersistedAnswer, undefined>> => {
	if (rule.discriminator === 'question') {
		return answers?.[rule.key] ?? null;
	}
	return (
		environment?.[rule.key as keyof QuestionnaireEnvironmentModel] ?? null
	);
};
