export const requestIdleCallbackPolyfill =
	window.requestIdleCallback ||
	function (cb: IdleRequestCallback) {
		const start = Date.now();
		return window.setTimeout(function () {
			cb({
				didTimeout: false,
				timeRemaining: function () {
					return Math.max(0, 50 - (Date.now() - start));
				}
			});
		}, 1);
	};

export const cancelIdleCallbackPolyfill =
	window.cancelIdleCallback ||
	function (id: number) {
		window.clearTimeout(id);
	};
