import { ReactNode, useEffect, useState } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { booleanFromString, Nullable, Optional, trimFromRight } from '@abb-emobility/shared/util';

import { themeContext } from './Theme.context';
import { VendorTheme, VendorThemeCollection } from './Theme.types';

export type ThemeProps = {
	children: ReactNode,
	themes: VendorThemeCollection
};

export function Theme(props: ThemeProps) {

	const { children, themes } = props;

	const l10n = useL10n();

	const [themeName, setThemeName] = useState<Nullable<string>>(null);
	const [theme, setTheme] = useState<Nullable<VendorTheme>>(null);

	useEffect(() => {
		// Take the first portion of the hostname
		const themeName = window.location.hostname.split('.').shift();

		if (themeName === undefined) {
			return;
		}

		// Get the theme from the themes.json
		const theme = themes?.[themeName] ?? undefined;
		if (theme === undefined) {
			return;
		}

		setThemeName(themeName);
		setTheme(theme);

		let cssFile = theme.cssFile;
		if (cssFile === undefined) {
			return;
		}

		const preferMinimizedCss = booleanFromString(
			new Optional(process.env['NX_PREFER_MINIMIZED_THEME_CSS']).getOrDefault('true')
		);
		if (preferMinimizedCss && !cssFile.endsWith('.min.css')) {
			cssFile = trimFromRight(cssFile, '.css') + '.min.css';
		}

		const head = document.head;
		const link = document.createElement('link');
		link.type = 'text/css';
		link.rel = 'stylesheet';
		link.href = '/themes/' + cssFile;
		head.appendChild(link);

		return () => {
			head.removeChild(link);
		};
	}, []);

	const providerValue = {
		name: (): string => {
			return themeName ?? 'theme-a';
		},
		pageTitle: (): string => {
			const defaultPageTitle = l10n.translate('omsCustomerApp.application.pageTitle');
			return theme?.pageTitle?.[l10n.language ?? 'en'] ?? defaultPageTitle;
		},
		caption: (): string => {
			const defaultCaption = l10n.translate('omsCustomerApp.application.caption');
			return theme?.caption?.[l10n.language ?? 'en'] ?? defaultCaption;
		}
	};

	return (
		<themeContext.Provider value={providerValue}>
			{children}
		</themeContext.Provider>
	);

}
