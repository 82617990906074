export enum PaginationResponseHeader {
	PAGE = 'x-pagination-page',
	MAX_PAGES = 'x-pagination-max-pages',
	TOTAL_ITEMS = 'x-pagination-total-items'
}

export type CollectionPaginationResponse = {
	currentPage: number,
	maxPages: number,
	totalItems?: number
};

export type PaginatedCollectionResponse<Model> = {
	items: Array<Model>
} & CollectionPaginationResponse;

export const extractPaginationResponse = (responseHeaders: Map<string, string>): CollectionPaginationResponse => {
	const currentPage = parseInt(responseHeaders.get(PaginationResponseHeader.PAGE) ?? '1');
	const maxPages = parseInt(responseHeaders.get(PaginationResponseHeader.MAX_PAGES) ?? '1');
	const totalItemsValue = responseHeaders.get(PaginationResponseHeader.TOTAL_ITEMS);
	const totalItems = totalItemsValue ? parseInt(totalItemsValue) : undefined;
	return { currentPage, maxPages, totalItems };
};
