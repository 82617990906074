import {
	AnyCustomerOrderInformationApiClientFactory,
	AnyCustomerOrderInformationApiClientInterface
} from '@abb-emobility/oms/api-integration';
import { AnyCustomerOrderInformationModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerOrderInformationEntityStoreName = 'customer-order-information-entity-crud';
export type CustomerOrderInformationEntityStore = { [customerOrderInformationEntityStoreName]: CrudEntityStore<AnyCustomerOrderInformationModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyCustomerOrderInformationApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<AnyCustomerOrderInformationModel, CustomerOrderInformationEntityStore, AnyCustomerOrderInformationApiClientInterface>(customerOrderInformationEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyCustomerOrderInformationModel, AnyCustomerOrderInformationApiClientInterface>(customerOrderInformationEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyCustomerOrderInformationModel, AnyCustomerOrderInformationApiClientInterface>(customerOrderInformationEntityStoreName, createApiClient);

// Slice definition
export const customerOrderInformationEntitySlice = createCrudEntitySlice<AnyCustomerOrderInformationModel, ThunkApiConfig>(customerOrderInformationEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerOrderInformationEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<AnyCustomerOrderInformationModel, CustomerOrderInformationEntityStore>(customerOrderInformationEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<AnyCustomerOrderInformationModel, CustomerOrderInformationEntityStore>(customerOrderInformationEntityStoreName);
const storeSize = createCrudEntityStoreSize<AnyCustomerOrderInformationModel, CustomerOrderInformationEntityStore>(customerOrderInformationEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<AnyCustomerOrderInformationModel, CustomerOrderInformationEntityStore>(customerOrderInformationEntityStoreName);

// Export the store accessors to be used by the data provider value
export const customerOrderInformationEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<AnyCustomerOrderInformationModel, CustomerOrderInformationEntityStore>;
