import { ReactNode } from 'react';

import { DownloadCard } from '@abb-emobility/oms/customer-ui-domain';
import {
	DownloadLayout
} from '@abb-emobility/oms/customer-ui-primitive';
import { useCustomerOrderDocumentCollectionData } from '@abb-emobility/oms/data-provider';

export function OrderDownloadDocumentList() {

	const orderDocumentCollectionData = useCustomerOrderDocumentCollectionData();
	const orderDocumentCollection = orderDocumentCollectionData.query();

	const renderDownloads = (): ReactNode => {
		return orderDocumentCollection.map((orderDocument): ReactNode => {
			return (
				<DownloadCard
					title={orderDocument.document.meta.title ?? orderDocument.document.meta.fileName}
					fileSize={orderDocument.document.meta.size}
					fileType={orderDocument.document.meta.mimeType}
					fileDate={orderDocument.document.meta.lastModifiedDate}
					url={orderDocument.document.url}
					key={orderDocument.id}
				/>
			);
		});
	};

	return (
		<DownloadLayout>
			{renderDownloads()}
		</DownloadLayout>
	);
}
