import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { TaskPayloadFoundationModel } from '../foundation/TaskPayloadFoundationModel';

export type InstallationApprovalPayloadModel = {
	installationApproved?: boolean
} & TaskPayloadFoundationModel;

export class InstallationApprovalPayloadModelConverter extends ModelConverter<InstallationApprovalPayloadModel> {
}
