import { ChangeEvent, InputHTMLAttributes } from 'react';

import './InputRadio.scss';

export type InputRadioProps = {
	label?: string,
	heading?: string,
	onChange?: (value: boolean) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export function InputRadio(props: InputRadioProps) {

	const { label, heading, onChange, ...rest } = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (onChange !== undefined) {
			onChange(event.target.checked);
		}
	};

	const renderHeading = () => {
		if (heading === undefined) {
			return;
		}
		return (
			<span className="input-radio__content__heading">{heading}</span>
		);
	};

	const renderLabel = () => {
		if (label === undefined) {
			return;
		}
		return (
			<span className="input-radio__content__label">{label}</span>
		);
	};

	return (
		<label className="input-radio">
			<span className="input-radio__input">
				<input
					type="radio"
					onChange={handleChange}
					{...rest}
				/>
			</span>
			<span className="input-radio__content">
				{renderHeading()}
				{renderLabel()}
			</span>
		</label>
	);
}
