import { CustomerOrderDocumentApiClientFactory, CustomerOrderDocumentApiClientInterface } from '@abb-emobility/oms/api-integration';
import { CustomerOrderDocumentModel } from '@abb-emobility/oms/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerOrderDocumentCollectionStoreName = 'customer-order-document-collection-crud';
export type CustomerOrderDocumentCollectionStore = { [customerOrderDocumentCollectionStoreName]: CrudCollectionStore<CustomerOrderDocumentModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerOrderDocumentApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerOrderDocumentModel, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<CustomerOrderDocumentModel, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerOrderDocumentModel, CustomerOrderDocumentApiClientInterface>(customerOrderDocumentCollectionStoreName, createApiClient);

// Slice definition
export const customerOrderDocumentCollectionSlice = createCrudCollectionSlice<CustomerOrderDocumentModel, ThunkApiConfig>(
	customerOrderDocumentCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerOrderDocumentCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>(customerOrderDocumentCollectionStoreName);
const countCollection = createCrudCountCollection<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>(customerOrderDocumentCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>(customerOrderDocumentCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>(customerOrderDocumentCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>(customerOrderDocumentCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>(customerOrderDocumentCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerOrderDocumentCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<CustomerOrderDocumentModel, CustomerOrderDocumentCollectionStore>;
