import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Bitte wählen',
			pictureSelectPlaceholder: '',
			pictureSelectTrigger: 'Bild auswählen',
			pictureSelectHint: {
				default: 'Bitte vergewissern Sie sich, dass sich keine Personen auf dem Bild befinden.',
				examplePicture: 'Bitte orientieren Sie sich an dem Beispielbild und vergewissern Sie sich, dass sich keine Personen auf dem Bild befinden.'
			},
			documentSelectPlaceholder: '',
			documentSelectTrigger: 'Dokument auswählen',
			selectedFileTooLarge: 'Die gewählte Datei ist zu groß, um übertragen zu werden. Bitte wählen Sie eine Datei mit weniger als {{maxFileSize}}.',
			answerTypeNotFound: 'Der Antworttyp {{type}} is unbekannt.',
			previousSegmentButtonLabel: 'Zurück',
			nextSegmentButtonLabel: 'Nächster Schritt',
			submitButtonLabel: 'Absenden',
			validationErrors: {
				heading: 'Ein Problem ist aufgetreten',
				message: 'Bitte prüfen Sie Ihre Antworten um den Fragebogen abzuschließen'
			}
		}
	}
};

export default literals;
