import { ReactNode } from 'react';

import { CustomerOrderProgressModel, CustomerOrderProgressStepModel } from '@abb-emobility/oms/domain-model';
import { ProgressState } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { OrderProgressIndicator } from './order-progress-indicator/OrderProgressIndicator';

import './OrderProgress.scss';

export type OrderProgressProps = {
	progress?: CustomerOrderProgressModel
};

// TODO: Maybe some refactoring and documentation could be helpful
export function OrderProgress(props: OrderProgressProps) {
	const { progress = null } = props;

	const l10n = useL10n();

	// Render skeleton while lazy loading
	if (progress === null) {
		return (
			<div className="order-progress">
				<OrderProgressIndicator />
			</div>
		);
	}

	if (progress.steps.length === 0) {
		return null;
	}

	const currentTaskPredicate = (task: CustomerOrderProgressStepModel): boolean => {
		return task.current;
	};
	const currentTask = progress.steps.find(currentTaskPredicate);
	let currentStepIndex = progress.steps.findIndex(currentTaskPredicate);

	let allDone = true;
	for (const step of progress.steps) {
		if (step.status !== ProgressState.DONE) {
			allDone = false;
			break;
		}
	}
	if (allDone) {
		currentStepIndex = progress.steps.length - 1;
	}

	let upcomingTask: Nullable<CustomerOrderProgressStepModel> = null;
	if (currentStepIndex >= 0) {
		upcomingTask = progress.steps?.[currentStepIndex + 1] ?? null;
	}

	const currentStep: number | undefined = currentStepIndex === -1 ? undefined : currentStepIndex + 1;
	const maxSteps = progress.steps.length;

	const renderLabel = (): ReactNode => {
		if (allDone) {
			return (
				<div className="order-progress__label__current">
					{l10n.translate('omsCustomerApp.orderProgress.allDoneStepLabel')}
				</div>
			);
		}

		if (currentTask) {
			return (
				<div className="order-progress__label__current">
					{l10n.translate(l10nLiteralFromEnumValue(currentTask.taskType, 'omsCustomerApp.task.shortName'))}
				</div>
			);
		}

		return (
			<div className="order-progress__label__current">
				{l10n.translate('omsCustomerApp.orderProgress.undefinedCurrentStepLabel')}
			</div>
		);
	};

	const renderNextLabel = (): ReactNode => {
		if (upcomingTask === null) {
			return;
		}
		return (
			<div className="order-progress__label__upcoming">
				{
					l10n.translate('omsCustomerApp.orderProgress.upcomingLabelPrefix')
					+ l10n.translate(l10nLiteralFromEnumValue(upcomingTask.taskType, 'omsCustomerApp.task.shortName'))
				}
			</div>
		);
	};

	return (
		<div className="order-progress">
			<OrderProgressIndicator currentStep={currentStep} maxSteps={maxSteps} />
			<div className="order-progress__label">
				{renderLabel()}
				{renderNextLabel()}
			</div>
		</div>
	);
}
