import { ReactNode } from 'react';

import { useExpandable } from '@abb-emobility/shared/interaction';

export type SectionItemsProps = {
	children?: ReactNode
};

export function SectionItems(props: SectionItemsProps) {
	const { children } = props;

	const expandable = useExpandable();
	const expanded = expandable.isExpanded();

	const renderChildren = (): ReactNode => {
		if (!expanded) {
			return null;
		}
		return children;
	};

	return (
		<main className="section-items">
			{renderChildren()}
		</main>
	);
}
