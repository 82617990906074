import { CustomError } from 'ts-custom-error';

export class SimpleError extends CustomError {
	constructor(name: string, message?: string, public status?: number, public timestamp?: string, public previousError?: Error) {
		super(message);
		Object.defineProperty(this, 'name', { value: name });
	}
}

export type ErrorDetail = {
	message: string,
	reference?: string
};

export class DetailedError extends SimpleError {
	constructor(
		name: string,
		message?: string,
		public override status?: number,
		public override timestamp?: string,
		public details: Array<ErrorDetail> = [],
		public override previousError?: Error
	) {
		super(name, message, status, timestamp, previousError);
	}
}

export class AppError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('AppError', message, status, timestamp, previousError);
	}
}

export class StoreError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('StoreError', message, status, timestamp, previousError);
	}
}

export class NotFoundError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('NotFoundError', message, status, timestamp, previousError);
	}
}

export class TooEarlyError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('TooEarlyError', message, status, timestamp, previousError);
	}
}

export class NetworkError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('NetworkError', message, status, timestamp, previousError);
	}
}

export class TimeoutError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('TimeoutError', message, status, timestamp, previousError);
	}
}

export class ApiError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('ApiError', message, status, timestamp, previousError);
	}
}

export class ValidationError extends DetailedError {
	constructor(message?: string, status?: number, timestamp?: string, details: Array<ErrorDetail> = [], previousError?: Error) {
		super('ValidationError', message, status, timestamp, details, previousError);
	}
}

export class AuthenticationRequiredError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('AuthenticationRequiredError', message, status, timestamp, previousError);
	}
}

export class AuthenticationFailedError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('AuthenticationFailedError', message, status, timestamp, previousError);
	}
}

export class AccessDeniedError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('AccessDeniedError', message, status, timestamp, previousError);
	}
}

export class WebSocketError extends SimpleError {
	constructor(message?: string, status?: number, timestamp?: string, previousError?: Error) {
		super('WebSocketError', message, status, timestamp, previousError);
	}
}
