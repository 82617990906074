import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import {
	PersistedAnswersDto,
	QuestionnaireEnvironmentModel,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';

export type InstallationPartnerOrderInformationBasicAnswersInformationModel = {
	readonly questionnaire: AnyQuestionnaireModel,
	readonly questionnaireEnvironment: QuestionnaireEnvironmentModel,
	readonly answers: PersistedAnswersDto
};

export class InstallationPartnerOrderInformationBasicAnswersInformationModelConverter extends ModelConverter<InstallationPartnerOrderInformationBasicAnswersInformationModel> {}
