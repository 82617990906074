import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	AccessibleUrl,
	createUrlFromUrlDto,
	isAbsoluteUrl,
	isAccessableUrl,
	isAccessableUrlDto,
	ProtectedUrl,
	Url
} from '@abb-emobility/shared/domain-model-foundation';
import { ApiError } from '@abb-emobility/shared/error';
import { combineUrl, Nullable, Optional } from '@abb-emobility/shared/util';

import { JsonRestRequestFactory } from '../network/JsonRestRequestFactory';
import { JsonRestRequestInterface } from '../network/JsonRestRequestInterface';

export class UrlResolver {

	private jsonRestRequest: Nullable<JsonRestRequestInterface> = null;

	public async resolve(
		documentStoreApiBaseUrl: string,
		documentStoreMediaBaseUrl: string,
		url: Url,
		jsonWebToken: Nullable<JsonWebToken>
	): Promise<string> {
		if (isAccessableUrl(url)) {
			if (isAbsoluteUrl(url)) {
				return url.value;
			}
			return combineUrl(documentStoreMediaBaseUrl, url.value);
		}
		const accessibleUrl = (await this.resolveProtectedUrl(documentStoreApiBaseUrl, url, jsonWebToken))
			.getOrThrow(new ApiError('Resolving the protected URL failed'));
		return combineUrl(documentStoreMediaBaseUrl, accessibleUrl.value);
	}

	public async resolveProtectedUrl(
		documentStoreApiBaseUrl: string,
		protectedUrl: ProtectedUrl,
		jsonWebToken: Nullable<JsonWebToken>
	): Promise<Optional<AccessibleUrl>> {
		let exchangeApiUrl: string;
		if (isAbsoluteUrl(protectedUrl)) {
			exchangeApiUrl = protectedUrl.exchangeUrl;
		} else {
			exchangeApiUrl = combineUrl(documentStoreApiBaseUrl, protectedUrl.exchangeUrl);
		}
		this.jsonRestRequest = JsonRestRequestFactory.create(jsonWebToken);
		const resolveResponse = await this.jsonRestRequest.put(exchangeApiUrl, {});
		const accessableUrlDto = resolveResponse.body;
		if (!isAccessableUrlDto(accessableUrlDto)) {
			return new Optional<AccessibleUrl>(null);
		}
		const url = createUrlFromUrlDto(accessableUrlDto).get();
		this.jsonRestRequest = null;
		return new Optional(url as AccessibleUrl);
	}

	public abort(): void {
		if (this.jsonRestRequest !== null) {
			this.jsonRestRequest.abort();
		}
	}

}
