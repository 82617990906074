import { useNavigate } from 'react-router-dom';

import { ButtonPrimary } from '@abb-emobility/oms/customer-ui-primitive';
import { useCustomerOrderEntityData } from '@abb-emobility/oms/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import { createRouteUrl, RoutePath } from '../../router/Routes';

import './CurrentTaskButton.scss';

export function CurrentTaskButton() {

	const l10n = useL10n();
	const navigate = useNavigate();
	const orderEntityCrudData = useCustomerOrderEntityData();
	const orderModel = orderEntityCrudData.query().getOrUndefined();

	const currentTask = orderModel?.progress.steps.find((step) => {
		return step.current === true;
	}) ?? null;

	const currentTaskId = currentTask?.taskId ?? null;

	const handleClick = (): void => {
		if (currentTask !== null && currentTaskId !== null) {
			navigate(createRouteUrl(RoutePath.TASK, ['orderId', orderModel?.id ?? ''], ['taskId', currentTaskId]));
		}
	};

	if (currentTask === null || currentTaskId === null) {
		return null;
	}

	return (
		<div className="current-task-button">
			<ButtonPrimary label={l10n.translate('omsCustomerApp.order.openTask.buttonLabel')} onClick={handleClick} />
		</div>
	);
}
