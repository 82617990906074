import { addressModelMock, customerModelMock, installationPartnerModelMock } from '@abb-emobility/shared/api-integration';
import { CandidateGroup } from '@abb-emobility/shared/candidate-groups-provider';
import { Dto, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';
import { Answers, basicQuestionsQuestionnaireGermanyAnswersMock } from '@abb-emobility/shared/questionnaire';
import { Nullable } from '@abb-emobility/shared/util';
import {
	BasicAnswersTaskModel,
	BasicQuestionsTaskModel,
	InstallationApprovalTaskModel,
	PreCheckInstallationUnfeasibleTaskModel,
	PreCheckRemoteTaskModel,
	PreCheckRegistrationGridOperatorTaskModel,
	QuotationAcceptanceTaskModel,
	PreCheckRejectedByGridOperatorTaskModel,
	QuotationTaskModel,
	ArrangeAppointmentTaskModel,
	PreCheckDecisionGridOperatorTaskModel,
	PreCheckPreferencesTaskModel,
	InstallationCompletionTaskModel,
	BasicAnswersEscalationTaskModel,
	BasicQuestionsEscalationTaskModel,
	PreCheckInstallationUnfeasibleEscalationTaskModel,
	PreCheckRemoteEscalationTaskModel,
	PreCheckRegistrationGridOperatorEscalationTaskModel,
	QuotationAcceptanceEscalationTaskModel,
	PreCheckRejectedByGridOperatorEscalationTaskModel,
	QuotationEscalationTaskModel,
	ArrangeAppointmentEscalationTaskModel,
	PreCheckDecisionGridOperatorEscalationTaskModel,
	PreCheckPreferencesEscalationTaskModel,
	InstallationCompletionEscalationTaskModel,
	PreCheckRevokeGridOperatorRegistrationTaskModel,
	AnyTaskModel,
	PaymentTaskModel,
	PaymentEscalationTaskModel,
	AddressValidationTaskModel,
	UncoveredInstallationAddressTaskModel,
	TaskStatus,
	TaskType,
	TaskPriority
} from '@abb-emobility/usertask/domain-model';

import { addressValidationPayloadModelMock } from './payload/AddressValidationPayloadModel.mock';
import { arrangeAppointmentPayloadModelMock } from './payload/ArrangeAppointmentPayloadModel.mock';
import { anyBasicAnswersPayloadModelMock } from './payload/BasicAnswersPayloadModel.mock';
import { basicQuestionsPayloadModelMock } from './payload/BasicQuestionsPayloadModel.mock';
import { installationApprovalPayloadModelMock } from './payload/InstallationApprovalPayloadModel.mock';
import { installationCompletionPayloadModelMock } from './payload/InstallationCompletionPayloadModel.mock';
import { paymentPayloadModelMock } from './payload/PaymentPayloadModel.mock';
import { preCheckDecisionGridOperatorPayloadModelMock } from './payload/PreCheckDecisionGridOperatorPayloadModel.mock';
import { preCheckInstallationUnfeasiblePayloadModelMock } from './payload/PreCheckInstallationUnfeasiblePayloadModel.mock';
import { preCheckPreferencesPayloadModelMock } from './payload/PreCheckPreferencesPayloadModel.mock';
import { preCheckRegistrationGridOperatorPayloadModelMock } from './payload/PreCheckRegistrationGridOperatorPayloadModel.mock';
import { preCheckRejectedByGridOperatorPayloadModelMock } from './payload/PreCheckRejectedByGridOperatorPayloadModel.mock';
import { preCheckRemotePayloadModelMock } from './payload/PreCheckRemotePayloadModel.mock';
import { preCheckRevokeGridOperatorRegistrationPayloadModelMock } from './payload/PreCheckRevokeGridOperatorRegistrationPayloadModel.mock';
import { quotationAcceptancePayloadModelMock } from './payload/QuotationAcceptancePayloadModel.mock';
import { quotationPayloadModelMock } from './payload/QuotationPayloadModel.mock';
import { uncoveredInstallationAddressPayloadModelMock } from './payload/UncoveredInstallationAddressPayloadModel.mock';

// Customer task mock data
export const customerBasicQuestionsTaskModelMock = (): Dto<BasicQuestionsTaskModel> => {
	return {
		id: 'c1',
		orderId: '1',
		marketplaceOrderId: '1',
		marketplace: 'smart Germany',
		status: TaskStatus.STARTED,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.LOW,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		payload: basicQuestionsPayloadModelMock()
	};
};

export const customerPreCheckPreferencesTaskModelMock: Dto<PreCheckPreferencesTaskModel> = {
	id: 'c2',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.MEDIUM,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: preCheckPreferencesPayloadModelMock
};

export const customerPreCheckInstallationUnfeasibleTaskModelMock: Dto<PreCheckInstallationUnfeasibleTaskModel> = {
	id: 'c3',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: preCheckInstallationUnfeasiblePayloadModelMock
};

export const customerPreCheckRejectedByGridOperatorTaskModelMock: Dto<PreCheckRejectedByGridOperatorTaskModel> = {
	id: 'c4',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: preCheckRejectedByGridOperatorPayloadModelMock
};

export const customerQuotationAcceptanceTaskModelMock: Dto<QuotationAcceptanceTaskModel> = {
	id: 'c5',
	orderId: '1',
	marketplaceOrderId: '3',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_ACCEPT_QUOTATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: quotationAcceptancePayloadModelMock
};

export const customerPaymentTaskModelMock: Dto<PaymentTaskModel> = {
	id: 'c6',
	orderId: '1',
	marketplaceOrderId: '3',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_PERFORM_PAYMENT,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: paymentPayloadModelMock
};

export const customerInstallationApprovalTaskModelMock: Dto<InstallationApprovalTaskModel> = {
	id: 'c7',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_APPROVE_INSTALLATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: installationApprovalPayloadModelMock
};

export const customerUncompletableTaskModelMock: Dto<InstallationApprovalTaskModel> = {
	id: 'c5000',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_APPROVE_INSTALLATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: installationApprovalPayloadModelMock
};

export const customerInvalidTaskModelMock: Dto<InstallationApprovalTaskModel> = {
	id: 'c4000',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_APPROVE_INSTALLATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: installationApprovalPayloadModelMock
};

// Installation partner task mock data
export const installationPartnerBasicAnswersTaskModelMock = (): Dto<BasicAnswersTaskModel> => {
	return {
		id: 'ip1',
		orderId: '1',
		marketplaceOrderId: '1',
		marketplace: 'smart Germany',
		status: TaskStatus.STARTED,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.LOW,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		payload: anyBasicAnswersPayloadModelMock()
	};
};

export const installationPartnerPreCheckRemoteTaskModelMock = (): Dto<PreCheckRemoteTaskModel> => {
	return {
		id: 'ip2',
		orderId: '1',
		marketplaceOrderId: '2',
		marketplace: 'smart Germany',
		status: TaskStatus.OVERDUE,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.HIGH,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		payload: preCheckRemotePayloadModelMock()
	};
};

export const installationPartnerPreCheckRegistrationGridOperatorTaskModelMock: Dto<PreCheckRegistrationGridOperatorTaskModel> = {
	id: 'ip3',
	orderId: '1',
	marketplaceOrderId: '3',
	marketplace: 'smart Germany',
	status: TaskStatus.OVERDUE,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: preCheckRegistrationGridOperatorPayloadModelMock
};

export const installationPartnerPreCheckGridOperatorDecisionTaskModelMock: Dto<PreCheckDecisionGridOperatorTaskModel> = {
	id: 'ip4',
	orderId: '1',
	marketplaceOrderId: '6',
	marketplace: 'smart Germany',
	status: TaskStatus.OVERDUE,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: preCheckDecisionGridOperatorPayloadModelMock
};

export const installationPartnerQuotationTaskModelMock = (): Dto<QuotationTaskModel> => {
	return {
		id: 'ip5',
		orderId: '1',
		marketplaceOrderId: '4',
		marketplace: 'smart Germany',
		status: TaskStatus.STARTED,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.LOW,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		payload: quotationPayloadModelMock()
	};
};

export const installationPartnerArrangeAppointmentTaskModelMock: Dto<ArrangeAppointmentTaskModel> = {
	id: 'ip6',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: arrangeAppointmentPayloadModelMock
};

export const installationPartnerCompleteInstallationTaskModelMock: Dto<InstallationCompletionTaskModel> = {
	id: 'ip7',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: installationCompletionPayloadModelMock
};

export const installationPartnerPreCheckRevokeGridOperatorRegistrationTaskModelMock: Dto<PreCheckRevokeGridOperatorRegistrationTaskModel> = {
	id: 'ip8',
	orderId: '1',
	marketplaceOrderId: '6',
	marketplace: 'smart Germany',
	status: TaskStatus.OVERDUE,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_REVOKE_GRID_OPERATOR_REGISTRATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	payload: preCheckRevokeGridOperatorRegistrationPayloadModelMock
};

// Customer service task mock data
export const customerServiceBasicQuestionsEscalationTaskModelMock = (): Dto<BasicQuestionsEscalationTaskModel> => {
	return {
		id: 'cs1',
		orderId: '1',
		marketplaceOrderId: '1',
		marketplace: 'smart Germany',
		status: TaskStatus.STARTED,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.LOW,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		languageCode: IsoLanguageCode.DE,
		payload: {
			...basicQuestionsPayloadModelMock(),
			// TODO fix typing to be compatible to Dto<Answers>
			answers: basicQuestionsQuestionnaireGermanyAnswersMock as Dto<Answers>,
			customer: customerModelMock,
			installationPartner: installationPartnerModelMock
		}
	};
};

export const customerServicePreCheckPreferencesEscalationTaskModelMock: Dto<PreCheckPreferencesEscalationTaskModel> = {
	id: 'cs2',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.MEDIUM,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...preCheckPreferencesPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServicePreCheckInstallationUnfeasibleEscalationTaskModelMock: Dto<PreCheckInstallationUnfeasibleEscalationTaskModel> = {
	id: 'cs3',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...preCheckInstallationUnfeasiblePayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServicePreCheckRejectedByGridOperatorEscalationTaskModelMock: Dto<PreCheckRejectedByGridOperatorEscalationTaskModel> = {
	id: 'cs4',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...preCheckRejectedByGridOperatorPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceAddressValidationEscalationTaskModelMock: Dto<AddressValidationTaskModel> = {
	id: 'cs43',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_ADDRESS_VALIDATION_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: addressValidationPayloadModelMock
};
export const customerServiceQuotationAcceptanceEscalationTaskModelMock: Dto<QuotationAcceptanceEscalationTaskModel> = {
	id: 'cs5',
	orderId: '1',
	marketplaceOrderId: '3',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_ACCEPT_QUOTATION_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...quotationAcceptancePayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServicePaymentEscalationTaskModelMock: Dto<PaymentEscalationTaskModel> = {
	id: 'cs6',
	orderId: '1',
	marketplaceOrderId: '3',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...paymentPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceUncompletableEscalationTaskModelMock: Dto<PaymentEscalationTaskModel> = {
	id: 'cs5000',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...installationApprovalPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceInvalidEscalationTaskModelMock: Dto<PaymentEscalationTaskModel> = {
	id: 'cs4000',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...installationApprovalPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceBasicAnswersEscalationTaskModelMock = (): Dto<BasicAnswersEscalationTaskModel> => {
	return {
		id: 'cs10',
		orderId: '1',
		marketplaceOrderId: '1',
		marketplace: 'smart Germany',
		status: TaskStatus.STARTED,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.LOW,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		languageCode: IsoLanguageCode.DE,
		payload: {
			...anyBasicAnswersPayloadModelMock(),
			customer: customerModelMock,
			installationPartner: installationPartnerModelMock
		}
	};
};

export const customerServicePreCheckRemoteEscalationTaskModelMock = (): Dto<PreCheckRemoteEscalationTaskModel> => {
	return {
		id: 'cs11',
		orderId: '1',
		marketplaceOrderId: '2',
		marketplace: 'smart Germany',
		status: TaskStatus.OVERDUE,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.HIGH,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		languageCode: IsoLanguageCode.DE,
		payload: {
			...preCheckRemotePayloadModelMock(),
			customer: customerModelMock,
			installationPartner: installationPartnerModelMock
		}
	};
};

export const customerServicePreCheckRegistrationGridOperatorEscalationTaskModelMock: Dto<PreCheckRegistrationGridOperatorEscalationTaskModel> = {
	id: 'cs12',
	orderId: '1',
	marketplaceOrderId: '3',
	marketplace: 'smart Germany',
	status: TaskStatus.OVERDUE,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...preCheckRegistrationGridOperatorPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServicePreCheckGridOperatorDecisionEscalationTaskModelMock: Dto<PreCheckDecisionGridOperatorEscalationTaskModel> = {
	id: 'cs13',
	orderId: '1',
	marketplaceOrderId: '6',
	marketplace: 'smart Germany',
	status: TaskStatus.OVERDUE,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.HIGH,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...preCheckDecisionGridOperatorPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceQuotationEscalationTaskModelMock = (): Dto<QuotationEscalationTaskModel> => {
	return {
		id: 'cs14',
		orderId: '1',
		marketplaceOrderId: '4',
		marketplace: 'smart Germany',
		status: TaskStatus.STARTED,
		updatedOn: '2022-01-01T16:30:12.345Z',
		type: TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION,
		dueDate: '2022-01-01T16:30:12.345Z',
		priority: TaskPriority.LOW,
		candidateGroupIds: ['1'],
		installationAddress: addressModelMock,
		languageCode: IsoLanguageCode.DE,
		payload: {
			...quotationPayloadModelMock(),
			customer: customerModelMock,
			installationPartner: installationPartnerModelMock
		}
	};
};

export const customerServiceArrangeAppointmentEscalationTaskModelMock: Dto<ArrangeAppointmentEscalationTaskModel> = {
	id: 'cs15',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...arrangeAppointmentPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceCompleteInstallationEscalationTaskModelMock: Dto<InstallationCompletionEscalationTaskModel> = {
	id: 'cs16',
	orderId: '1',
	marketplaceOrderId: '5',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: {
		...installationCompletionPayloadModelMock,
		customer: customerModelMock,
		installationPartner: installationPartnerModelMock
	}
};

export const customerServiceUncoveredInstallationAddressTaskModelMock: Dto<UncoveredInstallationAddressTaskModel> = {
	id: 'cs6000',
	orderId: '1',
	marketplaceOrderId: '2',
	marketplace: 'smart Germany',
	status: TaskStatus.STARTED,
	updatedOn: '2022-01-01T16:30:12.345Z',
	type: TaskType.CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS,
	dueDate: '2022-01-01T16:30:12.345Z',
	priority: TaskPriority.LOW,
	candidateGroupIds: ['1'],
	installationAddress: addressModelMock,
	languageCode: IsoLanguageCode.DE,
	payload: uncoveredInstallationAddressPayloadModelMock
};

const anyTaskModelTemplates = () => {
	return [
		customerBasicQuestionsTaskModelMock(),
		customerPreCheckPreferencesTaskModelMock,
		customerPreCheckInstallationUnfeasibleTaskModelMock,
		customerPreCheckRejectedByGridOperatorTaskModelMock,
		customerQuotationAcceptanceTaskModelMock,
		customerPaymentTaskModelMock,
		customerInstallationApprovalTaskModelMock,
		customerUncompletableTaskModelMock,
		customerInvalidTaskModelMock,
		installationPartnerBasicAnswersTaskModelMock(),
		installationPartnerPreCheckRemoteTaskModelMock(),
		installationPartnerPreCheckRegistrationGridOperatorTaskModelMock,
		installationPartnerPreCheckGridOperatorDecisionTaskModelMock,
		installationPartnerQuotationTaskModelMock(),
		installationPartnerArrangeAppointmentTaskModelMock,
		installationPartnerCompleteInstallationTaskModelMock,
		installationPartnerPreCheckRevokeGridOperatorRegistrationTaskModelMock,
		customerServiceBasicQuestionsEscalationTaskModelMock(),
		customerServicePreCheckPreferencesEscalationTaskModelMock,
		customerServicePreCheckInstallationUnfeasibleEscalationTaskModelMock,
		customerServicePreCheckRejectedByGridOperatorEscalationTaskModelMock,
		customerServiceAddressValidationEscalationTaskModelMock,
		customerServiceQuotationAcceptanceEscalationTaskModelMock,
		customerServicePaymentEscalationTaskModelMock,
		customerServiceUncompletableEscalationTaskModelMock,
		customerServiceInvalidEscalationTaskModelMock,
		customerServiceBasicAnswersEscalationTaskModelMock(),
		customerServicePreCheckRemoteEscalationTaskModelMock(),
		customerServicePreCheckRegistrationGridOperatorEscalationTaskModelMock,
		customerServicePreCheckGridOperatorDecisionEscalationTaskModelMock,
		customerServiceQuotationEscalationTaskModelMock(),
		customerServiceArrangeAppointmentEscalationTaskModelMock,
		customerServiceCompleteInstallationEscalationTaskModelMock,
		customerServiceUncoveredInstallationAddressTaskModelMock
	];
};

export const anyTaskModelMockById = (taskId: ModelPrimaryKey, candidateGroups: Array<CandidateGroup>): Nullable<Dto<AnyTaskModel>> => {
	const taskModel = anyTaskModelTemplates().find((task) => {
		return task.id === taskId;
	});
	if (taskModel === undefined) {
		return null;
	}
	return enrichTaskModel(taskModel, candidateGroups);
};

export const anyTaskModelMockByType = (taskType: TaskType, candidateGroups: Array<CandidateGroup>): Nullable<Dto<AnyTaskModel>> => {
	const taskModel = anyTaskModelTemplates().find((task) => {
		return task.type === taskType;
	});
	if (taskModel === undefined) {
		return null;
	}
	return enrichTaskModel(taskModel, candidateGroups);
};

export const anyTaskModelCollectionMock = (candidateGroups: Array<CandidateGroup>): Array<Dto<AnyTaskModel>> => {
	return anyTaskModelTemplates().map((task) => {
		return enrichTaskModel(task, candidateGroups);
	});
};

/* anyTaskModelMock contains all models for unit testing */
export const anyTaskModelMock = (): Array<Dto<AnyTaskModel>> => {
	return anyTaskModelTemplates();
};

const enrichTaskModel = (task: Dto<AnyTaskModel>, candidateGroups: Array<CandidateGroup>): Dto<AnyTaskModel> => {
	return {
		...task,
		candidateGroupIds: candidateGroups.map(candidateGroup => candidateGroup.id),
		installationAddress: addressModelMock
	};
};
