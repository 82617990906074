import { QuotationModel, QuotationModelConverter } from '@abb-emobility/shared/domain-model';
import {
	ModelConverter,
	ModelConverterInterface
} from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceTaskPayloadFoundationModel,
	CustomerServiceTaskPayloadFoundationModelConverter,
	TaskPayloadFoundationModel
} from '../foundation/TaskPayloadFoundationModel';

export enum QuotationAcceptanceCancellation {
	CANCEL_INSTALLATION = 'CANCEL_INSTALLATION',
	CANCEL_ORDER = 'CANCEL_ORDER'
}

export type QuotationAcceptancePayloadModel = {
	readonly quotation: QuotationModel,
	// TODO: Define as mandatory after the backend has integrated the member; see https://jira.fiege.com/browse/ABB-801
	readonly installationCancelable?: boolean,
	accepted?: boolean,
	cancellation?: QuotationAcceptanceCancellation
} & TaskPayloadFoundationModel;

export class QuotationAcceptancePayloadModelConverter extends ModelConverter<QuotationAcceptancePayloadModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof QuotationAcceptancePayloadModel, ModelConverterInterface<any>> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return new Map([
			['quotation', new QuotationModelConverter()]
		]);
	}

}

export type QuotationAcceptanceEscalationPayloadModel =
	QuotationAcceptancePayloadModel
	& CustomerServiceTaskPayloadFoundationModel;

export class QuotationAcceptanceEscalationPayloadModelConverter extends CustomerServiceTaskPayloadFoundationModelConverter<QuotationAcceptanceEscalationPayloadModel> {
	private payloadModelConverter = new QuotationAcceptancePayloadModelConverter();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof QuotationAcceptanceEscalationPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			...Array.from(super.getFieldConverterMapByModel()),
			...Array.from(this.payloadModelConverter.getFieldConverterMapByModel())
		]);
	}
}
