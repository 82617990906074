import { isTimestampDto, isTimestamp } from './Timestamp.guards';
import { UploadFileDto, UploadFileMetaDataDto, UploadFile, UploadFileMetaData } from './UploadFile';

export const isUploadFile = (file?: unknown): file is UploadFile => {
	if ((file ?? null) === null) {
		return false;
	}
	return (file as UploadFile).discriminator === 'UploadFile'
		&& (file as UploadFile).dataUri !== undefined
		&& isUploadFileMetaData((file as UploadFile).meta);
};

export const isUploadFileDto = (fileDto?: unknown): fileDto is UploadFileDto => {
	if ((fileDto ?? null) === null) {
		return false;
	}
	return (fileDto as UploadFileDto).dataUri !== undefined
		&& isUploadFileMetaDataDto((fileDto as UploadFileDto).meta);
};

export const isUploadFileMetaData = (fileMetaData?: unknown): fileMetaData is UploadFileMetaData => {
	if ((fileMetaData ?? null) === null) {
		return false;
	}
	return (fileMetaData as UploadFileMetaData).fileName !== undefined
		&& (fileMetaData as UploadFileMetaData).size !== undefined
		&& isTimestamp((fileMetaData as UploadFileMetaData).lastModifiedDate);
};

export const isUploadFileMetaDataDto = (fileMetaDataDto?: unknown): fileMetaDataDto is UploadFileMetaDataDto => {
	if ((fileMetaDataDto ?? null) === null) {
		return false;
	}
	return (fileMetaDataDto as UploadFileMetaDataDto).fileName !== undefined
		&& (fileMetaDataDto as UploadFileMetaDataDto).size !== undefined
		&& isTimestampDto((fileMetaDataDto as UploadFileMetaDataDto).lastModifiedDate);
};
