import { TaskCompletionCommentModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, Dto, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { InstallationPartnerOrderInformationFoundationModel } from './foundation/InstallationPartnerOrderInformationFoundationModel';
import { InstallationPartnerOrderInformationType } from './foundation/InstallationPartnerOrderInformationType';
import {
	InstallationPartnerOrderInformationApprovalInformationModel,
	InstallationPartnerOrderInformationApprovalInformationModelConverter
} from './order-information/InstallationPartnerOrderInformationApprovalInformationModel';
import {
	InstallationPartnerOrderInformationBasicAnswersInformationModel,
	InstallationPartnerOrderInformationBasicAnswersInformationModelConverter
} from './order-information/InstallationPartnerOrderInformationBasicAnswersInformationModel';
import {
	InstallationPartnerOrderInformationGridOperatorDecisionInformationModel,
	InstallationPartnerOrderInformationGridOperatorDecisionInformationModelConverter
} from './order-information/InstallationPartnerOrderInformationGridOperatorDecisionInformationModel';
import {
	InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel,
	InstallationPartnerOrderInformationGridOperatorRegistrationInformationModelConverter
} from './order-information/InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel';
import {
	InstallationPartnerOrderInformationPrecheckInformationModel, InstallationPartnerOrderInformationPrecheckInformationModelConverter
} from './order-information/InstallationPartnerOrderInformationPrecheckInformationModel';
import {
	InstallationPartnerOrderInformationQuotationInformationModel, InstallationPartnerOrderInformationQuotationInformationModelConverter
} from './order-information/InstallationPartnerOrderInformationQuotationInformationModel';

export type AnyInstallationPartnerOrderInformationModel =
	InstallationPartnerOrderInformationBasicAnswersModel |
	InstallationPartnerOrderInformationGridOperatorRegistrationModel |
	InstallationPartnerOrderInformationGridOperatorDecisionModel |
	InstallationPartnerOrderInformationPrecheckModel |
	InstallationPartnerOrderInformationQuotationModel |
	InstallationPartnerOrderInformationApprovalModel;

export type AnyInstallationPartnerOrderInformationPayloadModel<Model extends AnyInstallationPartnerOrderInformationModel> = Model['payload'];

export type InstallationPartnerOrderInformationBasicAnswersModel = {
	type: InstallationPartnerOrderInformationType.BASIC_ANSWERS,
	payload: InstallationPartnerOrderInformationBasicAnswersInformationModel
} & InstallationPartnerOrderInformationFoundationModel;

export type InstallationPartnerOrderInformationGridOperatorRegistrationModel = {
	type: InstallationPartnerOrderInformationType.GRID_OPERATOR_REGISTRATION,
	payload: InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel
} & InstallationPartnerOrderInformationFoundationModel;

export type InstallationPartnerOrderInformationGridOperatorDecisionModel = {
	type: InstallationPartnerOrderInformationType.GRID_OPERATOR_DECISION,
	payload: InstallationPartnerOrderInformationGridOperatorDecisionInformationModel
} & InstallationPartnerOrderInformationFoundationModel;

export type InstallationPartnerOrderInformationPrecheckModel = {
	type: InstallationPartnerOrderInformationType.PRE_CHECK,
	payload: InstallationPartnerOrderInformationPrecheckInformationModel
} & InstallationPartnerOrderInformationFoundationModel;

export type InstallationPartnerOrderInformationQuotationModel = {
	type: InstallationPartnerOrderInformationType.OFFER,
	payload: InstallationPartnerOrderInformationQuotationInformationModel
} & InstallationPartnerOrderInformationFoundationModel;

export type InstallationPartnerOrderInformationApprovalModel = {
	type: InstallationPartnerOrderInformationType.APPROVAL,
	payload: InstallationPartnerOrderInformationApprovalInformationModel
} & InstallationPartnerOrderInformationFoundationModel;

export class AnyInstallationPartnerOrderInformationModelConverter extends ModelConverter<AnyInstallationPartnerOrderInformationModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyInstallationPartnerOrderInformationModel> | ArrayOfTimestampFieldNames<AnyInstallationPartnerOrderInformationModel>> {
		return ['createdAt'];
	}

	override getFieldConverterMapByModel(
		model: AnyInstallationPartnerOrderInformationModel | Dto<AnyInstallationPartnerOrderInformationModel>
	) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const converterMap = new Map<keyof AnyInstallationPartnerOrderInformationModel, any>([
			['comment', new TaskCompletionCommentModelConverter()]
		]);
		switch (model.type) {
			case InstallationPartnerOrderInformationType.BASIC_ANSWERS:
				converterMap.set('payload', new InstallationPartnerOrderInformationBasicAnswersInformationModelConverter());
				break;
			case InstallationPartnerOrderInformationType.GRID_OPERATOR_REGISTRATION:
				converterMap.set('payload', new InstallationPartnerOrderInformationGridOperatorRegistrationInformationModelConverter());
				break;
			case InstallationPartnerOrderInformationType.GRID_OPERATOR_DECISION:
				converterMap.set('payload', new InstallationPartnerOrderInformationGridOperatorDecisionInformationModelConverter());
				break;
			case InstallationPartnerOrderInformationType.PRE_CHECK:
				converterMap.set('payload', new InstallationPartnerOrderInformationPrecheckInformationModelConverter());
				break;
			case InstallationPartnerOrderInformationType.OFFER:
				converterMap.set('payload', new InstallationPartnerOrderInformationQuotationInformationModelConverter());
				break;
			case InstallationPartnerOrderInformationType.APPROVAL:
				converterMap.set('payload', new InstallationPartnerOrderInformationApprovalInformationModelConverter());
				break;
		}
		return converterMap;
	}

}
