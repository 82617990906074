import { ButtonHTMLAttributes } from 'react';

import './ButtonTertiary.scss';

export type ButtonTertiaryProps = {
	label: string
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonTertiary(props: ButtonTertiaryProps) {
	const { type = 'button', label, ...rest } = props;

	return (
		<button
			type={type}
			className="button-tertiary"
			{...rest}
		>{label}</button>
	);
}
