import { AddressModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, Dto, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { TaskFoundationModel } from './foundation/TaskFoundationModel';
import { TaskType } from './foundation/TaskType';
import { AddressValidationPayloadModel, AddressValidationPayloadModelConverter } from './payload/AddressValidationPayloadModel';
import {
	ArrangeAppointmentEscalationPayloadModel,
	ArrangeAppointmentEscalationPayloadModelConverter,
	ArrangeAppointmentPayloadModel,
	ArrangeAppointmentPayloadModelConverter
} from './payload/ArrangeAppointmentPayloadModel';
import {
	AnyBasicAnswersEscalationPayloadModel,
	AnyBasicAnswersPayloadModel,
	BasicAnswersEscalationPayloadModelConverter,
	BasicAnswersPayloadModelConverter
} from './payload/BasicAnswersPayloadModel';
import {
	BasicQuestionsEscalationPayloadModel,
	BasicQuestionsEscalationPayloadModelConverter,
	BasicQuestionsPayloadModel,
	BasicQuestionsPayloadModelConverter
} from './payload/BasicQuestionsPayloadModel';
import {
	InstallationApprovalPayloadModel,
	InstallationApprovalPayloadModelConverter
} from './payload/InstallationApprovalPayloadModel';
import {
	InstallationCompletionEscalationPayloadModel,
	InstallationCompletionEscalationPayloadModelConverter,
	InstallationCompletionPayloadModel,
	InstallationCompletionPayloadModelConverter
} from './payload/InstallationCompletionPayloadModel';
import {
	PaymentEscalationPayloadModel,
	PaymentEscalationPayloadModelConverter,
	PaymentPayloadModel,
	PaymentPayloadModelConverter
} from './payload/PaymentPayloadModel';
import {
	PreCheckDecisionGridOperatorEscalationPayloadModel,
	PreCheckDecisionGridOperatorEscalationPayloadModelConverter,
	PreCheckDecisionGridOperatorPayloadModel,
	PreCheckDecisionGridOperatorPayloadModelConverter
} from './payload/PreCheckDecisionGridOperatorPayloadModel';
import {
	PreCheckInstallationUnfeasibleEscalationPayloadModel,
	PreCheckInstallationUnfeasibleEscalationPayloadModelConverter,
	PreCheckInstallationUnfeasiblePayloadModel,
	PreCheckInstallationUnfeasiblePayloadModelConverter
} from './payload/PreCheckInstallationUnfeasiblePayloadModel';
import {
	PreCheckPreferencesEscalationPayloadModel,
	PreCheckPreferencesEscalationPayloadModelConverter,
	PreCheckPreferencesPayloadModel,
	PreCheckPreferencesPayloadModelConverter
} from './payload/PreCheckPreferencesPayloadModel';
import {
	PreCheckRegistrationGridOperatorEscalationPayloadModel,
	PreCheckRegistrationGridOperatorEscalationPayloadModelConverter,
	PreCheckRegistrationGridOperatorPayloadModel,
	PreCheckRegistrationGridOperatorPayloadModelConverter
} from './payload/PreCheckRegistrationGridOperatorPayloadModel';
import {
	PreCheckRejectedByGridOperatorEscalationPayloadModel,
	PreCheckRejectedByGridOperatorEscalationPayloadModelConverter,
	PreCheckRejectedByGridOperatorPayloadModel,
	PreCheckRejectedByGridOperatorPayloadModelConverter
} from './payload/PreCheckRejectedByGridOperatorPayloadModel';
import {
	AnyPreCheckRemoteEscalationPayloadModel,
	AnyPreCheckRemotePayloadModel,
	PreCheckRemoteEscalationPayloadModelConverter,
	PreCheckRemotePayloadModelConverter
} from './payload/PreCheckRemotePayloadModel';
import {
	PreCheckRevokeGridOperatorRegistrationPayloadModel,
	PreCheckRevokeGridOperatorRegistrationPayloadModelConverter
} from './payload/PreCheckRevokeGridOperatorRegistrationPayloadModel';
import {
	QuotationAcceptanceEscalationPayloadModel,
	QuotationAcceptanceEscalationPayloadModelConverter,
	QuotationAcceptancePayloadModel,
	QuotationAcceptancePayloadModelConverter
} from './payload/QuotationAcceptancePayloadModel';
import {
	QuotationEscalationPayloadModel,
	QuotationEscalationPayloadModelConverter,
	QuotationPayloadModel,
	QuotationPayloadModelConverter
} from './payload/QuotationPayloadModel';
import { UncoveredInstallationAddressPayloadModel, UncoveredInstallationAddressPayloadModelConverter } from './payload/UncoveredInstallationAddressPayloadModel';

export type AnyTaskModel =
	BasicQuestionsTaskModel
	| BasicAnswersTaskModel
	| PreCheckRemoteTaskModel
	| PreCheckPreferencesTaskModel
	| PreCheckRegistrationGridOperatorTaskModel
	| PreCheckDecisionGridOperatorTaskModel
	| PreCheckRevokeGridOperatorRegistrationTaskModel
	| PreCheckInstallationUnfeasibleTaskModel
	| PreCheckRejectedByGridOperatorTaskModel
	| QuotationTaskModel
	| QuotationAcceptanceTaskModel
	| PaymentTaskModel
	| ArrangeAppointmentTaskModel
	| InstallationApprovalTaskModel
	| InstallationCompletionTaskModel
	| BasicQuestionsEscalationTaskModel
	| BasicAnswersEscalationTaskModel
	| PreCheckRemoteEscalationTaskModel
	| PreCheckPreferencesEscalationTaskModel
	| PreCheckRegistrationGridOperatorEscalationTaskModel
	| PreCheckDecisionGridOperatorEscalationTaskModel
	| PreCheckInstallationUnfeasibleEscalationTaskModel
	| PreCheckRejectedByGridOperatorEscalationTaskModel
	| QuotationEscalationTaskModel
	| QuotationAcceptanceEscalationTaskModel
	| PaymentEscalationTaskModel
	| ArrangeAppointmentEscalationTaskModel
	| InstallationCompletionEscalationTaskModel
	| AddressValidationTaskModel
	| UncoveredInstallationAddressTaskModel

export type BasicQuestionsTaskModel = {
	type: TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS,
	payload: BasicQuestionsPayloadModel
} & TaskFoundationModel;

export type BasicAnswersTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS,
	payload: AnyBasicAnswersPayloadModel
} & TaskFoundationModel;

export type PreCheckRemoteTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK,
	payload: AnyPreCheckRemotePayloadModel
} & TaskFoundationModel;

export type PreCheckRegistrationGridOperatorTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR,
	payload: PreCheckRegistrationGridOperatorPayloadModel
} & TaskFoundationModel;

export type PreCheckDecisionGridOperatorTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION,
	payload: PreCheckDecisionGridOperatorPayloadModel
} & TaskFoundationModel;

export type PreCheckRevokeGridOperatorRegistrationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_REVOKE_GRID_OPERATOR_REGISTRATION,
	payload: PreCheckRevokeGridOperatorRegistrationPayloadModel
} & TaskFoundationModel;

export type PreCheckPreferencesTaskModel = {
	type: TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES,
	payload: PreCheckPreferencesPayloadModel
} & TaskFoundationModel;

export type PreCheckInstallationUnfeasibleTaskModel = {
	type: TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION,
	payload: PreCheckInstallationUnfeasiblePayloadModel
} & TaskFoundationModel;

export type PreCheckRejectedByGridOperatorTaskModel = {
	type: TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION,
	payload: PreCheckRejectedByGridOperatorPayloadModel
} & TaskFoundationModel;

export type QuotationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION,
	payload: QuotationPayloadModel
} & TaskFoundationModel;

export type QuotationAcceptanceTaskModel = {
	type: TaskType.CUSTOMER_ACCEPT_QUOTATION,
	payload: QuotationAcceptancePayloadModel
} & TaskFoundationModel;

export type PaymentTaskModel = {
	type: TaskType.CUSTOMER_PERFORM_PAYMENT,
	payload: PaymentPayloadModel
} & TaskFoundationModel;

export type ArrangeAppointmentTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT,
	payload: ArrangeAppointmentPayloadModel
} & TaskFoundationModel;

export type InstallationApprovalTaskModel = {
	type: TaskType.CUSTOMER_APPROVE_INSTALLATION,
	payload: InstallationApprovalPayloadModel
} & TaskFoundationModel;

export type InstallationCompletionTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION,
	payload: InstallationCompletionPayloadModel
} & TaskFoundationModel;

export type BasicQuestionsEscalationTaskModel = {
	type: TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION,
	payload: BasicQuestionsEscalationPayloadModel
} & TaskFoundationModel;

export type BasicAnswersEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION,
	payload: AnyBasicAnswersEscalationPayloadModel
} & TaskFoundationModel;

export type PreCheckRemoteEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION,
	payload: AnyPreCheckRemoteEscalationPayloadModel
} & TaskFoundationModel;

export type PreCheckRegistrationGridOperatorEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION,
	payload: PreCheckRegistrationGridOperatorEscalationPayloadModel
} & TaskFoundationModel;

export type PreCheckDecisionGridOperatorEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION,
	payload: PreCheckDecisionGridOperatorEscalationPayloadModel
} & TaskFoundationModel;

export type PreCheckPreferencesEscalationTaskModel = {
	type: TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION,
	payload: PreCheckPreferencesEscalationPayloadModel
} & TaskFoundationModel;

export type PreCheckInstallationUnfeasibleEscalationTaskModel = {
	type: TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION,
	payload: PreCheckInstallationUnfeasibleEscalationPayloadModel
} & TaskFoundationModel;

export type PreCheckRejectedByGridOperatorEscalationTaskModel = {
	type: TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION,
	payload: PreCheckRejectedByGridOperatorEscalationPayloadModel
} & TaskFoundationModel;

export type AddressValidationTaskModel = {
	type: TaskType.CUSTOMER_ADDRESS_VALIDATION_ESCALATION,
	payload: AddressValidationPayloadModel
} & TaskFoundationModel;

export type UncoveredInstallationAddressTaskModel = {
	type: TaskType.CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS,
	payload: UncoveredInstallationAddressPayloadModel
} & TaskFoundationModel;

export type QuotationEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION,
	payload: QuotationEscalationPayloadModel
} & TaskFoundationModel;

export type QuotationAcceptanceEscalationTaskModel = {
	type: TaskType.CUSTOMER_ACCEPT_QUOTATION_ESCALATION,
	payload: QuotationAcceptanceEscalationPayloadModel
} & TaskFoundationModel;

export type PaymentEscalationTaskModel = {
	type: TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION,
	payload: PaymentEscalationPayloadModel
} & TaskFoundationModel;

export type ArrangeAppointmentEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION,
	payload: ArrangeAppointmentEscalationPayloadModel
} & TaskFoundationModel;

export type InstallationCompletionEscalationTaskModel = {
	type: TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION,
	payload: InstallationCompletionEscalationPayloadModel
} & TaskFoundationModel;

/*
* AnyTaskModelConverter is a converter implementation of the ModelConverter<AnyTaskModel>
*
* As the AnyTask model uses the TaskFoundationModel as common part of the type definition
* the converter for this type needs to be a part of this converter (fields in the initial converter map).
*
* The payload uses custom converters for every type.
* */
export class AnyTaskModelConverter extends ModelConverter<AnyTaskModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyTaskModel> | ArrayOfTimestampFieldNames<AnyTaskModel>> {
		return ['updatedOn', 'dueDate'];
	}

	override getFieldConverterMapByModel(model: AnyTaskModel | Dto<AnyTaskModel>) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const converterMap = new Map<keyof AnyTaskModel, ModelConverterInterface<any>>([
			['installationAddress', new AddressModelConverter()]
		]);
		switch (model.type) {
			case TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new BasicQuestionsPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new BasicQuestionsEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new BasicAnswersPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new BasicAnswersEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRemotePayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRemoteEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRegistrationGridOperatorPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRegistrationGridOperatorEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckDecisionGridOperatorPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckDecisionGridOperatorEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_REVOKE_GRID_OPERATOR_REGISTRATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRevokeGridOperatorRegistrationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckPreferencesPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckPreferencesEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckInstallationUnfeasiblePayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckInstallationUnfeasibleEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRejectedByGridOperatorPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PreCheckRejectedByGridOperatorEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_ADDRESS_VALIDATION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new AddressValidationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new UncoveredInstallationAddressPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new QuotationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new QuotationEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_ACCEPT_QUOTATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new QuotationAcceptancePayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_ACCEPT_QUOTATION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new QuotationAcceptanceEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_PERFORM_PAYMENT:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PaymentPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new PaymentEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new ArrangeAppointmentPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new ArrangeAppointmentEscalationPayloadModelConverter());
				return converterMap;
			case TaskType.CUSTOMER_APPROVE_INSTALLATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new InstallationApprovalPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new InstallationCompletionPayloadModelConverter());
				return converterMap;
			case TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				converterMap.set('payload', new InstallationCompletionEscalationPayloadModelConverter());
				return converterMap;
		}
	}

}
