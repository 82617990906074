import {
	InstallationPartnerOrderInformationApprovalModel,
	InstallationPartnerOrderInformationBasicAnswersModel,
	InstallationPartnerOrderInformationGridOperatorDecisionModel,
	InstallationPartnerOrderInformationGridOperatorRegistrationModel,
	InstallationPartnerOrderInformationPrecheckModel,
	InstallationPartnerOrderInformationQuotationModel,
	InstallationPartnerOrderInformationType
} from '@abb-emobility/oms/domain-model';
import { taskCompletionCommentModelMock } from '@abb-emobility/shared/api-integration';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	installationPartnerOrderInformationApprovalInformationModelMock
} from './order-information/InstallationPartnerOrderInformationApprovalInformationModel.mock';
import {
	installationPartnerOrderInformationBasicAnswersInformationModelMock
} from './order-information/InstallationPartnerOrderInformationBasicAnswersInformationModel.mock';
import {
	installationPartnerOrderInformationGridOperatorDecisionInformationModel
} from './order-information/InstallationPartnerOrderInformationGridOperatorDecisionInformationModel.mock';
import {
	installationPartnerOrderInformationGridOperatorRegistrationInformationModelMock
} from './order-information/InstallationPartnerOrderInformationGridOperatorRegistrationInformationModel.mock';
import {
	installationPartnerOrderInformationPrecheckInformationModelMock
} from './order-information/InstallationPartnerOrderInformationPrecheckInformationModel.mock';
import {
	installationPartnerOrderInformationQuotationInformationModelMock
} from './order-information/InstallationPartnerOrderInformationQuotationInformationModel.mock';

export const installationPartnerOrderInformationBasicAnswersModelMock = (): Dto<InstallationPartnerOrderInformationBasicAnswersModel> => {
	return {
		id: '1',
		orderId: '1',
		type: InstallationPartnerOrderInformationType.BASIC_ANSWERS,
		createdAt: '2022-01-01T16:30:12.345Z',
		payload: installationPartnerOrderInformationBasicAnswersInformationModelMock(),
		comment: taskCompletionCommentModelMock
	};
};

export const installationPartnerOrderInformationGridOperatorRegistrationModelMock: Dto<InstallationPartnerOrderInformationGridOperatorRegistrationModel> = {
	id: '2',
	orderId: '1',
	type: InstallationPartnerOrderInformationType.GRID_OPERATOR_REGISTRATION,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: installationPartnerOrderInformationGridOperatorRegistrationInformationModelMock,
	comment: taskCompletionCommentModelMock
};

export const installationPartnerOrderInformationGridOperatorDecisionModelMock: Dto<InstallationPartnerOrderInformationGridOperatorDecisionModel> = {
	id: '3',
	orderId: '1',
	type: InstallationPartnerOrderInformationType.GRID_OPERATOR_DECISION,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: installationPartnerOrderInformationGridOperatorDecisionInformationModel,
	comment: taskCompletionCommentModelMock
};

export const installationPartnerOrderInformationPrecheckModelMock: Dto<InstallationPartnerOrderInformationPrecheckModel> = {
	id: '4',
	orderId: '1',
	type: InstallationPartnerOrderInformationType.PRE_CHECK,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: installationPartnerOrderInformationPrecheckInformationModelMock,
	comment: taskCompletionCommentModelMock
};

export const installationPartnerOrderInformationQuotationModelMock: Dto<InstallationPartnerOrderInformationQuotationModel> = {
	id: '5',
	orderId: '1',
	type: InstallationPartnerOrderInformationType.OFFER,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: installationPartnerOrderInformationQuotationInformationModelMock,
	comment: taskCompletionCommentModelMock
};

export const installationPartnerOrderInformationApprovalModelMock: Dto<InstallationPartnerOrderInformationApprovalModel> = {
	id: '6',
	orderId: '1',
	type: InstallationPartnerOrderInformationType.APPROVAL,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: installationPartnerOrderInformationApprovalInformationModelMock,
	comment: taskCompletionCommentModelMock
};
