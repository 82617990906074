import { Route } from 'react-router-dom';

import { setPageTitle } from '@abb-emobility/shared/browser';
import { useSentryRoutes } from '@abb-emobility/shared/sentry-integration';

import { RoutePath } from './Routes';
import { useBuildPageTitle } from '../hooks/PageTitle.hook';
import { NotFoundView } from '../views/not-found-view/NotFoundView';
import { OrderDocumentView } from '../views/order-document-view/OrderDocumentView';
import { OrderView } from '../views/order-view/OrderView';
import { PaymentResultView } from '../views/payment-result-view/PaymentResultView';
import { TaskView } from '../views/task-view/TaskView';

export function Router() {

	setPageTitle(useBuildPageTitle());

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const Routes = useSentryRoutes();

	return (
		<Routes>
			<Route path={RoutePath.ORDER} element={<OrderView />} />
			<Route path={RoutePath.TASK} element={<TaskView />} />
			<Route path={RoutePath.PAYMENT_RESULT} element={<PaymentResultView />} />
			<Route path={RoutePath.DOCUMENTS} element={<OrderDocumentView />} />
			<Route path="*" element={<NotFoundView />} />
		</Routes>
	);
}
