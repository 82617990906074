import { ReactNode } from 'react';

import './TaskLayout.scss';

export type TaskLayoutProps = {
	children?: ReactNode
};

export function TaskLayout(props: TaskLayoutProps) {
	const { children } = props;

	return (
		<article className="task-layout">
			{children}
		</article>
	);
}
