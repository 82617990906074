import React, { ReactNode } from 'react';

import { CustomerOrderInformationOrderItemsModel } from '@abb-emobility/oms/domain-model';
import {
	calculateMajorUnitFromMinorUnit,
	getCurrencyByCode,
	getCurrencySymbolByCurrency
} from '@abb-emobility/shared/currency';
import { useL10n } from '@abb-emobility/shared/localization-provider';

import './OrderItemInformation.scss';

export type OrderItemInformationProps = {
	orderInformation: CustomerOrderInformationOrderItemsModel
};

export function OrderItemInformation(props: OrderItemInformationProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderGrossPrice = (): ReactNode => {
		const currency = getCurrencyByCode(orderInformation.payload.currencyCode);
		if (currency === null) {
			return '';
		}
		const price = l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, orderInformation.payload.totalGrossPrice), currency.fraction);
		return (
			<>
				{price}&nbsp;{getCurrencySymbolByCurrency(currency)}
			</>
		);
	};

	const renderOrderItems = (): ReactNode => {
		const orderLineItems = orderInformation.payload.orderLineItems;
		const orderLineItemElements = orderLineItems.map<ReactNode>((item, index) => {
			let itemName: ReactNode = item.productName;
			if (item.cancelled) {
				itemName = (
					<span>
						<s>{itemName}</s>
						<br />
						({l10n.translate('omsCustomerApp.order.information.orderItems.cancellationHint', new Map([['cancellationDate', l10n.formatTimestampDateTime(item.cancelledAt)]]))})
					</span>
				);
			}
			return (
				<tr className="order-item" key={item.id}>
					<td>#{index + 1}</td>
					<td>{itemName}</td>
				</tr>
			);
		});
		orderLineItemElements.push(
			<tr className="order-item order-item--last" key="sum">
				<td>{l10n.translate('omsCustomerApp.order.information.orderItems.totalGrossPrice')}</td>
				<td>{renderGrossPrice()}</td>
			</tr>
		);
		return orderLineItemElements;
	};

	const renderWallboxInformation = (): ReactNode => {
		if (!orderInformation.payload.wallboxSerialNumber) {
			return null;
		}
		return (
			<tr className="order-item__serial-number" key="wallbox-serial">
				<td>{l10n.translate('omsCustomerApp.order.information.orderItems.wallboxSerialNumbner')}</td>
				<td>{orderInformation.payload.wallboxSerialNumber}</td>
			</tr>
		);
	};

	return (
		<article className="order-item-information">
			<table className="order-items">
				<thead>
					<tr>
						<th>{l10n.translate('omsCustomerApp.order.information.orderItems.position')}</th>
						<th>{l10n.translate('omsCustomerApp.order.information.orderItems.description')}</th>
					</tr>
				</thead>
				<tbody>
					{renderOrderItems()}
					{renderWallboxInformation()}
				</tbody>
			</table>
		</article>
	);
}
