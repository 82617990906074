import { CurrencyCode } from '@abb-emobility/shared/currency';
import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerDeltaInstallationPackageModel,
	CustomerDeltaInstallationPackageModelConverter
} from './CustomerDeltaInstallationPackageModel';
import { numericApiSafeguard } from '../converter/NumericSafeGuard';

export type QuotationModel = {
	readonly items: Array<CustomerDeltaInstallationPackageModel>,
	readonly quotationConditions?: string,
	readonly currencyCode: CurrencyCode,
	readonly netPrice: number,
	readonly grossPrice: number,
	readonly vatRate: number
};

export class QuotationModelConverter extends ModelConverter<QuotationModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof QuotationModel, ModelConverterInterface<any>> {
		return new Map([
			['items', new CustomerDeltaInstallationPackageModelConverter()]
		]);
	}

	override getCustomConversionFields(): Map<keyof QuotationModel, CustomConversion> {
		return new Map([
			['netPrice', numericApiSafeguard],
			['grossPrice', numericApiSafeguard],
			['vatRate', numericApiSafeguard]
		]);
	}

}
