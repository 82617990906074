import { InstallationPartnerFiscalReportModel, InstallationPartnerFiscalReportModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class InstallationPartnerFiscalReportApiClient extends AbstractCrudApiClient<InstallationPartnerFiscalReportModel> {
	protected collectionControllerUri = 'fiscal-reports/';
	protected paginationControllerUri = 'fiscal-reports/';
	protected entityControllerUri = 'fiscal-report/{id}/';
	protected entitySearchControllerUri = 'fiscal-report/';
	protected modelConverter = new InstallationPartnerFiscalReportModelConverter();
}
