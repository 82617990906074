import React, { ReactNode, Suspense } from 'react';

import { SuccessIllustrationIdentifier } from './SuccessFeeback.enums';

import './SuccessFeedback.scss';

const SuccessIllustration1 = React.lazy(() => import('../illustration/SuccessIllustration1'));
const SuccessIllustration2 = React.lazy(() => import('../illustration/SuccessIllustration2'));
const SuccessIllustration3 = React.lazy(() => import('../illustration/SuccessIllustration3'));

export type SuccessProps = {
	illustration?: SuccessIllustrationIdentifier,
	heading: string,
	message: string,
	children?: ReactNode
};

export function SuccessFeeback(props: SuccessProps) {
	const { illustration = SuccessIllustrationIdentifier.ILLUSTRATION_1, heading, message, children = null } = props;

	const renderIllustration = (): ReactNode => {
		switch (illustration) {
			case SuccessIllustrationIdentifier.ILLUSTRATION_2:
				return (<SuccessIllustration2 />);
			case SuccessIllustrationIdentifier.ILLUSTRATION_3:
				return (<SuccessIllustration3 />);
			default:
				return (<SuccessIllustration1 />);
		}
	};

	const renderFooter = (): ReactNode => {
		if (children === null) {
			return;
		}
		return (
			<footer className="success__footer">
				{children}
			</footer>
		);
	};

	return (
		<article className="success">
			<header className="success__header">
				<Suspense fallback={null}>
					{renderIllustration()}
				</Suspense>
			</header>
			<main className="success__main">
				<h1 className="success__main__heading">
					{heading}
				</h1>
				<p className="success__main__description">
					{message}
				</p>
			</main>
			{renderFooter()}
		</article>
	);
}
