/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { numericApiSafeguard } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames,
	CustomConversion,
	Dto,
	ModelConverter,
	ModelConverterInterface,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerOrderInformationOrderItemsInformationOrderLineItemModel,
	CustomerOrderInformationOrderItemsInformationOrderLineItemModelConverter
} from './CustomerOrderInformationOrderItemsInformationLineItemModel';

export type CustomerOrderInformationOrderItemsInformationModel = {
	readonly orderLineItems: Array<CustomerOrderInformationOrderItemsInformationOrderLineItemModel>,
	readonly wallboxSerialNumber?: string
	readonly orderDate: Timestamp,
	readonly marketplaceName: string,
	readonly totalGrossPrice: number,
	readonly currencyCode: CurrencyCode
};

export class CustomerOrderInformationApprovalInformationModelConverter extends ModelConverter<CustomerOrderInformationOrderItemsInformationModel> {

	override getCustomConversionFields(): Map<keyof CustomerOrderInformationOrderItemsInformationModel, CustomConversion> {
		return new Map([
			['totalGrossPrice', numericApiSafeguard]
		]);
	}

	override getTimestampFields(): Array<TimestampFieldNames<CustomerOrderInformationOrderItemsInformationModel> | ArrayOfTimestampFieldNames<CustomerOrderInformationOrderItemsInformationModel>> {
		return ['orderDate'];
	}

	override getFieldConverterMapByModel(_model: Dto<CustomerOrderInformationOrderItemsInformationModel> | CustomerOrderInformationOrderItemsInformationModel): Map<keyof CustomerOrderInformationOrderItemsInformationModel, ModelConverterInterface<any>> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return new Map<keyof CustomerOrderInformationOrderItemsInformationModel, ModelConverterInterface<any>>([
			['orderLineItems', new CustomerOrderInformationOrderItemsInformationOrderLineItemModelConverter()]
		]);
	}

}
