import { ReactNode, useEffect, useState } from 'react';

import {
	ButtonBar,
	ButtonBarSection,
	ButtonBarSectionAlign, ButtonPrimary, ButtonTertiary,
	Form,
	Separator,
	SpinnerCable,
	SuccessFeeback
} from '@abb-emobility/oms/customer-ui-primitive';
import { scrollWindowToTop } from '@abb-emobility/shared/browser';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { TimeOfDay } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	PreCheckChannelModel,
	PreCheckPreferencesPayloadModel,
	PreCheckPreferencesTaskModel
} from '@abb-emobility/usertask/domain-model';

import { TaskPreCheckPreferencesFormStep1 } from './TaskPreCheckPreferencesFormStep1';
import { TaskPreCheckPreferencesFormStep2 } from './TaskPreCheckPreferencesFormStep2';

export type TaskPreCheckPreferencesProps = {
	task: PreCheckPreferencesTaskModel
};

export function TaskPreCheckPreferences(props: TaskPreCheckPreferencesProps) {

	const { task } = props;

	const l10n = useL10n();
	const anyTaskEntityData = useAnyTaskEntityData();
	const actionStatus = anyTaskEntityData.queryActionStatus();
	const [taskPayload, setTaskPayload] = useState<PreCheckPreferencesPayloadModel>(task.payload);
	const [formStep, setFormStep] = useState<number>(1);

	useEffect(() => {
		scrollWindowToTop();
	}, [formStep]);

	const handlePrevious = (): void => {
		setFormStep(1);
	};

	const handleNext = (): void => {
		setFormStep(2);
	};

	const handleSubmit = (): void => {
		anyTaskEntityData.complete(task, taskPayload);
	};

	const handlePreCheckChannelSelect = (channel: PreCheckChannelModel): void => {
		const selectedTimesOfDay = channel.medium.id !== taskPayload.preferredMediumId ? [] : taskPayload.preferredTimesOfDay;
		setTaskPayload({
			...taskPayload,
			preferredMediumId: channel.medium.id,
			preferredTimesOfDay: selectedTimesOfDay
		});
	};

	const handlePreferredTimesOfDaySelect = (timesOfDay: Array<TimeOfDay>): void => {
		setTaskPayload({
			...taskPayload,
			preferredTimesOfDay: timesOfDay
		});
	};

	const renderPayload = (): ReactNode => {
		switch (actionStatus) {
			case TaskActionStatus.COMPLETE_PENDING:
				return (<SpinnerCable />);
			case TaskActionStatus.COMPLETE_SUCCESS:
				return (
					<SuccessFeeback
						heading={l10n.translate('omsCustomerApp.task.preCheckPreferences.completionSuccess.heading')}
						message={l10n.translate('omsCustomerApp.task.preCheckPreferences.completionSuccess.message')}
					/>
				);
			default:
				switch (formStep) {
					case 1:
						return (
							<>
								<TaskPreCheckPreferencesFormStep1
									task={task}
									selectedMediumId={taskPayload.preferredMediumId}
									onSelectChannel={handlePreCheckChannelSelect}
								/>
								<Separator />
								{renderButtonBar()}
							</>
						);
					case 2:
						if (taskPayload.preferredMediumId !== undefined) {
							return (
								<>
									<TaskPreCheckPreferencesFormStep2
										task={task}
										mediumId={taskPayload.preferredMediumId}
										selectedTimesOfDay={taskPayload.preferredTimesOfDay ?? []}
										onSelectPreferredTimesOfDay={handlePreferredTimesOfDaySelect}
									/>
									<Separator />
									{renderButtonBar()}
								</>
							);
						}
					/* Intended fall through */
					default:
						throw new AppError('Form step ' + formStep + ' invalid.');
				}
		}
	};

	const renderSubmitButton = (): ReactNode => {
		switch (formStep) {
			case 1:
				return (
					<ButtonPrimary
						type="submit"
						label={l10n.translate('omsCustomerApp.task.preCheckPreferences.nextStepButtonLabel')}
						disabled={(taskPayload.preferredMediumId ?? null) === null}
						onClick={handleNext}
					/>
				);
			case 2:
				return (
					<ButtonPrimary
						type="submit"
						label={l10n.translate('omsCustomerApp.task.preCheckPreferences.submitButtonLabel')}
						disabled={(taskPayload.preferredTimesOfDay?.length ?? 0) === 0}
						onClick={handleSubmit}
					/>
				);
			default:
				throw new AppError('Form step ' + formStep + ' invalid.');
		}
	};

	const renderButtonBar = (): ReactNode => {
		return (
			<ButtonBar>
				<ButtonBarSection>
					<ButtonTertiary
						label={l10n.translate('omsCustomerApp.task.preCheckPreferences.previousStepButtonLabel')}
						onClick={handlePrevious}
						disabled={formStep === 1}
					/>
				</ButtonBarSection>
				<ButtonBarSection align={ButtonBarSectionAlign.ALIGN_RIGHT}>
					{renderSubmitButton()}
				</ButtonBarSection>
			</ButtonBar>
		);
	};

	return (
		<Form>
			{renderPayload()}
		</Form>
	);

}
