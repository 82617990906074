import { preCheckMediumModelMock01, preCheckMediumModelMock02, preCheckMediumModelMock03 } from '@abb-emobility/shared/api-integration';
import { Dto, TimeOfDay } from '@abb-emobility/shared/domain-model-foundation';
import { PreCheckPreferencesPayloadModel } from '@abb-emobility/usertask/domain-model';

export const preCheckPreferencesPayloadModelMock: Dto<PreCheckPreferencesPayloadModel> = {
	availableChannels: [
		{
			medium: preCheckMediumModelMock01,
			availableTimesOfDay: [TimeOfDay.MORNING, TimeOfDay.NOON, TimeOfDay.AFTERNOON, TimeOfDay.EVENING]
		},
		{
			medium: preCheckMediumModelMock02,
			availableTimesOfDay: [TimeOfDay.NOON, TimeOfDay.AFTERNOON, TimeOfDay.EVENING]
		},
		{
			medium: preCheckMediumModelMock03,
			availableTimesOfDay: [TimeOfDay.MORNING, TimeOfDay.NOON, TimeOfDay.AFTERNOON]
		}
	]
};
