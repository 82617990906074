import { CustomerModel } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerModelMock: Dto<CustomerModel> = {
	id: '1',
	name: 'Max Mustermann',
	phoneNumber: '+4945218797979',
	mailAddress: 'maximilian.muster@domain.tld',
	address: 'Musterstraße 234, 24145 Kiel',
	companyName: 'Musterfirma'
};
