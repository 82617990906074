import { CustomerOrderModel, CustomerOrderModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerOrderApiClient extends AbstractCrudApiClient<CustomerOrderModel> {
	protected collectionControllerUri = 'orders/';
	protected paginationControllerUri = 'orders/';
	protected entityControllerUri = 'order/{id}/';
	protected entitySearchControllerUri = 'order/';
	protected modelConverter = new CustomerOrderModelConverter();
}
