import { Optional, Probably } from '@abb-emobility/shared/util';

import { AccessibleUrl, UrlDto, Url } from './Url';
import { isUrlDto, isUrl } from './Url.guards';

export const createUrlFromUrlDto = (urlDto: Probably<UrlDto>): Optional<Url> => {
	let url = null;
	if (isUrlDto(urlDto)) {
		url = {
			discriminator: 'Url',
			...urlDto
		} as AccessibleUrl;
	}
	return new Optional<Url>(url);
};

export const createUrlDtoFromUrl = (url: Probably<Url>): Optional<UrlDto> => {
	let urlDto = null;
	if (isUrl(url)) {
		urlDto = { ...url } as Record<string, unknown>;
		delete urlDto['discriminator'];
	}
	return new Optional<UrlDto>(urlDto as UrlDto);
};

export const createAccessibleUrlFromUrlString = (url: string): AccessibleUrl => {
	return {
		discriminator: 'Url',
		accessible: true,
		value: url
	};
};
