import React from 'react';

import { TaskCompleteError } from '@abb-emobility/shared/business-error';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { AuthenticationFailedError, AuthenticationRequiredError, NotFoundError, restoreError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useAnyTaskEntitySearchData } from '@abb-emobility/usertask/data-provider';
import { TaskType } from '@abb-emobility/usertask/domain-model';

import { TaskBasicQuestions } from './task-basic-questions/TaskBasicQuestions';
import { TaskInstallationApproval } from './task-installation-approval/TaskInstallationApproval';
import { TaskPayment } from './task-payment/TaskPayment';
import { TaskPreCheckInstallationUnfeasible } from './task-pre-check-installation-unfeasible/TaskPreCheckInstallationUnfeasible';
import { TaskPreCheckPreferences } from './task-pre-check-preferences/TaskPreCheckPreferences';
import { TaskPreCheckRejectedByGridOperator } from './task-pre-check-rejected-by-grid-operator/TaskPreCheckRejectedByGridOperator';
import { TaskQuotationAcceptance } from './task-quotation-acceptance/TaskQuotationAcceptance';

export type TaskSearchResolverProps = {
	taskProps?: Record<string, string | boolean | number>
};

export function TaskSearchResolver(props: TaskSearchResolverProps) {

	const { taskProps = {} } = props;

	const l10n = useL10n();

	const anyTaskSearchData = useAnyTaskEntitySearchData();
	const task = anyTaskSearchData.query().getOrThrow(new NotFoundError(l10n.translate('omsCustomerApp.error.taskNotFound')));
	const actionErrorObject = anyTaskSearchData.queryActionError();

	anyTaskSearchData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskCompleteError(l10n.translate('omsCustomerApp.error.taskCompleteMessage'), undefined, undefined, error);
	}, true);

	const renderTask = () => {
		switch (task.type) {
			case TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS:
				return (<TaskBasicQuestions task={task} {...taskProps} />);
			case TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES:
				return (<TaskPreCheckPreferences task={task} {...taskProps} />);
			case TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION:
				return (<TaskPreCheckInstallationUnfeasible task={task} {...taskProps} />);
			case TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION:
				return (<TaskPreCheckRejectedByGridOperator task={task} {...taskProps} />);
			case TaskType.CUSTOMER_ACCEPT_QUOTATION:
				return (<TaskQuotationAcceptance task={task} {...taskProps} />);
			case TaskType.CUSTOMER_PERFORM_PAYMENT:
				return (<TaskPayment task={task} {...taskProps} />);
			case TaskType.CUSTOMER_APPROVE_INSTALLATION:
				return (<TaskInstallationApproval task={task} {...taskProps} />);
			default:
				throw new NotFoundError(l10n.translate('omsCustomerApp.error.taskTypeNotFound', new Map([['taskType', task.type]])));
		}
	};

	return (
		<>
			{renderTask()}
		</>
	);

}
