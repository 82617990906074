/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Mutable, Mutation, Model, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { AnyErrorObject } from '@abb-emobility/shared/error';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { CrudActionStatus } from '../../store/CrudActionStatus';
import { FetchStatus, fetchStatusPendingGroup } from '../../store/FetchStatus';
import { ModelFilter } from '../../store/ModelFilter';
import { ModelLimit } from '../../store/ModelLimit';
import { ModelSort } from '../../store/ModelSort';
import { CrudCollectionStore } from '../../store/crud/CrudCollectionStore';
import { CrudCollectionStoreAccessors } from '../../store/crud/CrudCollectionStoreAccessor';

export type CrudCollectionDataProviderValue<T extends Model> = {
	fetch: (sortCriteria?: SortCriteria<T>, filterCriteria?: FilterCriteria<T>, forceFetch?: boolean) => void,
	refetch: () => void,
	applySort: (sortCriteria: SortCriteria<T>, forceFetch?: boolean) => void,
	clearSort: (forceFetch?: boolean) => void,
	applyFilter: (filterCriteria: FilterCriteria<T>, forceFetch?: boolean) => void,
	clearFilter: (forceFetch?: boolean) => void,
	fetchNext: () => void,
	fetchEntity: (id: ModelPrimaryKey, forceFetch?: boolean) => void,
	refetchEntity: (id: ModelPrimaryKey) => void,
	query: (sort?: ModelSort<T>, filter?: ModelFilter<T>, limit?: ModelLimit) => ReadonlyArray<T>,
	queryCount: (filter?: ModelFilter<T>) => number,
	hasResults: (filter?: ModelFilter<T>) => boolean,
	queryEntity: (id: ModelPrimaryKey) => ReadonlyOptional<T>,
	queryCreated: () => ReadonlyOptional<T>,
	mutate: (id: ModelPrimaryKey, mutation: Mutation<T>) => void,
	create: (mutatable: Mutable<T>) => void,
	delete: (id: ModelPrimaryKey) => void,
	idle: () => boolean,
	pending: () => boolean,
	hasNextPage: () => boolean,
	isPaginated: () => boolean,
	queryCurrentPage: () => Nullable<number>,
	queryMaxPages: () => Nullable<number>,
	isSorted: () => boolean,
	querySortCriteria: () => Nullable<SortCriteria<T>>,
	isFiltered: () => boolean,
	queryFilterCriteria: () => Nullable<FilterCriteria<T>>,
	queryFetchStatus: () => FetchStatus,
	queryFetchEntityStatus: (id: ModelPrimaryKey) => FetchStatus,
	queryFetchError: () => Nullable<AnyErrorObject>,
	queryActionStatus: () => CrudActionStatus,
	queryActionError: () => Nullable<AnyErrorObject>,
	resolveFetchStatus: () => void,
	resolveActionStatus: () => void,
	useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void) => void,
	useFetchEntityStatusEffect: (id: ModelPrimaryKey, status: Array<FetchStatus>, effect: () => void) => void,
	useActionStatusEffect: (status: Array<CrudActionStatus>, effect: () => void, resolve: boolean) => void,
	resetStore: () => void,
	storeSize: () => number,
	storeEntryIds: () => ReadonlyArray<ModelPrimaryKey>
};

export const defaultCrudCollectionDataValue: CrudCollectionDataProviderValue<any> = {
	idle: (): boolean => {
		return true;
	},
	pending: (): boolean => {
		return false;
	},
	hasNextPage: (): boolean => {
		return false;
	},
	isPaginated: (): boolean => {
		return false;
	},
	queryCurrentPage: (): Nullable<number> => {
		return null;
	},
	queryMaxPages: (): Nullable<number> => {
		return null;
	},
	isSorted: (): boolean => {
		return false;
	},
	querySortCriteria: (): Nullable<SortCriteria<any>> => {
		return null;
	},
	isFiltered: (): boolean => {
		return false;
	},
	queryFilterCriteria: (): Nullable<FilterCriteria<any>> => {
		return null;
	},
	queryFetchStatus: (): FetchStatus => {
		return FetchStatus.IDLE;
	},
	queryFetchEntityStatus: (_id: ModelPrimaryKey): FetchStatus => {
		return FetchStatus.IDLE;
	},
	queryFetchError: (): Nullable<AnyErrorObject> => {
		return null;
	},
	queryActionStatus: (): CrudActionStatus => {
		return CrudActionStatus.IDLE;
	},
	queryActionError: (): Nullable<AnyErrorObject> => {
		return null;
	},
	fetch: (_sortCriteria?: SortCriteria<any>, _filterCriteria?: FilterCriteria<any>, _forceFetch?: boolean): void => {
		throw new Error('Data provider missing');
	},
	refetch: (): void => {
		throw new Error('Data provider missing');
	},
	applySort: (_sortCriteria: SortCriteria<any>, _forceFetch?: boolean): void => {
		throw new Error('Data provider missing');
	},
	clearSort: (_forceFetch?: boolean): void => {
		throw new Error('Data provider missing');
	},
	applyFilter: (_filterCriteria: FilterCriteria<any>, _forceFetch?: boolean): void => {
		throw new Error('Data provider missing');
	},
	clearFilter: (_forceFetch?: boolean): void => {
		throw new Error('Data provider missing');
	},
	fetchNext: (): void => {
		throw new Error('Data provider missing');
	},
	fetchEntity: (_id: ModelPrimaryKey, _forceFetch?: boolean): void => {
		throw new Error('Data provider missing');
	},
	refetchEntity: (_id: ModelPrimaryKey): void => {
		throw new Error('Data provider missing');
	},
	query: (_sort?: ModelSort<any>, _filter?: ModelFilter<any>, _limit?: ModelLimit): ReadonlyArray<any> => {
		throw new Error('Data provider missing');
	},
	queryCount: (_filter?: ModelFilter<any>): number => {
		throw new Error('Data provider missing');
	},
	hasResults: (_filter?: ModelFilter<any>): boolean => {
		throw new Error('Data provider missing');
	},
	queryEntity: (_id: ModelPrimaryKey): ReadonlyOptional<any> => {
		throw new Error('Data provider missing');
	},
	queryCreated: (): ReadonlyOptional<any> => {
		throw new Error('Data provider missing');
	},
	resolveFetchStatus: (): void => {
		throw new Error('Data provider missing');
	},
	resolveActionStatus: (): void => {
		throw new Error('Data provider missing');
	},
	mutate: (_id: ModelPrimaryKey, _mutation: Mutation<any>): void => {
		throw new Error('Data provider missing');
	},
	create: (_mutatable: Mutable<any>): void => {
		throw new Error('Data provider missing');
	},
	delete: (_id: ModelPrimaryKey): void => {
		throw new Error('Data provider missing');
	},
	useFetchStatusEffect: (_status: Array<FetchStatus>, _effect: () => void): void => {
		throw new Error('Data provider missing');
	},
	useFetchEntityStatusEffect: (_id: ModelPrimaryKey, _status: Array<FetchStatus>, _effect: () => void): void => {
		throw new Error('Data provider missing');
	},
	useActionStatusEffect: (_status: Array<CrudActionStatus>, _effect: () => void, _resolve = false): void => {
		throw new Error('Data provider missing');
	},
	resetStore: (): void => {
		throw new Error('Data provider missing');
	},
	storeSize: (): number => {
		throw new Error('Data provider missing');
	},
	storeEntryIds: (): ReadonlyArray<ModelPrimaryKey> => {
		throw new Error('Data provider missing');
	}
};

export const createCrudCollectionDataProviderValue = <T extends Model, RootStore extends Record<string, CrudCollectionStore<T>>>(
	storeKey: string,
	storeAccessors: CrudCollectionStoreAccessors<T, RootStore>,
	apiBaseUrl: string,
	jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> = async () => null
): CrudCollectionDataProviderValue<T> => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const dispatch = useDispatch();

	const dataProviderValue = {
		idle: (): boolean => {
			return dataProviderValue.queryFetchStatus() === FetchStatus.IDLE;
		},
		pending: (): boolean => {
			const fetchStatus = dataProviderValue.queryFetchStatus();
			return fetchStatusPendingGroup.includes(fetchStatus);
		},
		hasNextPage: (): boolean => {
			const currentPage = dataProviderValue.queryCurrentPage();
			const maxPages = dataProviderValue.queryMaxPages();
			return (currentPage ?? 1) < (maxPages ?? 1);
		},
		isPaginated: (): boolean => {
			const maxPages = dataProviderValue.queryMaxPages();
			return (maxPages ?? 1) > 1;
		},
		queryCurrentPage: (): Nullable<number> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<number>>(state => state[storeKey].currentPage);
		},
		queryMaxPages: (): Nullable<number> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<number>>(state => state[storeKey].maxPages);
		},
		isSorted: (): boolean => {
			const sortCriteria = dataProviderValue.querySortCriteria();
			return sortCriteria !== null;
		},
		querySortCriteria: (): Nullable<SortCriteria<T>> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<SortCriteria<T>>>(state => (state[storeKey].sortCriteria ?? null));
		},
		isFiltered: (): boolean => {
			const filterCriteria = dataProviderValue.queryFilterCriteria();
			return filterCriteria !== null;
		},
		queryFilterCriteria: (): Nullable<FilterCriteria<T>> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<FilterCriteria<T>>>(state => (state[storeKey].filterCriteria ?? null));
		},
		queryFetchStatus: (): FetchStatus => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, FetchStatus>(state => state[storeKey].fetchStatus);
		},
		queryFetchEntityStatus: (id: ModelPrimaryKey): FetchStatus => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, FetchStatus>(state => state[storeKey].fetchEntityStatus?.[id] ?? FetchStatus.IDLE);
		},
		queryFetchError: (): Nullable<AnyErrorObject> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<AnyErrorObject>>(state => state[storeKey].lastFetchError);
		},
		queryActionStatus: (): CrudActionStatus => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, CrudActionStatus>(state => state[storeKey].actionStatus);
		},
		queryActionError: (): Nullable<AnyErrorObject> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Nullable<AnyErrorObject>>(state => state[storeKey].lastActionError);
		},
		fetch: (sortCriteria?: SortCriteria<T>, filterCriteria?: FilterCriteria<T>, forceFetch?: boolean): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchAll({ apiBaseUrl, jsonWebTokenLoader, sortCriteria, filterCriteria, forceFetch }));
		},
		refetch: (): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.refetchAll({ apiBaseUrl, jsonWebTokenLoader }));
		},
		applySort: (sortCriteria: SortCriteria<T>, forceFetch?: boolean): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchAllSorted({ apiBaseUrl, jsonWebTokenLoader, sortCriteria, forceFetch }));
		},
		clearSort: (forceFetch?: boolean): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchAllSorted({ apiBaseUrl, jsonWebTokenLoader, sortCriteria: undefined, forceFetch }));
		},
		applyFilter: (filterCriteria: FilterCriteria<T>, forceFetch?: boolean): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchAllFiltered({ apiBaseUrl, jsonWebTokenLoader, filterCriteria, forceFetch }));
		},
		clearFilter: (forceFetch?: boolean): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchAllFiltered({ apiBaseUrl, jsonWebTokenLoader, filterCriteria: undefined, forceFetch }));
		},
		fetchNext: (): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchNext({ apiBaseUrl, jsonWebTokenLoader }));
		},
		fetchEntity: (id: ModelPrimaryKey, forceFetch?: boolean): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.fetchEntity({ apiBaseUrl, jsonWebTokenLoader, id, forceFetch }));
		},
		refetchEntity: (id: ModelPrimaryKey): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.refetchEntity({ apiBaseUrl, jsonWebTokenLoader, id }));
		},
		query: (sort?: ModelSort<T>, filter?: ModelFilter<T>, limit?: ModelLimit): ReadonlyArray<T> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, ReadonlyArray<T>>(storeAccessors.selectCollection(filter, sort, limit));
		},
		queryCount: (filter?: ModelFilter<T>): number => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, number>(storeAccessors.countCollection(filter));
		},
		hasResults: (filter?: ModelFilter<T>): boolean => {
			return dataProviderValue.queryCount(filter) > 0;
		},
		queryEntity: (id: ModelPrimaryKey): ReadonlyOptional<T> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, ReadonlyOptional<T>>(storeAccessors.selectEntity(id));
		},
		queryCreated: (): ReadonlyOptional<T> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, ReadonlyOptional<T>>(storeAccessors.selectCreated());
		},
		resolveFetchStatus: (): void => {
			dispatch(storeAccessors.resolveFetchStatus());
		},
		resolveActionStatus: (): void => {
			dispatch(storeAccessors.resolveActionStatus());
		},
		mutate: (id: ModelPrimaryKey, modelMutation: Mutation<T>): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.mutate({ apiBaseUrl, jsonWebTokenLoader, id, modelMutation }));
		},
		create: (mutableModel: Mutable<T>): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.create({ apiBaseUrl, jsonWebTokenLoader, mutableModel }));
		},
		delete: (id: ModelPrimaryKey): void => {
			// REMINDER: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
			//   Possible solution which is not applicable for the current distribution (store in the app context, dispatch in the library)
			//   can be found here https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(storeAccessors.delete({ apiBaseUrl, jsonWebTokenLoader, id }));
		},
		useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
			const fetchStatus = dataProviderValue.queryFetchStatus();
			useMemo(() => {
				if (status.includes(fetchStatus)) {
					effect();
				}
			}, [fetchStatus]);
		},
		useFetchEntityStatusEffect: (id: ModelPrimaryKey, status: Array<FetchStatus>, effect: () => void): void => {
			const fetchStatus = dataProviderValue.queryFetchEntityStatus(id);
			useMemo(() => {
				if (status.includes(fetchStatus)) {
					effect();
				}
			}, [fetchStatus]);
		},
		useActionStatusEffect: (status: Array<CrudActionStatus>, effect: () => void, resolve = false): void => {
			const actionStatus = dataProviderValue.queryActionStatus();
			useEffect(() => {
				if (status.includes(actionStatus)) {
					try {
						effect();
					} finally {
						if (resolve) {
							dataProviderValue.resolveActionStatus();
						}
					}
				}
			}, [actionStatus]);
		},
		resetStore: (): void => {
			dispatch(storeAccessors.resetStore());
		},
		storeSize: (): number => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, Readonly<number>>(storeAccessors.storeSize());
		},
		storeEntryIds: (): ReadonlyArray<ModelPrimaryKey> => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useSelector<RootStore, ReadonlyArray<ModelPrimaryKey>>(storeAccessors.storeEntryIds());
		}
	};

	return dataProviderValue;
};
