import { ReactNode } from 'react';

import { FormElements } from '../form-elements/FormElements';

import './FormSection.scss';

export type FormSectionProps = {
	children: ReactNode,
	heading?: string,
	description?: string
};

export function FormSection(props: FormSectionProps) {
	const { children, heading, description } = props;

	const renderHeaderHeading = () => {
		if (heading === undefined) {
			return;
		}
		return (
			<h1 className="form-section__header__heading">
				{heading}
			</h1>
		);
	};

	const renderHeaderDescription = () => {
		if (description === undefined) {
			return;
		}
		return (
			<p className="form-section__header__description">
				{description}
			</p>
		);
	};

	const renderHeader = () => {
		if (heading === undefined && description === undefined) {
			return;
		}
		return (
			<header className="form-section__header">
				{renderHeaderHeading()}
				{renderHeaderDescription()}
			</header>
		);
	};

	return (
		<section className="form-section">
			{renderHeader()}
			<FormElements>
				{children}
			</FormElements>
		</section>
	);
}
