import React, { ReactNode, useEffect, useRef } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import './OrderProgressIndicator.scss';

export type OrderProgressIndicatorProps = {
	currentStep?: number,
	maxSteps?: number
};

export function OrderProgressIndicator(props: OrderProgressIndicatorProps) {
	const { currentStep, maxSteps } = props;

	const l10n = useL10n();

	const canvasRef = useRef<HTMLCanvasElement>(null);

	const devicePixelRatio = window.devicePixelRatio;
	// The size the canvas should get rendered on screen
	const canvasSize = 72;
	// The size the canvas has virtually to draw on
	const drawableCanvasSize = Math.floor(canvasSize * devicePixelRatio);

	useEffect(() => {
		if (canvasRef.current !== null) {
			const canvasComputedStyle = window.getComputedStyle(canvasRef.current);
			const strokeColorProgress = canvasComputedStyle.stroke;
			const strokeColorTrack = canvasComputedStyle.borderColor;
			const strokeWidth = parseInt(canvasComputedStyle.strokeWidth);

			const context = canvasRef.current.getContext('2d');

			if (!context) {
				return;
			}

			const lineWidth = strokeWidth * devicePixelRatio;
			const radius = Math.floor((drawableCanvasSize - lineWidth) / 2);
			const centerOffset = drawableCanvasSize / 2;

			context.lineCap = 'round';

			// paint lower circle (track)
			context.beginPath();
			context.strokeStyle = strokeColorTrack;
			context.lineWidth = lineWidth;
			context.arc(centerOffset, centerOffset, radius, 0, 360);
			context.stroke();

			// paint upper circle (progress)
			if (currentStep !== undefined && maxSteps !== undefined) {
				const progress = (currentStep ?? 0) / maxSteps;
				const progressDegree = Math.floor(progress * 360);
				context.beginPath();
				context.strokeStyle = strokeColorProgress;
				context.lineWidth = lineWidth;
				context.arc(centerOffset, centerOffset, radius, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + progressDegree));
				context.stroke();
			}
		}
	}, [canvasRef, currentStep, maxSteps]);

	const renderLabel = () => {
		let label: ReactNode = null;
		if (maxSteps !== undefined) {
			const currentStepDisplay = currentStep ?? '?';
			label = (
				<>
					{currentStepDisplay}
					<span className="order-progress-indicator__circular__label__separator">
						{l10n.translate('omsCustomerApp.orderProgressIndicator.outOfSeparator')}
					</span>
					{maxSteps}
				</>
			);
		}
		return (
			<div className="order-progress-indicator__circular__label">
				{label}
			</div>
		);
	};

	const canvasStyle = {
		width: canvasSize,
		height: canvasSize
	};

	return (
		<div className="order-progress-indicator">
			<div className="order-progress-indicator__circular">
				{renderLabel()}
				<canvas
					style={canvasStyle}
					width={drawableCanvasSize}
					height={drawableCanvasSize}
					ref={canvasRef}
					className="order-progress-indicator__circular__canvas"
				/>
			</div>
		</div>
	);
}
