import { ProgressState } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { TaskType } from '@abb-emobility/usertask/domain-model';

export type CustomerOrderProgressStepModel = {
	status: ProgressState,
	current: boolean,
	taskId?: ModelPrimaryKey,
	taskType: TaskType
};

export type CustomerOrderProgressModel = {
	steps: Array<CustomerOrderProgressStepModel>
};

export class CustomerOrderProgressModelConverter extends ModelConverter<CustomerOrderProgressModel> {
}
