import { CustomerOrderDocumentModel } from '@abb-emobility/oms/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerOrderDocumentApiClient } from './CustomerOrderDocumentApiClient';
import { CustomerOrderDocumentApiClientMock } from './CustomerOrderDocumentApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerOrderDocumentApiClientInterface extends CrudApiClient<CustomerOrderDocumentModel> {
}

export class CustomerOrderDocumentApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerOrderDocumentApiClientInterface {
		if (process.env.NX_OMS_CUSTOMER_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new CustomerOrderDocumentApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerOrderDocumentApiClient().init(baseUrl, jsonWebToken);
	}

}
