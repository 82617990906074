import { CurrencyCode } from '@abb-emobility/shared/currency';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { OrderImportPaymentMode } from './OrderImportPaymentMode';

export type OrderImportPaymentModel = {
	mode: OrderImportPaymentMode,
	amount: number,
	currency: CurrencyCode,
	paymentReference: string,
	settlementReference: string
};

export class OrderImportPaymentModelConverter extends ModelConverter<OrderImportPaymentModel> {
}
