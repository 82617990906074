import React, { ReactNode } from 'react';

import { Optional } from '@abb-emobility/shared/util';

import { envContext, Environment, EnvironmentValue } from './EnvContext';

export type EnvProviderProps = {
	env: Environment,
	children: ReactNode
};

export const EnvProvider = (props: EnvProviderProps): React.JSX.Element => {
	const { env, children } = props;

	function getter<T = EnvironmentValue>(key: string): Optional<T> {
		return new Optional<T>(env?.[key] as unknown as T ?? null);
	}

	const environmentValue = {
		get: getter
	};

	return (
		<envContext.Provider value={environmentValue}>
			{children}
		</envContext.Provider>
	);

};
