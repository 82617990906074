import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Please select',
			pictureSelectPlaceholder: 'Select image or take a picture',
			pictureSelectTrigger: 'Select picture',
			pictureSelectHint: {
				default: 'Please make sure that there are no people in the picture.',
				examplePicture: 'Please refer to the sample image and make sure that there are no people in the picture.'
			},
			documentSelectPlaceholder: 'Please select a document',
			documentSelectTrigger: 'Select document',
			selectedFileTooLarge: 'The selected file is too large to be transferred. Please select a file with less than {{maxFileSize}}.',
			answerTypeNotFound: 'The answer type {{type}} is unknown.',
			previousSegmentButtonLabel: 'Back',
			nextSegmentButtonLabel: 'Next step',
			submitButtonLabel: 'Send information',
			validationErrors: {
				heading: 'There are some issues with your answers',
				message: 'Please check your answers to complete the questionnaire'
			}
		}
	}
};

export default literals;
