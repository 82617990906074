import { DateRangeDto, DateRange } from './DateRange';

export const isDateRange = (dateRange?: unknown): dateRange is DateRange => {
	if ((dateRange ?? null) === null) {
		return false;
	}
	return (dateRange as DateRange).discriminator === 'DateRange'
		&& (dateRange as DateRange).start !== undefined
		&& (dateRange as DateRange).end !== undefined;
};

export const isDateRangeDto = (dateRangeDto?: unknown): dateRangeDto is DateRangeDto => {
	if ((dateRangeDto ?? null) === null) {
		return false;
	}
	return (dateRangeDto as DateRangeDto).start !== undefined
		&& (dateRangeDto as DateRangeDto).end !== undefined;
};
