import { EnvContextValue } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { KeycloakApiClientFactory } from '@abb-emobility/shared/keycloak-integration';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import { AuthContextValue } from '../auth-provider/AuthContext';
import { JsonWebToken } from '../foundation/JsonWebToken';

let tokenRefreshPending = false;

export const createAccessTokenLoader = (auth: AuthContextValue, env: EnvContextValue): () => Promise<Nullable<JsonWebToken>> => {
	return async () => {
		if (auth.needsUpdate() && !tokenRefreshPending) {
			tokenRefreshPending = true;
			try {
				const oauthBaseUrl = new Optional(process.env['NX_KEYCLOAK_API_BASE_URL'])
					.getOrThrow(new AppError('Oauth base URL unavailable'));
				const oauthRealm = env.get<string>('oauthRealm').getOrThrow(new AppError('Oauth realm unavailable'));
				const oauthClientId = env.get<string>('oauthClientId').getOrThrow(new AppError('Oauth base URL unavailable'));

				const tokenRefreshResponseModel = await KeycloakApiClientFactory.create().refreshToken(
					oauthBaseUrl,
					oauthRealm,
					oauthClientId,
					auth.getToken().get()?.refreshToken ?? ''
				);
				const accessTokenValidTo = new Date();
				accessTokenValidTo.setSeconds(accessTokenValidTo.getSeconds() + tokenRefreshResponseModel.expires_in);
				const refreshTokenValidTo = new Date();
				refreshTokenValidTo.setSeconds(refreshTokenValidTo.getSeconds() + tokenRefreshResponseModel.refresh_expires_in);
				auth.update(tokenRefreshResponseModel.access_token, accessTokenValidTo, tokenRefreshResponseModel.refresh_token, refreshTokenValidTo);
			} finally {
				tokenRefreshPending = false;
			}
		}
		return auth.getToken().get();
	};
};
