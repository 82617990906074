import { Currency } from './Currency.types';
import { CurrencyCode } from './CurrencyCode';

export const currencyData: Array<Currency> = [
	{
		currency: 'Kyat',
		code: CurrencyCode.MMK,
		numeric: 104,
		fraction: 2
	},
	{
		currency: 'Burundi Franc',
		code: CurrencyCode.BIF,
		numeric: 108,
		symbol: 'BIF',
		fraction: 0
	},
	{
		currency: 'Riel',
		code: CurrencyCode.KHR,
		numeric: 116,
		symbol: '៛',
		fraction: 2
	},
	{
		currency: 'Canadian Dollar',
		code: CurrencyCode.CAD,
		numeric: 124,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Cape Verde Escudo',
		code: CurrencyCode.CVE,
		numeric: 132,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Cayman Islands Dollar',
		code: CurrencyCode.KYD,
		numeric: 136,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Sri Lanka Rupee',
		code: CurrencyCode.LKR,
		numeric: 144,
		symbol: '₨',
		fraction: 2
	},
	{
		currency: 'Chilean Peso',
		code: CurrencyCode.CLP,
		numeric: 152,
		symbol: '$',
		fraction: 0
	},
	{
		currency: 'Yuan Renminbi',
		code: CurrencyCode.CNY,
		numeric: 156,
		symbol: '¥',
		fraction: 2
	},
	{
		currency: 'Colombian Peso',
		code: CurrencyCode.COP,
		numeric: 170,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Comoro Franc',
		code: CurrencyCode.KMF,
		numeric: 174,
		fraction: 0
	},
	{
		currency: 'Costa Rican Colon',
		code: CurrencyCode.CRC,
		numeric: 188,
		symbol: '₡',
		fraction: 2
	},
	{
		currency: 'Croatian Kuna',
		code: CurrencyCode.HRK,
		numeric: 191,
		symbol: 'kn',
		fraction: 2
	},
	{
		currency: 'Cuban Peso',
		code: CurrencyCode.CUP,
		numeric: 192,
		symbol: '₱',
		fraction: 2
	},
	{
		currency: 'Cyprus Pound',
		code: CurrencyCode.CYP,
		numeric: 196,
		symbol: '€',
		fraction: 0
	},
	{
		currency: 'Czech Koruna',
		code: CurrencyCode.CZK,
		numeric: 203,
		symbol: 'Kč',
		fraction: 2
	},
	{
		currency: 'Danish Krone',
		code: CurrencyCode.DKK,
		numeric: 208,
		symbol: 'kr',
		fraction: 2
	},
	{
		currency: 'Dominican Peso',
		code: CurrencyCode.DOP,
		numeric: 214,
		symbol: 'RD$',
		fraction: 2
	},
	{
		currency: 'El Salvador Colon',
		code: CurrencyCode.SVC,
		numeric: 222,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Ethiopian Birr',
		code: CurrencyCode.ETB,
		numeric: 230,
		fraction: 2
	},
	{
		currency: 'Nakfa',
		code: CurrencyCode.ERN,
		numeric: 232,
		fraction: 2
	},
	{
		currency: 'Kroon',
		code: CurrencyCode.EEK,
		numeric: 233,
		fraction: 0
	},
	{
		currency: 'Falkland Islands Pound',
		code: CurrencyCode.FKP,
		numeric: 238,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Fiji Dollar',
		code: CurrencyCode.FJD,
		numeric: 242,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Djibouti Franc',
		code: CurrencyCode.DJF,
		numeric: 262,
		fraction: 0
	},
	{
		currency: 'Dalasi',
		code: CurrencyCode.GMD,
		numeric: 270,
		fraction: 2
	},
	{
		currency: 'Gibraltar Pound',
		code: CurrencyCode.GIP,
		numeric: 292,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Quetzal',
		code: CurrencyCode.GTQ,
		numeric: 320,
		symbol: 'Q',
		fraction: 2
	},
	{
		currency: 'Guinea Franc',
		code: CurrencyCode.GNF,
		numeric: 324,
		fraction: 0
	},
	{
		currency: 'Guyana Dollar',
		code: CurrencyCode.GYD,
		numeric: 328,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Gourde',
		code: CurrencyCode.HTG,
		numeric: 332,
		fraction: 2
	},
	{
		currency: 'Lempira',
		code: CurrencyCode.HNL,
		numeric: 340,
		symbol: 'L',
		fraction: 2
	},
	{
		currency: 'Hong Kong Dollar',
		code: CurrencyCode.HKD,
		numeric: 344,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Forint',
		code: CurrencyCode.HUF,
		numeric: 348,
		symbol: 'Ft',
		fraction: 2
	},
	{
		currency: 'Iceland Krona',
		code: CurrencyCode.ISK,
		numeric: 352,
		symbol: 'kr',
		fraction: 0
	},
	{
		currency: 'Indian Rupee',
		code: CurrencyCode.INR,
		numeric: 356,
		symbol: '₹',
		fraction: 2
	},
	{
		currency: 'Rupiah',
		code: CurrencyCode.IDR,
		numeric: 360,
		symbol: 'Rp',
		fraction: 2
	},
	{
		currency: 'Iranian Rial',
		code: CurrencyCode.IRR,
		numeric: 364,
		symbol: '﷼',
		fraction: 2
	},
	{
		currency: 'Iraqi Dinar',
		code: CurrencyCode.IQD,
		numeric: 368,
		fraction: 3
	},
	{
		currency: 'New Israeli Sheqel',
		code: CurrencyCode.ILS,
		numeric: 376,
		symbol: '₪',
		fraction: 2
	},
	{
		currency: 'Jamaican Dollar',
		code: CurrencyCode.JMD,
		numeric: 388,
		symbol: 'J$',
		fraction: 2
	},
	{
		currency: 'Yen',
		code: CurrencyCode.JPY,
		numeric: 392,
		symbol: '¥',
		fraction: 0
	},
	{
		currency: 'Tenge',
		code: CurrencyCode.KZT,
		numeric: 398,
		symbol: '₸',
		fraction: 2
	},
	{
		currency: 'Jordanian Dinar',
		code: CurrencyCode.JOD,
		numeric: 400,
		fraction: 3
	},
	{
		currency: 'Kenyan Shilling',
		code: CurrencyCode.KES,
		numeric: 404,
		fraction: 2
	},
	{
		currency: 'North Korean Won',
		code: CurrencyCode.KPW,
		numeric: 408,
		symbol: '₩',
		fraction: 2
	},
	{
		currency: 'Won',
		code: CurrencyCode.KRW,
		numeric: 410,
		symbol: '₩',
		fraction: 0
	},
	{
		currency: 'Kuwaiti Dinar',
		code: CurrencyCode.KWD,
		numeric: 414,
		fraction: 3
	},
	{
		currency: 'Som',
		code: CurrencyCode.KGS,
		numeric: 417,
		symbol: 'som',
		fraction: 2
	},
	{
		currency: 'Kip',
		code: CurrencyCode.LAK,
		numeric: 418,
		symbol: '₭',
		fraction: 2
	},
	{
		currency: 'Lebanese Pound',
		code: CurrencyCode.LBP,
		numeric: 422,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Loti',
		code: CurrencyCode.LSL,
		numeric: 426,
		fraction: 2
	},
	{
		currency: 'Latvian Lats',
		code: CurrencyCode.LVL,
		numeric: 428,
		fraction: 0
	},
	{
		currency: 'Liberian Dollar',
		code: CurrencyCode.LRD,
		numeric: 430,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Libyan Dinar',
		code: CurrencyCode.LYD,
		numeric: 434,
		fraction: 3
	},
	{
		currency: 'Lithuanian Litas',
		code: CurrencyCode.LTL,
		numeric: 440,
		symbol: 'Lt',
		fraction: 2
	},
	{
		currency: 'Pataca',
		code: CurrencyCode.MOP,
		numeric: 446,
		fraction: 2
	},
	{
		currency: 'Kwacha',
		code: CurrencyCode.MWK,
		numeric: 454,
		fraction: 2
	},
	{
		currency: 'Malaysian Ringgit',
		code: CurrencyCode.MYR,
		numeric: 458,
		symbol: 'RM',
		fraction: 2
	},
	{
		currency: 'Rufiyaa',
		code: CurrencyCode.MVR,
		numeric: 462,
		fraction: 2
	},
	{
		currency: 'Maltese Lira',
		code: CurrencyCode.MTL,
		numeric: 470,
		fraction: 0
	},
	{
		currency: 'Ouguiya',
		code: CurrencyCode.MRO,
		numeric: 478,
		fraction: 2
	},
	{
		currency: 'Mauritius Rupee',
		code: CurrencyCode.MUR,
		numeric: 480,
		symbol: '₨',
		fraction: 2
	},
	{
		currency: 'Mexican Peso ',
		code: CurrencyCode.MXN,
		numeric: 484,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Tugrik',
		code: CurrencyCode.MNT,
		numeric: 496,
		symbol: '₮',
		fraction: 2
	},
	{
		currency: 'Moldovan Leu',
		code: CurrencyCode.MDL,
		numeric: 498,
		fraction: 2
	},
	{
		currency: 'Moroccan Dirham',
		code: CurrencyCode.MAD,
		numeric: 504,
		fraction: 2
	},
	{
		currency: 'Rial Omani',
		code: CurrencyCode.OMR,
		numeric: 512,
		symbol: '﷼',
		fraction: 3
	},
	{
		currency: 'Namibia Dollar',
		code: CurrencyCode.NAD,
		numeric: 516,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Nepalese Rupee',
		code: CurrencyCode.NPR,
		numeric: 524,
		symbol: '₨',
		fraction: 2
	},
	{
		currency: 'Netherlands Antillian Guilder',
		code: CurrencyCode.ANG,
		numeric: 532,
		symbol: 'ƒ',
		fraction: 2
	},
	{
		currency: 'Aruban Guilder',
		code: CurrencyCode.AWG,
		numeric: 533,
		symbol: 'ƒ',
		fraction: 2
	},
	{
		currency: 'Vatu',
		code: CurrencyCode.VUV,
		numeric: 548,
		fraction: 0
	},
	{
		currency: 'New Zealand Dollar',
		code: CurrencyCode.NZD,
		numeric: 554,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Cordoba Oro',
		code: CurrencyCode.NIO,
		numeric: 558,
		symbol: 'C$',
		fraction: 2
	},
	{
		currency: 'Naira',
		code: CurrencyCode.NGN,
		numeric: 566,
		symbol: '₦',
		fraction: 2
	},
	{
		currency: 'Norwegian Krone',
		code: CurrencyCode.NOK,
		numeric: 578,
		symbol: 'kr',
		fraction: 2
	},
	{
		currency: 'Pakistan Rupee',
		code: CurrencyCode.PKR,
		numeric: 586,
		symbol: '₨',
		fraction: 2
	},
	{
		currency: 'Balboa',
		code: CurrencyCode.PAB,
		numeric: 590,
		symbol: 'B/.',
		fraction: 2
	},
	{
		currency: 'Kina',
		code: CurrencyCode.PGK,
		numeric: 598,
		symbol: 'K',
		fraction: 2
	},
	{
		currency: 'Guarani',
		code: CurrencyCode.PYG,
		numeric: 600,
		symbol: 'Gs',
		fraction: 0
	},
	{
		currency: 'Nuevo Sol',
		code: CurrencyCode.PEN,
		numeric: 604,
		symbol: 'S/',
		fraction: 2
	},
	{
		currency: 'Philippine Peso',
		code: CurrencyCode.PHP,
		numeric: 608,
		symbol: '₱',
		fraction: 2
	},
	{
		currency: 'Guinea-Bissau Peso',
		code: CurrencyCode.GWP,
		numeric: 624,
		fraction: 0
	},
	{
		currency: 'Qatari Rial',
		code: CurrencyCode.QAR,
		numeric: 634,
		symbol: '﷼',
		fraction: 2
	},
	{
		currency: 'Russian Ruble',
		code: CurrencyCode.RUB,
		numeric: 643,
		symbol: '₽',
		fraction: 2
	},
	{
		currency: 'Rwanda Franc',
		code: CurrencyCode.RWF,
		numeric: 646,
		symbol: 'R₣',
		fraction: 0
	},
	{
		currency: 'Saint Helena Pound',
		code: CurrencyCode.SHP,
		numeric: 654,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Dobra',
		code: CurrencyCode.STD,
		numeric: 678,
		fraction: 2
	},
	{
		currency: 'Saudi Riyal',
		code: CurrencyCode.SAR,
		numeric: 682,
		symbol: '﷼',
		fraction: 2
	},
	{
		currency: 'Seychelles Rupee',
		code: CurrencyCode.SCR,
		numeric: 690,
		symbol: '₨',
		fraction: 2
	},
	{
		currency: 'Leone',
		code: CurrencyCode.SLL,
		numeric: 694,
		symbol: 'Le',
		fraction: 2
	},
	{
		currency: 'Singapore Dollar',
		code: CurrencyCode.SGD,
		numeric: 702,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Slovak Koruna',
		code: CurrencyCode.SKK,
		numeric: 703,
		fraction: 0
	},
	{
		currency: 'Dong',
		code: CurrencyCode.VND,
		numeric: 704,
		symbol: '₫',
		fraction: 0
	},
	{
		currency: 'Somali Shilling',
		code: CurrencyCode.SOS,
		numeric: 706,
		symbol: 'S',
		fraction: 2
	},
	{
		currency: 'Rand',
		code: CurrencyCode.ZAR,
		numeric: 710,
		symbol: 'R',
		fraction: 2
	},
	{
		currency: 'Zimbabwe Dollar',
		code: CurrencyCode.ZWD,
		numeric: 716,
		fraction: 0
	},
	{
		currency: 'Lilangeni',
		code: CurrencyCode.SZL,
		numeric: 748,
		fraction: 2
	},
	{
		currency: 'Swedish Krona',
		code: CurrencyCode.SEK,
		numeric: 752,
		symbol: 'kr',
		fraction: 2
	},
	{
		currency: 'Swiss Franc',
		code: CurrencyCode.CHF,
		numeric: 756,
		symbol: 'CHF',
		fraction: 2
	},
	{
		currency: 'Syrian Pound',
		code: CurrencyCode.SYP,
		numeric: 760,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Baht',
		code: CurrencyCode.THB,
		numeric: 764,
		symbol: '฿',
		fraction: 2
	},
	{
		currency: 'Pa\'anga',
		code: CurrencyCode.TOP,
		numeric: 776,
		fraction: 2
	},
	{
		currency: 'Trinidad and Tobago Dollar',
		code: CurrencyCode.TTD,
		numeric: 780,
		symbol: 'TT$',
		fraction: 2
	},
	{
		currency: 'UAE Dirham',
		code: CurrencyCode.AED,
		numeric: 784,
		fraction: 2
	},
	{
		currency: 'Tunisian Dinar',
		code: CurrencyCode.TND,
		numeric: 788,
		fraction: 3
	},
	{
		currency: 'Manat',
		code: CurrencyCode.TMM,
		numeric: 795,
		fraction: 0
	},
	{
		currency: 'Uganda Shilling',
		code: CurrencyCode.UGX,
		numeric: 800,
		fraction: 0
	},
	{
		currency: 'Denar',
		code: CurrencyCode.MKD,
		numeric: 807,
		symbol: 'ден',
		fraction: 2
	},
	{
		currency: 'Egyptian Pound',
		code: CurrencyCode.EGP,
		numeric: 818,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Pound Sterling',
		code: CurrencyCode.GBP,
		numeric: 826,
		symbol: '£',
		fraction: 2
	},
	{
		currency: 'Tanzanian Shilling',
		code: CurrencyCode.TZS,
		numeric: 834,
		fraction: 2
	},
	{
		currency: 'US Dollar',
		code: CurrencyCode.USD,
		numeric: 840,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Peso Uruguayo ',
		code: CurrencyCode.UYU,
		numeric: 858,
		symbol: '$U',
		fraction: 2
	},
	{
		currency: 'Uzbekistan Sum',
		code: CurrencyCode.UZS,
		numeric: 860,
		symbol: 'som',
		fraction: 2
	},
	{
		currency: 'Tala',
		code: CurrencyCode.WST,
		numeric: 882,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Yemeni Rial',
		code: CurrencyCode.YER,
		numeric: 886,
		symbol: '﷼',
		fraction: 2
	},
	{
		currency: 'Kwacha',
		code: CurrencyCode.ZMK,
		numeric: 894,
		fraction: 0
	},
	{
		currency: 'New Taiwan Dollar',
		code: CurrencyCode.TWD,
		numeric: 901,
		symbol: 'NT$',
		fraction: 2
	},
	{
		currency: 'Ghana Cedi',
		code: CurrencyCode.GHS,
		numeric: 936,
		fraction: 2
	},
	{
		currency: 'Bolivar Fuerte',
		code: CurrencyCode.VEF,
		numeric: 937,
		symbol: 'Bs',
		fraction: 2
	},
	{
		currency: 'Sudanese Pound',
		code: CurrencyCode.SDG,
		numeric: 938,
		fraction: 2
	},
	{
		currency: 'Uruguay Peso en Unidades Indexadas',
		code: CurrencyCode.UYI,
		numeric: 940,
		fraction: 0
	},
	{
		currency: 'Serbian Dinar',
		code: CurrencyCode.RSD,
		numeric: 941,
		symbol: 'Дин.',
		fraction: 2
	},
	{
		currency: 'Metical',
		code: CurrencyCode.MZN,
		numeric: 943,
		symbol: 'MT',
		fraction: 2
	},
	{
		currency: 'Azerbaijanian Manat',
		code: CurrencyCode.AZN,
		numeric: 944,
		symbol: 'ман',
		fraction: 2
	},
	{
		currency: 'New Leu',
		code: CurrencyCode.RON,
		numeric: 946,
		symbol: 'lei',
		fraction: 2
	},
	{
		currency: 'WIR Euro',
		code: CurrencyCode.CHE,
		numeric: 947,
		fraction: 2
	},
	{
		currency: 'WIR Franc',
		code: CurrencyCode.CHW,
		numeric: 948,
		fraction: 2
	},
	{
		currency: 'New Turkish Lira',
		code: CurrencyCode.TRY,
		numeric: 949,
		symbol: '₺',
		fraction: 2
	},
	{
		currency: 'CFA Franc BEAC',
		code: CurrencyCode.XAF,
		numeric: 950,
		symbol: '₣',
		fraction: 0
	},
	{
		currency: 'East Caribbean Dollar',
		code: CurrencyCode.XCD,
		numeric: 951,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'CFA Franc BCEAO †',
		code: CurrencyCode.XOF,
		numeric: 952,
		symbol: '₣',
		fraction: 0
	},
	{
		currency: 'CFP Franc',
		code: CurrencyCode.XPF,
		numeric: 953,
		fraction: 0
	},
	{
		currency: 'Bond Markets Units European Composite Unit (EURCO)',
		code: CurrencyCode.XBA,
		numeric: 955,
		fraction: 0
	},
	{
		currency: 'European Monetary Unit (E.M.U.-6)',
		code: CurrencyCode.XBB,
		numeric: 956,
		fraction: 0
	},
	{
		currency: 'European Unit of Account 9(E.U.A.-9)',
		code: CurrencyCode.XBC,
		numeric: 957,
		fraction: 0
	},
	{
		currency: 'European Unit of Account 17(E.U.A.-17)',
		code: CurrencyCode.XBD,
		numeric: 958,
		fraction: 0
	},
	{
		currency: 'Gold',
		code: CurrencyCode.XAU,
		numeric: 959,
		fraction: 0
	},
	{
		currency: 'SDR',
		code: CurrencyCode.XDR,
		numeric: 960,
		fraction: 0
	},
	{
		currency: 'Silver',
		code: CurrencyCode.XAG,
		numeric: 961,
		fraction: 0
	},
	{
		currency: 'Platinum',
		code: CurrencyCode.XPT,
		numeric: 962,
		fraction: 0
	},
	{
		currency: 'Codes specifically reserved for testing purposes',
		code: CurrencyCode.XTS,
		numeric: 963,
		fraction: 0
	},
	{
		currency: 'Palladium',
		code: CurrencyCode.XPD,
		numeric: 964,
		fraction: 0
	},
	{
		currency: 'Surinam Dollar',
		code: CurrencyCode.SRD,
		numeric: 968,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Malagasy Ariary',
		code: CurrencyCode.MGA,
		numeric: 969,
		fraction: 2
	},
	{
		currency: 'Unidad de Valor Real',
		code: CurrencyCode.COU,
		numeric: 970,
		fraction: 2
	},
	{
		currency: 'Afghani',
		code: CurrencyCode.AFN,
		numeric: 971,
		symbol: '؋',
		fraction: 2
	},
	{
		currency: 'Somoni',
		code: CurrencyCode.TJS,
		numeric: 972,
		fraction: 2
	},
	{
		currency: 'Kwanza',
		code: CurrencyCode.AOA,
		numeric: 973,
		symbol: 'Kz',
		fraction: 2
	},
	{
		currency: 'Belarussian Ruble',
		code: CurrencyCode.BYR,
		numeric: 974,
		symbol: 'p.',
		fraction: 0
	},
	{
		currency: 'Bulgarian Lev',
		code: CurrencyCode.BGN,
		numeric: 975,
		symbol: 'лв',
		fraction: 2
	},
	{
		currency: 'Franc Congolais',
		code: CurrencyCode.CDF,
		numeric: 976,
		fraction: 2
	},
	{
		currency: 'Convertible Marks',
		code: CurrencyCode.BAM,
		numeric: 977,
		symbol: 'KM',
		fraction: 2
	},
	{
		currency: 'Euro',
		code: CurrencyCode.EUR,
		numeric: 978,
		symbol: '€',
		fraction: 2
	},
	{
		currency: 'Mexican Unidad de Inversion (UDI)',
		code: CurrencyCode.MXV,
		numeric: 979,
		fraction: 2
	},
	{
		currency: 'Hryvnia',
		code: CurrencyCode.UAH,
		numeric: 980,
		symbol: '₴',
		fraction: 2
	},
	{
		currency: 'Lari',
		code: CurrencyCode.GEL,
		numeric: 981,
		fraction: 2
	},
	{
		currency: 'Mvdol',
		code: CurrencyCode.BOV,
		numeric: 984,
		symbol: '$b',
		fraction: 2
	},
	{
		currency: 'Zloty',
		code: CurrencyCode.PLN,
		numeric: 985,
		symbol: 'zł',
		fraction: 2
	},
	{
		currency: 'Brazilian Real',
		code: CurrencyCode.BRL,
		numeric: 986,
		symbol: 'R$',
		fraction: 2
	},
	{
		currency: 'Unidades de fomento',
		code: CurrencyCode.CLF,
		numeric: 990,
		fraction: 4
	},
	{
		currency: 'US Dollar (Next day)',
		code: CurrencyCode.USN,
		numeric: 997,
		fraction: 2
	},
	{
		currency: 'US Dollar (Same day)',
		code: CurrencyCode.USS,
		numeric: 998,
		fraction: 0
	},
	{
		currency: 'The codes assigned for transactions where no currency is involved are:',
		code: CurrencyCode.XXX,
		numeric: 999,
		fraction: 0
	},
	{
		currency: 'Lek',
		code: CurrencyCode.ALL,
		numeric: 8,
		symbol: 'Lek',
		fraction: 2
	},
	{
		currency: 'Algerian Dinar',
		code: CurrencyCode.DZD,
		numeric: 12,
		symbol: 'د.ج',
		fraction: 2
	},
	{
		currency: 'Argentine Peso',
		code: CurrencyCode.ARS,
		numeric: 32,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Armenian Dram',
		code: CurrencyCode.AMD,
		numeric: 51,
		fraction: 2
	},
	{
		currency: 'Australian Dollar',
		code: CurrencyCode.AUD,
		numeric: 36,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Bahamian Dollar',
		code: CurrencyCode.BSD,
		numeric: 44,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Bahraini Dinar',
		code: CurrencyCode.BHD,
		numeric: 48,
		symbol: 'BD',
		fraction: 3
	},
	{
		currency: 'Taka',
		code: CurrencyCode.BDT,
		numeric: 50,
		symbol: '৳',
		fraction: 2
	},
	{
		currency: 'Barbados Dollar',
		code: CurrencyCode.BBD,
		numeric: 52,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Belize Dollar',
		code: CurrencyCode.BZD,
		numeric: 84,
		symbol: 'BZ$',
		fraction: 2
	},
	{
		currency: 'Bermudian Dollar',
		code: CurrencyCode.BMD,
		numeric: 60,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Ngultrum',
		code: CurrencyCode.BTN,
		numeric: 64,
		symbol: 'Nu',
		fraction: 2
	},
	{
		currency: 'Boliviano',
		code: CurrencyCode.BOB,
		numeric: 68,
		symbol: '$b',
		fraction: 2
	},
	{
		currency: 'Pula',
		code: CurrencyCode.BWP,
		numeric: 72,
		symbol: 'P',
		fraction: 2
	},
	{
		currency: 'Brunei Dollar',
		code: CurrencyCode.BND,
		numeric: 96,
		symbol: '$',
		fraction: 2
	},
	{
		currency: 'Solomon Islands Dollar',
		code: CurrencyCode.SBD,
		numeric: 90,
		symbol: '$',
		fraction: 2
	}
];
