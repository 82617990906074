import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerApp: {
		abbPspIntegration: {
			formData: {
				titleCheckout: 'Centralina a parete ABB',
				labelCancel: 'Annulla',
				titlePaymentData: 'Pagamento',
				labelAmount: 'Importo delle lavorazioni di installazione e dei materiali aggiuntivi',
				labelDate: 'Data',
				labelMerchant: 'Commerciante',
				labelIban: 'Il tuo IBAN'
			}
		},
		application: {
			pageTitle: 'Servizio di installazione centralina a parete di ABB',
			caption: 'ABB e-Mobility B.V.'
		},
		pageTitle: {
			order: 'Ordine {{marketplaceId}}',
			task: 'Ordine {{marketplaceId}} - {{taskType}}',
			documents: 'Documenti',
			payment: 'Pagamento',
			notFound: 'Non trovato',
			static: {
				terms: 'Termini e condizioni generali',
				privacy: 'Privacy',
				imprint: 'Marchio'
			}
		},
		footer: {
			termsLabel: 'Termini e condizioni generali',
			privacyLabel: 'Privacy',
			imprintLabel: 'Marchio'
		},
		otpAuthEmitter: {
			otpInputHeading: 'Inserisci il codice',
			otpInputMessage: 'Abbiamo inviato un codice di 6 cifre al tuo cellulare.'
		},
		otpAuthForm: {
			resendOtpButtonLabel: 'Invia di nuovo',
			otpInputLabel: 'Inserisci il codice',
			otpInputPlaceholder: 'Codice di autenticazione',
			otpSubmitButtonLabel: 'Continua'
		},
		error: {
			otpAuthInitFailed: 'Autenticazione non riuscita',
			otpAuthReceiveTokenFailed: 'Codice non valido',
			taskTypeNotFound: 'Il tipo di attività richiesta {{TaskType}} è sconosciuto.',
			taskNotFound: 'L\'attività richiesta non è stata trovata',
			orderNotFound: 'L\'ordine richiesto non è stato trovato',
			documentNotFound: 'Il documento richiesto non è stato trovato',
			validation: {
				heading: 'Dati non validi',
				message: 'I dati inviati non sono validi.'
			},
			generic: {
				heading: 'Si è verificato un errore',
				message: 'Si è verificato un errore che non è stato possibile recuperare.',
				resolveAction: {
					label: 'Ricarica'
				}
			},
			notFound: {
				heading: 'Non trovato',
				message: 'La risorsa richiesta non è stata trovata'
			},
			taskCompleteMessage: 'Si è verificato un errore durante il completamento dell\'attività.'
		},
		customerServiceDialogue: {
			dialogueTriggerLabel: 'Ho bisogno di aiuto',
			dialogueDismissLabel: 'Annulla',
			dialogueCaption: 'Servizio clienti',
			phone: {
				heading: 'Puoi contattarci telefonicamente in qualsiasi momento'
			},
			mail: {
				heading: 'Oppure inviaci un\'e-mail',
				subject: 'Il mio ordine della centralina a parete #{marketplaceId}}',
				subjectStatic: 'Il mio ordine della centralina a parete'
			},
			infoPage: {
				heading: 'Oppure potete visitare la nostra offerta informativa',
				label: 'Risposte alle domande più frequenti'
			}
		},
		orderProgress: {
			undefinedCurrentStepLabel: 'Sconosciuto',
			allDoneStepLabel: 'Fatto',
			upcomingLabelPrefix: 'Avanti: '
		},
		orderProgressIndicator: {
			outOfSeparator: 'di'
		},
		payment: {
			success: {
				heading: 'Il pagamento è andato a buon fine.',
				message: 'Ora è possibile procedere all\'installazione della centralina a parete. Il nostro partner di installazione ti contatterà a breve per organizzare un appuntamento per l\'installazione.\n\nGrazie per la fiducia!'
			},
			failed: {
				heading: 'Il pagamento non è andato a buon fine!',
				retryLabel: 'Riprova'
			}
		},
		task: {
			displayName: {
				customerAnswerBasicQuestions: 'Controlli di installazione',
				customerProvidePreCheckPreferences: 'Indica il metodo di contatto preferito',
				customerHandleUnfeasibleInstallation: 'Installazione non realizzabile',
				customerHandleGridOperatorRejection: 'Rifiutato dal gestore di rete',
				customerAcceptQuotation: 'Offerta di lavorazioni supplementari',
				customerPerformPayment: 'Pagamento',
				customerPayment: 'Il tuo pagamento',
				customerApproveInstallation: 'Conferma dell\'installazione',
				installationPartnerEvaluateBasicAnswers: 'Controlli di installazione',
				installationPartnerExecuteRemotePreCheck: 'Verifica finale',
				installationPartnerRegisterAtGridOperator: 'Registrazione gestore di rete',
				installationPartnerRevokeGridOperatorRegistration: 'Revoca gestore di rete',
				installationPartnerProvideGridOperatorDecision: 'Decisione gestore di rete',
				installationPartnerProvideQuotation: 'Preventivazione',
				installationPartnerArrangeAppointment: 'Fissa appuntamento',
				installationPartnerCompleteInstallation: 'Esecuzione dell\'installazione'
			},
			shortName: {
				customerAnswerBasicQuestions: 'Controlli di installazione',
				customerProvidePreCheckPreferences: 'Metodo di contatto preferito',
				customerHandleUnfeasibleInstallation: 'Installazione non realizzabile',
				customerHandleGridOperatorRejection: 'Rifiutato dal gestore di rete',
				customerAcceptQuotation: 'Offerta di lavorazioni supplementari',
				customerPerformPayment: 'Pagamento',
				customerPayment: 'Pagamento',
				customerApproveInstallation: 'Conferma dell\'installazione',
				installationPartnerEvaluateBasicAnswers: 'Controlli di installazione',
				installationPartnerExecuteRemotePreCheck: 'Verifica finale',
				installationPartnerRegisterAtGridOperator: 'Registrazione gestore di rete',
				installationPartnerRevokeGridOperatorRegistration: 'Revoca gestore di rete',
				installationPartnerProvideGridOperatorDecision: 'Decisione gestore di rete',
				installationPartnerProvideQuotation: 'Preventivazione',
				installationPartnerArrangeAppointment: 'Fissa appuntamento',
				installationPartnerCompleteInstallation: 'Esecuzione dell\'installazione'
			},
			basicQuestions: {
				questionnaire: {
					consent: {
						description: 'Il conferimento della procura ha lo scopo di consentirci di registrare l\'allacciamento alla rete con il tuo consenso.',
						powerOfAttorney: {
							heading: 'Conferimento della procura per la registrazione dei consumatori presso il gestore di rete',
							description: 'Comunicaci il tuo gestore di rete per la corretta registrazione della centralina a parete.',
							gridOperator: {
								question: 'Gestore di rete o fornitore di energia'
							},
							powerOfAttorney: {
								question: 'Autorizzo ABB e il relativo partner di installazione a registrare la centralina a parete presso il gestore di rete. Se l\'installazione non viene effettuata, la procura decade automaticamente.'
							}
						},
						marketingConsent: {
							heading: 'Consenso alla comunicazione con il partner di installazione',
							installationPartnerMarketingConsent: {
								question: 'Con la presente autorizzo ABB e il relativo partner di installazione a contattarmi per scopi di marketing.'
							}
						},
						surveyConsent: {
							heading: 'Condividi la tua opinione con noi.',
							description: 'Dopo aver completato il tuo ordine, partecipa a un breve sondaggio per aiutarci a migliorare i nostri prodotti e servizi.',
							surveyConsent: {
								question: 'Con la presente autorizzo ABB a invitarmi a partecipare a un sondaggio.'
							}
						}
					}
				},
				completionSuccess: {
					heading: 'Grazie per il tuo tempo!',
					message: 'A breve riceverai un\'e-mail di conferma. Stiamo verificando i tuoi dati e ti contatteremo per concordare i passi successivi.'
				}
			},
			preCheckPreferences: {
				previousStepButtonLabel: 'Indietro',
				nextStepButtonLabel: 'Passo successivo',
				submitButtonLabel: 'Invia informazioni',
				channelSelection: {
					heading: 'Colloquio personale con il nostro team di assistenza',
					message: 'Affinché l\'installazione della tua nuova centralina a parete avvenga senza intoppi, ti preghiamo di comunicarci le tue preferenze per una discussione personale con il nostro team di assistenza.\nLe fasi successive verranno concordate in base alle informazioni già fornite.',
					subheading: 'Come possiamo contattarti?',
					explanation: 'Esistono vari metodi di contatto.'
				},
				preferredTimesOfDay: {
					subHeading: 'Troviamo un momento per una videochiamata. Avverrà tramite {{channelName}}.',
					timeOfDay: {
						morning: 'Mattina',
						noon: 'Ora di pranzo',
						afternoon: 'Pomeriggio',
						evening: 'Sera'
					}
				},
				completionSuccess: {
					heading: 'È fantastico che tu abbia tempo per noi!',
					message: 'Ti contatteremo in base alle disponibilità che hai selezionato.'
				}
			},
			preCheckInstallationUnfeasible: {
				caption: 'Ci dispiace molto ma',
				message: 'l\'installazione da parte del nostro partner di installazione non è attualmente possibile. Il motivo è:\n\n{{unfeasibleReasong}}\n\nSiamo pertanto costretti a chiederti di annullare l\'ordine.',
				submitButtonLabel: 'Invia informazioni',
				unfeasibleReason: {
					noPotentialRailAvailable: 'Non è disponibile una guida potenziale, necessaria per l\'installazione della protezione da sovratensioni.',
					overvoltageProtectionUnfeasible: 'Non è possibile installare una protezione da sovratensione.',
					spaceForCableOutletMissing: 'Mancanza di spazio sufficiente per una necessaria presa supplementare per i cavi.',
					houseConnectionUndersized: 'L\'allacciamento alla casa è talmente sottodimensionato da impossibilitare l\'installazione della centralina a parete.',
					noThreePhaseAvailable: 'L\'impianto elettrico esistente non fornisce la necessaria alimentazione trifase.',
					outdatedMeterCabinet: 'Il quadro elettrico non soddisfa i requisiti per la messa in funzione della centralina a parete.',
					notTabCompliant: 'L\'impianto elettrico non è conforme alle condizioni tecniche di collegamento applicabili.',
					other: 'Impossibile eseguire l\'installazione a causa di motivi tecnici che non possono essere eliminati in corso d\'opera.'
				},
				completionCancelInstallationSuccess: {
					heading: 'L\'installazione è stata annullata con successo.',
					message: 'Abbiamo ricevuto l\'annullamento. A breve riceverai un\'e-mail di conferma.'
				},
				completionCancelOrderSuccess: {
					heading: 'L\'ordine è stato annullato con successo.',
					message: 'Abbiamo ricevuto l\'annullamento. A breve riceverai un\'e-mail di conferma.'
				}
			},
			preCheckRejectedByGridOperator: {
				caption: 'Siamo spiacenti, ma il tuo gestore di rete ha rifiutato la messa in funzione della centralina a parete',
				message: 'Sfortunatamente, l\'alimentazione della sede di installazione non è sufficiente per il funzionamento della centralina a parete. È possibile ridurre la potenza della centralina a parete o annullare l\'installazione.',
				submitButtonLabel: 'Invia informazioni',
				solutionDecreaseWallboxPower: {
					heading: 'Riduzione della potenza della centralina a parete',
					description: 'La potenza della centralina a parete verrà ridotta a una potenza adeguata all\'alimentazione. La riduzione del consumo energetico avrà un impatto sull\'efficienza di ricarica.'
				},
				solutionCancelInstallation: {
					heading: 'Annulla installazione',
					description: 'L\'installazione da parte del nostro partner di installazione è stata annullata. La centralina a parete ti sarà inviata direttamente. Le spese di installazione verranno rimborsate nei prossimi giorni.'
				},
				solutionCancelOrder: {
					heading: 'Annulla installazione e centralina a parete',
					description: 'L\'intero ordine verrà annullato e i costi verranno rimborsati nei prossimi giorni.'
				},
				completionDecreaseWallboxPowerSuccess: {
					heading: 'Riduzione della potenza della centralina a parete',
					message: 'Con la presente ABB conferma l\'installazione della tua centralina a parete in base alla riduzione di potenza conforme ai requisiti dell\'operatore di rete.\n\nLe fasi successive verranno comunicate quanto prima.'
				},
				completionCancelInstallationSuccess: {
					heading: 'L\'installazione è stata annullata con successo.',
					message: 'Abbiamo ricevuto l\'annullamento. A breve riceverai un\'e-mail di conferma.'
				},
				completionCancelOrderSuccess: {
					heading: 'L\'ordine è stato annullato con successo.',
					message: 'Abbiamo ricevuto l\'annullamento. A breve riceverai un\'e-mail di conferma.'
				}
			},
			installationApproval: {
				submitButtonLabel: 'Invia informazioni',
				message: 'Conferma il completamento dell\'installazione della centralina a parete.',
				approval: {
					caption: 'Conferma',
					label: 'Con la presente confermo l\'avvenuta installazione della centralina a parete, nonché la sua messa in funzione e la sua operatività.'
				},
				completionSuccess: {
					heading: 'Grazie!',
					message: 'L\'installazione della centralina a parete è stata completata. A breve riceverai tutta la documentazione importante.\n\nGrazie per la fiducia!'
				}
			},
			quotationAcceptance: {
				caption: 'La tua offerta individuale',
				message: 'I seguenti pacchetti di installazione sono servizi aggiuntivi che vengono fatturati separatamente. Una volta accettata l\'offerta, verrai reindirizzato al nostro fornitore di servizi di pagamento, Deutsche Bank, in modo che l\'ordine possa essere elaborato per nostro conto.\n\nL\'accettazione di questa offerta non comporta ulteriori spese e sarà possibile effettuare l\'installazione.\n\nIl nostro partner di installazione ti contatterà per organizzare un appuntamento per l\'installazione.',
				submitButtonLabel: 'Accetta l\'offerta soggetta a pagamento',
				cancelButtonLabel: 'Annulla',
				cancellationCaption: 'Purtroppo non siamo in grado di farti una buona offerta',
				cancellationMessage: 'Indica in che modo desideri annullare l\'ordine.',
				submitCancellationButtonLabel: 'Invia informazioni',
				recoverCancellationButtonLabel: 'Indietro',
				conditions: {
					caption: 'Condizioni dell\'offerta',
					message: 'L\'offerta è valida per tutto il periodo di applicazione delle seguenti condizioni in base alle informazioni fornite.'
				},
				acceptance: {
					terms: 'i Termini e condizioni generali',
					label: 'Con la presente accetto {{terminiLink}}.'
				},
				completionSuccess: {
					heading: 'Il tuo ordine è andato a buon fine.',
					message: 'Hai accettato la nostra offerta e verrai reindirizzato al nostro fornitore di servizi di pagamento.'
				},
				completionCancelInstallationSuccess: {
					heading: 'L\'installazione è stata annullata con successo.',
					message: 'Abbiamo ricevuto l\'annullamento. A breve riceverai un\'e-mail di conferma.'
				},
				completionCancelOrderSuccess: {
					heading: 'L\'ordine è stato annullato con successo.',
					message: 'Abbiamo ricevuto l\'annullamento. A breve riceverai un\'e-mail di conferma.'
				}
			},
			payment: {
				heading: 'Il tuo pagamento',
				message: 'Verrai reindirizzato al nostro fornitore di servizi di pagamento.'
			}
		},
		documents: {
			heading: 'Documentazione dell\'ordine',
			introduction: 'Riepilogo della documentazione'
		},
		order: {
			heading: 'Il tuo ordine',
			cancelledHint: 'L\'ordine è stato annullato con successo.',
			doneHint: 'L\'ordine è stato completato con successo.',
			introduction: 'Tutte le informazioni relative al tuo ordine Centralina a parete sono disponibili su {{marketplaceName}} a partire da {{orderDate}} con il codice ordine {{marketplaceOrderId}}.',
			openTask: {
				buttonLabel: 'Finisci ora'
			},
			information: {
				heading: {
					orderItems: 'Ordine',
					quotation: 'Offerta per servizi aggiuntivi',
					appointment: 'Appuntamento per l\'installazione',
					shipping: 'Stato della spedizione'
				},
				orderItems: {
					position: 'Posizione',
					description: 'Descrizione',
					wallboxSerialNumbner: 'Serie centralina a parete.',
					totalGrossPrice: 'Prezzo totale lordo',
					cancellationHint: 'Annullato il {{cancellationDate}}'
				},
				quotation: {
					cancellationHint: 'L\'installazione è stata annullata il {{cancellationDate}}.'
				},
				appointment: {
					heading: 'Installazione'
				},
				shipping: {
					heading: 'Spedito da {{carrier}}',
					trackingNumberLabel: 'Numero di riferimento',
					shippingAddressLabel: 'Indirizzo di spedizione',
					trackingLinkLabel: 'Tracciamento della spedizione',
					itemsLabel: 'Articoli di spedizione',
					productCodeLabel: 'Codice prodotto'
				}
			}
		},
		cancellationDialogue: {
			cancelInstallation: {
				heading: 'Annulla installazione',
				description: 'L\'installazione da parte del nostro partner di installazione è stata annullata. La centralina a parete ti sarà inviata direttamente. Le spese di installazione verranno rimborsate nei prossimi giorni.'
			},
			cancelOrder: {
				heading: 'Annulla installazione e centralina a parete',
				description: 'L\'intero ordine verrà annullato e i costi verranno rimborsati nei prossimi giorni.'
			}
		}
	}
};

export default literals;
