import { ReactNode, useState } from 'react';

import {
	FormSection,
	FormSegement,
	InputRadio,
	Separator
} from '@abb-emobility/oms/customer-ui-primitive';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Nullable } from '@abb-emobility/shared/util';

export type CancellationDialogueSelection = 'CANCEL_INSTALLATION' | 'CANCEL_ORDER';

export type CancellationDialogueProps = {
	heading: string,
	description: string,
	selected?: CancellationDialogueSelection,
	onSelect?: (selected: Nullable<CancellationDialogueSelection>) => void,
	installationCancellable: boolean
};

export function CancellationDialogue(props: CancellationDialogueProps) {

	const { heading, description, onSelect, installationCancellable } = props;
	let { selected = null } = props;

	if (!installationCancellable && props.selected === 'CANCEL_INSTALLATION') {
		selected = 'CANCEL_ORDER';
	}

	const l10n = useL10n();
	const [userSelection, setUserSelection] = useState<Nullable<CancellationDialogueSelection>>(selected);

	const handleCancelInstallationChange = (checked: boolean): void => {
		const selection = checked ? 'CANCEL_INSTALLATION' : null;
		setUserSelection(selection);
		if (onSelect !== undefined) {
			onSelect(selection);
		}
	};

	const handleCancelOrderChange = (checked: boolean): void => {
		const selection = checked ? 'CANCEL_ORDER' : null;
		setUserSelection(selection);
		if (onSelect !== undefined) {
			onSelect(selection);
		}
	};

	const renderCancelInstallationSelection = (): ReactNode => {
		if (!installationCancellable) {
			return null;
		}
		return (
			<>
				<FormSegement>
					<FormSection>
						<InputRadio
							name="cancellationType"
							heading={l10n.translate('omsCustomerApp.cancellationDialogue.cancelInstallation.heading')}
							label={l10n.translate('omsCustomerApp.cancellationDialogue.cancelInstallation.description')}
							defaultChecked={userSelection === 'CANCEL_INSTALLATION'}
							onChange={handleCancelInstallationChange}
						/>
					</FormSection>
				</FormSegement>
				<Separator />
			</>
		);
	};

	return (
		<>
			<FormSegement
				heading={heading}
				description={description}
			/>
			<Separator />
			{renderCancelInstallationSelection()}
			<FormSegement>
				<FormSection>
					<InputRadio
						name="cancellationType"
						heading={l10n.translate('omsCustomerApp.cancellationDialogue.cancelOrder.heading')}
						label={l10n.translate('omsCustomerApp.cancellationDialogue.cancelOrder.description')}
						defaultChecked={userSelection === 'CANCEL_ORDER'}
						onChange={handleCancelOrderChange}
					/>
				</FormSection>
			</FormSegement>
		</>
	);

}
