export const scrollWindowToTop = (behavior: ScrollBehavior = 'smooth'): void => {
	requestAnimationFrame(() => {
		window.scrollTo({ top: 0, behavior });
	});
};

export const scrollElementIntoView = (element: HTMLElement, options?: ScrollIntoViewOptions): void => {
	const behavior = options?.behavior ?? 'smooth';
	requestAnimationFrame(() => {
		element.scrollIntoView({ ...options, behavior });
	});
};
