import { Optional, Probably } from '@abb-emobility/shared/util';

import { DateRangeDto, DateRange } from './DateRange';
import { isDateRange } from './DateRange.guards';
import { TimestampDto, Timestamp } from './Timestamp';
import { createTimestampDtoFromTimestamp, createTimestampFromTimestampDto, createTimestampFromDate } from './Timestamp.util';

export const createDateRangeFromTimestampDtos = (start: TimestampDto, end: TimestampDto): DateRange => {
	return {
		discriminator: 'DateRange',
		start: createTimestampFromTimestampDto(start).getOrThrow(new Error('Expected date string value. Got ' + start)),
		end: createTimestampFromTimestampDto(end).getOrThrow(new Error('Expected date string value. Got ' + end))
	};
};

export const createDateRangeFromTimestamps = (start: Timestamp, end: Timestamp): DateRange => {
	return {
		discriminator: 'DateRange',
		start,
		end
	};
};

export const createDateRangeFromDates = (start: Date, end: Date): DateRange => {
	return {
		discriminator: 'DateRange',
		start: createTimestampFromDate(start),
		end: createTimestampFromDate(end)
	};
};

export const createDateRangeDtoFromDates = (start: Date, end: Date): Optional<DateRangeDto> => {
	return createDateRangeDtoFromDateRange(createDateRangeFromDates(start, end));
};

export const createDateRangeDtoFromDateRange = (dateRange: Probably<DateRange>): Optional<DateRangeDto> => {
	let dateRangeDto = null;
	if (isDateRange(dateRange)) {
		dateRangeDto = {
			start: createTimestampDtoFromTimestamp(dateRange.start).getOrThrow(new Error('Expected timestamp value. Got ' + dateRange.start)),
			end: createTimestampDtoFromTimestamp(dateRange.end).getOrThrow(new Error('Expected timestamp value. Got ' + dateRange.end))
		};
	}
	return new Optional<DateRangeDto>(dateRangeDto);
};

export const createDateRangeFromDateRangeDto = (dateRangeDto: DateRangeDto): DateRange => {
	return createDateRangeFromTimestampDtos(dateRangeDto.start, dateRangeDto.end);
};
