import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { InstallationPartnerOrderInformationFoundationModel } from './foundation/InstallationPartnerOrderInformationFoundationModel';
import { InstallationPartnerOrderInformationType } from './foundation/InstallationPartnerOrderInformationType';

export type AnyInstallationPartnerOrderInformationCollectionItemModel = {
	type: InstallationPartnerOrderInformationType
} & Omit<InstallationPartnerOrderInformationFoundationModel, 'comment'>;

export class AnyInstallationPartnerOrderInformationCollectionItemModelConverter extends ModelConverter<AnyInstallationPartnerOrderInformationCollectionItemModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyInstallationPartnerOrderInformationCollectionItemModel> | ArrayOfTimestampFieldNames<AnyInstallationPartnerOrderInformationCollectionItemModel>> {
		return ['createdAt'];
	}

}
