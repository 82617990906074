import React, { useEffect } from 'react';

export const useOnClickOutside = (ref: React.RefObject<HTMLElement>, handler: () => void, enabled: boolean) => {

	useEffect(() => {
		const listener = () => {
			if (enabled) {
				handler();
			}
		};

		const interceptingHandler = (event: MouseEvent | TouchEvent) => {
			if (enabled) {
				event.stopPropagation();
			}
		};

		window.addEventListener('mousedown', listener);
		window.addEventListener('touchstart', listener);
		if (ref.current !== null) {
			ref.current.addEventListener('mousedown', interceptingHandler);
			ref.current.addEventListener('touchstart', interceptingHandler);
		}

		return () => {
			window.removeEventListener('mousedown', listener);
			window.removeEventListener('touchstart', listener);
			if (ref.current !== null) {
				ref.current.removeEventListener('mousedown', interceptingHandler);
				ref.current.removeEventListener('touchstart', interceptingHandler);
			}
		};
	}, [ref, handler]);

};
