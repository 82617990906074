import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiPrimitive: {
		input: {
			optionalValueSuffix: '(optional)'
		}
	}
};

export default literals;
