import { VendorThemeCollection } from '../app/components/theme/Theme.types';

export const themes: VendorThemeCollection = {
	'theme-s': {
		pageTitle: {
			en: 'ABB wallbox installation service'
		},
		caption: {
			en: 'Smart marketplace partner'
		},
		cssFile: 'smart.css'
	},
	'theme-f': {
		pageTitle: {
			en: 'ABB wallbox installation service'
		},
		caption: {
			en: 'ABB wallbox marketplace partner'
		},
		cssFile: 'fiege.css'
	}
};
