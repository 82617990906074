import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { HypermediaEntityResponse, HypermediaLink } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Model, ModelPrimaryKey, Mutation } from '@abb-emobility/shared/domain-model-foundation';
import { Nullable, ReadonlyOptional } from '@abb-emobility/shared/util';

import { CrudEntityStore } from './CrudEntityStore';
import { CrudActionStatus } from '../CrudActionStatus';
import { FetchStatus } from '../FetchStatus';
import { HypermediaLinkFilter } from '../HypermediaLinkFilter';
import { HypermediaLinkSort } from '../HypermediaLinkSort';
import { AsyncThunkConfig } from '../ThunkApiConfig';

// Initial store
export const createInitialCrudEntityStore = <T extends Model>() => {
	return {
		scopes: {}
	} as CrudEntityStore<T>;
};

// Scope loader
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createInitialEntityStoreEntry = (store: any, id: ModelPrimaryKey): void => {
	if (store.scopes?.[id] === undefined) {
		store.scopes[id] = {
			model: null,
			hypermediaLinks: [],
			fetchStatus: FetchStatus.IDLE,
			lastFetchError: null,
			actionStatus: CrudActionStatus.IDLE,
			lastActionError: null
		};
	}
};

// Action error identifier
export enum CrudEntityErrorAction {
	FETCH = 'FETCH',
	MUTATE = 'MUTATE',
	DELETE = 'DELETE'
}

// Slice creator
export const createCrudEntitySlice = <T extends Model, ThunkApiConfig extends AsyncThunkConfig>(
	storeName: string,
	fetch: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey,
		forceFetch?: boolean
	}, ThunkApiConfig>,
	mutateEntity: AsyncThunk<HypermediaEntityResponse<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey,
		modelMutation: Mutation<T>
	}, ThunkApiConfig>,
	deleteEntity: AsyncThunk<Nullable<T>, {
		apiBaseUrl: string,
		jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
		id: ModelPrimaryKey
	}, ThunkApiConfig>
) => {
	return createSlice({
		name: storeName,
		initialState: createInitialCrudEntityStore<T>(),
		// Regular synchronous reducers
		reducers: {
			resetStore(store) {
				Object.assign(store, createInitialCrudEntityStore<T>());
			},
			resolveActionStatus(store, action: PayloadAction<ModelPrimaryKey>) {
				createInitialEntityStoreEntry(store, action.payload);
				store.scopes[action.payload].actionStatus = CrudActionStatus.IDLE;
			},
			resolveFetchStatus(store, action: PayloadAction<ModelPrimaryKey>) {
				createInitialEntityStoreEntry(store, action.payload);
				store.scopes[action.payload].fetchStatus = FetchStatus.IDLE;
			}
		},
		// Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
		// Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
		// handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
		// https://github.com/immerjs/immer.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		extraReducers: {
			[String(fetch.pending)]: (state, action) => {
				createInitialEntityStoreEntry(state, action.meta.arg.id);
				// state.scopes[action.meta.arg.id] is Proxy instance in this use case, Object.assign acts like an unproxy function
				const entityState = Object.assign({}, state.scopes?.[action.meta.arg.id]);
				const pendingFetchStatus = entityState.fetchStatus === FetchStatus.IDLE ? FetchStatus.PENDING : FetchStatus.REFRESH_PENDING;
				state.scopes[action.meta.arg.id].fetchStatus = pendingFetchStatus;
			},
			[String(fetch.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					createInitialEntityStoreEntry(state, action.payload.item.id);
					state.scopes[action.payload.item.id].model = action.payload.item;
					state.scopes[action.payload.item.id].hypermediaLinks = action.payload.hypermediaLinks;
					state.scopes[action.payload.item.id].fetchStatus = FetchStatus.SUCCESS;
				}
			},
			[String(fetch.rejected)]: (state, action) => {
				createInitialEntityStoreEntry(state, action.meta.arg.id);
				const lastFetchError = action.payload ?? action.error;
				lastFetchError.action = CrudEntityErrorAction.FETCH;
				state.scopes[action.meta.arg.id].lastFetchError = lastFetchError;
				state.scopes[action.meta.arg.id].fetchStatus = FetchStatus.FAILED;
			},
			[String(mutateEntity.pending)]: (state, action) => {
				createInitialEntityStoreEntry(state, action.meta.arg.id);
				state.scopes[action.meta.arg.id].actionStatus = CrudActionStatus.MUTATE_PENDING;
			},
			[String(mutateEntity.fulfilled)]: (state, action: PayloadAction<HypermediaEntityResponse<Draft<T>>>) => {
				if (action.payload.item !== null) {
					createInitialEntityStoreEntry(state, action.payload.item.id);
					state.scopes[action.payload.item.id].model = action.payload.item;
					state.scopes[action.payload.item.id].hypermediaLinks = action.payload.hypermediaLinks;
					state.scopes[action.payload.item.id].actionStatus = CrudActionStatus.MUTATE_SUCCESS;
				}
			},
			[String(mutateEntity.rejected)]: (state, action) => {
				createInitialEntityStoreEntry(state, action.meta.arg.id);
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = CrudEntityErrorAction.MUTATE;
				state.scopes[action.meta.arg.id].lastActionError = lastActionError;
				state.scopes[action.meta.arg.id].actionStatus = CrudActionStatus.MUTATE_FAILED;
			},
			[String(deleteEntity.pending)]: (state, action) => {
				createInitialEntityStoreEntry(state, action.meta.arg.id);
				state.scopes[action.meta.arg.id].actionStatus = CrudActionStatus.DELETE_PENDING;
			},
			[String(deleteEntity.fulfilled)]: (state, action: PayloadAction<Nullable<Draft<T>>>) => {
				if (action.payload !== null) {
					createInitialEntityStoreEntry(state, action.payload.id);
					state.scopes[action.payload.id].model = null;
					state.scopes[action.payload.id].actionStatus = CrudActionStatus.DELETE_SUCCESS;
				}
			},
			[String(deleteEntity.rejected)]: (state, action) => {
				createInitialEntityStoreEntry(state, action.meta.arg.id);
				const lastActionError = action.payload ?? action.error;
				lastActionError.action = CrudEntityErrorAction.DELETE;
				state.scopes[action.meta.arg.id].lastActionError = lastActionError;
				state.scopes[action.meta.arg.id].actionStatus = CrudActionStatus.DELETE_FAILED;
			}
		}
	});
};

// Selector creators
export const createCrudSelectEntity = <T extends Model, Store extends Record<string, CrudEntityStore<T>>>(storeName: string) => {
	return (id: ModelPrimaryKey): (rootStore: Store) => ReadonlyOptional<T> => {
		return (rootStore): ReadonlyOptional<T> => {
			return new ReadonlyOptional(rootStore[storeName].scopes?.[id]?.model ?? null);
		};
	};
};

export const createCrudSelectHypermediaLinks = <T extends Model, Store extends Record<string, CrudEntityStore<T>>>(storeName: string) => {
	return (id: ModelPrimaryKey, sort?: HypermediaLinkSort, filter?: HypermediaLinkFilter): (rootStore: Store) => ReadonlyArray<HypermediaLink> => {
		return (rootStore): ReadonlyArray<HypermediaLink> => {
			let hypermediaLinks = [...(rootStore[storeName].scopes?.[id]?.hypermediaLinks ?? [])];
			if ((filter ?? null) !== null) {
				hypermediaLinks = hypermediaLinks.filter(filter as HypermediaLinkFilter);
			}
			if ((sort ?? null) !== null) {
				hypermediaLinks = [...hypermediaLinks].sort(sort);
			}
			return hypermediaLinks;
		};
	};
};

export const createCrudEntityStoreSize = <T extends Model, Store extends Record<string, CrudEntityStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => Readonly<number> => {
		return (rootStore): Readonly<number> => {
			return Object.values(rootStore[storeName].scopes)
				.filter((scope) => {
					return (scope?.model ?? null) !== null;
				})
				.length;
		};
	};
};

export const createCrudEntityStoreEntryIds = <T extends Model, Store extends Record<string, CrudEntityStore<T>>>(storeName: string) => {
	return (): (rootStore: Store) => ReadonlyArray<ModelPrimaryKey> => {
		return (rootStore): ReadonlyArray<ModelPrimaryKey> => {
			return Object.values(rootStore[storeName].scopes)
				.map((scope) => {
					return scope.model;
				})
				.filter((model): model is T => {
					return model !== null;
				})
				.map((model) => {
					return model.id;
				});
		};
	};
};
