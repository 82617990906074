import { KeycloakApiClient } from './KeycloakApiClient';
import { KeycloakApiClientInterface } from './KeycloakApiClientInterface';
import { KeycloakApiClientMock } from './KeycloakApiClientMock';

export class KeycloakApiClientFactory {

	public static create(): KeycloakApiClientInterface {
		if (process.env['NX_SHARED_KEYCLOAK_API_MOCKED'] === 'true') {
			return new KeycloakApiClientMock();
		}
		return new KeycloakApiClient();
	}

}
