import React from 'react';

import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	createTaskEntitySearchDataProviderValue,
	EntitySearchDataProviderProps,
	FetchMode,
	useDataProviderFetchInit,
	useDataProviderSearchFailedHandler,
	useSearchDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';
import { AnyTaskModel } from '@abb-emobility/usertask/domain-model';

import { anyTaskEntitySearchData } from './AnyTaskEntitySearchData';
import { AnyTaskEntitySearchStore, anyTaskEntityStoreAccessors, anyTaskEntitySearchStoreName } from './AnyTaskEntitySearchSlice';

export function AnyTaskEntitySearchDataProvider(props: EntitySearchDataProviderProps<AnyTaskModel>) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		filterCriteria,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null
	} = props;

	const env = useEnv();
	const auth = useAuth();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_USER_TASK_MANAGEMENT_API_BASE_URL'])
		.getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createTaskEntitySearchDataProviderValue<AnyTaskModel, TaskPayloadModel<AnyTaskModel>, AnyTaskEntitySearchStore>(
		anyTaskEntitySearchStoreName,
		anyTaskEntityStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderSearchFailedHandler(dataProviderValue, new FetchError(l10n.translate('usertaskDataProvider.error.taskSearchMessage')));

	useDataProviderFetchInit(
		fetchMode,
		() => dataProviderValue.search(filterCriteria),
		() => dataProviderValue.resolveActionStatus()
	);

	return useSearchDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<anyTaskEntitySearchData.Provider value={dataProviderValue}>
			{props.children}
		</anyTaskEntitySearchData.Provider>
	);

}
