export enum CurrencyCode {
	MMK = 'MMK',
	BIF = 'BIF',
	KHR = 'KHR',
	CAD = 'CAD',
	CVE = 'CVE',
	KYD = 'KYD',
	LKR = 'LKR',
	CLP = 'CLP',
	CNY = 'CNY',
	COP = 'COP',
	KMF = 'KMF',
	CRC = 'CRC',
	HRK = 'HRK',
	CUP = 'CUP',
	CYP = 'CYP',
	CZK = 'CZK',
	DKK = 'DKK',
	DOP = 'DOP',
	SVC = 'SVC',
	ETB = 'ETB',
	ERN = 'ERN',
	EEK = 'EEK',
	FKP = 'FKP',
	FJD = 'FJD',
	DJF = 'DJF',
	GMD = 'GMD',
	GIP = 'GIP',
	GTQ = 'GTQ',
	GNF = 'GNF',
	GYD = 'GYD',
	HTG = 'HTG',
	HNL = 'HNL',
	HKD = 'HKD',
	HUF = 'HUF',
	ISK = 'ISK',
	INR = 'INR',
	IDR = 'IDR',
	IRR = 'IRR',
	IQD = 'IQD',
	ILS = 'ILS',
	JMD = 'JMD',
	JPY = 'JPY',
	KZT = 'KZT',
	JOD = 'JOD',
	KES = 'KES',
	KPW = 'KPW',
	KRW = 'KRW',
	KWD = 'KWD',
	KGS = 'KGS',
	LAK = 'LAK',
	LBP = 'LBP',
	LSL = 'LSL',
	LVL = 'LVL',
	LRD = 'LRD',
	LYD = 'LYD',
	LTL = 'LTL',
	MOP = 'MOP',
	MWK = 'MWK',
	MYR = 'MYR',
	MVR = 'MVR',
	MTL = 'MTL',
	MRO = 'MRO',
	MUR = 'MUR',
	MXN = 'MXN',
	MNT = 'MNT',
	MDL = 'MDL',
	MAD = 'MAD',
	OMR = 'OMR',
	NAD = 'NAD',
	NPR = 'NPR',
	ANG = 'ANG',
	AWG = 'AWG',
	VUV = 'VUV',
	NZD = 'NZD',
	NIO = 'NIO',
	NGN = 'NGN',
	NOK = 'NOK',
	PKR = 'PKR',
	PAB = 'PAB',
	PGK = 'PGK',
	PYG = 'PYG',
	PEN = 'PEN',
	PHP = 'PHP',
	GWP = 'GWP',
	QAR = 'QAR',
	RUB = 'RUB',
	RWF = 'RWF',
	SHP = 'SHP',
	STD = 'STD',
	SAR = 'SAR',
	SCR = 'SCR',
	SLL = 'SLL',
	SGD = 'SGD',
	SKK = 'SKK',
	VND = 'VND',
	SOS = 'SOS',
	ZAR = 'ZAR',
	ZWD = 'ZWD',
	SZL = 'SZL',
	SEK = 'SEK',
	CHF = 'CHF',
	SYP = 'SYP',
	THB = 'THB',
	TOP = 'TOP',
	TTD = 'TTD',
	AED = 'AED',
	TND = 'TND',
	TMM = 'TMM',
	UGX = 'UGX',
	MKD = 'MKD',
	EGP = 'EGP',
	GBP = 'GBP',
	TZS = 'TZS',
	USD = 'USD',
	UYU = 'UYU',
	UZS = 'UZS',
	WST = 'WST',
	YER = 'YER',
	ZMK = 'ZMK',
	TWD = 'TWD',
	GHS = 'GHS',
	VEF = 'VEF',
	SDG = 'SDG',
	UYI = 'UYI',
	RSD = 'RSD',
	MZN = 'MZN',
	AZN = 'AZN',
	RON = 'RON',
	CHE = 'CHE',
	CHW = 'CHW',
	TRY = 'TRY',
	XAF = 'XAF',
	XCD = 'XCD',
	XOF = 'XOF',
	XPF = 'XPF',
	XBA = 'XBA',
	XBB = 'XBB',
	XBC = 'XBC',
	XBD = 'XBD',
	XAU = 'XAU',
	XDR = 'XDR',
	XAG = 'XAG',
	XPT = 'XPT',
	XTS = 'XTS',
	XPD = 'XPD',
	SRD = 'SRD',
	MGA = 'MGA',
	COU = 'COU',
	AFN = 'AFN',
	TJS = 'TJS',
	AOA = 'AOA',
	BYR = 'BYR',
	BGN = 'BGN',
	CDF = 'CDF',
	BAM = 'BAM',
	EUR = 'EUR',
	MXV = 'MXV',
	UAH = 'UAH',
	GEL = 'GEL',
	BOV = 'BOV',
	PLN = 'PLN',
	BRL = 'BRL',
	CLF = 'CLF',
	USN = 'USN',
	USS = 'USS',
	XXX = 'XXX',
	ALL = 'ALL',
	DZD = 'DZD',
	ARS = 'ARS',
	AMD = 'AMD',
	AUD = 'AUD',
	BSD = 'BSD',
	BHD = 'BHD',
	BDT = 'BDT',
	BBD = 'BBD',
	BZD = 'BZD',
	BMD = 'BMD',
	BTN = 'BTN',
	BOB = 'BOB',
	BWP = 'BWP',
	BND = 'BND',
	SBD = 'SBD'
}
