import { useCustomerOrderEntityData } from '@abb-emobility/oms/data-provider';

import { OrderProgress } from '../order-progress/OrderProgress';

export function TaskOrderProgress() {

	const orderEntityCrudData = useCustomerOrderEntityData();
	const orderModel = orderEntityCrudData.query().getOrUndefined();

	return (
		<OrderProgress progress={orderModel?.progress ?? undefined} />
	);
}
