import { Icon, IconIdentifier } from '@abb-emobility/oms/customer-ui-primitive';
import { PreCheckMediumType } from '@abb-emobility/shared/domain-model';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';
import { PreCheckChannelModel } from '@abb-emobility/usertask/domain-model';

import './PreCheckChannelSelect.scss';

export type PreCheckChannelProps = {
	channel: PreCheckChannelModel,
	onSelect: (channel: PreCheckChannelModel) => void,
	selected?: boolean,
	disabled?: boolean
};

export function PreCheckChannelSelect(props: PreCheckChannelProps) {

	const { channel, onSelect, selected = false, disabled = false } = props;

	const handleClick = () => {
		if (disabled) {
			return;
		}
		onSelect(channel);
	};

	const appointmentProposalClassString = buildCssClassStringFromClassMap({
		'pre-check-channel-select': true,
		'pre-check-channel-select--selected': selected,
		'pre-check-channel-select--disabled': disabled
	});

	const appointmentProposalIconClassString = buildCssClassStringFromClassMap({
		'pre-check-channel-select__icon': true,
		'pre-check-channel-select__icon--selected': selected
	});

	const renderIcon = (): IconIdentifier => {
		switch (channel.medium.type) {
			case PreCheckMediumType.PHONE_CALL:
				return IconIdentifier.PHONE_CALL;
			case PreCheckMediumType.VIDEO_CALL:
				return IconIdentifier.VIDEO_CAMERA;
		}
	};

	return (
		<section className={appointmentProposalClassString} onClick={handleClick}>
			<div className={appointmentProposalIconClassString}>
				<Icon name={renderIcon()} />
			</div>
			<div className="pre-check-channel-select__channel">
				<span className="pre-check-channel-select__channel__heading">
					{channel.medium.name}
				</span>
			</div>
		</section>
	);
}
