import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
	uiDomainLiteralsDe,
	uiDomainLiteralsEn,
	uiDomainLiteralsEs,
	uiDomainLiteralsFr,
	uiDomainLiteralsIt,
	uiDomainLiteralsNl,
	uiDomainLiteralsPt,
	uiDomainLiteralsSv
} from '@abb-emobility/oms/customer-ui-domain';
import {
	uiPrimitiveLiteralsDe,
	uiPrimitiveLiteralsEn,
	uiPrimitiveLiteralsEs,
	uiPrimitiveLiteralsFr,
	uiPrimitiveLiteralsIt,
	uiPrimitiveLiteralsNl,
	uiPrimitiveLiteralsPt,
	uiPrimitiveLiteralsSv
} from '@abb-emobility/oms/customer-ui-primitive';
import {
	abbUiQuestionnaireLiteralsDe,
	abbUiQuestionnaireLiteralsEn,
	abbUiQuestionnaireLiteralsEs,
	abbUiQuestionnaireLiteralsFr,
	abbUiQuestionnaireLiteralsIt,
	abbUiQuestionnaireLiteralsNl,
	abbUiQuestionnaireLiteralsPt,
	abbUiQuestionnaireLiteralsSv
} from '@abb-emobility/oms/customer-ui-questionnaire';
import {
	omsDataProviderLiteralsDe,
	omsDataProviderLiteralsEn,
	omsDataProviderLiteralsEs,
	omsDataProviderLiteralsFr,
	omsDataProviderLiteralsIt,
	omsDataProviderLiteralsNl,
	omsDataProviderLiteralsPt,
	omsDataProviderLiteralsSv
} from '@abb-emobility/oms/data-provider';
import { AuthHandler } from '@abb-emobility/shared/auth-provider';
import { EnvProvider } from '@abb-emobility/shared/environment';
import { NotFoundError } from '@abb-emobility/shared/error';
import { L10n, L10nProvider, Locale } from '@abb-emobility/shared/localization-provider';
import { ConsoleLogger, Logger, LoggerProvider } from '@abb-emobility/shared/logger';
import { sentryConfig, SentryLogger, TagValue } from '@abb-emobility/shared/sentry-integration';

import { AppFactory } from './app/AppFactory';
import { environment } from './environments/environment';
import { default as appLiteralsDe } from './l10n/literals-de';
import { default as appLiteralsEn } from './l10n/literals-en';
import { default as appLiteralsEs } from './l10n/literals-es';
import { default as appLiteralsFr } from './l10n/literals-fr';
import { default as appLiteralsIt } from './l10n/literals-it';
import { default as appLiteralsNl } from './l10n/literals-nl';
import { default as appLiteralsPt } from './l10n/literals-pt';
import { default as appLiteralsSv } from './l10n/literals-sv';
import { store } from './store/store';

// Set up locales to be used by L10n
export const locales = [
	new Locale('de', [
		appLiteralsDe,
		omsDataProviderLiteralsDe,
		uiPrimitiveLiteralsDe,
		uiDomainLiteralsDe,
		abbUiQuestionnaireLiteralsDe
	]),
	new Locale('en', [
		appLiteralsEn,
		omsDataProviderLiteralsEn,
		uiPrimitiveLiteralsEn,
		uiDomainLiteralsEn,
		abbUiQuestionnaireLiteralsEn
	]),
	new Locale('es', [
		appLiteralsEs,
		omsDataProviderLiteralsEs,
		uiPrimitiveLiteralsEs,
		uiDomainLiteralsEs,
		abbUiQuestionnaireLiteralsEs
	]),
	new Locale('fr', [
		appLiteralsFr,
		omsDataProviderLiteralsFr,
		uiPrimitiveLiteralsFr,
		uiDomainLiteralsFr,
		abbUiQuestionnaireLiteralsFr
	]),
	new Locale('it', [
		appLiteralsIt,
		omsDataProviderLiteralsIt,
		uiPrimitiveLiteralsIt,
		uiDomainLiteralsIt,
		abbUiQuestionnaireLiteralsIt
	]),
	new Locale('nl', [
		appLiteralsNl,
		omsDataProviderLiteralsNl,
		uiPrimitiveLiteralsNl,
		uiDomainLiteralsNl,
		abbUiQuestionnaireLiteralsNl
	]),
	new Locale('pt', [
		appLiteralsPt,
		omsDataProviderLiteralsPt,
		uiPrimitiveLiteralsPt,
		uiDomainLiteralsPt,
		abbUiQuestionnaireLiteralsPt
	]),
	new Locale('sv', [
		appLiteralsSv,
		omsDataProviderLiteralsSv,
		uiPrimitiveLiteralsSv,
		uiDomainLiteralsSv,
		abbUiQuestionnaireLiteralsSv
	])
];

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

// Set up logger instances to be used by ErrorHandler components
const loggers: Array<Logger> = [
	new ConsoleLogger(true, undefined, undefined)
];
if (process.env['NX_SENTRY_ENABLED'] === 'true') {
	loggers.push(new SentryLogger(
		sentryConfig(),
		true,
		(error) => !(error instanceof NotFoundError),
		undefined,
		() => AuthHandler.get().getScope(),
		() => {
			return new Map<string, TagValue>([
				['client.locale', L10n.selectedLocale()]
			]);
		}
	));
}

root.render(
	<StrictMode>
		<LoggerProvider loggers={loggers}>
			<BrowserRouter>
				<Provider store={store}>
					<EnvProvider env={environment}>
						<L10nProvider
							locales={locales}
							fallbackLocaleIdentifier={'en'}
						>
							<AppFactory />
						</L10nProvider>
					</EnvProvider>
				</Provider>
			</BrowserRouter>
		</LoggerProvider>
	</StrictMode>
);
