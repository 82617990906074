import React, { ReactElement } from 'react';

import { ErrorFeedback, SpinnerCable } from '@abb-emobility/oms/customer-ui-primitive';
import { CustomerOrderInformationEntityDataProvider } from '@abb-emobility/oms/data-provider';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { ErrorHandler, AuthenticationFailedError, AuthenticationRequiredError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { OrderInformationFactory } from '../payload/OrderInformationFactory';

export type OrderInformationAssemblerProps = {
	orderInformationId: ModelPrimaryKey
};

export function OrderInformationAssembler(props: OrderInformationAssemblerProps) {

	const { orderInformationId } = props;

	const l10n = useL10n();
	const auth = useAuth();

	const renderOrderInformationError = (error: Error): Nullable<ReactElement> => {
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			auth.reauthenticate();
		}

		return (
			<ErrorFeedback
				heading={l10n.translate('omsCustomerApp.error.generic.heading')}
				message={l10n.translate('omsCustomerApp.error.generic.message')}
				key="OmsGenericError"
			/>
		);
	};

	return (
		<ErrorHandler errorComponent={renderOrderInformationError}>
			<CustomerOrderInformationEntityDataProvider
				primaryKey={orderInformationId}
				pendingComponent={SpinnerCable}
			>
				<OrderInformationFactory />
			</CustomerOrderInformationEntityDataProvider>
		</ErrorHandler>
	);
}
