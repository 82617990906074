import { ModelConverter, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

export type InstallationPartnerOrderLineItemModel = {
	readonly id: ModelPrimaryKey
	readonly productName: string
	readonly productCode?: string
};

export class InstallationPartnerOrderLineItemModelConverter extends ModelConverter<InstallationPartnerOrderLineItemModel> {
}
