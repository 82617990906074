import { createContext, useContext } from 'react';

export type DismissableContextValue = {
	dismiss: () => void
};

export const dismissableContext = createContext<DismissableContextValue>({
	dismiss: (): void => {
		throw new Error('No dialogue provided');
	}
});

export const useDismissable = () => {
	return useContext(dismissableContext);
};
