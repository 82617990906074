import { AbstractTaskEntityApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { TaskPayloadModel } from '@abb-emobility/shared/domain-model-foundation';
import { AnyTaskModel, AnyTaskModelConverter } from '@abb-emobility/usertask/domain-model';

export class AnyTaskApiClient extends AbstractTaskEntityApiClient<AnyTaskModel, TaskPayloadModel<AnyTaskModel>> {
	protected collectionControllerUri = 'tasks/';
	protected paginationControllerUri = 'tasks/';
	protected entityControllerUri = 'task/{id}/';
	protected entitySearchControllerUri = 'task/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyTaskModelConverter();
}
