import { ChangeEvent, ReactElement, ReactNode, SelectHTMLAttributes } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './InputSelect.scss';

export type InputSelectOption = {
	label: string,
	value: string,
	disabled?: boolean
};

export type InputSelectProps = {
	label: string,
	notificationElement?: ReactNode,
	placeholder?: string,
	options?: Array<InputSelectOption>,
	onChange?: (value: string) => void
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'>;

export function InputSelect(props: InputSelectProps) {

	const { label, notificationElement, placeholder, options = [], value, required = false, onChange, ...rest } = props;

	const l10n = useL10n();

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		if (onChange !== undefined) {
			onChange(event.currentTarget.value);
		}
	};

	const renderOptions = (): ReactNode => {
		const optionElements: Array<ReactElement> = [];
		if (placeholder !== undefined) {
			optionElements.push(
				<option
					value=""
					disabled={true}
					key={'placeholder'}
				>
					{placeholder}
				</option>
			);
		}
		for (const index in options) {
			optionElements.push(
				<option
					value={options[index].value}
					disabled={options[index].disabled}
					key={index}
				>
					{options[index].label}
				</option>
			);
		}
		return optionElements;
	};

	const renderIcon = () => {
		if (props.multiple) {
			return null;
		}
		return (
			<span className="input-select__field__icon">
				<Icon name={IconIdentifier.CARET_DOWN} />
			</span>
		);
	};

	const renderLabel = (): ReactNode => {
		const labelSuffix = required ? null : (
			<span className="input-select__label__suffix">
				{l10n.translate('omsCustomerUiPrimitive.input.optionalValueSuffix')}
			</span>
		);
		return (
			<span className="input-select__label">
				{label}
				{labelSuffix}
			</span>
		);
	};

	return (
		<label className="input-select">
			{renderLabel()}
			{notificationElement}
			<span className="input-select__field">
				<select
					className="input-select__field__select"
					defaultValue={value}
					onChange={handleChange}
					required={required}
					{...rest}
				>
					{renderOptions()}
				</select>
				{renderIcon()}
			</span>
		</label>
	);
}
