import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données du client.',
			customerEntityFetchMessage: 'Une erreur s’est produite lors du chargement des détails du client.',
			taskFetchMessage: 'Une erreur s’est produite lors du chargement des détails de la tâche.',
			taskSearchMessage: 'Une erreur s’est produite lors de la recherche d’une tâche.',
			taskCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données de la tâche.',
			orderFetchMessage: 'Une erreur s’est produite lors du chargement des détails de la commande.',
			orderCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données de la commande.',
			orderEventCollectionFetchMessage: 'Une erreur s’est produite lors du chargement de l’historique des commandes.',
			orderEventEntityFetchMessage: 'Une erreur s’est produite lors du chargement de l’événement de commande.',
			orderShipmentCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données d’expédition.',
			orderAccountingCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données du compte.',
			orderFilesCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des fichiers.',
			orderInstallationCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données d’installation.',
			orderNotesCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des notes.',
			orderInformationCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des informations de commande.',
			installerAppointmentCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des rendez-vous.',
			reportCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des rapports.',
			searchCollectionFetchMessage: 'Une erreur s’est produite lors de la recherche de données.',
			orderPaymentCollectionFetchMessage: 'Une erreur s’est produite lors du chargement des données de paiement.',
			orderInformationEntityFetchMessage: 'Une erreur s’est produite lors du chargement des détails sur les informations de commande.'
		}
	}
};

export default literals;
