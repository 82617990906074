import { ReactNode } from 'react';

import { loggerContext } from './LoggerContext';
import { EventData } from '../EventData';
import { LogLevel } from '../LogLevel';
import { Logger } from '../Logger';

export type LoggerProviderProps = {
	loggers: Array<Logger>,
	children: ReactNode
};

export function LoggerProvider(props: LoggerProviderProps) {
	const { loggers } = props;

	const loggerContextValue = {
		provided: true,
		async logEvent(message: string, data?: EventData, category?: string, level?: LogLevel): Promise<void> {
			for (const logger of loggers) {
				void logger.logEvent(message, data, category, level);
			}
		},
		async logMessage(message: unknown, level?: LogLevel): Promise<void> {
			for (const logger of loggers) {
				void logger.logMessage(message, level);
			}
		},
		async logError(error: Error, level?: LogLevel): Promise<void> {
			for (const logger of loggers) {
				void logger.logError(error, level);
			}
		}
	};

	return (
		<loggerContext.Provider value={loggerContextValue}>
			{props.children}
		</loggerContext.Provider>
	);
}
