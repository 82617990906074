import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	compareFilterCriteria,
	compareSortCriteria,
	CrudApiClient,
	FilterCriteria,
	HypermediaEntityResponse,
	PaginatedCollectionResponse,
	SortCriteria
} from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Model, ModelPrimaryKey, Mutable, Mutation } from '@abb-emobility/shared/domain-model-foundation';
import { createErrorObject } from '@abb-emobility/shared/error';
import { Nullable } from '@abb-emobility/shared/util';

import { CrudCollectionStore } from './CrudCollectionStore';
import { CrudEntityStore } from './CrudEntityStore';
import { FetchStatus, fetchStatusPendingGroup } from '../FetchStatus';
import { ThunkApiConfig } from '../ThunkApiConfig';

export const createCrudFetchAllThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, sortCriteria?: SortCriteria<T>, filterCriteria?: FilterCriteria<T>, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchAll',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(params.sortCriteria, params.filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Proceed if force fetch
				if (params.forceFetch) {
					return true;
				}
				// Proceed if sort criteria has changed
				if (!compareSortCriteria<T>(params.sortCriteria, store.sortCriteria ?? undefined)) {
					return true;
				}
				// Proceed if filter criteria has changed
				if (!compareFilterCriteria<T>(params.filterCriteria, store.filterCriteria ?? undefined)) {
					return true;
				}
				// Cancel if store is populated
				return store.fetchStatus === FetchStatus.IDLE;
			}
		}
	);
};

export const createCrudRefetchAllThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> }, ThunkApiConfig>(
		storeName + '/refetchAll',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const sortCriteria = store.sortCriteria ?? undefined;
				const filterCriteria = store.filterCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(sortCriteria, filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				return !fetchStatusPendingGroup.includes(store.fetchStatus);
			}
		}
	);
};

export const createCrudFetchAllSortedThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, sortCriteria?: SortCriteria<T>, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchAllSorted',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const filterCriteria = store.filterCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(params.sortCriteria, filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Proceed if force fetch
				if (params.forceFetch) {
					return true;
				}
				// Proceed if sort criteria has changed
				if (!compareSortCriteria<T>(params.sortCriteria, store.sortCriteria ?? undefined)) {
					return true;
				}
				// Cancel if store is populated
				return store.fetchStatus === FetchStatus.IDLE;
			}
		}
	);
};

export const createCrudFetchAllFilteredThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, filterCriteria?: FilterCriteria<T>, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchAllFiltered',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const sortCriteria = store.sortCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchCollection(sortCriteria, params.filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Proceed if force fetch
				if (params.forceFetch) {
					return true;
				}
				// Proceed if filter criteria has changed
				if (!compareFilterCriteria<T>(params.filterCriteria, store.filterCriteria ?? undefined)) {
					return true;
				}
				// Cancel if store is populated
				return store.fetchStatus === FetchStatus.IDLE;
			}
		}
	);
};

export const createCrudFetchNextThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<PaginatedCollectionResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>> }, ThunkApiConfig>(
		storeName + '/fetchNext',
		async (params, thunkApi) => {
			try {
				const store = (thunkApi.getState() as Store)[storeName];
				const currentPage = store.currentPage ?? 1;
				const sortCriteria = store.sortCriteria ?? undefined;
				const filterCriteria = store.filterCriteria ?? undefined;
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchPage(currentPage + 1, sortCriteria, filterCriteria);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				const currentPage = store.currentPage ?? 1;
				const lastPage = store.maxPages ?? 1;
				return !fetchStatusPendingGroup.includes(store.fetchStatus) && currentPage < lastPage;
			}
		}
	);
};

export const createCrudFetchCollectionEntityThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchCollectionEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus) || store.fetchEntityStatus?.[params.id] === FetchStatus.PENDING) {
					return false;
				}
				// Ignore existing model if forceFetch
				if (params.forceFetch) {
					return true;
				}
				// Cancel if id is already existing
				const existingModel = store.models.find((entry) => {
					return entry.id === params.id;
				});
				return existingModel === undefined;
			}
		}
	);
};

export const createCrudRefetchCollectionEntityThunk = <T extends Model, Store extends Record<string, CrudCollectionStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey }, ThunkApiConfig>(
		storeName + '/refetchCollectionEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (fetchStatusPendingGroup.includes(store.fetchStatus)) {
					return false;
				}
				// Cancel if id not already existing
				const existingModel = store.models.find((entry) => {
					return entry.id === params.id;
				});
				return existingModel !== undefined;
			}
		}
	);
};

export const createCrudFetchEntityThunk = <T extends Model, Store extends Record<string, CrudEntityStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, forceFetch?: boolean }, ThunkApiConfig>(
		storeName + '/fetchEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (store.scopes?.[params.id].fetchStatus === FetchStatus.PENDING) {
					return false;
				}
				// Ignore existing model if forceFetch
				if (params.forceFetch) {
					return true;
				}
				// Cancel if id is already existing
				const existingModel = store.scopes?.[params.id].model ?? null;
				return existingModel === null;
			}
		}
	);
};

export const createCrudRefetchEntityThunk = <T extends Model, Store extends Record<string, CrudEntityStore<T>>, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey }, ThunkApiConfig>(
		storeName + '/refetchEntity',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).fetchEntity(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		},
		{
			condition: (params, { getState }): boolean => {
				// Silently abort the action
				const store = (getState() as Store)[storeName];
				// Abort if already pending
				if (store.scopes?.[params.id].fetchStatus === FetchStatus.PENDING) {
					return false;
				}
				// Cancel if id is not existing
				const existingModel = store.scopes?.[params.id].model ?? null;
				return existingModel !== null;
			}
		}
	);
};

export const createCrudMutateThunk = <T extends Model, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey, modelMutation: Mutation<T> }, ThunkApiConfig>(
		storeName + '/mutate',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).mutate(params.id, params.modelMutation);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};

export const createCrudCreateThunk = <T extends Model, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<HypermediaEntityResponse<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, mutableModel: Mutable<T> }, ThunkApiConfig>(
		storeName + '/create',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).create(params.mutableModel);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};

export const createCrudDeleteThunk = <T extends Model, ApiClient extends CrudApiClient<T>>(
	storeName: string,
	apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => ApiClient
) => {
	return createAsyncThunk<Nullable<T>, { apiBaseUrl: string, jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>, id: ModelPrimaryKey }, ThunkApiConfig>(
		storeName + '/delete',
		async (params, thunkApi) => {
			try {
				return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader()).delete(params.id);
			} catch (error) {
				const errorObject = createErrorObject(error as Error);
				return thunkApi.rejectWithValue(errorObject);
			}
		}
	);
};
