import { InstallationPartnerOrderInformationGridOperatorDecisionInformationModel } from '@abb-emobility/oms/domain-model';
import { gridOperatorModelMock } from '@abb-emobility/shared/api-integration';
import { DecisionType } from '@abb-emobility/shared/domain-model';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

export const installationPartnerOrderInformationGridOperatorDecisionInformationModel: Dto<InstallationPartnerOrderInformationGridOperatorDecisionInformationModel> = {
	gridOperator: gridOperatorModelMock,
	decision: DecisionType.ACCEPTED,
	files: [
		{
			url: {
				accessible: true,
				value: 'https://fiege.com'
			},
			meta: {
				fileName: 'Prüfung-Philipp Otto Runge Musterstraße 234, 24145 Kiel.pdf',
				mimeType: 'pdf'
			}
		},
		{
			url: {
				accessible: true,
				value: 'https://fiege.com'
			},
			meta: {
				fileName: 'Prüfung-Philipp Otto Runge Musterstraße 234, 24145 Kiel.pdf',
				mimeType: 'application/pdf',
				size: 8900,
				lastModifiedDate: createTimestampDtoFromDate(new Date())
			}
		}
	]
};
