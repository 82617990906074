import { InstallerModel } from '@abb-emobility/shared/domain-model';

export const installerModelMock01: InstallerModel = {
	id: '1',
	userName: 'max',
	name: 'Max Musterinstaller',
	mailAddress: 'mm@domain.tld',
	phoneNumber: '+4915112345678'
};

export const installerModelMock02: InstallerModel = {
	id: '2',
	userName: 'minni',
	name: 'Minni Musterinstaller',
	mailAddress: 'mm@domain.tld',
	phoneNumber: '+4915112345678'
};
