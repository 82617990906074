import { InstallationPartnerOrderProgressModel, InstallationPartnerOrderProgressStepModel } from '@abb-emobility/oms/domain-model';
import { ProgressState } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';
import { TaskType } from '@abb-emobility/usertask/domain-model';

export const installationPartnerOrderProgressStepModelMock01: Dto<InstallationPartnerOrderProgressStepModel> = {
	taskType: TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS,
	status: ProgressState.DONE
};

export const installationPartnerOrderProgressStepModelMock02: Dto<InstallationPartnerOrderProgressStepModel> = {
	taskType: TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK,
	status: ProgressState.DONE
};

export const installationPartnerOrderProgressStepModelMock03: Dto<InstallationPartnerOrderProgressStepModel> = {
	taskType: TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION,
	status: ProgressState.DONE
};

export const installationPartnerOrderProgressStepModelMock04: Dto<InstallationPartnerOrderProgressStepModel> = {
	taskType: TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT,
	status: ProgressState.ONGOING
};

export const installationPartnerOrderProgressStepModelMock05: Dto<InstallationPartnerOrderProgressStepModel> = {
	taskType: TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION,
	status: ProgressState.PENDING
};

export const installationPartnerOrderProgressMock: Dto<InstallationPartnerOrderProgressModel> = {
	steps: [
		installationPartnerOrderProgressStepModelMock01,
		installationPartnerOrderProgressStepModelMock02,
		installationPartnerOrderProgressStepModelMock03,
		installationPartnerOrderProgressStepModelMock04,
		installationPartnerOrderProgressStepModelMock05
	]
};

export const installationPartnerOrderNoProgressMock: Dto<InstallationPartnerOrderProgressModel> = {
	steps: [
		{
			...installationPartnerOrderProgressStepModelMock01,
			status: ProgressState.PENDING
		},
		{
			...installationPartnerOrderProgressStepModelMock04,
			status: ProgressState.PENDING
		},
		{
			...installationPartnerOrderProgressStepModelMock05,
			status: ProgressState.PENDING
		}
	]
};
