import { DecisionType, InstallationDecisionType, numericApiSafeguard } from '@abb-emobility/shared/domain-model';
import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

type PrecheckRequestedEffort = {
	trade: string,
	expectedEffort: number
};

export class PrecheckRequestedEffortConverter extends ModelConverter<PrecheckRequestedEffort> {

	override getCustomConversionFields(): Map<keyof PrecheckRequestedEffort, CustomConversion> {
		return new Map([
			['expectedEffort', numericApiSafeguard]
		]);
	}

}

type PrecheckDecision = {
	decision: DecisionType,
	installationType: InstallationDecisionType
};

export type InstallationPartnerOrderInformationPrecheckInformationModel = {
	readonly precheckDecision: PrecheckDecision,
	readonly requestedEffort: Array<PrecheckRequestedEffort>
};

export class InstallationPartnerOrderInformationPrecheckInformationModelConverter extends ModelConverter<InstallationPartnerOrderInformationPrecheckInformationModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof InstallationPartnerOrderInformationPrecheckInformationModel, ModelConverterInterface<any>> {
		return new Map([
			['requestedEffort', new PrecheckRequestedEffortConverter()]
		]);
	}

}
