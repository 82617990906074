import { AbstractTaskCollectionApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { AnyTaskCollectionItemModel, AnyTaskCollectionItemModelConverter } from '@abb-emobility/usertask/domain-model';

export class AnyTaskCollectionApiClient extends AbstractTaskCollectionApiClient<AnyTaskCollectionItemModel> {
	protected collectionControllerUri = 'tasks/';
	protected paginationControllerUri = 'tasks/';
	protected entityControllerUri = 'task/{id}/';
	protected entitySearchControllerUri = 'task/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyTaskCollectionItemModelConverter();
}
