import { Nullable } from './Nullable';
import { Probably } from './Probably';

export class Optional<T> {

	private readonly value: Probably<T>;

	constructor(value: Probably<T>) {
		this.value = value;
	}

	public get(): Nullable<T> {
		return this.value ?? null;
	}

	public getOrDefault(defaultValue: T): T {
		return this.value ?? defaultValue;
	}

	public getOrUndefined(): T | undefined {
		return this.value ?? undefined;
	}

	public getOrThrow(error: Error): T {
		if ((this.value ?? null) === null) {
			throw error;
		}
		return this.value as T;
	}

	public getOrCompute(computation: () => T): T {
		if ((this.value ?? null) === null) {
			return computation();
		}
		return this.value as T;
	}

}

export class ReadonlyOptional<T> extends Optional<T> {

	override get(): Nullable<Readonly<T>> {
		return super.get();
	}

	override getOrDefault(defaultValue: T): Readonly<T> {
		return super.getOrDefault(defaultValue);
	}

	override getOrUndefined(): Readonly<T> | undefined {
		return super.getOrUndefined();
	}

	override getOrThrow(error: Error): Readonly<T> {
		return super.getOrThrow(error);
	}

	override getOrCompute(computation: () => T): Readonly<T> {
		return super.getOrCompute(computation);
	}

}
