import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Durante o carregamento dos dados do cliente, ocorreu um erro.',
			customerEntityFetchMessage: 'Durante o carregamento dos detalhes do cliente, ocorreu um erro.',
			taskFetchMessage: 'Durante o carregamento dos detalhes da tarefa, ocorreu um erro.',
			taskSearchMessage: 'Durante a procura de uma tarefa, ocorreu um erro.',
			taskCollectionFetchMessage: 'Durante o carregamento dos dados da tarefa, ocorreu um erro.',
			orderFetchMessage: 'Durante o carregamento dos detalhes da encomenda, ocorreu um erro.',
			orderCollectionFetchMessage: 'Durante o carregamento dos dados da encomenda, ocorreu um erro.',
			orderEventCollectionFetchMessage: 'Durante o carregamento do histórico de encomendas, ocorreu um erro.',
			orderEventEntityFetchMessage: 'Durante o carregamento do evento de encomenda, ocorreu um erro.',
			orderShipmentCollectionFetchMessage: 'Durante o carregamento dos dados de envio, ocorreu um erro.',
			orderAccountingCollectionFetchMessage: 'Durante o carregamento dos dados da conta, ocorreu um erro.',
			orderFilesCollectionFetchMessage: 'Durante o carregamento dos ficheiros ocorreu um erro.',
			orderInstallationCollectionFetchMessage: 'Durante o carregamento dos dados de instalação, ocorreu um erro.',
			orderNotesCollectionFetchMessage: 'Durante o carregamento das notas ocorreu um erro.',
			orderInformationCollectionFetchMessage: 'Durante o carregamento das informações da encomenda, ocorreu um erro.',
			installerAppointmentCollectionFetchMessage: 'Durante o carregamento das nomeações ocorreu um erro.',
			reportCollectionFetchMessage: 'Durante o carregamento dos relatórios, ocorreu um erro.',
			searchCollectionFetchMessage: 'Durante a pesquisa de dados, ocorreu um erro.',
			orderPaymentCollectionFetchMessage: 'Durante o carregamento dos dados de pagamento, ocorreu um erro.',
			orderInformationEntityFetchMessage: 'Durante o carregamento dos detalhes das informações da encomenda, ocorreu um erro.'
		}
	}
};

export default literals;
