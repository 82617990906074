import React, { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import './InputCheckbox.scss';

export type InputCheckboxProps = {
	label?: string | React.JSX.Element,
	notificationElement?: ReactNode,
	heading?: string,
	onChange?: (checked: boolean, value: Nullable<string>) => void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export function InputCheckbox(props: InputCheckboxProps) {

	const { label, notificationElement, heading, onChange, ...rest } = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (onChange !== undefined) {
			onChange(event.currentTarget.checked, event.currentTarget.value ?? null);
		}
	};

	const renderHeading = () => {
		if (heading === undefined) {
			return;
		}
		return (
			<span className="input-checkbox__content__heading">{heading}</span>
		);
	};

	const renderLabel = () => {
		if (label === undefined) {
			return;
		}
		return (
			<span className="input-checkbox__content__label">{label}</span>
		);
	};

	return (
		<>
			{notificationElement}
			<label className="input-checkbox">
			<span className="input-checkbox__input">
				<input
					type="checkbox"
					onChange={handleChange}
					{...rest}
				/>
			</span>
				<span className="input-checkbox__content">
				{renderHeading()}
					{renderLabel()}
			</span>
			</label>
		</>
	);
}
