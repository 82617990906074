import { CustomerModel, CustomerModelConverter } from '@abb-emobility/shared/domain-model';
import { CustomConversion, ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';

export enum AddressValidationContext {
	DELIVERY = 'DELIVERY',
	BILLING = 'BILLING'
}

export type AddressValidationAddressModel = {
	zip: string,
	city: string,
	street: string,
	countryCode: IsoCountryCode,
	region?: string
};

export class AddressValidationAddressModelConverter extends ModelConverter<AddressValidationAddressModel> {

	override getCustomConversionFields(): Map<keyof AddressValidationAddressModel, CustomConversion> {
		return new Map([[
			'countryCode', {
				toDto: (modelValue) => {
					return modelValue;
				},
				toModel: (dtoValue) => {
					return dtoValue?.toUpperCase() as IsoCountryCode;
				}
			}
		]]);
	}
}

export type AddressPayloadModel = {
	address: AddressValidationAddressModel,
	usage: Array<AddressValidationContext>
};

export class AddressPayloadModelConverter extends ModelConverter<AddressPayloadModel> {
	override getFieldConverterMapByModel(): Map<keyof AddressPayloadModel, ModelConverterInterface<AddressValidationAddressModel>> {
		return new Map([
			['address', new AddressValidationAddressModelConverter()]
		]);
	}
}

export type AddressValidationPayloadModel = {
	readonly customer: CustomerModel,
	readonly invalidAddresses: Array<AddressPayloadModel>,
	addressDraft?: Array<AddressPayloadModel>
};

export class AddressValidationPayloadModelConverter extends ModelConverter<AddressValidationPayloadModel> {
	override getFieldConverterMapByModel() {
		const customerModelConverter = new CustomerModelConverter();
		return new Map<
			keyof AddressValidationPayloadModel,
			ModelConverterInterface<CustomerModel> |
			ModelConverterInterface<AddressPayloadModel>
		>([
			['customer', customerModelConverter],
			['invalidAddresses', new AddressPayloadModelConverter()],
			['addressDraft', new AddressPayloadModelConverter()]
		]);
	}
}
