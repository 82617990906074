export enum AnswerTypeId {
	TEXT = 'TEXT',
	MAIL_ADDRESS = 'MAIL_ADDRESS',
	PHONE = 'PHONE',
	NUMBER = 'NUMBER',
	PICTURE = 'PICTURE',
	DOCUMENT = 'DOCUMENT',
	SINGLE_SELECT = 'SINGLE_SELECT',
	MULTI_SELECT = 'MULTI_SELECT',
	CONSENT = 'CONSENT'
}

export type SelectOption = {
	label: string,
	value: string
};

export type AnswerTypeOptionsFoundation = {
	placeholder?: string,
	required?: boolean
};

export type TextAnswerTypeOptions = AnswerTypeOptionsFoundation & {
	minLength?: number,
	maxLength?: number,
	multiline?: boolean
};

export type MailAddressAnswerTypeOptions = AnswerTypeOptionsFoundation;

export type PhoneAnswerTypeOptions = AnswerTypeOptionsFoundation;

export type NumberAnswerTypeOptions = AnswerTypeOptionsFoundation & {
	minValue?: number,
	maxValue?: number,
	precision?: number
};

export type PictureAnswerTypeOptions = AnswerTypeOptionsFoundation;

export type DocumentAnswerTypeOptions = AnswerTypeOptionsFoundation;

export type SingleSelectAnswerTypeOptions = AnswerTypeOptionsFoundation & {
	options: Array<SelectOption>
};

export type MultiSelectAnswerTypeOptions = AnswerTypeOptionsFoundation & {
	options: Array<SelectOption>
};

export type ConsentAnswerTypeOptions = AnswerTypeOptionsFoundation;

export type TextAnswerType = {
	type: AnswerTypeId.TEXT,
	options: TextAnswerTypeOptions
};

export type MailAddressAnswerType = {
	type: AnswerTypeId.MAIL_ADDRESS,
	options: MailAddressAnswerTypeOptions
};

export type PhoneAnswerType = {
	type: AnswerTypeId.PHONE,
	options: PhoneAnswerTypeOptions
};

export type NumberAnswerType = {
	type: AnswerTypeId.NUMBER,
	options: NumberAnswerTypeOptions
};

export type PictureAnswerType = {
	type: AnswerTypeId.PICTURE,
	options: PictureAnswerTypeOptions
};

export type DocumentAnswerType = {
	type: AnswerTypeId.DOCUMENT,
	options: DocumentAnswerTypeOptions
};

export type SingleSelectAnswerType = {
	type: AnswerTypeId.SINGLE_SELECT,
	options: SingleSelectAnswerTypeOptions
};

export type MultiSelectAnswerType = {
	type: AnswerTypeId.MULTI_SELECT,
	options: MultiSelectAnswerTypeOptions
};

export type ConsentAnswerType = {
	type: AnswerTypeId.CONSENT,
	options: ConsentAnswerTypeOptions
};

export type StringAnswerType =
	| TextAnswerType
	| MailAddressAnswerType
	| PhoneAnswerType
	| SingleSelectAnswerType
	| MultiSelectAnswerType
	| ConsentAnswerType;

export type NumericAnswerType = NumberAnswerType;

export type FileAnswerType = PictureAnswerType | DocumentAnswerType;

export type AnyAnswerType =
	| StringAnswerType
	| NumericAnswerType
	| FileAnswerType;

export type PictureHint = {
	url: string,
	title: string
};

export type AnyQuestion = {
	key: string,
	question: string,
	hint?: string,
	pictureHint?: PictureHint,
	answerType: AnyAnswerType
};
