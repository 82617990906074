import { OrderImportB2bModel, OrderImportB2bModelConverter } from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class OrderImportB2bApiClient extends AbstractCrudApiClient<OrderImportB2bModel> {
	protected collectionControllerUri = 'order/bundled/b2b';
	protected paginationControllerUri = 'order/bundled/b2b';
	protected entityControllerUri = 'order/bundled/b2b/{id}/';
	protected entitySearchControllerUri = 'order/bundled/b2b';
	protected modelConverter = new OrderImportB2bModelConverter();
}
