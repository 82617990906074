import { ReactNode } from 'react';

import './FooterItems.scss';

export type FooterItemsProps = {
	children: ReactNode
};

export function FooterItems(props: FooterItemsProps) {
	const { children } = props;

	return (
		<div className="footer-items">
			{children}
		</div>
	);
}
