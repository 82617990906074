import { CustomConversion } from '@abb-emobility/shared/domain-model-foundation';
import { isString } from '@abb-emobility/shared/util';

export const numericApiSafeguard: CustomConversion = {
	toDto(modelValue: unknown) {
		return modelValue;
	},
	toModel(dtoValue: unknown) {
		if (isString(dtoValue)) {
			return parseFloat(dtoValue);
		}
		return dtoValue;
	}
};
