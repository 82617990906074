import { CustomerOrderDocumentModel } from '@abb-emobility/oms/domain-model';
import { AccessibleUrlDto, Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerOrderDocumentModelMock: Dto<CustomerOrderDocumentModel> = {
	id: '1',
	document: {
		meta: {
			title: 'Manual',
			fileName: 'manual.pdf',
			lastModifiedDate: '2022-03-04T20:13:11.696Z',
			mimeType: 'application/pdf',
			size: 1024
		},
		url: {
			accessible: true,
			value: '/app/uploads/2022/02/22-02-PM-FIEGE-Now_en.zip'
		}
	}
};

export const customerOrderDocumentModelCollectionMock: Array<Dto<CustomerOrderDocumentModel>> = [
	customerOrderDocumentModelMock,
	{ ...customerOrderDocumentModelMock, id: '2' },
	{ ...customerOrderDocumentModelMock, id: '3' },
	{ ...customerOrderDocumentModelMock, id: '4' },
	{ ...customerOrderDocumentModelMock, id: '5' }
];

export const customerOrderDocumentProtectedDownlaodUrlModelMock: AccessibleUrlDto = {
	accessible: true,
	value: '/app/uploads/2022/02/22-02-PM-FIEGE-Now_en.zip'
};
