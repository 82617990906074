import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	omsCustomerUiQuestionnaire: {
		questionnaire: {
			singleSelectPlaceholder: 'Veuillez sélectionner',
			pictureSelectPlaceholder: 'Sélectionner une image ou prendre une photo',
			pictureSelectTrigger: 'Sélectionner une photo',
			pictureSelectHint: {
				default: 'Veillez à ce qu’il n’y ait pas de personnes sur la photo.',
				examplePicture: 'Veuillez vous référer à l\'image d\'exemple et vous assurer qu\'aucune personne ne se trouve sur l\'image.'
			},
			documentSelectPlaceholder: 'Veuillez sélectionner un document',
			documentSelectTrigger: 'Sélectionner un document',
			selectedFileTooLarge: 'Le fichier sélectionné est trop volumineux pour être transféré. Veuillez sélectionner un fichier dont la taille est inférieure à {{maxFileSize}}.',
			answerTypeNotFound: 'Le type de réponse {{type}} est inconnu.',
			previousSegmentButtonLabel: 'Retour',
			nextSegmentButtonLabel: 'Étape suivante',
			submitButtonLabel: 'Envoyer des informations',
			validationErrors: {
				heading: 'Vos réponses comportent quelques problèmes',
				message: 'Veuillez cocher vos réponses pour compléter le questionnaire'
			}
		}
	}
};

export default literals;
