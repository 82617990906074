import {
	AnyCustomerOrderInformationCollectionItemModel,
	AnyCustomerOrderInformationCollectionItemModelConverter
} from '@abb-emobility/oms/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class AnyCustomerOrderInformationCollectionApiClient extends AbstractCrudApiClient<AnyCustomerOrderInformationCollectionItemModel> {
	protected collectionControllerUri = 'order-informations/';
	protected paginationControllerUri = 'order-informations/';
	protected entityControllerUri = 'order-information/{id}/';
	protected entitySearchControllerUri = 'order-information/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyCustomerOrderInformationCollectionItemModelConverter();
}
